import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  message,
  Button,
  Modal,
  Table,
  Tooltip,
  Popconfirm,
  Icon,
  Input,
  Divider,
  Row,
  Col,
  Notification
} from "antd";
import { withRouter } from "react-router-dom";
import "./style.css";

import colors from "../../styles";
import StyledIcon from "../../components/StyledIcon";
import UserForm from "../../components/UserForm"
import ModuleLayout from "../../components/ModuleLayout"
import Loader from "../../components/Loader"
import * as error_codes from '../../constants/error_codes'
import * as roles from '../../constants/roles'
import * as actions from '../../constants'

const { danger, success } = colors;
const Search = Input.Search;

const UserManagement = ({ history }) => {
  const [searchValue, setSearchValue] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  // const [isLoading, setIsLoading] = useState(false);
  const [userFormType, setUserFormType] = useState("create")
  const [updateUuid, setUpdateUuid] = useState("")

  const usersList = useSelector((state) => state.rootReducer.userManagment.users)
  const usersFilteredList = useSelector((state) => state.rootReducer.userManagment.usersFilteredList)
  const managerRole = useSelector((state) => state.rootReducer.auth.rolename)
  const loading = useSelector(state => state.rootReducer.loaders.loadingUsersList)
  const deletedUser = useSelector(state => state.rootReducer.userManagment.deletedUser)
  const error = useSelector((state) => state.rootReducer.userManagment.error)

  //const { userData: { tenant_uuid, claims } } = useContext(UserContext);

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)

  const dispatch = useDispatch()

  useEffect(
    () => {
      (async () => {
        dispatch(({ type: actions.FETCH_USERS_DATA, payload: { tenant_uuid } }))
        if(managerRole === roles.USER_ROLE_SUPERADMIN){
          dispatch(({ type: actions.USER_FETCH_COMPANIES_LIST, payload: { tenant_uuid } }))
        }
      })()
      return () => {
        dispatch({ type: actions.CLEAN_USER_MANAGMENT_STATE })
      }
    },
    []
  )

  const handleDelete = async user_uuid => {
    dispatch(({ type: actions.REMOVE_USER, payload: { tenant_uuid, user_uuid } }))
  }

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
            Modal.destroyAll()
            Modal.error({
              destroyOnClose: true,
              title: error.message,
              okText: "Retry",
              onOk: () => {
                dispatch(({ type: actions.FETCH_USERS_DATA, payload: { tenant_uuid } }))
              },
            })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (deletedUser) {
        message.success("User successfully deleted")
        dispatch(({ type: actions.CLEAN_USER_CREATION_EDITION_STATES }))
      }
    },
    [deletedUser]
  )

  const userCanDoActions =
    domainClaims.CanRemoveUser || domainClaims.CanEditUser;

  const columns = userCanDoActions
    ? [
        {
          title: "Username",
          dataIndex: "username",
          key: "username",
          width: "60%"
        },
        {
          title: "User Status",
          key: "userstatus",
          width: "20%",
          render: (text, record) => (
            <div>
              {record.is_active ? "Active": "No active"}
            </div>
          )
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <>
              {domainClaims.CanEditUser ? (
                <>
                  <Tooltip placement="topRight" title="Edit">
                    <span>
                      <StyledIcon
                        handleClick={() => {
                          setUserFormType("update");
                          setUpdateUuid(record.uuid);
                          setIsModalVisible(true);
                        }}
                        type="edit"
                        scope="action"
                        color={success}
                      />
                    </span>
                  </Tooltip>
                  <Divider type="vertical" style={{ margin: "0 1em" }} />
                </>
              ) : null}
              {domainClaims.CanRemoveUser ? (
                <Popconfirm
                  title={`You are about to delete user ${record.username}. Are you sure?`}
                  onConfirm={() => handleDelete(record.uuid)}
                  okText="Yes"
                  cancelText="No"
                  placement="bottomRight"
                >
                  <Tooltip placement="topRight" title="Delete">
                    <span>
                      <StyledIcon type="delete" scope="action" color={danger} />
                    </span>
                  </Tooltip>
                </Popconfirm>
              ) : null}
            </>
          ),
          width: "20%",
          align: "center"
        }
      ]
    : [
        {
          title: "User name",
          dataIndex: "username",
          key: "username",
          width: "100%"
        }
      ];

  return loading ? (
    <Loader />
  ) : (
    <ModuleLayout
      navbarComponent={
        <>
          <Icon type="user" /> <span>User Management</span>
        </>
      }
      contentComponent={
        <>
          <Row
            type="flex"
            justify="space-between"
            style={{
              width: "100%",
              marginBottom: "1.5em",
              marginLeft: "0",
              marginRight: "0"
            }}
          >
            <Col>
              <Button
                style={{ float: "left" }}
                type="primary"
                size="large"
                icon="plus"
                disabled={!domainClaims.CanCreateUser}
                onClick={() => {
                  setUpdateUuid("")
                  setIsModalVisible(true);
                  setUserFormType("create");
                }}
              >
                Add User
              </Button>
            </Col>
            {/* <Col>
              <Search
                placeholder="Search..."
                enterButton
                size="large"
                onChange={e => {
                  const value = e.target.value;
                  setSearchValue(value);
                  let _appData = { ...appData };
                  _appData.userList = usersCompleteList
                    .filter(user => user.username.includes(value))
                    .map(user => {
                      user.key = user.uuid;
                      return user;
                    });
                  setAppData(_appData);
                }}
              />
            </Col> */}
          </Row>
          <Table
            bordered
            style={{ width: "100%" }}
            size="large"
            columns={columns}
            dataSource={usersList.map(user => {
              user.key = user.uuid;
              return user;
            })}
          />
          <Modal
            title={
              <span>
                <Icon type="user" />
                {userFormType === "create" ? ` Add User` : ` Update User`}
              </span>
            }
            width="60vw"
            destroyOnClose={true}
            maskClosable={true}
            style={{ top: "2em" }}
            visible={isModalVisible}
            onCancel={() => {
              setIsModalVisible(false)
              dispatch(({ type: actions.CLEAN_USER_CREATION_EDITION_STATES }))
            }}
            footer={null}
          >
            <UserForm
              uuid={updateUuid}
              type={userFormType}
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
            />
          </Modal>
        </>
      }
    />
  );
};

export default withRouter(UserManagement);
