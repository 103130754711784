import React, { useEffect } from "react";
import { Form, Input, Button, message, Card, Icon, Row, Col } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link } from "react-router-dom";
import StyledIcon from "../StyledIcon";
import "./styles.css";

import * as actions from '../../constants'
import colors from "../../styles";
const { darkIconColor } = colors;

const FormItem = Form.Item
const TextArea = Input.TextArea

const UserResetPasswordForm = ({
  form,
  history }) => {
  const loading = useSelector((state) => state.rootReducer.loaders.loadingChangeUserPasswordToken)
  const success = useSelector((state) => state.rootReducer.userPasswordReset.successChangeUserPasswordToken)
  const error = useSelector((state) => state.rootReducer.userPasswordReset.errorChangetPasswordToken)
  const dispatch = useDispatch()

  const { getFieldDecorator, validateFields, resetFields } = form;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { reset_token, password, password2 } = values
        dispatch(({ type: actions.CHANGE_USER_PASSWORD_TOKEN, payload: { form: { reset_token, password, password2 } } }))
      } else {
        message.error(err);
      }
    })
  }

  useEffect(
    () => {
      if (error !== null) {
        message.error(error.message)
        dispatch(({ type: actions.CLEAN_USER_RESET_PASSWORD_STATES }))
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (success) {
        resetFields()
        message.success("Password changed successfully")
        dispatch(({ type: actions.CLEAN_USER_RESET_PASSWORD_STATES }))
        history.push("/login")
      }
    },
    [success]
  )

  const validateToNextPassword = (rule, value, callback) => {
    if (value) {
      form.validateFields(["confirm"], { force: true })
    }
    callback();
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Password confirm is inconsistent");
    } else {
      callback();
    }
  }

  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh", backgroundColor: "#fff" }}
      >
        <Col xs={20} sm={14} md={12} lg={10} xl={7}>
          <Card>
            <h3>Check your email for the token to reset your password, if you not have received email, request a new one</h3>
            <Form onSubmit={handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator("reset_token", {
                  rules: [
                    { required: true, message: 'Please input the reset token!' }
                  ]
                }
                )(
                  <TextArea placeholder="Input reset token from email" disabled={loading} />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Please input your new password!' },
                    {
                      validator: validateToNextPassword
                    }
                  ]
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="New password"
                  />,
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password2', {
                  rules: [
                    { required: true, message: 'Please input your password confirmation!' },
                    {
                      validator: compareToFirstPassword
                    }
                  ]
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password confirmation"
                  />,
                )}
              </FormItem>
              <FormItem style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Change password
          </Button>
              </FormItem>
            </Form>
            <div>You did not recieved a token?, then request one <span> </span>
              <Link to="forgot_password">
                <a
                  type="link"
                  disabled={loading}
                >
                  here
              </a>
              </Link>
              !
            </div>
            <Row
              type="flex"
              justify="center"
              align="middle">
              <Link to="/login">
                <Button
                  type="default"
                  style={{ width: "100%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Cancel
          </Button>
              </Link>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const WrappedUserResetPasswordForm = Form.create()(UserResetPasswordForm);

export default withRouter(WrappedUserResetPasswordForm)