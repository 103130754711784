import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchIlluminance = (tenant_uuid, uuid, group_type ) => action(actions.FETCH_ILLUMINANCE, {tenant_uuid, uuid, group_type})

export const updateIlluminance = (tenant_uuid, uuid, form, group_type ) => action(actions.UPDATE_ILLUMINANCE, {tenant_uuid, uuid, form, group_type})

export const successFetchUpdateIlluminance = data => action(actions.SUCCESS_FETCH_UPDATE_ILLUMINANCE, { data })

export const errorFetchUpdateIlluminance = error => action(actions.ERROR_FETCH_UPDATE_ILLUMINANCE, { error })

export const cleanIlluminanceError = () => action(actions.CLEAN_ILLUMINANCE_ERROR)

export const cleanIlluminancelState = () => action(actions.CLEAN_ILLUMINANCE_STATE)