import { action } from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchUser2faQr = ( tenant_uuid, uuid, isLogin) => action(actions.FETCH_USER_2FA_QR, { tenant_uuid, uuid, isLogin })

export const successFetchUser2faQr = data => action(actions.SUCCESS_FETCH_USER_2FA_QR, { data })

export const errorFetchUser2faQr = error => action(actions.ERROR_FETCH_USER_2FA_QR, { error })

export const fetchUserProfile = ( tenant_uuid, uuid ) => action(actions.FETCH_USER_PROFILE, { tenant_uuid, uuid })

export const successFetchUserProfile = ( data ) => action(actions.SUCCESS_FETCH_USER_PROFILE, { data })

export const updateUserProfile = ( tenant_uuid, uuid, form ) => action(actions.UPDATE_USER_PROFILE, { tenant_uuid, uuid, form })

export const successUpdateUserProfile = ( data ) => action(actions.SUCCESS_UPDATE_USER_PROFILE, { data })

export const errorFetchUpdateUserProfile = error => action(actions.ERROR_FETCH_UPDATE_USER_PROFILE, { error })

export const cleanUserProfileState = () => action(actions.CLEAN_USER_PROFILE_STATE)