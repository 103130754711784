import React from "react";
import "./style.css";
import CenteredRow from "../CenteredRow";
import StyledIcon from "../StyledIcon";
import colors from "../../styles";

const { primary_color_hover } = colors;

const Loader = () => {
  return (
    <CenteredRow>
      <StyledIcon type="loading" color={primary_color_hover} scope="loader" />
    </CenteredRow>
  );
};

export default Loader;
