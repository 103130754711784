import React, { useState, useEffect } from "react";
import { Slider, Row, Col, DatePicker } from "antd";
import moment from 'moment'
import StyledIcon from "../StyledIcon";
import colors from "../../styles";
import "./style.css";

const { HEATMAP_RADIUS_UV_CONTROL } = window.Configs;
const { primary_color_hover } = colors;

const FrecuencyMap = ({
  dataLoading,
  selectDay,
  config,
  thedata,
  parentBubble,
  onRadiusChange
}) => {
  const [bubble, setBubble] = useState(parentBubble)
  const [date, setDate] = useState(config.day)
  const [loading, setLoading] = useState(false)

  //  const [marksRadius, setMarksRadius] = useState({ '5': 5, '180': 180 })



  useEffect(() => {
    setBubble(parentBubble);
  }, [parentBubble])

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading])

  const onDateChangeDate = param => {
    let stringdate = ""
    if (param) {
      stringdate = param._d.getFullYear() + "-" + (Number(param._d.getMonth()) + 1) + "-" + (Number(param._d.getDate()) > 9 ? param._d.getDate() : "0" + param._d.getDate());
    } else {
      stringdate = moment().format("YYYY-MM-DD")
    }
    setDate(stringdate);
    selectDay(stringdate);
  };

  const render = () => {
    //const { stat, date } = this.state;
    return (
      <>
        {loading ? (
          <div className="loader">
            <StyledIcon type="loading" color={primary_color_hover} scope="loader" />
          </div>

        ) : (
            <div className="frecuencycontrol">
              <Row style={{ padding: "10px 0px" }}>
                <Col span={12}>
                  <span style={{ marginRight: "10px" }}>Date:</span>
                  <DatePicker format="YYYY-MM-DD" defaultValue={moment(date, "YYYY-MM-DD")} onChange={onDateChangeDate} />
                </Col>

                <Col span={12}>
                  <Row style={{margin:"10px 0px"}}>
                    <Col span={8} style={{ textAlign: "center", marginTop: "-5px" }}>
                      Bubble Size:
                    </Col>
                    <Col span={16}>
                      <Slider
                        min={5}
                        max={2 * HEATMAP_RADIUS_UV_CONTROL}
                        //marks={marksRadius}
                        onAfterChange={onRadiusChange}
                        step={5}
                        defaultValue={bubble}
                        //className={getClassNotActiveStep()}
                        style={{ marginTop: "0px", marginBottom: "10px", marginRight: "20px" }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

            </div>
          )}
      </>
    )
  }

  return (
    render()
  )
}

export default FrecuencyMap;
