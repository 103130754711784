import React from "react";
import { IntlProvider } from "react-intl";
// import { IntlProvider, addLocaleData } from "react-intl";
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../constants'


// import intlEN from "react-intl/locale-data/en";
import messages_en from "./../../i18n/en.json";

// addLocaleData([...intlEN]);
const messages = {
  en: messages_en
}

const IntlWrapper = props => {
  const { children } = props

  const lang = useSelector((state) => state.rootReducer.auth.lang)
  const location = useSelector((state) => state.router.location)

  const dispatch = useDispatch()

  let langParam = "";
  langParam += location.pathname[1];
  langParam += location.pathname[2];
  if (langParam === "en" || langParam === "es") {
    dispatch(({ type: actions.CHANGE_LANGUAGE, payload: { language: langParam } }))
  } else {
    //dispatch(({ type: actions.CHANGE_LANGUAGE, payload: { language: lang } }))
  }

  return (
    <IntlProvider key={lang} locale={lang} messages={messages[lang]}>
      {children}
    </IntlProvider>
  )
}

export default IntlWrapper
