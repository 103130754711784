import moment from 'moment'

export const getStudiedFromSensorData = (sensorData, sensorName, start, end) => {
  let sum = 0
  let count = 0
  let empty = true;
  const sensorDataLength = sensorData.length
  for (let index = 0; index < sensorDataLength; index++) {
    // # This is for every sensor in the device
    const sensor = sensorData[index]
    const sensorCreatedDate = moment.utc(sensor.created, 'YYYY-MM-DD HH:mm:ss')
    //console.log('Start ->', format_start, index)
    //console.log('End ->', format_end , index)
    //console.log('Created ->', sensor.created, index)
    if (sensorCreatedDate.diff(start) >= 0 && end.diff(sensorCreatedDate) > 0) {
      try {
        empty = false
        if (sensorName !== 'power') {
          if (sensor.sensor_reading && sensor.sensor_reading.length) {
            const reading = sensor.sensor_reading
            let tmp =
              reading.includes(',') || reading.includes('.')
                ? parseFloat(reading)
                : parseInt(reading)

            if (sensorName === 'pressure') {
              tmp = tmp / 100
            }

            if (tmp >= 0) {
              sum += tmp
              count++
            }
          }
        } else {
          // * For power sensor
          sum += parseFloat(sensor.sensor_reading)/3600
        }
      } catch (err) {
        return { sum, count, empty }
      }
    }
  }
  return { sum, count, empty }
}

export const getStudiedFromAggregatedData = (deviceData, hour) => {
  let sum = 0
  const deviceDataLength = deviceData.length
  for (let index = 0; index < deviceDataLength; index++) {
    // # This is for every sensor in the device
    const device = deviceData[index]
    for (let consumption_index = 0; consumption_index < device.consumption_data.length; consumption_index++) {
      const deviceConsumptioDate = moment.utc(device.consumption_data[consumption_index].interval_start_datetime, 'YYYY-MM-DD HH:mm:ss')
      if (deviceConsumptioDate.diff(hour) == 0) {
        try {
          sum += device.consumption_data[consumption_index].energy_consumption
        } catch (err) {
          return sum
        }
      }
    }
  }
  return sum
}