import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchChartData = (tenant_uuid, uuid, sensor_type, request_time, step_time ) => action(actions.FETCH_CHART_DATA, {tenant_uuid, uuid, sensor_type, request_time, step_time})

export const successFetchChartData = (data, requestTime, stepTime, start, end, startLocal, endLocal, currentTime, sensor_type) => action(actions.SUCCESS_FETCH_CHART_DATA, { data, requestTime, stepTime, start, end, startLocal, endLocal, currentTime, sensor_type })

export const errorFetchChartData = error => action(actions.ERROR_FETCH_CHART_DATA, { error })

export const cleanChartDataError = () => action(actions.CLEAN_CHART_DATA_ERROR)

export const cleanChartDataState = () => action(actions.CLEAN_CHART_DATA_STATE)