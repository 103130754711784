import React, { useEffect } from "react"
import { Form, Input, Button, message, Card, Icon, Row, Col } from "antd"
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link } from "react-router-dom"

import "./styles.css"

import * as actions from '../../constants'
import colors from "../../styles";
const { darkIconColor } = colors;

const FormItem = Form.Item;

const UserForgotPasswordForm = ({
  form,
  history }) => {
  const loading = useSelector((state) => state.rootReducer.loaders.loadingRequestUserPasswordReset)
  const error = useSelector((state) => state.rootReducer.userPasswordReset.errorRequestPasswordReset)
  const success = useSelector((state) => state.rootReducer.userPasswordReset.succesRequestUserPasswordReset)
  const dispatch = useDispatch()

  const { getFieldDecorator, validateFields, resetFields } = form;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { email } = values
        dispatch(({ type: actions.REQUEST_USER_PASSWORD_RESET, payload: { username: email } }))
      } else {
        message.error(err);
      }
    })
  }

  useEffect(
    () => {
      if (error !== null) {
        message.error(error.message)
        dispatch(({ type: actions.CLEAN_USER_RESET_PASSWORD_STATES }))
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (success) {
        resetFields()
        message.success("Request successfully. Check email for reset token")
        dispatch(({ type: actions.CLEAN_USER_RESET_PASSWORD_STATES }))
        history.push("/reset_password")
      }
    },
    [success]
  )

  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh", backgroundColor: "#fff" }}
      >
        <Col xs={20} sm={14} md={12} lg={10} xl={7}>
          <Card>
            <h3>Input your user email to request a password reset:</h3>
            <Form onSubmit={handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email!' }],
                })(
                  <Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                  />,
                )}
              </FormItem>
              <FormItem style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Request password reset
          </Button>
              </FormItem>
            </Form>
            <div>Already had a token?, then<span> </span>
              <Link to="/reset_password">
                <a
                  type="link"
                  disabled={loading}
                >
                  click here!
              </a>
              </Link>
            </div>
            <Row
              type="flex"
              justify="center"
              align="middle">
              <Link to="/login">
                <Button
                  type="default"
                  style={{ width: "100%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Cancel
          </Button>
              </Link>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const WrappedUserForgotPasswordForm = Form.create()(UserForgotPasswordForm);

export default withRouter(WrappedUserForgotPasswordForm)