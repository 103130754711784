import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Menu, Dropdown, Tooltip, Icon, Modal, Notification, Button } from "antd";
import "./style.css";
import localStorageUtil from "../../utils/localStorageUtil";
import { setAuthToken } from "../../services";
import { withRouter } from "react-router-dom";
import StyledIcon from "../StyledIcon";
import colors from "../../styles";
import * as actions from '../../constants';
import * as error_codes from '../../constants/error_codes'
import NotificationUser from "../NotificationUser";
import UserProfileForm from "../UserProfileForm"
import {isModuleNotificatoinActive} from '../../utils/helpers';

const { lightIconColor, danger } = colors;

const Navbar = ({ history, children }) => {

  const dispatch = useDispatch()
  const appDeployed = parseInt(process.env.REACT_APP_SET_ADMIN_API)
  const username = useSelector((state) => state.rootReducer.auth.username)
  const user_modules = useSelector(state => state.rootReducer.auth.modules)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false)

  const [notificationToast, setNotificationToast] = useState([])


  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const notificationToastReducer = useSelector((state) => state.rootReducer.notificationUser.notificationToast)
  const error = useSelector((state) => state.rootReducer.notificationUser.errorToast)

  const [lenToast, setLenToast] = useState(0);
  const [isShowToast, setIsShowToast] = useState(false);

  const notificationAllData = useSelector((state) => state.rootReducer.notificationUser.notificationData.all)
  const [countNotification, setCountNotification] = useState(0);

  //notifications
  const executeNotifications = () => {
    if (notificationToast && notificationToast.length > 0) {
      setIsShowToast(true)
      openNotification(notificationToast[notificationToast.length - 1])
    } else {
      setIsShowToast(false)
    }
  }

  useEffect(() => {
    setNotificationToast(notificationToastReducer)
  }, [notificationToastReducer]);

  useEffect(() => {
    if (isShowToast === false) {
      executeNotifications();
    }
  }, [isShowToast]);

  useEffect(() => {
    dispatch(({
      type: actions.FETCH_USER_NOTIFICATION,
      payload: { tenant_uuid: tenant_uuid, transport: "all" }
    }))
  },
    [])

  useEffect(() => {
    //count Notifiaction
    if (notificationAllData && notificationAllData.length > 0) {
      const notReadNotification = notificationAllData.filter((item) => {
        if (item.notification_receipts.length === 0) {
          return true;
        } else if (item.notification_receipts.length > 0) {
          let is_read = true;
          item.notification_receipts.forEach(element => {
            if (element && element.receipt && element.receipt.username === username && element.is_read) {
              is_read = false;
            }
          });
          return is_read;
        } else {
          return false;
        }
      })
      setCountNotification(notReadNotification.length);
    } else {
      setCountNotification(0);
    }
  }, [notificationAllData]);


  const openNotification = async (toast) => {
    if (toast) {

      await setIsShowToast(true)

      let config = {
        key: toast.uuid,
        message: 'TOAST NOTIFICATION',
        description: toast.message,
        duration: 5,
        placement: "topLeft",
        //icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        onClose: () => { setIsShowToast(false) },
        className: "toast-notification-show",
      };

      if (toast.requires_confirmation) {
        config = {
          ...config,
          btn: (
            <Button type="primary" size="small" onClick={() => {
              Notification.close(toast.uuid)
              dispatch(({
                type: actions.UPDATE_TOAST_NOTIFICATION,
                payload: { tenant_uuid: tenant_uuid, notification_uuid: toast.uuid, form: { is_show: true, is_confirmed: true }, update_detail: false, call: "toast" }
              }))

            }}>
              Confirm
            </Button>
          )
        };
      }

      switch (toast.message_type) {
        case 2: //error
          await Notification.error(config);
          break;
        case 3: //warning
          await Notification.warning(config);
          break;
        case 4: //alarm
          await Notification.success({
            ...config,
            "icon": <Icon type="bell" />
          });
          break;
        default:
          await Notification.info(config);
          break;
      }

      //llamar api para poner notification como vista
      await dispatch(({
        type: actions.UPDATE_TOAST_NOTIFICATION,
        payload: { tenant_uuid: tenant_uuid, notification_uuid: toast.uuid, form: { is_show: true } }
      }))

      await dispatch(({
        type: actions.REMOVE_TOAST_NOTIFICATION,
        payload: { uuid: toast.uuid }
      }))
    }
  };

  useEffect(() => {
    if (lenToast < notificationToast.length) {
      executeNotifications();
    }
    setLenToast(notificationToast.length)
  }, [notificationToast]);



  useEffect(() => {
    dispatch(({
      type: actions.FETCH_NEW_NOTIFICATION,
      payload: { tenant_uuid: tenant_uuid }
    }))
    const interval = setInterval(_ => {
      //api call
      dispatch(({
        type: actions.FETCH_NEW_NOTIFICATION,
        payload: { tenant_uuid: tenant_uuid }
      }))

      if (isShowToast) {

      } else {
        executeNotifications();
      }
    }, 60000);
    return _ => clearInterval(interval);
  }, []);

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          /*
          Modal.error({
              destroyOnClose: true,
              title: error.message,
              okText: "Retry",
              onOk: () => {
                  if (receipt_send === constant.SEND) {
                      dispatch(({
                          type: actions.FETCH_USER_SEND_NOTIFICATION,
                          payload: { tenant_uuid: tenant_uuid, transport: transport }
                      }))
                  } else {
                      dispatch(({
                          type: actions.FETCH_USER_NOTIFICATION,
                          payload: { tenant_uuid: tenant_uuid, transport: transport }
                      }))
                  }
              },
          })
          */
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_USER_NOTIFICATION_ERROR })
        }
      }
    },
    [error]
  )



  const onLogOut = () => {
    localStorageUtil.clearToken();
    setAuthToken(null);
    history.push("/login");
    dispatch({ type: actions.LOG_OUT })
  };

  const settingMenu = (
    <Menu theme="light">
      {
        //<Menu.Item key="0">Setting 1</Menu.Item>
      }
      {
        <Menu.Item disabled={true} key="1" style={{ color: "black" }}>User: {username}</Menu.Item>
      }
      {isModuleNotificatoinActive(user_modules, "notification") &&
        //onClick={showUserPanel} <StyledIcon type="message" scope="setting" />
        <Menu.Item
          key="2"
          onClick={() => {
            setIsModalVisible(true)
          }}
          style={{ color: "black" }}
        >Notifications</Menu.Item>
      }
      <Menu.Item
        key="user_profile_menu"
        onClick={() => {
          setIsProfileModalVisible(true)
        }}
        style={{ color: "black" }}
      >Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="log_out">
        <span style={{ color: danger }} onClick={onLogOut}>
          <StyledIcon type="logout" color={danger} scope="setting" /> Log Out
        </span>
      </Menu.Item>
    </Menu>
  );

  //<Badge count={5}>
  return (
    <Row type="flex" justify="space-between" style={{ height: "100%", width: "100%" }}>
      <Col style={{ marginLeft: "4px" }}>
        <h2 style={{ color: lightIconColor }}>{children}</h2>
      </Col>


      <Col>
        <h3 style={{ color: lightIconColor }}><b>Admin App {(appDeployed === 1 || appDeployed === 2) && (<span>(<Icon type="bug" /> DEV)</span>)}</b></h3>
      </Col>


      <Col>
        <span>
          <Dropdown overlay={settingMenu} trigger={["click"]}>
            <Tooltip placement="left" title="User Settings">
              <span className="icon-user-nitification">

                <StyledIcon
                  type="user"
                  color={lightIconColor}
                  scope="navbar"
                />
                {isModuleNotificatoinActive(user_modules, "notification") &&
                  <span className="icon-badge">{countNotification}</span>
                }
              </span>
            </Tooltip>
          </Dropdown>
        </span>
      </Col>

      <Modal
        title={
          <span>
            Notifications
                </span>
        }
        width="60vw"
        maskClosable={true}
        style={{ top: "2em" }}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >

        {
          <NotificationUser
            visible={isModalVisible}
          />
        }
      </Modal>

      <Modal
        title={
          <span>
            User Profile
          </span>
        }
        width="60vw"
        maskClosable={true}
        destroyOnClose={true}
        style={{ top: "2em" }}
        visible={isProfileModalVisible}
        onCancel={() => setIsProfileModalVisible(false)}
        footer={null}
      >

        {
          <UserProfileForm 
            setIsModalVisible={setIsProfileModalVisible} 
            isModalVisible={isProfileModalVisible} />
        }
      </Modal>
    </Row>
  );
};

export default withRouter(Navbar);
