import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Row, Col, Notification, Alert, Icon } from 'antd'
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";
import { UserContext } from "../../context";
import * as actions from '../../constants';
import moment from 'moment';
import './styles.css';
import { getMessageIconType, getMessageType, getNotificationType } from "../../utils/helpers";
import * as constant from '../../constants/notifications';


const NotificationDetail = ({
    history,
    call,
    receipt_send,
    transport,
    notification,
    deselectNotification
}) => {
    const {
        userData: { tenant_uuid }
    } = useContext(UserContext);

    const [loading,] = useState(false);

    const username = useSelector((state) => state.rootReducer.auth.username)
    const dispatch = useDispatch();

    useEffect(() => {
        if (call === "user" && receipt_send==="receipt") {
            if (notification && notification.uuid) {
                let update = true;
                if (notification.notification_receipts && notification.notification_receipts.length > 0) {
                    notification.notification_receipts.forEach(element => {
                        if (element.receipt.username === username && element.is_read === true) {
                            update = false
                        }
                    });

                }
                if (update) {
                    dispatch(({
                        type: actions.UPDATE_TOAST_NOTIFICATION,
                        payload: { tenant_uuid: tenant_uuid, notification_uuid: notification.uuid, form: { is_read: true }, update_detail: true, call: call }
                    }))
                }
            }
        }
    }, [notification]);

    const backFunction = () => {
        deselectNotification();
    }

    const getTime = (param_date) => {
        const strdate = param_date.replace("T", " ");
        //convert local
        let local = moment.utc(strdate).local().format("D MMM. YYYY H:mm");
        return local;
    }

    const showDescription = (notification) => {
        let user_receipt = {}
        if (notification.notification_receipts && notification.notification_receipts.length > 0) {
            const user_receipt_filter = notification.notification_receipts.filter((item) => { return item.receipt.username === username })
            user_receipt = user_receipt_filter[0]
        }

        return (

            <>
                {notification.transport === constant.INDEX_EMAIL ? <Col span={24}><span className="subject">{notification.subject}</span></Col> : <></>}
                <Col span={24}><span className="message">{notification.message}</span></Col>
                {notification.transport === constant.INDEX_TOAST && <>
                    <Col span={12} className="notification-detail-info"></Col>
                    <Col span={12} className="notification-detail-info" style={{ textAlign: "right", paddingRight: "0px" }}>
                        {notification.requires_confirmation ? (user_receipt && user_receipt.is_confirmed && user_receipt.is_confirmed === true ?
                            <span>Confirmed</span>
                            : call === "element" ? <span>Not confirmed</span> :
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        if (receipt_send === "receipt") {
                                            Notification.close(notification.uuid)
                                            dispatch(({
                                                type: actions.UPDATE_TOAST_NOTIFICATION,
                                                payload: { tenant_uuid: tenant_uuid, notification_uuid: notification.uuid, form: { is_confirmed: true }, update_detail: true, call: call }
                                            }))
                                        }
                                    }}>
                                    Confirm</Button>) : <></>
                        }
                    </Col>
                </>}
            </>
        )
    }

    return (
        loading//isLoading
            ? <Loader />
            : notification && notification.transport ?
                <>
                    <Row className="notification-detail">
                        <Row style={{ marginBottom: "10px" }}>
                            <Col span={4}><Button onClick={() => backFunction()}><Icon type="arrow-left" />Back</Button></Col>
                            <Col span={20} style={{ textAlign: "center" }}><h3>{notification.transport === constant.INDEX_TOAST ? "Toast" : notification.transport === constant.INDEX_EMAIL ? "Mail" : notification.transport === constant.INDEX_SMS ? "SMS" : ""} Notifications</h3></Col>
                        </Row>
                        <Row >
                            <Card style={{ width: "100%" }}>
                                <Col span={12} className="notification-detail-info">Notification type: {getNotificationType(notification.notification_type)}</Col>
                                <Col span={12} className="notification-detail-info align-left">Created: <span className="date">{getTime(notification.created_date)}</span></Col>
                                <Col span={12} className="notification-detail-info">Sent by: {notification.automatic ? "SYSTEM" : notification.sender.username}</Col>
                                <Col span={12} className="notification-detail-info align-left">Send: <span className="date">{getTime(notification.send_date)}</span></Col>
                                <Col span={24} className="notification-detail-info detail">
                                    {notification.message_type === constant.ALARM ?
                                        <Alert
                                            message={getMessageType(notification.message_type)}
                                            description={showDescription(notification)}
                                            type={getMessageIconType(notification.message_type)}
                                            showIcon
                                            icon={<Icon type="bell" />}
                                        /> :
                                        <Alert
                                            message={getMessageType(notification.message_type)}
                                            description={showDescription(notification)}
                                            type={getMessageIconType(notification.message_type)}
                                            showIcon
                                        />
                                    }
                                </Col>
                                <Col span={24} className="notification-detail-info">Escalation time (minutes): {notification.escalation_time}</Col>
                                <Col span={24} className="notification-detail-info user-receivers">
                                    Receipts
                                </Col>
                                <Col span={24} className="notification-detail-info">{notification.notification_receipts && notification.notification_receipts.length && notification.notification_receipts.length > 0 && notification.notification_receipts.map((item, index) => (
                                    <Row key={"user-item-" + index} >
                                        <Col span={24}>
                                            {item.receipt.username}
                                        </Col>

                                    </Row>
                                ))}</Col>



                            </Card>
                        </Row>
                    </Row>
                </> : <></>
    )
}

export default withRouter(NotificationDetail)

/*
uuid = fields.String(required=True)
    created_date = fields.DateTime(required=True)
    sender_date = fields.DateTime(required=True)
    automatic = fields.Boolean(required=True)
    notification_type = fields.Integer(required=True)
    message_type = fields.Integer(required=True)
    message = fields.String(required=True)
    company = fields.Nested(SimpleGroupSchema)
    building = fields.Nested(SimpleBuildingSchema)
    group = fields.Nested(SimpleCompanySchema)
    notification_toast_receipts = fields.Nested(NotificationToastReciberSchema, many=True)
    sender = fields.Nested(UserNameSchema)

*/