import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, message, Notification, Empty,
} from "antd";
import InnerHTML from 'dangerously-set-html-content';
import moment from 'moment';
import Loader from "../Loader";
import { UserContext } from "../../context";
import "./style.css";

import { withRouter } from "react-router-dom";
import { fetchEnergyAnalysisApi } from '../../api/energyAnalysisApi';

import * as actions from '../../constants';
import * as error_codes from '../../constants/error_codes';

const { unzip } = require('zlib');


const ElementDetailEnergyAiChartTab = ({
  history,
  element,
  element_uuid,
  tabKey
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [contentPage, setContentPage] = useState("");

  const { userData: { tenant_uuid } } = useContext(UserContext);

  const token = useSelector((state) => state.rootReducer.auth.accessToken)

  const dispatch = useDispatch()

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              main();

            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          setError(null);
        }
      }
    },
    [error]
  )

  //------------------------
  function base64ToBuffer(str) {
    str = window.atob(str); // creates a ASCII string
    var buffer = new ArrayBuffer(str.length),
      view = new Uint8Array(buffer);
    for (var i = 0; i < str.length; i++) {
      view[i] = str.charCodeAt(i);
    }
    return buffer;
  }

  async function main() {
    try {
      setLoading(true);

      let element_type = "group"
      if (element.group_type) {
        if (element.group_type === "Company") {
          element_type = "company"
        } else if (element.group_type === "Building") {
          element_type = "building"
        } else {
          element_type = "group"
        }
      }

      const data = await fetchEnergyAnalysisApi(tenant_uuid, element_type, element_uuid);
      if (data && data.graph !== "") {
        const strbase64 = data.graph;
        const bufferbase64 = base64ToBuffer(strbase64.toString());
        const bufferstr = Buffer.from(bufferbase64, 'base64');
        unzip(bufferstr, (err, buffer) => {
          if (err) {
            console.error('An error occurred:', err);
            process.exitCode = 1;
            setError(err);
            setLoading(false);
          } else {
            setContentPage(buffer.toString());
            setLoading(false);
          }
        });
      } else {
        setContentPage("");
        setLoading(false);
      }
    } catch (err) {
      let error = {}

      if (err.message.includes("401")) {
        error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
      } else if (err.message.includes("403")) {
        error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
      } else if (err.message.includes("404")) {
        const error_code = err.response.data.error_code
        if (error_code === error_codes.ERR_INVALID_GROUP) {
          error = { "message": "Group was deleted!", "error_code": error_code }
        }
      } else if (err.message.includes("Network Error")) {
        error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
      }
      setLoading(false);
      setError(error);
    }

  }

  //----------------------------
  useEffect(() => {
    if (tabKey === "energy_analysis") {
      ; (async () => {
        main();
      })()
    }

  },
    [tabKey, element_uuid])//,element

  const render = () => {
    const content = (contentPage && contentPage !== "" ? (
      <>
        <InnerHTML html={contentPage} />
      </>
    ) : (
        <>
          <div style={{textAlign: "center"}}>No data to perform energy analysis</div>
          <div style={{ marginTop: '5%' }}><Empty /></div>
        </>
      )
    );

    return (
      loading //isLoading
        ? <Loader />
        :
        <>
          {content}
        </>
    );
  }

  return (
    render()
  )
}

export default withRouter(ElementDetailEnergyAiChartTab);
