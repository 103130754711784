import {
  Modal,
  message,
  Notification,
} from "antd";
import React from "react";
import { getGroupImageMap, getGroupDevicesLocation, getGroupExitLocation, getGroupHistoricSensorDataService, getDeviceHistoricSensorDataService, putGroupDevicesLocation, putGroupExitLocation, getDeviceHistoricHighPrecisionSensorDataService, getDeviceHistoricUVSensorDataService, getVectorDataAnalysisRequestService, createVectorDataAnalysisRequestService, getVectorDataAnalysisStatusRequestService, getDeviceGraphService } from "../../services";
import moment from 'moment';
import * as actions from '../../constants';
const requestTime = 1;
const { HEATMAP_RANGE, HEATMAP_RANGE_PA, HEATMAP_RANGE_UV_CONTROL, HEATMAP_RANGE_PA_VECTORS,
  ANIMATION_TRANSITION_FRAME_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, ANIMATION_WAKE_REDUCTION_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP } = window.Configs;

//update in db device location
export const putGroupMetadataDevice = async (history, dispatch, tenant_uuid, element_uuid, devices) => {
  //message.error("You are not authorized to perform this action");

  try {
    const response = await putGroupDevicesLocation(tenant_uuid, element_uuid, { data: devices })
    if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
      const data = await response.data;
      if (devices.length > 0) {
        message.success("Successfully Updated Device(s) Location")
      }
      return data;
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
    } else {
      if (err.message.includes("Network Error")) {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => putGroupMetadataDevice(history, dispatch, tenant_uuid, element_uuid, devices),
        })
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => putGroupMetadataDevice(),
          onOk: () => window.location.reload(),
        })
      }
    }
  }
}


//update in db exit location
export const putGroupMetadataExit = async (history, dispatch, tenant_uuid, element_uuid, exits) => {
  //message.error("You are not authorized to perform this action");

  try {
    const response = await putGroupExitLocation(tenant_uuid, element_uuid, { data: exits })
    if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
      const data = await response.data;
      //if (exits.length > 0) {
      message.success("Successfully Updated Exit(s) Location")
      //}
      return data;
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
    } else {
      if (err.message.includes("Network Error")) {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => putGroupMetadataDevice(history, dispatch, tenant_uuid, element_uuid, exits),
        })
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => putGroupMetadataDevice(),
          onOk: () => window.location.reload(),
        })
      }
    }
  }
}

export const getGroupMetadataImage = async (history, dispatch, tenant_uuid, element_uuid) => {
  try {
    const response = await getGroupImageMap(tenant_uuid, element_uuid)
    // if (window.ccancel) {
    //    return;
    //}
    if (response && response.status === 200) {
      const data = await response.data;
      return { "data": data.image, "error": false, "message": "" };
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "" };
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "" };
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
      return { "data": "", "error": true, "message": "Group was deleted!" };
    } else {
      if (err.message.includes("Network Error")) {
        /*
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getGroupMetadataImage(history, dispatch, tenant_uuid, element_uuid),
        })
        */
        return { "data": "", "error": true, "message": "Connection is lost!" };
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => getGroupMetadataImage(),
          onOk: () => window.location.reload(),
        })
      }
      return { "data": "", "error": true, "message": err.message };
    }
  }
  return { "data": "", "error": true, "message": "" };
}

//obtain in db the device location
export const getGroupMetadataDevice = async (history, dispatch, tenant_uuid, element_uuid) => {
  try {
    const response = await getGroupDevicesLocation(tenant_uuid, element_uuid)
    //if (window.ccancel) {
    //  return;
    //}
    if (response && response.status === 200) {
      const data = await response.data;
      return { "data": data, "error": false, "message": "" };
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You are not authorized to perform this action" };
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You have not permissions to perform this action" };
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
      return { "data": "", "error": true, "message": "Group was deleted!" };
    } else {
      if (err.message.includes("Network Error")) {
        /*
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getGroupMetadataDevice(history, dispatch, tenant_uuid, element_uuid),
        })
        */
        return { "data": "", "error": true, "message": "Connection is lost!" };
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => getGroupMetadataDevice(history, tenant_uuid, element_uuid),
          onOk: () => window.location.reload(),
        })
      }
      return { "data": "", "error": true, "message": err.message };
    }
  }
  return { "data": "", "error": true, "message": "" };
}


//obtain in db the exit location
export const getGroupMetadataExits = async (history, dispatch, tenant_uuid, element_uuid) => {
  try {
    const response = await getGroupExitLocation(tenant_uuid, element_uuid)
    //if (window.ccancel) {
    //  return;
    //}
    if (response && response.status === 200) {
      const data = await response.data;
      return { "data": data, "error": false, "message": "" };
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You are not authorized to perform this action" };
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You have not permissions to perform this action" };
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
      return { "data": "", "error": true, "message": "Group was deleted!" };
    } else {
      if (err.message.includes("Network Error")) {
        /*
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getGroupMetadataDevice(history, dispatch, tenant_uuid, element_uuid),
        })
        */
        return { "data": "", "error": true, "message": "Connection is lost!" };
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => getGroupMetadataDevice(history, tenant_uuid, element_uuid),
          onOk: () => window.location.reload(),
        })
      }
      return { "data": "", "error": true, "message": err.message };
    }
  }
  return { "data": "", "error": true, "message": "" };
}

//obtain in db the device location
export const getGroupDeviceGraph = async (history, dispatch, tenant_uuid, element_uuid) => {
  try {
    const response = await getDeviceGraphService(tenant_uuid, element_uuid)
    //if (window.ccancel) {
    //  return;
    //}
    if (response && response.status === 200) {
      const data = await response.data;
      return { "data": data, "error": false, "message": "" };
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You are not authorized to perform this action" };
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You have not permissions to perform this action" };
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
      return { "data": "", "error": true, "message": "Group was deleted!" };
    } else {
      if (err.message.includes("Network Error")) {
        /*
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getGroupMetadataDevice(history, dispatch, tenant_uuid, element_uuid),
        })
        */
        return { "data": "", "error": true, "message": "Connection is lost!" };
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => getGroupMetadataDevice(history, tenant_uuid, element_uuid),
          onOk: () => window.location.reload(),
        })
      }
      return { "data": "", "error": true, "message": err.message };
    }
  }
  return { "data": "", "error": true, "message": "" };
}


//obtain in db all sensor data reading
export const getChartSensorData = async (
  history,
  tenant_uuid,
  theconfig,
  startDate,
  endDate
) => {
  try {
    const response = await getGroupHistoricSensorDataService(tenant_uuid, theconfig.element_uuid, theconfig.sensorName, startDate, endDate)
    if (response && response.status === 200) {
      const data = await response.data;
      return data
    }

  } catch (err) {
  }
}

export const getChartSensorDataDevice = async (
  history,
  tenant_uuid,
  device,
  theconfig,
  startDate,
  endDate
) => {
  try {
    const response = await getDeviceHistoricSensorDataService(tenant_uuid, theconfig.element_uuid, device.aws_id, theconfig.sensorName, startDate, endDate)
    if (response && response.status === 200) {
      const data = await response.data;
      return data
    }

  } catch (err) {
    if (err.message.includes("401")) {
      return err
    } else if (err.message.includes("403")) {
      return err;
    } else if (err.message.includes("404")) {
      return err;
    } else {
      return err;
    }
  }

}


export const getChartHighPrecisionSensorDataDevice = async (
  history,
  tenant_uuid,
  device,
  theconfig,
  startDate,
  endDate
) => {
  try {
    const response = await getDeviceHistoricHighPrecisionSensorDataService(tenant_uuid, theconfig.element_uuid, device.aws_id, theconfig.sensorName, startDate, endDate)
    if (response && response.status === 200) {
      const data = await response.data;
      return data
    }

  } catch (err) {
    if (err.message.includes("401")) {
      return err
    } else if (err.message.includes("403")) {
      return err;
    } else if (err.message.includes("404")) {
      return err;
    } else {
      return err;
    }
  }

}



export const getChartUVSensorDataDevice = async (
  history,
  tenant_uuid,
  device,
  theconfig,
  startDate,
  endDate
) => {
  try {
    const response = await getDeviceHistoricUVSensorDataService(tenant_uuid, theconfig.element_uuid, device.aws_id, startDate, endDate)
    if (response && response.status === 200) {
      const data = await response.data;
      return data
    }

  } catch (err) {
    if (err.message.includes("401")) {
      return err
    } else if (err.message.includes("403")) {
      return err;
    } else if (err.message.includes("404")) {
      return err;
    } else {
      return err;
    }
  }

}


export const getVectorDataAnalysisRequest = async (
  history,
  tenant_uuid,
  element_type,
  element_uuid,
  analysis_type
) => {
  try {
    const response = await getVectorDataAnalysisRequestService(tenant_uuid, element_type, element_uuid, analysis_type)
    if (response && response.status === 200) {
      const data = await response.data;
      return data
    }

  } catch (err) {
    if (err.message.includes("401")) {
      return err
    } else if (err.message.includes("403")) {
      return err;
    } else if (err.message.includes("404")) {
      return err;
    } else {
      return err;
    }
  }

}

export const createVectorDataAnalysisRequest = async (
  history,
  dispatch,
  tenant_uuid,
  element_type,
  element_uuid,
  analysis_type, form
) => {
  try {
    const response = await createVectorDataAnalysisRequestService(tenant_uuid, element_type, element_uuid, analysis_type, form)

    if (response && response.status === 201) {
      const data = await response.data;
      return { "data": data, "error": false, "message": "" };
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You are not authorized to perform this action" };
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You have not permissions to perform this action" };
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
      return { "data": "", "error": true, "message": "Group was deleted!" };
    } else {
      if (err.message.includes("Network Error")) {
        /*
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getGroupMetadataDevice(history, dispatch, tenant_uuid, element_uuid),
        })
        */
        return { "data": "", "error": true, "message": "Connection is lost!" };
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => getGroupMetadataDevice(history, tenant_uuid, element_uuid),
          onOk: () => window.location.reload(),
        })
      }
      return { "data": "", "error": true, "message": err.message };
    }
  }
  return { "data": "", "error": true, "message": "" };
}
/*
} catch (err) {
  if (err.message.includes("401")) {
    return err
  } else if (err.message.includes("403")) {
    return err;
  } else if (err.message.includes("404")) {
    return err;
  } else {
    return err;
  }
}
*/

export const getVectorDataAnalysisStatusRequest = async (
  history,
  dispatch,
  tenant_uuid,
  data_analysis_uuid
) => {
  try {
    const response = await getVectorDataAnalysisStatusRequestService(tenant_uuid, data_analysis_uuid)
    if (response && response.status === 200) {
      const data = response.data;
      return { "data": data, "error": false, "message": "" };
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You are not authorized to perform this action" };
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "You have not permissions to perform this action" };
    } else if (err.message.includes("404")) {
      //message.error("Group was deleted!");
      Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
      return { "data": "", "error": true, "message": "Group was deleted!" };
    } else {
      if (err.message.includes("Network Error")) {
        /*
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getGroupMetadataDevice(history, dispatch, tenant_uuid, element_uuid),
        })
        */
        return { "data": "", "error": true, "message": "Connection is lost!" };
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => getGroupMetadataDevice(history, tenant_uuid, element_uuid),
          onOk: () => window.location.reload(),
        })
      }
      return { "data": "", "error": true, "message": err.message };
    }
  }
  return { "data": "", "error": true, "message": "" };
}

const proccessPromises = async (promises) => {
  let data = await Promise.all(promises)
  return data
}


//method that obtain device sensor value for render in heatmap of device
export const getDataHour = async (history, dispatch, tenant_uuid, theconfig, devices) => {
  const date = theconfig.day + "-" + theconfig.hour;
  const start = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc()
  const startLocal = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss")
  const endLocal = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").add(requestTime, 'hours')
  const end = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add(requestTime, 'hours')

  const currentTime = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add(requestTime, 'hours')
  const tmpData = []

  let data = []
  const part = 2;
  let emptydata = true;
  let countdataempty = 0;
  const promises = [];


  //---create promise---
  for (let index = 0; index < part; index++) {
    for (let i = 0; i < devices.length; i++) {
      let tmpstart = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add(index * (1 / part), 'hours').add(1, 'seconds')
      let tmpcurrentTime = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add((index + 1) * (1 / part), 'hours')
      promises.push(new Promise(resolve => {
        const interval_fetch = getChartSensorDataDevice(
          history,
          tenant_uuid,
          devices[i],
          theconfig,
          tmpstart.format('YYYYMMDDHHmmss'),
          tmpcurrentTime.format('YYYYMMDDHHmmss')
        )
        resolve(interval_fetch)
      }
      ))
    }
  }

  let request_success = []

  //---process promise---
  let deviceserror = false;
  let permissionerror = false;
  let credentialerror = false;

  const fetch_data = await proccessPromises(promises)
  for (let i = 0; i < fetch_data.length; i++) {
    if (fetch_data[i] && fetch_data[i].message) { //} && fetch_data[i].length > 0) {
      countdataempty = countdataempty + 1;
      if (fetch_data[i].message.includes("403")) {
        permissionerror = true;
      }
      if (fetch_data[i].message.includes("404")) {
        deviceserror = true;
      }
      if (fetch_data[i].message.includes("401")) {
        credentialerror = true;
      }

    } else {

      if (emptydata) {
        data.push(fetch_data[i]);
        emptydata = false;
      } else {

        let newaws = -1;
        for (let j = 0; j < data.length; j++) {
          if (data[j].aws_id === fetch_data[i].aws_id) {
            newaws = j;
            break;
          }
        }

        if (newaws === -1) {
          data.push(fetch_data[i]);
          request_success.push(1);
        } else {
          data[newaws].sensor_data = data[newaws].sensor_data.concat(fetch_data[i].sensor_data);
          request_success[newaws] = request_success[newaws] + 1;
        }
      }

    }
  }

  if (countdataempty !== 0) {
    //data = []
    if (credentialerror) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
    } else {
      let messageerror = "Error loading sensor data"
      if (deviceserror) {
        messageerror = "There are devices that have been removed"
      } else if (permissionerror) {
        messageerror = "You have not access permissions for loading sensor data"
      }
      //message.error(messageerror);
      Notification.error({ message: "Error", "description": messageerror, "duration": 0 });
    }
  }

  let current_index = -1;

  //---agroup values for hour---
  const dataLength = data.length
  while (currentTime.diff(end) >= 0) {
    let tmpDataDevice = []
    current_index = -1;
    for (let index = 0; index < devices.length; index++) {
      for (let indey = 0; indey < dataLength; indey++) {

        // # This is for every device in response data
        if (data[indey].aws_id === devices[index].aws_id) {
          current_index = index;

          const device = data[indey]

          const studiedSensor = await getStudiedFromSensorData(
            theconfig,
            device.sensor_data,
            start,
            end,
          )

          let average = 0;
          if (theconfig.sensorName === "motion") {
            average = studiedSensor.sum
          } else {
            average = studiedSensor.sum / studiedSensor.count
          }
          average = average ? average : 0
          const xvalue = devices[index].x;
          const yvalue = devices[index].y;

          let convertaverage = await convert_range(average, theconfig.sensorName, HEATMAP_RANGE);

          tmpDataDevice.push([
            xvalue,
            yvalue,
            convertaverage.toFixed(2),
            average.toFixed(2)
          ])
        }
      }

      const xvalue = devices[index].x;
      const yvalue = devices[index].y;
      if (tmpDataDevice.length - 1 < index) {
        tmpDataDevice.push([
          xvalue,
          yvalue,
          "-1",
          "0.00"
        ])
      }

      if (request_success[current_index] > 0 && request_success[current_index] < 2) {
        tmpDataDevice[current_index][2] = "-1";
        tmpDataDevice[current_index][3] = "0.00";
      }
    }

    tmpData.push({
      time: endLocal.format('HH'),
      devices: tmpDataDevice,
    })

    start.add(requestTime, 'hours')
    end.add(requestTime, 'hours')
    startLocal.add(requestTime, 'hours')
    endLocal.add(requestTime, 'hours')
  }

  return tmpData

}

//method that obtain device sensor value for render in heatmap of device
export const getDataLastHour = async (history, dispatch, tenant_uuid, theconfig, devices) => {
  const lasthour = parseFloat(theconfig.lasthour); // PANEL_HEATMAP_HOURS;
  const date = moment().subtract(lasthour, 'hours').format('YYYY-MM-DD-HH-mm-ss');
  const start = moment(date, "YYYY-MM-DD-HH-mm-ss").utc()
  const startLocal = moment(date, "YYYY-MM-DD-HH-mm-ss")
  const endLocal = moment(date, "YYYY-MM-DD-HH-mm-ss").add(requestTime * 30, 'minutes')
  const end = moment(date, "YYYY-MM-DD-HH-mm-ss").utc().add(requestTime * 30, 'minutes')

  const currentTime = moment(date, "YYYY-MM-DD-HH-mm-ss").utc().add(lasthour, 'hours')
  const tmpData = [];
  let returndevices = []

  let data = []
  //const part = 24;
  let emptydata = true;
  let countdataempty = 0;
  const promises = [];
  //---create promises---
  for (let index = 0; index < lasthour * 2; index++) {
    for (let i = 0; i < devices.length; i++) {
      let tmpstart = moment(date, "YYYY-MM-DD-HH-mm-ss").utc().add(index * 30, 'minutes').add(1, 'seconds')
      let tmpcurrentTime = moment(date, "YYYY-MM-DD-HH-mm-ss").utc().add((index + 1) * 30, 'minutes')

      promises.push(new Promise(resolve => {
        const interval_fetch = getChartSensorDataDevice(
          history,
          tenant_uuid,
          devices[i],
          theconfig,
          tmpstart.format('YYYYMMDDHHmmss'),
          tmpcurrentTime.format('YYYYMMDDHHmmss')
        )
        resolve(interval_fetch)
      }
      ))
    }
  }

  let request_success = []

  const fetch_data = await proccessPromises(promises)
  //---process promise---
  let deviceserror = false;
  let permissionerror = false;
  let credentialerror = false;

  for (let i = 0; i < fetch_data.length; i++) {
    if (fetch_data[i] && fetch_data[i].message) { //} && fetch_data[i].length > 0) {
      countdataempty = countdataempty + 1;
      if (fetch_data[i].message.includes("403")) {
        permissionerror = true;
      }
      if (fetch_data[i].message.includes("404")) {
        deviceserror = true;
      }
      if (fetch_data[i].message.includes("401")) {
        credentialerror = true;
      }

    } else {

      if (emptydata) {
        data.push(fetch_data[i]);
        emptydata = false;
      } else {
        let newaws = -1;
        for (let j = 0; j < data.length; j++) {
          if (data[j].aws_id === fetch_data[i].aws_id) {
            newaws = j;
            break;
          }
        }

        if (newaws === -1) {
          data.push(fetch_data[i]);
          request_success.push(1);
        } else {
          data[newaws].sensor_data = data[newaws].sensor_data.concat(fetch_data[i].sensor_data);
          request_success[newaws] = request_success[newaws] + 1;
        }
      }
    }
  }

  if (countdataempty !== 0) {
    //data = []
    if (credentialerror) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
    } else {
      let messageerror = "Error loading sensor data"
      if (deviceserror) {
        messageerror = "There are devices that have been removed"
      } else if (permissionerror) {
        messageerror = "You have not access permissions for loading sensor data"
      }
      //message.error(messageerror);
      Notification.error({ message: "Error", "description": messageerror, "duration": 0 });

    }
  }

  const dataLength = data.length

  let current_index = -1;

  //---agroup value for hour
  while (currentTime.diff(end) >= 0) {
    let tmpDataDevice = []
    current_index = -1;
    for (let index = 0; index < devices.length; index++) {
      for (let indey = 0; indey < dataLength; indey++) {

        // # This is for every device in response data
        if (data[indey].aws_id === devices[index].aws_id) {
          current_index = index;
          const device = data[indey]

          const studiedSensor = await getStudiedFromSensorData(
            theconfig,
            device.sensor_data,
            start,
            end,
          )

          let average = 0
          if (theconfig.sensorName === "motion") {
            average = studiedSensor.sum
          } else {
            average = studiedSensor.sum / studiedSensor.count
          }
          average = average ? average : 0
          const xvalue = devices[index].x;
          const yvalue = devices[index].y;

          let convertaverage = await convert_range(average, theconfig.sensorName, HEATMAP_RANGE);

          tmpDataDevice.push([
            xvalue,
            yvalue,
            convertaverage.toFixed(2),
            average.toFixed(2)
          ])
        }
      }

      const xvalue = devices[index].x;
      const yvalue = devices[index].y;
      if (tmpDataDevice.length - 1 < index) {
        tmpDataDevice.push([
          xvalue,
          yvalue,
          "-1",
          "0"
        ])
      }

      if (request_success[current_index] > 0 && request_success[current_index] < (parseFloat(lasthour) * 2)) {
        tmpDataDevice[current_index][2] = "-1";
        tmpDataDevice[current_index][3] = "0";
      }

    }

    tmpData.push({
      time: endLocal.format('HH'),
      devices: tmpDataDevice,
    })

    start.add(requestTime * 30, 'minutes')
    end.add(requestTime * 30, 'minutes')
    startLocal.add(requestTime * 30, 'minutes')
    endLocal.add(requestTime * 30, 'minutes')
  }

  //---sum hour for device---
  for (let index = 0; index < tmpData.length; index++) {
    const element = tmpData[index];
    for (let indey = 0; indey < element.devices.length; indey++) {
      let dev = element.devices[indey];
      if (returndevices[indey]) {
        returndevices[indey][2] = parseFloat(returndevices[indey][2]) + parseFloat(dev[2]);
        returndevices[indey][3] = parseFloat(returndevices[indey][3]) + parseFloat(dev[3]);
      } else {
        returndevices.push([
          dev[0],
          dev[1],
          parseFloat(dev[2]),
          parseFloat(dev[3])
        ])
      }
    }
  }

  //---calculate media for sum---
  for (let index = 0; index < returndevices.length; index++) {
    returndevices[index][2] = returndevices[index][2] / (tmpData.length);
    returndevices[index][3] = returndevices[index][3] / (tmpData.length);
  }
  return returndevices
}

//transform range of sensor value to color sernsor range 0-1
const convert_range = (val, sensor, heatmap_range) => {
  let newval = 0;
  if ((val) === 0) {
    return val;
  }

  for (let i = 1; i < heatmap_range["default"].length; i++) {
    if (val < heatmap_range[sensor][i]) {
      newval = heatmap_range["default"][i - 1] + ((heatmap_range["default"][i] - heatmap_range["default"][i - 1]) * (val - heatmap_range[sensor][i - 1])) / (heatmap_range[sensor][i] - heatmap_range[sensor][i - 1]);
      if (newval > heatmap_range["default"][heatmap_range["default"].length - 1]) {
        newval = heatmap_range["default"][heatmap_range["default"].length - 1];
      }
      return newval;
    }
  }

  return heatmap_range["default"][heatmap_range["default"].length - 1];
}


//for range start end calculate sum reading sensor, call for getDataDay
const getStudiedFromSensorData = (theconfig, sensorData, start, end) => {
  let sum = 0
  let count = 0
  const sensorDataLength = sensorData.length
  for (let index = 0; index < sensorDataLength; index++) {
    // # This is for every sensor in the device
    const sensor = sensorData[index]
    let created = sensor.created
    if (created.includes("T")) {
      created = created.split("T")[0] + " " + created.split("T")[1]
    }
    const sensorCreatedDate = moment.utc(created, 'YYYY-MM-DD HH:mm:ss')

    if (sensorCreatedDate.diff(start) >= 0 && end.diff(sensorCreatedDate) > 0) {
      try {
        if (theconfig.sensorName !== 'power' && theconfig.sensorName !== 'ir') {
          if (sensor.sensor_reading && sensor.sensor_reading.length) {
            const reading = sensor.sensor_reading
            let tmp =
              reading.includes(',') || reading.includes('.')
                ? parseFloat(reading)
                : parseInt(reading)

            if (theconfig.sensorName === 'pressure') {
              tmp = tmp / 100
            }

            if (tmp >= 0) {
              sum += tmp
              count++
            }
          }
        } else if (theconfig.sensorName === 'ir') {
          if (sensor.is_raw && sensor.sensor_mv) {
            sum += parseFloat(sensor.sensor_raw)
            count++
          } else if (!sensor.is_raw && sensor.sensor_reading) {
            sum += parseFloat(sensor.sensor_reading)
            count++
          }
        } else {
          // * For power sensor
          if (sensor.is_raw && sensor.sensor_mv) {
            sum += parseFloat(sensor.sensor_raw) / 51
            count++
          } else if (!sensor.is_raw && sensor.sensor_reading) {
            sum += parseFloat(sensor.sensor_reading)
            count++
          }
        }
      } catch (err) {
        message.error('Invalid sensor reading')
        //Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });              
      }
    }
  }
  return { sum, count }
}


//legend
export const getLeyend = (sensor, heatmap_range, heatmap_color, gradient_labels, is_show_sensor, start_alpha) => {
  const arraypoint = point(sensor, heatmap_range)
  return (
    <div style={{ width: "100%", height: "33px", padding: "0px 20px" }}>
      {(is_show_sensor) && (<div style={{ width: "100%", height: "20px", fontSize: "12px", textAlign: "center", marginBottom: "0px", marginTop: "-5px" }}>{sensor}</div>)}
      <div style={{ width: "100%", height: "8px", background: buildGradient(sensor, heatmap_range, heatmap_color, start_alpha) }}> </div>
      <div style={{ width: "100%", position: "relative", fontSize: gradient_labels.length === 0 ? "8px" : "12px" }}>
        {heatmap_range && heatmap_range[sensor].map((value, index) => (
          <span key={`point${index}`} style={{ left: (arraypoint[index] + "%"), position: "absolute", width: "40px", marginLeft: "-20px", textAlign: "center" }}>{gradient_labels && gradient_labels.length && gradient_labels[index] ? gradient_labels[index] : value}</span>
        ))}
      </div>
    </div>
  )
}


const point = (sensor, heatmap_range) => {
  //build array of color point
  let returnarray = []
  returnarray.push(0)
  let percentvalue = 0;
  const rangevlaue = Math.abs(heatmap_range[sensor][0] - heatmap_range[sensor][heatmap_range[sensor].length - 1]);
  for (let i = 1; i < heatmap_range[sensor].length; i++) {
    percentvalue = percentvalue + Math.abs((heatmap_range[sensor][i] - heatmap_range[sensor][i - 1]) * 100 / rangevlaue);
    returnarray.push(percentvalue)
  }
  return returnarray;
}

const buildGradient = (sensor, heatmap_range, heatmap_color, start_alpha) => {
  //transform range of sensor value to color sernsor range 0-1
  let gradientvalue = "linear-gradient(90deg, " + heatmap_color["0.0"] + " 0%";
  if (!gradientvalue) {
    gradientvalue = "linear-gradient(90deg, " + heatmap_color["0"] + " 0%";
  }
  if (start_alpha) {
    gradientvalue = "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%";
  }

  let colorvalue = [];
  if (start_alpha) {
    colorvalue.push("0")
  }
  for (var clave in heatmap_color) {
    if (heatmap_color.hasOwnProperty(clave)) {
      colorvalue.push(clave);
    }
  }

  let percentvalue = 0;
  let rgbacolor = "";
  const rangevlaue = Math.abs(heatmap_range[sensor][0] - heatmap_range[sensor][heatmap_range[sensor].length - 1]);
  for (let i = 1; i < heatmap_range[sensor].length; i++) {
    percentvalue = percentvalue + Math.abs((heatmap_range[sensor][i] - heatmap_range[sensor][i - 1]) * 100 / rangevlaue);
    rgbacolor = ((heatmap_color[colorvalue[i]]).replace("rgb(", "rgba(")).replace(")", ", " + (start_alpha ? heatmap_range["default"][i] : 1) + ")")
    gradientvalue = gradientvalue + ", " + rgbacolor + " " + percentvalue + "%";
  }
  gradientvalue = gradientvalue + ")";
  return gradientvalue;
}






















//---------------------------------------------------
//-----------VERSION 2  PEOPLE ANALYICS--------------
//---------------------------------------------------
//method that obtain high precision device sensor value for render in heatmap of people analytics
export const getHighPrecisionDataHour = async (history, dispatch, tenant_uuid, theconfig, devices, occupancyLimit) => {
  const stepTime = 1;
  let date = theconfig.day + "-" + theconfig.hour;
  let start = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc()
  let endLocal = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").add(stepTime, 'seconds')

  const tmpData = []

  let data = []
  const part = 2;
  let emptydata = true;
  let countdataempty = 0;
  const promises = [];


  //---create promise---
  for (let index = 0; index < part; index++) {
    for (let i = 0; i < devices.length; i++) {
      let tmpstart = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add(index * (1 / part), 'hours').add(1, 'seconds')
      let tmpcurrentTime = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add((index + 1) * (1 / part), 'hours')
      promises.push(new Promise(resolve => {
        const interval_fetch = getChartHighPrecisionSensorDataDevice(
          history,
          tenant_uuid,
          devices[i],
          theconfig,
          tmpstart.format('YYYYMMDDHHmmss'),
          tmpcurrentTime.format('YYYYMMDDHHmmss')
        )
        resolve(interval_fetch)
      }
      ))
    }
  }

  let request_success = []

  //---process promise---
  let deviceserror = false;
  let permissionerror = false;
  let credentialerror = false;

  const fetch_data = await proccessPromises(promises)
  for (let i = 0; i < fetch_data.length; i++) {
    if (fetch_data[i] && fetch_data[i].message) { //} && fetch_data[i].length > 0) {
      countdataempty = countdataempty + 1;
      if (fetch_data[i].message.includes("403")) {
        permissionerror = true;
      }
      if (fetch_data[i].message.includes("404")) {
        deviceserror = true;
      }
      if (fetch_data[i].message.includes("401")) {
        credentialerror = true;
      }

    } else {

      if (emptydata) {
        data.push(fetch_data[i]);
        emptydata = false;
      } else {

        let newaws = -1;
        for (let j = 0; j < data.length; j++) {
          if (data[j].aws_id === fetch_data[i].aws_id) {
            newaws = j;
            break;
          }
        }

        if (newaws === -1) {
          data.push(fetch_data[i]);
          request_success.push(1);
        } else {
          //data[newaws].sensor_data = data[newaws].sensor_data.concat(fetch_data[i].sensor_data);
          data[newaws].sensor_data = Object.assign(data[newaws].sensor_data, fetch_data[i].sensor_data);
          request_success[newaws] = request_success[newaws] + 1;
        }
      }

    }
  }

  if (countdataempty !== 0) {
    //data = []
    if (credentialerror) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
    } else {
      let messageerror = "Error loading high precision sensor data"
      if (deviceserror) {
        messageerror = "There are devices that have been removed"
      } else if (permissionerror) {
        messageerror = "You have not access permissions for loading high precision sensor data"
      }
      //message.error(messageerror);
      Notification.error({ message: "Error", "description": messageerror, "duration": 0 });
    }
  }

  let current_index = -1;
  let active_devices = [];
  let active_persons = new Array(3600 / stepTime);
  for (let index = 0; index < active_persons.length; index++) {
    active_persons[index] = new Array(devices.length)
    for (let indey = 0; indey < devices.length; indey++) {
      active_persons[index][indey] = 0;
    }
  }
  let occupancy_events = 0;
  let tmpDataDevice = []

  //---agroup values for hour---
  const dataLength = data.length

  current_index = -1;

  //DENSITY
  const group_control_data = {}
  let control_group = []
  let index_group = 0
  for (let index = 0; index < devices.length; index++) {
    const isExist = (devices[index].control_group_id + "") in group_control_data
    if (isExist) {

      for (let indey = 0; indey < control_group.length; indey++) {
        if (control_group[indey].id + "" === devices[index].control_group_id + "") {
          control_group[indey].num_devices = control_group[indey].num_devices + 1
        }
      }

    } else {
      group_control_data[devices[index].control_group_id + ""] = {}
      group_control_data[devices[index].control_group_id + ""]["active_device"] = 0
      group_control_data[devices[index].control_group_id + ""]["num_person"] = 0
      group_control_data[devices[index].control_group_id + ""]["total_active_device"] = 0
      group_control_data[devices[index].control_group_id + ""]["total_num_person"] = 0
      group_control_data[devices[index].control_group_id + ""]["percent_active_device"] = 0
      group_control_data[devices[index].control_group_id + ""]["max_num_person"] = 0

      control_group.push({ id: devices[index].control_group_id, num_devices: 1 })
      index_group = index_group + 1
    }
  }

  //console.log("initialize done")
  //console.log(group_control_data)
  //console.log(control_group)

  for (let index = 0; index < devices.length; index++) {
    for (let indey = 0; indey < dataLength; indey++) {

      // # This is for every device in response data
      if (data[indey].aws_id === devices[index].aws_id && parseFloat(devices[index].x) > -100 && parseFloat(devices[index].y) > -100) {
        current_index = index;
        const device = data[indey]
        let sum = 0;
        for (let indez = 0; indez < 3600; indez++) {
          if (device["sensor_data"][indez] && device["sensor_data"][indez].sensor_reading) {
            const reading = device["sensor_data"][indez].sensor_reading + ""
            let tmp =
              reading.includes(',') || reading.includes('.')
                ? parseFloat(reading)
                : parseInt(reading)

            if (tmp > 0) {
              //matriz person time - device
              active_persons[indez][indey] = 1;
            } else {
              active_persons[indez][indey] = 0;
            }

            sum = sum + tmp;
          }
        }

        occupancy_events = occupancy_events + sum
        if (sum > 0) {
          active_devices[indey] = 1;
        }

        let average = sum

        average = average ? average : 0
        const xvalue = devices[index].x;
        const yvalue = devices[index].y;

        let convertaverage = await convert_range(average, theconfig.sensorName, HEATMAP_RANGE_PA);
        tmpDataDevice.push([
          xvalue,
          yvalue,
          convertaverage.toFixed(2),
          average.toFixed(2),
          devices[index].control_group_id
        ])
      }
    }

    const xvalue = devices[index].x;
    const yvalue = devices[index].y;
    if (tmpDataDevice.length - 1 < index) {
      tmpDataDevice.push([
        xvalue,
        yvalue,
        "-1",
        "0.00"
      ])
    }

    if (request_success[current_index] > 0 && request_success[current_index] < 2) {
      tmpDataDevice[current_index][2] = "-1";
      tmpDataDevice[current_index][3] = "0.00";
    }
  }

  tmpData.push({
    time: endLocal.format('HH'),
    devices: tmpDataDevice,
  })

  start = moment(date + "-00-00", "YYYY-MM-DD-HH-mm-ss")
  let array_log = []
  let index_warning = 0;
  let sum_person = 0;
  let average_person = 0;
  let delta = 80;
  let sum_percent = 0;
  let average_percent = 0;

  let last_num_person = -1;
  //for stepTime get warning when count person > limit person
  for (let index = 0; index < 3600 / stepTime; index++) {

    let num_person = 0;
    let percent_occupancy = 0

    //DENSITY
    for (let indeg = 0; indeg < control_group.length; indeg++) {
      if (group_control_data && group_control_data[control_group[indeg].id + ""]) {
        group_control_data[control_group[indeg].id + ""]["active_device"] = 0
        group_control_data[control_group[indeg].id + ""]["num_person"] = 0
      }
    }


    for (let indey = 0; indey < devices.length; indey++) {

      num_person = num_person + active_persons[index][indey];
      percent_occupancy = percent_occupancy + active_persons[index][indey];

      //DENSITY
      if (group_control_data && group_control_data[devices[indey].control_group_id + ""]) {
        group_control_data[devices[indey].control_group_id + ""]["active_device"] = group_control_data[devices[indey].control_group_id + ""]["active_device"] + active_persons[index][indey]
        group_control_data[devices[indey].control_group_id + ""]["num_person"] = group_control_data[devices[indey].control_group_id + ""]["num_person"] + active_persons[index][indey]
      }



      let num_active_neighbor = 0
      let neighbor_up = false
      let neighbor_down = false
      let neighbor_right = false
      let neighbor_left = false

      let neighbor_up_in_group = false
      let neighbor_down_in_group = false
      let neighbor_right_in_group = false
      let neighbor_left_in_group = false
      for (let indez = 0; indez < devices.length; indez++) {
        if (indey !== indez && active_persons[index][indey] && active_persons[index][indez]) {

          const dist = (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)) * (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)) + (parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) * (parseFloat(devices[indey].y) - parseFloat(devices[indez].y))
          if (delta * delta > dist) {

            num_active_neighbor = num_active_neighbor + 1;
            const isup = (parseFloat(devices[indey].y) < parseFloat(devices[indez].y)) && ((parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) * (parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) > (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)) * (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)))
            const isdown = (parseFloat(devices[indey].y) > parseFloat(devices[indez].y)) && ((parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) * (parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) > (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)) * (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)))
            const isright = (parseFloat(devices[indey].x) > parseFloat(devices[indez].x)) && ((parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) * (parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) < (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)) * (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)))
            const isleft = (parseFloat(devices[indey].x) < parseFloat(devices[indez].x)) && ((parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) * (parseFloat(devices[indey].y) - parseFloat(devices[indez].y)) < (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)) * (parseFloat(devices[indey].x) - parseFloat(devices[indez].x)))
            if (isup) {
              neighbor_up = true
            }
            if (isdown) {
              neighbor_down = true
            }
            if (isright) {
              neighbor_right = true
            }
            if (isleft) {
              neighbor_left = true
            }

            //---    ---
            if (devices[indey].control_group_id === devices[indez].control_group_id) {
              if (isup) {
                neighbor_up_in_group = true
              }
              if (isdown) {
                neighbor_down_in_group = true
              }
              if (isright) {
                neighbor_right_in_group = true
              }
              if (isleft) {
                neighbor_left_in_group = true
              }
            }
          }
        }
      }

      if (num_active_neighbor >= 4 && neighbor_up && neighbor_down && neighbor_right && neighbor_left) {
        num_person = num_person + 3
      }

      //DENSITY ---> if equal control group
      if (num_active_neighbor >= 4 && neighbor_up_in_group && neighbor_down_in_group && neighbor_right_in_group && neighbor_left_in_group) {
        if (group_control_data && group_control_data[devices[indey].control_group_id + ""]) {
          group_control_data[devices[indey].control_group_id + ""]["num_person"] = group_control_data[devices[indey].control_group_id + ""]["num_person"] + 3
        }
      }

    }// close indey

    if (num_person > occupancyLimit && last_num_person !== num_person) { //occupancyLimit) {
      const log_warning = { "time": "[" + start.format('HH:mm') + "] [" + start.format('DD/MM') + "]", "num_person": num_person }
      array_log[index_warning] = log_warning;
      index_warning = index_warning + 1;

      last_num_person = num_person;
    }


    sum_person = sum_person + num_person
    sum_percent = sum_percent + (percent_occupancy / (devices.length > 0 ? devices.length : 1)) * 100

    //DENSITY
    for (let indeg = 0; indeg < control_group.length; indeg++) {
      if (group_control_data && group_control_data[control_group[indeg].id + ""]) {
        group_control_data[control_group[indeg].id + ""]["total_active_device"] = group_control_data[control_group[indeg].id + ""]["total_active_device"] + group_control_data[control_group[indeg].id + ""]["active_device"]
        group_control_data[control_group[indeg].id + ""]["total_num_person"] = group_control_data[control_group[indeg].id + ""]["total_num_person"] + group_control_data[control_group[indeg].id + ""]["num_person"]
        group_control_data[control_group[indeg].id + ""]["percent_active_device"] = group_control_data[control_group[indeg].id + ""]["percent_active_device"] + (group_control_data[control_group[indeg].id + ""]["active_device"] / (control_group[indeg].num_devices && control_group[indeg].num_devices === 0 ? 1 : control_group[indeg].num_devices)) * 100
        group_control_data[control_group[indeg].id + ""]["max_num_person"] = Math.max(group_control_data[control_group[indeg].id + ""]["max_num_person"], group_control_data[control_group[indeg].id + ""]["num_person"])
      }
    }

    start.add(stepTime, "seconds")
  }//close indey
  average_person = sum_person / (3600 / stepTime);
  average_percent = sum_percent / (3600 / stepTime);

  //DENSITY
  for (let indeg = 0; indeg < control_group.length; indeg++) {
    if (group_control_data && group_control_data[control_group[indeg].id + ""]) {
      group_control_data[control_group[indeg].id + ""]["percent_active_device"] = group_control_data[control_group[indeg].id + ""]["percent_active_device"] / (3600 / stepTime)
      //group_control_data[control_group[indeg].id + ""]["percent_active_device"] = group_control_data[control_group[indeg].id + ""]["percent_active_device"] / parseInt(group_control_data[control_group[indeg].id + ""]["total_active_device"]+"" === "0" ? 1 : group_control_data[control_group[indeg].id + ""]["total_active_device"])
    }
  }
  //tmpDataDevice.push([
  //  xvalue,
  //  yvalue,
  //  convertaverage.toFixed(2),
  //  average.toFixed(2)
  //])
  let tmpDensityDataDevice = []
  for (let index = 0; index < tmpDataDevice.length; index++) {
    if (tmpDataDevice[index][2] >= 0 && tmpDataDevice[index][2] < 1) {
      let percent_value = group_control_data[devices[index].control_group_id + ""]["percent_active_device"]
      tmpDensityDataDevice.push([
        tmpDataDevice[index][0],
        tmpDataDevice[index][1],
        convert_range(percent_value, "density", HEATMAP_RANGE_PA),
        percent_value,
        devices[index].control_group_id
      ])
    } else {
      tmpDensityDataDevice.push(tmpDataDevice[index])
    }

  }

  let tmpDensityData = []
  tmpDensityData.push({
    time: endLocal.format('HH'),
    devices: tmpDensityDataDevice,
  })

  array_log = array_log.reverse();

  //console.log("groups_data")
  //console.log(group_control_data)
  //console.log(tmpDensityData)

  return { "tmpData": tmpData, "active_devices": average_percent.toFixed(0), "occupancy_events": occupancy_events, "occupants": Math.floor(average_person + 0.9999), "warning": array_log, "tmpDensityData": tmpDensityData, "group_control_data": group_control_data, "control_group": control_group }
}















//---------------------------------------------------
//-----------VERSION 1 UV CONTROL--------------
//---------------------------------------------------

//method that obtain device sensor value for render in heatmap of device
export const getDataDay = async (history, dispatch, tenant_uuid, theconfig, devices) => {
  const date = theconfig.day;
  const endLocal = moment(date + "-00-00-00", "YYYY-MM-DD-HH-mm-ss").add(requestTime, 'hours')
  const tmpData = []

  let data = []
  const part = 1 / 24;
  let emptydata = true;
  let countdataempty = 0;
  const promises = [];


  //---create promise---
  for (let index = 0; index < 1; index++) {
    for (let i = 0; i < devices.length; i++) {
      let tmpstart = moment(date + "-00-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add(index * (1 / part), 'hours').add(1, 'seconds')
      let tmpcurrentTime = moment(date + "-00-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add((index + 1) * (1 / part), 'hours')
      promises.push(new Promise(resolve => {
        const interval_fetch = getChartUVSensorDataDevice(
          history,
          tenant_uuid,
          devices[i],
          theconfig,
          tmpstart.format('YYYYMMDDHHmmss'),
          tmpcurrentTime.format('YYYYMMDDHHmmss')
        )
        resolve(interval_fetch)
      }
      ))
    }
  }

  let request_success = []

  //---process promise---
  let deviceserror = false;
  let permissionerror = false;
  let credentialerror = false;

  const fetch_data = await proccessPromises(promises)
  for (let i = 0; i < fetch_data.length; i++) {
    if (fetch_data[i] && fetch_data[i].message) { //} && fetch_data[i].length > 0) {
      countdataempty = countdataempty + 1;
      if (fetch_data[i].message.includes("403")) {
        permissionerror = true;
      }
      if (fetch_data[i].message.includes("404")) {
        deviceserror = true;
      }
      if (fetch_data[i].message.includes("401")) {
        credentialerror = true;
      }

    } else {

      if (emptydata) {
        data.push(fetch_data[i]);
        emptydata = false;
      } else {

        let newaws = -1;
        for (let j = 0; j < data.length; j++) {
          if (data[j].aws_id === fetch_data[i].aws_id) {
            newaws = j;
            break;
          }
        }

        if (newaws === -1) {
          data.push(fetch_data[i]);
          request_success.push(1);
        } else {
          data[newaws].sensor_data = data[newaws].sensor_data.concat(fetch_data[i].sensor_data);
          request_success[newaws] = request_success[newaws] + 1;
        }
      }

    }
  }

  if (countdataempty !== 0) {
    //data = []
    if (credentialerror) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
    } else {
      let messageerror = "Error loading sensor data"
      if (deviceserror) {
        messageerror = "There are devices that have been removed"
      } else if (permissionerror) {
        messageerror = "You have not access permissions for loading sensor data"
      }
      //message.error(messageerror);
      Notification.error({ message: "Error", "description": messageerror, "duration": 0 });
    }
  }

  //---agroup values for hour---
  const dataLength = data.length
  let tmpDataDevice = []
  for (let index = 0; index < devices.length; index++) {
    for (let indey = 0; indey < dataLength; indey++) {

      // # This is for every device in response data
      if (data[indey].aws_id === devices[index].aws_id) {
        const device = data[indey]
        let tmpstart = moment(date + "-00-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add(0, 'hours').add(1, 'seconds')
        let tmpcurrentTime = moment(date + "-00-00-00", "YYYY-MM-DD-HH-mm-ss").utc().add(24, 'hours')
        const studiedSensor = await getStudiedFromUVLightCycleSensorData(
          tmpstart,
          tmpcurrentTime,
          device.sensor_data,
          device.last_data
        )

        let average = 0;
        average = studiedSensor.sum
        average = average ? average : 0
        const xvalue = devices[index].x;
        const yvalue = devices[index].y;

        let convertaverage = await convert_range(average, theconfig.sensorName, HEATMAP_RANGE_UV_CONTROL);

        tmpDataDevice.push([
          xvalue,
          yvalue,
          convertaverage.toFixed(2),
          average.toFixed(2)
        ])
      }
    }

    const xvalue = devices[index].x;
    const yvalue = devices[index].y;
    if (tmpDataDevice.length - 1 < index) {
      tmpDataDevice.push([
        xvalue,
        yvalue,
        "-1",
        "0.00"
      ])
    }

    /*
    if (request_success[current_index] > 0 && request_success[current_index] < 2) {
      tmpDataDevice[current_index][2] = "-1";
      tmpDataDevice[current_index][3] = "0.00";
    }
    */
  }

  tmpData.push({
    time: endLocal.format('DD'),
    devices: tmpDataDevice,
  })

  return tmpData
}


//for range start end calculate sum reading sensor, call for getDataDay
const getStudiedFromUVLightCycleSensorData = (tmpstart, tmpend, sensorData, last_data) => {
  let sum = 0
  let count = 0
  const sensorDataLength = sensorData.length

  let sensorBeforeCreatedDate = tmpend
  let sensorBeforeValue = 0
  let tmp = 0
  let created = null
  let num_minutes = 0

  sensorData = sortList(sensorData, 'DESC')

  for (let index = 0; index < sensorDataLength; index++) {
    // # This is for every sensor in the device
    const sensor = sensorData[index]
    created = sensor.created
    if (created.includes("T")) {
      created = created.split("T")[0] + " " + created.split("T")[1]
    }
    const sensorCreatedDate = moment.utc(created, 'YYYY-MM-DD HH:mm:ss')

    try {
      if (sensor.sensor_reading && sensor.sensor_reading.length) {
        const reading = sensor.sensor_reading
        tmp =
          reading.includes(',') || reading.includes('.')
            ? parseFloat(reading)
            : parseInt(reading)

        if (tmp === 1 && (sensorBeforeValue === 0 || sensorBeforeValue === 1)) {
          count++
          num_minutes = parseInt(sensorBeforeCreatedDate.diff(sensorCreatedDate, 'seconds'))
          sum += num_minutes
        }
      }

    } catch (err) {
      message.error('Invalid sensor reading')
      //Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });              
    }
    sensorBeforeValue = tmp
    sensorBeforeCreatedDate = moment.utc(created, 'YYYY-MM-DD HH:mm:ss')
  }

  if ((last_data === 1 || last_data === "1") && (sensorBeforeValue === 0 || sensorBeforeValue === 1)) {
    count++
    sum += parseInt(sensorBeforeCreatedDate.diff(tmpstart, 'seconds'))
  }

  sum = parseInt(Math.round(sum / 60))
  return { sum, count }
}


function sortList(list, order) {
  if (order === "ASC") {
    return list.sort((a, b) => {
      let a_created = a.created
      if (a_created.includes("T")) {
        a_created = a_created.split("T")[0] + " " + a_created.split("T")[1]
      }
      const ACreatedDate = moment.utc(a_created, 'YYYY-MM-DD HH:mm:ss')

      let b_created = b.created
      if (b_created.includes("T")) {
        b_created = b_created.split("T")[0] + " " + b_created.split("T")[1]
      }
      const BCreatedDate = moment.utc(b_created, 'YYYY-MM-DD HH:mm:ss')

      //return parseFloat(ACreatedDate) - parseFloat(BCreatedDate);
      return parseInt(ACreatedDate.diff(BCreatedDate, 'minutes'))
    })
  }
  else {
    return list.sort((a, b) => {

      let a_created = a.created
      if (a_created.includes("T")) {
        a_created = a_created.split("T")[0] + " " + a_created.split("T")[1]
      }
      const ACreatedDate = moment.utc(a_created, 'YYYY-MM-DD HH:mm:ss')

      let b_created = b.created
      if (b_created.includes("T")) {
        b_created = b_created.split("T")[0] + " " + b_created.split("T")[1]
      }
      const BCreatedDate = moment.utc(b_created, 'YYYY-MM-DD HH:mm:ss')

      //return parseFloat(BCreatedDate) - parseFloat(ACreatedDate);
      return parseInt(BCreatedDate.diff(ACreatedDate, 'minutes'))
    });
  }
}











//---------------------------------------------------
//-----------VECTOR PEOPLE ANALYICS--------------
//---------------------------------------------------
//method that obtain animation array value for vectors tab in people analysitcs
export const getDataAnalysisRequest = async (history, dispatch, tenant_uuid, theconfig, devices_graph) => {
  const result_data = await getVectorDataAnalysisRequest(
    history,
    tenant_uuid,
    theconfig.element_type,
    theconfig.element_uuid,
    theconfig.analysis_type
  )

  if (result_data && result_data.error_code) {
    return {
      "error": true, "data": [[]], "config": {}
    }
  } else {


    if (result_data && result_data.data && result_data.data_analysis_status_type && result_data.data_analysis_status_type === 3) {
      const arraydata = await getResponseDataArray(result_data)
      const data = await buildanalysismapdata(arraydata, devices_graph)
      return {
        "data": data, "config": {
          "uuid": result_data.uuid,
          "end": result_data.end,
          "start": result_data.start,
          "upsampling_secs": result_data.upsampling_secs,
          "downsampling_secs": result_data.downsampling_secs,
          "analysis_type": result_data.analysis_type,
          "analysis_type_id": result_data.analysis_type_id,
          "data_analysis_status_type": result_data.data_analysis_status_type,
        }
      }
    } else {
      return {
        "data": [[]], "config": {
          "uuid": result_data.uuid,
          "end": result_data.end,
          "start": result_data.start,
          "upsampling_secs": result_data.upsampling_secs,
          "downsampling_secs": result_data.downsampling_secs,
          "analysis_type": result_data.analysis_type,
          "analysis_type_id": result_data.analysis_type_id,
          "data_analysis_status_type": result_data.data_analysis_status_type,
        }
      }
    }
  }
}

//convert string data to json
export const getResponseDataArray = async (result_data) => {
  //\"{}\"
  let maxvalue = 0
  if (result_data && result_data.data && (result_data.data + "").length > 50) {
    try {
      const data_str = result_data.data + ""
      const data_replace1 = data_str.replace(/"{/g, '{'); //('\"{', '{')
      const data_replace2 = data_replace1.replace(/}"/g, '}');//('}\"', '}')
      const data_replace3 = data_replace2.replace(/\\"/g, '"'); //('\"{', '{')
      const data_replace4 = data_replace3.replace(/}\\"/g, '"');//('}\"', '}')
      //console.log("DATA")
      //console.log(data_replace4)
      var data = JSON.parse(data_replace4 + "")
      return data
    } catch (error) {
      return [[]]
    }
  } else {
    return [[]]
  }
}

//transform data to required format by map component
export const buildanalysismapdata = async (result_data, devices_graph) => {

  //\"{}\"
  let maxvalue = 0
  try {
    var data = result_data
    let tmpData = []

    //build heatmap min middle and max config values
    let maxvalue = 0
    for (let indez = 0; indez < data.length; indez++) {
      const datax = data[indez]
      for (let index = 0; index < devices_graph.length; index++) {
        //for (let indey = 0; indey < (datax.device_list_count).length; indey++) {
        for (var k in datax.device_list_count) {
          const device = datax.device_list_count[k]
          // # This is for every device in response data
          if (k + "" === devices_graph[index].id_device + "") {
            let tmp = 0;
            //Proces and manipulate data and conform datamap
            const reading = device + ""
            tmp =
              reading.includes(',') || reading.includes('.')
                ? parseFloat(reading)
                : parseInt(reading)
            maxvalue = Math.max(tmp, maxvalue)
          }
        }
      }
    }

    const heatmapPAVectors = {
      "default": [0, 0.3, 0.6, 0.9],
      "vectors": [0, maxvalue * 0.3, maxvalue * 0.6, maxvalue * 0.9]
    }

    //build data map for device value
    for (let indez = 0; indez < data.length; indez++) {
      let tmpDataDevice = []
      const datax = data[indez]
      for (let index = 0; index < devices_graph.length; index++) {

        //for (let indey = 0; indey < (datax.device_list_count).length; indey++) {
        for (var k in datax.device_list_count) {
          const device = datax.device_list_count[k]

          // # This is for every device in response data
          if (k + "" === devices_graph[index].id_device + "" && parseFloat(devices_graph[index].x) > -100 && parseFloat(devices_graph[index].y) > -100) {
            let tmp = 0;
            //Proces and manipulate data and conform datamap
            const reading = device + ""
            tmp =
              reading.includes(',') || reading.includes('.')
                ? parseFloat(reading)
                : parseInt(reading)

            maxvalue = Math.max(tmp, maxvalue)

            const xvalue = devices_graph[index].x;
            const yvalue = devices_graph[index].y;

            let convertaverage = await convert_range(tmp, "vectors", heatmapPAVectors); // HEATMAP_RANGE_PA_VECTORS);
            tmpDataDevice.push([
              xvalue,
              yvalue,
              convertaverage.toFixed(2),
              tmp,
              devices_graph[index].id_device
            ])
          }
        }

        const xvalue = devices_graph[index].x;
        const yvalue = devices_graph[index].y;
        if (tmpDataDevice.length - 1 < index) {
          tmpDataDevice.push([
            xvalue,
            yvalue,
            "0.00",
            "0",
            devices_graph[index].id_device
          ])
        }
      }

      tmpData.push(
        tmpDataDevice
      )
    }

    let wakeArray = tmpData
    if (tmpData && tmpData.length > 0) {
      //insert transition frame for smoother animation
      const transitionArray = addAnimationTransition(tmpData, devices_graph)
      //create wake in animation
      wakeArray = addAnimationWare(transitionArray, devices_graph)
    }

    return wakeArray
  } catch (error) {
    return [[]]
  }
}


//function for insert transition frame for smoother animation
const addAnimationTransition = (result_data, devices_graph) => {
  const transitionframe = ANIMATION_TRANSITION_FRAME_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP

  let transitionFrameArray = []
  for (let indez = 0; indez < result_data.length - 1; indez++) {
    let transitionFrameIndex = []

    //create array trasition empty
    transitionFrameIndex.push([])
    for (let indextransition = 1; indextransition < transitionframe; indextransition++) {
      transitionFrameIndex.push([])
    }


    let tmpDataDevice = []
    const datax = result_data[indez]
    for (let index = 0; index < datax.length; index++) {
      const device_neighbor = getDeviceNeightbor(datax[index][4], devices_graph)
      if (device_neighbor) {
        for (let indey = 0; indey < device_neighbor.neighbors.length; indey++) {
          const { neighbor_data, index_neighbor } = getNeightborData(device_neighbor.neighbors[indey].id_device, datax)
          if (neighbor_data && index_neighbor > index) {
            let traslatevalue = 0

            //see if two devices that are neighbors have opposite variations, to create transition
            //move  x < y  diff  | move min dif xs ys | move max dif xs ys | move xs | move ys
            if (datax[index][2] > result_data[indez + 1][index][2] && datax[index_neighbor][2] < result_data[indez + 1][index_neighbor][2]) {
              //if in xold> xnew and yold < ynew   x->y
              traslatevalue = Math.min(datax[index][2] - result_data[indez + 1][index][2], result_data[indez + 1][index_neighbor][2] - datax[index_neighbor][2])
              //set direction of motion in x and y
              const signex = datax[index][0] - datax[index_neighbor][0] < 0 ? 1 : -1
              const signey = datax[index][1] - datax[index_neighbor][1] < 0 ? 1 : -1

              for (let indextransition = 1; indextransition < transitionframe; indextransition++) {
                const xvalue = datax[index][0] + Math.abs(datax[index][0] - datax[index_neighbor][0]) * signex * (1 / transitionframe) * indextransition
                const yvalue = datax[index][1] + Math.abs(datax[index][1] - datax[index_neighbor][1]) * signey * (1 / transitionframe) * indextransition
                //create transition value for insert frame number
                transitionFrameIndex[indextransition].push([xvalue, yvalue, traslatevalue + "", 0, 0])
              }
            } else if (datax[index][2] < result_data[indez + 1][index][2] && datax[index_neighbor][2] > result_data[indez + 1][index_neighbor][2]) {
              //si en xold < xnew and yold > ynew   x<-y
              traslatevalue = Math.min(result_data[indez + 1][index][2] - datax[index][2], datax[index_neighbor][2] - result_data[indez + 1][index_neighbor][2])
              //traslatevalue = Math.max(result_data[indez + 1][index][2] - datax[index][2], datax[index_neighbor][2] - result_data[indez + 1][index_neighbor][2])

              //set direction of motion in x and y
              const signex = datax[index][0] - datax[index_neighbor][0] < 0 ? -1 : 1
              const signey = datax[index][1] - datax[index_neighbor][1] < 0 ? -1 : 1

              for (let indextransition = 1; indextransition < transitionframe; indextransition++) {
                const xvalue = datax[index_neighbor][0] + Math.abs(datax[index][0] - datax[index_neighbor][0]) * signex * (1 / transitionframe) * indextransition
                const yvalue = datax[index_neighbor][1] + Math.abs(datax[index][1] - datax[index_neighbor][1]) * signey * (1 / transitionframe) * indextransition
                //create transition value for insert frame number
                transitionFrameIndex[indextransition].push([xvalue, yvalue, traslatevalue + "", 0, 0])
              }
            } else {
              //const signex = datax[index][0] - datax[index_neighbor][0] < 0 ? 1 : -1
              //const signey = datax[index][1] - datax[index_neighbor][1] < 0 ? 1 : -1

              //for (let indextransition = 1; indextransition < transitionframe; indextransition++) {
              //  const xvalue = datax[index][0] + Math.abs(datax[index][0] - datax[index_neighbor][0]) * signex * (1 / transitionframe) * indextransition
              //  const yvalue = datax[index][1] + Math.abs(datax[index][1] - datax[index_neighbor][1]) * signey * (1 / transitionframe) * indextransition
              //  transitionFrameIndex[indextransition].push([xvalue, yvalue, traslatevalue + "", 0, 0])
              //}
            }
          }
        }
      }
    }

    //create array trasition empty
    const transitionDeviceValue = []
    transitionDeviceValue.push([])
    for (let indextransition = 1; indextransition < transitionframe; indextransition++) {
      transitionDeviceValue.push([])
    }

    let traslatevalue = 0
    let traslatesensorvalue = 0
    for (let index = 0; index < devices_graph.length; index++) {
      traslatevalue = Math.abs(parseFloat(result_data[indez][index][2]) - parseFloat(result_data[indez + 1][index][2]))
      traslatesensorvalue = Math.abs(result_data[indez][index][3] - result_data[indez + 1][index][3])
      //direction of movement of transfer between device
      const signe = parseFloat(result_data[indez][index][2]) - parseFloat(result_data[indez + 1][index][2]) < 0 ? 1 : -1

      //create transition for increase or decrease static
      for (let indextransition = 1; indextransition < transitionframe; indextransition++) {
        const newvalue = parseFloat(result_data[indez][index][2]) + traslatevalue * signe * (1 / transitionframe) * indextransition
        const newsensorvalue = result_data[indez][index][3] + traslatesensorvalue * signe * (1 / transitionframe) * indextransition
        transitionDeviceValue[indextransition].push([result_data[indez][index][0], result_data[indez][index][1], newvalue + "", newsensorvalue, result_data[indez][index][4]])
      }
    }

    //add transitions between neighbors
    transitionFrameArray.push((result_data[indez]).concat([]))
    for (let indextransition = 1; indextransition < transitionframe; indextransition++) {
      //transitionFrameArray.push((result_data[indez]).concat(transitionFrameIndex[indextransition]))
      transitionFrameArray.push((transitionDeviceValue[indextransition]).concat(transitionFrameIndex[indextransition]))

    }
  }
  transitionFrameArray.push((result_data[result_data.length - 1]).concat([]))


  return transitionFrameArray;
}

//create wake in animation
const addAnimationWare = (result_data, devices_graph) => {
  const wake = ANIMATION_WAKE_REDUCTION_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP
  const transitionframe = ANIMATION_TRANSITION_FRAME_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP

  for (let indez = result_data.length - 1; indez > 1; indez--) {
    //for (let indez = 1; indez < result_data.length-1; indez++) {
    //let tmpDataDevice = []
    const datax = result_data[indez - 1]

    for (let index = devices_graph.length; index < datax.length; index++) {
      const nextIndex = emptyNextIteration(result_data[indez - 1][index], result_data[indez])
      //for a position x, y
      //If there is a value in the previous animation frame and not in the current frame, create a wake by putting a lower value in the current frame
      if (nextIndex === -1) {
        //making a reduction of the previous value
        if (result_data[indez - 1][index][2] > 0.2) {
          const wakevalue = result_data[indez - 1][index][2] * (1 / wake) + ""
          result_data[indez].push([result_data[indez - 1][index][0], result_data[indez - 1][index][1], wakevalue, result_data[indez - 1][index][3], result_data[indez - 1][index][4]])
        }

      } else if (nextIndex > -1) {
        //the current value is very small it is 0 previous value
        if (result_data[indez - 1][index][2] <= 0.2) {
          result_data[indez][nextIndex][2] = 0
        } else {
          result_data[indez][nextIndex][2] = result_data[indez - 1][index][2] * (1 / wake) + ""
        }
      }

    }
  }

  //console.log(result_data)
  return result_data
}

const getDeviceNeightbor = (id_device, devices_graph) => {
  for (let index = 0; index < devices_graph.length; index++) {
    if (devices_graph[index].id_device + "" === id_device + "") {
      return devices_graph[index]
    }
  }
  return undefined
}

const getNeightborData = (id_device, devices_data) => {
  for (let index = 0; index < devices_data.length; index++) {
    if (devices_data[index][4] + "" === id_device + "") {
      return { neighbor_data: devices_data[index], index_neighbor: index }
    }
  }
  return { neighbor_data: undefined, index_neighbor: -1 }
}

const emptyNextIteration = (element, nextElements) => {
  for (let index = 0; index < nextElements.length; index++) {

    if (element[0] + "" === nextElements[index][0] + "" && element[1] + "" === nextElements[index][1] + "" && nextElements[index][2] !== 0) {
      return -2
    } else if (element[0] + "" === nextElements[index][0] + "" && element[1] + "" === nextElements[index][1] + "" && nextElements[index][2] === 0) {
      return index
    }
  }
  return -1
}