import React from "react";
import { Button, Row } from "antd";
import { withRouter } from "react-router-dom";
import "./styles.css";

import Controllers from "../Controllers"


const SettingControllers = ({
    elementData,
    isModalVisible,
    setIsModalVisible,
}) => {

    const handleCancel = async e => {
        setIsModalVisible(false)
    };

    return (
        <Row>
            <Controllers
                isModalVisible={isModalVisible}
                elementData={elementData}
            />

            <Button
                type="default"
                htmlType="button"
                style={{ width: "40%", marginRight: "10%" }}
                size="large"
                icon="close"
                onClick={handleCancel}
            >
                Cancel
            </Button>
        </Row >
    );
};

export default withRouter(SettingControllers);