import React, { useState, useEffect } from "react";
import { Slider, Row, Col, DatePicker } from "antd";
import moment from 'moment'
import StyledIcon from "../StyledIcon";
import colors from "../../styles";
import "./style.css";


const { primary_color_hover } = colors;

const FrecuencyMap = ({
  dataLoading,
  selectDay,
  changeHour,
  config,
  thedata,
}) => {
  const [date, setDate] = useState(config.day)
  const [hour, setHour] = useState(config.hour)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading])

  const onDateChangeHour = param => {
    if (typeof param === "number") {
      setHour(param);
      changeHour(param)
    }
  };

  const onDateChangeDate = param => {
    let stringdate = ""
    if (param) {
      stringdate = param._d.getFullYear() + "-" + (Number(param._d.getMonth()) + 1) + "-" + (Number(param._d.getDate()) > 9 ? param._d.getDate() : "0" + param._d.getDate());
    } else {
      stringdate = moment().format("YYYY-MM-DD")
    }
    setDate(stringdate);
    selectDay(stringdate);
  };

  const getClassNotActiveStep = () => {
    let classStepSlider = ""
    for (let index = 0; index < 24; index++) {
      if (isEmptyHour(index)) {
        classStepSlider = classStepSlider + " step" + (index + 1);
      }

    }
    return classStepSlider;
  };

  const isEmptyHour = (hour) => {
    let returnvalue = false;
    (thedata.chartData[hour].devices).forEach(element => {
      if (element[2] === "1" || element[2] === 1) {
        returnvalue = true;
      }
    });
    return returnvalue;
  };

  const marks = {
    0: '0',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '5',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '10',
    11: '',
    12: '',
    13: '',
    14: '',
    15: '15',
    16: '',
    17: '',
    18: '',
    19: '',
    20: '20',
    21: '',
    22: '',
    23: '23'
  };

  const render = () => {
    //const { stat, date } = this.state;
    return (
      <>
        {loading ? (
          <div className="loader">
            <StyledIcon type="loading" color={primary_color_hover} scope="loader" />
          </div>

        ) : (
            <div className="frecuencycontrol">
              <Row style={{ padding: "10px 0px" }}>
                <Col span={12}>
                  <span style={{ marginRight: "10px" }}>Date:</span>
                  <DatePicker format="YYYY-MM-DD" defaultValue={moment(date, "YYYY-MM-DD")} onChange={onDateChangeDate} />
                </Col>
                <Col span={12} className={"slider-hour"}>
                  <Row>
                    <Col span={24}>
                      <Slider
                        min={0}
                        max={23}
                        marks={marks}
                        onAfterChange={onDateChangeHour}
                        defaultValue={hour}
                        className={getClassNotActiveStep()}
                        style={{ marginTop: "0px", marginBottom: "20px", marginRight: "20px" }}
                      />
                    </Col>
                    <Col span={24} style={{ textAlign: "center", marginTop: "0px" }}>
                      Hour
            </Col>
                  </Row>

                </Col>
              </Row>

            </div>
          )}
      </>
    )
  }

  return (
    render()
  )
}

export default FrecuencyMap;
