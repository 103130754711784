import { action } from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchUsersData = tenant_uuid => action(actions.FETCH_USERS_DATA, { tenant_uuid })

export const successFetchUsersData = (usersData, rolesData) => action(actions.SUCCESS_FETCH_USERS_DATA, { usersData, rolesData })

export const fetchUser = (tenant_uuid, user_uuid, managerCompany, managerBuildings, managerGroup, managerRole) => action(actions.FETCH_USER, { tenant_uuid, user_uuid, managerCompany, managerBuildings, managerGroup, managerRole })

export const successFetchUser = (dataUser, dataCompanies, dataBuildings, dataGroups) => action(actions.SUCCESS_FETCH_USER, { dataUser, dataCompanies, dataBuildings, dataGroups })

export const errorFetchUser = error => action(actions.ERROR_FETCH_USER, { error })

export const errorFetchUsersData = error => action(actions.ERROR_FETCH_USERS_DATA, { error })

export const removeUser = (tenant_uuid, user_uuid) => action(actions.REMOVE_USER, { tenant_uuid, user_uuid })

export const successRemoveUser = user_uuid => action(actions.SUCCESS_REMOVE_USER, { user_uuid })

export const createUser = (tenant_uuid, form) => action(actions.CREATE_USER, { tenant_uuid, form })

export const successCreateUser = data => action(actions.SUCCESS_CREATE_USER, { data })

export const editUser = (tenant_uuid, user_uuid, form) => action(actions.EDIT_USER, { tenant_uuid, user_uuid, form })

export const successEditUser = data => action(actions.SUCCESS_EDIT_USER, { data })

export const setIsUserCreatedUpdated = flag => action(actions.SET_IS_USER_CREATED_UPDATED, { flag })

export const errorRemoveUser = error => action(actions.ERROR_REMOVE_USER, { error })

export const errorCreateEditUser = error => action(actions.ERROR_CREATE_EDIT_USER, { error })

export const userFetchCompaniesList = tenant_uuid => action(actions.USER_FETCH_COMPANIES_LIST, { tenant_uuid })

export const userSuccessFetchCompaniesList = data => action(actions.USER_SUCCESS_FETCH_COMPANIES_LIST, { data })

export const userFetchCompanyBuildings = (tenant_uuid, company_uuid) => action(actions.USER_FETCH_COMPANY_BUILDINGS, { tenant_uuid, company_uuid })

export const userSuccessFetchCompanyBuildings = (data, company_uuid) => action(actions.USER_SUCCESS_FETCH_COMPANY_BUILDINGS, { data, company_uuid })

export const userFetchBuildingGroups = (tenant_uuid, buildings_uuids, user_role) => action(actions.USER_FETCH_BUILDING_GROUPS, { tenant_uuid, buildings_uuids, user_role })

export const userSuccessFetchBuildingGroups = (data, user_role) => action(actions.USER_SUCCESS_FETCH_BUILDING_GROUPS, { data, user_role })

export const userFetchGroupChildren = (tenant_uuid, group_uuid) => action(actions.USER_FETCH_GROUP_CHILDREN, { tenant_uuid, group_uuid })

export const userSuccessFetchGroupChildren = (data) => action(actions.USER_SUCCESS_FETCH_GROUP_CHILDREN, { data })

export const cleanBuildingsCurrentOptions = () => action(actions.CLEAN_BUILDINGS_CURRENT_OPTIONS)

export const cleanGroupsCurrentOptions = () => action(actions.CLEAN_GROUPS_CURRENT_OPTIONS)

export const cleanUserManagmentState = () => action(actions.CLEAN_USER_MANAGMENT_STATE)

export const cleanUserCreationEditionStates = () => action(actions.CLEAN_USER_CREATION_EDITION_STATES)

export const resetUserPassword = (tenant_uuid, user_uuid) => action(actions.RESET_USER_PASSWORD, { tenant_uuid, user_uuid })

export const successResetUserPassword = data => action(actions.SUCCESS_RESET_USER_PASSWORD, { data })

export const cleanUserResetPasswordStates = () => action(actions.CLEAN_USER_RESET_PASSWORD_STATES)
