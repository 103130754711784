import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Radio, message, Slider, Icon, Notification, Modal, TimePicker, Switch } from "antd";
import { withRouter } from "react-router-dom";
import IdleTimer from "react-idle-timer"
import "./styles.css";
import moment from 'moment';

import CenteredRow from "../CenteredRow";
import Loader from "../Loader";
import { UserContext } from "../../context";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'

const FormItem = Form.Item;

const _SettingsIluminanceForm = ({
    form,
    history,
    isModalVisible,
    tabKey,
}) => {

    const loading = useSelector(state => state.rootReducer.loaders.loadingIlluminance)
    const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)
    const illuminanceData = useSelector(state => state.rootReducer.illuminance.illuminanceData)
    const error = useSelector((state) => state.rootReducer.illuminance.error)

    const [saveDisabled, setSaveDisabled] = useState(true);

    const [touchedFields, setTouchedFields] = useState([])
    const idleTimer = useRef(null)
    const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

    const dispatch = useDispatch()

    const {
        userData: { tenant_uuid }
    } = useContext(UserContext);

    const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue } = form

    const handleSubmit = async e => {
        e.preventDefault()
        await validateFields(async (err, values) => {
            if (!err) {
                const { lux, watts, max_on_time, max_off_time, min_off_level, occupancy_switch } = values
                const form = {}

                if (touchedFields.includes("lux")) {
                    form.lux = lux
                }
                if (touchedFields.includes("watts")) {
                    form.watts = watts
                }
                if (touchedFields.includes("max_on_time")) {
                    form.max_on_secs = (max_on_time.hours()) * 3600 + (max_on_time.minutes()) * 60
                }
                if (touchedFields.includes("max_off_time")) {
                    form.max_off_secs = (max_off_time.hours()) * 3600 + (max_off_time.minutes()) * 60
                }
                if (touchedFields.includes("min_off_level")) {
                    form.min_off_level = min_off_level
                }


                if (touchedFields.includes("occupancy_switch")) {
                    form.occupancy_sensor = occupancy_switch
                }

                dispatch(({
                    type: actions.UPDATE_ILLUMINANCE,
                    payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, form: form, group_type: elementData.group_type }
                }))
            } else {
                message.error(err)
            }
        })
    }

    /*
    useEffect(
        () => {
            (async () => {
                if (!loading && isModalVisible) {
                    resetFields()
                    dispatch(({
                        type: actions.FETCH_ILLUMINANCE,
                        payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                    }))
                    setSaveDisabled(true)
                }
            })();
        },
        [elementData]
    )
    */

    useEffect(
        () => {
            (async () => {
                if (tabKey === "illuminance" && !loading && isModalVisible) {
                    resetFields()
                    dispatch(({
                        type: actions.FETCH_ILLUMINANCE,
                        payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                    }))
                    setSaveDisabled(true)
                }
            })();
        },
        [tabKey]
    )

    useEffect(
        () => {
            const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
            if (touched_fields.length !== 0) {
                setSaveDisabled(false)
            }
            setTouchedFields(touched_fields)
        },
        [form]
    )

    useEffect(
        () => {
            if (!isModalVisible) {
                resetFields()
                setSaveDisabled(true)
                dispatch(({ type: actions.CLEAN_ILLUMINANCE_STATE }))
            } else {
                if (tabKey === "illuminance" && !loading) {
                    resetFields()
                    dispatch(({
                        type: actions.FETCH_ILLUMINANCE,
                        payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                    }))
                    setSaveDisabled(true)
                }
            }
        },
        [isModalVisible]
    )

    useEffect(
        () => {
            if (illuminanceData !== null) {

                if (illuminanceData.update) {
                    setSaveDisabled(true)

                    if (illuminanceData.occupancy_sensor_in_payload) {
                        message.success(`Occupancy sensor value of ${ 
                            elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }

                    if (illuminanceData.lux_in_payload) {
                        message.success(`Illuminance of ${
                            elementData.group_type === "Building" ? "building" : elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }
                    if (illuminanceData.watts_in_payload) {
                        message.success(`Power % of ${
                            elementData.group_type === "Building" ? "building" : elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }
                    if (illuminanceData.max_on_secs_in_payload) {
                        message.success(`Maximum ON time value of ${
                            elementData.group_type === "Building" ? "building" : elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }

                    if (illuminanceData.max_off_secs_in_payload) {
                        message.success(`Maximum OFF time value of ${
                            elementData.group_type === "Building" ? "building" : elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }

                    if (illuminanceData.min_off_level_in_payload) {
                        message.success(`Minimum OFF level value of ${
                            elementData.group_type === "Building" ? "building" : elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }


                }
                resetFields()
            }
        },
        [illuminanceData]
    )

    useEffect(
        () => {
            if (error !== null) {
                if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
                    Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
                    history.push("/login");
                    dispatch({ type: actions.LOG_OUT })
                } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
                    Modal.destroyAll()
                    Modal.error({
                        destroyOnClose: true,
                        title: error.message,
                        okText: "Retry",
                        onOk: () => {
                            resetFields()
                            dispatch(({
                                type: actions.FETCH_ILLUMINANCE,
                                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                            }))
                            setSaveDisabled(true)
                        },
                    })
                } else {
                    Notification.error({ message: "Error", "description": error.message, "duration": 0 });
                    dispatch({ type: actions.CLEAN_ILLUMINANCE_ERROR })
                }
            }
        },
        [error]
    )

    const onUserIdle = async e => {
        if (isModalVisible && tabKey === "illuminance") {
            resetFields()
            dispatch(({
                type: actions.FETCH_ILLUMINANCE,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
            }))
            setSaveDisabled(true)
        }
        //idleTimer.reset()
    }

    const convertHour = (seconds) => {
        const hour = Math.floor(seconds / 3600);
        return hour;
    }

    const convertMinute = (seconds) => {
        const minute = Math.floor((seconds % 3600) / 60);
        return minute;
    }

    const markslux = {
        0: {
            style: {
                fontSize: '12px',
                minWidth: '100px'
            },
            label: <span>0 lux</span>
        },
        500: {
            style: {
                fontSize: '12px',
                minWidth: '100px'

            },
            label: <span>500 lux</span>,
        }
    };

    const markswatts = {
        0: {
            style: {
                fontSize: '12px',
                minWidth: '100px'
            },
            label: <span>0%</span>
        },
        100: {
            style: {
                fontSize: '12px',
                minWidth: '100px'
            },
            label: <span>100%</span>,
        }
    };

    const marksminofflevel = {
        5: {
            style: {
                fontSize: '12px',
                minWidth: '100px'
            },
            label: <span>5</span>
        },
        99: {
            style: {
                fontSize: '12px',
                minWidth: '100px'
            },
            label: <span>99</span>,
        }
    };


    return loading ? (
        <Loader />
    ) : (
            <IdleTimer
                ref={idleTimer}
                element={document}
                onIdle={onUserIdle}
                debounce={250}
                timeout={timeout}
            >
                <div>
                    <CenteredRow>
                        <Form onSubmit={handleSubmit}>

                            {elementData && elementData.group_type && elementData.group_type !== "Building" && <FormItem label="Occupancy Switch">
                                {getFieldDecorator("occupancy_switch", {
                                    initialValue: illuminanceData !== null && illuminanceData.occupancy_sensor ? illuminanceData.occupancy_sensor + "" : "false"
                                })(
                                    <Radio.Group >
                                        <Radio.Button value="false">OFF</Radio.Button>
                                        <Radio.Button value="true">ON</Radio.Button>
                                    </Radio.Group>
                                )}
                            </FormItem>
                            }

                            <FormItem label="Illuminance" >
                                {getFieldDecorator("lux", {
                                    initialValue: illuminanceData !== null && illuminanceData.lux ? illuminanceData.lux : 0
                                })(
                                    <Slider marks={markslux} size="large" min={0} max={500} step={1} />
                                )}
                            </FormItem>
                            <FormItem label="Maximum Power %" >
                                {getFieldDecorator("watts", {
                                    initialValue: illuminanceData !== null && illuminanceData.watts ? illuminanceData.watts : 0
                                })(
                                    <Slider marks={markswatts} size="large" min={0} max={100} step={2} />
                                )}
                            </FormItem>
                            <FormItem label="Maximum ON Time (HH/MM)" >
                                {getFieldDecorator("max_on_time", {
                                    rules: [{ required: true }],
                                    initialValue: illuminanceData !== null && illuminanceData.max_on_secs ? moment((convertHour(illuminanceData.max_on_secs) + ":" + convertMinute(illuminanceData.max_on_secs)), "HH:mm") : moment("00:01", "HH:mm")
                                })(
                                    <TimePicker 
                                        format={"HH:mm"} 
                                        allowClear={false}
                                    />
                                )}
                            </FormItem>

                            <FormItem label="Maximum OFF Time (HH/MM)" >
                                {getFieldDecorator("max_off_time", {
                                    rules: [{ required: true }],
                                    initialValue: illuminanceData !== null && illuminanceData.max_off_secs ? moment((convertHour(illuminanceData.max_off_secs) + ":" + convertMinute(illuminanceData.max_off_secs)), "HH:mm") : moment("00:01", "HH:mm")
                                })(
                                    <TimePicker 
                                        format={"HH:mm"} 
                                        allowClear={false}
                                    />
                                )}
                            </FormItem>

                            <FormItem label="Triggered ON Level (%)" >
                                {getFieldDecorator("min_off_level", {
                                    initialValue: illuminanceData !== null && illuminanceData.min_off_level ? illuminanceData.min_off_level : 1
                                })(
                                    <Slider marks={marksminofflevel} size="small" min={5} max={100} step={1} />
                                )}
                            </FormItem>
                            <FormItem >
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    style={{ width: "40%" }}
                                    disabled={saveDisabled}
                                    loading={loading}
                                    onClick={handleSubmit}
                                >
                                    <Icon type="save" /> Save
                        </Button>
                            </FormItem>
                        </Form>
                    </CenteredRow>
                </div>
            </IdleTimer>
        )
};

const SettingsIluminanceForm = Form.create()(_SettingsIluminanceForm)

export default withRouter(SettingsIluminanceForm)