import { call, put, takeLatest, takeLeading, all } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { 
    successFetchElement, 
    successFetchCompanyTreeData, 
    handleTreeData,
    handleTreeDataPosition, 
    errorFetchElement } 
    from '../actions/companyManagmentActions'
import { 
    fetchElementDetailsApi, 
    fetchAllCompaniesApi, 
    fetchCompanySynopis, 
    fetchBuildingsSynopis, 
    deleteElementApi, 
    fetchAllModulesListApi,
    fetchChangeElementPositionApi } 
    from '../api/companyManagmentApi'
import { getAreaTypesListApi } from '../api/areaTypeParametersApi'
import { fetchLightCapabilityApi } from '../api/lightCapabilityApi'
import { toogleFetchingElement, toogleFetchingCompanyTreeLoader } from '../actions/loaderActions'
import { successFetchLightCapabilityData } from '../actions/lightCapabilityActions'
import {successCreateEditElement} from '../actions/createEditElementActions'
import * as roles from '../constants/roles'
import * as groupTypes from '../constants/groups'

export function* getElementDetails(action) {
    try {
        yield put(toogleFetchingElement(true))
        const data = yield call(fetchElementDetailsApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.group_type
        )
        
        let light_capability = null
        if((action.payload.group_type === groupTypes.GROUP_TYPE_CONTROL || action.payload.group_type === groupTypes.GROUP_TYPE_MIX || action.payload.group_type === groupTypes.GROUP_TYPE_LOGICAL) && action.payload.module === 'lightsControl'){
            light_capability = yield call(fetchLightCapabilityApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
            )
        }

        yield put(successFetchElement(data))
        
        if((action.payload.group_type === groupTypes.GROUP_TYPE_CONTROL || action.payload.group_type === groupTypes.GROUP_TYPE_MIX || action.payload.group_type === groupTypes.GROUP_TYPE_LOGICAL) && action.payload.module === 'lightsControl'){
            yield put(successFetchLightCapabilityData(light_capability))
        }
        
        yield put(toogleFetchingElement(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleFetchingElement(false))
        yield put(errorFetchElement(error))
    }
}

export function* getCompanyTreeData(action) {
    try {
        yield put(toogleFetchingCompanyTreeLoader(true))

        let data = null
        let modulesData = null
        let synopsisData = null
        let areaTypesData = null
        const user_role = action.payload.rolename

        if (user_role === roles.USER_ROLE_COMPANYADMIN) {
            data = yield all([action.payload.user_companies].map(company_uuid => { return call(fetchElementDetailsApi, action.payload.tenant_uuid, company_uuid, 'Company') }))
        } else if (user_role === roles.USER_ROLE_BUILDINGADMIN) {
            data = yield all(action.payload.user_buildings.map(building_uuid => { return call(fetchElementDetailsApi, action.payload.tenant_uuid, building_uuid, 'Building') }))
            if (data.length && data.length > 0) {
                synopsisData = [yield call(fetchCompanySynopis, action.payload.tenant_uuid, data[0].company_uuid)]
            }
        } else if (user_role === roles.USER_ROLE_OFFICEADMIN) {
            data = yield all([action.payload.user_groups].map(group => { return call(fetchElementDetailsApi, action.payload.tenant_uuid, group.uuid, 'Logical') }))
            if (data.length && data.length > 0) {
                synopsisData = yield all([call(fetchCompanySynopis, action.payload.tenant_uuid, data[0].company_uuid), call(fetchBuildingsSynopis, action.payload.tenant_uuid, data[0].parent_building_uuid)])
            }
        } else if (user_role === roles.USER_ROLE_SUPERADMIN) {
            data = yield call(fetchAllCompaniesApi, action.payload.tenant_uuid)
            modulesData = yield call(fetchAllModulesListApi)
        }

        areaTypesData = yield call(getAreaTypesListApi)

        yield put(successFetchCompanyTreeData(data, synopsisData, modulesData, areaTypesData))
        yield put(toogleFetchingCompanyTreeLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleFetchingCompanyTreeLoader(false))
        yield put(errorFetchElement(error))
    }
}

export function* deleteElement(action) {
    try {
        yield put(toogleFetchingElement(true))

        yield call(deleteElementApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.group_type
        )

        yield put(handleTreeData({uuid: action.payload.uuid}, "del"))
        yield put(toogleFetchingElement(false))

    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else{
            error = err.response.data
        }
        yield put(toogleFetchingElement(false))
        yield put(errorFetchElement(error))
    }
}

export function* changeElementPositionTreeData(action) {
    try {
        yield put(toogleFetchingCompanyTreeLoader(true))

        const element = yield call(fetchChangeElementPositionApi, action.payload.tenant_uuid, action.payload.group_uuid, action.payload.parent_uuid)

        yield put(handleTreeDataPosition(element, "add", action.payload.drag_position, action.payload.drop_position))
        yield put(handleTreeDataPosition({uuid: action.payload.group_uuid}, "del", action.payload.drag_position, action.payload.drop_position))
        //yield put(successCreateEditElement(data))

        yield put(toogleFetchingCompanyTreeLoader(false))

    } catch (err) {
        /*
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        */
        yield put(toogleFetchingCompanyTreeLoader(false))
        //yield put(errorFetchElement(error))
    }
}

export function* fetchElementDetailsWatcher() {
    yield takeLatest(actions.FETCH_ELEMENT, getElementDetails)
}

export function* fetchCompanyTreeDataWatcher() {
    yield takeLatest(actions.FETCH_COMPANY_TREE_DATA, getCompanyTreeData)
}

export function* deleteElementWatcher() {
    yield takeLeading(actions.DELETE_ELEMENT, deleteElement)
}

export function* changeElementPositionWatcher() {
    yield takeLatest(actions.CHANGE_ELEMENT_POSITION_TREE_DATA, changeElementPositionTreeData)
}
