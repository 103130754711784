import {
    postNotification, getNotificationService, getUserNotificationService, getAllNotificationLastMinutesService, putToastNotificationService, getElementsNotificationService
} from "../services";

export const fetchNotificationApi = async (tenant_uuid, element_type, uuid, receive_send, transport) => {

    let fetch_function = () => { }
    fetch_function = getNotificationService

    const response = await fetch_function(tenant_uuid, element_type, uuid, receive_send, transport);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}


export const fetchUserNotificationApi = async (tenant_uuid, receive_send, transport) => {
    let fetch_function = () => { }
    fetch_function = getUserNotificationService

    const response = await fetch_function(tenant_uuid, receive_send, transport);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const fetchCreateNotificationApi = async (tenant_uuid, form) => {
    const response = await postNotification(tenant_uuid, form)
    if (response && response.status === 201) {
        let data = await response.data;
        return data
    }
}


export const fetchAllNotificationLastMinutesApi = async (tenant_uuid, last_minutes) => {
    let fetch_function = () => { }
    fetch_function = getAllNotificationLastMinutesService

    const response = await fetch_function(tenant_uuid, last_minutes);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const fetchToastNotificationApi = async (tenant_uuid, notification_uuid, form) => {
    const response = await putToastNotificationService(tenant_uuid, notification_uuid, form)
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}

export const fetchElementsNotificationApi = async (tenant_uuid, element_type) => {
    const response = await getElementsNotificationService(tenant_uuid, element_type)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}
