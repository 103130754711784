import * as actions from '../constants'
import moment from 'moment'

const initialState = {
  powerCalibrationData: null,
  update: false,
  error: null,
}

const powerCalibration = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_POWER_CALIBRATION_DATA:
      const powerCalibrationData = Object.assign({}, action.payload.data, {
        power_calibration_requested_date_time: moment.utc(action.payload.data.power_calibration_requested_date_time, 'YYYY-MM-DD HH:mm:ss').local()
      })

      return Object.assign({}, state, {
        powerCalibrationData: powerCalibrationData,
        update: false,
        error: null
      })

    case actions.SUCCESS_SAVE_POWER_CALIBRATION_SETUP:
      const powerCalibrationSavedData = Object.assign({}, action.payload.data, {
        power_calibration_requested_date_time: moment.utc(action.payload.data.power_calibration_requested_date_time, 'YYYY-MM-DD HH:mm:ss').local()
      })

      return Object.assign({}, state, {
        powerCalibrationData: powerCalibrationSavedData,
        update: true,
        error: null
      })

    case actions.ERROR_FETCH_UPDATE_POWER_CALIBRATION:
      return Object.assign({}, state, {
        powerCalibrationData: null,
        update: false,
        error: action.payload.error
      })

    case actions.CLEAN_POWER_CALIBRATION_ERROR:
      return Object.assign({}, state, {
        error: null,
        update: false,
      })

    case actions.CLEAN_POWER_CALIBRATION_STATE:
      return Object.assign({}, state, {
        error: null,
        powerCalibrationData: null,
        update: false,
      })
      
    default:
      return state
  }
}

export default powerCalibration