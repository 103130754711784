import * as actions from '../constants'
import { setAuthToken, setTemp2faToken } from "../services"
import getDomainClaims from '../utils/domainClaims'

const initialState = {
  is_auth: false,
  second_step_2fa: false,
  username: "",
  userUuid: "",
  accessToken: null,
  refreshToken: null,
  tenant_uuid: "",
  claims: [],
  rolename: "",
  company: "",
  buildings: [],
  group: null,
  modules: [],
  lang: 'en',
  error: null,
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_LOG_IN:
      setTemp2faToken(null)
      setAuthToken(action.payload.accessToken)
      const tokenParsed = JSON.parse(atob(action.payload.accessToken.split(".")[1]))
      const user_claims = tokenParsed.user_claims
      //console.log(tokenParsed)
      const claims = getDomainClaims(user_claims.claims)
      console.log(user_claims)

      const result = {
        is_auth: true,
        second_step_2fa: false,
        username: tokenParsed.identity,
        userUuid: user_claims.uuid,
        modules: user_claims.modules,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.accessToken,
        tenant_uuid: user_claims.tenant_uuid,
        claims: claims,
        rolename: user_claims.roles[0].rolename,
        error: null
      }

      if ("company" in user_claims) {
        result.company = user_claims.company
      }

      if ("buildings" in user_claims) {
        result.buildings = user_claims.buildings
      }

      if ("group" in user_claims) {
        result.group = user_claims.group
      }

      return Object.assign({}, state, result)

    case actions.SUCCESS_2FA_FIRST_STEP:
      setTemp2faToken(action.payload.data.access_token)
      return Object.assign({}, state, {
        second_step_2fa: true,
        username: action.payload.data.user.username,
        userUuid: action.payload.data.user.uuid,
        tenant_uuid: action.payload.data.user.tenant_uuid
      })

    case actions.CHANGE_LANGUAGE:
      return Object.assign({}, state, {
        lang: action.payload.language
      })

    case actions.ERROR_LOG_IN:
      return Object.assign({}, state, {
        is_auth: false,
        second_step_2fa: false,
        // username: "",
        //accessToken: null,
        //refreshToken: null,
        //tenant_uuid: "",
        claims: [],
        rolename: "",
        company: "",
        buildings: [],
        group: null,
        modules: [],
        lang: 'en',
        error: action.payload.error
      })

    case actions.CLEAN_2FA_FIRST_STEP_STATE:
      return Object.assign({}, state, {
        error: null,
      })

    case actions.CLEAN_2FA_SECOND_STEP_STATE:
      return Object.assign({}, state, {
        error: null,
        second_step_2fa: false,
        //accessToken: null,
        //refreshToken: null,
        //tenant_uuid: "",
        //userUuid: ""
      })
      
    default:
      return state
  }
}

export default auth
