import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, message, Select, Modal, Notification } from "antd"
import { withRouter } from "react-router-dom"

import * as error_codes from '../../constants/error_codes'
import * as actions from '../../constants'
import "./styles.css"

import colors from "../../styles";
import CenteredRow from "../CenteredRow";
import { areaTypeParameterTypes } from "../../constants/areaTypeParameterTypes";

const { darkIconColor } = colors;

const Option = Select.Option;
const FormItem = Form.Item;
const TextArea = Input.TextArea

const AreaTypeManagmentParameterForm = ({ form, history, type, area_type_parameter_name, setIsModalVisible, isModalVisible }) => {
  const isLoading = useSelector(state => state.rootReducer.loaders.loadingAreaTypeParameterEdition)
  const isReady = !useSelector(state => state.rootReducer.loaders.loadingAreaTypeParameterEdition)
  const parameterDetails = useSelector(state => state.rootReducer.areaTypeParameters.currentAreaTypeParameterDetails)
  const isAreaTypeParameterCreatedEdited = useSelector(state => state.rootReducer.areaTypeParameters.isAreaTypeParameterCreatedEdited)
  const error = useSelector((state) => state.rootReducer.areaTypeParameters.errorCreateEditAreaTypeParameter)

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, isFieldTouched } = form
  const update = type === "update"

  const dispatch = useDispatch()

  useEffect(
    () => {
      resetFields()
      if (update) {
        dispatch(({ type: actions.FETCH_AREA_TYPE_PARAMETER, payload: { area_type_parameter_name } }))
      } 
    },
    [area_type_parameter_name]
  )

  useEffect(
    () => {
      if (!isModalVisible) {
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
      }
    },
    [isModalVisible]
  )

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { label, name, description, type } = values;
        const form = {}
        let update_form = {}

        if (label && label.length) {
          form.label = label;
          if (update && isFieldTouched("label")) {
            update_form.label = form.label
          }
        }
        if (description && description.length) {
          form.description = description
          update_form.description = description
        }

        if (name && name.length) {
          form.name = name
          if (update && isFieldTouched("name")) {
            update_form.name = form.name
          }
        }

        if (type && type.length) {
          form.type = type
          if (update) {
            update_form.type = form.type
          }
        }

        if (!update) {
          dispatch(({
            type: actions.CREATE_AREA_TYPE_PARAMETER,
            payload: {
              form
            }
          }))
        } else {
          dispatch(({
            type: actions.EDIT_AREA_TYPE_PARAMETER,
            payload: {
              area_type_parameter_name,
              form: update_form
            }
          }))
        }
      } else {
        message.error(err);
      }
    })
  }

  const handleLabelChange = e => {
    if(!update){
      var slugify = require('slugify')
      setFieldsValue({'name': slugify(e.target.value, '_').toLowerCase()})
    } 
  }

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
              setIsModalVisible(false)
              dispatch(({ type: actions.ERROR_FETCH_AREA_TYPE_PARAMETERS_LIST }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (isAreaTypeParameterCreatedEdited) {
        message.success(update ? `Area type parameter successfully updated` : `Area type parameter successfully added`)
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
        setIsModalVisible(false)
        resetFields()
      }
    },
    [isAreaTypeParameterCreatedEdited]
  )

  const handleCancel = (e) => {
    dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
    setIsModalVisible(false)
  }

  return (
    <CenteredRow>
      <Form onSubmit={handleSubmit}>
        <FormItem label="Parameter name">
          {getFieldDecorator("label", {
            rules: [{ required: !update, message: "Name is required" }],
            initialValue: update ? parameterDetails && parameterDetails.label : ""
          })(
            <Input
              disabled={!isReady}
              placeholder="Parameter name"
              onChange={handleLabelChange}
            />
          )}
        </FormItem>
        <FormItem label="Database ID">
          {getFieldDecorator("name", {
            rules: [{ required: !update, message: "Database ID is required" }],
            initialValue: update ? parameterDetails && parameterDetails.name : ""
          })(
            <Input
              disabled={!isReady}
              placeholder="Parameter ID on database"
            />
          )}
        </FormItem>
        <FormItem label="Type">
          {getFieldDecorator("type", {
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (value && value.length >= 1) {
                    callback();
                  } else {
                    callback("A type is required");
                  }
                }
              }
            ],
            initialValue: update
              ? parameterDetails && parameterDetails.value_type
              : ""
          })(
            <Select
              mode="single"
              disabled={!isReady}
              style={{ width: "100%" }}
              placeholder="Select parameter's type"
            >
              {areaTypeParameterTypes && areaTypeParameterTypes.map(type => (
                <Option key={type.name}>{type.label}</Option>
              ))}
            </Select>
          )}
        </FormItem>
        <FormItem label="Description">
          {getFieldDecorator("description", {
            initialValue: update
            ? parameterDetails && parameterDetails.description
            : ""
          })(
            <TextArea
              disabled={!isReady}
              placeholder="Parameter description"
            />
          )}
        </FormItem>
          
        <FormItem style={{ textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "40%" }}
            size="large"
            icon={update ? "upload" : "plus"}
            loading={isLoading}
          >
            {update ? " Save" : " Add Parameter"}
          </Button>
          <Button
            type="default"
            htmlType="button"
            onClick={handleCancel}
            icon={"close"}
            style={{ width: "40%", marginLeft: "1em" }}
            size="large"
          >
            Cancel
          </Button>
        </FormItem>
      </Form>
    </CenteredRow>
  )
};

const WrappedAreaTypeManagmentParameterForm = Form.create()(AreaTypeManagmentParameterForm);

export default withRouter(WrappedAreaTypeManagmentParameterForm);
