import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchElementNotifications = (tenant_uuid, uuid, element_type ) => action(actions.FETCH_ELEMENTS_NOTIFICATION, {tenant_uuid, element_type})
export const successFetchUpdateElementsNotifications = data => action(actions.SUCCESS_FETCH_ELEMENTS_NOTIFICATION, { data })
export const errorFetchUpdateElementsNotifications = error => action(actions.ERROR_FETCH_UPDATE_ELEMENTS_NOTIFICATION, { error })
export const cleanElementsNotificationsError = () => action(actions.CLEAN_ELEMENTS_NOTIFICATION_ERROR)
export const cleanElementsNotificationsState = () => action(actions.CLEAN_ELEMENTS_NOTIFICATION)


export const fetchNotifications = (tenant_uuid, uuid, element_type, transport ) => action(actions.FETCH_NOTIFICATION, {tenant_uuid, uuid, element_type, transport})
export const successFetchUpdateNotifications = data => action(actions.SUCCESS_FETCH_UPDATE_NOTIFICATION, { data })
export const errorFetchUpdateNotifications = error => action(actions.ERROR_FETCH_UPDATE_NOTIFICATION, { error })
export const cleanNotificationsError = () => action(actions.CLEAN_NOTIFICATION_ERROR)
export const cleanNotificationsState = () => action(actions.CLEAN_NOTIFICATION_STATE)


export const fetchUserNotifications = (tenant_uuid, uuid, element_type, transport ) => action(actions.FETCH_USER_NOTIFICATION, {tenant_uuid, uuid, element_type, transport})
export const successFetchUpdateUserNotifications = data => action(actions.SUCCESS_FETCH_UPDATE_USER_NOTIFICATION, { data })
export const errorFetchUpdateUserNotifications = error => action(actions.ERROR_FETCH_UPDATE_USER_NOTIFICATION, { error })
export const cleanUserNotificationsError = () => action(actions.CLEAN_USER_NOTIFICATION_ERROR)
export const cleanUserNotificationsState = () => action(actions.CLEAN_USER_NOTIFICATION_STATE)


//SEND
export const fetchSendNotifications = (tenant_uuid, uuid, element_type, transport ) => action(actions.FETCH_SEND_NOTIFICATION, {tenant_uuid, uuid, element_type, transport})
export const successFetchUpdateSendNotifications = data => action(actions.SUCCESS_FETCH_UPDATE_SEND_NOTIFICATION, { data })
export const errorFetchUpdateSendNotifications = error => action(actions.ERROR_FETCH_UPDATE_SEND_NOTIFICATION, { error })
export const cleanSendNotificationsError = () => action(actions.CLEAN_SEND_NOTIFICATION_ERROR)
export const cleanSendNotificationsState = () => action(actions.CLEAN_SEND_NOTIFICATION_STATE)

export const fetchUserSendNotifications = (tenant_uuid, uuid, element_type, transport ) => action(actions.FETCH_USER_SEND_NOTIFICATION, {tenant_uuid, uuid, element_type, transport})
export const successFetchUpdateUserSendNotifications = data => action(actions.SUCCESS_FETCH_UPDATE_USER_SEND_NOTIFICATION, { data })
export const errorFetchUpdateUserSendNotifications = error => action(actions.ERROR_FETCH_UPDATE_USER_SEND_NOTIFICATION, { error })
export const cleanUserSendNotificationsError = () => action(actions.CLEAN_USER_SEND_NOTIFICATION_ERROR)
export const cleanUserSendNotificationsState = () => action(actions.CLEAN_USER_SEND_NOTIFICATION_STATE)

//export const fetchSendSMSNotifications = (tenant_uuid, uuid, element_type ) => action(actions.FETCH_SEND_SMS_NOTIFICATION, {tenant_uuid, uuid, element_type})
export const successCreateNotification = data => action(actions.SUCCESS_CREATE_NOTIFICATION, { data })
export const errorCreateNotification = error => action(actions.ERROR_CREATE_NOTIFICATION, { error })
export const cleanCreateNotification = () => action(actions.CLEAN_CREATE_NOTIFICATION)


//TOAST
export const fetchNewNotifications = (tenant_uuid, uuid, element_type ) => action(actions.FETCH_NEW_NOTIFICATION, {tenant_uuid, uuid, element_type})
export const successFetchAddNotifications = data => action(actions.SUCCESS_FETCH_ADD_NOTIFICATION, { data })
export const successFetchUpdateNotificationReceipts = data => action(actions.SUCCESS_FETCH_UPDATE_NOTIFICATION_RECEIPT, { data })

export const updateToastNotifications = (tenant_uuid, notification_uuid, form, update_detail, call) => action(actions.UPDATE_TOAST_NOTIFICATION, {tenant_uuid, notification_uuid, form, update_detail, call})
export const successFetchUpdateToastNotifications = data => action(actions.SUCCESS_FETCH_UPDATE_TOAST_NOTIFICATION, { data })
export const removeToastNotifications = data => action(actions.REMOVE_TOAST_NOTIFICATION, { data })
export const errorFetchUpdateToastNotifications = error => action(actions.ERROR_FETCH_UPDATE_TOAST_NOTIFICATION, { error })
export const cleanToastNotificationsError = () => action(actions.CLEAN_TOAST_NOTIFICATION_ERROR)
export const cleanToastNotificationsState = () => action(actions.CLEAN_TOAST_NOTIFICATION_STATE)

export const successAddToastNotifications = data => action(actions.SUCCESS_FETCH_ADD_NOTIFICATION, { data })


export const setNotificationDetail = data => action(actions.SET_NOTIFICATION_DETAIL, data)
export const setNotificationUserDetail = data => action(actions.SET_NOTIFICATION_USER_DETAIL, data)
