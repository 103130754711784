import * as actions from '../constants'
import moment from 'moment'

const initialState = {
  circadianRhythmData: null,
  update: false,
  error: null,
}

const circadianRhythm = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_CIRCADIAN_RHYTHM_DATA:
      const circadianRhythmData = Object.assign({}, action.payload.data, {
        circadian_start_date_time: moment.utc(action.payload.data.circadian_start_date_time, 'YYYY-MM-DD HH:mm:ss').local()
      })

      return Object.assign({}, state, {
        circadianRhythmData: circadianRhythmData,
        update: false,
        error: null
      })

    case actions.SUCCESS_UPDATE_CIRCADIAN_RHYTHM:
      const circadianRhythmUpdateData = Object.assign({}, action.payload.data, {
        circadian_start_date_time: moment.utc(action.payload.data.circadian_start_date_time, 'YYYY-MM-DD HH:mm:ss').local()
      })

      return Object.assign({}, state, {
        circadianRhythmData: circadianRhythmUpdateData,
        update: true,
        error: null
      })

    case actions.ERROR_CIRCADIAN_RHYTHM:
      return Object.assign({}, state, {
        circadianRhythmData: null,
        update: false,
        error: action.payload.error
      })

    case actions.CLEAN_CIRCADIAN_RHYTHM_ERROR:
      return Object.assign({}, state, {
        error: null,
        update: false,
      })

    case actions.CLEAN_CIRCADIAN_RHYTHM_STATE:
      return Object.assign({}, state, {
        error: null,
        circadianRhythmData: null,
        update: false,
      })
      
    default:
      return state
  }
}

export default circadianRhythm