import {
    getGroupLevelsOverrideService,
    getGroupOnDetectOffOverrideService,
    putGroupLevelsOverridePropagationService,
    putGroupOnOverridePropagationService,
} from "../services";

export const fetchGroupLevelsOverrideApi = async (tenant_uuid, uuid) => {

    const response = await getGroupLevelsOverrideService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const fetchGroupOnDetectOffOverrideApi = async (tenant_uuid, uuid) => {

    const response = await getGroupOnDetectOffOverrideService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateGroupLevelsOverrideApi = async (tenant_uuid, uuid, form) => {

    const response = await putGroupLevelsOverridePropagationService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}

export const updateOnOverrideApi = async (tenant_uuid, uuid, form) => {

    const response = await putGroupOnOverridePropagationService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}