import * as actions from '../constants';

const initialState = {
  peopleAnalyticsData: null,
  error: null,
}

const peopleAnalytics = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_PEOPLEANALYTICS:
      return Object.assign({}, state, {
        peopleAnalyticsData: action.payload.data,
        error: null
      })
    case actions.ERROR_FETCH_UPDATE_PEOPLEANALYTICS:
      return Object.assign({}, state, {
        peopleAnalyticsData: null,
        error: action.payload.error
      })
    case actions.CLEAN_PEOPLEANALYTICS_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_PEOPLEANALYTICS_STATE:
      return Object.assign({}, state, {
        error: null,
        peopleAnalyticsData: null,
      })
    default:
      return state
  }
}

export default peopleAnalytics