import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, message, Select, Switch, Popconfirm, Tooltip, Row, Col } from "antd"
import { withRouter } from "react-router-dom"

import * as error_codes from '../../constants/error_codes'
import * as actions from '../../constants'
import "./styles.css"

import colors from "../../styles";
import CenteredRow from "../CenteredRow";
import { areaTypeParameterTypes } from "../../constants/areaTypeParameterTypes";

const { darkIconColor } = colors;

const Option = Select.Option;
const FormItem = Form.Item;

const AreaTypeManagmentParameterValueForm = ({ form, history, type, area_type_parameter_value_data }) => {
  const isLoading = useSelector(state => state.rootReducer.loaders.loadingAreaTypeEdition)
  const isReady = !useSelector(state => state.rootReducer.loaders.loadingAreaTypeEdition)
  const areaTypeDetails = useSelector(state => state.rootReducer.areaTypeParameters.currentAreaTypeDetails)
  const areaTypeParameters = useSelector(state => state.rootReducer.areaTypeParameters.currentAreaTypeAllowedParameters)
  const isAreaTypeParameterValueCreatedEdited = useSelector(state => state.rootReducer.areaTypeParameters.isAreaTypeParameterValueCreatedEdited)
  const lastEditedAreaTypeParameterValueID = useSelector(state => state.rootReducer.areaTypeParameters.lastEditedAreaTypeParameterValueID)
  const [isBoolean, setIsBoolean] = useState(area_type_parameter_value_data && area_type_parameter_value_data.parameter_type && area_type_parameter_value_data.parameter_type === 'boolean')

  const { getFieldDecorator, validateFields, getFieldValue, resetFields, setFieldsValue, isFieldTouched } = form

  const update = (type === "update")
  const [saveDisable, setSaveDisable] = useState(update)

  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(({ type: actions.UPDATE_AREA_TYPE_ALLOWED_PARAMETERS }))
    },
    [areaTypeDetails.parameters_values]
  )

  useEffect(
    () => {
      setIsBoolean(area_type_parameter_value_data && area_type_parameter_value_data.parameter_type && area_type_parameter_value_data.parameter_type === 'boolean')
    },
    [area_type_parameter_value_data]
  )

  useEffect(
    () => {
      if (isFieldTouched("value") && update) {
        setSaveDisable(false)
      }
    },
    [getFieldValue("value")]
  )

  useEffect(
    () => {
      if (isAreaTypeParameterValueCreatedEdited) {
        if (update) {
          if (lastEditedAreaTypeParameterValueID === area_type_parameter_value_data.id) {
            setSaveDisable(true)
          }
        }else{
          resetFields()
        }
      }
    },
    [isAreaTypeParameterValueCreatedEdited]
  )


  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { label, type, value } = values;
        const form = {}
        let update_form = {}

        if (label && label.length) {
          form.parameter_label = label;
          form.parameter_name = label
          if (update) {
            update_form.parameter_label = label
            update_form.parameter_name = area_type_parameter_value_data.parameter_name
          }
        }

        if (type && type.length) {
          form.parameter_type = type
          form.create = false
          if (update) {
            update_form.parameter_type = form.parameter_type
            update_form.create = false
          }
        }

        if (!isBoolean && value && value.length) {
          form.value = value
          if (update) {
            update_form.value = value
          }
        }

        if (isBoolean) {
          if (value) {
            form.value = 'true'
            if (update) {
              update_form.value = 'true'
            }
          } else {
            form.value = 'false'
            if (update) {
              update_form.value = 'false'
            }
          }
        }

        if (!update) {
          dispatch(({
            type: actions.ADD_AREA_TYPE_PARAMETER_VALUE,
            payload: {
              area_type_name: areaTypeDetails.name,
              isCreate: true,
              form
            }
          }))
        } else {
          dispatch(({
            type: actions.ADD_AREA_TYPE_PARAMETER_VALUE,
            payload: {
              area_type_name: areaTypeDetails.name,
              isCreate: false,
              form: update_form
            }
          }))
        }
      } else {
        message.error(err);
      }
    })
  }

  const handleDelete = (e) => {
    dispatch(({ type: actions.DELETE_AREA_TYPE_PARAMETER_VALUE, payload: { area_type_name: areaTypeDetails.name, parameter_name: area_type_parameter_value_data.parameter_name } }))
    dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
  }

  const handleOnChangeParameterSelector = parameter_name => {
    const parameter = areaTypeParameters.filter(parameter => parameter.name === parameter_name)[0]
    setFieldsValue({ "type": parameter.value_type })
    if (parameter.value_type === 'boolean') {
      setIsBoolean(true)
    } else {
      setIsBoolean(false)
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row type="flex" justify="space-between">
          <Col xs={8}>
            <FormItem wrapperCol={{ sm: 24 }}
              style={{ width: "60%", height: 60, marginBottom: 0, marginRight: 0 }}
            >
              {getFieldDecorator("label", {
                initialValue: update ? area_type_parameter_value_data && area_type_parameter_value_data.parameter_name : []
                , rules: [
                  { required: true, message: 'A parameter is required' },
                ]
              })(
                <Select
                  mode="single"
                  style={{ width: "100%" }}
                  disabled={!isReady || update}
                  placeholder="Parameter Name"
                  onChange={handleOnChangeParameterSelector}
                >
                  {areaTypeParameters && areaTypeParameters.map(parameter => (
                    <Option key={parameter.name}>{parameter.label}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={4}>
            <FormItem>
              {getFieldDecorator("type", {
                initialValue: update
                  ? area_type_parameter_value_data && area_type_parameter_value_data.parameter_type
                  : ""
              })(
                <Select
                  mode="single"
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Select parameter's type"
                >
                  {areaTypeParameterTypes && areaTypeParameterTypes.map(type => (
                    <Option key={type.name}>{type.label}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={4}>
            {isBoolean ?
              <FormItem>
                {getFieldDecorator("value", {
                  initialValue: update
                    && area_type_parameter_value_data && area_type_parameter_value_data.value === 'true' ? true
                    : false,
                  valuePropName: 'checked'
                })(
                  <Switch disabled={!isReady} />
                )}
              </FormItem>
              :
              <FormItem>
                {getFieldDecorator("value", {
                  initialValue: update
                    ? area_type_parameter_value_data && area_type_parameter_value_data.value
                    : "",
                  rules: [
                    { required: true, message: 'A value is required' },
                  ]
                })(
                  <Input
                    disabled={!isReady}
                    placeholder="Parameter Value"
                  />
                )}
              </FormItem>
            }

          </Col>
          <Col xs={8}>
            <FormItem style={{ textAlign: "center" }}>
              <Tooltip title={update ? " Save" : " Add Parameter Value"}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={saveDisable}
                >
                  {update ? "Save" : "Add"}
                </Button>
              </Tooltip>
              {update && (
                <Popconfirm
                  title={`You are about to delete parameter value ${area_type_parameter_value_data.parameter_label}. Are you sure?`}
                  onConfirm={() => handleDelete()}
                  okText="Yes"
                  cancelText="No"
                  placement="bottomRight"
                >
                  <Tooltip title="Delete value">
                    <Button
                      type="danger"
                      htmlType="button"
                      style={{ marginLeft: "1em" }}
                      loading={isLoading}
                    >
                      Delete
                  </Button>
                  </Tooltip>
                </Popconfirm>
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  )
};

const WrappedAreaTypeManagmentParameterValueForm = Form.create()(AreaTypeManagmentParameterValueForm);

export default withRouter(WrappedAreaTypeManagmentParameterValueForm);
