export const NO_ERROR = 0  // enum.auto()

export const ERR_INTERNAL_SERVER_ERROR = 500  // maps to http error code
export const ERR_PERMISSION_REQUIRED = 403
export const ERR_LOST_CONNECTION = 10000
export const ERR_INVALID_TOKEN = 10001

// errors based in 2000
export const ERR_INVALID_TENANT = 2000  // Invalid tenant.
// The MIME type for the request must be application/json.
export const ERR_INVALID_REQUEST_JSON_EXPECTED = 2001
// 'JSON parse error in request payload.'
export const ERR_INVALID_REQUEST_PARSE_ERROR = 2002
export const ERR_COMPANY_ALREADY_EXISTS = 2003  // 'company already exist.'
export const ERR_INVALID_COMPANY = 2004  // 'Invalid company.'
export const ERR_INVALID_TENANTUUID_FOR_COMPANY = 2005  // 'Invalid tenant uuid for company.'
// 'Company can not be deleted, company has building(s).'
export const ERR_COMPANY_CANNOT_DELETE_CONTAINS_BUILDINGS = 2006
export const ERR_BUILDING_ALREADY_EXISTS = 2007  // 'building already exist.'
export const ERR_INVALID_BUILDING = 2008  // 'Invalid building.'
// 'Invalid company uuid for building.'
export const ERR_INVALID_COMPANYUUID_FOR_BUILDING = 2009
// 'Building can not be deleted, building has group(s).'
export const ERR_BUILDING_CANNOT_DELETE_CONTAINS_GROUPS = 2010
// 'Only Logical groups can be added to building.'
export const ERR_ONLY_LOGICAL_GROUPS_CAN_BE_ADDED_TO_BUILDING = 2011
export const ERR_GROUP_ALREADY_EXIST = 2012  // 'group name already exist.',
export const ERR_INVALID_GROUP = 2013  // 'Invalid group.'
export const ERR_INVALID_BUILDINGUUID_FOR_GROUP = 2014  // 'Invalid building uuid for group.'
// 'Only Logical groups can be added/updated for building.'
export const ERR_ONLY_LOGICAL_GROUPS_CAN_BE_UPDATED_TO_BUILDING = 2015
// 'Parent group can not be control group.'
export const ERR_PARENT_GROUP_CANNOT_BE_CONTROL_GROUP = 2016
// 'Orphan branch of groups found. Does not belong to any building.'
export const ERR_ORPHAN_BRANCH_OF_GROUPS_FOUND = 2017
export const ERR_INVALID_TENANT_FOR_GROUPUUID = 2018  // 'Invalid tenant for group uuid'
// 'Group can not be deleted, group has group(s).'
export const ERR_GRPOUP_CANNOT_DELETE_CONTAINS_GROUPS = 2019
// 'Group can not be deleted, group has device(s).'
export const ERR_GRPOUP_CANNOT_DELETE_CONTAINS_DEVICES = 2020
export const ERR_GROUP_ALREADY_HAS_OWN_SCHEDULE = 2021  // 'Group already has own schedule.'
export const ERR_SCHEDULE_NAME_EXISTS = 2022  // 'schedule with name already exist.'
export const ERR_GROUP_DOES_NOT_HAS_OWN_SCHEDULE = 2023  // 'Group does not has own schedule.'
// 'Group own schedule does not match schedule_uuid.'
export const ERR_GROUP_OWN_SCHEDULE_DOES_NOT_MATCH_SCHEDULE_UUID = 2024
// 'Group own or overriden schedule does not match schedule_uuid.'
export const ERR_GROUP_OWN_OR_OVERRIDEN_SCHEDULE_DOES_NOT_MATCH_SCHEDULE_UUID = 2025
// 'You can not add devices to Logical groups.'
export const ERR_CANNOT_ADD_DEVICES_TO_LOGICAL_GROUP = 2026
// 'device with aws_id already exists.'
export const ERR_DEVICE_WITH_AWSID_ALREADY_EXISTS = 2027
export const ERR_DEVICE_WITH_AWSID_NOT_FOUND = 2028  // 'device with aws_id not found.'
// 'device with aws_id not in group provided.'
export const ERR_DEVICE_WIHT_AWSID_NOT_IN_GROUP_PROVIDED = 2029
// 'You can only set level for Control groups.'
export const ERR_LEVEL_CAN_BE_SET_ONLY_FOR_CONTROL_GROUPS = 2030
// only slider level sparameter is missing (this refers to post payload)
export const ERR_SLIDERLEVEL_PARAMETER_MISSING = 2031
// 'You can only get level for Control groups.'
export const ERR_LEVEL_CAN_BE_GET_ONLY_FOR_CONTROL_GROUPS = 2032
export const ERR_SETON_ONLY_ON_SUPPORTED = 2033  // only 'on' field supported in payload
export const ERR_INVALID_SENSOR_NAME = 2034
// only lux supported  (this refers to post/put payload)
export const ERR_SETLUX_ONLY_LUX_SUPPORTED = 2035
export const ERR_CALIBRATION_CAN_BE_EXECUTE_ONLY_FOR_CONTROL_GROUPS = 2036
export const ERR_INVALID_DATETIME_VALUE = 2037
// wrong datetime interval start date must be older than end date
export const ERR_INVALID_DATETIME_INTERVAL = 2038

export const ERR_SETON_ON_REQUIRED = 2039  // required 'on' field supported in payload
// required 'on' field supported in payload
export const ERR_SETON_ONLY_ONE_SHUTDOWN_OR_DISABLE_REQUIRED = 2040

// name length too short
export const ERR_NAME_TOO_SHORT = 2041

// group_uuid is required
export const ERR_GROUP_UUID_REQUIRED = 2042

// aws_id is required
export const ERR_AWS_ID_REQUIRED = 2043

// building_uuid or parent_id required
export const ERR_BUILDINGUUID_PARENTID_REQUIRED = 2044

// building_uuid and parent_id are mutually exclusives
export const ERR_BUILDINGUUID_PARENTID_MUTUALLY_EXCLUSIVES = 2045

// group with that name already exists in building
export const ERR_INVALID_GROUP_NAME_IN_BUILDING = 2046

// group with that name already exists in group
export const ERR_INVALID_GROUP_NAME_IN_GROUP = 2047

// user do not exists or invalid user uuid
export const ERR_INVALID_USER = 2048
// user only can be added to control groups
export const ERR_USERS_CAN_BE_ADDED_ONLY_TO_CONTROL_GROUPS = 2049
// user already added to group
export const ERR_USER_ALREADY_ADDED_TO_GROUP = 2050
// user do no exists on group
export const ERR_USER_DOES_NOT_EXISTS_ON_GROUP = 2051
export const ERR_WATTS_VALUE_BETWEEN_0_AND_100 = 2052

// recursive relationships not allowed
export const ERR_NOT_RECURSIVE_RELATIONSHIPS = 2053

// roles error
export const ERR_INVALID_ROLE = 2054
export const ERR_ROLE_NAME_REQUIRED = 2055
export const ERR_ROLE_NAME_ALREADY_IN_USE = 2056

// users
export const ERR_USERNAME_TO_SHORT = 2057
export const ERR_USERNAME_ALREADY_EXISTS = 2058
export const ERR_PASSWORD_MISMATCH = 2059
export const ERR_PASSWORD_TO_SHORT = 2060
export const ERR_ROLE_REQUIRED = 2061
export const ERR_ROLE_ALREADY_ADDED_TO_USER = 2062
export const ERR_USER_HAS_NOT_ROLE = 2063
export const ERR_FLAG_FALSE_ROLE_LIST_NO_EMPTY = 2064
export const ERR_FLAG_FALSE_GROUP_LIST_NO_EMPTY = 2065

// level override errors
export const ERR_BOTH_WHITE_LEVELS_REQUIRED = 2066
export const ERR_WHITE_LEVELS_SUM_100 = 2067

// dashboard do not exists or invalid dashboard uuid
export const ERR_INVALID_DASHBOARD = 2068
export const ERR_INVALID_DASHBOARD_PANEL = 2069
export const ERR_INVALID_DASHBOARD_NOT_MATCH_PANEL = 2070

export const ERR_INVALID_DEVICE = 2071  // 'Invalid device.'
export const ERR_INVALID_GROUP_FOR_DEVICE = 2072 

// other users code errors
export const ERR_ONLY_ONE_ROLE_PER_USER = 2073
export const ERR_COMPANY_ADMIN_REQUIRE_COMPANY = 2074
export const ERR_COMPANY_ADMIN_ONLY_ONE_COMPANY = 2075
export const ERR_BUILDING_ADMIN_REQUIRE_BUILDING = 2076
export const ERR_OFFICE_ADMIN_REQUIRE_GROUP = 2077
export const ERR_FLAG_FALSE_COMPANIES_LIST_NO_EMPTY = 2078
export const ERR_FLAG_FALSE_BUILDINGS_LIST_NO_EMPTY = 2079
export const ERR_OFFICE_ADMIN_ONLY_ONE_GROUP = 2080
export const ERR_OFFICE_ADMIN_CAN_BE_ADDED_ONLY_LOGICAL_GROUP = 2081
export const ERR_USER_BUILDINGS_MUST_BELONG_TO_THE_SAME_COMPANY = 2082
export const ERR_USER_GROUP_MUST_BELONG_TO_SAME_COMPANY = 2083
export const ERR_USER_GROUP_MUST_BELONG_TO_SAME_BUILDING = 2084
export const ERR_USER_GROUP_MUST_CHILD_OF_A_GROUP = 2085
export const ERR_USER_REQUIRE_GROUP = 2086

// metadata image error
export const ERR_INVALID_IMAGE = 2087

// Group can not be deleted, group has associated user(s).'
export const ERR_GRPOUP_CANNOT_DELETE_CONTAINS_ASSOCIATED_USERS = 2088

// people analytics
export const ERR_FLOOR_PLAN_AREA_VALUE_BETWEEN_0_AND_10000 = 2089
export const ERR_WORKSPACE_AREA_VALUE_BETWEEN_2_AND_10 = 2090
export const ERR_OCCUPANCY_LIMIT_VALUE_BETWEEN_1_AND_1000 = 2091

// max_on and max_off error
export const ERR_MAX_ON_NOT_LOWER_THAN_60_SECS = 2092
export const ERR_MAX_OFF_NOT_LOWER_THAN_60_SECS = 2093

// company modules errors
export const ERR_AT_LEAST_A_MODULE_ITS_REQUIRED_FOR_COMPANY = 2094
export const ERR_INVALID_MODULE_NAME = 2095

// area type parameters errors
export const ERR_AREA_TYPE_ALREADY_EXISTS = 2096
export const ERR_INVALID_AREA_TYPE = 2097
export const ERR_AREA_TYPE_PARAMETER_NAME_ALREADY_EXISTS = 2098
export const ERR_AREA_TYPE_PARAMETER_INVALID_VALUE_TYPE = 2099
export const ERR_AREA_TYPE_PARAMETER_INVALID_VALUE_WITH_TYPE = 2100
export const ERR_AREA_TYPE_PARAMETER_NAME_REQUIRED = 2101
export const ERR_AREA_TYPE_INVALID_PARAMETER = 2102
export const ERR_AREA_TYPE_INVALID_PARAMETER_VALUE = 2103
export const ERR_AREA_TYPE_PARAMETER_TYPE_MODIFY_WITH_EXISTING_VALUES = 2104
export const ERR_AREA_TYPE_PARAMETER_NOT_SLUGIFIED_NAME = 2105
export const ERR_AREA_TYPE_PARAMETER_ID_ALREADY_EXISTS = 2106

//reset password token errors
export const    ERR_RESET_TOKEN_EXPIRED = 2107
export const    ERR_INVALID_RESET_TOKEN = 2108

// notification
export const    ERR_INVALID_NOTIFICATION = 2109
export const    ERR_INVALID_NOTIFICATION_RECEIPT = 2110

// 2fa 
export const   ERR_USER_2FA_NO_ACTIVE = 2111

// energy analysis
export const ERR_INVALID_PEAK_RANGE = 2112
export const ERR_PEAK_INTERVALS_OVERLAPS = 2113
export const ERR_INVALID_PEAK_COST = 2114
export const ERR_INVALID_DATE_RANGE = 2115