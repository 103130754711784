import React, { useState, useEffect } from "react";
import { Switch, Slider, Button, Select, Row, Col, DatePicker, InputNumber } from "antd";
import moment from 'moment'
import StyledIcon from "../StyledIcon";
import colors from "../../styles";
import "./style.css";

const { Option } = Select;
const { primary_color_hover } = colors;
const { MINIMUM_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, MAXIMUM_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, DEFAULT_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, MINIMUM_NUMBER_OF_FRAMES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP,
  DEFAULT_TIME_REFRESH_DATA_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, MINIMUM_TIME_REFRESH_DATA_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, MAXIMUM_TIME_REFRESH_DATA_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP,
  MINIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, MAXIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, ANIMATION_TRANSITION_FRAME_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, DEFAULT_DOWNSAMPLING_SECONDS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP } = window.Configs;

const VectorOptionMap = ({
  dataLoading,
  selectDate,
  config,
  thedata,
  getVectorData,

  maxAnimationIndex,
  animationindex,
  setAnimationIndexParent,
  animationPlay,
  setPlay,
  animationSpeed,
  setSpeed,
  setRefreshMinutesInterval,
  indexNewValue,
  setJumpToRefresh,
  
  vectorSpiner,
}) => {
  const [selected, setSelected] = useState(true)
  const [date, setDate] = useState("")
  const [range, setRange] = useState(DEFAULT_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP)
  const [interval, setInterval] = useState(DEFAULT_DOWNSAMPLING_SECONDS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP)
  const [loading, setLoading] = useState(false)
  const [intervalOption, setintervalOption] = useState([1, 2, 3, 5, 8, 13, 21, 35])

  const [refresh, setRefresh] = useState(false)
  const [refreshMinutes, setRefreshMinutes] = useState(DEFAULT_TIME_REFRESH_DATA_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP)

  const [animMaxIndex, setAnimMaxIndex] = useState(maxAnimationIndex)

  const [jump, setJump] = useState(false)

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading])

  useEffect(() => {
    setAnimMaxIndex(maxAnimationIndex)
  }, [maxAnimationIndex])

  useEffect(() => {

  }, [indexNewValue])


  const handleChangeDate = param => {
    let stringdate = ""
    if (param && param._d) {
      stringdate = param._d.getFullYear() + "-" + (Number(param._d.getMonth()) + 1) + "-" + (Number(param._d.getDate()) > 9 ? param._d.getDate() : "0" + param._d.getDate()) + " " + (Number(param._d.getHours()) > 9 ? param._d.getHours() : "0" + param._d.getHours()) + ":" + (Number(param._d.getMinutes()) > 9 ? param._d.getMinutes() : "0" + param._d.getMinutes()) + ":" + (Number(param._d.getSeconds()) > 9 ? param._d.getSeconds() : "0" + param._d.getSeconds());
    } else {
      stringdate = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    setDate(stringdate);
    //selectDate(stringdate);
  };

  const getCurrentMoment = () => {
    const stringdate = moment().format("YYYY-MM-DD HH:mm:ss")
    return moment(stringdate, "YYYY-MM-DD HH:mm:ss")
  }

  const changeSelected = param => {
    setSelected(!selected)
  };

  const handleChangeRange = param => {
    setRange(param)
    if (!checkNumberOfFrame(param, interval)) {
      setInterval(intervalOption[0])
    }
  };

  const handleChangeInterval = param => {
    setInterval(param)
  };

  const handleClickPlay = param => {
    setPlay(true)
  };

  const handleClickPause = param => {
    setPlay(false)
  };

  const handleClickPlus = param => {
    setSpeed(0.5)
  };

  const handleClickMinus = param => {
    setSpeed(-0.5)
  };

  const handleJumpToRefresh = param => {
    setJump(param)
  }

  const changeRefresh = param => {
    setRefresh(param)
    setRefreshMinutes(param === false ? 0 : refreshMinutes)
  };

  const handleChangeRefreshMiutes = param => {
    setRefreshMinutes(refresh === false ? 0 : param)
  };


  const handleChangeAnimationIndex = (index) => {
    setAnimationIndexParent(index)
  }

  const handleSubmit = () => {
    let end = moment().utc().format("YYYY-MM-DD HH:mm:ss")
    let tmp_end = moment().utc().format("YYYY-MM-DD HH:mm:ss")
    const thedate = date
    if (!selected && date !== "") {
      end = moment(thedate, "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")
      tmp_end = moment(thedate, "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")
    }

    if (selected) {
      setJumpToRefresh(jump)
      setRefreshMinutesInterval((selected===false || refresh === false) ? 0 : refreshMinutes)
    }


    //console.log(end)

    const tmp_start = moment(tmp_end, "YYYY-MM-DD HH:mm:ss").subtract(range, "minutes")
    const start = tmp_start.format("YYYY-MM-DD HH:mm:ss")
    const downsampling_secs = interval

    const form = {
      start: start,
      end: end,
      downsampling_secs: downsampling_secs,
      data_analysis_status_type: 1,
      refresh: (refresh === false || selected === false ? 0 : refreshMinutes),
      range: range,
    };

    getVectorData(form)

    //console.log(start)
  };

  const checkNumberOfFrame = (new_range, new_interval) => {
    return ((new_range * 60) / new_interval) > MINIMUM_NUMBER_OF_FRAMES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP
  }

  const getNewDataIndex = () => {
    let marks = {};

    if (indexNewValue > 0) {
      const index = indexNewValue * ANIMATION_TRANSITION_FRAME_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP
      marks[index] = {
        style: {
          color: '#287d8e',
          fontSize: '11px',
        },
        label: <strong>refreshed data</strong>,
      }
    }
    return marks;

  }



  const render = () => {
    //const { stat, date } = this.state;
    return (
      <>
        {loading ? (
          <div className="loader">
            <StyledIcon type="loading" color={primary_color_hover} scope="loader" />
          </div>

        ) : (
            <div className="frecuencycontrol">
              {true && (
                <Row style={{ padding: "0px 0px" }}>
                  <Slider min={0} max={animMaxIndex} disabled={animationPlay} onChange={handleChangeAnimationIndex} onAfterChange={handleChangeAnimationIndex} value={animationindex} marks={getNewDataIndex()} />
                </Row>
              )}
              <Row style={{ padding: "0px 0px 20px 0px" }}>
                <Col span={6}>
                  {(!animationPlay) && (
                    <Button type="primary" shape="circle" onClick={handleClickPlay} disabled={maxAnimationIndex === 0}>
                      <svg style={{ height: "14px", margin: "8px 6px 8px 10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                        aria-describedby="desc" role="img">
                        <title>Play animation</title>
                        <desc>A solid styled icon from Orion Icon Library.</desc>
                        <path data-name="layer1"
                          fill="#202020" d="M6 2l52 30L6 62V2z"></path>
                      </svg>
                    </Button>
                  )}
                  {(animationPlay) && (
                    <Button type="primary" shape="circle" onClick={handleClickPause} disabled={maxAnimationIndex === 0}>
                      <svg style={{ height: "18px", margin: "6px" }} viewBox="0 0 16 16" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                      </svg>
                    </Button>
                  )}

                  <Button type="default" shape="circle" onClick={handleClickPlus} disabled={maxAnimationIndex === 0 || animationSpeed === MAXIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                      aria-describedby="desc" role="img">
                      <title>Aument animation speed</title>
                      <desc>A solid styled icon from Orion Icon Library.</desc>
                      <path data-name="layer1"
                        fill="#202020" d="M44 29h-9v-9h-6v9h-9v6h9v9h6v-9h9v-6z"></path>
                    </svg>
                  </Button>
                  <Button type="default" shape="circle" onClick={handleClickMinus} disabled={maxAnimationIndex === 0 || animationSpeed === MINIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                      aria-describedby="desc" role="img">
                      <title>Reduce animation speed</title>
                      <desc>A solid styled icon from Orion Icon Library.</desc>
                      <path data-name="layer1"
                        fill="#202020" d="M20 30h24v6H20z"></path>
                    </svg></Button>
                </Col>
              </Row>

              <Row style={{ padding: "10px 0px" }}>
                <Col span={12} >
                  <span style={{ marginRight: "10px" }}>Minute ranges:</span>
                  <InputNumber min={MINIMUM_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP} max={MAXIMUM_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP} defaultValue={DEFAULT_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP} onChange={handleChangeRange} />
                </Col>
                <Col span={12} >
                  <span style={{ marginRight: "10px" }}>Interval seconds:</span>
                  <Select value={interval} style={{ width: 120 }} onChange={handleChangeInterval}>
                    {intervalOption.map((item) => (
                      (checkNumberOfFrame(range, item) && <Option key={"interval" + item} value={item}>{item}</Option>)
                    )
                    )}
                  </Select>
                </Col>
              </Row>

              <Row style={{ padding: "10px 0px" }}>

                <Col span={12}>
                  <span style={{ marginRight: "10px" }}>Selected Current Date:</span>
                  <Switch onChange={changeSelected} defaultChecked />
                </Col>
                <Col span={12}>
                  {(false) && (
                    <div>
                      <span style={{ marginRight: "10px" }}>Date:</span>
                      Current
                    </div>)}
                  {(!selected) && (
                    <div>
                      <span style={{ marginRight: "10px" }}>Date:</span>
                      <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" defaultValue={getCurrentMoment()} onChange={handleChangeDate} />
                    </div>)}
                </Col>
              </Row>
              {(selected) && (
                <Row style={{ padding: "10px 0px" }}>
                  <Col span={12}>
                    <span style={{ marginRight: "10px" }}>Refresh data:</span>
                    <Switch onChange={changeRefresh} />
                  </Col>
                  <Col span={12}>
                    {(refresh) && (
                      <div>
                        <span style={{ marginRight: "10px" }}>Minutes for refresh data:</span>
                        <InputNumber min={MINIMUM_TIME_REFRESH_DATA_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP} max={MAXIMUM_TIME_REFRESH_DATA_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP} defaultValue={DEFAULT_TIME_REFRESH_DATA_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP} onChange={handleChangeRefreshMiutes} />
                      </div>
                    )}
                  </Col>
                </Row>
              )}

              {(selected && refresh) && (
                <Row style={{ padding: "10px 0px" }}>
                  <Col span={24}>
                    <span style={{ marginRight: "10px" }}>Jump refresh data:</span>
                    <Switch onChange={handleJumpToRefresh} />
                  </Col>
                </Row>
              )}


              <Row style={{ padding: "10px 0px" }}>
                <Col span={12} >
                  <Button type="primary" onClick={handleSubmit}>Get Data</Button>
                </Col>
              </Row>

            </div>
          )}
      </>
    )
  }

  return (
    render()
  )
}

export default VectorOptionMap;
