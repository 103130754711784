import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { LocaleProvider, ConfigProvider } from "antd";
import { createBrowserHistory } from "history";
import "./index.css";

import { ConnectedRouter } from "connected-react-router";

import enUS from "antd/lib/locale-provider/en_US";

import IntlWrapper from "./components/IntlWrapper";

import configureStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
import Routes from "./routes";
import rootSaga from './sagas/combined'

export const history = createBrowserHistory();

const initialState = {};
const { store, sagaMiddleware } = configureStore(initialState, history)
sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConfigProvider locale={enUS}>
        <IntlWrapper>
          <Routes />
        </IntlWrapper>
      </ConfigProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
