import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
} from "antd";
import moment from 'moment'
import Loader from "../Loader";
import DevicesHeatMap from "../DevicesHeatMap/indexfiredetection";
import { UserContext } from "../../context";
import "./style.css";


import { getGroupMetadataImage, getGroupMetadataDevice, getGroupMetadataExits, getDataHour } from "../../components/DevicesHeatMap/heatmapfunction";

const ElementDetailExitsLocationTab = ({
  history,
  element,
  tabKey
}) => {
  const [loading, setLoading] = useState(false);

  //control show or not visibility of frecuency map select sensor, date and hour control
  const [data, setData] = useState({
    image: "",
    imageDisplay: "horizontal",
    devices: [],
    extis: [],
    chartData: [
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
    ],
    data: []
  });
  const [config, setConfig] = useState({
    shouldRenderFrecuencyMap: false,
    sensorName: 'ir',
    day: moment().format("YYYY-MM-DD"),
    hour: Number(moment().format("HH")),
    'element_uuid': element.uuid
  });

  const [readyimageload, setReadyImageLoad] = useState("empty");
  const { userData: { tenant_uuid } } = useContext(UserContext);

  const dispatch = useDispatch()

  useEffect(() => {
    if (tabKey === "devices_details") {
      ; (async () => {

        loadHeatmap()
      })()
    }
    
  },
    [tabKey])//,element

  const loadHeatmap = async () =>{
    setLoading(true);
        try {
          const newconfig = {
            shouldRenderFrecuencyMap: false,
            sensorName: 'ir',
            day: moment().format("YYYY-MM-DD"),
            hour: Number(moment().format("HH")),
            'element_uuid': element.uuid
          };

          const newdata = await getDataHeatMap(tenant_uuid, newconfig)

          //console.log(newdata)
          setReadyImageLoad("empty")

          setLoading(false);

          await setConfig(
            newconfig
          )
          await setData(
            newdata
          )
        } catch{

        }
  }

  const getDataHeatMap = async (tenant_uuid, theconfig) => {
    let image = "";
    let devices = []
    let exits = []

    const responseimage = await getGroupMetadataImage(history, dispatch, tenant_uuid, theconfig.element_uuid);
    
  
    if(responseimage && !responseimage.error){
      image = responseimage.data;
      const responsedevices = await getGroupMetadataDevice(history, dispatch, tenant_uuid, theconfig.element_uuid);
      if(responsedevices && !responsedevices.error){
        devices = responsedevices.data

        const responseexits = await getGroupMetadataExits(history, dispatch, tenant_uuid, theconfig.element_uuid);
        if(responseexits && !responseexits.error){
          exits = responseexits.data
        }else if(responseexits && responseexits.message === "Connection is lost!"){
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: "Connection is lost!",
            okText: "Retry",
            onOk: () => loadHeatmap(),
          })
        }
      }else if(responsedevices && responsedevices.message === "Connection is lost!"){
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => loadHeatmap(),
        })
      }
    }else if(responseimage && responseimage.message === "Connection is lost!"){
      Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => loadHeatmap(),
        })
    }

    const data = await getDataHour(history, dispatch, tenant_uuid, theconfig, devices);

    let emptydevices = []
    for (let index = 0; index < devices.length; index++) {
      emptydevices.push([devices[index].x, devices[index].y, "1"])
    }
    let newchartdata = [];
    for (let index = 0; index < 24; index++) {
      newchartdata.push({ devices: emptydevices })
    }
    newchartdata[theconfig.hour] = (data[0]);

    //return ({ image: image, devices: devices, chartData: data, data: data[theconfig.hour].devices })
    return ({ image: image, devices: devices, exits: exits, chartData: newchartdata, data: data[0].devices })
  }


  //when change the image, check dimension and asign correct display class
  useEffect(() => {
    setReadyImageLoad(data.image)

  },
    [data, config, data.image, data.devices, data.exits])

  const changeLoading = (value) => {
    setLoading(value);
  }

  //DevicesHeatMap function udate
  const updateParentConfig = (parentindex, parentconfig) => {
    setConfig(parentconfig);
  }

  //DevicesHeatMap function update
  const updateParentData = (parentindex, parentdata) => {
    setData(parentdata);
  }

  const render = () => {
    const content = (
      <>
        <DevicesHeatMap
          index={-1}
          editabled={true}
          parentdata={data}
          parentconfig={config}
          updateParentConfig={updateParentConfig}
          updateParentData={updateParentData}
          changeLoading={changeLoading} />
      </>
    );

    return (
      loading //isLoading
        ? <Loader />
        :
        <>
          {
            (readyimageload !== "empty" ?
              <>
                {content}
              </>
              : <Loader />)
          }
        </>
    );
  }

  return (
    render()
  )
}

export default ElementDetailExitsLocationTab;
