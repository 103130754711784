import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  message,
  Button,
  Modal,
  Table,
  Tooltip,
  Popconfirm,
  Icon,
  Input,
  Divider,
  Row,
  Col,
  Notification
} from "antd";
import { withRouter } from "react-router-dom";
import "./style.css";

import colors from "../../styles";
import StyledIcon from "../../components/StyledIcon";
import AreaTypeManagmentParameterForm from "../../components/AreaTypeManagmentParameterForm"
import Loader from "../../components/Loader"
import * as error_codes from '../../constants/error_codes'
import * as actions from '../../constants'

const { danger, success } = colors;
const Search = Input.Search;

const AreaTypeManagmentParametersList = ({ history }) => {
  const [searchValue, setSearchValue] = useState("")
  const [isParameterModalVisible, setIsParameterModalVisible] = useState(false)
  // const [isLoading, setIsLoading] = useState(false);
  const [parameterFormType, setParameterFormType] = useState("create")
  const [parameterName, setParameterName] = useState("")

  const parametersList = useSelector((state) => state.rootReducer.areaTypeParameters.areaTypeParameters)
  const areaTypeParametersFilteredList = useSelector((state) => state.rootReducer.areaTypeParameters.areaTypeParametersFilteredList)
  const managerRole = useSelector((state) => state.rootReducer.auth.rolename)
  const loading = useSelector(state => state.rootReducer.loaders.loadingAreaTypeParameters)
  const deletedAreaTypeParameter = useSelector(state => state.rootReducer.areaTypeParameters.deletedAreaTypeParameter)
  const error = useSelector((state) => state.rootReducer.areaTypeParameters.errorAreaTypeParameters)

  //const { userData: { tenant_uuid, claims } } = useContext(UserContext)

  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)

  const dispatch = useDispatch()

  useEffect(
    () => {
      (async () => {
        dispatch(({ type: actions.FETCH_AREA_TYPE_PARAMETERS_LIST }))
      })()
      return () => {
        //dispatch({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_MANAGMENT_STATE })
      }
    },
    []
  )

  const handleDelete = async area_type_parameter_name => {
    dispatch(({ type: actions.REMOVE_AREA_TYPE_PARAMETER, payload: { area_type_parameter_name } }))
  }

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({ type: actions.FETCH_AREA_TYPE_PARAMETERS_LIST }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (deletedAreaTypeParameter) {
        message.success("Area type parameter successfully deleted")
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
      }
    },
    [deletedAreaTypeParameter]
  )

  const userCanDoActions =
    domainClaims.CanRemoveAreaTypeParameter || domainClaims.CanUpdateAreaTypeParameter;

  const columns = userCanDoActions
    ? [
      {
        title: "Area type parameter name",
        dataIndex: "label",
        key: "name",
        width: "80%"
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <>
            {domainClaims.CanUpdateAreaTypeParameter ? (
              <>
                <Tooltip placement="topRight" title="Edit">
                  <span>
                    <StyledIcon
                      handleClick={() => {
                        setParameterName(record.name)
                        setParameterFormType("update")
                        setIsParameterModalVisible(true)
                      }}
                      type="edit"
                      scope="action"
                      color={success}
                    />
                  </span>
                </Tooltip>
                <Divider type="vertical" style={{ margin: "0 1em" }} />
              </>
            ) : null}
            {domainClaims.CanRemoveAreaTypeParameter ? (
              <Popconfirm
                title={`You are about to delete area type parameter ${record.label}. Are you sure?`}
                onConfirm={() => handleDelete(record.name)}
                okText="Yes"
                cancelText="No"
                placement="bottomRight"
              >
                <Tooltip placement="topRight" title="Delete">
                  <span>
                    <StyledIcon type="delete" scope="action" color={danger} />
                  </span>
                </Tooltip>
              </Popconfirm>
            ) : null}
          </>
        ),
        width: "20%",
        align: "center"
      }
    ]
    : [
      {
        title: "Area type parameter name",
        dataIndex: "label",
        key: "name",
        width: "100%"
      }
    ];

  return loading ? (
    <Loader />
  ) : (
      <div>
        <>
          <Row
            gutter={[8]}
            type="flex"
            justify="left"
            style={{
              width: "100%",
              marginBottom: "1.5em",
              marginLeft: "0",
              marginRight: "0"
            }}
          >
            <Col>
              <Button
                style={{ float: "left" }}
                type="primary"
                size="large"
                icon="plus"
                disabled={!domainClaims.CanCreateAreaTypeParameter}
                onClick={() => {
                  setIsParameterModalVisible(true)
                  setParameterFormType("create")
                }}
              >
                Add Area Type Parameter
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            style={{ width: "100%" }}
            size="large"
            columns={columns}
            dataSource={parametersList.map(parameter => {
              parameter.key = parameter.name;
              return parameter
            })}
          />
          <Modal
            title={
              <span>
                {parameterFormType === "create" && (
                  <div>
                    <Icon type="tags" />
                     Add Area Type Parameter
                  </div>
                )}
                {parameterFormType === "update" && (
                  <div>
                    <Icon type="tags" />
                     Update Area Type Parameter
                  </div>
                )}
              </span>
            }
            width="60vw"
            destroyOnClose={true}
            maskClosable={true}
            style={{ top: "2em" }}
            visible={isParameterModalVisible}
            onCancel={() => {
              setIsParameterModalVisible(false)
              dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
            }}
            footer={null}
          >
            {<AreaTypeManagmentParameterForm
              type={parameterFormType}
              setIsModalVisible={setIsParameterModalVisible}
              isModalVisible={isParameterModalVisible}
              area_type_parameter_name={parameterName}
            />}
          </Modal>
        </>

      </div>
    )
}

export default withRouter(AreaTypeManagmentParametersList)
