import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { Layout, Menu, Icon, Row, notification, Modal } from "antd";
import "./style.css";
import { AppContext } from "../../context";

import Loader from "../../components/Loader";
import UserManagement from "../UserManagement";
import CompanyManagement from "../CompanyManagement";
import PanelManagement from "../PanelManagement";
import AreaTypeParameterManagment from "../AreaTypesManagment";
import localStorageUtil from "../../utils/localStorageUtil";
import * as roles from "../../constants/roles"

import { SiderContext } from "antd/lib/layout/Sider";

const { ENABLED_DASHBOARD } = require('../../config.json');
const { Content, Sider } = Layout;

const Dashboard = () => {
  const [appData, setAppData] = useState({});
  //const [collapsed, setCollapsed] = useState(true);
  const managerRole = useSelector((state) => state.rootReducer.auth.rolename)


  let siderContent = [
    {
      title: "Users",
      content: () => <UserManagement />,
      disabled: false,
      icon: "user",
      path: "/dashboard/user",
      exact: true
    },
    {
      title: "Organization Chart",
      content: () => <CompanyManagement />,
      disabled: false,
      icon: "apartment",
      path: "/dashboard/company",
      exact: true
    },
    {
      title: "User Panels",
      content: () => <PanelManagement selectKey={selectKey} />,
      disabled: false,
      icon: "block",
      path: "/dashboard/user_panel",
      exact: true
    }
  ]

  if (managerRole === roles.USER_ROLE_SUPERADMIN) {
    siderContent.splice(1, 0,
      {
        title: "Area Types Managment",
        content: () => <AreaTypeParameterManagment selectKey={selectKey} />,
        disabled: false,
        icon: "appstore",
        path: "/dashboard/area_types",
        exact: true
      }
    )
  }

  const initialCurrentKey = () => {
    if (!localStorageUtil.get("siderKey") || localStorageUtil.get("siderKey") > (siderContent.length - 1).toString()) {
      return "0"
    } else {
      return localStorageUtil.get("siderKey")
    }
  }


  const [currentMenuKey, setCurrentMenuKey] = useState(
    initialCurrentKey()
  )


  //const onCollapse = collapsed => setCollapsed(collapsed);

  const handleClick = e => {
    localStorageUtil.set(e.key, "siderKey");
    setCurrentMenuKey(e.key);
  };

  const selectKey = key => {
    localStorageUtil.set(key, "siderKey");
    setCurrentMenuKey(key);
  }

  return !siderContent ? (
    <Row
      className="row-flex-padding container"
      type="flex"
      justify="center"
      align="middle"
    >
      <Loader />
    </Row>
  ) : (
      <AppContext.Provider value={{ appData, setAppData }}>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            theme="dark"
            // collapsible
            // breakpoint="xxl"
            collapsed={true}
          // onCollapse={onCollapse}
          >
            <div className="logo" />
            <Menu
              theme="dark"
              mode="vertical"
              selectedKeys={[currentMenuKey]}
              onClick={handleClick}
            >
              {siderContent.map((sider, index) => (

                ((sider.title !== "User Panels" || ENABLED_DASHBOARD) ?
                  <Menu.Item key={index} disabled={sider.disabled}>
                    <Link to={sider.path}>
                      <Icon type={sider.icon} />
                      <span className="nav-text">{sider.title}</span>
                    </Link>
                  </Menu.Item>
                  : "")

              ))}
            </Menu>
          </Sider>
          <Layout>
            <Content>
              <Switch>
                {siderContent.map((sider, index) => (
                  <Route
                    key={index}
                    path={sider.path}
                    exact={sider.exact}
                    component={sider.content}
                  />
                ))}
                <Redirect to={siderContent[currentMenuKey].path} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </AppContext.Provider>
    );
};

export default Dashboard;
