import { action } from '../utils/actionCreator'
import * as actions from '../constants'

export const toogleFetchingElement = ( flag ) => action(actions.TOOGLE_FETCHING_ELEMENT_LOADER, { flag })

export const toogleFetchingCompanyTreeLoader = flag => action(actions.TOOGLE_FETCHING_COMPANY_TREE_LOADER, { flag })

export const toogleCreateEditElementLoader = ( flag ) => action(actions.TOOGLE_CREATE_EDIT_ELEMENT_LOADER, { flag })

export const toogleLatestDataLoader = ( flag ) => action(actions.TOOGLE_LATEST_DATA_LOADER, { flag })

export const toogleChartDataLoader = ( flag ) => action(actions.TOOGLE_CHART_DATA_LOADER, { flag })

export const toogleIlluminanceLoader = ( flag ) => action(actions.TOOGLE_ILLUMINANCE_LOADER, { flag })

export const toogleLightsControlLoader = ( flag ) => action(actions.TOOGLE_LIGHTS_CONTROL_LOADER, { flag })

export const toogleControllersLoader = ( flag ) => action(actions.TOOGLE_CONTROLLERS_LOADER, { flag })

export const tooglePowerCalibrationLoader = ( flag ) => action(actions.TOOGLE_POWER_CALIBRATION_LOADER, { flag })

export const toogleBlueLightBoostLoader = ( flag ) => action(actions.TOOGLE_BLUE_LIGHT_BOOST_LOADER, { flag })

export const toogleCircadianRhythmLoader = ( flag ) => action(actions.TOOGLE_CIRCADIAN_RHYTHM_LOADER, { flag })

export const toogleAggregationRequestLoader = ( flag ) => action(actions.TOOGLE_AGGREGATION_REQUEST_LOADER, { flag })

export const toogleLoginLoader = ( flag ) => action(actions.TOOGLE_LOGIN_LOADER, { flag })

export const toogleUsersLoader = ( flag ) => action(actions.TOOGLE_USERS_LOADER, { flag })

export const toogleUserEditionLoader = ( flag ) => action(actions.TOOGLE_USER_EDITION_LOADER, { flag })

export const tooglePeopleAnalyticsLoader = ( flag ) => action(actions.TOOGLE_PEOPLEANALYTICS_LOADER, { flag })

export const toogleUVControlLoader = ( flag ) => action(actions.TOOGLE_UV_CONTROL_LOADER, { flag })

export const toogleAdvancedScheduleLoader = ( flag ) => action(actions.TOOGLE_ADVANCED_SCHEDULE_LOADER, { flag })

export const toogleAreaTypesLoader = ( flag ) => action(actions.TOOGLE_AREA_TYPES_LOADER, { flag })

export const toogleAreaTypeParametersLoader = ( flag ) => action(actions.TOOGLE_AREA_TYPE_PARAMETERS_LOADER, { flag })

export const toogleAreaTypeEditionLoader = ( flag ) => action(actions.TOOGLE_AREA_TYPE_EDITION_LOADER, { flag })

export const toogleAreaTypeParameterValueEditionLoader = ( flag ) => action(actions.TOOGLE_AREA_TYPE_PARAMETER_VALUE_EDITION, { flag })

export const toogleAreaTypeParameterEditionLoader = ( flag ) => action(actions.TOOGLE_AREA_TYPE_PARAMETER_EDITION, { flag })

export const toogleRequestPasswordResetLoader = ( flag ) => action(actions.TOOGLE_REQUEST_PASSWORD_RESET_LOADER, { flag })

export const toogleChangePasswordTokenLoaders = ( flag ) => action(actions.TOOGLE_CHANGE_PASSWORD_TOKEN_LOADER, { flag })

export const toogleNotificationLoader = ( flag ) => action(actions.TOOGLE_NOTIFICATION_LOADER, { flag })

export const toogleUserNotificationLoader = ( flag ) => action(actions.TOOGLE_USER_NOTIFICATION_LOADER, { flag })

export const toogleSendNotificationLoader = ( flag ) => action(actions.TOOGLE_SEND_NOTIFICATION_LOADER, { flag })

export const toogleUserSendNotificationLoader = ( flag ) => action(actions.TOOGLE_USER_SEND_NOTIFICATION_LOADER, { flag })

export const toogleCreateNotificationLoader = ( flag ) => action(actions.TOOGLE_CREATE_NOTIFICATION_LOADER, { flag })

export const toogleElementsNotificationLoader = ( flag ) => action(actions.TOOGLE_ELEMENTS_NOTIFICATION_LOADER, { flag })

export const toogleGetUser2faQrLoader = ( flag ) => action(actions.TOOGLE_GET_USER_2FA_QR_LOADER, { flag })

export const toogleUserProfileLoader = ( flag ) => action(actions.TOOGLE_USER_PROFILE_LOADER, { flag })

export const toogleSoundMaskingLoader = ( flag ) => action(actions.TOOGLE_SOUND_MASKING_LOADER, { flag })

export const toogleEnergyAnalysisOverviewLoader = ( flag ) => action(actions.TOOGLE_ENERGY_ANALYSIS_OVERVIEW_LOADER, { flag })

export const toogleEnergyConsumptionOverviewLoader = ( flag ) => action(actions.TOOGLE_ENERGY_CONSUMPTION_OVERVIEW_LOADER, { flag })

export const toogleEnergyChartLoader = ( flag ) => action(actions.TOOGLE_ENERGY_CHART_LOADER, { flag })

