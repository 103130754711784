import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  Col,
  Modal,
  Icon,
  Button,
  Row,
  Card,
  message,
  Select
} from "antd";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import ModuleLayout from "../../components/ModuleLayout";
import ModuleLightsControl from "../../components/ModuleLightsControl";
import ModulePeopleAnalytics from "../../components/ModulePeopleAnalytics";
import ModuleUVControl from "../../components/ModuleUVControl";
import ModuleEnergyAnalysis from "../../components/ModuleEnergyAnalysis";
import ModuleNotification from "../../components/ModuleNotification";
import ModuleFireDetection from "../../components/ModuleFireDetection";
import ModuleSoundMasking from "../../components/ModuleSoundMasking";
import ModuleEnergyAi from "../../components/ModuleEnergyAi";
import TreeCompanyBuildingGroups from "../../components/TreeCompanyBuildingGroups";
import "./style.css";
import CreateEditElementForm from "../../components/CreateEditElementForm";
import Settings from "../../components/Settings";
import SettingControllers from "../../components/Settings/indexcontrollers";
import SettingsPeopleAnalytics from "../../components/Settings/indexpeopleanalytics";
import SettingsEnergyAnalysis from "../../components/Settings/indexEnergyAnalysis";
import StyledIcon from "../../components/StyledIcon";
import colors from "../../styles";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import * as helpers from '../../utils/helpers'
import SearchTreeElement from "../../components/SearchTreeElement";

const { Option } = Select
const { darkIconColor } = colors;

const CompanyManagement = ({ history }) => {

  const treeRef = useRef(null);
  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)
  const managerRole = useSelector((state) => state.rootReducer.auth.rolename)
  const managerCompany = useSelector((state) => state.rootReducer.auth.company)
  const managerBuildings = useSelector((state) => state.rootReducer.auth.buildings)
  const managerGroup = useSelector((state) => state.rootReducer.auth.group)

  const treeData = useSelector((state) => state.rootReducer.companyManagment.companyTreeData)
  const defaultExpandedUuid = useSelector((state) => state.rootReducer.companyManagment.defaultExpandedUuid)
  const [expandedKeysState, setExpandedKeys] = useState([])
  const elementDetails = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const isLoading = useSelector(state => state.rootReducer.loaders.loadingCompanyTree)
  const deletedElement = useSelector((state) => state.rootReducer.companyManagment.deletedElement)
  const deletedElementType = useSelector((state) => state.rootReducer.companyManagment.deletedElementType)
  const fetchingDetails = useSelector(state => state.rootReducer.loaders.isFetchingElement)
  const user_modules = useSelector(state => state.rootReducer.auth.modules)
  const error = useSelector(state => state.rootReducer.companyManagment.error)

  // Modal info
  const [isModalVisible, setIsModalVisible] = useState(false);
  const modalType = useSelector((state) => state.rootReducer.companyManagment.modalType)
  const [actionType, setActionType] = useState("");

  //TreeCompanyBuildigGroups
  const info_redirect_heatmap = useSelector(state => state.rootReducer.redirect.heatmap_tab)
  const [selectedParentKey, setSelectParentKey] = useState("");
  const [redirectKey, setRedirectKey] = useState("");
  const [selectSearch, setSelectSearch] = useState("")

  const [moduleType, setModuleType] = useState(user_modules[0].name);

  const dispatch = useDispatch()

  /**
   * type is to describe the tree node type
   * 1 -> company
   * 2 -> building
   * 3 -> logical group
   * 4 -> control group
   * 5 -> mix group
   * 6 -> user
   */

  useEffect(() => {
    dispatch({ type: actions.FETCH_COMPANY_TREE_DATA, payload: { tenant_uuid: tenant_uuid, rolename: managerRole, user_companies: managerCompany, user_buildings: managerBuildings, user_groups: managerGroup } })

    return () => {
      dispatch({ type: actions.CLEAN_COMPANY_MANAGMENT_STATES })
    }
  },
    []
  )

  useEffect(
    () => {
      if (treeData) {
        treeRef.current = treeData;

        if (treeData !== [] && info_redirect_heatmap && info_redirect_heatmap.loading && info_redirect_heatmap.loading === "ready") {
          const arrayclickposition = helpers.getArrayClickPosition(treeData, info_redirect_heatmap.uuid);
          setRedirectKey(info_redirect_heatmap.uuid)
          dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: info_redirect_heatmap.group_type } })
          dispatch({ type: actions.SET_CLICK_POSITION, payload: { position: arrayclickposition } })
          dispatch({ type: actions.FETCH_ELEMENT, payload: { tenant_uuid: info_redirect_heatmap.tenant_uuid, uuid: info_redirect_heatmap.uuid, group_type: info_redirect_heatmap.group_type, module: moduleType } })
          dispatch(({ type: actions.REDIRECT_DEVICES, payload: { redirect: true } }))

          setRedirectKey(info_redirect_heatmap.uuid)
        }

      }

      return () => {
        dispatch({ type: actions.REDIRECT_HEATMAP, payload: { tenant_uuid: "", uuid: "", group_type: "", loading: "false" } })
      }

    },
    [treeData]
  )

  useEffect(
    () => {
      dispatch(({ type: actions.REDIRECT_DEVICES, payload: { redirect: false } }))
    },
    [elementDetails]
  )

  useEffect(
    () => {
      if (deletedElement) {
        message.success(`${deletedElementType}deleted`)
        dispatch({ type: actions.RESET_DELETE_COMPANY_ELEMENT_POPUP })
      }
    },
    [deletedElement]
  )

  useEffect(
    () => {
      if (error) {
        if (error.error_code === error_codes.ERR_LOST_CONNECTION
          || error.error_code === error_codes.ERR_INVALID_COMPANY
          || error.error_code === error_codes.ERR_INVALID_BUILDING
          || error.error_code === error_codes.ERR_INVALID_GROUP) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch({ type: actions.FETCH_COMPANY_TREE_DATA, payload: { tenant_uuid: tenant_uuid, rolename: managerRole, user_companies: managerCompany, user_buildings: managerBuildings, user_groups: managerGroup } })
              dispatch({ type: actions.CLEAN_COMPANY_MANAGMENT_STATES })
            },
          })
        } else if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          message.error(error.message);
          history.push("/login")
          dispatch({ type: actions.LOG_OUT })
        } else {
          message.error(error.message)
          // dispatch({ type: actions.CLEAN_COMPANY_MANAGMENT_STATES })
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (defaultExpandedUuid && defaultExpandedUuid !== "") {
        setExpandedKeys([...expandedKeysState, defaultExpandedUuid])
      }
    },
    [defaultExpandedUuid]
  )

  const handleSelect = async (selectedKeys, info) => {

    if (!("autoSelected" in info.node.props.dataRef)) {
      dispatch({ type: actions.CLEAN_PEOPLEANALYTICS_STATE })
      dispatch({ type: actions.FETCH_ELEMENT, payload: { tenant_uuid: tenant_uuid, uuid: info.node.props.dataRef.uuid, group_type: info.node.props.dataRef.group_type, module: moduleType } })
    }

    dispatch({ type: actions.SET_CLICK_POSITION, payload: { position: info.node.props.pos.substring(2).split("-") } })
    setSelectParentKey(helpers.getParentKey(info.node.props.dataRef.uuid, treeData) + "");
  }

  const handleRightClick = async ({ node }) => {

    if (!("autoSelected" in node.props.dataRef)) {
      dispatch({ type: actions.CLEAN_PEOPLEANALYTICS_STATE })
      dispatch({ type: actions.FETCH_ELEMENT, payload: { tenant_uuid: tenant_uuid, uuid: node.props.dataRef.uuid, group_type: node.props.dataRef.group_type, module: moduleType } })
    }

    dispatch({ type: actions.SET_CLICK_POSITION, payload: { position: node.props.pos.substring(2).split("-") } })
    setSelectParentKey(helpers.getParentKey(node.props.dataRef.uuid, treeData) + "");
  }

  const typeChangedModule = async type => {
    setModuleType(type)
  }

  const handleSearchSelect = async element => {
    if (element !== "") {
      setSelectSearch(element)
    } else {
      setSelectSearch("")
    }
  }


  return isLoading ? (
    <Loader />
  ) : (
      <ModuleLayout
        direction="row"
        justify="space-around"
        align="top"
        navbarComponent={
          <>
            <Icon type="apartment" /> <span>Organization Chart</span>
          </>
        }
        contentComponent={
          <>
            <Row
              type="flex"
              justify="space-between"
              style={{
                width: "100%",
                marginBottom: "1.5em",
                marginLeft: "0",
                marginRight: "0"
              }}
            >

              <Col span={8}>
                {
                  domainClaims.CanCreateCompany && (

                    <Button
                      type="primary"
                      size="large"
                      icon="plus"
                      onClick={() => {
                        dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: "Company" } })
                        setIsModalVisible(true)
                        setActionType("add")
                      }}
                    >
                      Add Company
                    </Button>

                  )
                }
              </Col>

              <Col span={15}>
                <Select
                  style={{ minWidth: 150, marginTop: "10px" }}
                  placeholder=""
                  onChange={typeChangedModule}
                  value={moduleType}
                >
                  {user_modules && user_modules.map(module => (
                    <Option key={module.name} value={module.name}> <span style={{ textTransform: 'capitalize' }}>{module.label}</span></Option>
                  ))}

                </Select>
              </Col>

            </Row>
            <Row
              type="flex"
              justify="space-between"
              style={{
                width: "100%",
                marginBottom: "1.5em",
                marginLeft: "0",
                marginRight: "0"
              }}
            >
              <Col span={8}>
                <Row>
                  <Col>
                    <Card
                      title="Companies"
                      extra={isLoading
                        ? []
                        : [
                          <SearchTreeElement key="search" treeData={treeData} handleSearchSelect={handleSearchSelect} />
                        ]}
                    >
                      <TreeCompanyBuildingGroups
                        history={history}
                        treeData={treeData}
                        handleSelect={handleSelect}
                        handleRightClick={handleRightClick}
                        defaultExpanded={expandedKeysState}
                        selectedParentKey={selectedParentKey}
                        selectedKey={(elementDetails && elementDetails.uuid ? elementDetails.uuid + "" : "")}
                        redirectKey={redirectKey}
                        selectSearch={selectSearch}
                        overlayMenu={true}
                        moduleType={moduleType}
                        modalSetters={{
                          setIsModalVisible,
                          setActionType
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-between"
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    marginBottom: "1.5em",
                    marginLeft: "0",
                    marginRight: "0"
                  }}
                >
                  {
                    //domainClaims.CanCreateCompany
                    true && (
                      <Col>
                        {moduleType === "lightsControl" || moduleType === "peopleAnalytics" || moduleType === "uv-cControl" || moduleType === "energyAnalysis"  || moduleType === "notification"  || moduleType === "fireDetection"  || moduleType === "soundMasking" ? (
                          <Button
                            type="primary"
                            size="large"
                            disabled={elementDetails && elementDetails.group_type && elementDetails.group_type === "Control" ? false : true}
                            onClick={() => {
                              setActionType("settings");
                              dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: "Controllers" } })
                              //dispatch(({ type: actions.REDIRECT_SETTING_CONTROLLER_TAB, payload: { redirect: true } }))
                              setIsModalVisible(true);
                            }}
                          >
                            Edit Controllers
                          </Button>
                        ) : (<></>)}
                      </Col>
                    )
                  }

                </Row>
              </Col>

              <Col span={15}>
                <Row>
                  <Col>
                    {moduleType === "lightsControl" ? (
                      <ModuleLightsControl
                        putToLoad={fetchingDetails}
                        parentTab={"lights_control"}
                        modalSetters={
                          { setIsModalVisible, setActionType } // setParentUuid,
                        }
                      />
                    ) : (moduleType === "peopleAnalytics" ? (
                      <ModulePeopleAnalytics
                        putToLoad={fetchingDetails}
                        parentTab={"lastest_data"}
                        modalSetters={
                          { setIsModalVisible, setActionType } // setParentUuid,
                        }
                      />
                    ) : (moduleType === "uv-cControl" ? (
                      <ModuleUVControl
                        putToLoad={fetchingDetails}
                        parentTab={"lastest_data"}
                        modalSetters={
                          { setIsModalVisible, setActionType } // setParentUuid,
                        }
                      />
                    ) : (moduleType === "energyAnalysis" ? (
                      <ModuleEnergyAnalysis
                        putToLoad={fetchingDetails}
                        parentTab={"energy_analysis_overview"}
                        modalSetters={
                          { setIsModalVisible, setActionType } // setParentUuid,
                        }
                      />
                      ) : (moduleType === "energyAi" ? (
                        <ModuleEnergyAi
                          putToLoad={fetchingDetails}
                          parentTab={"general_details"}
                          modalSetters={
                            { setIsModalVisible, setActionType } // setParentUuid,
                          }
                        />
                    ) : (moduleType === "notification" ? (
                      <ModuleNotification
                        putToLoad={fetchingDetails}
                        parentTab={"lastest_data"}
                        modalSetters={
                          { setIsModalVisible, setActionType } // setParentUuid,
                        }
                      />
                    ): (moduleType === "fireDetection" ? (
                      <ModuleFireDetection
                        putToLoad={fetchingDetails}
                        parentTab={"lastest_data"}
                        modalSetters={
                          { setIsModalVisible, setActionType } // setParentUuid,
                        }
                      />
                    ) : (moduleType === "soundMasking" ? (
                      <ModuleSoundMasking
                        putToLoad={fetchingDetails}
                        parentTab={"lastest_data"}
                        modalSetters={
                          { setIsModalVisible, setActionType } // setParentUuid,
                        }
                      />
                    ) : (
                        <></>
                     ))))))))}

                  </Col>
                </Row>
              </Col>
            </Row>

            <Modal
              title={
                <span>
                  {modalType === "Company" ? (
                    <span>
                      <StyledIcon
                        type="bank"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` ${actionType === "add" ? "Add" : "Edit"} Company`}
                    </span>
                  ) : modalType === "Building" ? (
                    <span>
                      <StyledIcon
                        type="hdd"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` ${actionType === "add" ? "Add" : "Edit"} Building`}
                    </span>
                  ) : modalType === "Logical" ? (
                    <span>
                      <StyledIcon
                        type="apartment"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` ${actionType === "add" ? "Add" : "Edit"} Logical Group`}
                    </span>
                  ) : modalType === "Mix" ? (
                    <span>
                      <StyledIcon
                        type="appstore"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` ${actionType === "add" ? "Add" : "Edit"} Mix Group`}
                    </span>
                  ) : modalType === "Settings" && elementDetails ? (
                    <span>
                      <StyledIcon
                        type="sliders"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` Settings of ${elementDetails.group_type === "Building" ? "Building" : "Group"} ${elementDetails.name}`}
                    </span>
                  ) : modalType === "PeopleAnalytics" && elementDetails ? (
                    <span>
                      <StyledIcon
                        type="sliders"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` Settings of ${elementDetails.group_type === "Building" ? "Building" : "Group"} ${elementDetails.name}`}
                    </span>
                  ) : modalType === "EnergyAnalysis" && elementDetails ? (
                    <span>
                      <StyledIcon
                        type="sliders"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` Settings of ${elementDetails.group_type === "Building" ? "Building" : "Group"} ${elementDetails.name}`}
                    </span>
                  ) : modalType === "Controllers" && elementDetails ? (
                    <span>
                      <StyledIcon
                        type="sliders"
                        color={darkIconColor}
                        scope="login"
                      />
                      {` Settings of ${elementDetails.group_type === "Building" ? "Building" : "Group"} ${elementDetails.name}`}
                    </span>
                  ) : (
                                    <span>
                                      <StyledIcon
                                        type="control"
                                        color={darkIconColor}
                                        scope="login"
                                      />
                                      {` ${actionType === "add" ? "Add" : "Edit"} Control Group`}
                                    </span>
                                  )}
                </span>
              }
              width="60vw"
              maskClosable={true}
              style={{ top: "2em" }}
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
            >

              {
                modalType === "Settings" ?
                  (
                    <Settings
                      elementData={elementDetails}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                    />
                  ) : modalType === "Controllers" ?
                    (
                      <SettingControllers
                        elementData={elementDetails}
                        setIsModalVisible={setIsModalVisible}
                        isModalVisible={isModalVisible}
                      />
                    ) : modalType === "PeopleAnalytics" ?
                      (
                        <SettingsPeopleAnalytics
                          elementData={elementDetails}
                          setIsModalVisible={setIsModalVisible}
                          isModalVisible={isModalVisible}
                        />
                      ) : modalType === "EnergyAnalysis" ?
                        (
                          <SettingsEnergyAnalysis
                            elementData={elementDetails}
                            setIsModalVisible={setIsModalVisible}
                            isModalVisible={isModalVisible}
                          />
                        ) : (
                          <CreateEditElementForm
                            setIsModalVisible={setIsModalVisible}
                            isModalVisible={isModalVisible}
                            group_type={modalType}
                            type={actionType}
                          />
                        )
              }
            </Modal>
          </>
        }
      />
    );
};

export default withRouter(CompanyManagement);
