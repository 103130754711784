import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchPowerCalibrationData = (tenant_uuid, uuid) => action(actions.FETCH_POWER_CALIBRATION_DATA, {tenant_uuid, uuid })

export const successFetchPowerCalibrationData = data => action(actions.SUCCESS_FETCH_POWER_CALIBRATION_DATA, { data })

export const savePowerCalibrationSetup = (tenant_uuid, uuid, form) => action(actions.SAVE_POWER_CALIBRATION_SETUP, {tenant_uuid, uuid, form })

export const successSavePowerCalibrationSetup = data  => action(actions.SUCCESS_SAVE_POWER_CALIBRATION_SETUP, { data })

export const errorFetchUpdatePowerCalibration = error => action(actions.ERROR_FETCH_UPDATE_POWER_CALIBRATION, { error })

export const cleanPowerCaibrationState = () => action(actions.CLEAN_POWER_CALIBRATION_STATE)
