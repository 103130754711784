import {
    getEnergyAnalysisService
} from "../services";

export const fetchEnergyAnalysisApi = async (tenant_uuid, element_type, uuid) => {

    const response = await getEnergyAnalysisService(tenant_uuid, element_type, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}
