import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from "redux-saga"
import rootReducer  from "../reducers/combined";

export default function configureStore(initialState, history) {
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false
        })
      : compose;
  

  /*const store = createStore(
    combineReducers({
      router: connectRouter(history),
      rootReducer,
      initialState
    }),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );*/

  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      rootReducer,
      initialState
    }),
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./../reducers/combined", () => {
      const nextReducer = require("../reducers/combined").default;
      store.replaceReducer(nextReducer);
    });
  }

  return { store, sagaMiddleware }
}
