import * as actions from '../constants';

const initialState = {
  loadingLogin: false,
  loadingUsersList: false,
  loadingUserEdition: false,
  loadingUser2faQr: false,
  loadingUserProfile: false,
  isFetchingElement: false,
  loadingCompanyTree: false,
  isCreatingEditingElement: false,
  loadingIlluminance: false,
  loadingLatestData: false,
  loadingChartData: false,
  loadingLightsControl: false,
  loadingControllers: false,
  loadingPowerCalibration: false,
  loadingBlueLightBoost: false,
  loadingCircadianRhythm: false,
  loadingAggregationRequest: false,
  loadingAreaTypes: false,
  loadingAreaTypeParameters: false,
  loadingAreaTypeEdition: false,
  loadingAreaTypeParameterValueEdition: false,
  loadingAreaTypeParameterEdition: false,
  loadingRequestUserPasswordReset: false,
  loadingChangeUserPasswordToken: false,
  loadingEnergyAnalysisOverviewSettings: false,
  loadingEnergyConsumptionOverview: false,
  loadingEnergyChart: false,
  loadingNotification: false,
  loadingUserNotification: false,
  loadingUVControl: false,
  loadingAdvancedSchedule: false,
  loadingCreateNotification: false,
  loadingElementsNotification: false,
  loadingSoundMasking: false,
}

const loaders = (state = initialState, action) => {
  switch (action.type) {
    case actions.TOOGLE_LOGIN_LOADER:
      return Object.assign({}, state, {
        loadingLogin: action.payload.flag,
      })
    case actions.TOOGLE_USERS_LOADER:
      return Object.assign({}, state, {
        loadingUsersList: action.payload.flag,
      })
    case actions.TOOGLE_USER_EDITION_LOADER:
      return Object.assign({}, state, {
        loadingUserEdition: action.payload.flag,
      })
    case actions.TOOGLE_FETCHING_ELEMENT_LOADER:
      return Object.assign({}, state, {
        isFetchingElement: action.payload.flag,
      })
    case actions.TOOGLE_FETCHING_COMPANY_TREE_LOADER:
      return Object.assign({}, state, {
        loadingCompanyTree: action.payload.flag,
      })
    case actions.TOOGLE_CREATE_EDIT_ELEMENT_LOADER:
      return Object.assign({}, state, {
        isCreatingEditingElement: action.payload.flag,
      })
    case actions.TOOGLE_ILLUMINANCE_LOADER:
      return Object.assign({}, state, {
        loadingIlluminance: action.payload.flag,
      })
    case actions.TOOGLE_LATEST_DATA_LOADER:
      return Object.assign({}, state, {
        loadingLatestData: action.payload.flag,
      })
    case actions.TOOGLE_CHART_DATA_LOADER:
      return Object.assign({}, state, {
        loadingChartData: action.payload.flag,
      })
    case actions.TOOGLE_LIGHTS_CONTROL_LOADER:
      return Object.assign({}, state, {
        loadingLightsControl: action.payload.flag,
      })
    case actions.TOOGLE_CONTROLLERS_LOADER:
      return Object.assign({}, state, {
        loadingControllers: action.payload.flag,
      })
    case actions.TOOGLE_POWER_CALIBRATION_LOADER:
      return Object.assign({}, state, {
        loadingPowerCalibration: action.payload.flag,
      })
    case actions.TOOGLE_AGGREGATION_REQUEST_LOADER:
      return Object.assign({}, state, {
        loadingAggregationRequest: action.payload.flag,
      })
    case actions.TOOGLE_PEOPLEANALYTICS_LOADER:
      return Object.assign({}, state, {
        loadingPeopleAnalytics: action.payload.flag,
      })
    case actions.TOOGLE_UV_CONTROL_LOADER:
      return Object.assign({}, state, {
        loadingUVControl: action.payload.flag,
      })
    case actions.TOOGLE_ADVANCED_SCHEDULE_LOADER:
      return Object.assign({}, state, {
        loadingAdvancedSchedule: action.payload.flag,
      })
    // loaders states for area type parameters
    case actions.TOOGLE_AREA_TYPES_LOADER:
      return Object.assign({}, state, {
        loadingAreaTypes: action.payload.flag,
      })
    case actions.TOOGLE_AREA_TYPE_PARAMETERS_LOADER:
      return Object.assign({}, state, {
        loadingAreaTypeParameters: action.payload.flag,
      })
    case actions.TOOGLE_AREA_TYPE_EDITION_LOADER:
      return Object.assign({}, state, {
        loadingAreaTypeEdition: action.payload.flag,
      })
    case actions.TOOGLE_AREA_TYPE_PARAMETER_VALUE_EDITION:
      return Object.assign({}, state, {
        loadingAreaTypeParameterValueEdition: action.payload.flag,
      })
    case actions.TOOGLE_AREA_TYPE_PARAMETER_EDITION:
      return Object.assign({}, state, {
        loadingAreaTypeParameterEdition: action.payload.flag,
      })
    // toogles for user password reset
    case actions.TOOGLE_REQUEST_PASSWORD_RESET_LOADER:
      return Object.assign({}, state, {
        loadingRequestUserPasswordReset: action.payload.flag,
      })
    case actions.TOOGLE_CHANGE_PASSWORD_TOKEN_LOADER:
      return Object.assign({}, state, {
        loadingChangeUserPasswordToken: action.payload.flag,
      })
    case actions.TOOGLE_BLUE_LIGHT_BOOST_LOADER:
      return Object.assign({}, state, {
        loadingBlueLightBoost: action.payload.flag,
      })

    case actions.TOOGLE_CIRCADIAN_RHYTHM_LOADER:
      return Object.assign({}, state, {
        loadingCircadianRhythm: action.payload.flag,
      })
      
    //toogles for user profile
    case actions.TOOGLE_GET_USER_2FA_QR_LOADER:
      return Object.assign({}, state, {
        loadingUser2faQr: action.payload.flag,
      })

    case actions.TOOGLE_USER_PROFILE_LOADER:
      return Object.assign({}, state, {
        loadingUserProfile: action.payload.flag,
      })

    // toogles for notification message
    case actions.TOOGLE_NOTIFICATION_LOADER:
      return Object.assign({}, state, {
        loadingNotification: action.payload.flag,
      })
    case actions.TOOGLE_SEND_NOTIFICATION_LOADER:
      return Object.assign({}, state, {
        loadingNotification: action.payload.flag,
      })


    case actions.TOOGLE_USER_NOTIFICATION_LOADER:
      return Object.assign({}, state, {
        loadingUserNotification: action.payload.flag,
      })
    case actions.TOOGLE_USER_SEND_NOTIFICATION_LOADER:
      return Object.assign({}, state, {
        loadingUserNotification: action.payload.flag,
      })


    case actions.TOOGLE_CREATE_NOTIFICATION_LOADER:
      return Object.assign({}, state, {
        loadingCreateNotification: action.payload.flag,
      })
    case actions.TOOGLE_ELEMENTS_NOTIFICATION_LOADER:
      return Object.assign({}, state, {
        loadingElementsNotification: action.payload.flag,
      })

    //toogle loader sound masking
    case actions.TOOGLE_SOUND_MASKING_LOADER:
      return Object.assign({}, state, {
        loadingSoundMasking: action.payload.flag,
      })

    // toogle enery analysis overview
    case actions.TOOGLE_ENERGY_ANALYSIS_OVERVIEW_LOADER:
      return Object.assign({}, state, {
        loadingEnergyAnalysisOverviewSettings: action.payload.flag,
      })
    case actions.TOOGLE_ENERGY_CONSUMPTION_OVERVIEW_LOADER:
      return Object.assign({}, state, {
        loadingEnergyConsumptionOverview: action.payload.flag,
      })
    case actions.TOOGLE_ENERGY_CHART_LOADER:
      return Object.assign({}, state, {
        loadingEnergyChart: action.payload.flag,
      })

    default:
      return state
  }
}

export default loaders