import React, { useEffect } from "react";
import { Form, Input, Button, message, Card, Icon } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link } from "react-router-dom";
import StyledIcon from "../StyledIcon";
import "./styles.css";

import * as actions from '../../constants'
import colors from "../../styles";
const { darkIconColor } = colors;

const FormItem = Form.Item;

const LoginForm = ({
  form,
  history }) => {
  const loading = useSelector((state) => state.rootReducer.loaders.loadingLogin)
  const is_auth = useSelector((state) => state.rootReducer.auth.is_auth)
  const second_step_2fa = useSelector((state) => state.rootReducer.auth.second_step_2fa)
  const error = useSelector((state) => state.rootReducer.auth.error)
  const dispatch = useDispatch()
  const appDeployed = parseInt(process.env.REACT_APP_SET_ADMIN_API)

  const { getFieldDecorator, validateFields, resetFields } = form;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { username, password } = values
        dispatch(({ type: actions.LOG_IN, payload: { username, password } }))
      } else {
        message.error(err);
      }
    })
  }

  useEffect(
    () => {
      if (error !== null) {
        message.error(error.message);
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (is_auth) {
        resetFields()
        history.push("/")
        dispatch(({ type: actions.CLEAN_2FA_FIRST_STEP_STATE }))
      }
    },
    [is_auth]
  )

  useEffect(
    () => {
      if (second_step_2fa) {
        resetFields()
        dispatch(({ type: actions.CLEAN_2FA_FIRST_STEP_STATE }))
        history.push("/2fa_second_step")
      }
    },
    [second_step_2fa]
  )

  return (
    <>
      <Card>
        <img className="centered-img" src="/logo_login.png" alt="ILLUMENAI" />
        <br />
        <h3 className="centered-subtitle"><b>Admin App {(appDeployed === 1 || appDeployed === 2) && (<span>(<Icon type="bug" /> DEV)</span>)}</b></h3>
        <Form onSubmit={handleSubmit} className="login-form">
          <FormItem>
            {getFieldDecorator("username", {
              rules: [{ required: true, message: "Enter your user name" }]
            })(
              <Input
                prefix={
                  <StyledIcon type="mail" color={darkIconColor} scope="login" />
                }
                placeholder="Username"
                size="large"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Enter your password" }]
            })(
              <Input
                prefix={
                  <StyledIcon type="lock" color={darkIconColor} scope="login" />
                }
                type="password"
                placeholder="Password"
                size="large"
              />
            )}
          </FormItem>
          <FormItem style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "50%", marginTop: "2em" }}
              size="large"
              loading={loading}
            >
              LogIn
          </Button>
          </FormItem>
        </Form>
        <Link to="/forgot_password">
          <Button
            type="link"
            style={{ width: "100%", marginBottom: "2%" }}
            disabled={loading}
          >
            I forgot my password
              </Button>
        </Link>
      </Card>
    </>
  );
};

const WrappedLoginForm = Form.create()(LoginForm);

export default withRouter(WrappedLoginForm)