import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
    Tree,
    Icon
} from "antd";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../context";
import ContextMenu from "../../components/ContextMenu";
import { getTreeNodeIcon, getParentKey } from '../../utils/helpers'
import * as actions from '../../constants'
import './styles.scss'
const { TreeNode } = Tree;

const TreeCompanyBuildingGroups = ({
    history,
    treeData,
    handleSelect = () => {
        return;
    },
    handleRightClick = () => {
        return;
    },
    autoExpand = false,
    selectedParentKey = "",
    redirectKey = "",
    selectedKey = "",
    selectSearch = "",
    defaultExpanded = [],
    overlayMenu = false,
    moduleType = "",
    modalSetters = {
        setIsModalVisible: () => {
            return false;
        },
        setActionType: () => {
            return "";
        }
    } }) => {

    const {
        userData: { tenant_uuid }
    } = useContext(UserContext);

    const rolename = useSelector((state) => state.rootReducer.auth.rolename)

    const [defaultExpandedKeys, setDefaultExpandedKeys] = useState(defaultExpanded)
    const [expandedKeysState, setExpandedKeys] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true)
    const [currentSelectedKey, setCurrentSelectedKey] = useState("")
    const fetchingDetails = useSelector(state => state.rootReducer.loaders.isFetchingElement)

    const [autoKeySelect, setAutoKeySelect] = useState(true)

    const [selectSearchItem, setSelectSearchItem] = useState("")

    const [classDrag, setClassDrag] = useState("")

    const dispatch = useDispatch();

    useEffect(() => {
        setAutoExpandParent(true)
        setExpandedKeys([selectedKey, selectedParentKey])
    }, [selectedKey])

    useEffect(() => {
        setAutoExpandParent(true)
        setSelectSearchItem(selectSearch)
        setExpandedKeys([selectSearch, selectedKey, selectedParentKey])
    }, [selectSearch])

    useEffect(() => {
        setDefaultExpandedKeys(defaultExpanded)
    }, [defaultExpanded])

    useEffect(() => {
    }, [])

    const handleOnExpand = async expandedKeysparam => {
        setAutoExpandParent(false)
        setExpandedKeys(expandedKeysparam)
    }

    const thehandleSelect = async (selectedKeysparam, info) => {
        //const parentKey = getParentKey(selectedKeysparam+"", treeData)
        await setAutoExpandParent(true)
        setSelectSearchItem("")
        setAutoKeySelect(false)
        handleSelect(selectedKeysparam, info)
        //setCurrentSelectedKey(selectedKeysparam)
        if (selectedKeysparam + "" !== "") {
            setExpandedKeys([selectedKeysparam + ""])
        }

    }

    const thehandleRightClick = async ({ node }) => {
        await setAutoExpandParent(true)
        setAutoKeySelect(false)
        handleRightClick({ node });
    }

    const getClassNode = uuid => {
        let stringclass = ""
        stringclass = stringclass + ((uuid === redirectKey && autoKeySelect) ? "auto-selected " : "");
        stringclass = stringclass + (selectSearchItem === uuid ? "current-search " : "");
        stringclass = stringclass + (selectedKey === uuid ? "selected-key " : "");
        return stringclass
    }

    const renderTreeNodes = data =>
        data && data.length && data.map(item => {
            if (item.children) {
                return (
                    <TreeNode
                        title={!("autoSelected" in item) ? (
                            <ContextMenu
                                text={item.name}
                                parent={item}
                                icon={item.group_type}
                                moduleType={moduleType}
                                disableMenu={fetchingDetails}
                                overlayMenu={overlayMenu}
                                modalSetters={modalSetters}

                            />
                        ) :
                            <h3><b><Icon type={getTreeNodeIcon(item.group_type)} /> {item.name}</b></h3>

                        }
                        selectable={!("autoSelected" in item)}
                        className={getClassNode(item.uuid)}
                        key={item.uuid}
                        dataRef={item}
                    >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode
                    title={
                        <ContextMenu
                            text={item.name}
                            parent={item}
                            icon={item.group_type}
                            moduleType={moduleType}
                            disableMenu={fetchingDetails}
                            overlayMenu={overlayMenu}
                            modalSetters={modalSetters}
                        />
                    }
                    {...item}
                    key={item.uuid}
                    dataRef={item}
                    className={getClassNode(item.uuid)}

                />
            )
        })

    const onDragOver = info => {
        setExpandedKeys((expandedKeysState.filter(item => { return item !== info.node.props.eventKey })).concat(info.node.props.eventKey));
    };

    const onDragStart = info => {
        setClassDrag("is-drag")
    };

    const onDragEnd = info => {
        setClassDrag("")
    };

    const onDrop = info => {
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dragPos = info.dragNode.props.pos.split('-');
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        dropPos.splice(0, 1)
        dragPos.splice(0, 1)

        // check group control logical parent logical
        // check group logical parent building
        if (dragPos.length>=2 && dropPos.length>=2 && dragPos[0] === dropPos[0] && dragPos[1] === dropPos[1]) {
            if (((info.dragNode.props.dataRef.group_type === "Control" || info.dragNode.props.dataRef.group_type === "Logical" || info.dragNode.props.dataRef.group_type === "Mix") && (info.node.props.dataRef.group_type === "Logical" || info.node.props.dataRef.group_type === "Mix")) || ((info.dragNode.props.dataRef.group_type === "Logical" || info.dragNode.props.dataRef.group_type === "Mix") && info.node.props.dataRef.group_type === "Building" && rolename !== "OfficeAdministrator")) {
                dispatch(({ type: actions.CHANGE_ELEMENT_POSITION_TREE_DATA, payload: { tenant_uuid: tenant_uuid, group_uuid: dragKey, parent_uuid: dropKey, drag_position: dragPos, drop_position: dropPos } }))
            }
        }
    };

    return false ? (
        <Loader />
    ) : (
            <Tree
                onSelect={thehandleSelect}
                onRightClick={thehandleRightClick}
                autoExpandParent={autoExpandParent}
                expandedKeys={expandedKeysState.concat(defaultExpandedKeys)}
                onExpand={handleOnExpand}
                //selectedKeys={[currentSelectedKey]}

                className={`draggable-tree ${classDrag}`}
                draggable
                blockNode
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
                onDragOver={onDragOver}
            >
                {treeData && treeData.length && renderTreeNodes(treeData)}
            </Tree>
        );
};

export default withRouter(TreeCompanyBuildingGroups);