import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Button, Form, message, Modal, Icon, Notification, Row, Col, Switch, DatePicker, Card } from 'antd'
import { withRouter } from "react-router-dom"
import * as group_constants from '../../constants/groups'
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import moment from 'moment'
import './styles.css'



const FormItem = Form.Item

const _GroupPowerCalibrationForm = ({
  form,
  history,
  tabKey,
  isModalVisible
}) => {

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, isFieldTouched } = form

  const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const loading = useSelector(state => state.rootReducer.loaders.loadingPowerCalibration)
  const powerCalibrationData = useSelector(state => state.rootReducer.powerCalibration.powerCalibrationData)
  const groupControllers = useSelector(state => state.rootReducer.controllers.controllers)
  const update = useSelector(state => state.rootReducer.powerCalibration.update)
  const error = useSelector((state) => state.rootReducer.powerCalibration.error)

  const [saveDisabled, setSaveDisabled] = useState(true)

  const dispatch = useDispatch()

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)

  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { enable_calibration, calibration_datetime } = values

        const formated_calibration_date_time = moment(calibration_datetime).utc().format("YYYY-MM-DD HH:mm:ss")

        dispatch(({
          type: actions.SAVE_POWER_CALIBRATION_SETUP,
          payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, form: { power_calibration_requested: enable_calibration, power_calibration_requested_date_time: formated_calibration_date_time } }
        }))
      } else {
        message.error(err)
      }
    })
  }

  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
    },
    [form]
  )

  useEffect(
    () => {
      (async () => {
        if (tabKey === "controllers" && elementData.group_type !== group_constants.GROUP_TYPE_LOGICAL) {
          dispatch(({
            type: actions.FETCH_DEVICES,
            payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
          }))
        } else {
          if (elementData.group_type !== group_constants.GROUP_TYPE_LOGICAL) {
            dispatch({ type: actions.CLEAN_DEVICES_STATE })
          }
        }


        if (tabKey === "controllers" && !loading && isModalVisible) {
          resetFields()
          dispatch(({
            type: actions.FETCH_POWER_CALIBRATION_DATA,
            payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
          }))
          setSaveDisabled(true)
        } else {
          dispatch(({
            type: actions.CLEAN_POWER_CALIBRATION_STATE,
          }))
        }
      })();
    },
    [tabKey]
  )


  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 })
          history.push("/login")
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              setSaveDisabled(true)
              dispatch(({
                type: actions.FETCH_POWER_CALIBRATION_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 })
          dispatch({ type: actions.CLEAN_POWER_CALIBRATION_ERROR })
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (update) {
        message.success("Power calibration successfully updated")
        setSaveDisabled(true)
        resetFields()
      }
    },
    [powerCalibrationData]
  )

  const showPowerCalibrationPanel = () => {
    if (elementData) {
      if (elementData.group_type === group_constants.GROUP_TYPE_LOGICAL || elementData.group_type === group_constants.GROUP_TYPE_CONTROL) {
        return true
      } else {
        return groupControllers && groupControllers.length !== 0
      }
    } else {
      return false
    }
  }

  const disabledDate = (currentDate) => {
    return currentDate && currentDate < moment.now()
  }

  return (
    <>
      
      
        <Col xs={16} style={{marginBottom: "30px"}}>
          <Card>
            <h3><Icon type="thunderbolt" /> Power Calibration</h3>
            <hr />
            {showPowerCalibrationPanel() && (
            <Form className="input-form" onSubmit={handleSubmit}>
            
              <Row>
                <Col xs={12}>
                  <FormItem label="Enable calibration">
                    {getFieldDecorator("enable_calibration", {
                      initialValue: powerCalibrationData ? powerCalibrationData.power_calibration_requested : false,
                      valuePropName: 'checked'
                    })(
                      <Switch disabled={loading} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={10}>
                  <FormItem label="Calibration date and time">
                    {getFieldDecorator("calibration_datetime", {
                      rules: [{ required: true, message: "A date for power calibration is required" }],
                      initialValue: powerCalibrationData ? powerCalibrationData.power_calibration_requested_date_time : moment("1070-01-01 00:00:00")
                    })(
                      <DatePicker
                        showTime
                        disabledDate={disabledDate}
                        format="YYYY-MM-DD HH:mm:ss"
                        disabled={loading || !getFieldValue("enable_calibration")}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Tooltip title="Save power calibration settings">
                <Button
                  icon="save"
                  type="primary"
                  className="add-schedule-btn"
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={saveDisabled}
                >
                  Save
            </Button>
              </Tooltip>
            </Form>)}
          </Card>
        </Col>
    </>
  )
}

const GroupPowerCalibrationForm = Form.create()(_GroupPowerCalibrationForm)
export default withRouter(GroupPowerCalibrationForm)
