import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v1'
import IdleTimer from "react-idle-timer"
import { message, Form, Button, Row, Icon, Input, Slider, Notification, Modal, InputNumber, Radio, Empty, Tooltip } from "antd";
import Loader from "../Loader";
import { withRouter } from "react-router-dom";
import ScheduleInput from '../ScheduleInput'

import "./styles.css";

import CenteredRow from "../CenteredRow";
import { UserContext } from "../../context";
import * as actions from '../../constants';
import * as error_codes from '../../constants/error_codes';


const FormItem = Form.Item;
const ButtonGroup = Button.Group

const _SoundMaskingForm = ({
  form,
  history,
  //isModalVisible,
  handleTreeData,
  tabKey,
}) => {

  const loading = useSelector(state => state.rootReducer.loaders.loadingSoundMasking)
  const group = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const error = useSelector((state) => state.rootReducer.soundMasking.error)

  const sound_masking = useSelector(state => state.rootReducer.soundMasking.soundmaskingdata)
  //const uv_control = null;

  const [saveDisabled, setSaveDisabled] = useState(true)
  const [touchedFields, setTouchedFields] = useState([])
  const idleTimer = useRef(null)
  const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

  const dispatch = useDispatch()

  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)


  const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue } = form

  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { sound_masking_enabled, sound_masking_level } = values
        const form = {}
        if (touchedFields.includes("sound_masking_enabled")) {
          form.sound_masking_enabled = sound_masking_enabled
        }

        if (touchedFields.includes("sound_masking_level")) {
          form.sound_masking_level = sound_masking_level
        }
        
        dispatch({ type: actions.UPDATE_SOUND_MASKING, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid, form: form } })
      } else {
        message.error(err)
      }
    })
  }


  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
      setTouchedFields(touched_fields)
    },
    [form]
  )

  useEffect(
    () => {
      /*
      if (!isModalVisible) {
        resetFields()
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_LIGHTS_CONTROL_STATE }))
      }
      */
      return () => {
        resetFields()
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_SOUND_MASKING_STATE }))
      }
    },
    []
  )

  useEffect(
    () => {
      (async () => {
        if (tabKey === "sound_masking_form" && !loading) {
          resetFields()
          dispatch(({ type: actions.FETCH_SOUND_MASKING, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
          setSaveDisabled(true)
        }
      })();
    },
    [tabKey, group.uuid]
  )


  useEffect(
    () => {
      if (sound_masking !== null) {

        if (sound_masking.update) {
          setSaveDisabled(true)
          if (touchedFields.includes("sound_masking_enabled")) {
            message.success(`ON/OFF Sound Masking of group '${group.name}' successfully updated`)
          }

          if (touchedFields.includes("sound_masking_level")) {
            message.success(`Level Sound Masking of group '${group.name}' successfully updated`)
          }

          // add handle tree data if needed
          resetFields()
        }
      }
    },
    [sound_masking]
  )

  useEffect(
    () => {
      if (error !== null) {
        console.log("ERRORRRR")
        console.log(error)
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              dispatch(({ type: actions.FETCH_SOUND_MASKING, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
              setSaveDisabled(true)
            },
          })
        } else {
            Notification.error({ message: "Error", "description": error.message, "duration": 0 });
            dispatch({ type: actions.CLEAN_SOUND_MASKING_ERROR })
        }
      }
    },
    [error]
  )


  const onUserIdle = async e => {
    if (tabKey === "sound_masking_form") {
      resetFields()
      dispatch(({ type: actions.FETCH_SOUND_MASKING, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
      setSaveDisabled(true)
    }
    //idleTimer.reset()
  }


  const markslevel = {
    0: {
      style: {
        fontSize: '12px'
      },
      label: <span>0%</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>100%</span>,
    }
  }


  return (false //isLoading
    ? <Loader />
    : (!group ? (<div>
      <div className="big-text">No group selected</div>
    </div>) : (
        <div className="not-margin">
          <CenteredRow >

            <Form onSubmit={handleSubmit}>
              <FormItem label="Sound Masking Enabled">
                {getFieldDecorator("sound_masking_enabled", {
                  initialValue: sound_masking && sound_masking.sound_masking_enabled && sound_masking.sound_masking_enabled !== null ? sound_masking.sound_masking_enabled + "" : "false",
                  //valuePropName: 'checked'
                })(
                  <Radio.Group disabled={loading} loading={loading}>
                    <Radio.Button value="true">ON</Radio.Button>
                    <Radio.Button value="false">OFF</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>

              
              <FormItem label="Sound Masking Level">
                {getFieldDecorator("sound_masking_level", {
                  initialValue: sound_masking && sound_masking.sound_masking_level && sound_masking.sound_masking_level !== null ? sound_masking.sound_masking_level : 0,
                  //valuePropName: 'checked'
                })(
                  <Slider disabled={loading}  marks={markslevel} step={1} min={0} max={100} />
                )}
              </FormItem>

              <FormItem >
                <Button
                  type="primary"
                  htmlType="button"
                  style={{ width: "40%" }}
                  disabled={saveDisabled}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {!loading && (<Icon type="save" />)} Save
                        </Button>
              </FormItem>
            </Form >
          </CenteredRow >
        </div>
      )));
};

const ElementDetailSoundMaskingForm = Form.create()(_SoundMaskingForm);

export default withRouter(ElementDetailSoundMaskingForm);