import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  Form,
  Col,
  Row,
  List,
  Icon,
  Modal,
  Upload,
  message,
  Button,
  Notification,
} from "antd";
import { withRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useDrop, useDrag, DragPreviewImage } from "react-dnd";
import { putGroupImageMap } from "../../services";
import { UserContext } from "../../context";
import { dragdevice } from './image.js';
import * as actions from '../../constants';
import "./style.css";

let inside = false;

const DevicesForm = ({
  history,
  element_uuid,
  datadevices,
  image,
  imagedisplay,
  changeImageDisplay,
  updateImageMap,
  setParentDevice,
  updateFormData,
}) => {

  const dispatch = useDispatch()

  const [imageFile, setImageFile] = useState(image);
  const [imageMap, setImageMap] = useState(true);
  const [devices, setDevices] = useState(
    Object.assign([], datadevices)
  );//datadevices

  const [loading, setLoading] = useState(false)

  const { userData: { tenant_uuid } } = useContext(UserContext);

  useEffect(() => {
    setImageFile(image);
  },
    [image])

  const render = () => {

    //component to recive drop
    const Board = ({ children }) => {
      const [{ isOver, canDrop }, drop] = useDrop({
        accept: "device",
        canDrop: () => true,
        collect: monitor => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
        drop({ index, id }, monitor) {
          console.log(index + "-" + id)
          const { x: mapX, y: mapY } = document
            .getElementsByClassName("image-map")[0]
            .getBoundingClientRect();
          const { x: deviceX, y: deviceY } = monitor.getClientOffset();
          /*updateDevicePosition({ index, id, deviceX, deviceY, mapX, mapY, });*/
          const oldDevices = devices;
          const device = oldDevices[index];
          let x = deviceX - mapX;
          let y = deviceY - mapY;

          oldDevices[index] = { ...device, x, y };
          setDevices(oldDevices);

          const deviceIcon = document.getElementsByClassName(`item-device-${id}`);
          for (var i = 0; i < deviceIcon.length; i++) {
            deviceIcon[i].style.left = `${x}px`;
            deviceIcon[i].style.top = `${y}px`;
          }

          setParentDevice(oldDevices);

          //deviceIcon.style.left = `${x}px`;
          //deviceIcon.style.top = `${y}px`;
          return undefined;
        },
      });

      const isActive = canDrop && isOver
      //let backgroundColor = '#222'
      if (isActive) {
        //console.log("DENTRO")
        if (inside === false) {
          inside = (true);
        }
        //backgroundColor = 'darkgreen'
      } else if (canDrop) {
        //backgroundColor = 'darkkhaki'
        //console.log("FUERA")
        if (inside === true) {
          inside = (false);
        }
      }

      return (
        <div
          ref={drop}
          className={"contenedor"}
          style={{
            position: "relative",
          }}
        >
          {children}
        </div>
      );
    };

    //component for make drag
    const Device = ({ children, device }) => {
      const [{ isDragging }, drag, preview] = useDrag({
        item: { ...device, type: "device" },
        collect: monitor => {
          return {
            isDragging: !!monitor.isDragging(),
          };
        },
        end: ({ index, id }, monitor) => {
          //const { id: droppedId, originalIndex } = monitor.getItem()
          const didDrop = monitor.didDrop()
          if (!didDrop) {
            //moveCard(droppedId, originalIndex)
            //console.log("SOLTADO" + id + " " + index)
            if (inside === false) {
              const x = -100;
              const y = -100;

              const oldDevices = devices;
              oldDevices[index] = { ...device, x, y };
              setDevices(oldDevices);

              const deviceIcon = document.getElementsByClassName(`item-device-${id}`);
              for (var i = 0; i < deviceIcon.length; i++) {
                deviceIcon[i].style.left = `${x}px`;
                deviceIcon[i].style.top = `${y}px`;
              }
              setParentDevice(oldDevices);
            }
          }
        },
      });

      const deviceimage = dragdevice;
      return (
        <>
          <DragPreviewImage connect={preview} src={deviceimage} />
          <div
            ref={drag}
            style={{
              cursor: "move",
            }}
          >
            {children}
          </div>
        </>
      );
    };

    const imageMapUpload = async (options) => {
      updateFormData(true);
      setLoading(true);
      const { onSuccess, onError, file, onProgress } = options;
      const data = new FormData();
      data.append('image', file);
      try {

        const response = await putGroupImageMap(tenant_uuid, element_uuid, data)
        if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
          const resp_data = await response.data;
          message.success("Successfully Added New Image");
          await updateImageMap(resp_data.image);

          /*
          const imageedit = document.getElementById('image-map-edit')
          if (Number(imageedit.width) > Number(imageedit.height)) {
            changeImageDisplay("horizontal");
          } else {
            changeImageDisplay("vertical");
          }
          */
          updateFormData(false);
          setLoading(false);
          onSuccess("Ok");
        }
      } catch (err) {
        if (err.message.includes("401")) {
          //message.error("You are not authorized to perform this action");
          Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
          //history.push("/login");
          dispatch({ type: actions.LOG_OUT });
          //onError("Error");
        } else if (err.message.includes("422")) {
          //message.error("There is a problem with image size");
          Notification.error({ message: "Error", "description": "There is a problem with image size", "duration": 0 });
          //onError("Error");
        } else if (err.message.includes("403")) {
          //message.error("You have not permissions to upload image map");
          Notification.error({ message: "Error", "description": "You have not permissions to upload image map", "duration": 0 });
          //onError("Error");
          dispatch({ type: actions.LOG_OUT });
          //onError("Error");
        } else if (err.message.includes("404")) {
          //message.error("Group was deleted!");
          Notification.error({ message: "Error", "description": "Group was deleted!", "duration": 0 });
          //onError("Error");
        } else {

          if (err.message.includes("Network Error")) {
            Modal.destroyAll()
            Modal.error({
              destroyOnClose: true,
              title: "Connection is lost!",
              okText: "Retry",
              onOk: () => imageMapUpload(options),
            })
          } else {
            Modal.destroyAll()
            Modal.error({
              destroyOnClose: true,
              title: err.message,
              okText: "Reload",
              //onOk: () => imageMapUpload(options),
              onOk: () => window.location.reload(),
            })
          }
        }
      }
      setLoading(false);
      updateFormData(false);
    };

    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          <Row>
            <Col span={18}>
              {imageMap && (
                <Board thisRef={this}>
                  <img
                    id="image-map-edit"
                    className={`${imagedisplay} image-map`}
                    //src={imageFile}
                    src={`data:image/jpeg;base64,${imageFile}`}
                    max-width="500px"
                    max-height="500px"
                    alt=""
                    onMouseDown={() => { return false; }}
                    draggable="false"
                    onDragStart={() => { return false; }}
                  />

                  <Row style={{ top: "0px", position: "absolute" }}>
                    <Col>
                      {devices.map((device, index) => (
                        <Device
                          key={`device-${device.id}`}
                          device={{ ...device, index }}
                        >
                          {

                            <div
                              className={`device item-device-${device.id}`}
                              style={{
                                position: "absolute",
                                zIndex: device.x > 0 ? 1 : 0,
                                //opacity: device.x > 0 ? 1 : 0,
                                left: device.x,
                                top: device.y,
                              }}
                            >
                              <Icon type="bulb" />
                              <div className="device-name">{device.aws_id}</div>
                            </div>
                          }
                        </Device>
                      ))}
                    </Col>
                  </Row>
                </Board>
              )}
              {true && (
                <Col>
                  <Row>
                    <Upload
                      accept="image/*"
                      customRequest={imageMapUpload}
                      showUploadList={false}
                    >
                      <Button style={{ marginTop: "15px" }} loading={loading}>
                        <Icon style={{ paddingLeft: "15px" }} type="upload" /> Click to Upload
                      </Button>
                    </Upload>
                  </Row>
                </Col>
              )}
            </Col>
            <Col span={6}>
              <List
                header={<div>Devices</div>}
                bordered
                dataSource={devices}
                style={{ maxHeight: "400px", overflow: "scroll" }}
                renderItem={(item, index) => (
                  <List.Item>
                    {imageMap && (
                      <Device device={{ ...item, index }}><div>{item.aws_id}</div><div style={{ fontSize: "11px" }}>device {index + 1}</div></Device>
                    )}
                    {!imageMap && item.aws_id}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </div>
      </DndProvider>
    );
  }

  return (
    render()
  )
}

const DevicesFormComp = Form.create()(DevicesForm);

export default withRouter(DevicesFormComp);