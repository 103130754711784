import React, { useEffect, useContext } from "react"
import { useSelector } from 'react-redux'
import { Redirect, Route } from "react-router-dom"
import { setAuthToken } from "../../services"
import { UserContext } from "../../context"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userData, setUserData } = useContext(UserContext);
  const jwtToken = useSelector((state) => state.rootReducer.auth.accessToken)

  useEffect(() => {
    if (jwtToken) {
      const tokenParsed = JSON.parse(atob(jwtToken.split(".")[1]));

      let _userData = {
        username: tokenParsed.identity,
        tenant_uuid: tokenParsed.user_claims.tenant_uuid,
        claims: tokenParsed.user_claims.claims
      }

      setUserData(_userData);
    } else {
      setUserData({});
    }
  }, []);

  return userData ? (
    <Route
      {...rest}
      render={props => {
        if (jwtToken) {
          setAuthToken(jwtToken);
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  ) : null;
};

export default PrivateRoute;
