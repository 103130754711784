import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, message, Icon, Notification, Modal, InputNumber, Switch } from "antd";
import { withRouter } from "react-router-dom";
import IdleTimer from "react-idle-timer"
import useFocus from "../../utils/customHooks"
import StyledIcon from "../../components/StyledIcon";
import "./styles.css";

import CenteredRow from "../CenteredRow";
import Loader from "../Loader";
import { UserContext } from "../../context";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'

const FormItem = Form.Item;

const _SettingsPeopleAnalyticsForm = ({
    form,
    history,
    isModalVisible
}) => {

    const loading = useSelector(state => state.rootReducer.loaders.loadingPeopleAnalytics)
    const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)

    const peopleAnalytics = useSelector(state => state.rootReducer.peopleAnalytics.peopleAnalyticsData)
    const error = useSelector((state) => state.rootReducer.peopleAnalytics.error)

    const [saveDisabled, setSaveDisabled] = useState(true);

    const [touchedFields, setTouchedFields] = useState([])
    const idleTimer = useRef(null)
    const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

    const [inputRef, setInputFocus] = useFocus();

    const [floorPlan, setFloorPlan] = useState(4500);
    const [workspaceRadius, setWorkspaceRadius] = useState(3);
    const [occupancyLimit, setOccupancyLimit] = useState(125);

    const dispatch = useDispatch()

    const {
        userData: { tenant_uuid }
    } = useContext(UserContext);

    const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue, setFieldsValue } = form

    useEffect(
        () => {
            if (peopleAnalytics && peopleAnalytics.floor_plan_area) {
                setFloorPlan(peopleAnalytics.floor_plan_area);
            }
            if (peopleAnalytics && peopleAnalytics.workspace_radius) {
                setWorkspaceRadius(peopleAnalytics.workspace_radius);
            }
            if (peopleAnalytics && peopleAnalytics.occupancy_limit) {
                setOccupancyLimit(peopleAnalytics.occupancy_limit);
            }
        },
        [peopleAnalytics]
    )

    const handleSubmit = async e => {
        e.preventDefault()
        await validateFields(async (errors, values) => {
            if (!errors) {
                const { people_analytics, floor_plan_area, workspace_radius, occupancy_limit } = values
                const form = {}

                if (touchedFields.includes("people_analytics")) {
                    form.people_analytics = people_analytics
                }

                if (touchedFields.includes("floor_plan_area")) {
                    if (floor_plan_area !== "" && floor_plan_area !== null) {
                        form.floor_plan_area = floor_plan_area
                    }
                }
                if (touchedFields.includes("workspace_radius")) {
                    if (workspace_radius !== "" && workspace_radius !== null) {
                        form.workspace_radius = workspace_radius
                    }
                }
                if (touchedFields.includes("floor_plan_area") || touchedFields.includes("workspace_radius") || touchedFields.includes("occupancy_limit")) {
                    if (occupancy_limit !== "" && occupancy_limit !== null) {
                        form.occupancy_limit = occupancy_limit
                    }
                }

                dispatch(({
                    type: actions.UPDATE_PEOPLEANALYTICS,
                    payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type, form: form }
                }))
            } else {
                const { people_analytics, floor_plan_area, workspace_radius, occupancy_limit } = errors
                if (people_analytics && people_analytics.errors && people_analytics.errors.length > 0) {
                    people_analytics.errors.forEach(element => {
                        message.error(element.message)
                    });
                }
                if (floor_plan_area && floor_plan_area.errors && floor_plan_area.errors.length > 0) {
                    floor_plan_area.errors.forEach(element => {
                        message.error(element.message)
                    });
                }
                if (workspace_radius && workspace_radius.errors && workspace_radius.errors.length > 0) {
                    workspace_radius.errors.forEach(element => {
                        message.error(element.message)
                    });
                }
                if (occupancy_limit && occupancy_limit.errors && occupancy_limit.errors.length > 0) {
                    occupancy_limit.errors.forEach(element => {
                        message.error(element.message)
                    });
                }
                //message.error(err === ""? "Invalid value": err)
            }
        })
    }

    useEffect(
        () => {
            const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
            if (touched_fields.length !== 0) {
                setSaveDisabled(false)
            }
            setTouchedFields(touched_fields)
        },
        [form]
    )

    useEffect(
        () => {
            (async () => {
                if (!loading && isModalVisible  && elementData && elementData.group_type !== "Company" ) {
                    resetFields()
                    dispatch(({
                        type: actions.FETCH_PEOPLEANALYTICS,
                        payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                    }))
                    setSaveDisabled(true)
                }
            })();
        },
        []
    )

    useEffect(
        () => {
            if (!isModalVisible) {
                resetFields()
                setSaveDisabled(true)
                //dispatch(({ type: actions.CLEAN_PEOPLEANALYTICS_STATE }))
            } else {
                if (!loading  && elementData && elementData.group_type !== "Company" ) {
                    resetFields()
                    dispatch(({
                        type: actions.FETCH_PEOPLEANALYTICS,
                        payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                    }))
                    setSaveDisabled(true)
                }
            }
        },
        [isModalVisible]
    )

    useEffect(
        () => {
            if (peopleAnalytics !== null) {

                if (peopleAnalytics.update) {
                    setSaveDisabled(true)

                    if (peopleAnalytics && peopleAnalytics.people_analytics_in_payload) {
                        message.success(`People Analytics of ${
                            elementData.group_type === "Building" ? "building" : elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }

                    if (peopleAnalytics && peopleAnalytics.floor_plan_area_in_payload) {
                        message.success(`Floor Plan Area value of ${
                            elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }

                    if (peopleAnalytics && peopleAnalytics.workspace_radius_in_payload) {
                        message.success(`Workspace workspace_radius value of ${
                            elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }

                    if (peopleAnalytics && peopleAnalytics.occupancy_limit_in_payload) {
                        message.success(`Occupancy Limit on Floor Plan value of ${
                            elementData.group_type + " group"
                            } '${elementData.name}' successfully updated`)
                    }


                }
                resetFields()
            }
        },
        [peopleAnalytics]
    )

    useEffect(
        () => {
            if (error !== null) {
                if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
                    Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
                    history.push("/login");
                    dispatch({ type: actions.LOG_OUT })
                } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
                    Modal.destroyAll()
                    Modal.error({
                        destroyOnClose: true,
                        title: error.message,
                        okText: "Retry",
                        onOk: () => {
                            resetFields()
                            dispatch(({
                                type: actions.FETCH_PEOPLEANALYTICS,
                                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                            }))
                            setSaveDisabled(true)
                        },
                    })
                } else {
                    Notification.error({ message: "Error", "description": error.message, "duration": 0 });
                    dispatch({ type: actions.CLEAN_PEOPLEANALYTICS_ERROR })
                }
            }
        },
        [error]
    )


    const onUserIdle = async e => {
        if (isModalVisible) {
            resetFields()
            dispatch(({
                type: actions.FETCH_PEOPLEANALYTICS,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
            }))
            setSaveDisabled(true)
        }
    }

    const handleFloorPlanChange = (value, e) => {
        if (value > 0) {
            setFloorPlan(value)
            const calc_occupancy_limit = (value / ((workspaceRadius * 2) * (workspaceRadius * 2))).toFixed(0)
            setOccupancyLimit(calc_occupancy_limit)
            setFieldsValue({
                occupancy_limit: parseInt(calc_occupancy_limit),
              });
        }

    };

    const handleWorkspaceRadiusChange = (value, e) => {
        if (value > 0) {
            setWorkspaceRadius(value)
            const calc_occupancy_limit = (floorPlan / ((value * 2) * (value * 2))).toFixed(0)
            setOccupancyLimit(calc_occupancy_limit)
            setFieldsValue({
                occupancy_limit: parseInt(calc_occupancy_limit),
              });
        }
    };

    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
    };

    return loading ? (
        <Loader />
    ) : (
            <IdleTimer
                ref={idleTimer}
                element={document}
                onIdle={onUserIdle}
                debounce={250}
                timeout={timeout}
            >
                <div>

                    <CenteredRow>
                        <Form
                            {...formItemLayout}
                            onSubmit={handleSubmit}
                        >

                            <FormItem label="People Analytics" >
                                {getFieldDecorator("people_analytics", {
                                    initialValue: peopleAnalytics && peopleAnalytics.people_analytics ? peopleAnalytics.people_analytics : false,
                                    valuePropName: 'checked'
                                })(
                                    <Switch />
                                )}
                            </FormItem>

                            {elementData && elementData.group_type && (elementData.group_type === "Control" || elementData.group_type === "Logical" || elementData.group_type === "Mix") ? (
                                <>
                                    <FormItem label={<span>Floor Plan Area (ft<sup>2</sup>)</span>}>
                                        {getFieldDecorator("floor_plan_area", {
                                            rules: [
                                                //{ required: true, message: "A floor plan area is required" },
                                                { type: 'number', min: 1, max: 100000, message: "floor plan area must be between 1 and 1000" }
                                            ],
                                            initialValue: parseInt(floorPlan)
                                        })(<InputNumber
                                            onChange={handleFloorPlanChange}
                                            ref={inputRef} />)}
                                    </FormItem>

                                    <FormItem label={<span>Workspace Radius (ft)</span>}>
                                        {getFieldDecorator("workspace_radius", {
                                            rules: [
                                                //{ required: true, message: "A workspace radius is required" },
                                                { type: 'number', min: 2, max: 10, message: "workspace radius must be between 2 and 10" }
                                            ],
                                            initialValue: parseInt(workspaceRadius)
                                        })(<InputNumber
                                            ref={inputRef}
                                            style={{ "marginRight": "10px" }}
                                            onChange={handleWorkspaceRadiusChange}
                                        />)}
                                        <StyledIcon type="logout" />
                                    </FormItem>


                                    <FormItem label={<span>Occupancy Limit of Floor Plan</span>}>
                                        {getFieldDecorator("occupancy_limit", {
                                            rules: [
                                                //{ required: true, message: "A occupancy limit is required" },
                                                { type: 'number', min: 1, max: 1000, message: "occupancy limit must be between 0 and 1000" },
                                            
                                            ],
                                            dependencies:['floor_plan_area', 'workspace_radius'],
                                            
                                            initialValue: parseInt(occupancyLimit)
                                        })(<InputNumber
                                            ref={inputRef}
                                        />)}
                                    </FormItem>

                                    <span className={"span-occupancy-limit"}>*Maximum Number of People based on Floor Plan Area: {(floorPlan / ((workspaceRadius * 2) * (workspaceRadius * 2))).toFixed(0)}</span>

                                </>
                            ) : (<></>)}


                            <FormItem >
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    style={{ width: "40%" }}
                                    disabled={saveDisabled}
                                    loading={loading}
                                    onClick={handleSubmit}
                                >
                                    <Icon type="save" /> Save
                        </Button>
                            </FormItem>
                        </Form>
                    </CenteredRow>
                </div>
            </IdleTimer>
        )
};

const SettingsPeopleAnalyticsForm = Form.create()(_SettingsPeopleAnalyticsForm)

export default withRouter(SettingsPeopleAnalyticsForm)