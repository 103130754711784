import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Tabs, Icon, Row } from "antd";
import { withRouter } from "react-router-dom";
import "./styles.css";

import SettingsPeopleAnalyticsForm from "../SettingsPeopleAnalyticsForm"

const { TabPane } = Tabs;

const SettingPeopleAnalytics = ({
    elementData,
    isModalVisible,
    setIsModalVisible,
}) => {

    //console.log("TLC: Initial lux value " + elementData.lux);
    const info_setting_controller_tab = useSelector(state => state.rootReducer.redirect.setting_controller_tab)


    const handleCancel = async e => {
        setIsModalVisible(false)
    };

    
    
    return (
        <Row>
            { elementData && elementData.group_type && elementData.group_type !== "Company" && (
                        <SettingsPeopleAnalyticsForm
                            isModalVisible={isModalVisible}
                        />
            )}
             <Button
                type="default"
                htmlType="button"
                style={{ width: "40%", marginRight: "10%" }}
                size="large"
                icon="close"
                onClick={handleCancel}
            >
                Cancel
            </Button>
        </Row >
    );
};

export default withRouter(SettingPeopleAnalytics);