import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, message, Select, Modal, Notification, Switch, Collapse } from "antd"
import StyledIcon from "../StyledIcon"
import { withRouter } from "react-router-dom"
import User2faQr from "../User2faQr"
import * as error_codes from '../../constants/error_codes'
import * as actions from '../../constants'
import "./styles.css"

import colors from "../../styles";
import CenteredRow from "../CenteredRow";
const { Panel } = Collapse

const { darkIconColor } = colors;

const Option = Select.Option;
const FormItem = Form.Item;

const UserProfileForm = ({ form, history, setIsModalVisible, isModalVisible }) => {
  const isLoading = useSelector(state => state.rootReducer.loaders.loadingUserProfile)
  const userProfileData = useSelector(state => state.rootReducer.userProfile.userProfileData)
  const error = useSelector((state) => state.rootReducer.userProfile.errorFetchUpdateProfile)
  const update = useSelector((state) => state.rootReducer.userProfile.updateProfile)

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const user_uuid = useSelector((state) => state.rootReducer.auth.userUuid)


  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, isFieldTouched } = form

  const dispatch = useDispatch()

  useEffect(
    () => {
      resetFields()
      dispatch(({ type: actions.FETCH_USER_PROFILE, payload: { tenant_uuid, uuid: user_uuid } }))
    },
    []
  )

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { active_2fa } = values;
        const form = {};

        if (isFieldTouched("active_2fa")) {
          form.active_2fa = active_2fa
        }


        dispatch(({
          type: actions.UPDATE_USER_PROFILE,
          payload: {
            tenant_uuid,
            uuid: user_uuid,
            form: form
          }
        }))

      } else {
        message.error(err);
      }
    })
  }

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              setIsModalVisible(false)
              dispatch(({ type: actions.CLEAN_USER_PROFILE_STATE }))
              dispatch(({ type: actions.FETCH_USER_PROFILE, payload: { tenant_uuid, uuid: user_uuid } }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (update) {
        message.success(`User successfully updated`)
        resetFields()
      }
    },
    [update]
  )

  const handleCancel = (e) => {
    setIsModalVisible(false)
    dispatch(({ type: actions.CLEAN_USER_PROFILE_STATE }))
  }

  return (
    <CenteredRow>
      <Form onSubmit={handleSubmit}>

        <FormItem label="Two-Factor Authentication">
          {getFieldDecorator("active_2fa", {
            initialValue: userProfileData ? userProfileData.active_2fa : false,
            valuePropName: 'checked'
          })(
            <Switch disabled={isLoading} />
          )}
        </FormItem>

        {userProfileData && userProfileData.active_2fa && (
          <div>
            <Collapse ghost>
              <Panel header="Check your 2fa QR Code" key="1">
                <User2faQr isLogin={false} />
              </Panel>
            </Collapse>
            <hr />
          </div>
        )}

        <FormItem style={{ textAlign: "center", marginTop: "5%" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "40%" }}
            size="large"
            icon={"upload"}
            loading={isLoading}
          >
            {" Save"}
          </Button>
          <Button
            type="default"
            htmlType="button"
            onClick={handleCancel}
            icon={"close"}
            style={{ width: "40%", marginLeft: "1em" }}
            size="large"
          >
            Cancel
          </Button>
        </FormItem>
      </Form>
    </CenteredRow>
  )
};

const WrappedUserProfileForm = Form.create()(UserProfileForm);

export default withRouter(WrappedUserProfileForm);
