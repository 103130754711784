import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdateUVControl, errorFetchUpdateUVControl } from '../actions/UVControlActions'
import { fetchGroupUVControlApi, updateGroupUVControlApi } from '../api/UVControlApi'
import { toogleUVControlLoader } from '../actions/loaderActions'

export function* getGroupUVControlValues(action) {
    try {
        yield put(toogleUVControlLoader(true))

        const uv_control_data = yield call(fetchGroupUVControlApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
        )

        const data = Object.assign({}, uv_control_data, null)

        if(!data.uv_schedule){
            data.uv_schedule = {timezone: "Canada/Eastern",
            periods: []}
        }

        data.update = false

        yield put(successFetchUpdateUVControl(data))
        yield put(toogleUVControlLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        }
        yield put(toogleUVControlLoader(false))
        yield put(errorFetchUpdateUVControl(error))
    }
}

export function* updateGroupUVControlValues(action) {
    try {
        yield put(toogleUVControlLoader(true))

        let uv_control_data = {}
        const uv_post_movement_delay_minutes = "uv_post_movement_delay_minutes" in action.payload.form
        const uv_aggresive_cleaning = "uv_aggresive_cleaning" in action.payload.form
        const uv_time_zone = "uv_time_zone" in action.payload.form
        const uv_schedule = "uv_schedule" in action.payload.form
        const form = {}
       


        if ( uv_post_movement_delay_minutes || uv_aggresive_cleaning || uv_time_zone || uv_schedule) {
            
            if(uv_post_movement_delay_minutes){
                form.uv_post_movement_delay_minutes = action.payload.form.uv_post_movement_delay_minutes
            }

            if(uv_aggresive_cleaning){
                form.uv_aggresive_cleaning = action.payload.form.uv_aggresive_cleaning === "true" ? true : false
            }

            if(uv_time_zone){
                form.uv_time_zone = action.payload.form.uv_time_zone
            }

            if(uv_schedule){
                form.uv_schedule = action.payload.form.uv_schedule
            }

            uv_control_data = yield call(updateGroupUVControlApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                form
            )
        }

        

        const data = Object.assign({}, uv_control_data, {})

        data.update = true

        yield put(successFetchUpdateUVControl(data))
        yield put(toogleUVControlLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleUVControlLoader(false))
        yield put(errorFetchUpdateUVControl(error))
    }
}

export function* fetchGroupUVControlWatcher() {
    yield takeLatest(actions.FETCH_UV_CONTROL, getGroupUVControlValues)
}

export function* updateGroupUVControlWatcher() {
    yield takeLeading(actions.UPDATE_UV_CONTROL, updateGroupUVControlValues)
}