import {
  Modal,
  message,
  Notification,
} from "antd";
import * as actions from '../../constants';
import { getPanels, postPanel, putPanel, deletePanel, getGroupService } from "../../services";

export const existGroup = async (history, dispatch, tenant_uuid, group_uuid) => {
  try {
    const response = await getGroupService(tenant_uuid, group_uuid)
    if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
      return response.data;
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT })
    } else {
      if (err.message.includes("403")) {
        return false;
      }
      if (err.message.includes("404")) {
        return false;
      }
      if (err.message.includes("Network Error")) {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => existGroup(history, dispatch, tenant_uuid, group_uuid),
        })
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          onOk: () => window.location.reload(),
        })
      }
    }
  }
}

export const getDashboardPanelData = async (history, dispatch, tenant_uuid, dashboard_uuid) => {
  try {
    const response = await getPanels(tenant_uuid, dashboard_uuid)
    if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
      const data = await response.data;
      return data;
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
    } else {
      if (err.message.includes("404")) {
        //message.error("Panel does not exists!");
        Notification.error({ message: "Error", "description": "Panel does not exists!", "duration": 0 });

      }
      if (err.message.includes("Network Error")) {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid),
        })
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => getDashboardPanelData(),
          onOk: () => window.location.reload(),
        })
      }
      return []
    }
  }
}

//update in db device location
export const postDashboardPanelData = async (history, dispatch, tenant_uuid, dashboard_uuid, form) => {
  //setLoading(true)
  try {
    const response = await postPanel(tenant_uuid, dashboard_uuid, form)
    if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
      const data = await response.data;
      //setLoading(false);
      //return data.uuid;
      return { "data": data.uuid, "error": false, "message": "" };
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "" };
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
      return { "data": "", "error": true, "message": "" };
    } else {
      if (err.message.includes("404")) {
        //message.error("Panel does not exists!");
        Notification.error({ message: "Error", "description": "Panel does not exists!", "duration": 0 });

      }
      if (err.message.includes("Network Error")) {
        /*
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => postDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, form),
        })
        */
        return { "data": "", "error": true, "message": "Connection is lost!" };
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          onOk: () => window.location.reload(),
        })
        return { "data": "", "error": true, "message": "" };
      }
    }
  }
}

//update in db device location
export const putDashboardPanelData = async (history, dispatch, tenant_uuid, dashboard_uuid, panel_uuid, form) => {
  try {
    const response = await putPanel(tenant_uuid, dashboard_uuid, panel_uuid, form)
    if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
      const data = await response.data;
      return data;
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
    } else {
      if (err.message.includes("404")) {
        //message.error("Panel does not exists!");
        Notification.error({ message: "Error", "description": "Panel does not exists!", "duration": 0 });

      }
      if (err.message.includes("Network Error")) {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => putDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panel_uuid, form),
        })
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          //onOk: () => putDashboardPanelData(panel_uuid, form),
          onOk: () => window.location.reload(),
        })
      }
    }
  }
}

//update in db device location
export const deleteDashboardPanelData = async (history, dispatch, tenant_uuid, dashboard_uuid, panel_uuid) => {

  try {
    const response = await deletePanel(tenant_uuid, dashboard_uuid, panel_uuid)
    if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
      const data = await response.data;
      return data;
      //setLoading(false);
    }
  } catch (err) {
    if (err.message.includes("401")) {
      //message.error("You are not authorized to perform this action");
      Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
      //history.push("/login");
      dispatch({ type: actions.LOG_OUT });
    } else if (err.message.includes("403")) {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
      dispatch({ type: actions.LOG_OUT });
    } else {
      if (err.message.includes("404")) {
        //message.error("Panel does not exists!");
        Notification.error({ message: "Error", "description": "Panel does not exists!", "duration": 0 });

      }
      if (err.message.includes("Network Error")) {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => deleteDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panel_uuid),
        })
      } else {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: err.message,
          okText: "Reload",
          onOk: () => window.location.reload(),
        })
      }
    }
  }
}