import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import IdleTimer from "react-idle-timer"
import { Slider, Radio, message, Form, Button, Icon, Notification, Modal, Row, Col } from "antd";
import { withRouter } from "react-router-dom";

import "./styles.css";

import CenteredRow from "../CenteredRow";
import GroupBlueLightBoostForm from "../GroupBlueLightBoostForm"
import GroupCircadianRhythmForm from "../GroupCircadianRhythmForm"
import { UserContext } from "../../context";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'

const FormItem = Form.Item;

const _SettingsLightsControlForm = ({
  form,
  history,
  //isModalVisible,
  handleTreeData,
  tabKey,
}) => {

  const loading = useSelector(state => state.rootReducer.loaders.loadingLightsControl)
  const group = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const light_capability = useSelector(state => state.rootReducer.lightCapability.light_capability)
  const lightsData = useSelector(state => state.rootReducer.lightsControl.lightsData)
  const error = useSelector((state) => state.rootReducer.lightsControl.error)

  const illuminanceData = useSelector(state => state.rootReducer.illuminance.illuminanceData)

  const [sliderBrightValue, setSliderBrightValue] = useState(0)
  const [sliderTemperatureValue, setSliderTemperatureValue] = useState(0)
  const [sliderBrightOverridenValue, setSliderBrightOverridenValue] = useState(0)
  const [sliderTemperatureOverridenValue, setSliderTemperatureOverridenValue] = useState(0)
  const [brightInheritance, setBrightInheritance] = useState("own")
  const [temperatureInheritance, setTemperatureInheritance] = useState("own")
  const [onInheritance, setOnInheritance] = useState("own")
  const [onMode, setOnMode] = useState("off")
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [touchedFields, setTouchedFields] = useState([])
  const [clickRadio, setClickRadio] = useState(false)
  const idleTimer = useRef(null)
  const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

  const dispatch = useDispatch()

  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)


  const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue } = form

  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { slider_level, white_level, on_mode } = values
        const form = {}
        if (touchedFields.includes("slider_level")) {
          form.slider_level = slider_level
        }

        if (touchedFields.includes("white_level")) {
          form.white_level = white_level
          form.warm_white_level = 100 - white_level
        }

        if (touchedFields.includes("on_mode") || clickRadio) {
          if (on_mode === "on") {
            form.on = true
          } else if (on_mode === "off") {
            form.on = false
          } else {
            form.on = false
            form.shutdown = true
          }
        }

        dispatch(({ type: actions.UPDATE_LIGHTS_LEVELS_ON, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid, form: form } }))
        setClickRadio(false)
      } else {
        message.error(err)
      }
    })
  }

  const handleClickRadio = async e => {
    e.preventDefault()
    setClickRadio(true)
    setSaveDisabled(false)
  }


  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
      setTouchedFields(touched_fields)
    },
    [form]
  )

  useEffect(
    () => {
      /*
      if (!isModalVisible) {
        resetFields()
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_LIGHTS_CONTROL_STATE }))
      }
      */
      return () => {
        resetFields()
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_LIGHTS_CONTROL_STATE }))
      }
    },
    []
  )

  useEffect(
    () => {
      (async () => {
        if (tabKey === "lights_control") {
          if (!loading) {
            resetFields()
            dispatch(({ type: actions.FETCH_LIGHTS_LEVELS_ON, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))

            dispatch(({
              type: actions.FETCH_ILLUMINANCE,
              payload: { tenant_uuid: tenant_uuid, uuid: group.uuid, group_type: group.group_type }
            }))

            setSaveDisabled(true)
          }
        }
      })();
    },
    [tabKey, group.uuid]
  )

  useEffect(
    () => {
      if (lightsData !== null) {
        if ("slider_level" in lightsData) {
          setSliderBrightValue(lightsData.slider_level)
          setSliderTemperatureValue(lightsData.white_level)
          setSliderBrightOverridenValue(lightsData.overriden_slider_level)
          setSliderTemperatureOverridenValue(lightsData.overriden_white_level)
          setBrightInheritance(lightsData.slider_level_inheritance)
          setTemperatureInheritance(lightsData.color_level_inheritance)
        }

        if ("on_mode" in lightsData) {
          setOnMode(lightsData.on_mode)
          setOnInheritance(lightsData.on_inheritance)
        }

        if (lightsData.update) {
          setSaveDisabled(true)

          if (touchedFields.includes("slider_level")) {
            message.success(`Brightness levels of group '${group.name}' successfully updated`)
          }

          if (touchedFields.includes("white_level")) {
            message.success(`Lights temperature colors levels of group '${group.name}' successfully updated`)
          }

          if (touchedFields.includes("on_mode")) {
            message.success(`ON/OFF status of group '${group.name}' successfully updated`)
          }

          // add handle tree data if needed
        }
        resetFields()
      }
    },
    [lightsData]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              dispatch(({ type: actions.FETCH_LIGHTS_LEVELS_ON, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
              setSaveDisabled(true)
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_LIGHTS_CONTROL_ERROR })
        }
      }
    },
    [error]
  )

  const getSliderBrightMarks = () => {
    const marks = {}
    marks[brightInheritance === "own" ? sliderBrightOverridenValue : sliderBrightValue] = {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "40px"
      },
      label: <span><div className="arrow_box inline-div-center"><span className="bubble-text">{brightInheritance === "own" ? sliderBrightOverridenValue : sliderBrightValue}%</span></div></span>
    }
    if (brightInheritance === "own") {
      if (sliderBrightOverridenValue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>0% </span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">0%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderBrightOverridenValue}%</span></div></div></span>
        }
      }
      if (sliderBrightOverridenValue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>100%</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-right">100%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderBrightOverridenValue}%</span></div></div></span>
        }
      }
    } else {
      if (sliderBrightValue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>0%</span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">0%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderBrightValue}%</span></div></div></span>
        }
      }
      if (sliderBrightValue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>100%</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"> <div className="inline-div-static-label-right">100%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderBrightValue}%</span></div></div></span>
        }
      }
    }
    return marks
  }

  const getSliderTemperatureMarks = () => {
    const marks = {}
    marks[temperatureInheritance === "own" ? sliderTemperatureOverridenValue : sliderTemperatureValue] = {
      style: {
        color: "#ff4d4f",
        fontSize: '12px',
        minWidth: "50px"
      },
      label: <div className="arrow_box inline-div-center">{temperatureInheritance === "own" ? formatterTemperature(sliderTemperatureOverridenValue) : formatterTemperature(sliderTemperatureValue)}</div>
    }
    if (temperatureInheritance === "own") {
      if (sliderTemperatureOverridenValue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Warm White</span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "50px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">Warm White</div> <div className="arrow_box inline-div"><span className="bubble-text">{formatterTemperature(sliderTemperatureOverridenValue)}</span></div></div></span>
        }
      }
      if (sliderTemperatureOverridenValue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Bright White</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "70px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-right">Bright White</div> <div className="arrow_box inline-div"><span className="bubble-text">{formatterTemperature(sliderTemperatureOverridenValue)}</span></div></div></span>
        }
      }
    } else {
      if (sliderTemperatureValue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Warm White</span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "50px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">Warm White</div> <div className="arrow_box inline-div"><span className="bubble-text">{formatterTemperature(sliderTemperatureValue)}</span></div></div></span>
        }
      }
      if (sliderTemperatureValue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Bright White</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: '70px'
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-right">Bright White</div> <div className="arrow_box inline-div"><span className="bubble-text">{formatterTemperature(sliderTemperatureValue)}</span></div></div></span>
        }
      }
    }
    return marks
  }

  const formatterTemperature = (value) => {
    const conver = (3000 + 30 * value).toFixed(0)
    return `${conver}K`;
  }

  const onUserIdle = async e => {
    if (tabKey === "lights_control") {
      resetFields()
      dispatch(({ type: actions.FETCH_LIGHTS_LEVELS_ON, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
      setSaveDisabled(true)
    }
    //idleTimer.reset()
  }

  return (!group ? (<div>
    <div className="big-text">No group selected</div>
  </div>) : (
      <CenteredRow>
        <IdleTimer
          ref={idleTimer}
          element={document}
          onIdle={onUserIdle}
          debounce={250}
          timeout={timeout}
        ></IdleTimer>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={{ span: 14 }}>
              <FormItem label="Switch">
                {getFieldDecorator("on_mode", {
                  initialValue: onMode
                })(
                  <Radio.Group disabled={loading}>
                    <Radio.Button className={onInheritance === "override" ? "radio-button-overriden" : ""} disabled={loading} value="shutdown" onClick={handleClickRadio} >OFF</Radio.Button>
                    {group && group.occupancy_sensor === true ?
                      <div style={{ display: "inherit" }}> </div> :
                      <Radio.Button className={onInheritance === "override" ? "radio-button-overriden middle" : "middle"} disabled={loading} value="off" onClick={handleClickRadio} >AUTO</Radio.Button>
                    }
                    <Radio.Button className={onInheritance === "override" ? "radio-button-overriden" : ""} disabled={loading} value="on" onClick={handleClickRadio} >ON</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
          </Row>
          {
            (group.group_type === "Control" || group.group_type === "Mix") && (
              <Row>
                <Col sm={{ span: 10 }}>
                  <GroupBlueLightBoostForm
                    tabKey={tabKey}
                  />
                </Col>
                <Col sm={{ span: 10 }}>
                  <GroupCircadianRhythmForm
                    tabKey={tabKey}
                  />
                </Col>
              </Row>
            )
          }

          <div className="big-text">Brightness:</div>
          <br />
          <FormItem>
            {getFieldDecorator("slider_level", {
              initialValue: brightInheritance === "own"
                ? sliderBrightValue ? sliderBrightValue : 0
                : sliderBrightOverridenValue ? sliderBrightOverridenValue : 0
            })(
              <Slider disabled={loading || (group && group.on_mode === "off" && group.circadian_rhythm)} step={1} marks={getSliderBrightMarks()} min={0} max={100} />
            )}
          </FormItem>

          {light_capability !== "SINGLE" && (
            <div>
              <div className="big-text">Light Temperature:</div>
              <br />
              <FormItem>
                {getFieldDecorator("white_level", {
                  initialValue: temperatureInheritance === "own"
                    ? sliderTemperatureValue ? sliderTemperatureValue : 0
                    : sliderTemperatureOverridenValue ? sliderTemperatureOverridenValue : 0
                })(
                  <Slider disabled={loading || (group && group.circadian_rhythm)} step={1} marks={getSliderTemperatureMarks()} min={0} max={100} tipFormatter={formatterTemperature} />
                )}
              </FormItem>
            </div>
          )}

          <FormItem >
            <Button
              type="primary"
              htmlType="button"
              style={{ width: "40%" }}
              disabled={saveDisabled}
              loading={loading}
              onClick={handleSubmit}
            >
              {!loading && (<Icon type="save" />)} Save
                        </Button>
          </FormItem>
        </Form >
      </CenteredRow >
    ));
};

const SettingsLightsControlForm = Form.create()(_SettingsLightsControlForm);

export default withRouter(SettingsLightsControlForm);