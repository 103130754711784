import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchBlueLightBoostData = (tenant_uuid, uuid) => action(actions.FETCH_BLUE_LIGHT_BOOST_DATA, {tenant_uuid, uuid })

export const successFetchBlueLightBoostData = data => action(actions.SUCCESS_FETCH_BLUE_LIGHT_BOOST_DATA, { data })

export const activateDeactivateBlueLightBoost = (tenant_uuid, uuid, form) => action(actions.ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST, {tenant_uuid, uuid, form })

export const successActivateDeactivateBlueLightBoost = data  => action(actions.SUCCESS_ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST, { data })

export const errorBlueLightBoost = error => action(actions.ERROR_BLUE_LIGHT_BOOST, { error })

export const cleanBlueLightBoostState = () => action(actions.CLEAN_BLUE_LIGHT_BOOST_STATE)
