import React, { useEffect, useState } from 'react'
import { Empty, List, Alert, Icon } from 'antd'
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";
import NotificationRow from "../../components/NotificationRow";
import './styles.css';
import {getMessageIconType, getMessageType} from "../../utils/helpers";

const NotificationList = ({
    history,
    transport,
    notificationData,
    isLoading,
    selectNotification
}) => {
    

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(isLoading)
      },
        [isLoading]
      )

    const notificationDetail = (index) => {
        selectNotification(index)
    }

    return (
        loading //isLoading
            ? <Loader />
            :
            <>
                <div className="toast-data">
                    {notificationData && notificationData.length > 0 ?
                        <List
                            className="toasts-message"
                            itemLayout="horizontal"
                            dataSource={notificationData}
                            renderItem={(item, index) => (
                                <List.Item onClick={() => notificationDetail(index)}>
                                    {item.message_type === 4 ?

                                        <Alert
                                            key={`toast-${index}`}
                                            description={
                                                <NotificationRow 
                                                    message_type={getMessageType(item.message_type)} 
                                                    message={item.transport === 2 ? item.subject : item.message}
                                                    date={item.send_date}
                                                    notification_state={item.notification_state}
                                                    />
                                            }
                                            type={getMessageIconType(item.message_type)}
                                            showIcon
                                            icon={<Icon type="bell" />}
                                        /> :

                                        <Alert
                                            key={`toast-${index}`}
                                            //message={getMessageType(item.message_type)}
                                            description={
                                                <NotificationRow 
                                                    message_type={getMessageType(item.message_type)} 
                                                    message={item.transport === 2 ? item.subject : item.message}
                                                    date={item.send_date}
                                                    notification_state={item.notification_state}
                                                    />
                                                }
                                            type={getMessageIconType(item.message_type)}
                                            showIcon
                                        />
                                    }

                                </List.Item>
                            )}
                        />
                        : <Empty />}

                </div>
            </>
    )
}

export default withRouter(NotificationList)