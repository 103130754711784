import {
    getGroupSoundMaskingService,
    putGroupSoundMaskingService
} from "../services";

export const fetchGroupSoundMaskingApi = async (tenant_uuid, uuid) => {

    const response = await getGroupSoundMaskingService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateGroupSoundMaskingApi = async (tenant_uuid, uuid, form) => {

    const response = await putGroupSoundMaskingService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}