const tmp_claims = {
    // user_managment_claims
    // super_admin_claims
    CanCreateSuperAdmin : 1,
    CanEditSuperAdmin : 2,
    CanRemoveSuperAdmin : 3,
    // company_admin_claims
    CanCreateCompanyAdmin : 4,
    CanEditCompanyAdmin : 5,
    CanAssignCompanyToCompanyAdmin : 6,
    CanRemoveCompanyAdmin : 7,
    // building admin_claims
    CanCreateBuildingAdmin : 8,
    CanEditBuildingAdmin : 9,
    CanAssignBuildingToBuildingAdmin : 10,
    CanRemoveBuildingAdmin : 11,
    // office_admin_claims
    CanCreateOfficeAdministrator : 12,
    CanEditOfficeAdministrator : 13,
    CanAssignGroupToOfficeAdministrator : 14,
    CanRemoveOfficeAdministrator : 15,
    // user_claims
    CanCreateUser : 16,
    CanEditUser : 17,
    CanAssignUserToGroup : 18,
    CanRemoveUser : 19,
    // general_user_claims
    CanInitiatePasswordChange : 20,
    CanManuallyChangeUserPassword : 21,
    CanListUsers : 22,
    CanListRoles : 23,
    CanRequestUserInfo : 24,
    // company_managment_claims
    // company_claims
    CanCreateCompany : 25,
    CanManageCompanyInformation : 26,
    CanRemoveCompany : 27,
    CanRequestCompanySynopsis : 28,
    CanRequestCompanyInfo : 29,
    CanListCompanies : 30,
    // building_claims
    CanCreateBuilding : 31,
    CanManageBuildingInformation : 32,
    CanRemoveBuilding : 33,
    CanListBuildings : 34,
    CanRequestBuildingInfo : 35,
    CanRequestBuildingSynopsis : 36,
    CanManipulateBuildingsParameters : 37,
    // groups_claims
    CanCreateGroup : 39,
    CanManageGroupInformation : 40,
    CanRemoveGroup : 41,
    CanChangeGroupName : 42,
    CanRequestGroupInfo : 43,
    CanListGroups : 44,
    CanManipulateGroupsParameters : 45,
    CanRequestGroupsParameters : 46,
    CanListGroupModules : 47,
    CanRemoveModuleFromGroup : 48,
    CanAssignModuleToGroup : 49,
    CanManipulateGroupLevels : 50,
    CanManipulateGroupOnOff : 51,
    CanRequestGroupLevels : 52,
    CanRequestGroupOnOff : 53,
    CanRequestGroupLatestSensorData : 54,
    CanRequestGroupHistoricSensorData : 55,
    CanAddGroupMapImage : 56,
    CanRequestGroupMapImage : 57,
    CanRemoveGroupMapImage : 58,
    CanChangeGroupDevicesLocation : 59,
    CanRequestGroupDevicesLocation : 60,
    // devices_claims
    CanRequestDeviceHistoricSensorData : 61,
    CanRequestDevicesHeatmap : 62,
    // dashboard_claims
    CanCreateDashboards : 63,
    CanListDashboard : 64,
    CanCreatePanels : 65,
    CanListPanel : 66,
    CanEditPanel : 67,
    // unused_claims
    CanProvisionNewModules : 68,
    CanRequestChangeModuleToGroup : 69,
    CanManageGroupSettings :70,
    CanManageModuleSettings : 71,
    CanRunReports : 72,
    CanRunDetailedReports : 73,
    CanRunGraphicalReports : 74,

    // new_claims
    CanRemoveDevicesLocation: 75,
    CanRequestDashboard: 76,
    CanRequestPanelInfo: 77,
    CanRemovePanel: 78,
    CanRemoveDashboard: 79,

    // list_user_companies_buildings_groups_claims
    CanListUserCompanies: 80,
    CanListUserBuildings: 81,
    CanListUserGroups: 82,

    // group_power_calibration_claims
    CanSetupGroupsPowerCalibration: 83,
    CanRequestGroupsPowerCalibrationStatus: 84,

    // aggregation_request_claims
    CanRequestGroupAggregationInfo: 87,
    CanManageGroupAggregation: 88,

    CanRequestBuildingAggregationInfo: 89,
    CanManageBuildingAggregation: 90,

    CanRequestCompanyAggregationInfo: 91,
    CanManageCompanyAggregation: 92,

    // modules claims
    CanManageCompanyModules: 93,
    CanListCompanyModules: 94,
    CanListAllCompanyModules: 95,

    // uv-c control claims
    CanManipulateUVControl: 96,
    CanRequestUVControl: 97,

    // groups_area_type_managment_claims
    CanCreateAreaType: 98,
    CanListAreaTypes: 99,
    CanUpdateAreaType: 100,
    CanRemoveAreaType: 101,
    CanGetAreaTypeDetails: 102,
    CanCreateAreaTypeParameter: 103,
    CanListAreaTypesParameters: 104,
    CanUpdateAreaTypeParameter: 105,
    CanRemoveAreaTypeParameter: 106,
    CanGetAreaTypeParameterDetails: 107,
    CanRemoveParameterValue: 108,
    CanEditAreaTypeParameterValues: 109,
    
    // generated graph claim
    CanRequestGeneratedGraph: 110,
    // user_password_reset
    CanResetUserPassword: 111,

    // notification claims
    CanRequestNotification: 112,
    CanUpdateNotification: 113,
    CanCreateNotification: 114,
    CanRemoveNotification: 115,

    // blue_light_boost_claims
    CanActivateDeactivateBlueLightBoost: 116,
    CanRequestGroupsBlueLightBoostStatus: 117
}

export const claims = Object.freeze(tmp_claims)
