import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, Slider, Select, message, Notification, Icon, Collapse, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import "./styles.css";

import CenteredRow from "../CenteredRow";
import { UserContext } from "../../context";
import useFocus from "../../utils/customHooks"
import { getGroupLabel } from "../../utils/helpers"
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'

const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
const { Panel } = Collapse

const _CreateEditElementForm = ({
  form,
  history,
  setIsModalVisible,
  isModalVisible,
  group_type,
  type
}) => {
  const [inputRef, setInputFocus] = useFocus();

  const {
    userData: { tenant_uuid }
  } = useContext(UserContext);

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, isFieldTouched } = form;
  const [initialLuxValue, setInitialLuxValue] = useState(400)

  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const editCreatedElement = useSelector((state) => state.rootReducer.editCreateElement.editCreatedElement)
  const error = useSelector((state) => state.rootReducer.editCreateElement.error)
  const loading = useSelector(state => state.rootReducer.loaders.isCreatingEditingElement)
  const claims = useSelector(state => state.rootReducer.auth.claims)
  const allModules = useSelector(state => state.rootReducer.companyManagment.modules)
  const allAreaTypes = useSelector(state => state.rootReducer.companyManagment.areasTypes)

  const dispatch = useDispatch()

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const {
          name,
          description,
          lux,
          modules,
          area_type,
          address_line1,
          address_line2,
          address_line3,
          country,
          state,
          city,
          zip_code,
          tenant_information,
          email,
          website,
          emergency_phone,
          landlord_phone,
          technical_phone,
          //extra_phone
        } = values;
        const form = { name };

        if (description && description.length) {
          form.description = description;
        }

        form.modules = modules

        const contact = {
          address_line1,
          address_line2,
          address_line3,
          country,
          state,
          city,
          zip_code,
          tenant_information,
          email,
          website,
          emergency_phone,
          landlord_phone,
          technical_phone,
          //extra_phone
        }
        
        let updated_area_type = false
        if (group_type !== "Company" && group_type !== "Building") {
          if (area_type && area_type.length > 0){
            form.area_type = area_type
            if (type !== "add"){
              updated_area_type = true
            }
          }
        }

        if (type === "add") {
          form.contact = contact
          if(group_type === "Company"){
            form.modules = modules
          }

          dispatch(({ type: actions.CREATE_ELEMENT, payload: { tenant_uuid: tenant_uuid, form: form, group_type: group_type } }))

          if (group_type !== "Company") {
            form.lux = lux
            if (group_type === "Building") {
              form.company_uuid = element.uuid
            } else {
              form.group_type = group_type
              if (element.group_type === "Building") {
                form.building_uuid = element.uuid
              } else {
                form.parent_uuid = element.uuid
              }
            }
          }
        } else {
          const uuid = element.uuid

          let modules_payload = null
          if (claims.CanManageCompanyModules){
            modules_payload = { modules: modules }
          }

          dispatch(({ type: actions.EDIT_ELEMENT, payload: { tenant_uuid: tenant_uuid, uuid: uuid, updated_area_type, element_form: form, modules_form: modules_payload, contact_form: contact, group_type: group_type } }))
        }
      }
    })
  }

  const handleCancel = async e => {
    setIsModalVisible(false)
  }

  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length === 0) {
        if (type === 'add') {
          setInputFocus()
        }
      }
    },
    [form]
  )

  useEffect(
    () => {
      if (!isModalVisible) {
        resetFields()
        dispatch(({ type: actions.CLEAN_CREATION_EDIT_ELEMENT_STATE }))
      } else {
        if (type === "add" && group_type !== "Company" && group_type !== "Building") {
          setInitialLuxValue(element.lux)
        }
      }
    },
    [isModalVisible]
  )

  useEffect(
    () => {
      if (editCreatedElement !== null) {
        const group_label = getGroupLabel(group_type)

        message.success(
          `${group_type} ${group_label}'${editCreatedElement.name}' successfully ${
          type === "add" ? "created" : "edited"
          }`
        )

        setIsModalVisible(false)
      }
    },
    [editCreatedElement]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_CREATION_EDIT_ELEMENT_STATE })
        }
      }
    },
    [error]
  )

  const markslux = {
    0: {
      style: {
        fontSize: '12px'
      },
      label: <span>0 lux</span>
    },
    500: {
      style: {
        fontSize: '12px',

      },
      label: <span>500 lux</span>,
    }
  };

  return (
    <CenteredRow>
      <Form onSubmit={handleSubmit} className="input-form">
        <FormItem label="Name">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "A name is required" }],
            initialValue: type === 'edit' && element ? element.name : ""
          })(<Input placeholder={`${group_type} ${getGroupLabel(group_type)}name`} ref={inputRef} disabled={loading}/>)}
        </FormItem>
        <FormItem label="Description">
          {getFieldDecorator("description", {
            initialValue: type === 'edit' && element ? element.description : ""
          })(
            <TextArea placeholder="Description" disabled={loading} />
          )}
        </FormItem>

        {
          (group_type === "Control" || group_type === "Logical" || group_type === "Mix") && (
            <div>
              <FormItem label="Area Type">
                {getFieldDecorator("area_type", {
                  initialValue: type === 'edit' && group_type !== "Company" && group_type !== "Building" && element && element.area_type ? element.area_type.name : []
                })(
                  <Select
                    mode="single"
                    disabled={loading }
                    style={{ width: "100%" }}
                    placeholder="Select group's area type"
                    allowClear={true}
                  >
                    { group_type !== "Company" && group_type !== "Building" && allAreaTypes && allAreaTypes.map(area => (
                      <Option key={area.name}>{area.label}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
          )}

        {
          (type === "add" && group_type !== "Company") && (
            <FormItem label="Illuminance">
              {getFieldDecorator("lux", {
                initialValue: initialLuxValue
              })(<Slider marks={markslux} size="large" min={0} max={500} step={1} disabled={loading} />)}
            </FormItem>
          )
        }

        {
          group_type === "Company" && (
            <div>
              <FormItem label="Modules">
                {getFieldDecorator("modules", {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (value && value.length >= 1) {
                          callback()
                        } else {
                          callback("At least one module is required")
                        }
                      }
                    }
                  ],
                  initialValue: type === 'edit' && group_type === "Company" && element && element.modules ? element.modules.map(module => `${module.name}`) : []
                })(
                  <Select
                    mode="multiple"
                    disabled={loading || !claims.CanManageCompanyModules }
                    style={{ width: "100%" }}
                    placeholder="Select company's module(s)"
                  >
                    { group_type === "Company" && allModules && allModules.map(module => (
                      <Option key={module.name}>{module.label}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
          )}

        <h4><b>Contact Information:</b></h4>
        <br></br>
        <Collapse>
          {
            (group_type === "Company" || group_type === "Building") && (
              <Panel header="Address" key="1">
                <Row gutter={30}>
                  <Col xs={6}>
                    <FormItem label="City">
                      {getFieldDecorator("city", {
                        initialValue: type === 'edit' && element && element.contact && element.contact.city ? element.contact.city : ""
                      })(
                        <Input placeholder="City" />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={6}>
                    <FormItem label="State">
                      {getFieldDecorator("state", {
                        initialValue: type === 'edit' && element && element.contact && element.contact.state ? element.contact.state : ""
                      })(
                        <Input placeholder="State" />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={6}>
                    <FormItem label="Country">
                      {getFieldDecorator("country", {
                        initialValue: type === 'edit' && element && element.contact && element.contact.country ? element.contact.country : ""
                      })(
                        <Input placeholder="Country" />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <FormItem label="Address 1">
                  {getFieldDecorator("address_line1", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.address_line1 ? element.contact.address_line1 : ""
                  })(
                    <Input placeholder="Street, number, etc" />
                  )}
                </FormItem>
                <FormItem label="Address 2">
                  {getFieldDecorator("address_line2", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.address_line2 ? element.contact.address_line2 : ""
                  })(
                    <Input placeholder="Street, number, etc" />
                  )}
                </FormItem>
                <FormItem label="Address 3">
                  {getFieldDecorator("address_line3", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.address_line3 ? element.contact.address_line3 : ""
                  })(
                    <Input placeholder="Street, number, etc" />
                  )}
                </FormItem>
                <FormItem label="Postal Colde">
                  {getFieldDecorator("zip_code", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.zip_code ? element.contact.zip_code : ""
                  })(
                    <Input placeholder="Postal, zip code" />
                  )}
                </FormItem>
              </Panel>
            )
          }
          <Panel header="Phones/Email" key="2">
            <Row gutter={30}>
              <Col xs={10}>
                <FormItem label="Emergency Phone">
                  {getFieldDecorator("emergency_phone", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.emergency_phone ? element.contact.emergency_phone : ""
                  })(
                    <Input placeholder="Emergency phone" />
                  )}
                </FormItem>
              </Col>
              <Col xs={10}>
                <FormItem label="Landlord Phone">
                  {getFieldDecorator("landlord_phone", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.landlord_phone ? element.contact.landlord_phone : ""
                  })(
                    <Input placeholder="Landlord Phone" />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col xs={10}>
                <FormItem label="Support Phone">
                  {getFieldDecorator("technical_phone", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.technical_phone ? element.contact.technical_phone : ""
                  })(
                    <Input placeholder="Support Phone" />
                  )}
                </FormItem>
              </Col>
              {/*

              <Col xs={10}>
                <FormItem label="Extra Phone">
                  {getFieldDecorator("extra_phone", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.extra_phone ? element.contact.extra_phone : ""
                  })(
                    <Input placeholder="Extra Phone" />
                  )}
                </FormItem>
              </Col>

              */}
            </Row>
            <Row gutter={10}>
              <Col xs={11}>
                <FormItem label="Email">
                  {getFieldDecorator("email", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.email ? element.contact.email : ""
                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
              </Col>
              <Col xs={11}>
                <FormItem label="Website">
                  {getFieldDecorator("website", {
                    initialValue: type === 'edit' && element && element.contact && element.contact.website ? element.contact.website : ""
                  })(
                    <Input placeholder="Website url" />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>
          <Panel header="Tenant Information" key="3">
            <FormItem>
              {getFieldDecorator("tenant_information", {
                initialValue: type === 'edit' && element && element.contact && element.contact.tenant_information ? element.contact.tenant_information : ""
              })(
                <TextArea placeholder="Tenant information/description" />
              )}
            </FormItem>
          </Panel>
        </Collapse>
        <br></br>
        <FormItem style={{ textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="button"
            style={{ width: "40%" }}
            size="large"
            loading={loading}
            onClick={handleSubmit}
          >
            {!loading ? (<Icon type={type === "add" ? "plus" : "save"} />) : ""} {type === "add" ? "Add" : "Save"}
          </Button>

          <Button
            type="default"
            htmlType="button"
            style={{ width: "40%", marginLeft: "10px" }}
            size="large"
            loading={loading}
            onClick={handleCancel}
          >
            {!loading ? <Icon type="close" /> : <></>} Cancel
          </Button>
        </FormItem>
      </Form>
    </CenteredRow>
  );
};

const CreateEditElementForm = Form.create()(_CreateEditElementForm);

export default withRouter(CreateEditElementForm);
