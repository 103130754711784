import * as actions from '../constants';

const initialState = {
  uvcontroldata: null,
  error: null,
}

const UVControl = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_UV_CONTROL:
      return Object.assign({}, state, {
        uvcontroldata: action.payload.data,
        error: null
      })
    case actions.ERROR_FETCH_UPDATE_UV_CONTROL:
      return Object.assign({}, state, {
        uvcontroldata: null,
        error: action.payload.error
      })
    case actions.CLEAN_UV_CONTROL_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_UV_CONTROL_STATE:
      return Object.assign({}, state, {
        error: null,
        uvcontroldata: null,
      })
    default:
      return state
  }
}

export default UVControl