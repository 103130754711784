import * as actions from '../constants';

const initialState = {
  controllers: null,
  error: null,
  added: false,
  removed: false,
}

const controllers = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_DEVICES:
      return Object.assign({}, state, {
        controllers: action.payload.data,
        error: null,
        added: false,
        removed: false,
      })
    case actions.SUCCESS_ADD_DEVICES:
      return Object.assign({}, state, {
        controllers: state.controllers.concat(action.payload.data),
        error: null,
        added: true,
        removed: false,
      })
    case actions.SUCCESS_REMOVE_DEVICE:
      return Object.assign({}, state, {
        controllers: state.controllers.filter(d => d.aws_id !== action.payload.aws_id),
        error: null,
        added: false,
        removed: true,
      })
    case actions.ERROR_FETCH_UPDATE_DEVICES:
      return Object.assign({}, state, {
        error: action.payload.error
      })
    case actions.CLEAN_DEVICES_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_DEVICES_STATE:
      return Object.assign({}, state, {
        error: null,
        controllers: null,
        added: false,
        removed: false,
      })
    default:
      return state
  }
}

export default controllers