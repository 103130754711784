import React, { useState, useEffect, useContext } from "react";
import { Menu, Dropdown, Icon } from "antd";
import "./style.css";

const ContextMenuDashboard = ({
  text,
  icon,
  parent,
  
}) => {
  const [addItem, setAddItem] = useState([{}]);
  const [item, setItem] = useState([{}]);

  useEffect(() => {
    (async () => {
      // Add item
      if (parent.group_type === "Company") setAddItem(["Building"]);
      if (parent.group_type === "Building") setAddItem(["Logical Group"]);
      if (parent.group_type === "Logical")
        setAddItem(["Logical Group", "Control Group", "Mix Group"]);

      // Remove items
      setItem({ uuid: parent.uuid, elementType: parent.group_type });
    })();
  }, []);

  const getIcon = group_type => {
    let iconType = "";
    switch (group_type) {
      case "Company":
        iconType = "bank";
        break;
      case "Building":
        iconType = "hdd";
        break;
      case "Logical":
        iconType = "apartment";
        break;
      case "Control":
        iconType = "control";
        break;
      case "Mix":
        iconType = "appstore";
        break;
      default:
        iconType = "team";
        break;
    }
    return iconType;
  };

  const menu = (
    <Menu >
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["ContextMenuDashboard"]}>
      <span>
        <Icon type={getIcon(icon)} />
        <span style={{ userSelect: "none" }}>{` ${text}`}</span>
      </span>
    </Dropdown>
  );
};

export default ContextMenuDashboard;
