import React, { useState, useEffect } from 'react'
import { Modal, Slider } from 'antd'
//import {lightColorTemperatureFormatter} from "../../utils/lightLevelsColorTemperature"

const ScheduleModal = ({ visible, hideModal, savePeriod, period, schedule, group }) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading,] = useState(false)

  //const [startTime, setStartTime] = useState()
  //const [stopTime, setStopTime] = useState()


  const [startHour, setStartHour] = useState(0)
  const [stopHour, setStopHour] = useState(0)

  const [startMinute, setStartMinute] = useState(0)
  const [stopMinute, setStopMinute] = useState(0)

  const [lightLevel, setLightLevel] = useState(0)
  const [whiteLevel, setWhiteLevel] = useState(0)

  useEffect(() => {
    if (period && period.start_time) {
      setLoading(false);
      //const getoffset = new Date().getTimezoneOffset() / 60;
      
      
      //setStartTime(period.start_time)
      let arraystart = period.start_time
      arraystart = arraystart.split(":")
      setStartHour(Number(arraystart[0])%24) // - getoffset + 24)%24)
      setStartMinute(Number(arraystart[1])%60)
      
      //setStopTime(period.stop_time)
      let arraystop = period.stop_time
      arraystop = arraystop.split(":")
      setStopHour(Number(arraystop[0])%24) // - getoffset + 24)%24)
      setStopMinute(Number(arraystop[1])%60)
      setLightLevel(period.light_level)
      setWhiteLevel(period.white_level)
    } else {
      //setStartTime("00:00:00")
      setStartHour(0)
      setStartMinute(0)
      //setStopTime("00:00:00")
      setStopHour(0)
      setStopMinute(0)
      setLightLevel(schedule.light_level)
      setWhiteLevel(schedule.white_level)
    }


  }, [period])

  useEffect(() => {
      setLoading(false);
  }, [visible])

  const lightColorTemperatureFormatter = (value) => {
    const conver = (3000 + 30*value).toFixed(0)
    return `${conver}K`;
}

  const onSave = async () => {
    let startH = startHour;
    let startM = startMinute;
    let stopH = stopHour;
    let stopM = stopMinute;
    if (startHour < stopHour || (startHour === stopHour && startMinute < stopMinute)) {

    } else {

      if (startMinute + 15 < 60) {
        stopH = startH;
        stopM = startM + 15;
      } else {
        stopH = (startH + 1);
        stopM = (startM + 15) % 60;
      }

      if(stopH===24){
        stopH=23
        stopM=59

        if(startM===59){
          startM=58
        }
        

      }
    }
      //const startT = (startH + ":" + startM + ":00")
    //const stopT = (stopH + ":" + stopM + ":00")
    setLoading(true);
    await savePeriod(startH, startM, stopH, stopM, lightLevel, whiteLevel)
  }

  const handleChangeStartHour = (value) => {
    //if(value<stopHour || (value===stopHour && startMinute<stopMinute)){
    setStartHour(value)
    //}
  }
  const handleChangeStartMinute = (value) => {
    //if(startHour<stopHour || (startHour===stopHour && value<stopMinute)){
    setStartMinute(value)
    //}

  }
  const handleChangeStopHour = (value) => {
    setStopHour(value)
  }
  const handleChangeStopMinute = (value) => {
    setStopMinute(value)
  }

  const handleChangeLightLevel = value => {
    setLightLevel(value);
  }

  const handleChangeWhiteLevel = value => {
    setWhiteLevel(value);
  }

  const setTime = async () => {
    //setStartTime(startHour + ":" + (startMinute<10?"0":"") + startMinute + ":00")
    //setStopTime(stopHour + ":" + (stopMinute<10?"0":"") + stopMinute + ":00")

  }

  const showStartTime = () =>{
    const setCero = (((Number)(startMinute)<10)?"0":"");
    return(startHour + ":" + setCero + "" + startMinute + "")
  }

  const showStopTime = () =>{
    const setCero = (((Number)(stopMinute)<10)?"0":"");
    return(stopHour + ":" + setCero + "" + stopMinute + "")
  }

  const markshour = {
    0: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>0</span>
    },
    23: {
      style: {
        fontSize: '12px',
        color: "#53575A"

      },
      label: <span>23</span>,
    }
  };

  const marksminute = {
    0: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>0</span>
    },
    59: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>59</span>,
    }
  };

  const marksbright = {
    0: {
      style: {
        fontSize: '12px'
      },
      label: <span>0%</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>100%</span>,
    }
  }

  const marksColorTemperature = {
    0: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Warm White</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Bright White</span>,
    }
  }

  return (
    <Modal
      wrapClassName="fullscreen-mobile-modal"
      destroyOnClose
      okText="Save"
      title={period && period.start_time ? 'Edit Period' : 'Create Period'}
      visible={visible}
      onOk={onSave}
      okButtonProps={{ loading: loading }}
      onCancel={hideModal}
      cancelButtonProps={{ loading: loading }}
      confirmLoading={confirmLoading}
    >
      <div className="slider-select-period">
        <div className="big-text"><span>ON: </span>{showStartTime()}</div>
        <Slider disabled={loading} marks={markshour} min={0} max={23} onChange={handleChangeStartHour} onAfterChange={setTime} value={startHour} />
      </div>

      <div className="slider-select-period">
        <Slider disabled={loading} marks={marksminute} min={0} max={59} onChange={handleChangeStartMinute} onAfterChange={setTime} value={startMinute} />
      </div>

      <div className="slider-select-period">
        <div className="big-text"><span>OFF: </span>{showStopTime()}</div>
        <Slider disabled={loading} marks={markshour} min={0} max={23} onChange={handleChangeStopHour} onAfterChange={setTime} value={stopHour}  />
      </div>
      <div className="slider-select-period">

        <Slider disabled={loading} marks={marksminute} min={0} max={59} onChange={handleChangeStopMinute} onAfterChange={setTime} value={stopMinute} />

        <div className="big-text">Brightness</div>
          <div>
            <Slider disabled={loading} step={5} marks={marksbright} min={0} max={100} onChange={handleChangeLightLevel} value={lightLevel ? lightLevel : 0} />
          </div>
          <div className="big-text">Light Temperature</div>
          <div>
            <Slider disabled={loading} step={5} marks={marksColorTemperature} tipFormatter={lightColorTemperatureFormatter} min={0} max={100} onChange={handleChangeWhiteLevel} value={whiteLevel ? whiteLevel: 0} />
          </div>
      </div>
    </Modal>
  )
}


export default (ScheduleModal)
