const axios = require("axios");

// setAppApi 1 for local enviroment (localhost)
// 2 for north virginia dev enviroment deploy
// 3 for montreal prod enviroment deploy
const setAppApi = parseInt(process.env.REACT_APP_SET_ADMIN_API)

let REACT_APP_ADMIN_API

if (setAppApi === 1) {
  REACT_APP_ADMIN_API = process.env.REACT_APP_ADMIN_API_LOCAL
} else if (setAppApi === 2) {
  REACT_APP_ADMIN_API = process.env.REACT_APP_ADMIN_API_DEV
} else {
  REACT_APP_ADMIN_API = process.env.REACT_APP_ADMIN_API_PROD
}

axios.defaults.headers.common["Content-Type"] = "application/json";

// Add Authorization token to request header globally
export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export const setTemp2faToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

//! App login
export const loginService = form => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/auth`,
    data: form
  })
}

export const login2faService = form => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/2fa_auth`,
    data: form
  })
}

export const login2faSecondStepService = form => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/2fa_auth/second_step`,
    data: form
  })
}

// reset password URIs
export const postForgotPasswordService = form => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/forgot_password`,
    data: form
  })
}

export const postTokenResetPasswordService = form => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/reset_password`,
    data: form
  })
}

//! USER
export const getAllUsersService = tenant_uuid => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/users`
  });
};

export const deleteUsersService = (tenant_uuid, uuid) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}`
  });
};

export const postUsersService = (tenant_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/users`,
    data: form
  });
};

export const putUsersService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}`,
    data: form
  });
};

export const putUsersTransactionalService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user_update/${uuid}`,
    data: form
  });
};

// Get an user's details [including roles]
export const getUserDetailsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}`
  });
};

// Get an user's groups
export const getUserGroupsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/groups`
  })
}

export const getUserCompaniesService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/companies`
  })
}

export const getUserBuildingsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/buildings`
  })
}

// Add user to groups
export const postUserToGroupService = (tenant_uuid, uuid, group_uuid) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/group/${group_uuid}`
  });
};

// Delete user to groups
export const delUserToGroupService = (tenant_uuid, uuid, group_uuid) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/group/${group_uuid}`
  });
};

export const putUserResetPasswordService = (tenant_uuid, uuid) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/reset_password`,
  })
}

// user profile
// get user user 2fa qr
export const getUser2faQrCodeService = (tenant_uuid, uuid) => {
  console.log(tenant_uuid, uuid)
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/qrcode`
  })
}

export const getUser2faQrCodeLoginService = (tenant_uuid, uuid) => {
  console.log(tenant_uuid, uuid)
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/qrcode_login`
  })
}

export const putUserProfileService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${uuid}/profile`,
    data: form
  })
}

//! ROLES
export const getAllRolesService = () => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/roles`
  });
};

//! MODULES
export const getAllModulesService = () => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/modules`
  })
}

//! COMPANY
export const getAllCompaniesService = tenant_uuid => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/companies`
  });
};

export const postCompanyService = (tenant_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/companies`,
    data: form
  });
};

export const deleteCompanyService = (tenant_uuid, uuid) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}`
  });
};

export const getCompanyDetailsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}`
  });
};

export const getCompanySynopsisService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}/synopsis`
  })
}

export const putCompanyService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}`,
    data: form
  })
}

export const putCompanyContactService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}/contact`,
    data: form
  })
}

export const putCompanyModulesService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}/modules`,
    data: form
  })
}

export const getCompanyAggregationRequestService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}/aggregation_request`
  })
}

export const putCompanyAggregationRequestService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/company/${uuid}/aggregation_request`,
    data: form
  })
}

export const getChangeElementPositionService = (tenant_uuid, group_uuid, parent_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/insert/${group_uuid}/after/${parent_uuid}`
  })
}

//! BUILDING
export const getAllBuildingsService = tenant_uuid => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/buildings`
  });
};

export const postBuildingsService = (tenant_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/buildings`,
    data: form
  })
}

export const deleteBuildingService = (tenant_uuid, uuid) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}`
  });
};

export const getBuildingDetailsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}`
  });
};

export const getBuildingSynopsisService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/synopsis`
  })
}

export const putBuildingService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}`,
    data: form
  });
};

export const putBuildingLuxService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/lux`,
    data: form
  });
};

export const getBuildingLuxService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/lux`
  });
};

export const putBuildingWattsService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/watts`,
    data: form
  });
};

export const getBuildingWattsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/watts`
  });
};

export const putBuildingParametersService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/parameters`,
    data: form
  });
};

export const getBuildingParametersService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/parameters`
  });
};

export const putBuildingEnergyOverviewSettingsService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/energy_analysis_settings`,
    data: form
  });
};

export const getBuildingEnergyOverviewSettingsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/energy_analysis_settings`
  });
}

export const putBuildingContactService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/contact`,
    data: form
  });
};

export const getBuildingAggregationRequestService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/aggregation_request`
  })
}

export const getBuildingPeaksEnergyConsumptionService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/peaks_energy_consumption`
  })
}

export const getBuildingLatestEnergyConsumptionService = (tenant_uuid, uuid, hours, minutes, seconds) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/hours/${hours}/minutes/${minutes}/seconds/${seconds}/energy_consumption`
  })
}

export const putBuildingAggregationRequestService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/building/${uuid}/aggregation_request`,
    data: form
  })
}

//! GROUP
export const getAllGroupsService = tenant_uuid => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/groups`
  });
};

export const getGroupService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}`
  });
};

export const postGroupsService = (tenant_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/groups`,
    data: form
  });
};

export const deleteGroupService = (tenant_uuid, uuid) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}`
  });
};

export const getGroupDetailsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}`
  });
};

export const putGroupService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}`,
    data: form
  });
};

export const putGroupLuxService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/lux`,
    data: form
  });
};

export const getGroupLuxService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/lux`
  });
};

export const putGroupWattsService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/watts`,
    data: form
  });
};

export const getGroupWattsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/watts`,
  });
};

export const putGroupParametersService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/parameters`,
    data: form
  });
};

export const getGroupParametersService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/parameters`
  });
};

export const putGroupEnergyAnalysisSettingsService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/energy_analysis_settings`,
    data: form
  });
};

export const getGroupEnergyAnalysisSettingsService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/energy_analysis_settings`
  });
};

export const getGroupPeakEnergyConsumptionService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/peaks_energy_consumption`
  })
}

export const getGroupLatestEnergyConsumptionService = (tenant_uuid, uuid, hours, minutes, seconds) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/hours/${hours}/minutes/${minutes}/seconds/${seconds}/energy_consumption`
  })
}


// power calibration services
export const putGroupPowerCalibrationService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/power_calibration`,
    data: form
  })
}

export const getGroupPowerCalibrationService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/power_calibration`
  })
}

// blue light boost services
export const putGroupBlueLightBoostService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/blue_light_boost`,
    data: form
  })
}

export const getGroupBlueLightBoostService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/blue_light_boost`
  })
}

// circadian rhythm services
export const putGroupCircadianRhythmService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/circadian_rhythm`,
    data: form
  })
}

export const getGroupCircadianRhythmService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/circadian_rhythm`
  })
}

export const getGroupLightCapabilityService = (tenant_uuid, group_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/light_capability`
  })
}

export const getGroupDevicesService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/devices`,
  });
};

export const getGroupLatestSensorDataService = (tenant_uuid, uuid, sensor_type) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/latest_sensordata/${sensor_type}/threading`,
  })
}

export const getGroupHistoricSensorDataService = (tenant_uuid, uuid, sensor_type, start_date, end_date) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/historic_sensordata/${sensor_type}/start/${start_date}/end/${end_date}`,
  })
}

export const getDeviceHistoricSensorDataService = (tenant_uuid, uuid, aws_id, sensor_type, start_date, end_date) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/device/${aws_id}/historic_sensordata/${sensor_type}/start/${start_date}/end/${end_date}`,
  });
};

export const getDeviceHistoricHighPrecisionSensorDataService = (tenant_uuid, uuid, aws_id, sensor_type, start_date, end_date) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/device/${aws_id}/historic_high_precision_sensordata/${sensor_type}/start/${start_date}/end/${end_date}`,
  });
};

export const getDeviceHistoricUVSensorDataService = (tenant_uuid, uuid, aws_id, start_date, end_date) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/device/${aws_id}/historic_uv_lightcycle_sensordata/start/${start_date}/end/${end_date}`,
  });
};

export const getVectorDataAnalysisRequestService = (tenant_uuid, element_type, element_uuid, analysis_type) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/${element_type}/${element_uuid}/data_analysis/${analysis_type}`,
  });
};

export const createVectorDataAnalysisRequestService = (tenant_uuid, element_type, element_uuid, analysis_type, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/${element_type}/${element_uuid}/data_analysis/${analysis_type}`,
    data: form
  });
};

export const getVectorDataAnalysisStatusRequestService = (tenant_uuid, data_analysis_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/data_analysis/${data_analysis_uuid}/status`,
  });
};

export const getDeviceGraphService = (tenant_uuid, group_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/device_graph`,
  });
};



export const getGroupHistoricAggregatedSensorDataService = (tenant_uuid, uuid, start_date, end_date) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/historic_energy_aggregation/start/${start_date}/end/${end_date}`,
  })
}

export const putGroupContactService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/contact`,
    data: form
  });
};

export const getGroupLevelsOverrideService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/level/override`,
  });
};

export const getGroupOnDetectOffOverrideService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/on_detect_off/override`,
  });
};

export const putGroupLevelsOverridePropagationService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/level/propagation`,
    data: form
  })
}

export const putGroupOnOverridePropagationService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/on_detect_off/propagation`,
    data: form
  })
}

export const getGroupOccupancySwitchService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/occupancy_switch`,
  });
};

export const putGroupOccupancySwitchService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/occupancy_switch`,
    data: form
  })
}

export const getGroupAggregationRequestService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/aggregation_request`,
  })
}

export const putGroupAggregationRequestService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/aggregation_request`,
    data: form
  })
}



// Devices (controllers)

export const postDevicesService = (tenant_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/devices`,
    data: form
  });
};

export const deleteDeviceService = (tenant_uuid, aws_id) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/device/${aws_id}`
  });
};




export const getGroupDevicesLocation = (tenant_uuid, group_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/metadata/location_device`,
  });
};

export const putGroupDevicesLocation = (tenant_uuid, group_uuid, data) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/metadata/location_device`,
    data: data
  });
};



export const getGroupExitLocation = (tenant_uuid, group_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/metadata/exit`,
  });
};

export const putGroupExitLocation = (tenant_uuid, group_uuid, data) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/metadata/exit`,
    data: data
  });
};



export const getGroupImageMap = (tenant_uuid, group_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/metadata/image_map`
  });
};

export const putGroupImageMap = (tenant_uuid, group_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${group_uuid}/metadata/image_map`,
    data: form
  });
};

//dashboard
export const getUserDashboard = (tenant_uuid, username) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/username/${username}/dashboards`
  });
};

export const postDashboard = (tenant_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/dashboard`,
    data: form
  });
};
export const getDashboard = (tenant_uuid, dashboard_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/dashboard/${dashboard_uuid}`
  });
};

export const getPanels = (tenant_uuid, dashboard_uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/dashboard/${dashboard_uuid}/panels`
  });
};
export const postPanel = (tenant_uuid, dashboard_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/dashboard/${dashboard_uuid}/panels`,
    data: form
  });
};
export const putPanel = (tenant_uuid, dashboard_uuid, panel_uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/dashboard/${dashboard_uuid}/panel/${panel_uuid}`,
    data: form
  });
};
export const deletePanel = (tenant_uuid, dashboard_uuid, panel_uuid) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/dashboard/${dashboard_uuid}/panel/${panel_uuid}`
  });
};



export const putGroupPeopleAnalyticsParametersService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/people_analytics`,
    data: form
  });
};

export const getGroupPeopleAnalyticsParametersService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/people_analytics`
  });
};



export const getGroupUVControlService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/uvcontrol`,
  });
};

export const putGroupUVControlService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/uvcontrol`,
    data: form
  });
};

// area type parameters services
export const getAreaTypesListService = () => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/area_types`,
  })
}

export const postAreaTypeService = (form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/area_types`,
    data: form
  })
}

export const getAreaTypeDetailsService = (area_type_name) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/area_type/${area_type_name}`
  })
}

export const putAreaTypeService = (area_type_name, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/area_type/${area_type_name}`,
    data: form
  })
}

export const deleteAreaTypeService = (area_type_name) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/area_type/${area_type_name}`
  })
}

export const postAreaTypeParameterValueService = (area_type_name, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/area_type/${area_type_name}`,
    data: form
  })
}

export const deleteAreaTypeParameterValueService = (area_type_name, parameter_name) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/area_type/${area_type_name}/${parameter_name}`
  })
}

export const getAreaTypeParametersListService = () => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/area_type_parameters`,
  })
}

export const postAreaTypeParameterService = (form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/area_type_parameters`,
    data: form
  })
}

export const getAreaTypeParameterDetailsService = (area_type_parameter_name) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/area_type_parameter/${area_type_parameter_name}`
  })
}

export const putAreaTypeParameterService = (area_type_parameter_name, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/area_type_parameter/${area_type_parameter_name}`,
    data: form
  })
}

export const deleteAreaTypeParameterService = (area_type_parameter_name) => {
  return axios({
    method: "delete",
    url: `${REACT_APP_ADMIN_API}/area_type_parameter/${area_type_parameter_name}`
  })
}

export const getEnergyAnalysisService = (tenant_uuid, element_type, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/${element_type}/${uuid}/generated_graph`,
  });
};


//Notification
export const getNotificationService = (tenant_uuid, element_type, uuid, receive_send, transport) => {
  
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/${element_type}/${uuid}/${transport}/notifications/${receive_send}`,
  });
};

export const getUserNotificationService = (tenant_uuid, receive_send, transport) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/${transport}/notifications/${receive_send}`,
  });
};

export const postNotification = (tenant_uuid, form) => {
  return axios({
    method: "post",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/notifications`,
    data: form
  })
}


export const getAllNotificationLastMinutesService = (tenant_uuid, last_minutes) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user/all/notifications/last_minutes/${last_minutes}`,
  });
};

export const putToastNotificationService = (tenant_uuid, notification_uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/notification/${notification_uuid}/notifications_receipt`,
    data: form
  })
}

export const getElementsNotificationService = (tenant_uuid, elements_type) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/user_elements/${elements_type}`,
  });
};


//Advanced Schedule
export const getGroupAdvancedScheduleService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/advanced_schedule`,
  });
};

export const putGroupAdvancedScheduleService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/advanced_schedule`,
    data: form
  });
};

//sound masking
export const getGroupSoundMaskingService = (tenant_uuid, uuid) => {
  return axios({
    method: "get",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/sound_masking`,
  });
};

export const putGroupSoundMaskingService = (tenant_uuid, uuid, form) => {
  return axios({
    method: "put",
    url: `${REACT_APP_ADMIN_API}/${tenant_uuid}/group/${uuid}/sound_masking`,
    data: form
  });
};
