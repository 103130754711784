import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import IdleTimer from "react-idle-timer"
import { message, Card, Select, DatePicker, Form, Button, Row, Col, Icon, Input, Notification, Modal, InputNumber, Radio, Switch } from "antd";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import "./styles.css";


import { UserContext } from "../../context";
import * as actions from '../../constants';
import * as error_codes from '../../constants/error_codes';
import { getIndexTransport } from "../../utils/helpers"
import * as constant from '../../constants/notifications';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const _NotificationCreate = ({
  form,
  history,
  form_type,
  element_uuid,
  element_type,
  deselectCreate,
}) => {

  const rolename = useSelector((state) => state.rootReducer.auth.rolename)

  const [transport, setTransport] = useState(constant.TOAST)

  const [sendDate,] = useState(moment())
  const [notificationType,] = useState(constant.DIRECT)
  const [messageType,] = useState(constant.INFORMATION)
  const [subject,] = useState("")
  const [themessage,] = useState("")
  const [escalationTime,] = useState("60")

  const [saveDisabled, setSaveDisabled] = useState(true)
  const [touchedFields, setTouchedFields] = useState([])
  const idleTimer = useRef(null)
  const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)


  const [elementType, setElementType] = useState("group")
  const notificationElements = useSelector((state) => state.rootReducer.notificationUser.notificationElements)
  const loadingElements = useSelector(state => state.rootReducer.loaders.loadingElementsNotification)
  //const errorElements = useSelector((state) => state.rootReducer.notificationUser.errorElements)


  const error = useSelector((state) => state.rootReducer.notification.errorCreateNotification)
  const succesCreate = useSelector((state) => state.rootReducer.notification.notificationSuccesCreate)
  const loading = useSelector(state => state.rootReducer.loaders.loadingCreateNotification)


  const dispatch = useDispatch()

  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)


  const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue } = form


  const backFunction = () => {
    deselectCreate()
  }

  useEffect(
    () => {
      if (elementType && elementType !== "") {
        dispatch({ type: actions.FETCH_ELEMENTS_NOTIFICATION, payload: { tenant_uuid: tenant_uuid, element_type: elementType } })
      }
    },
    [elementType]
  )

  useEffect(
    () => {

    },
    [notificationElements]
  )


  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { notification_send_date, notification_type, notification_message_type, notification_message, notification_subject, notification_escalation_time, notification_element_type, notification_element_uuid, notification_requires_confirmation } = values
        const form = {}
        if (touchedFields.includes("notification_send_date")) {
          form.send_date = notification_send_date
        }

        if (touchedFields.includes("notification_type")) {
          form.notification_type = parseInt(notification_type)
        } else {
          form.notification_type = 1
        }

        if (touchedFields.includes("notification_message_type")) {
          form.message_type = parseInt(notification_message_type)
        }

        if (touchedFields.includes("notification_message")) {
          form.message = notification_message
        }

        if (touchedFields.includes("notification_subject")) {
          form.subject = notification_subject
        }

        if (touchedFields.includes("notification_escalation_time")) {
          form.escalation_time = parseInt(notification_escalation_time)
        }

        if (touchedFields.includes("notification_requires_confirmation")) {
          form.requires_confirmation = notification_requires_confirmation
        }else{
          form.requires_confirmation = false
        }

        form.transport = getIndexTransport(transport)

        if (form_type === "element") {
          form.element_uuid = element_uuid
          form.element_type = element_type
        } else if (form_type === "user") {
          if (touchedFields.includes("notification_element_type")) {
            form.element_type = notification_element_type
          } else {
            form.element_type = elementType
          }
          if (touchedFields.includes("notification_element_uuid")) {
            form.element_uuid = notification_element_uuid
          } else {
            form.element_uuid = notificationElements.length > 0 ? notificationElements[0].uuid : ""
          }
        }

        dispatch({ type: actions.CREATE_NOTIFICATION, payload: { tenant_uuid: tenant_uuid, form: form } })
      } else {
        message.error(err)
      }
    })
  }


  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
      setTouchedFields(touched_fields)
    },
    [form]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              dispatch({ type: actions.CREATE_NOTIFICATION, payload: { tenant_uuid: tenant_uuid, form: form } })
              setSaveDisabled(true)
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_CREATE_NOTIFICATION })
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (succesCreate) {
        message.success(`Notification successfully created`)
        deselectCreate()
        dispatch({ type: actions.CLEAN_CREATE_NOTIFICATION })
      } else {
        dispatch({ type: actions.CLEAN_CREATE_NOTIFICATION })
      }
    },
    [succesCreate]
  )

  function handleElementTypeChange(value) {
    setElementType(value);
  }

  function selectSendDate(value, dateString) {
    //console.log('Selected Time: ', value);
    //console.log('Formatted Selected Time: ', dateString);
  }

  const selectTransport = e => {
    setTransport(e.target.value);
  };

  function onOk(value) {
    //console.log('onOk: ', value);
  }


  return (false ? (<div>
    <div className="big-text">No group selected</div>
  </div>) : (
      <>
        <Row style={{ marginBottom: "10px" }}>
          <Col span={4}><Button onClick={() => backFunction()}><Icon type="arrow-left" />Back</Button></Col>
          {false && 
          <Col span={20} style={{ textAlign: "center" }}>
            <Radio.Group
              className="radio-button-receipt-sender"
              onChange={selectTransport}
              value={transport}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio.Button key="option-toast" value="toast">Toast</Radio.Button>
              <Radio.Button key="option-mail" value="mail">Mail</Radio.Button>
              <Radio.Button key="option-sms" value="sms">SMS</Radio.Button>
            </Radio.Group>
          </Col>
          }
        </Row>
        <div className="not-margin">
          <Card style={{ width: "100%" }}>
            <IdleTimer
              ref={idleTimer}
              element={document}
              debounce={250}
              timeout={timeout}
            ></IdleTimer>

            <Form onSubmit={handleSubmit}>
              {form_type === "user" ?
                <>
                  <FormItem label={<span>Send to</span>} className="form-display-inline">
                    {getFieldDecorator("notification_element_type", {
                      initialValue: elementType
                    })(<Select style={{ width: 240 }} onChange={handleElementTypeChange}>
                      {(rolename === "SuperAdmin" || rolename === "CompanyAdmin") &&
                        <Option key="company-option" value="company">Company</Option>
                      }
                      {(rolename === "SuperAdmin" || rolename === "CompanyAdmin" || rolename === "BuildingAdmin") &&
                        <Option key="building-option" value="building">Building</Option>
                      }
                      <Option key="group-option" value="group">Group</Option>

                      <Option key="user-option" value="user">User</Option>
                    </Select>)}
                  </FormItem>

                  <FormItem label={<span>Select {elementType}</span>} className="form-display-inline">
                    {getFieldDecorator("notification_element_uuid", {
                      initialValue: notificationElements && notificationElements.length > 0 ? (notificationElements[0].name ? notificationElements[0].name : notificationElements[0].username) : ""
                    })(<Select
                      mode="single"
                      style={{ width: 240 }}
                      enabled={loadingElements}
                    >
                      {notificationElements && notificationElements.map(item => (
                        <Option key={item.uuid} value={item.uuid}>{item.name ? item.name : item.username}</Option>
                      ))
                      }
                    </Select>)}
                  </FormItem>
                </>
                : <></>}

              {transport === constant.EMAIL ?
                <FormItem label={<span>Subject</span>}>
                  {getFieldDecorator("notification_subject", {
                    initialValue: subject
                  })(<Input style={{ width: "100%" }} />)}
                </FormItem>
                : <></>}

              <FormItem key="form-item-notification-message" label={<span>Message</span>}>
                {getFieldDecorator("notification_message", {
                  rules: [
                    { required: true, message: "Message is required" },
                  ],
                  initialValue: themessage
                })(<TextArea rows={10} />)}
              </FormItem>

              <FormItem label="Message Type" className="form-display-inline">
                {getFieldDecorator("notification_message_type", {
                  initialValue: messageType,
                })(
                  <Radio.Group className="radio-button-create-notification-form radio-group-message-type">
                    <Radio.Button key="option-information" value="1">Information</Radio.Button>
                    <Radio.Button key="option-error" value="2">Error</Radio.Button>
                    <Radio.Button key="option-warmning" value="3">Warning</Radio.Button>
                    <Radio.Button key="option-alarm" value="4">Alarm</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>

              <FormItem label="Notification Type" className="form-display-inline">
                {getFieldDecorator("notification_type", {
                  initialValue: notificationType,
                  //valuePropName: 'checked'
                })(
                  <Select
                    mode="single"
                    style={{ width: 240 }}
                    className="radio-button-create-notification-form radio-group-notification-type">
                    <Option key="notification-option-type-1" value="1">Direct</Option>
                    <Option key="notification-option-type-2" value="2">Contact</Option>
                    {transport === constant.EMAIL &&
                      <Option key="notification-option-type-3" value="4">Contact emergency</Option>
                    }
                    {transport === constant.EMAIL &&
                      <Option key="notification-option-type-4" value="5">Contact Landlord</Option>
                    }
                    {transport === constant.EMAIL &&
                      <Option key="notification-option-type-5" value="6">Contact Techincal</Option>
                    }
                    <Option key="notification-option-type-6" value="3">Broadcast</Option>
                  </Select>
                )}
              </FormItem>

              <FormItem label={<span>Send Date</span>} className="form-display-inline">
                {getFieldDecorator("notification_send_date", {
                  initialValue: sendDate
                })(<DatePicker showTime onChange={selectSendDate} onOk={onOk} />
                )}
              </FormItem>

              {transport !== constant.TOAST ?
                <FormItem label={<span>Escalation time</span>} className="form-display-inline">
                  {getFieldDecorator("notification_escalation_time", {
                    initialValue: escalationTime
                  })(<InputNumber />)}
                </FormItem>
                : <></>}

              {transport === constant.TOAST ?
                <FormItem label={<span>Requires Confirmation</span>} className="form-display-inline">
                  {getFieldDecorator("notification_requires_confirmation", {
                    initialValue: false
                  })(<Switch />)}
                </FormItem>
                : <></>}




              <FormItem>
                <Button
                  type="primary"
                  htmlType="button"
                  style={{ width: "40%" }}
                  disabled={saveDisabled}
                  loading={loading}
                  onClick={handleSubmit}
                  className="form-float-right"
                >
                  {!loading && (<Icon type="save" />)} Save
                        </Button>
              </FormItem>
            </Form >
          </Card>
        </div>
      </>
    ));
};

const NotificationCreateForm = Form.create()(_NotificationCreate);

export default withRouter(NotificationCreateForm);