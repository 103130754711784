import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'

import { successFetchUpdateToastNotifications, errorFetchUpdateToastNotifications, 
    successFetchUpdateUserNotifications, errorFetchUpdateUserNotifications, 
    successFetchUpdateUserSendNotifications, errorFetchUpdateUserSendNotifications,
    successFetchUpdateElementsNotifications, errorFetchUpdateElementsNotifications,
    successFetchAddNotifications, successFetchUpdateNotificationReceipts,
    setNotificationDetail, setNotificationUserDetail } from '../actions/notificationActions'

import { fetchUserNotificationApi, fetchToastNotificationLastMinutesApi, fetchElementsNotificationApi, fetchToastNotificationApi, fetchAllNotificationLastMinutesApi } from '../api/notificationApi'
import { toogleUserNotificationLoader, toogleUserSendNotificationLoader, toogleElementsNotificationLoader } from '../actions/loaderActions'
import { handleTreeData } from '../actions/companyManagmentActions'



export function* updateElementsNotificationValues(action) {
    try {
        yield put(toogleElementsNotificationLoader(true))
        const data = yield call(fetchElementsNotificationApi,
            action.payload.tenant_uuid,
            action.payload.element_type
        )

        yield put(successFetchUpdateElementsNotifications(data))
        yield put(toogleElementsNotificationLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleElementsNotificationLoader(false))
        yield put(errorFetchUpdateElementsNotifications(error))
    }
}


export function* getUserNotificationValues(action) {
    try {
        yield put(toogleUserNotificationLoader(true))

        const data = yield call(fetchUserNotificationApi,
            action.payload.tenant_uuid,
            "receive",
            action.payload.transport
        )

        data.update = false

        yield put(successFetchUpdateUserNotifications({"transport":action.payload.transport, "notification":data}))
        yield put(toogleUserNotificationLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleUserNotificationLoader(false))
        yield put(errorFetchUpdateUserNotifications(error))
    }
}

export function* getUserSendNotificationValues(action) {
    try {
        yield put(toogleUserSendNotificationLoader(true))

        const data = yield call(fetchUserNotificationApi,
            action.payload.tenant_uuid,
            "send",
            action.payload.transport
        )

        data.update = false

        yield put(successFetchUpdateUserSendNotifications({"transport":action.payload.transport, "notification":data}))
        yield put(toogleUserSendNotificationLoader(false))
        

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleUserSendNotificationLoader(false))
        yield put(errorFetchUpdateUserSendNotifications(error))
    }
}



export function* getNewNotificationValues(action) {
    try {
        const data = yield call(fetchAllNotificationLastMinutesApi,
            action.payload.tenant_uuid,
            3
        )

        //data.update = false
        yield put(successFetchUpdateToastNotifications(data))
        yield put(successFetchAddNotifications(data))
        
    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(errorFetchUpdateToastNotifications(error))
    }
}


export function* updateToastNotificationValues(action) {
    try {
        const data = yield call(fetchToastNotificationApi,
            action.payload.tenant_uuid,
            action.payload.notification_uuid,
            action.payload.form
        )

        yield put(successFetchUpdateNotificationReceipts({"notification_uuid": action.payload.notification_uuid, "notification_receipt": data}));
        if (action.payload.update_detail && action.payload.update_detail === true ){
            if (action.payload.call === "user" ){
                yield put(setNotificationUserDetail(true));
            }else{
                yield put(setNotificationDetail(true));
            }
        }

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(errorFetchUpdateToastNotifications(error))
    }
}



export function* fetchElementNotificationsWatcher() {
    yield takeLatest(actions.FETCH_ELEMENTS_NOTIFICATION, updateElementsNotificationValues)
}

export function* fetchUserNotificationsWatcher() {
    yield takeLatest(actions.FETCH_USER_NOTIFICATION, getUserNotificationValues)
}

export function* fetchUserSendNotificationsWatcher() {
    yield takeLatest(actions.FETCH_USER_SEND_NOTIFICATION, getUserSendNotificationValues)
}

export function* fetchNewNotificationsWatcher() {
    yield takeLatest(actions.FETCH_NEW_NOTIFICATION, getNewNotificationValues)
}

export function* updateToastNotificationsWatcher() {
    yield takeLatest(actions.UPDATE_TOAST_NOTIFICATION, updateToastNotificationValues)
}
