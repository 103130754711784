import { call, put, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successLogIn, errorLogIn, success2faFirstStep } from '../actions/authActions'
import { login2faApi, secondStep2faApi } from '../api/authApi'
import { toogleLoginLoader } from '../actions/loaderActions'

export function* login(action) {
    try {
        yield put(toogleLoginLoader(true))

        const data = yield call(login2faApi,
            action.payload.username,
            action.payload.password,
        )
        
        if (!data.user.active_2fa) {
            yield put(successLogIn(data.access_token, data.refresh_token))
        } else {
            yield put(success2faFirstStep(data))
        }
        
        yield put(toogleLoginLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "Wrong credentials", "error_code": error_codes.ERR_INVALID_TOKEN }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        } else {
            error = { "message": err.response.data.message, "error_code": error_codes.ERR_INVALID_TOKEN }
        }

        yield put(toogleLoginLoader(false))
        yield put(errorLogIn(error))
    }
}

export function* secondStep2fa(action) {
    try {
        yield put(toogleLoginLoader(true))

        const data = yield call(secondStep2faApi,
            action.payload.username,
            action.payload.verification_code,
        )
        
        
        yield put(successLogIn(data.access_token, data.refresh_token))
        yield put(toogleLoginLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "Wrong credentials", "error_code": error_codes.ERR_INVALID_TOKEN }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        } else {
            error = { "message": err.response.data.message, "error_code": error_codes.ERR_INVALID_TOKEN }
        }

        yield put(toogleLoginLoader(false))
        yield put(errorLogIn(error))
    }
}

export function* loginWatcher() {
    yield takeLeading(actions.LOG_IN, login)
}

export function* login2faSecondStepWatcher() {
    yield takeLeading(actions.SECOND_STEP_2FA, secondStep2fa)
}