import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Card, Empty, Button, Tooltip, Modal } from "antd";
import "./style.css";
import Notification from "../Notification";
import { getGroupLabel, enableDeleteCompanyElementButton } from "../../utils/helpers"
import * as actions from '../../constants'

const ModuleNotification = ({
  history,
  putToLoad,
  parentTab,
  modalSetters: { setIsModalVisible, setActionType }
}) => {

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const [isLoading, setIsLoading] = useState(false)
  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)
  const managerGroup = useSelector((state) => state.rootReducer.auth.group)

  const dispatch = useDispatch()

  const getElementType = group_type => {
    let elementType = "";
    switch (group_type) {
      case "Logical":
        elementType = "Logical Group";
        break;
      case "Control":
        elementType = "Control Group";
        break;
      case "Mix":
        elementType = "Mix Group";
        break;
      default:
        elementType = group_type;
        break;
    }
    return elementType;
  }


  const handleClickEdit = () => {
    setActionType("edit")
    dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: element.group_type } })
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (putToLoad) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  },
    [putToLoad]
  )


  const handleClickRemove = () => {
    const group_label = getGroupLabel(element.group_type)
    Modal.confirm({
      content: `You are about to delete ${element.group_type} ${group_label}${element.name}. Are you sure?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch({ type: actions.DELETE_ELEMENT, payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, group_type: element.group_type } })
      }
    });
  };

  const handleClickSettings = () => {
    //setActionType("settings");
    //dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: "PeopleAnalytics" } })
    //setIsModalVisible(true);
  };


  return (
    <Card
      title={
        isLoading
          ? "Loading details..."
          : !element || !element.name
            ? <small>Select an element to see its details</small>
            : <span>{getElementType(element.group_type)} <b>{element.name}</b> Details:</span>
      }
      actions={<></>}

      extra={isLoading
        ? []
        : [
          <Tooltip title="Edit element" key="tolltip1">
            <Button
              type="link"
              onClick={handleClickEdit}
              icon="edit"
              disabled={!element || element.name === undefined ? true : false}
            >
            </Button>
          </Tooltip>
          ,
          <Tooltip title="Settings" key="tolltip2">
            <Button
              type="link"
              onClick={handleClickSettings}
              icon="control"
              disabled={true}
            >
            </Button>
          </Tooltip>
          ,
          <Tooltip title="Delete element" key="tolltip3">
            <Button type="link" icon="delete" onClick={handleClickRemove} disabled={!element || element.name === undefined || !enableDeleteCompanyElementButton(domainClaims, element, managerGroup) ? true : false}>
            </Button>
          </Tooltip>
        ]}

    >
      {element && element.name !== undefined
        ? (
          <Notification
            putToLoad={putToLoad}
          />
        )
        : <Empty />
      }
    </Card >
  );
};

export default withRouter(ModuleNotification);
