import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  message,
  Button,
  Modal,
  Table,
  Tooltip,
  Popconfirm,
  Icon,
  Input,
  Divider,
  Row,
  Col,
  Notification
} from "antd";
import { withRouter } from "react-router-dom";
import "./style.css";

import colors from "../../styles";
import StyledIcon from "../../components/StyledIcon";
import AreaTypeManagmentAreaTypeForm from "../../components/AreaTypeManagmentAreaTypeForm"
import AreaTypeManagmentParametersList from "../../components/AreaTypeManagmentParametersList"
import ModuleLayout from "../../components/ModuleLayout"
import Loader from "../../components/Loader"
import * as error_codes from '../../constants/error_codes'
import * as actions from '../../constants'

const { danger, success } = colors;
const Search = Input.Search;

const AreaTypeParametersManagement = ({ history }) => {
  const [searchValue, setSearchValue] = useState("")
  const [isAreaModalVisible, setIsAreaModalVisible] = useState(false)
  // const [isLoading, setIsLoading] = useState(false);
  const [areaFormType, setAreaFormType] = useState("create")
  const [areaTypeName, setAreaTypeName] = useState("")

  const areaTypesList = useSelector((state) => state.rootReducer.areaTypeParameters.areaTypes)
  const areaTypesFilteredList = useSelector((state) => state.rootReducer.areaTypeParameters.areaTypesFilteredList)
  const managerRole = useSelector((state) => state.rootReducer.auth.rolename)
  const loading = useSelector(state => state.rootReducer.loaders.loadingAreaTypes)
  const deletedAreaType = useSelector(state => state.rootReducer.areaTypeParameters.deletedAreaType)
  const error = useSelector((state) => state.rootReducer.areaTypeParameters.error)

  //const { userData: { tenant_uuid, claims } } = useContext(UserContext);

  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)

  const dispatch = useDispatch()

  useEffect(
    () => {
      (async () => {
        dispatch(({ type: actions.FETCH_AREA_TYPES_LIST }))
      })()
      return () => {
        dispatch({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_MANAGMENT_STATE })
      }
    },
    []
  )

  const handleDelete = async area_type_name => {
    dispatch(({ type: actions.REMOVE_AREA_TYPE, payload: { area_type_name } }))
  }

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({ type: actions.FETCH_AREA_TYPES_LIST }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (deletedAreaType) {
        message.success("Area type successfully deleted")
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
      }
    },
    [deletedAreaType]
  )

  const userCanDoActions =
    domainClaims.CanRemoveAreaType || domainClaims.CanUpdateAreaType;

  const columns = userCanDoActions
    ? [
      {
        title: "Area type name",
        dataIndex: "label",
        key: "name",
        width: "80%"
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <>
            {domainClaims.CanUpdateAreaType ? (
              <>
                <Tooltip placement="topRight" title="Edit">
                  <span>
                    <StyledIcon
                      handleClick={() => {
                        setAreaTypeName(record.name)
                        setAreaFormType("update")
                        setIsAreaModalVisible(true)
                      }}
                      type="edit"
                      scope="action"
                      color={success}
                    />
                  </span>
                </Tooltip>
                <Divider type="vertical" style={{ margin: "0 1em" }} />
              </>
            ) : null}
            {domainClaims.CanRemoveAreaType ? (
              <Popconfirm
                title={`You are about to delete area type ${record.label}. Are you sure?`}
                onConfirm={() => handleDelete(record.name)}
                okText="Yes"
                cancelText="No"
                placement="bottomRight"
              >
                <Tooltip placement="topRight" title="Delete">
                  <span>
                    <StyledIcon type="delete" scope="action" color={danger} />
                  </span>
                </Tooltip>
              </Popconfirm>
            ) : null}
          </>
        ),
        width: "20%",
        align: "center"
      }
    ]
    : [
      {
        title: "Area Type Name",
        dataIndex: "label",
        key: "name",
        width: "100%"
      }
    ];

  return loading ? (
    <Loader />
  ) : (
      <ModuleLayout
        navbarComponent={
          <>
            <Icon type="appstore" /> <span>Area Type Parameters Management</span>
          </>
        }
        contentComponent={
          <>
            <Row
              gutter={[8]}
              type="flex"
              justify="start"
              style={{
                width: "100%",
                marginBottom: "1.5em",
                marginLeft: "0",
                marginRight: "0"
              }}
            >
              <Col>
                <Button
                  style={{ float: "left" }}
                  type="primary"
                  size="large"
                  icon="plus"
                  disabled={!domainClaims.CanCreateAreaType}
                  onClick={() => {
                    setIsAreaModalVisible(true)
                    setAreaFormType("create")
                  }}
                >
                  Add Area Type
              </Button>
              </Col>
              <Col>
                <Button
                  style={{ float: "left" }}
                  type="primary"
                  size="large"
                  icon="tags"
                  disabled={!domainClaims.CanCreateAreaTypeParameter}
                  onClick={() => {
                    setIsAreaModalVisible(true)
                    setAreaFormType("parameters")
                  }}
                >
                  Manage Parameters
              </Button>
              </Col>
            </Row>
            <Table
              bordered
              style={{ width: "100%" }}
              size="large"
              columns={columns}
              dataSource={areaTypesList.map(area => {
                area.key = area.name;
                return area;
              })}
            />
            <Modal
              title={
                <span>
                  {areaFormType === "create" && (
                    <div>
                      <Icon type="appstore" />
                     Add Area Type
                    </div>
                  )}
                  {areaFormType === "update" && (
                    <div>
                      <Icon type="appstore" />
                     Update Area Type
                    </div>
                  )}
                  {areaFormType === "parameters" && (
                    <div>
                      <Icon type="tags" />
                     Parameters managment
                    </div>
                  )}
                </span>
              }
              width="60vw"
              destroyOnClose={true}
              maskClosable={true}
              style={{ top: "2em" }}
              visible={isAreaModalVisible}
              onCancel={() => {
                setIsAreaModalVisible(false)
                dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
              }}
              footer={null}
            >
            {(areaFormType === "create" || areaFormType === "update") && (
              <AreaTypeManagmentAreaTypeForm
                area_type_name={areaTypeName}
                type={areaFormType}
                setIsModalVisible={setIsAreaModalVisible}
                isModalVisible={isAreaModalVisible} 
              />
            )}
            {areaFormType === "parameters" && (
                <AreaTypeManagmentParametersList/>
            )}
            </Modal>
          </>
        }
      />
    );
};

export default withRouter(AreaTypeParametersManagement)
