import React, { useEffect } from "react"
import { Modal, Button } from "antd"
import packageJson from '../../../package.json'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../constants'

global.appVersion = packageJson.version


const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g)

    const versionsB = versionB.split(/\./g)
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())

        const b = Number(versionsB.shift())
        // eslint-disable-next-line no-continue
        if (a === b) continue
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b)
    }
    return false
}

const CacheBuster = () => {
    const loading = useSelector((state) => state.rootReducer.cacheBusting.loading)
    const version = useSelector((state) => state.rootReducer.cacheBusting.version)
    const isLatestVersion = useSelector((state) => state.rootReducer.cacheBusting.isLatestVersion)

    const dispatch = useDispatch()

    useEffect(
        () => {
            fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.version
                    const currentVersion = global.appVersion
                    console.log("Bundle cached version " + currentVersion)

                    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
                    if (shouldForceRefresh) {
                        console.log(`We have a new version - ${latestVersion}. Should force refresh`)
                        dispatch(({ type: actions.CACHE_CLEANUP_NEEDED, payload: { version: latestVersion } }))
                    } else {
                        console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`)
                        dispatch(({ type: actions.CACHE_CLEANUP_NOT_NEEDED }))
                    }
                })
        },
        []
    )

    return !loading && (
        <Modal
            title="New version detected"
            width="60vw"
            destroyOnClose={true}
            visible={!isLatestVersion}
            footer={null}
        >
            <p>A newer version <b>({version})</b> was detected on server, versus current cached on your browser <b>({global.appVersion})</b>. You need to clean cache and
            reload the application.</p>
            <Button
                type="primary"
                htmlType="button"
                onClick={() => { dispatch(({ type: actions.REFRESH_CACHE_AND_RELOAD })) }}
                icon={"delete"}
                style={{ width: "50%", marginLeft: "1em" }}
                size="large"
            >
                Clean cache an reload app
            </Button>
        </Modal>
    )

}

export default CacheBuster