import {
    loginService,
    login2faService,
    login2faSecondStepService
} from "../services";

export const loginApi = async (username, password) => {

    const response = await loginService({username, password})
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const login2faApi = async (username, password) => {

    const response = await login2faService({username, password})
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const secondStep2faApi = async (username, verification_code) => {

    const response = await login2faSecondStepService({username, verification_code})
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}