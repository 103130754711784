import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  List,
  Row,
  Col,
  Switch,
  Modal,
  Icon,
  Tooltip,
  message,

} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../Loader";
import FrecuencyMap from "../FrecuencyMap/indexfiredetection";
import DevicesForm from "../DevicesForm/indexexits";
import Map from "../HeatMap";
import { UserContext } from "../../context";
import { getDataDay, getDataHour, putGroupMetadataDevice, getLeyend, putGroupMetadataExit } from "./heatmapfunction";
import { symbol, getTitle } from '../../utils/helpers';
import "./style.css";

const { HEATMAP_RADIUS, HEATMAP_RANGE, HEATMAP_COLOR } = window.Configs;

const DevicesHeatMap = ({
  history,
  index,
  editabled,
  parentdata,
  parentconfig,
  updateParentConfig,
  updateParentData,
  changeLoading
  //tabKey
}) => {
  const [thedata, setThedata] = useState(
    parentdata
    /*
    { image: "", imagedisplay: "horizontal", devices: [], data: [], chartData: [] }
    */
  );
  const [theconfig, setTheconfig] = useState(
    parentconfig
    /*
    { element_uuid: parentconfig.element_uuid, shouldRenderFrecuencyMap: false, sensorName: 'temperature', day: moment().format("YYYY-MM-DD"), hour: Number(moment().format("HH")), }
    */
  );

  const [bubble, setBubble] = useState(HEATMAP_RADIUS)

  const [dataheatmap, setDataheatmap] = useState(thedata.data);
  const [dataLoading, setDataLoading] = useState(false);
  const { userData: { tenant_uuid } } = useContext(UserContext);

  //Edit Form
  const [currentdevices, setCurrentDevices] = useState([]);
  const [currentexits, setCurrentExits] = useState([]);
  const [isModalVisible, setIsModalVisibility] = useState(false);
  const [loadingModalData, setLoadingModalData] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    //(async () => {
    if (thedata.update === true) {
      //setUpdatedata(false)
      //await new Promise(r => setTimeout(r, 200))

      setDataheatmap([])
    }
    //})()
  },
    [thedata.update])

  useEffect(() => {
    if (thedata.update === true) {
      //setUpdatedata(true)
      setDataheatmap(thedata.data)
      if (dataheatmap.length > 0) {
        setThedata({
          ...thedata,
          update: false,
        })
      }
    }
  },
    [dataheatmap])

  useEffect(() => {
    //console.log(thedata.image)
  },
    [thedata.image])

  const updateImageDisplay = (e) => {
    if (thedata.image !== "") {
      //const imageshow = document.getElementById('image-map-show')
      //if (imageshow) {
      if (Number(e.target.width) >= Number(e.target.height)) {
        setThedata({ ...thedata, imageDisplay: "horizontal" });
      } else {
        setThedata({ ...thedata, imageDisplay: "vertical" });
      }
      changeLoading(false);
      //}
    }
  }

  const onChange = checked => {
    updateParentConfig(index, { ...theconfig, shouldRenderFrecuencyMap: checked })
    setTheconfig({
      ...theconfig,
      shouldRenderFrecuencyMap: checked
    })
    //setUpdate(true)
  };

  const putEmptydata = (devices) => {
    let newchartdata = [];
    let emptydevices = [];

    //for all devices put x, y, 1
    for (let index = 0; index < devices.length; index++) {
      emptydevices.push([devices[index].x, devices[index].y, "1"])
    }

    //for all hour put empty device
    for (let index = 0; index < 24; index++) {
      newchartdata.push({ devices: emptydevices })
    }
    return newchartdata;
  }

  const isEmptyHour = (hour) => {
    let returnvalue = false;
    (thedata.chartData[hour].devices).forEach(element => {
      if (element[2] === "1" || element[2] === 1) {
        returnvalue = true;
      }
    });
    return returnvalue;
  }

  const selectSensor = async (new_sensor) => {
    if (thedata.chartData.length > 0) {
      setDataLoading(true)
      const datahour = await getDataHour(history, dispatch, tenant_uuid, { ...theconfig, sensorName: new_sensor }, thedata.devices)

      let newchartdata = putEmptydata(thedata.devices);
      newchartdata[theconfig.hour] = datahour[0];

      updateParentConfig(index, { ...theconfig, sensorName: new_sensor })
      updateParentData(index, { ...thedata, chartData: newchartdata, data: newchartdata[theconfig.hour].devices })

      setDataLoading(false)

      await setThedata({
        ...thedata,
        chartData: newchartdata,
        data: datahour[0].devices,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        sensorName: new_sensor
      })
    }

  }


  const selectDay = async (date) => {
    if (thedata.chartData.length > 0) {
      setDataLoading(true)
      const datahour = await getDataHour(history, dispatch, tenant_uuid, { ...theconfig, day: date }, thedata.devices)

      let newchartdata = putEmptydata(thedata.devices);;
      newchartdata[theconfig.hour] = datahour[0];

      updateParentConfig(index, { ...theconfig, day: date })
      updateParentData(index, { ...thedata, chartData: newchartdata, data: newchartdata[theconfig.hour].devices })

      setDataLoading(false)

      await setThedata({
        ...thedata,
        chartData: newchartdata,
        data: datahour[0].devices,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        day: date
      })
    }
    //setUpdateDataDay(true)
  }


  const changeHour = async (new_hour) => {
    if (isEmptyHour(new_hour)) {
      setDataLoading(true)

      const newconfig = {
        ...theconfig,
        hour: new_hour
      };
      const datahour = await getDataHour(history, dispatch, tenant_uuid, newconfig, thedata.devices);
      setDataLoading(false)

      let newchartdata = thedata.chartData;
      newchartdata[new_hour] = datahour[0];

      setDataLoading(false)

      await setThedata({
        ...thedata,
        chartData: newchartdata,
        data: datahour[0].devices,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        hour: new_hour
      })

    } else {
      await setThedata({
        ...thedata,
        data: thedata.chartData[new_hour].devices,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        hour: new_hour
      })

    }

    //setUpdateDataHour(true)
  }



  //EDIT FORM
  const setParentExit = (newexits) => {
    setCurrentExits(newexits);
  }

  //method call since device form, change imagen atribute
  const updateImageMap = (newimage) => {
    setThedata({ ...thedata, image: newimage });
    updateParentData(index, { ...thedata, image: newimage })
  };

  const setModalVisibility = () => {
    setIsModalVisibility(false);
  };

  //when save devices form, update devices atribute with device location of form device
  const handleSubmit = async () => {
    setLoadingModalData(true)
    const newdattadevice = await putGroupMetadataExit(history, dispatch, tenant_uuid, theconfig.element_uuid, currentexits);
    //const newchartdata = await updateLocationDataDay()
    setLoadingModalData(false)

    //setThedata({ ...thedata, update: true, devices: currentdevices, chartData: newchartdata, data: newchartdata[theconfig.hour].devices })
    setThedata({ ...thedata, update: true, exits: currentexits })
    //if (currentdevices.length === 0) {
    setIsModalVisibility(false);
    //}
  };

  //method that only update location device
  const updateLocationDataDay = async () => {
    let tmpData = [];
    const dataLength = thedata.chartData[0].devices.length
    let i = 0;
    while (i < thedata.chartData.length) {
      let tmpDataDevice = []
      for (let index = 0; index < dataLength; index++) {
        // # This is for every device in response data
        const xvalue = currentdevices[index].x;
        const yvalue = currentdevices[index].y;
        tmpDataDevice.push([
          xvalue,
          yvalue,
          thedata.chartData[i].devices[index][2],
          thedata.chartData[i].devices[index][3],
        ])
      }
      tmpData.push({
        time: thedata.chartData[i].time,
        devices: tmpDataDevice,
      })
      i++
    }

    return tmpData;
  }

  const changeImageDisplay = (orientation) => {
    setThedata({ ...thedata, imageDisplay: orientation });
  }

  const handleCancelEdit = () => {
    setIsModalVisibility(false);
  }
  const handleEditMap = () => {
    setCurrentExits(Object.assign([], thedata.exits));
    setIsModalVisibility(true);
  }

  const updateFormData = (value) => {
    setLoadingModalData(value)
  }

  const onRadiusChange = param => {
    if (typeof param === "number") {
      setBubble(param);
    }
  };

  const render = () => {
    let imageMap = true;
    if (thedata.image === "") {
      imageMap = false;
    }

    const footer = [
      <Button
        type="primary"
        onClick={handleEditMap}
        icon="edit"
        loading={dataLoading}
      >
        Edit
      </Button>,
    ];

    //lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 20 }} 
    const content = (
      <>
        <Row>
          <Col span={19} className="img-container" style={{ width: (editabled ? "" : "100%") }}>
            {imageMap && (
              <>
                <Row className={"contenedor"}>
                  {thedata.devices && thedata.devices.map((device, indexd) => (
                    <Tooltip key={`device-tooltip-${device.id}`} title={
                      <div style={{ display: "inline-grid", textAlign: "center" }}>
                        <span>{device.aws_id}</span>
                        <span>{getTitle(theconfig.sensorName)} = {(dataheatmap && dataheatmap[indexd] && dataheatmap[indexd][3] && (dataheatmap[indexd][3] !== "0" && dataheatmap[indexd][3] !== 0) ? (parseFloat(dataheatmap[indexd][3])).toFixed(2) + "" : "0.00")} {symbol(theconfig.sensorName)}</span>
                      </div>
                    }>

                      <div
                        className={`device device-${device.id} ${(dataheatmap && dataheatmap[indexd] && dataheatmap[indexd][2] && (dataheatmap[indexd][2] === "-1" || dataheatmap[indexd][2] === -1) ? "inactive" : "")}`}
                        style={{
                          position: "absolute",
                          zIndex: device.x > 0 ? 1 : 0,
                          //opacity: device.x > 0 ? 1 : 0,
                          left: device.x,
                          top: device.y,
                        }}
                      >
                        <Icon type="bulb" />
                        <div className="device-name" style={{ display: "inline-grid" }}>
                          <span>{device.aws_id}</span>
                        </div>
                      </div>
                    </Tooltip>
                  ))}
                  <img id="image-map-show"
                    className={thedata.imageDisplay}
                    //src={image}
                    src={`data:image/jpeg;base64,${thedata.image}`}
                    max-width="500px"
                    max-height="500px"
                    onLoad={updateImageDisplay}
                    alt="" />
                  {(thedata.data && thedata.data.length > 0 && !thedata.update) && (<Map datadevice={dataheatmap} radius={bubble} heatmap_color={HEATMAP_COLOR} renderMap={theconfig.shouldRenderFrecuencyMap} />)}
                  {thedata.exits && thedata.exits.length> 0 && thedata.exits.map((exit, index) => (
                    <Tooltip key={`exit-tooltip-${index}`} title={
                      <div style={{ display: "inline-grid", textAlign: "center" }}>
                        <span>{exit.exit_type === "Normal" ? "normal exit" : "fire exit"}
                        </span>
                      </div>
                    }>
                      <div
                        key={`item-exit-${index}`}
                        className={`exit device item-exit-${index}`}
                        style={{
                          position: "absolute",
                          zIndex: exit.x > 0 ? 1 : 0,
                          //opacity: exit.x > 0 ? 1 : 0,
                          left: exit.x,
                          top: exit.y,
                        }}
                      >
                        {exit.exit_type === "Normal" ? <span className="image-name text-green">NX</span> : <span className="text-red image-name">FX</span>}
                        <div className="device-name exit-name">EXIT</div>
                      </div>
                    </Tooltip>

                  ))}
                </Row>

                <Row>
                  <div style={{ margin: "10px 0px 20px 0px", textAlign: "center" }}>

                    <div style={{ width: "500px", maxWidth: "500px", margin: "auto" }}>
                      {getLeyend(theconfig.sensorName, HEATMAP_RANGE, HEATMAP_COLOR, [], true, true)}
                    </div>

                  </div>
                  <div style={{ margin: "10px 0px 20px 0px" }}>
                    Show fire map <Switch onChange={onChange} checked={theconfig.shouldRenderFrecuencyMap} loading={dataLoading} />
                  </div>
                  {(theconfig.shouldRenderFrecuencyMap) && <FrecuencyMap config={theconfig} thedata={thedata} selectDay={selectDay} changeHour={changeHour} selectSensor={selectSensor} dataLoading={dataLoading} parentBubble={bubble} onRadiusChange={onRadiusChange} />}
                </Row>
              </>
            )}
            {!imageMap && (
              <Row>
                <Col>
                  No devices map available, please, edit and upload one.
              </Col>
              </Row>
            )}
          </Col>
          {(editabled ?
            <Col span={5} className="device-container">

              {false && <List
                header={<div>Exits</div>}
                bordered
                dataSource={[{ id: "n", name: "Normal" }, { id: "f", name: "Fire" }]}
                style={{ maxHeight: "400px", overflow: "scroll", marginBottom: "10px" }}
                renderItem={(item, index) => (
                  <List.Item>
                    {imageMap && (
                      <div>{item.name} ({item.name === "Normal" ? <span className="image-name text-green">NX</span> : <span className="text-red image-name">FX</span>})</div>
                    )}
                    {!imageMap && item.exit_type}
                  </List.Item>
                )}
              />}

              <List
                header={<div>Devices</div>}
                bordered
                dataSource={thedata.devices}
                style={{ maxHeight: "400px", overflow: "scroll" }}
                renderItem={(item, indexd) => <List.Item style={{ display: "block" }}><div>{item.aws_id}</div><div style={{ fontSize: "11px" }}>device {indexd + 1}</div></List.Item>}
              />

            </Col>
            :
            <></>
          )}
        </Row>
      </>
    );

    const card = (
      editabled //isLoading
        ? <Card bordered={false} actions={footer}>{content}</Card>
        :
        <>{content}</>
    )

    return (
      false //isLoading
        ? <Loader />
        :
        <>
          {card}
          <Modal
            title="Edit devices"
            width="60vw"
            destroyOnClose={true}
            maskClosable={true}
            style={{ top: "2em" }}
            visible={isModalVisible}
            onCancel={setModalVisibility}
            footer={[
              <Button
                key="save"
                type="primary"
                htmlType="button"
                style={{ width: "40%" }}
                size="large"
                loading={loadingModalData}
                onClick={handleSubmit}
              >
                <Icon type="save" style={{ marginLeft: "15px" }} /> Save
            </Button>,
              <Button
                key="cancel"
                type="default"
                htmlType="button"
                style={{ width: "40%", marginLeft: "10px" }}
                size="large"
                loading={loadingModalData}
                onClick={handleCancelEdit}
              >
                <Icon type="close" style={{ marginLeft: "15px" }} /> Cancel
            </Button>,
            ]}
          >
            <DevicesForm
              element_uuid={theconfig.element_uuid}
              dataexits={thedata.exits}
              image={thedata.image}
              imagedisplay={thedata.imageDisplay}
              imageMap={imageMap}
              setParentExit={setParentExit}
            />
          </Modal>
        </>
    );
  }

  return (
    render()
  )
}

export default DevicesHeatMap;
