import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchUVControl = (tenant_uuid, uuid ) => action(actions.FETCH_UV_CONTROL, {tenant_uuid, uuid})

export const updateUVControl = (tenant_uuid, uuid, form ) => action(actions.UPDATE_UV_CONTROL, {tenant_uuid, uuid, form})

export const successFetchUpdateUVControl = data => action(actions.SUCCESS_FETCH_UPDATE_UV_CONTROL, { data })

export const errorFetchUpdateUVControl = error => action(actions.ERROR_FETCH_UPDATE_UV_CONTROL, { error })

export const cleanUVControlError = () => action(actions.CLEAN_UV_CONTROL_ERROR)

export const cleanUVControlState = () => action(actions.CLEAN_UV_CONTROL_STATE)