export const sensorDataTypes = {
    TEMPERATURE: 'temperature',
    HUMIDITY: 'humidity',
    PRESSURE: 'pressure',
    IR: 'ir',
    LIGHT: 'lux',
    CO2: 'co2',
    VOC: 'voc',
}

export const allSensorTypes = {
    TEMPERATURE: 'temperature',
    HUMIDITY: 'humidity',
    PRESSURE: 'pressure',
    IR: 'ir',
    LIGHT: 'lux',
    MOTION: 'motion',
    POWER: 'power',
    CO2: 'co2',
    VOC: 'voc',
    ENH_MOTION: 'enh_motion',
    UV_LIGHTCYCLE: 'uv_lightcycle',
}