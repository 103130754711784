import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  Form,
  Col,
  Row,
  List,
  Icon,
  Modal,
  Upload,
  message,
  Button,
  Notification,
} from "antd";
import { withRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useDrop, useDrag, DragPreviewImage } from "react-dnd";
import { putGroupImageMap } from "../../services";
import { UserContext } from "../../context";
import { dragdevice } from './image.js';
import * as actions from '../../constants';
import "./style.css";

let inside = false;

const DevicesForm = ({
  history,
  element_uuid,
  dataexits,
  image,
  imagedisplay,
  setParentExit,
}) => {

  const dispatch = useDispatch()

  const [imageFile, setImageFile] = useState(image);
  const [imageMap, setImageMap] = useState(true);
  const [exits, setExits] = useState(
    Object.assign([], dataexits)
  );//dataexits

  const [loading, setLoading] = useState(false)

  const { userData: { tenant_uuid } } = useContext(UserContext);

  useEffect(() => {
    setImageFile(image);
  },
    [image])


  useEffect(() => {
    //console.log(exits)
  },
    [exits])

  const render = () => {

    //component to recive drop
    const Board = ({ children }) => {
      const [{ isOver, canDrop }, drop] = useDrop({
        accept: "exit",
        canDrop: () => true,
        collect: monitor => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
        drop({ index, id }, monitor) {
          //console.log(index + "-" + id)
          const { x: mapX, y: mapY } = document
            .getElementsByClassName("image-map")[0]
            .getBoundingClientRect();
          const { x: exitX, y: exitY } = monitor.getClientOffset();
          /*updateDevicePosition({ index, id, exitX, exitY, mapX, mapY, });*/
          const oldExits = exits;
          
          let x = exitX - mapX;
          let y = exitY - mapY;

          if(id=="n"){
            oldExits.push({id:oldExits.length+"", exit_name:oldExits.length+"",x:x,y:y,exit_type:"Normal"})
          }else if(id=="f"){
            oldExits.push({id:oldExits.length+"", exit_name:oldExits.length+"",x:x,y:y,exit_type:"Fire"})
          }else{
            const exit = oldExits[index];
            oldExits[index] = { ...exit, x, y };
          }
          //setExits(oldExits);
          setExits(Object.assign([], oldExits));

          const exitIcon = document.getElementsByClassName(`item-exit-${oldExits.length}`);
          for (var i = 0; i < exitIcon.length; i++) {
            exitIcon[i].style.left = `${x}px`;
            exitIcon[i].style.top = `${y}px`;
          }

          setParentExit(oldExits);

          //exitIcon.style.left = `${x}px`;
          //exitIcon.style.top = `${y}px`;
          return undefined;
        },
      });

      const isActive = canDrop && isOver
      //let backgroundColor = '#222'
      if (isActive) {
        //console.log("DENTRO")
        if (inside === false) {
          inside = (true);
        }
        //backgroundColor = 'darkgreen'
      } else if (canDrop) {
        //backgroundColor = 'darkkhaki'
        //console.log("FUERA")
        if (inside === true) {
          inside = (false);
        }
      }

      return (
        <div
          ref={drop}
          className={"contenedor"}
          style={{
            position: "relative",
          }}
        >
          {children}
        </div>
      );
    };

    //component for make drag
    const Exit = ({ children, exit }) => {
      const [{ isDragging }, drag, preview] = useDrag({
        item: { ...exit, type: "exit" },
        collect: monitor => {
          return {
            isDragging: !!monitor.isDragging(),
          };
        },
        end: ({ index, id }, monitor) => {
          //const { id: droppedId, originalIndex } = monitor.getItem()
          const didDrop = monitor.didDrop()
          if (!didDrop) {
            //moveCard(droppedId, originalIndex)
            //console.log("SOLTADO" + id + " " + index)
            if (inside === false) {
              const x = -100;
              const y = -100;

              const oldExits = exits;
              //oldExits[index] = { ...exit, x, y };
              let deleted_array = oldExits.filter( (element) => {
                return element.id+"" !== id+""
              })
              index = 1
              deleted_array.forEach(element => {
                deleted_array[index-1] = { ...element, id: (index-1)+"", exit_name: (index-1)+"" }
                index = index + 1
              });

              setExits(Object.assign([], deleted_array));

              const exitIcon = document.getElementsByClassName(`item-exit-${id}`);
              for (var i = 0; i < exitIcon.length; i++) {
                exitIcon[i].style.left = `${x}px`;
                exitIcon[i].style.top = `${y}px`;
              }
              setParentExit(deleted_array);
            }
          }
        },
      });

      const exitimage = dragdevice;
      return (
        <>
          <div
            ref={drag}
            style={{
              cursor: "move",
            }}
          >
            {children}
          </div>
        </>
      );
    };

    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          <Row>
            <Col span={18}>
              {imageMap && (
                <Board thisRef={this}>
                  <img
                    id="image-map-edit"
                    className={`${imagedisplay} image-map`}
                    //src={imageFile}
                    src={`data:image/jpeg;base64,${imageFile}`}
                    max-width="500px"
                    max-height="500px"
                    alt=""
                    onMouseDown={() => { return false; }}
                    draggable="false"
                    onDragStart={() => { return false; }}
                  />

                  <Row style={{ top: "0px", position: "absolute" }}>
                    <Col>
                      {exits.map((exit, index) => (
                        <Exit
                          key={`exit-${exit.id}`}
                          exit={{ ...exit, index }}
                        >
                          {

                            <div
                              className={`exit device item-exit-${exit.id}`}
                              style={{
                                position: "absolute",
                                zIndex: exit.x > 0 ? 1 : 0,
                                //opacity: exit.x > 0 ? 1 : 0,
                                left: exit.x,
                                top: exit.y,
                              }}
                            >
                              { exit.exit_type === "Normal" ? <span className="image-name text-green">NX</span> : <span className="text-red image-name">FX</span>}
                              <div className="exit-name device-name">EXIT</div>
                            </div>
                          }
                        </Exit>
                      ))}
                    </Col>
                  </Row>
                </Board>
              )}
             
            </Col>
            <Col span={6}>
              <List
                header={<div>Exits</div>}
                bordered
                dataSource={[{id: "n", name: "Normal"}, {id: "f", name: "Fire"}]}
                style={{ maxHeight: "400px", overflow: "scroll" }}
                renderItem={(item, index) => (
                  <List.Item>
                    {imageMap && (
                      <Exit exit={{ ...item, index }}><div>{item.name} ({ item.name === "Normal" ? <span className="image-name text-green">NX</span> : <span className="text-red image-name">FX</span>})</div></Exit>
                    )}
                    {!imageMap && item.exit_type}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </div>
      </DndProvider>
    );
  }

  return (
    render()
  )
}

const DevicesFormComp = Form.create()(DevicesForm);

export default withRouter(DevicesFormComp);