import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { Card, Button, Modal, Icon, Row, Col, List, message } from "antd";
import { Rnd } from "react-rnd";
import { useDrop, useDrag } from "react-dnd";
import ScrollableFeed from 'react-scrollable-feed';
import "./style.css";

import ModalHeatmapConfig from "./ModalHeatmapConfig";
import { UserContext } from "../../context";
import DevicesHeatMapPanel from "../DevicesHeatMap/indexpanel";
import Demo from './demo';
import Loader from "../../components/Loader";
import moment from 'moment'
import { getDashboardPanelData, postDashboardPanelData, putDashboardPanelData, deleteDashboardPanelData, existGroup } from "./dashboardfunction";
import { getGroupMetadataImage, getGroupMetadataDevice, getGroupMetadataExits, getDataLastHour } from "../DevicesHeatMap/heatmapfunction";
import * as actions from '../../constants'

let deleteP = false;
const { HEATMAP_RADIUS } = window.Configs;

const Board = ({
  history,
  selectKey,
  dashboard_uuid
}) => {

  const { userData: { tenant_uuid } } = useContext(UserContext);
  //const [isLoading, setIsLoading] = useState(false)
  const [loadingPanel, setLoadingPanel] = useState(false)
  const [newpanel, setNewpanel] = useState({})
  const [boxs,] = useState(
    [
      {
        name: "Heatmap devices",
        text: "Show heatmap devices",
        type: "HEATMAP"
      }
      /*
      {
        name: "Other",
        text: "Show other",
        type: "OTHER"
      }
      */

    ]
  )
  const [panels, setPanels] = useState([
    /*
    {
      x: 400,
      y: 200,
      zindex: 0,
      width: "200px",
      height: "150px",
      title: "Component 3",
      fullscreen: false,
      content: () => <div>Data of component 3</div>,
    }
    */
  ])
  /*
  const [data, setData] = useState([
    {
    devices: [],
    image: "data",
    imagedisplay: "horizontal",
    data: "[]",
    chartData: "[device]",
    }
    ])
    const [config, setConfig] = useState([   
    {
    sensor: 'temperature',
    day: '2019-12-02',
    hour: 14,
    shouldRenderFrecuencyMap
    element_uuid: 
    }
  ])
  */
  const [sensor, setSensor] = useState("temperature");
  const [lasthour, setLastHour] = useState("0.5");
  const [selectGroup, setSelectGroup] = useState();

  const [refrnd, setRefrnd] = useState([]);
  /*
    useEffect(() => {
      refrnd.map((item,index)=>{
          item =  useRef(null);
      })
    },
      []
    )
    */
  const [isModalVisible, setIsModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    getDashboardPanels()
  },
    [dashboard_uuid]
  )
  useEffect(() => {
  },
    [panels]
  )

  const updateZIndex = (currentIndex) => {
    const newpanels = panels
    newpanels.map((panel, index) => {
      if (currentIndex === index) {
        newpanels[index].zindex = 1;
        document.getElementById(`panel-${index}`).style.zIndex = 1;
      } else {

        if (newpanels[index].zindex === 1) {
          newpanels[index].zindex = 0;

          if (panels[index].uuid) {
            const u2 = putDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panels[index].uuid, { panel_information: JSON.stringify({ ...newpanels[index], data: {} }) })
          }
        }
        document.getElementById(`panel-${index}`).style.zIndex = 0;
      }
    })
    return newpanels
  }

  const maximizar = async (e, index) => {
    const newpanels = updateZIndex(index)
    newpanels[index].fullscreen = !newpanels[index].fullscreen

    if (newpanels[index].fullscreen) {
      document.getElementById(`panel-${index}`).style.width = "100%"
      document.getElementById(`panel-${index}`).style.width = "100%"


      refrnd[index].updatePosition({ x: 0, y: 0 })
      refrnd[index].updateSize({ width: "100%", height: "100%" })
    } else {
      refrnd[index].updatePosition({ x: newpanels[index].x, y: newpanels[index].y })
      refrnd[index].updateSize({ width: newpanels[index].width, height: newpanels[index].height })
    }

    if (panels[index].uuid) {
      const datareturn = putDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panels[index].uuid, { panel_information: JSON.stringify({ ...newpanels[index], data: {} }) })
    }

    /*
    let newrefrnd = []
    newrefrnd = refrnd.map((ref, index) => {
      return (ref)
    })

    await setRefrnd(newrefrnd)
    */
    await setPanels(newpanels)
  }

  const close = async (e, currentIndex) => {
    if (loading) {
      return;
    }
    setLoading(true);
    document.getElementById(`panel-${currentIndex}`).style.visibility = "hidden";

    const newpanels = []
    await panels.map((panel, index) => {
      if (currentIndex === index) {

      } else {
        newpanels.push(panel)
      }
    })
    const newrefrnd = []
    const returndata = await deleteDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panels[currentIndex].uuid)

    await refrnd.map((ref, index) => {
      if (currentIndex === index) {

      } else {
        newrefrnd.push(ref)
      }
    })
    deleteP = false;
    setLoading(false)
    await setPanels(newpanels)
    await setRefrnd(newrefrnd)
  }

  //component for make drag
  const Box = ({ children, panel }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      item: { ...panel, type: "panel" },
      collect: monitor => {
        return {
          isDragging: !!monitor.isDragging(),
        };
      },
      end: async (item, monitor) => {
        //const { id: droppedId, originalIndex } = monitor.getItem()
        const didDrop = monitor.didDrop()
        if (!didDrop) {

        }
      },
    });
    return (
      <>
        <div
          ref={drag}
          style={{
            cursor: "move",
          }}
        >
          {children}
        </div>
      </>
    );
  };

  //component that recive drop
  const BoardContainer = ({ children }) => {
    //const [inside, setInside] = useState(false);

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: "panel",
      canDrop: () => true,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: async ({ index, id }, monitor) => {

        const { x: mapX, y: mapY } = document
          .getElementsByClassName("ant-card-body")[0]
          .getBoundingClientRect();
        const { x: panelX, y: panelY } = monitor.getClientOffset()

        setNewpanel(
          {
            x: panelX - mapX,
            y: panelY - mapY,
            zindex: 0,
            width: "300px",
            height: "200px",
            title: boxs[index].name,
            fullscreen: false,
            type: boxs[index].type,
            data: {
            },
            config: {
            }
          }
        )
        setIsModalVisibility(true)
        return undefined;
      },
    });

    return (
      <div
        ref={drop}
        className={"contenedor"}
        style={{
          position: "relative",
        }}
      >
        {children}
      </div>
    );
  };

  const setModalVisibility = (value) => {
    setIsModalVisibility(value);
  }

  const handleCancelEdit = () => {
    setIsModalVisibility(false);
  }


  const setSelectElement = async (select_element) => {
    setSelectGroup(select_element);
  }
  const setSelectSensor = async (select_sensor) => {
    setSensor(select_sensor);
  }

  const setSelectLastHour = async (select_lasthour) => {
    setLastHour(select_lasthour);
  }


  const handleSubmit = async () => {
    if (sensor) {
      switch (newpanel.type) {
        case 'HEATMAP':
          setLoading(true)
          if (selectGroup && selectGroup.uuid && selectGroup.uuid !== "") {
            const newconfigheatmap = {
              ...newpanel.config,
              //shouldRenderFrecuencyMap: false,
              sensorName: sensor,
              lasthour: lasthour,
              'bubble': HEATMAP_RADIUS,
              //day: moment().format("YYYY-MM-DD"),
              //hour: Number(moment().format("HH")),
              'element_uuid': selectGroup.uuid
            };

            const responsepost = await postDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, {
              panel_information: JSON.stringify({ ...newpanel, data: {}, config: newconfigheatmap }),
              panel_type: newpanel.type
            })

            if (responsepost && responsepost.error && responsepost.message === "Connection is lost!") {
              Modal.destroyAll()
              Modal.error({
                destroyOnClose: true,
                title: "Connection is lost!",
                okText: "Retry",
                onOk: () => handleSubmit(),
              })
            } else if(responsepost && !responsepost.error){
              const uuidpanel = responsepost.data;

              const newdataheatmap = await getDataHeatMap(tenant_uuid, newconfigheatmap);

              let thenewpanelheatmap = {
                ...newpanel,
                //uuid: uuid_heatmap,
                data: newdataheatmap,
                config: newconfigheatmap
              }

              //setLoading(false)

              setSensor("temperature");
              setLastHour(0.5);
              setSelectGroup();

              let newpanels = panels
              newpanels.push(
                { ...thenewpanelheatmap, uuid: uuidpanel }
              )
              newpanels.map((item, index) => {
                item.fullscreen = false
              })
              setPanels(newpanels)
              setIsModalVisibility(false);
              dispatch({ type: actions.CLEAN_COMPANY_MANAGMENT_STATES })
            }
          } else {
            message.info("Not group select");
          }
          setLoading(false)


          break;
        case 'OTHER':
          setLoading(true)

          let thenewpaneldemo = {
            ...newpanel,
            //uuid: uuid_other,
            config: {
              ...newpanel.config,
              enabled: false,
              'element_uuid': selectGroup.uuid
            },
            data: {
              moment: moment().format("YYYY-MM-DD-mm-ss"),
            }
          };

          if (selectGroup && selectGroup.uuid) {
            const uuidpanel = await postDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, {
              panel_information: JSON.stringify(thenewpaneldemo),
              panel_type: thenewpaneldemo.type
            })

            setLoading(false)

            let newpanels = panels
            newpanels.push(
              { ...thenewpaneldemo, uuid: uuidpanel }
            )
            newpanels.map((item, index) => {
              item.fullscreen = false
            })
            setPanels(newpanels)
            setIsModalVisibility(false);
          }
          break;
      }
    }




  }

  const loadingTreeNode = async (value) => {
    setLoading(value)
  }

  //Panels function udate
  const updateParentConfig = (parentindex, parentconfig) => {
    switch (panels[parentindex].type) {
      case 'HEATMAP':
        let newpanels = panels
        panels.map((panel, index) => {
          if (index === parentindex) {
            newpanels[index].config = parentconfig
          }
        })
        if (panels[parentindex].uuid) {
          putDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panels[parentindex].uuid, { panel_information: JSON.stringify({ ...newpanels[parentindex], data: {} }) })
        }
        setPanels(newpanels)
        break;
      case 'OTHER':
        let newpanels_other = panels
        panels.map((panel, index) => {
          if (index === parentindex) {
            newpanels_other[index].config = parentconfig
          }
        })
        //if (panels[parentindex].uuid) {
        putDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panels[parentindex].uuid, { panel_information: JSON.stringify({ ...newpanels_other[parentindex], data: {} }) })
        //}
        setPanels(newpanels_other)
        break;
    }
  }

  //Panels function update
  const updateParentData = (parentindex, parentdata) => {
    switch (panels[parentindex].type) {
      case 'HEATMAP':
        let newpanels = panels
        panels.map((panel, index) => {
          if (index === parentindex) {
            newpanels[index].data = parentdata
          }
        })
        setPanels(newpanels)
        break;
      case 'OTHER':
        let newpanels_other = panels
        panels.map((panel, index) => {
          if (index === parentindex) {
            newpanels_other[index].data = parentdata
          }
        })
        setPanels(newpanels_other)
        break;
    }
  }

  //api
  //update in db device location
  const getDashboardPanels = async () => {
    setLoading(true)
    setLoadingPanel(true)
    const data = await getDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid);
    if (data && data.length > 0) {
      let newdatapanels = []
      for (let index = 0; index < data.length; index++) {
        switch (data[index].panel_type) {
          case 'HEATMAP':
            let thedata = JSON.parse(data[index].panel_information)
            let datauuid = data[index].uuid
            //datainformation.uuid = datauuid
            const dataheatmap = await getDataHeatMap(tenant_uuid, thedata.config);

            /*
            if (thedata.fullscreen) {
              newdatapanels.push({
                ...thedata,
                uuid: datauuid,
                data: dataheatmap,
                x: 0,
                y: 0,
                width: '100%',
                height: '100%'
              })

            } else {
              */
            newdatapanels.push({
              ...thedata,
              uuid: datauuid,
              data: dataheatmap
            })
            //}

            break;
          case 'OTHER':
            let thedata_other = JSON.parse(data[index].panel_information)
            let datauuid_other = data[index].uuid
            //datainformation.uuid = datauuid
            newdatapanels.push({
              ...thedata_other,
              uuid: datauuid_other,
              data: {
                moment: moment().format("YYYY-MM-DD-mm-ss")
              }
            })
        }
      }
      setPanels(newdatapanels)
    }
    setLoading(false);
    setLoadingPanel(false)
  }

  const changeLoading = (value) => {

  }

  const getFullWidth = () => {
    return document.getElementById("board-inner").offsetWidth + "px"
  }

  const getFullHeight = () => {
    return document.getElementById("board-inner").offsetHeight + "px"
  }

  const convertWidthPercentPixel = (convertwidth) => {
    const index = convertwidth.search("%")

    if (index === -1) {
      return convertwidth
    } else {
      const percentvalue = convertwidth.slice(0, index)
      return ((parseFloat(percentvalue) * parseFloat(document.getElementById("board-inner").offsetWidth)) / 100).toFixed(2) + "px"
    }


  }

  const convertHeightPercentPixel = (convertheight) => {
    const index = convertheight.search("%")

    if (index === -1) {
      return convertheight
    } else {
      const percentvalue = convertheight.slice(0, index)
      return ((parseFloat(percentvalue) * parseFloat(document.getElementById("board-inner").offsetHeight)) / 100).toFixed(2) + "px"
    }


  }

  const getDataHeatMap = async (tenant_uuid, theconfig) => {
    const exist_group = await existGroup(history, dispatch, tenant_uuid, theconfig.element_uuid);
    if (exist_group) {
      let image = "";
      let devices = [];
      let exits = [];

      const responseimage = await getGroupMetadataImage(history, dispatch, tenant_uuid, theconfig.element_uuid);
      if (responseimage && !responseimage.error) {
        image = responseimage.data;
        const responsedevices = await getGroupMetadataDevice(history, dispatch, tenant_uuid, theconfig.element_uuid);
        if (responsedevices && !responsedevices.error) {
          devices = responsedevices.data;

          const responseexits = await getGroupMetadataExits(history, dispatch, tenant_uuid, theconfig.element_uuid);
        if(responseexits && !responseexits.error){
          exits = responseexits.data
        }else if(responseexits && responseexits.message === "Connection is lost!"){
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: "Connection is lost!",
            okText: "Retry",
            onOk: () => getDashboardPanels(),
          })
        }
        }else if(responsedevices && responsedevices.message === "Connection is lost!"){
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: "Connection is lost!",
            okText: "Retry",
            onOk: () => getDashboardPanels(),
          })
        }
      }else if(responseimage && responseimage.message === "Connection is lost!"){
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => getDashboardPanels(),
        })
      }
      //const data = await getDataDay(history, tenant_uuid, theconfig, devices);
      let data = []
      if (devices && devices.length > 0) {
        data = await getDataLastHour(history, dispatch, tenant_uuid, theconfig, devices)
      }
      //console.log(lolo)
      return ({ image: image, devices: devices, exits: exits, data: data, element_name: exist_group.name })
    } else {
      return ({})
    }
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      style={{
        width: "100%",
        marginBottom: "1.5em",
        marginLeft: "0",
        marginRight: "0",
        height: "100%",
      }}
    >
      <Col span={18}>
        <Card
          title={
            loadingPanel
              ? "Loading panels..."
              : <small>Dashboard</small>
          }
          className="board"
        >
          <BoardContainer className="boardcontainer" thisRef={this}>
            {loadingPanel ? <Loader /> :
              <div id="board-inner" className="board-inner">
                {panels.map((panel, index) => (

                  <Rnd
                    key={`p-${index}`}
                    //ref={refrnd[index]}
                    style={{ zIndex: panel.zindex }}
                    ref={c => { refrnd[index] = c; }}
                    id={`panel-${index}`}
                    default={{
                      x: (panel.fullscreen ? 0 : panel.x),
                      y: (panel.fullscreen ? 0 : panel.y),
                      width: (panel.fullscreen ? '100%' : panel.width),
                      height: (panel.fullscreen ? '100%' : panel.height),
                    }}
                    /*
                    position= {{x: (panel.fullscreen ? 0 : panel.x), y: (panel.fullscreen ? 0 : panel.y) }}
                    size={{
                      width: (panel.fullscreen ? document.getElementById("board-inner").style.width : panel.width),
                      height: (panel.fullscreen ? document.getElementById("board-inner").style.height : panel.height),
                    }}
                    */
                    minWidth='350'
                    minHeight='200'
                    maxWidth='100%'
                    maxHeight='100%'
                    bounds='.board-inner'
                    className="panel"
                    dragHandleClassName="text-title-panel"
                    //onResizeStop= { (delta) => resizePanel(delta, index)}
                    //onDragStop= { (e) => dragPanel(e, index)}

                    onDragStart={(e, d) => {
                      if (panels[index].fullscreen) {
                        document.getElementById(`panel-${index}`).style.width = "100%"
                        document.getElementById(`panel-${index}`).style.height = "100%"

                        refrnd[index].updatePosition({ x: 0, y: 0 })
                        refrnd[index].updateSize({ width: "100%", height: "100%" })
                      }

                      if (panels[index].fullscreen === false) {

                        if (deleteP === false) {
                          const newpanels = updateZIndex(index)
                          document.getElementById(`panel-${index}`).style.width = convertWidthPercentPixel(panels[index].width)
                          newpanels[index].width = convertWidthPercentPixel(panels[index].width)
                          document.getElementById(`panel-${index}`).style.height = convertHeightPercentPixel(panels[index].height)
                          newpanels[index].height = convertHeightPercentPixel(panels[index].height)
                          setPanels(newpanels)
                        }
                      }
                    }}

                    onDragStop={(e, d) => {
                      if (panels[index].fullscreen === false && deleteP === false) {
                        updateZIndex(index)
                        const newpanels = panels
                        if (!panels[index].fullscreen) {
                          newpanels[index].x = d.x
                          newpanels[index].y = d.y
                        }

                        setPanels(newpanels)
                        if (panels[index].uuid) {
                          putDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panels[index].uuid, { panel_information: JSON.stringify({ ...newpanels[index], data: {} }) })
                        }
                      }
                    }}

                    onResizeStart={(e, direction, ref, delta, position) => {
                      if (panels[index].fullscreen) {
                        const newpanels = panels
                        newpanels.width = getFullWidth()
                        newpanels.height = getFullHeight()
                        newpanels[index].fullscreen = false
                        setPanels(newpanels)
                        refrnd[index].updatePosition({ x: 0, y: 0 })
                        refrnd[index].updateSize({ width: getFullWidth(), height: getFullHeight() })
                        document.getElementById(`panel-${index}`).style.width = getFullWidth();
                        document.getElementById(`panel-${index}`).style.height = getFullHeight();
                      }

                      if (panels[index].fullscreen === false) {
                        const newpanels = updateZIndex(index)
                        setPanels(newpanels)
                      }
                    }}

                    onResize={(e, direction, ref, delta, position) => {
                      if (direction === "right" || direction === "left") {
                        if (!panels[index].fullscreen) {
                          //newpanels[index].height = panels[index].height
                          //document.getElementById(`panel-${index}`).style.height = panels[index].height;
                        }
                      }
                    }}

                    onResizeStop={(e, direction, ref, delta, position) => {
                      //if (panels[index].fullscreen === false) {
                      const newpanels = panels

                      if (direction === "right" || direction === "left") {
                        newpanels[index].width = convertWidthPercentPixel(ref.style.width)
                        newpanels[index].x = position.x

                        //solve problem with more height width resize
                        if (panels[index].fullscreen) {
                          document.getElementById(`panel-${index}`).style.height = getFullHeight();
                          newpanels[index].height = getFullHeight()
                          newpanels[index].x = position.x
                          newpanels[index].y = position.y
                        } else {
                          document.getElementById(`panel-${index}`).style.width = convertWidthPercentPixel(ref.style.width)
                          newpanels[index].width = convertWidthPercentPixel(ref.style.width)
                          newpanels[index].x = position.x
                          document.getElementById(`panel-${index}`).style.height = convertHeightPercentPixel(ref.style.height)
                          newpanels[index].height = convertHeightPercentPixel(ref.style.height)
                          newpanels[index].y = position.y
                          //document.getElementById(`panel-${index}`).style.height = panels[index].height;
                        }
                      }

                      if (direction === "bottom" || direction === "top") {
                        newpanels[index].height = convertHeightPercentPixel(ref.style.height)

                        if (panels[index].fullscreen) {
                          document.getElementById(`panel-${index}`).style.width = getFullWidth();
                          newpanels[index].width = getFullWidth()
                          newpanels[index].x = position.x
                          newpanels[index].y = position.y
                        } else {
                          document.getElementById(`panel-${index}`).style.width = convertWidthPercentPixel(ref.style.width)
                          newpanels[index].width = convertWidthPercentPixel(ref.style.width)
                          newpanels[index].x = position.x
                          document.getElementById(`panel-${index}`).style.height = convertHeightPercentPixel(ref.style.height)
                          newpanels[index].height = convertHeightPercentPixel(ref.style.height)
                          newpanels[index].y = position.y
                          //document.getElementById(`panel-${index}`).style.width = panels[index].width;
                        }
                      }
                      if (direction === "bottomRight" || direction === "bottomLeft") {
                        document.getElementById(`panel-${index}`).style.width = convertWidthPercentPixel(ref.style.width)
                        newpanels[index].width = convertWidthPercentPixel(ref.style.width)
                        newpanels[index].x = position.x
                        document.getElementById(`panel-${index}`).style.height = convertHeightPercentPixel(ref.style.height)
                        newpanels[index].height = convertHeightPercentPixel(ref.style.height)
                        newpanels[index].y = position.y
                      }
                      newpanels[index].fullscreen = false
                      setPanels(newpanels)
                      if (panels[index].uuid) {
                        putDashboardPanelData(history, dispatch, tenant_uuid, dashboard_uuid, panels[index].uuid, { panel_information: JSON.stringify({ ...newpanels[index], data: {} }) })
                      }
                      //}
                    }}
                  >
                    <div className="title-panel">
                      <span className="btn-panel close" onClick={(e) => { deleteP = true; close(e, index) }}><Icon type="close" /></span>
                      <span className="btn-panel maximizer" onClick={(e) => { maximizar(e, index) }}>{false ? <Icon type="fullscreen-exit" /> : <Icon type="fullscreen" />}</span>
                      <div className="move-title-panel">
                        <span className="text-title-panel" style={{ cursor: "move" }}>{panel.title}</span>
                      </div>
                    </div>
                    <div className="body-panel">
                      <ScrollableFeed forceScroll={true} >
                        <Row style={{ padding: "10px" }}>
                          {
                            (!loadingPanel ?
                              (panel.type === "HEATMAP" ?
                                (panel.data && panel.data.devices ?

                                  <DevicesHeatMapPanel
                                    index={index}
                                    editabled={false}
                                    selectKey={selectKey}

                                    parentdata={panel.data}
                                    parentconfig={panel.config}

                                    updateParentConfig={updateParentConfig}
                                    updateParentData={updateParentData}
                                    changeLoading={changeLoading}
                                  />
                                  : <div>Panel empty</div>)
                                : (panel.type === "OTHER" ?
                                  <Demo
                                    index={index}

                                    parentdata={panel.data}
                                    parentconfig={panel.config}

                                    updateParentConfig={updateParentConfig}
                                    updateParentData={updateParentData} />
                                  : <div>Empty</div>
                                ))
                              : <Loader />)
                          }
                        </Row>
                      </ScrollableFeed>
                    </div>
                  </Rnd>
                ))}
              </div>
            }
          </BoardContainer>
        </Card>
      </Col>
      <Col span={6}>
        <Card title="Panels">
          <List
            bordered
            dataSource={boxs}
            style={{ overflow: "scroll" }}
            renderItem={(item, index) => (
              <List.Item key={`list-item-${index}`}>
                <Box panel={{ ...item, index }}><div>{item.name}</div><div style={{ fontSize: "11px" }}>{item.text}</div></Box>
              </List.Item>
            )}
          />
        </Card>
      </Col>

      <Modal
        title="Heatmap Devices"
        width="60vw"
        destroyOnClose={true}
        maskClosable={true}
        style={{ top: "2em" }}
        visible={isModalVisible}
        onCancel={handleCancelEdit}
        footer={[
          <Button
            key="save"
            type="primary"
            htmlType="button"
            style={{ width: "40%" }}
            size="large"
            loading={loading}
            onClick={handleSubmit}
          >
            <Icon type="save" style={{ marginLeft: "15px" }} /> Save
            </Button>,
          <Button
            key="cancel"
            type="default"
            htmlType="button"
            style={{ width: "40%", marginLeft: "10px" }}
            size="large"
            loading={loading}
            onClick={handleCancelEdit}
          >
            <Icon type="close" style={{ marginLeft: "15px" }} /> Cancel
            </Button>,
        ]}
      >
        <ModalHeatmapConfig
          setElement={setSelectElement}
          selectSensor={setSelectSensor}
          selectLastHour={setSelectLastHour}
          setIsloading={loadingTreeNode}
        />
      </Modal>
    </Row>
  );
};

export default Board;