import * as actions from '../constants';

const initialState = {
  heatmap_tab: {
    tenat_uuid: "",
    uuid: "",
    group_type: "",
    loading: ""
  },
  details_tab: {
    redirect: false
  },
  devices_tab: {
    redirect: false
  },
}

const redirect = (state = initialState, action) => {
  switch (action.type) {
    case actions.REDIRECT_HEATMAP:
      return Object.assign({}, state, {
        heatmap_tab: action.payload,
      })
    case actions.REDIRECT_DETAILS:
      return Object.assign({}, state, {
        details_tab: action.payload,
      })
    case actions.REDIRECT_DEVICES:
        return Object.assign({}, state, {
          devices_tab: action.payload,
        })
    default:
      return state
  }
}

export default redirect