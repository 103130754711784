import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const createElement = (tenant_uuid, form, group_type) => action(actions.CREATE_ELEMENT, {tenant_uuid, form, group_type})

export const editElement = (tenant_uuid, uuid,  updated_area_type, element_form, modules_form, contact_form, group_type) => action(actions.EDIT_ELEMENT, {tenant_uuid, uuid, element_form, modules_form, contact_form, group_type})

export const successCreateEditElement = data => action(actions.SUCCESS_CREATE_EDIT_ELEMENT, { data })

export const errorCreateEditElement = error => action(actions.ERROR_CREATE_EDIT_ELEMENT, { error })

export const cleanCreationEditElementStates = () => action(actions.CLEAN_CREATION_EDIT_ELEMENT_STATE)