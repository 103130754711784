import * as actions from '../constants'

const initialState = {
  areaTypes: [],
  currentAreaTypeDetails: null,
  areaTypesFilteredList: null,
  currentAreaTypeAllowedParameters: [],
  disableAddParameterButton: false,
  search_filter: "",
  areaTypeParameters: [],
  areaTypeParametersFilteredList: null,
  currentAreaTypeParameterDetails: null,
  deletedAreaType: false,
  deletedAreaTypeParameterValue: false,
  deletedAreaTypeParameter: false,
  isAreaTypeCreatedEdited: false,
  isAreaTypeParameterCreatedEdited: false,
  isAreaTypeParameterValueCreatedEdited: false,
  lastEditedAreaTypeParameterValueID: -1,
  error: null,
  errorCreateEditAreaType: null,
  errorCreateEditAreaTypeParameter: null,
  errorAreaTypeParameters: null,
}

const areaTypeParameters = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_AREA_TYPES_LIST:

      let areaTypesCompleteList = action.payload.data.map(area => {
        area.key = area.name
        return area
      })

      return Object.assign({}, state, {
        areaTypes: areaTypesCompleteList
      })

    case actions.SUCCESS_FETCH_AREA_TYPE_PARAMETERS_LIST:

      let areaTypeParametersCompleteList = action.payload.data.map(parameter => {
        parameter.key = parameter.name
        return parameter
      })

      let areaTypeAllowedParameters = []
      if (state.currentAreaTypeDetails && state.currentAreaTypeDetails.parameters_values) {
        if (state.currentAreaTypeDetails.parameters_values.length > 0) {
          areaTypeAllowedParameters = areaTypeParametersCompleteList.filter(parameter => parameter.name !== state.currentAreaTypeDetails.parameters_values[0].parameter_name)
          for (let i = 1; i < state.currentAreaTypeDetails.parameters_values.length; i++) {
            areaTypeAllowedParameters = areaTypeAllowedParameters.filter(parameter => parameter.name !== state.currentAreaTypeDetails.parameters_values[i].parameter_name)
          }
        }
      }

      return Object.assign({}, state, {
        areaTypeParameters: areaTypeParametersCompleteList,
        currentAreaTypeAllowedParameters: areaTypeAllowedParameters,
      })

    case actions.SUCCESS_REMOVE_AREA_TYPE:
      const result = {}
      const area_type_name = action.payload.area_type_name
      result.areaTypes = state.areaTypes.filter(area => area.key !== area_type_name)

      if (state.areaTypesFilteredList) {
        result.areaTypesFilteredList = state.areaTypesFilteredList.filter(area => area.key !== area_type_name)
      }

      result.deletedAreaType = true

      return Object.assign({}, state, result)

    case actions.SUCCESS_REMOVE_AREA_TYPE_PARAMETER:
      const resultP = {}
      const area_type_parameter_name = action.payload.area_type_parameter_name
      resultP.areaTypeParameters = state.areaTypeParameters.filter(parameter => parameter.key !== area_type_parameter_name)

      if (state.areaTypeParametersFilteredList) {
        resultP.areaTypeParametersFilteredList = state.areaTypeParametersFilteredList.filter(parameter => parameter.key !== area_type_parameter_name)
      }

      resultP.deletedAreaTypeParameter = true

      return Object.assign({}, state, resultP)

    case actions.SUCCESS_FETCH_AREA_TYPE:
      const resultFetchAreaType = action.payload.data

      const resultCurrentObjects = {
        currentAreaTypeDetails: resultFetchAreaType
      }

      return Object.assign({}, state, resultCurrentObjects)

    case actions.SUCCESS_FETCH_AREA_TYPE_PARAMETER:
      const resultFetchAreaTypeParameter = action.payload.data

      const resultCurrentObjectsP = {
        currentAreaTypeParameterDetails: resultFetchAreaTypeParameter
      }

      return Object.assign({}, state, resultCurrentObjectsP)

    case actions.SUCCESS_CREATE_AREA_TYPE:
      const newAreaTypeData = action.payload.data
      return Object.assign({}, state, {
        areaTypes: state.areaTypes.concat({ label: newAreaTypeData.label, name: newAreaTypeData.name, key: newAreaTypeData.name }),
        currentAreaTypeDetails: { label: newAreaTypeData.label, name: newAreaTypeData.name, key: newAreaTypeData.name, parameters_values: [], description: newAreaTypeData.description },
        isAreaTypeCreatedEdited: true
      })

    case actions.SUCCESS_CREATE_AREA_TYPE_PARAMETER:
      const newAreaTypeParameterData = action.payload.data
      return Object.assign({}, state, {
        areaTypeParameters: state.areaTypeParameters.concat({ label: newAreaTypeParameterData.label, name: newAreaTypeParameterData.name, key: newAreaTypeParameterData.name }),
        isAreaTypeParameterCreatedEdited: true
      })

    case actions.SUCCESS_EDIT_AREA_TYPE:
      const newAreaTypesData = [...state.areaTypes]
      const areaTypeIndex = newAreaTypesData.findIndex((area => area.name === state.currentAreaTypeDetails.name))
      newAreaTypesData[areaTypeIndex] = Object.assign({}, newAreaTypesData[areaTypeIndex], { name: action.payload.data.name, label: action.payload.data.label })
      const newCurrentAreaTypeDetails = Object.assign({}, state.currentAreaTypeDetails, { name: action.payload.data.name, label: action.payload.data.label, description: action.payload.data.description })

      return Object.assign({}, state, {
        areaTypes: newAreaTypesData,
        isAreaTypeCreatedEdited: true,
        currentAreaTypeDetails: newCurrentAreaTypeDetails
      })

    case actions.ADD_AREA_TYPE_PARAMETER_VALUE_EMPTY_FORM:
      const newEmtyAreaTypeDetails = { ...state.currentAreaTypeDetails }
      newEmtyAreaTypeDetails.parameters_values = newEmtyAreaTypeDetails.parameters_values.concat({ create: true })
      return Object.assign({}, state, {
        currentAreaTypeDetails: newEmtyAreaTypeDetails,
        disableAddParameterButton: true,
      })

    case actions.SUCCESS_ADD_AREA_TYPE_PARAMETER_VALUE:
      const newAreaTypeDetails = { ...state.currentAreaTypeDetails }
      if (action.payload.isCreate) {
        newAreaTypeDetails.parameters_values = newAreaTypeDetails.parameters_values.filter((value => !value.create))
        newAreaTypeDetails.parameters_values = newAreaTypeDetails.parameters_values.concat({
          parameter_name: action.payload.data.parameter_name,
          parameter_label: action.payload.data.parameter_label,
          parameter_type: action.payload.data.parameter_type,
          value: action.payload.data.value,
          id: action.payload.data.id
        })
      }

      return Object.assign({}, state, {
        currentAreaTypeDetails: newAreaTypeDetails,
        isAreaTypeParameterValueCreatedEdited: true,
        disableAddParameterButton: false,
        lastEditedAreaTypeParameterValueID: action.payload.data.id,
      })

    case actions.UPDATE_AREA_TYPE_ALLOWED_PARAMETERS:

      let newAreaTypeAllowedParameters = []
      if (state.currentAreaTypeDetails && state.currentAreaTypeDetails.parameters_values) {
        if (state.currentAreaTypeDetails.parameters_values.length > 0) {
          newAreaTypeAllowedParameters = state.areaTypeParameters.filter(parameter => parameter.name !== state.currentAreaTypeDetails.parameters_values[0].parameter_name)
          for (let i = 1; i < state.currentAreaTypeDetails.parameters_values.length; i++) {
            newAreaTypeAllowedParameters = newAreaTypeAllowedParameters.filter(parameter => parameter.name !== state.currentAreaTypeDetails.parameters_values[i].parameter_name)
          }
        }
      }

      return Object.assign({}, state, {
        currentAreaTypeAllowedParameters: newAreaTypeAllowedParameters,
      })

    case actions.SUCCESS_DELETE_AREA_TYPE_PARAMETER_VALUE:
      const deletedAreaTypeDetails = { ...state.currentAreaTypeDetails }
      deletedAreaTypeDetails.parameters_values = deletedAreaTypeDetails.parameters_values.filter((value => value.parameter_name !== action.payload.parameter_name))

      return Object.assign({}, state, {
        currentAreaTypeDetails: deletedAreaTypeDetails,
        deletedAreaTypeParameterValue: true
      })

    case actions.SUCCESS_EDIT_AREA_TYPE_PARAMETER:
      const newAreaTypeParametersData = [...state.areaTypeParameters]
      const areaTypeParameterIndex = newAreaTypeParametersData.findIndex((parameter => parameter.name === state.currentAreaTypeParameterDetails.name))
      newAreaTypeParametersData[areaTypeParameterIndex] = Object.assign({}, newAreaTypeParametersData[areaTypeParameterIndex], { name: action.payload.data.name, label: action.payload.data.label })

      return Object.assign({}, state, {
        areaTypeParameters: newAreaTypeParametersData,
        isAreaTypeParameterCreatedEdited: true
      })

    case actions.ERROR_FETCH_AREA_TYPES_LIST:
      return Object.assign({}, state, {
        areaTypes: [],
        currentAreaTypeDetails: null,
        areaTypesFilteredList: null,
        currentAreaTypeAllowedParameters: [],
        disableAddParameterButton: false,
        search_filter: "",
        areaTypeParameters: [],
        areaTypeParametersFilteredList: null,
        currentAreaTypeParameterDetails: null,
        deletedAreaType: false,
        deletedAreaTypeParameterValue: false,
        deletedAreaTypeParameter: false,
        isAreaTypeCreatedEdited: false,
        isAreaTypeParameterCreatedEdited: false,
        isAreaTypeParameterValueCreatedEdited: false,
        lastEditedAreaTypeParameterValueID: -1,
        error: action.payload.error,
        errorCreateEditAreaType: null,
        errorCreateEditAreaTypeParameter: null
      })

    case actions.ERROR_REMOVE_AREA_TYPE || actions.ERROR_FETCH_AREA_TYPE:
      return Object.assign({}, state, {
        error: action.payload.error
      })

    case actions.ERROR_CREATE_EDIT_AREA_TYPE:
      return Object.assign({}, state, {
        errorCreateEditAreaType: action.payload.error
      })

    case actions.ERROR_FETCH_AREA_TYPE_PARAMETERS_LIST:
      if (action.payload.inAreaTypeForm) {
        return Object.assign({}, state, {
          error: action.payload.error
        })
      } else {
        return Object.assign({}, state, {
          errorAreaTypeParameters: action.payload.error
        })
      }


    case actions.ERROR_CREATE_EDIT_AREA_TYPE_PARAMETER:
      return Object.assign({}, state, {
        errorCreateEditAreaTypeParameter: action.payload.error
      })

    case actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES:
      return Object.assign({}, state, {
        deletedAreaType: false,
        deletedAreaTypeParameterValue: false,
        deletedAreaTypeParameter: false,
        isAreaTypeCreatedEdited: false,
        isAreaTypeParameterCreatedEdited: false,
        isAreaTypeParameterValueCreatedEdited: false,
        // lastEditedAreaTypeParameterValueID: -1,
        disableAddParameterButton: false,
        error: null,
        errorCreateEditAreaType: null,
        errorCreateEditAreaTypeParameter: null
      })

    case actions.CLEAN_AREA_TYPE_PARAMETERS_MANAGMENT_STATE:
      return Object.assign({}, state, {
        areaTypes: [],
        currentAreaTypeDetails: null,
        areaTypesFilteredList: null,
        currentAreaTypeAllowedParameters: [],
        disableAddParameterButton: false,
        search_filter: "",
        areaTypeParameters: [],
        areaTypeParametersFilteredList: null,
        currentAreaTypeParameterDetails: null,
        deletedAreaType: false,
        deletedAreaTypeParameterValue: false,
        deletedAreaTypeParameter: false,
        isAreaTypeCreatedEdited: false,
        isAreaTypeParameterCreatedEdited: false,
        isAreaTypeParameterValueCreatedEdited: false,
        lastEditedAreaTypeParameterValueID: -1,
        error: null,
        errorCreateEditAreaType: null,
        errorCreateEditAreaTypeParameter: null
      })

    default:
      return state
  }
}

export default areaTypeParameters