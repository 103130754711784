import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdatePeopleAnalytics, errorFetchUpdatePeopleAnalytics } from '../actions/peopleAnalyticsActions'
import { fetchPeopleAnalyticsApi, updatePeopleAnalyticsApi } from '../api/peopleAnalyticsApi'
import { fetchIllumanceApi, updateIllumanceApi } from '../api/illuminanceApi'
import { tooglePeopleAnalyticsLoader } from '../actions/loaderActions'
import { handleTreeData } from '../actions/companyManagmentActions'

export function* getPeopleAnalyticsValues(action) {
    try {
        yield put(tooglePeopleAnalyticsLoader(true))

        const illuminance_data = yield call(fetchIllumanceApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.group_type
        )

        let people_analytics_data = null
        if (action.payload.group_type === "Control" || action.payload.group_type === "Logical" || action.payload.group_type === "Mix") {
            people_analytics_data = yield call(fetchPeopleAnalyticsApi,
                action.payload.tenant_uuid,
                action.payload.uuid
            )

            if(!people_analytics_data.floor_plan_area){
                people_analytics_data.floor_plan_area = 4500
            }

            if(!people_analytics_data.workspace_radius){
                people_analytics_data.workspace_radius = 3
            }

            if(!people_analytics_data.occupancy_limit){
                people_analytics_data.occupancy_limit = 8
            }
        }
        const data = Object.assign({}, people_analytics_data, { "people_analytics": illuminance_data.people_analytics })

        data.update = false

        yield put(successFetchUpdatePeopleAnalytics(data))
        yield put(tooglePeopleAnalyticsLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(tooglePeopleAnalyticsLoader(false))
        yield put(errorFetchUpdatePeopleAnalytics(error))
    }
}

export function* updatePeopleAnalyticsValues(action) {
    try {
        yield put(tooglePeopleAnalyticsLoader(true))

        const formpa = {}
        formpa.people_analytics = action.payload.form.people_analytics

        const illuminance_data = yield call(updateIllumanceApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            formpa,
            action.payload.group_type
        )

        const form = {}
        form.floor_plan_area = action.payload.form.floor_plan_area
        form.workspace_radius = action.payload.form.workspace_radius
        form.occupancy_limit = action.payload.form.occupancy_limit

        let people_analytics_data = null
        if (action.payload.group_type === "Control" || action.payload.group_type === "Logical" || action.payload.group_type === "Mix") {
            people_analytics_data = yield call(updatePeopleAnalyticsApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                form
            )
        }

        let data = Object.assign({}, people_analytics_data, { "people_analytics": illuminance_data.people_analytics })

        data.update = true
        data.floor_plan_area_in_payload = "floor_plan_area" in action.payload.form
        data.workspace_radius_in_payload = "workspace_radius" in action.payload.form
        data.occupancy_limit_in_payload = "occupancy_limit" in action.payload.form
        data.people_analytics_in_payload = "people_analytics" in action.payload.form


        yield put(handleTreeData(data, "settings"))
        yield put(successFetchUpdatePeopleAnalytics(data))
        yield put(tooglePeopleAnalyticsLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(tooglePeopleAnalyticsLoader(false))
        yield put(errorFetchUpdatePeopleAnalytics(error))
    }
}

export function* fetchPeopleAnalyticsWatcher() {
    yield takeLatest(actions.FETCH_PEOPLEANALYTICS, getPeopleAnalyticsValues)
}

export function* updatePeopleAnalyticsWatcher() {
    yield takeLeading(actions.UPDATE_PEOPLEANALYTICS, updatePeopleAnalyticsValues)
}