import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Empty, Button, Modal, Radio, Row, Col, Notification as NotificationANTD } from "antd";
import "./style.css";
import NotificationList from "../NotificationList";
import NotificationDetail from "../NotificationDetail";
import NotificationCreateForm from "../NotificationCreateForm";
import { getElementTypeForAPI } from "../../utils/helpers"
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import * as constant from '../../constants/notifications'

const Notification = ({
  history,
  parentTab
}) => {

  const [transport, setTransport] = useState("all")
  const [currentNotification, setCurrentNotification] = useState()
  const [currentNotificationUUID, setCurrentNotificationUUID] = useState()

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const [receipt_send, setReciberSend] = useState("receipt")
  const [newNotification, setNewNotification] = useState(false)
  const [detailNotification, setDetailNotification] = useState(false)

  const notificationData = useSelector((state) => state.rootReducer.notification.notificationData)
  const loading = useSelector(state => state.rootReducer.loaders.loadingNotification)
  const error = useSelector((state) => state.rootReducer.notification.error)

  const notificationSendData = useSelector((state) => state.rootReducer.notification.notificationSendData)
  const loadingSend = useSelector(state => state.rootReducer.loaders.loadingNotification)
  const errorSend = useSelector((state) => state.rootReducer.notification.errorSend)


  const dispatch = useDispatch()


  
  useEffect(() => {
    setTransport("all")
  },
    [element.uuid, parentTab]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          NotificationANTD.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              if (receipt_send === constant.SEND) {
                dispatch(({
                  type: actions.FETCH_SEND_NOTIFICATION,
                  payload: { tenant_uuid: tenant_uuid, element_type: getElementTypeForAPI(element.group_type), uuid: element.uuid, transport: transport }
                }))
              } else {
                dispatch(({
                  type: actions.FETCH_NOTIFICATION,
                  payload: { tenant_uuid: tenant_uuid, element_type: getElementTypeForAPI(element.group_type), uuid: element.uuid, transport: transport }
                }))
              }
            },
          })
        } else {
          NotificationANTD.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_NOTIFICATION_ERROR })
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (errorSend !== null) {
        if (errorSend.error_code === error_codes.ERR_INVALID_TOKEN || errorSend.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          NotificationANTD.error({ message: "Permission required", "description": errorSend.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (errorSend.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              if (receipt_send === constant.SEND) {
                dispatch(({
                  type: actions.FETCH_SEND_NOTIFICATION,
                  payload: { tenant_uuid: tenant_uuid, element_type: getElementTypeForAPI(element.group_type), uuid: element.uuid, transport: transport }
                }))
              } else {
                dispatch(({
                  type: actions.FETCH_NOTIFICATION,
                  payload: { tenant_uuid: tenant_uuid, element_type: getElementTypeForAPI(element.group_type), uuid: element.uuid, transport: transport }
                }))
              }
            },
          })
        } else {
          NotificationANTD.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_NOTIFICATION_ERROR })
        }
      }
    },
    [errorSend]
  )


  useEffect(() => {
    if (element && element.uuid && element.group_type) {

      if (receipt_send === constant.SEND) {
        dispatch(({
          type: actions.FETCH_SEND_NOTIFICATION,
          payload: { tenant_uuid: tenant_uuid, element_type: getElementTypeForAPI(element.group_type), uuid: element.uuid, transport: transport }
        }))
      } else {
        dispatch(({
          type: actions.FETCH_NOTIFICATION,
          payload: { tenant_uuid: tenant_uuid, element_type: getElementTypeForAPI(element.group_type), uuid: element.uuid, transport: transport }
        }))
      }

    } else {
      dispatch(({
        type: actions.CLEAN_NOTIFICATION_STATE
      }))
      dispatch(({
        type: actions.CLEAN_SEND_NOTIFICATION_STATE
      }))
    }
  },
    [transport, element.uuid, receipt_send])


  const detailNotificationFunction = (index) => {
    if (receipt_send === constant.RECEIPT) {
      if (notificationData && notificationData[transport].length > index) {
        setCurrentNotification(notificationData[transport][index])
        setCurrentNotificationUUID(notificationData[transport][index].uuid)
        //dispatch({
        //  type: actions.SET_NOTIFICATION_DETAIL,
        //  payload: notificationData[transport][index]
        //})
        setDetailNotification(true)
      }
    } else if (receipt_send === constant.SEND) {

      if (notificationSendData && notificationSendData[transport].length > index) {
        setCurrentNotification(notificationSendData[transport][index])
        setCurrentNotificationUUID(notificationSendData[transport][index].uuid)
        //dispatch({
        //  type: actions.SET_NOTIFICATION_DETAIL,
        //  payload: notificationData[transport][index]
        //})
        setDetailNotification(true)
      }
    }
  }

  const detailNotificationFalse = () => {
    setDetailNotification(false)
  }

  const newNotificationFalse = () => {
    setNewNotification(false)
  }

  const createNotification = e => {
    setNewNotification(true);
  };

  const selectTransortShow = e => {
    setTransport(e.target.value);
  };

  const selectReceiptSend = e => {
    setReciberSend(e.target.value);
  };

  return (
    element && element.name !== undefined
      ? (
        <>
          {newNotification ?

            <>
              <Row>
                <NotificationCreateForm
                  form_type="element"
                  element_uuid={element.uuid}
                  element_type={element.group_type}
                  deselectCreate={newNotificationFalse}
                />
              </Row>

            </>
            :
            detailNotification ?

              <>
                <Row>
                  <NotificationDetail
                    call="element"
                    receipt_send={receipt_send}
                    transport={transport}
                    notification={currentNotification}
                    deselectNotification={detailNotificationFalse}
                  />
                </Row>
              </>
              :
              <>
                <Row className="notification-detail" style={{marginBottom: "10px"}}>
                  <Col span={6} >
                    <Button type="primary" onClick={createNotification}>New Notification</Button>
                  </Col>
                  <Col span={12} >
                    <Radio.Group
                      className="radio-button-receipt-sender"
                      onChange={selectTransortShow}
                      value={transport}
                      optionType="button"
                      buttonStyle="solid"
                    >
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="toast">Toast</Radio.Button>
                      <Radio.Button value="mail">Mail</Radio.Button>
                      <Radio.Button value="sms">SMS</Radio.Button>

                    </Radio.Group>
                  </Col>
                  <Col span={6} >
                    <Radio.Group
                      className="radio-button-receipt-sender"
                      onChange={selectReceiptSend}
                      value={receipt_send}
                      optionType="button"
                      buttonStyle="solid"
                    >
                      <Radio.Button value="receipt">Receipt</Radio.Button>
                      <Radio.Button value="send">Send</Radio.Button>

                    </Radio.Group>
                  </Col>
                </Row>

                {receipt_send && receipt_send === constant.RECEIPT && notificationData && notificationData[transport] ?
                  <NotificationList
                    transport={transport}
                    notificationData={notificationData[transport]}
                    isLoading={loading}
                    selectNotification={detailNotificationFunction}
                  />
                  : receipt_send && receipt_send === constant.SEND && notificationSendData && notificationSendData[transport] ?
                    <NotificationList
                      transport={transport}
                      notificationData={notificationSendData[transport]}
                      isLoading={loadingSend}
                      selectNotification={detailNotificationFunction}

                    />
                    :
                    <Empty />
                }
              </>
          }

        </>
      )
      : <Empty />
  );
};

export default withRouter(Notification);
