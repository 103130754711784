import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchCircadianRhythmData = (tenant_uuid, uuid) => action(actions.FETCH_CIRCADIAN_RHYTHM_DATA, {tenant_uuid, uuid })

export const successFetchCircadianRhythmData = data => action(actions.SUCCESS_FETCH_CIRCADIAN_RHYTHM_DATA, { data })

export const updateCircadianRhythm = (tenant_uuid, uuid, form) => action(actions.UPDATE_CIRCADIAN_RHYTHM, {tenant_uuid, uuid, form })

export const successUpdateCircadianRhythm = data  => action(actions.SUCCESS_UPDATE_CIRCADIAN_RHYTHM, { data })

export const errorCircadianRhythm = error => action(actions.ERROR_CIRCADIAN_RHYTHM, { error })

export const cleanCircadianRhythmState = () => action(actions.CLEAN_CIRCADIAN_RHYTHM_STATE)
