import {
    putCompanyAggregationRequestService,
    putBuildingAggregationRequestService,
    putGroupAggregationRequestService,
    getCompanyAggregationRequestService,
    getBuildingAggregationRequestService,
    getGroupAggregationRequestService
 } from "../services"
 
 export const updateElementAggregationRequestApi = async (tenant_uuid, uuid, form, group_type) => {
     let putFunction = () => { }
     switch (group_type) {
         case "Building":
            putFunction = putBuildingAggregationRequestService
             break;
         case "Logical":
            putFunction = putGroupAggregationRequestService
             break;
         case "Control":
             putFunction = putGroupAggregationRequestService
             break;
         case "Mix":
             putFunction = putGroupAggregationRequestService
             break;
         case "Company":
             putFunction = putCompanyAggregationRequestService
             break;
         default:
             putFunction = undefined;
     }
     
     const response = await putFunction(tenant_uuid, uuid, form)
     if (response && response.status === 202) {
         let data = await response.data;
         return data
     }
 }

 export const fetchElementAggregationRequestApi = async (tenant_uuid, uuid, group_type) => {
    let getFunction = () => { }
    switch (group_type) {
        case "Building":
           getFunction = getBuildingAggregationRequestService
            break;
        case "Logical":
           getFunction = getGroupAggregationRequestService
            break;
        case "Control":
            getFunction = getGroupAggregationRequestService
            break;
        case "Mix":
            getFunction = getGroupAggregationRequestService
            break;
        case "Company":
            getFunction = getCompanyAggregationRequestService
            break;
        default:
            getFunction = undefined;
    }
    
    const response = await getFunction(tenant_uuid, uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}