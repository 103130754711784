import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchDevices, successAddDevices, successRemoveDevice, errorFetchUpdateDevices } from '../actions/controllersActions'
import { successFetchLightCapabilityData } from '../actions/lightCapabilityActions'
import { fetchDevicesApi, addDevicesApi, removeDeviceApi } from '../api/controllersApi'
import {fetchLightCapabilityApi} from '../api/lightCapabilityApi'
import { toogleControllersLoader } from '../actions/loaderActions'

export function* getGroupControllers(action) {
    try {
        yield put(toogleControllersLoader(true))
        const data = yield call(fetchDevicesApi,
            action.payload.tenant_uuid,
            action.payload.uuid
        )

        yield put(successFetchDevices(data))
        yield put(toogleControllersLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }
        yield put(toogleControllersLoader(false))
        yield put(errorFetchUpdateDevices(error))
    }
}

export function* addControllers(action) {
    try {
        yield put(toogleControllersLoader(true))

        const controllers = action.payload.data
        const payload = []
        for(let i = 0; i < controllers.length; i++){
            if ("isNew" in controllers[i]) {
              if ("value" in controllers[i]){
                payload.push({ "aws_id": controllers[i].value, "group_uuid": action.payload.uuid })
              }
            }
          }

        const data = yield call(addDevicesApi,
                action.payload.tenant_uuid,
                payload
            )

        //updated group light capability
        const light_capability = yield call(fetchLightCapabilityApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
        )

        yield put(successAddDevices(data))
        yield put(successFetchLightCapabilityData(light_capability))
        yield put(toogleControllersLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }
        yield put(toogleControllersLoader(false))
        yield put(errorFetchUpdateDevices(error))
    }
}

export function* removeController(action) {
    try {
        yield put(toogleControllersLoader(true))

        const result = yield call(removeDeviceApi,
                action.payload.tenant_uuid,
                action.payload.aws_id
            )
        
        if(result){
            //updated group light capability
            const light_capability = yield call(fetchLightCapabilityApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
            )

            yield put(successRemoveDevice(action.payload.aws_id))
            yield put(successFetchLightCapabilityData(light_capability))
            yield put(toogleControllersLoader(false))
        }
    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }
        yield put(toogleControllersLoader(false))
        yield put(errorFetchUpdateDevices(error))
    }
}

export function* fetchControllersWatcher() {
    yield takeLatest(actions.FETCH_DEVICES, getGroupControllers)
}

export function* addControllersWatcher() {
    yield takeLeading(actions.ADD_DEVICES, addControllers)
}

export function* removeControllerWatcher() {
    yield takeLeading(actions.REMOVE_DEVICE, removeController)
}