import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdateAdvancedSchedule, errorFetchUpdateAdvancedSchedule } from '../actions/AdvancedScheduleActions'
import { fetchGroupAdvancedScheduleApi, updateGroupAdvancedScheduleApi } from '../api/AdvancedScheduleApi'
import { toogleAdvancedScheduleLoader } from '../actions/loaderActions'

export function* getGroupAdvancedScheduleValues(action) {
    try {
        yield put(toogleAdvancedScheduleLoader(true))

        const advanced_schedule_data = yield call(fetchGroupAdvancedScheduleApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
        )

        const data = Object.assign({}, advanced_schedule_data, null)

        if(!data.advanced_schedule){
            data.advanced_schedule = {timezone: "Canada/Eastern",
            periods: []}
        }

        data.update = false

        yield put(successFetchUpdateAdvancedSchedule(data))
        yield put(toogleAdvancedScheduleLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        }
        yield put(toogleAdvancedScheduleLoader(false))
        yield put(errorFetchUpdateAdvancedSchedule(error))
    }
}

export function* updateGroupAdvancedScheduleValues(action) {
    try {
        yield put(toogleAdvancedScheduleLoader(true))

        let advanced_schedule_data = {}
        const advanced_schedule_enabled = "advanced_schedule_enabled" in action.payload.form
        const advanced_schedule = "advanced_schedule" in action.payload.form
        const form = {}
       
        if ( advanced_schedule_enabled || advanced_schedule) {
            
            if(advanced_schedule_enabled){
                form.advanced_schedule_enabled = action.payload.form.advanced_schedule_enabled
            }

            if(advanced_schedule){
                form.advanced_schedule = action.payload.form.advanced_schedule
            }

            advanced_schedule_data = yield call(updateGroupAdvancedScheduleApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                form
            )
        }

        const data = Object.assign({}, advanced_schedule_data, {})

        data.update = true

        yield put(successFetchUpdateAdvancedSchedule(data))
        yield put(toogleAdvancedScheduleLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleAdvancedScheduleLoader(false))
        yield put(errorFetchUpdateAdvancedSchedule(error))
    }
}

export function* fetchGroupAdvancedScheduleWatcher() {
    yield takeLatest(actions.FETCH_ADVANCED_SCHEDULE, getGroupAdvancedScheduleValues)
}

export function* updateGroupAdvancedScheduleWatcher() {
    yield takeLeading(actions.UPDATE_ADVANCED_SCHEDULE, updateGroupAdvancedScheduleValues)
}