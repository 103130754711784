// Groupd schedule override (schedule_inheritance)
export const GROUP_SCHEDULE_OVERRIDE = 'override'
export const GROUP_SCHEDULE_OWN = 'own'
export const GROUP_SCHEDULE_NONE = 'none'

// Group Type (group_type) either 'Control'  or 'Logical',  'Mix'
export const GROUP_TYPE_CONTROL = 'Control'
export const GROUP_TYPE_LOGICAL = 'Logical'
export const GROUP_TYPE_MIX = 'Mix'

export const schedule_valid_overrides = [GROUP_SCHEDULE_OVERRIDE,
                            GROUP_SCHEDULE_OWN, GROUP_SCHEDULE_NONE]

export const GROUP_LEVEL_OVERRIDE = 'override'
export const GROUP_LEVEL_OWN ='own'
export const GROUP_LEVEL_NONE = 'none'

export const GROUP_COLORTEMPERATURE_OVERRIDE = 'override'
export const GROUP_COLORTEMPERATURE_OWN ='own'
export const GROUP_COLORTEMPERATURE_NONE = 'none'

export const GROUP_ON_OVERRIDE = 'override'
export const GROUP_ON_SUPLEMENTAL = 'suplemental'
export const GROUP_ON_OWN ='own'
export const GROUP_ON_NONE = 'none'