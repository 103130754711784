import * as actions from '../constants'
import * as roles from '../constants/roles'
import * as groupTypes from '../constants/groups'

const { RESTRICTION_MAX_GROUP_LEVEL_USER_CREATE_OR_EDIT } = window.Configs;

const initialState = {
  users: [],
  currentUserDetails: null,
  usersFilteredList: null,
  search_filter: "",
  roles: null,
  newUserPassword: "",
  isPasswordReseted: false,
  companies_options: null,
  buildings_options: null,
  groups_options: null,
  error: null,
  errorCreateEdit: null,
  deletedUser: false,
  isUserCreatedEdited: false
}

function getChildrenArray(groups, user_role) {
  let result = []
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i]
    if (user_role === roles.USER_ROLE_OFFICEADMIN) {
      if (group.group_type === groupTypes.GROUP_TYPE_LOGICAL) {
        result.push({
          uuid: group.uuid,
          name: group.name
        })
      }
    } else if (user_role === roles.USER_ROLE_USER) {
      const num_level = get_num_level_group(group)

      //RESTRICTION_MAX_GROUP_LEVEL_USER_CREATE_OR_EDIT
      if(num_level < RESTRICTION_MAX_GROUP_LEVEL_USER_CREATE_OR_EDIT){
        result.push({
          uuid: group.uuid,
          name: group.name
        })
      }
      

      /*
      //add cheking logical control  child and not logical child
      if (group.group_type === groupTypes.GROUP_TYPE_CONTROL) {
        result.push({
          uuid: group.uuid,
          name: group.name
        })
      } else if (group.group_type === groupTypes.GROUP_TYPE_LOGICAL) {
        if (group.children && group.children.length > 0) {
          if (isChildren(group.children, groupTypes.GROUP_TYPE_CONTROL) && (!isChildren(group.children, groupTypes.GROUP_TYPE_LOGICAL) && !isChildren(group.children, groupTypes.GROUP_TYPE_MIX))) {
            result.push({
              uuid: group.uuid,
              name: group.name
            })
          }
        }
      } else if (group.group_type === groupTypes.GROUP_TYPE_MIX) {
        if (group.children && group.children.length > 0) {
          if (isChildren(group.children, groupTypes.GROUP_TYPE_CONTROL) && (!isChildren(group.children, groupTypes.GROUP_TYPE_LOGICAL) && !isChildren(group.children, groupTypes.GROUP_TYPE_MIX))) {
            result.push({
              uuid: group.uuid,
              name: group.name
            })
          }
        }
      }
      */
    }

    if (group.children && group.children.length > 0) {
      result = [].concat(result, getChildrenArray(group.children, user_role))
    }
  }

  return result
}


function get_num_level_group(group){
  let max_level = 0;
  if (group.children && group.children.length > 0) {
    const groups = group.children;
    for (let i = 0; i < groups.length; i++) {
      //const group = get_num_level_group(groups[i])
      max_level = Math.max(max_level, 1 + get_num_level_group(groups[i]))
    }
  }
  
  return max_level
  
}

function isChildren(groups, group_type) {
  let result = false;
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i]
    if (group.group_type === group_type) {
      result = true;
      break;
    }

  }

  return result
}

const userManagment = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_USERS_DATA:

      let usersCompleteList = action.payload.usersData.map(user => {
        user.key = user.uuid;
        return user
      })

      return Object.assign({}, state, {
        users: usersCompleteList,
        roles: action.payload.rolesData
      })

    case actions.SUCCESS_REMOVE_USER:
      const result = {}
      const user_uuid = action.payload.user_uuid
      result.users = state.users.filter(user => user.key !== user_uuid)

      if (state.usersFilteredList) {
        result.usersFilteredList = state.usersFilteredList.filter(user => user.key !== user_uuid)
      }

      result.deletedUser = true

      return Object.assign({}, state, result)

    case actions.SUCCESS_FETCH_USER:
      const resultFetchUser = action.payload.dataUser
      resultFetchUser.companies = action.payload.dataCompanies
      resultFetchUser.buildings = action.payload.dataBuildings
      resultFetchUser.groups = action.payload.dataGroups

      const resultCurrentObjects = {
        currentUserDetails: resultFetchUser
      }

      return Object.assign({}, state, resultCurrentObjects)

    case actions.USER_SUCCESS_FETCH_COMPANIES_LIST:
      const companies_options = action.payload.data.map(company => {
        const company_data = {}
        company_data.name = company.name
        company_data.uuid = company.uuid
        company_data.key = company.uuid
        return company_data
      })

      return Object.assign({}, state, {
        companies_options
      })

    case actions.USER_SUCCESS_FETCH_COMPANY_BUILDINGS:
      const buildings_options = action.payload.data.children.map(building => {
        const building_data = {}
        building_data.name = building.name
        building_data.uuid = building.uuid
        building_data.key = building.uuid
        return building_data
      })

      const resultFetchCompanyBuildings = {
        buildings_options,
        currentCompanies: action.payload.company_uuid
      }

      return Object.assign({}, state, resultFetchCompanyBuildings)

    case actions.USER_SUCCESS_FETCH_BUILDING_GROUPS:
      let groups_options = null
      if (action.payload.data.length && action.payload.data.length > 0) {
        groups_options = getChildrenArray(action.payload.data[0].children, action.payload.user_role).map(group => {
          return group
        })
        for (let i = 1; i < action.payload.data.length; i++) {
          groups_options = [].concat(groups_options, getChildrenArray(action.payload.data[i].children, action.payload.user_role))
        }
      }

      const buildingsGroupsResult = {
        groups_options
      }

      return Object.assign({}, state, buildingsGroupsResult)

    case actions.USER_SUCCESS_FETCH_GROUP_CHILDREN:
      const groupOptionsOffice = getChildrenArray(action.payload.data.children, roles.USER_ROLE_USER)

      return Object.assign({}, state, {
        groups_options: groupOptionsOffice
      })

    case actions.SUCCESS_CREATE_USER:
      const newUserData = action.payload.data
      return Object.assign({}, state, {
        users: state.users.concat({ username: newUserData.username, uuid: newUserData.uuid, key: newUserData.uuid, is_active: newUserData.is_active }),
        isUserCreatedEdited: true
      })

    case actions.SUCCESS_EDIT_USER:
      const newUsersData = [...state.users]
      const userIndex = newUsersData.findIndex((user => user.uuid === state.currentUserDetails.uuid))
      newUsersData[userIndex] = Object.assign({}, newUsersData[userIndex], { username: action.payload.data.username, is_active: action.payload.data.is_active })

      return Object.assign({}, state, {
        users: newUsersData,
        isUserCreatedEdited: true
      })

    case actions.SUCCESS_RESET_USER_PASSWORD:
      return Object.assign({}, state, {
        newUserPassword: action.payload.data.new_password,
        isPasswordReseted: true,
      })

    case actions.SET_IS_USER_CREATED_UPDATED:
      return Object.assign({}, state, {
        isUserCreatedEdited: action.payload.flag
      })

    case actions.CLEAN_BUILDINGS_CURRENT_OPTIONS:
      return Object.assign({}, state, {
        buildings_options: []
      })

    case actions.CLEAN_GROUPS_CURRENT_OPTIONS:
      return Object.assign({}, state, {
        groups_options: []
      })

    case actions.ERROR_FETCH_USERS_DATA:
      return Object.assign({}, state, {
        users: [],
        currentUserDetails: null,
        usersFilteredList: null,
        search_filter: "",
        newUserPassword: "",
        isPasswordReseted: false,
        roles: null,
        companies_options: null,
        buildings_options: null,
        groups_options: null,
        deletedUser: false,
        isUserCreatedEdited: false,
        errorCreateEdit: null,
        error: action.payload.error
      })

    case actions.ERROR_REMOVE_USER || actions.ERROR_FETCH_USER:
      return Object.assign({}, state, {
        error: action.payload.error
      })

    case actions.ERROR_CREATE_EDIT_USER:
      return Object.assign({}, state, {
        errorCreateEdit: action.payload.error
      })

    case actions.CLEAN_USER_MANAGMENT_STATE:
      return Object.assign({}, state, {
        users: [],
        currentUserDetails: null,
        usersFilteredList: null,
        search_filter: "",
        roles: null,
        newUserPassword: "",
        isPasswordReseted: false,
        companies_options: null,
        buildings_options: null,
        groups_options: null,
        deletedUser: false,
        isUserCreatedEdited: false,
        errorCreateEdit: null,
        error: null
      })

    case actions.CLEAN_USER_CREATION_EDITION_STATES:
      return Object.assign({}, state, {
        currentUserDetails: null,
        deletedUser: false,
        isUserCreatedEdited: false,
        newUserPassword: "",
        isPasswordReseted: false,
        errorCreateEdit: null,
        error: null
      })

    case actions.CLEAN_USER_RESET_PASSWORD_STATES:
      return Object.assign({}, state, {
        newUserPassword: "",
        isPasswordReseted: false,
      })

    default:
      return state
  }
}

export default userManagment