import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { Button, Tooltip, Form, Input, Row } from 'antd'
import './styles.scss'

const _ControllerInput = ({
  form,
  loading,
  controller,
  handleRemoveController,
  handleOnChangeController
}) => {
  const { getFieldDecorator } = form
  const { aws_id, id } = controller
  const [currentValue, setValue] = useState("")

  const handleOnChange = e => {
        setValue(e.target.value)
        handleOnChangeController(id, e.target.value)
  }

  useEffect(() => {
    if(aws_id){
      form.setFieldsValue({ aws_id })
    }else{
      form.setFieldsValue({ aws_id: currentValue })
    } 
  }, [controller, currentValue])

  return (
    <div>
      <div className="controller-form">
        <div className="controller-form__controller">
          <Row>
            <div className="controller">
              <span className="controller__title">AWS ID</span>
              <Form.Item>
                {getFieldDecorator('aws_id', {
                  rules: [{ required: true, message: 'Missing AWS ID!' }],
                  onChange: handleOnChange,
                })
                  (
                    <Input disabled={loading || !!aws_id } placeholder="AWS ID"/>
                  )}
              </Form.Item>
              {aws_id
                ?
                (
                  <Tooltip title="Remove Controller">
                    <Button
                      type="danger"
                      className="action-btn controller__remove-btn"
                      shape="circle"
                      icon="delete"
                      loading={loading}
                      onClick={() => handleRemoveController(aws_id)}
                    />
                  </Tooltip>
                )
                : ""
              }
            </div>
          </Row>
        </div>
      </div>
    </div>
  )
}

const ControllerInput = Form.create()(_ControllerInput)
export default withRouter(ControllerInput)
