import * as actions from '../constants';
import * as error_codes from '../constants/error_codes'
import moment from 'moment'
import { getStudiedFromSensorData, getStudiedFromAggregatedData } from '../utils/chartData'

const initialState = {
  energyAnalysisSettingsData: null,
  energyChartData: [],
  aggregationExists: true,
  errorFetchEnergyChart: null,
  currentDemandData: [
    { "name": "", "value": 0 },
    { "name": "", "value": 0 }
  ],
  currentDemand: 0,
  currentConsumptionData: [
    { "name": "", "value": 0 },
    { "name": "", "value": 0 }
  ],
  currentConsumption: 0,
  offPeakConsumptionData: [
    { "name": "", "value": 0 },
    { "name": "", "value": 0 }
  ],
  offPeakConsumption: 0,
  midPeakConsumptionData: [
    { "name": "", "value": 0 },
    { "name": "", "value": 0 }
  ],
  midPeakConsumption: 0,
  onPeakConsumptionData: [
    { "name": "", "value": 0 },
    { "name": "", "value": 0 }
  ],
  onPeakConsumption: 0,
  off_peak_cost: 0,
  mid_peak_cost: 0,
  on_peak_cost: 0,
  error: null,
  errorConsumptionData: null,
  invalidPeakNotification: false,
}

const energyAnalysisOverview = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS:
      const fetchResult = Object.assign({}, action.payload.data, {
        off_peak_start: action.payload.data.off_peak_start !== null ? moment(action.payload.data.off_peak_start, "HH:mm:SS").local() : null,
        off_peak_end: action.payload.data.off_peak_end !== null ? moment(action.payload.data.off_peak_end, "HH:mm:ss").local() : null,
        mid_peak_start: action.payload.data.mid_peak_start !== null ? moment(action.payload.data.mid_peak_start, "HH:mm:SS").local() : null,
        mid_peak_end: action.payload.data.mid_peak_end !== null ? moment(action.payload.data.mid_peak_end, "HH:mm:ss").local() : null,
        on_peak_start: action.payload.data.on_peak_start !== null ? moment(action.payload.data.on_peak_start, "HH:mm:SS").local() : null,
        on_peak_end: action.payload.data.on_peak_end !== null ? moment(action.payload.data.on_peak_end, "HH:mm:ss").local() : null,
        consumption_date_span_start: action.payload.data.consumption_date_span_start !== null ? moment(action.payload.data.consumption_date_span_start, "YYYY-MM-DD").local() : null,
        consumption_date_span_end: action.payload.data.consumption_date_span_end !== null ? moment(action.payload.data.consumption_date_span_end, "YYYY-MM-DD").local() : null,
      })

      return Object.assign({}, state, {
        energyAnalysisSettingsData: fetchResult,
        invalidPeakNotification: false,
        error: null
      })

    case actions.SUCCESS_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA:
      const calculate_demand = (action.payload.data[0].consumption * 100) / action.payload.data[0].max_consumption
      const percent_demand = calculate_demand > 100 ? 100 : calculate_demand
      const rest_demand = 100 - percent_demand

      const calculate_consumption = (action.payload.data[1].consumption * 100) / action.payload.data[1].max_consumption
      const percent_consumption = calculate_consumption > 100 ? 100 : calculate_consumption
      const rest_consumption = 100 - percent_consumption

      const calculate_off = (action.payload.data[2].off_peak_consumption * 100) / action.payload.data[2].off_peak_max_consumption
      const percent_off = calculate_off > 100 ? 100 : calculate_off
      const rest_off = 100 - percent_off

      const calculate_mid = (action.payload.data[2].mid_peak_consumption * 100) / action.payload.data[2].mid_peak_max_consumption
      const percent_mid = calculate_mid > 100 ? 100 : calculate_mid
      const rest_mid = 100 - percent_mid

      const calculate_on = (action.payload.data[2].on_peak_consumption * 100) / action.payload.data[2].on_peak_max_consumption
      const percent_on = calculate_on > 100 ? 100 : calculate_on
      const rest_on = 100 - percent_on

      return Object.assign({}, state, {
        currentDemandData: [
          { "name": "consumption", "value": percent_demand },
          { "name": "rest", "value": rest_demand }],
        currentDemand: action.payload.data[0].consumption,
        currentConsumptionData: [
          { "name": "consumption", "value": percent_consumption },
          { "name": "rest", "value": rest_consumption }],
        currentConsumption: action.payload.data[1].consumption,
        offPeakConsumptionData: [
          { "name": "consumption", "value": percent_off },
          { "name": "rest", "value": rest_off }],
        offPeakConsumption: action.payload.data[2].off_peak_consumption,
        midPeakConsumptionData: [
          { "name": "consumption", "value": percent_mid },
          { "name": "rest", "value": rest_mid }],
        midPeakConsumption: action.payload.data[2].mid_peak_consumption,
        onPeakConsumptionData: [
          { "name": "consumption", "value": percent_on },
          { "name": "rest", "value": rest_on }],
        onPeakConsumption: action.payload.data[2].on_peak_consumption,
        off_peak_cost: action.payload.data[2].off_peak_total_cost,
        mid_peak_cost: action.payload.data[2].mid_peak_total_cost,
        on_peak_cost: action.payload.data[2].on_peak_total_cost,
        invalidPeakNotification: false,
        error: null
      })

    case actions.ERROR_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS:
      return Object.assign({}, state, {
        energyAnalysisSettingsData: null,
        invalidPeakNotification: false,
        error: action.payload.error
      })
    case actions.CLEAN_ENERGY_ANALYSIS_SETTINGS_ERROR:
      return Object.assign({}, state, {
        invalidPeakNotification: false,
        error: null,
      })
    case actions.CLEAN_ENERGY_ANALYSIS_SETTINGS_STATE:
      return Object.assign({}, state, {
        error: null,
        invalidPeakNotification: false,
        energyAnalysisSettingsData: null,
      })

    case actions.ERROR_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA:
      let errorOverview = action.payload.error
      let invalidPeakNotification = false
      if (errorOverview.error_code === error_codes.ERR_INVALID_PEAK_RANGE){
        errorOverview = null
        invalidPeakNotification = true
      }

      return Object.assign({}, state, {
        currentDemandData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        currentDemand: 0,
        currentConsumption: 0,
        offPeakConsumption: 0,
        midPeakConsumption: 0,
        onPeakConsumption: 0,
        currentConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        offPeakConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        midPeakConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        onPeakConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        off_peak_cost: 0,
        mid_peak_cost: 0,
        on_peak_cost: 0,
        invalidPeakNotification: invalidPeakNotification,
        errorConsumptionData: errorOverview
      })
    case actions.CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_ERROR:
      return Object.assign({}, state, {
        invalidPeakNotification: false,
        errorConsumptionData: null,
      })
    case actions.SUCCESS_FETCH_CHART_ENERGY_DATA:
      const data = action.payload.data
      const start = action.payload.start
      const end = action.payload.end
      const startLocal = action.payload.startLocal
      const endLocal = action.payload.endLocal
      const currentTime = action.payload.currentTime
      const stepTime = action.payload.stepTime
      let aggregationExists = true
      const tmpData = []

      if (action.payload.requestTime < 24) {
        const sensorName = 'power'

        const dataLength = data ? data.length : 0

        while (currentTime.diff(end) >= 0) {
          let iterationSum = 0
          let iterationEmpty = true
          for (let index = 0; index < dataLength; index++) {
            //console.log('TCL: GroupSensorChart -> end, index', end.format('HH:mm:ss'), index)
            //console.log('TCL: GroupSensorChart -> endLocal ,index', endLocal.format('HH:mm:ss'), index)
            // # This is for every device in response data
            const device = data[index]
            const studiedSensor = getStudiedFromSensorData(
              device.sensor_data,
              sensorName,
              start,
              end,
            )
            iterationSum += studiedSensor.sum
            if (studiedSensor.empty) {

            } else {
              iterationEmpty = false

            }
          }

          if (iterationEmpty === false) {
            tmpData.push({
              time: endLocal.format('HH:mm:ss'),
              watts: parseFloat(iterationSum.toFixed(2)),
            })
          }

          start.add(stepTime, 'minutes')
          end.add(stepTime, 'minutes')
          startLocal.add(stepTime, 'minutes')
          endLocal.add(stepTime, 'minutes')
        }

        tmpData.reverse()
      } else {
        if (data.aggregation_exists) {
          const tmpstart = end.subtract(1, 'hours')
          const tmpend = end
          const tmpStartLocal = endLocal.subtract(1, 'hours')
          const tmpEndLocal = endLocal
          while (tmpend.diff(start) >= 0) {
            const sum = getStudiedFromAggregatedData(
              data.consumption_data,
              tmpstart,
            )

            tmpData.push({
              time: tmpEndLocal.format('YYYY-MM-DD HH:mm:ss'),
              watts: parseFloat(sum.toFixed(2)),
            })


            tmpstart.subtract(1, 'hours')
            tmpend.subtract(1, 'hours')
            tmpStartLocal.subtract(1, 'hours')
            tmpEndLocal.subtract(1, 'hours')
          }
        }else{
          aggregationExists = false
        }
        
      }

      return Object.assign({}, state, {
        energyChartData: tmpData,
        aggregationExists: aggregationExists,
        errorFetchEnergyChart: null,
      })

    case actions.ERROR_FETCH_CHART_ENERGY_DATA:
      return Object.assign({}, state, {
        energyChartData: [],
        aggregationExists: true,
        errorFetchEnergyChart: action.payload.error,
      })

    case actions.CLEAN_CHART_ENERGY_DATA_STATE:
      return Object.assign({}, state, {
        energyChartData: [],
        aggregationExists: true,
        errorFetchEnergyChart: null,
      })

    case actions.CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_STATE:
      return Object.assign({}, state, {
        currentDemandData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        currentDemand: 0,
        currentConsumption: 0,
        offPeakConsumption: 0,
        midPeakConsumption: 0,
        onPeakConsumption: 0,
        currentConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        offPeakConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        midPeakConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        onPeakConsumptionData: [
          { "name": "", "value": 0 },
          { "name": "", "value": 0 }
        ],
        off_peak_cost: 0,
        mid_peak_cost: 0,
        on_peak_cost: 0,
        invalidPeakNotification: false,
        energyConsumptionOverviewData: null,
      })
    default:
      return state
  }
}

export default energyAnalysisOverview