import React from "react";
import { Layout } from "antd";
import Navbar from "../Navbar";
import CenteredRow from "../CenteredRow";

const { Header, Content } = Layout;

const ModuleLayout = ({
  navbarComponent,
  contentComponent,
  direction,
  justify,
  align
}) => {
  return (
    <Layout>
      <Header>
        <Navbar>{navbarComponent}</Navbar>
      </Header>
      <Content>
        <CenteredRow justify={justify} align={align} direction={direction}>
          {contentComponent}
        </CenteredRow>
      </Content>
    </Layout>
  );
};

export default ModuleLayout;
