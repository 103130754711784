import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchElement = (tenant_uudi, uuid, group_type, module) => action(actions.FETCH_ELEMENT, {tenant_uudi, uuid, group_type, module})

export const successFetchElement = data => action(actions.SUCCESS_FETCH_ELEMENT, { data })

export const fetchCompanyTreeData = (tenant_uudi, rolename, user_companies, user_buildings, user_groups ) => action(actions.FETCH_COMPANY_TREE_DATA, {tenant_uudi, rolename, user_companies, user_buildings, user_groups })

export const successFetchCompanyTreeData = (data, synopsisData, modulesData, areaTypes) => action(actions.SUCCESS_FETCH_COMPANY_TREE_DATA, { data, synopsisData, modulesData, areaTypes })

export const deleteElement = (tenant_uuid, uuid, group_type) => action(actions.DELETE_ELEMENT, {tenant_uuid, uuid, group_type})

export const errorFetchElement = error => action(actions.ERROR_FETCH_ELEMENT, { error })

export const handleTreeData = (element, modal_action) => action(actions.HANDLE_TREE_DATA, {element, modal_action})

export const handleTreeDataPosition = (element, modal_action, drag_position, drop_position) => action(actions.HANDLE_TREE_DATA_POSITION, {element, modal_action, drag_position, drop_position})

export const setModalType = type => action(actions.SET_MODAL_TYPE, {type})

export const setClickPosition = position => action(actions.SET_CLICK_POSITION, {position})

export const cleanCompanyManagmentStates = () => action(actions.CLEAN_COMPANY_MANAGMENT_STATES)

export const resetDeleteCompanyElementPopup = () => action(actions.RESET_DELETE_COMPANY_ELEMENT_POPUP)

export const changeElementPositionTreeData = (tenant_uuid, group_uuid, parent_uuid, position) => action(actions.CHANGE_ELEMENT_POSITION_TREE_DATA, { tenant_uuid, group_uuid, parent_uuid, position })

export const updateCurrentElementData = data => action(actions.UPDATE_CURRENT_ELEMENT_DATA, { data })