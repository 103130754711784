import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdateSoundMasking, errorFetchUpdateSoundMasking } from '../actions/soundMaskingActions'
import { fetchGroupSoundMaskingApi, updateGroupSoundMaskingApi } from '../api/soundMaskingApi'
import { toogleSoundMaskingLoader } from '../actions/loaderActions'

export function* getGroupSoundMaskingValues(action) {
    try {
        yield put(toogleSoundMaskingLoader(true))

        const data = yield call(fetchGroupSoundMaskingApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
        )

        //const data = Object.assign({}, sound_masking_data, null)

        data.update = false

        yield put(successFetchUpdateSoundMasking(data))
        yield put(toogleSoundMaskingLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        }
        yield put(toogleSoundMaskingLoader(false))
        yield put(errorFetchUpdateSoundMasking(error))
    }
}

export function* updateGroupSoundMaskingValues(action) {
    try {
        yield put(toogleSoundMaskingLoader(true))

        let sound_masking_data = {}
        const sound_masking_enabled = "sound_masking_enabled" in action.payload.form
        const sound_masking_level = "sound_masking_level" in action.payload.form
        const form = {}

        if ( sound_masking_enabled || sound_masking_level ) {
            
            if(sound_masking_enabled){
                form.sound_masking_enabled = action.payload.form.sound_masking_enabled === "true" ? true : false
            }
            
            if(sound_masking_level){
                form.sound_masking_level = action.payload.form.sound_masking_level
            }

            sound_masking_data = yield call(updateGroupSoundMaskingApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                form
            )
        }

        const data = Object.assign({}, sound_masking_data, {})

        data.update = true

        yield put(successFetchUpdateSoundMasking(data))
        yield put(toogleSoundMaskingLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleSoundMaskingLoader(false))
        yield put(errorFetchUpdateSoundMasking(error))
    }
}

export function* fetchGroupSoundMaskingWatcher() {
    yield takeLatest(actions.FETCH_SOUND_MASKING, getGroupSoundMaskingValues)
}

export function* updateGroupSoundMaskingWatcher() {
    yield takeLeading(actions.UPDATE_SOUND_MASKING, updateGroupSoundMaskingValues)
}