import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchAggregationRequestData, successSaveAggregationRequestSetup, errorFetchUpdateAggregationRequest } from '../actions/aggregationRequestActions'
import { fetchElementAggregationRequestApi, updateElementAggregationRequestApi } from '../api/aggregationRequestApi'
import { toogleAggregationRequestLoader } from '../actions/loaderActions'

export function* getAggregationRequestValues(action) {
    try {
        yield put(toogleAggregationRequestLoader(true))

        const data = yield call(fetchElementAggregationRequestApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.group_type
        )

        yield put(successFetchAggregationRequestData(data))
        yield put(toogleAggregationRequestLoader(false))

    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_COMPANY) {
                error = { "message": "Company was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }

        yield put(toogleAggregationRequestLoader(false))
        yield put(errorFetchUpdateAggregationRequest(error))
    }
}

export function* saveAggregationRequest(action) {
    try {
        yield put(toogleAggregationRequestLoader(true))

        const data = yield call(updateElementAggregationRequestApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.form,
                action.payload.group_type
            )

        yield put(successSaveAggregationRequestSetup(data))
        yield put(toogleAggregationRequestLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_COMPANY) {
                error = { "message": "Company was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }

        yield put(toogleAggregationRequestLoader(false))
        yield put(errorFetchUpdateAggregationRequest(error))
    }
}

export function* fetchAggregationRequestWatcher() {
    yield takeLatest(actions.FETCH_AGGREGATION_REQUEST_DATA, getAggregationRequestValues)
}

export function* saveAggregationRequestWatcher() {
    yield takeLeading(actions.SAVE_AGGREGATION_REQUEST_SETUP, saveAggregationRequest)
}