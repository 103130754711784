import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchAggregationRequestData = (tenant_uuid, uuid, group_type) => action(actions.FETCH_AGGREGATION_REQUEST_DATA, {tenant_uuid, uuid, group_type })

export const successFetchAggregationRequestData = data => action(actions.SUCCESS_FETCH_AGGREGATION_REQUEST_DATA, { data })

export const saveAggregationRequestSetup = (tenant_uuid, uuid, form, group_type) => action(actions.SAVE_AGGREGATION_REQUEST_SETUP, {tenant_uuid, uuid, form, group_type })

export const successSaveAggregationRequestSetup = data  => action(actions.SUCCESS_SAVE_AGGREGATION_REQUEST_SETUP, { data })

export const errorFetchUpdateAggregationRequest = error => action(actions.ERROR_FETCH_UPDATE_AGGREGATION_REQUEST, { error })

export const cleanAggregationRequestState = () => action(actions.CLEAN_AGGREGATION_REQUEST_STATE)
