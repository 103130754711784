import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { Button, Tooltip, Form, Input, Row } from 'antd';
import StyledIcon from "../StyledIcon";
import './styles.scss'

const SearchTreeElement = ({
  treeData,
  handleSearchSelect = (element) => {
    return;
  },
}) => {
  const [list, setList] = useState([])
  const [matchingElement, setMatchingElement] = useState([])
  const [currentElement, setCurrentElement] = useState(0)
  const [totalElement, setTotalElement] = useState(0)

  useEffect(() => {
    const dataList = [];
    if (treeData && treeData !== []) {
      const generateList = data => {
        for (let i = 0; i < data.length; i++) {
          const node = data[i];
          const { uuid, name } = node;
          dataList.push({ key: uuid, title: name });
          if (node.children) {
            generateList(node.children);
          }
        }
      };
      generateList(treeData);
    }
    setList(dataList)
  }, [treeData])

  const handleClickNext = async e => {
    if (currentElement + 1 < totalElement) {
      setCurrentElement(currentElement + 1);
      handleSearchSelect(matchingElement[currentElement + 1].key)
    }
  }

  const handleClickPrevius = async e => {
    if (currentElement > 0) {
      setCurrentElement(currentElement - 1);
      handleSearchSelect(matchingElement[currentElement - 1].key)
    }
  }

  const onChange = async value => {
      setMatchingElement([]);
      setCurrentElement(0);
      setTotalElement(0);
      handleSearchSelect("")
  }

  const handleSerach = async e => {
    if (e.target.value === "") {
      setMatchingElement([]);
      setCurrentElement(0);
      setTotalElement(0);
      handleSearchSelect("")
    } else {

      const tmpmatchingelement = [];
      list.forEach(element => {
        //if(element.title === e.target.value){
        if (element.title.indexOf(e.target.value) > -1) {
          tmpmatchingelement.push(element);
        }
      });
      setMatchingElement(tmpmatchingelement);
      setCurrentElement(0);
      setTotalElement(tmpmatchingelement.length);
      if (tmpmatchingelement.length > 0) {
        handleSearchSelect(tmpmatchingelement[0].key)
      }
    }

  }

  const getSuffix = (
    <>
      {(totalElement > 0 ?
        <>
          <span style={{ fontSize: "12px" }}>{currentElement + 1} of {totalElement} </span>
          <Button
            style={{ width: "20px", height: "20px", lineHeight: "1.3", fontSize: "14px" }}
            type="link"
            onClick={handleClickPrevius}
            icon="up"
          //disabled={!element || element.name === undefined ? true : false}
          ></Button>
          <Button
            style={{ width: "20px", height: "20px", lineHeight: "1.3", fontSize: "14px" }}
            type="link"
            onClick={handleClickNext}
            icon="down"
          //disabled={!element || element.name === undefined ? true : false}
          ></Button>
        </>
        : <></>)}
    </>
  );

  return (
    <>
    <Input 
      prefix={<StyledIcon type="search"/>} 
      suffix={getSuffix} 
      placeholder="Search" 
      className={totalElement>0 ? "input-tree-search buttons-search": "input-tree-search"  }
      onPressEnter={handleSerach}
      onChange={onChange}
      />
    </>
  )
}

export default withRouter(SearchTreeElement);
