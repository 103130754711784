import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Card, Button, message, Modal, Tabs, Icon, Empty, Tooltip } from "antd";
import "./style.css";
import ElementDetailDevicesLocationTab from "../ElementDetailDevicesLocationTab";
import ElementDetailGeneralTab from "../ElementDetailGeneralTab";
import ElementDetailDataTab from "../ElementDetailDataTab";
import ElementDetailChartsTab from "../ElementDetailChartsTab";
import SettingsLightsControlForm from "../ElementDetailLightsControlFormTab"
import ElementDetailAdvancedScheduleForm from "../ElementDetailAdvancedScheduleFormTab";

import { getGroupLabel, enableDeleteCompanyElementButton } from "../../utils/helpers"
import * as actions from '../../constants'

const { TabPane } = Tabs

const ModuleLightsControl = ({
  putToLoad,
  parentTab,
  modalSetters: { setIsModalVisible, setActionType }
}) => {

  const [currentTab, setCurrentTab] = useState(parentTab)
  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const [isLoading, setIsLoading] = useState(false)
  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)
  const managerGroup = useSelector((state) => state.rootReducer.auth.group)

  //let { id_group } = useParams();
  const info_redirect_details = useSelector(state => state.rootReducer.redirect.details_tab)
  const info_redirect_devices = useSelector(state => state.rootReducer.redirect.devices_tab)
  const [elementUuid, setELementUuid] = useState(element ? elementUuid : "")
  const dispatch = useDispatch()

  const getElementType = group_type => {
    let elementType = "";
    switch (group_type) {
      case "Logical":
        elementType = "Logical Group";
        break;
      case "Control":
        elementType = "Control Group";
        break;
      case "Mix":
        elementType = "Mix Group";
        break;
      default:
        elementType = group_type;
        break;
    }
    return elementType;
  }

  const handleClickEdit = () => {
    setActionType("edit")
    dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: element.group_type } })
    setIsModalVisible(true);
  };

  const handleClickSettings = () => {
    setActionType("settings");
    dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: "Settings" } })
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (putToLoad) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  },
    [putToLoad]
  )

  useEffect(() => {
    if (info_redirect_devices.redirect) {
      setCurrentTab("devices_details")
    } else {
      if (element && element.group_type && (element.group_type !== "Company" && element.group_type !== "Building")) {
        setCurrentTab("lights_control")
      } else {
        setCurrentTab("general_details")
      }
    }
  },
    [elementUuid, parentTab]
  )

  useEffect(() => {
    if (element && element.uuid) {
      setELementUuid(element.uuid)
    }
  },
    [element]
  )

  useEffect(() => {
    if (info_redirect_details.redirect) {
      if (currentTab === "devices_details") {
        if (element && element.group_type && (element.group_type !== "Company" && element.group_type !== "Building")) {
          setCurrentTab("lights_control")
        } else {
          setCurrentTab("general_details")
        }
      }
      dispatch(({ type: actions.REDIRECT_DETAILS, payload: { redirect: false } }))
    }

  },
    [info_redirect_details.redirect]
  )

  const handleClickRemove = () => {
    const group_label = getGroupLabel(element.group_type)
    Modal.confirm({
      content: `You are about to delete ${element.group_type} ${group_label}${element.name}. Are you sure?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch({ type: actions.DELETE_ELEMENT, payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, group_type: element.group_type } })
      }
    });
  };

  const handleOnChangeTab = async activeKey => {
    setCurrentTab(activeKey)
  }

  return (
    <Card
      title={
        isLoading
          ? "Loading details..."
          : !element || !element.name
            ? <small>Select an element to see its details</small>
            : <span>{getElementType(element.group_type)} <b>{element.name}</b> Details:</span>
      }
      actions={ <></>/*isLoading || (currentTab && currentTab === "devices_details")
        ? []
        : [
          <Button
            type="primary"
            onClick={handleClickEdit}
            icon="edit"
            disabled={!element || element.name === undefined ? true : false}
          >
            Edit
        </Button>,
          <Button
            type="primary"
            onClick={handleClickSettings}
            icon="control"
            disabled={!element || element.name === undefined || element.group_type === "Company" ? true : false}
          >
            Settings
       </Button>,
          <Button icon="delete" onClick={handleClickRemove} disabled={!element || element.name === undefined || !enableDeleteCompanyElementButton(domainClaims, element, managerGroup) ? true : false}>
            Remove
        </Button>
         
        ]*/}
      extra={isLoading
        ? []
        : [
          <Tooltip title="Edit element" key="tolltip1">
            <Button
              type="link"
              onClick={handleClickEdit}
              icon="edit"
              disabled={!element || element.name === undefined ? true : false}
            >
            </Button>
          </Tooltip>
          ,
          <Tooltip title="Settings" key="tolltip2">
            <Button
              type="link"
              onClick={handleClickSettings}
              icon="control"
              disabled={!element || element.name === undefined || element.group_type === "Company" ? true : false}
            >
            </Button>
          </Tooltip>
          ,
          <Tooltip title="Delete element" key="tolltip3">
            <Button type="link" icon="delete" onClick={handleClickRemove} disabled={!element || element.name === undefined || !enableDeleteCompanyElementButton(domainClaims, element, managerGroup) ? true : false}>
            </Button>
          </Tooltip>
        ]}
    >
      {element && element.name !== undefined
        ? (

          <Tabs defaultActiveKey="lights_control" activeKey={currentTab} onChange={handleOnChangeTab}>
            {
              (element.group_type === "Logical" || element.group_type === "Control" || element.group_type === "Mix") && (

                <TabPane
                  tab={
                    <span>
                      <Icon type="sliders" />
                      Light Control
                                    </span>
                  }
                  key="lights_control"
                >
                  {
                    <SettingsLightsControlForm
                      isModalVisible={true}
                      tabKey={currentTab}
                    />
                  }
                </TabPane>
              )}

              {
              (element.group_type === "Logical" || element.group_type === "Control" || element.group_type === "Mix") && (

                <TabPane
                  tab={
                    <span>
                      <Icon type="calendar" />
                      Schedule
                                    </span>
                  }
                  key="advanced_schedule"
                >
                  {
                    <ElementDetailAdvancedScheduleForm
                      isModalVisible={true}
                      tabKey={currentTab}
                    />
                  }
                </TabPane>
              )}

            {(element.group_type === "Control" || element.group_type === "Mix") && (
              <TabPane
                tab={
                  <span>
                    <Icon type="project" />
                    Data
                      </span>
                }
                key="latest_data"
              >
                {
                  <ElementDetailDataTab
                    tabKey={currentTab}
                  />
                }
              </TabPane>
            )}
            {(element.group_type === "Control" || element.group_type === "Mix") && (
              <TabPane
                tab={
                  <span>
                    <Icon type="area-chart" />
                    Charts
                    </span>
                }
                key="charts_data"
              >
                {
                  <ElementDetailChartsTab
                    tabKey={currentTab}
                  />
                }

              </TabPane>
            )}
            {(element.group_type === "Control" || element.group_type === "Mix" || element.group_type === "Logical") && (
              <TabPane
                tab={
                  <span>
                    <Icon type="environment" theme="filled" />
                    Maps
                      </span>
                }
                key="devices_details">
                <ElementDetailDevicesLocationTab
                  element={element}
                  tabKey={currentTab}
                />
              </TabPane>
            )}

            <TabPane
              tab={
                <span>
                  <Icon type="profile" />
                  Details
                </span>
              }
              key="general_details"
            >
              {
                <ElementDetailGeneralTab
                  element={element}
                  tabKey={currentTab}
                  putToLoad={putToLoad}
                />
              }
            </TabPane>

          </Tabs>
        )
        : <Empty />
      }
    </Card >
  );
};

export default ModuleLightsControl;
