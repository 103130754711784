import {
    getAreaTypesListService,
    getAreaTypeParametersListService,
    postAreaTypeService,
    getAreaTypeDetailsService,
    putAreaTypeService,
    deleteAreaTypeService,
    postAreaTypeParameterValueService,
    deleteAreaTypeParameterValueService,
    postAreaTypeParameterService,
    getAreaTypeParameterDetailsService,
    putAreaTypeParameterService,
    deleteAreaTypeParameterService
 } from "../services"
 
 export const getAreaTypesListApi = async () => {
     const response = await getAreaTypesListService()
     if (response && response.status === 200) {
         let data = await response.data;
         return data
     }
 }
 
 export const getAreaTypeParametersListApi = async () => {
     const response = await getAreaTypeParametersListService()
     if (response && response.status === 200) {
         let data = await response.data;
         return data
     }
 }

 export const createAreaTypeApi = async (form) => {
    const response = await postAreaTypeService(form)
    if (response && response.status === 201) {
        let data = await response.data;
        return data
    }
}

export const getAreaTypeDetailsApi = async (area_type_name) => {
    const response = await getAreaTypeDetailsService(area_type_name)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateAreaTypeApi = async (area_type_name, form) => {
    const response = await putAreaTypeService(area_type_name, form)
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}

export const deleteAreaTypeApi = async (area_type_name) => {
    const response = await deleteAreaTypeService(area_type_name)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const addAreaTypeParameterValueApi = async (area_type_name, form) => {
    const response = await postAreaTypeParameterValueService(area_type_name, form)
    if (response && response.status === 201) {
        let data = await response.data;
        return data
    }
}

export const deleteAreaTypeParameterValueApi = async (area_type_name, parameter_name) => {
    const response = await deleteAreaTypeParameterValueService(area_type_name, parameter_name)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const createAreaTypeParameterApi = async (form) => {
    const response = await postAreaTypeParameterService(form)
    if (response && response.status === 201) {
        let data = await response.data;
        return data
    }
}

export const getAreaTypeParameterDetailsApi = async (area_type_parameter_name) => {
    const response = await getAreaTypeParameterDetailsService(area_type_parameter_name)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateAreaTypeParameterApi = async (area_type_parameter_name, form) => {
    const response = await putAreaTypeParameterService(area_type_parameter_name, form)
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}

export const deleteAreaTypeParameterApi = async (area_type_parameter_name) => {
    const response = await deleteAreaTypeParameterService(area_type_parameter_name)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}
