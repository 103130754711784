import * as actions from '../constants';

const initialState = {
    succesRequestUserPasswordReset: false,
    successChangeUserPasswordToken: false,
    errorRequestPasswordReset: null,
    errorChangetPasswordToken: null,
}

const userPasswordReset = (state = initialState, action) => {
    switch (action.type) {
        case actions.SUCCESS_REQUEST_USER_PASSWORD_RESET:
            return Object.assign({}, state, {
                succesRequestUserPasswordReset: true,
                errorRequestPasswordReset: null
            })
        case actions.SUCCESS_CHANGE_USER_PASSWORD_TOKEN:
            return Object.assign({}, state, {
                successChangeUserPasswordToken: true,
                errorChangetPasswordToken: null
            })
        case actions.ERROR_REQUEST_USER_PASSWORD_RESET:
            return Object.assign({}, state, {
                errorRequestPasswordReset: action.payload.error
            })
        case actions.ERROR_CHANGE_USER_PASSWORD_TOKEN:
            return Object.assign({}, state, {
                errorChangetPasswordToken: action.payload.error
            })
        case actions.CLEAN_USER_RESET_PASSWORD_STATES:
            return Object.assign({}, state, {
                succesRequestUserPasswordReset: false,
                successChangeUserPasswordToken: false,
                errorRequestPasswordReset: null,
                errorChangetPasswordToken: null
            })
        default:
            return state
    }
}

export default userPasswordReset