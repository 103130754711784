import * as actions from '../constants';

const initialState = {
  illuminanceData: null,
  error: null,
}

const illuminance = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_ILLUMINANCE:
      return Object.assign({}, state, {
        illuminanceData: action.payload.data,
        error: null
      })
    case actions.ERROR_FETCH_UPDATE_ILLUMINANCE:
      return Object.assign({}, state, {
        illuminanceData: null,
        error: action.payload.error
      })
    case actions.CLEAN_ILLUMINANCE_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_ILLUMINANCE_STATE:
      return Object.assign({}, state, {
        error: null,
        illuminanceData: null,
      })
    default:
      return state
  }
}

export default illuminance