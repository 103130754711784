import * as actions from '../constants'

const initialState = {
  userProfileData: null,
  updateProfile: false,
  qrCode: null,
  errorFetch2faQr: null,
  errorFetchUpdateProfile: null
}

const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_USER_2FA_QR:
      return Object.assign({}, state, {
        qrCode: action.payload.data.img,
        errorFetch2faQr: null
      })

    case actions.SUCCESS_FETCH_USER_PROFILE:
      return Object.assign({}, state, {
        userProfileData: action.payload.data,
        updateProfile: false,
        errorFetchUpdateProfile: null
      })

    case actions.SUCCESS_UPDATE_USER_PROFILE:
      return Object.assign({}, state, {
        userProfileData: action.payload.data,
        updateProfile: true,
        errorFetchUpdateProfile: null
      })

    case actions.ERROR_FETCH_USER_2FA_QR:
      return Object.assign({}, state, {
        errorFetch2faQr: action.payload.error,
        qrCode: null
      })

    case actions.ERROR_FETCH_UPDATE_USER_PROFILE:
      return Object.assign({}, state, {
        userProfileData: null,
        updateProfile: false,
        errorFetchUpdateProfile: action.payload.error
      })
    case actions.CLEAN_USER_PROFILE_STATE:
        return Object.assign({}, state, {
          userProfileData: null,
          updateProfile: false,
          errorFetchUpdateProfile: null,
          qrCode: null,
          errorFetch2faQr: null
        })
    default:
      return state
  }
}

export default userProfile
