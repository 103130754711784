import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Row, Col, Spin, Notification, Modal } from 'antd'
import { withRouter } from "react-router-dom";
import {
  AreaChart,
  BarChart,
  Bar,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts'
import { UserContext } from "../../context";
import StyledIcon from "../StyledIcon";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import colors from "../../styles";
import { symbol, getTitle } from '../../utils/helpers';

const { primary_color_hover } = colors;

const Option = Select.Option

const { SENSOR_READING_TIME } = window.Configs;


const ElementDetailChartsTab = ({
  history,
  tabKey
}) => {
  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)

  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const chartData = useSelector((state) => state.rootReducer.chartData.chartData)
  const [sensorName, setSensorName] = useState('temperature')
  const [requestTime, setRequestTime] = useState(1)
  const loading = useSelector(state => state.rootReducer.loaders.loadingChartData)
  const error = useSelector((state) => state.rootReducer.chartData.error)

  const dispatch = useDispatch()

  useEffect(() => {
    ; (async () => {
      if (tabKey === "charts_data") {
        dispatch(({
          type: actions.FETCH_CHART_DATA,
          payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, sensor_type: sensorName, request_time: requestTime, step_time: convertRequestTime(requestTime)  }
        }))
      }
    })()
  }, [sensorName, requestTime, tabKey])

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({
                type: actions.FETCH_CHART_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, sensor_type: sensorName, request_time: requestTime, step_time: convertRequestTime(requestTime)   }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_CHART_DATA_ERROR })
        }
      }
    },
    [error]
  )

  const handleTimeChange = value => setRequestTime(value)

  const handleTypeChange = value => setSensorName(value)

  const convertRequestTime = value => {
      let realvalue = SENSOR_READING_TIME;

      while(realvalue + SENSOR_READING_TIME < value){
        realvalue = realvalue + SENSOR_READING_TIME;
      }
      return realvalue;
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" >
          <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
            <p className="label" style={{marginBottom: "0px"}}>{`${label}`}</p>
            <p className="intro" style={{color: "rgb(130, 202, 157)", marginBottom: "0px"}}>{`${getTitle(sensorName)}:  ${payload && payload.length>0 ? payload[0].value : 0}`} {symbol(sensorName)}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Col>
        {sensorName !== 'power' ? (
          <AreaChart
            width={730}
            height={250}
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="time" />
            <YAxis dataKey="reading_average" />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />}/>
            <Area
              type="monotone"
              dataKey="reading_average"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#greenGradient)"
            />
          </AreaChart>
        ) : (
            <BarChart
              width={730}
              height={250}
              data={chartData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis dataKey="watts" />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="watts" fill="#82ca9d" />
            </BarChart>
          )}
      </Col>
      {loading ? (
        <div className="loader">
          <StyledIcon type="loading" color={primary_color_hover} scope="loader" />
        </div>

      ) : (
          <Row>
            <Col xs={10}>
              <div>
                <span style={{ marginRight: '5px', fontWeight: 600 }}>
                  Time Mark:{' '}
                </span>
                <Select
                  defaultValue={`${requestTime}`}
                  style={{ width: 140 }}
                  onChange={handleTimeChange}
                >
                  <Option value="1">Last hour</Option>
                  <Option value="6">Last 6 hours</Option>
                  <Option value="12">Last 12 hours</Option>
                </Select>
              </div>
            </Col>
            <Col xs={10}>
              <div>
                <span style={{ marginRight: '5px', fontWeight: 600 }}>
                  Sensor Name:{' '}
                </span>
                <Select
                  defaultValue={sensorName}
                  style={{ width: 120 }}
                  onChange={handleTypeChange}
                >
                  <Option value="temperature">Temperature</Option>
                  <Option value="pressure">Pressure</Option>
                  <Option value="humidity">Humidity</Option>
                  <Option value="power">Power</Option>
                  <Option value="co2">CO2</Option>
                  <Option value="voc">VOC</Option>
                </Select>
              </div>
            </Col>
          </Row>
        )}
    </>

  )
}

export default withRouter(ElementDetailChartsTab)
