import {
    getGroupDevicesService,
    postDevicesService,
    deleteDeviceService,
} from "../services";

export const fetchDevicesApi = async (tenant_uuid, uuid) => {

    const response = await getGroupDevicesService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}

export const addDevicesApi = async (tenant_uuid, data) => {

    let result = []
    for(let i = 0; i < data.length; i++){
        const form = { aws_id: data[i].aws_id, group_uuid: data[i].group_uuid }
        const response = await postDevicesService(tenant_uuid, form);
        if (response && response.status === 201) {
            let data_result = await response.data
            result.push(data_result)
        }
    }

    return result
}

export const removeDeviceApi = async (tenant_uuid, aws_id) => {
    const response = await deleteDeviceService(tenant_uuid, aws_id)
    if (response && response.status === 200) {
        return true
    }else {
        return false
    }
}