import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdateLightsLevelOn, errorFetchUpdateLightsLevelOn } from '../actions/lightsControlActions'
import { updateCurrentElementData } from "../actions/companyManagmentActions"
import { fetchGroupLevelsOverrideApi, fetchGroupOnDetectOffOverrideApi, updateGroupLevelsOverrideApi, updateOnOverrideApi } from '../api/lightsControlApi'
import { toogleLightsControlLoader } from '../actions/loaderActions'

export function* getGroupLevelsOnValues(action) {
    try {
        yield put(toogleLightsControlLoader(true))

        const levels_data = yield call(fetchGroupLevelsOverrideApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
        )

        const on_data = yield call(fetchGroupOnDetectOffOverrideApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
        )

        const data = Object.assign({}, levels_data, on_data)

        data.update = false

        yield put(successFetchUpdateLightsLevelOn(data))
        yield put(toogleLightsControlLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        }
        yield put(toogleLightsControlLoader(false))
        yield put(errorFetchUpdateLightsLevelOn(error))
    }
}

export function* updateGroupLevelsOnValues(action) {
    try {
        yield put(toogleLightsControlLoader(true))

        let levels_data = {}
        let on_data = {}
        const slider_level_in_payload = "slider_level" in action.payload.form
        const warm_white_level_in_payload = "warm_white_level" in action.payload.form
        const on_in_payload = "on" in action.payload.form
        const shutdown_in_payload = "shutdown" in action.payload.form
        const form = {}
        const on_form = {}


        if ( slider_level_in_payload || warm_white_level_in_payload) {
            
            if(slider_level_in_payload){
                form.slider_level = action.payload.form.slider_level
            }

            if(warm_white_level_in_payload){
                form.white_level = action.payload.form.white_level
                form.warm_white_level = action.payload.form.warm_white_level
            }

            levels_data = yield call(updateGroupLevelsOverrideApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                form
            )
        }

        if (on_in_payload) {
            on_form.on = action.payload.form.on
            if(shutdown_in_payload){
                on_form.shutdown = action.payload.form.shutdown
            }
            on_data = yield call(updateOnOverrideApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                on_form
            )

            yield put(updateCurrentElementData(on_data))
        }

        const data = Object.assign({}, levels_data, on_data)

        data.update = true

        yield put(successFetchUpdateLightsLevelOn(data))
        yield put(toogleLightsControlLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleLightsControlLoader(false))
        yield put(errorFetchUpdateLightsLevelOn(error))
    }
}

export function* fetchGroupLevelsOnWatcher() {
    yield takeLatest(actions.FETCH_LIGHTS_LEVELS_ON, getGroupLevelsOnValues)
}

export function* updateGroupLevelsOnWatcher() {
    yield takeLeading(actions.UPDATE_LIGHTS_LEVELS_ON, updateGroupLevelsOnValues)
}