import { all } from 'redux-saga/effects'

import * as watchersAuth from './authSaga'
import * as watchersCompanyManagment from './companyManagmentSaga'
import * as watchersEditCreateElement from './editCreateElementSaga'
import * as watchersLightsControl from './lightsControlSaga'
import * as watchersIlluminance from './illuminanceSaga'
import * as watchersLatestData from './latestDataSaga'
import * as watchersChartData from './chartDataSaga'
import * as watchersControllers from './controllersSaga'
import * as watchersPowerCalibration from './powerCalibrationSaga'
import * as watchersAggregationRequest from './aggregationRequestSaga'
import * as watchersUserManagment from './userManagmentSaga'
import * as watchersUserPasswordReset from './userPasswordResetSaga'
import * as watchersPeopleAnalytics from './peopleAnalyticsParamsSaga'
import * as watchersUVControl from './UVControlSaga'
import * as watchersAreaTypeParameterWatchers from './areaTypeParametersSaga'
import * as watchersNotification from './notificationSaga'
import * as watchersUserNotification from './notificationUserSaga'
import * as watchersBlueLightBoost from './blueLightBoostSaga'
import * as watchersUserProfile from './userProfileSaga'
import * as watchersAdvancedSchedule from './advancedScheduleSaga'
import * as watchersSoundMasking from './soundMaskingSaga'
import * as watchersEnergyAnalysisOverview from './energyAnalysisOverviewSaga'
import * as watchersCircadianRhythm from './circadianRhythmSaga'

export default function* rootSaga() {
  yield all([
    // auth watchers
    watchersAuth.loginWatcher(),
    watchersAuth.login2faSecondStepWatcher(),
    // user reset password watchers
    watchersUserPasswordReset.requestPasswordResetWatcher(),
    watchersUserPasswordReset.changePasswordTokenWatcher(),
    // user managment watchers
    watchersUserManagment.fetchUsersDataWatcher(),
    watchersUserManagment.removeUserWatcher(),
    watchersUserManagment.fetchUserDetailWatcher(),
    watchersUserManagment.fetchUserCompaniesWatcher(),
    watchersUserManagment.fetchUserCompanyBuildingsWatcher(),
    watchersUserManagment.fetchUserBuildingGroupsWatcher(),
    watchersUserManagment.fetchUserGroupChildrenWatcher(),
    watchersUserManagment.createUserWatcher(),
    watchersUserManagment.updateUserWatcher(),
    watchersUserManagment.resetUserPasswordWatcher(),
    // user profile watchers
    watchersUserProfile.fetchUser2faQrWatcher(),
    watchersUserProfile.fetchUserProfileWatcher(),
    watchersUserProfile.updateUserProfileWatcher(),
    // company managment watchers
    watchersCompanyManagment.fetchElementDetailsWatcher(),
    watchersCompanyManagment.fetchCompanyTreeDataWatcher(),
    watchersCompanyManagment.deleteElementWatcher(),
    watchersCompanyManagment.changeElementPositionWatcher(),
    
    watchersEditCreateElement.createElementWatcher(),
    watchersEditCreateElement.editElementWatcher(),
    
    // lights control watchers
    watchersLightsControl.fetchGroupLevelsOnWatcher(),
    watchersLightsControl.updateGroupLevelsOnWatcher(),
    // illuminance watchers
    watchersIlluminance.fetchIlluminaceWatcher(),
    watchersIlluminance.updateIlluminacenWatcher(),
    // latest data watchers
    watchersLatestData.fetchLatestDataWatcher(),
    // chart data watchers
    watchersChartData.fetchChartDataWatcher(),
    // controllers watchers
    watchersControllers.addControllersWatcher(),
    watchersControllers.removeControllerWatcher(),
    watchersControllers.fetchControllersWatcher(),
    // power calibration controllers
    watchersPowerCalibration.fetchPowerCalibrationWatcher(),
    watchersPowerCalibration.savePowerCalibrationWatcher(),
    // aggregation request
    watchersAggregationRequest.fetchAggregationRequestWatcher(),
    watchersAggregationRequest.saveAggregationRequestWatcher(),
    // people analytics
    watchersPeopleAnalytics.fetchPeopleAnalyticsWatcher(),
    watchersPeopleAnalytics.updatePeopleAnalyticsWatcher(),
    // uv control
    watchersUVControl.fetchGroupUVControlWatcher(),
    watchersUVControl.updateGroupUVControlWatcher(),
    // advanced schedule
    watchersAdvancedSchedule.fetchGroupAdvancedScheduleWatcher(),
    watchersAdvancedSchedule.updateGroupAdvancedScheduleWatcher(),
    // area type parameters
    watchersAreaTypeParameterWatchers.fetchAreaTypesListWatcher(),
    watchersAreaTypeParameterWatchers.fetchAreaTypeParametersListWatcher(),
    watchersAreaTypeParameterWatchers.fetchAreaTypeDetailsWatcher(),
    watchersAreaTypeParameterWatchers.fetchAreaTypeParameterDetailsWatcher(),
    watchersAreaTypeParameterWatchers.createAreaTypeWatcher(),
    watchersAreaTypeParameterWatchers.createAreaTypeParameterWatcher(),
    watchersAreaTypeParameterWatchers.updateAreaTypeWatcher(),
    watchersAreaTypeParameterWatchers.updateAreaTypeParameterWatcher(),
    watchersAreaTypeParameterWatchers.removeAreaTypeWatcher(),
    watchersAreaTypeParameterWatchers.removeAreaTypeParameterWatcher(),
    watchersAreaTypeParameterWatchers.addAreaTypeParameterValueWatcher(),
    watchersAreaTypeParameterWatchers.removeAreaTypeParameterValueWatcher(),
    //notification
    watchersNotification.fetchNotificationsWatcher(),
    watchersUserNotification.fetchUserNotificationsWatcher(),

    watchersNotification.fetchSendNotificationsWatcher(),
    watchersUserNotification.fetchUserSendNotificationsWatcher(),

    watchersNotification.createNotificationWatcher(),

    watchersUserNotification.fetchNewNotificationsWatcher(),
    
    watchersUserNotification.updateToastNotificationsWatcher(),
    watchersUserNotification.fetchElementNotificationsWatcher(),
    // blue light boost 
    watchersBlueLightBoost.fetchBlueLightBoostWatcher(),
    watchersBlueLightBoost.activateDeactivateBlueLightBoostWatcher(),

    //sound masking
    watchersSoundMasking.fetchGroupSoundMaskingWatcher(),
    watchersSoundMasking.updateGroupSoundMaskingWatcher(),
    // energy analysis overview
    watchersEnergyAnalysisOverview.fetchEnergyAnalysisSettingsWatcher(),
    watchersEnergyAnalysisOverview.updateEnergyAnalysisSettingsWatcher(),
    watchersEnergyAnalysisOverview.fetchEnergyConsumptionOverviewWatcher(),
    watchersEnergyAnalysisOverview.fetchChartEnergyDemandDataWatcher(),

    // circadian rhythm
    watchersCircadianRhythm.fetchCircadianRhythmWatcher(),
    watchersCircadianRhythm.updateCircadianRhythmWatcher()
  ])
}