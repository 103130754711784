import * as actions from '../constants';

const initialState = {
  sensorData: [],
  controllers: [],
  filteredIds: [],
  error: null,
}

const latestData = (state = initialState, action) => {
  let ids = []
  let controllers = []
  switch (action.type) {
    case actions.SUCCESS_FETCH_LATEST_DATA:
      ids = state.filteredIds
      controllers = ids.length > 0
            ? action.payload.data.filter(c => c.aws_id && ids.includes(c.aws_id))
            : action.payload.data.filter(c => c.aws_id)
      return Object.assign({}, state, {
        sensorData: action.payload.data,
        controllers: controllers,
        error: null
      })
    case actions.FILTER_SENSOR_DATA:
     ids = action.payload.ids
     controllers = ids.length > 0
          ? state.sensorData.filter(c => c.aws_id && ids.includes(c.aws_id))
          : state.sensorData.filter(c => c.aws_id)
      return Object.assign({}, state, {
        controllers: controllers,
        filteredIds: ids,
        error: null
      })
    case actions.ERROR_FETCH_LATEST_DATA:
      return Object.assign({}, state, {
        sensorData: [],
        error: action.payload.error
      })
    case actions.CLEAN_LATEST_DATA_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_LATEST_DATA_STATE:
      return Object.assign({}, state, {
        error: null,
        sensorData: [],
        controllers: [],
        filteredIds: [],
      })
    default:
      return state
  }
}

export default latestData