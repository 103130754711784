import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, message, Select, Modal, Notification, Row, Empty } from "antd"
import { withRouter } from "react-router-dom"

import * as error_codes from '../../constants/error_codes'
import * as actions from '../../constants'
import "./styles.css"

import colors from "../../styles";
import CenteredRow from "../CenteredRow";

import AreaTypeManagmentAreaTypeParameterValueForm from "../AreaTypeManagmentAreaTypeParameterValueForm"

const { darkIconColor } = colors;

const Option = Select.Option;
const FormItem = Form.Item;
const TextArea = Input.TextArea

const AreaTypeManagmentAreaTypeForm = ({ form, history, type, area_type_name, setIsModalVisible, isModalVisible }) => {
  const isLoading = useSelector(state => state.rootReducer.loaders.loadingAreaTypeEdition)
  const isReady = !useSelector(state => state.rootReducer.loaders.loadingAreaTypeEdition)
  const areaTypeDetails = useSelector(state => state.rootReducer.areaTypeParameters.currentAreaTypeDetails)
  const isAreaTypeCreatedEdited = useSelector(state => state.rootReducer.areaTypeParameters.isAreaTypeCreatedEdited)
  const isAreaTypeParameterValueCreatedEdited = useSelector(state => state.rootReducer.areaTypeParameters.isAreaTypeParameterValueCreatedEdited)
  const deletedAreaTypeParameterValue = useSelector(state => state.rootReducer.areaTypeParameters.deletedAreaTypeParameterValue)
  const error = useSelector((state) => state.rootReducer.areaTypeParameters.errorCreateEditAreaType)
  const areaTypeParameters = useSelector(state => state.rootReducer.areaTypeParameters.currentAreaTypeAllowedParameters)
  const disableAddParameterButton = useSelector(state => state.rootReducer.areaTypeParameters.disableAddParameterButton)

  const { getFieldDecorator, validateFields, resetFields, isFieldTouched, setFieldsValue } = form
  const [update, setUpdate] = useState(type === "update")

  const dispatch = useDispatch()

  useEffect(
    () => {
      //resetFields()
      if (update) {
        dispatch(({ type: actions.FETCH_AREA_TYPE_PARAMETERS_LIST }))
        dispatch(({ type: actions.FETCH_AREA_TYPE, payload: { area_type_name } }))
      }
    },
    [area_type_name]
  )

  useEffect(
    () => {
      if (!isModalVisible) {
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
      }
    },
    [isModalVisible]
  )

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { label, description } = values;
        const form = {}
        let update_form = {}

        if (label && label.length) {
          form.label = label;
          if (update && isFieldTouched("label")) {
            update_form.label = form.label
          }
        }
        if (description && description.length) {
          form.description = description
          update_form.description = description
        }

        if (!update) {
          dispatch(({
            type: actions.CREATE_AREA_TYPE,
            payload: {
              form
            }
          }))
        } else {
          dispatch(({
            type: actions.EDIT_AREA_TYPE,
            payload: {
              area_type_name: areaTypeDetails.name,
              form: update_form
            }
          }))
        }
        setUpdate(true)
        dispatch(({ type: actions.FETCH_AREA_TYPE_PARAMETERS_LIST }))
      } else {
        message.error(err);
      }
    })
  }

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
              setIsModalVisible(false)
              dispatch(({ type: actions.FETCH_AREA_TYPES_LIST }))
              dispatch(({ type: actions.FETCH_AREA_TYPE_PARAMETERS_LIST }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (isAreaTypeCreatedEdited) {
        message.success(update ? `Area type successfully updated` : `Area type parameter added`)
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
        if(update){
          resetFields()
        }
      }
    },
    [isAreaTypeCreatedEdited]
  )

  useEffect(
    () => {
      if (isAreaTypeParameterValueCreatedEdited) {
        message.success(`Area type value successfully updated`)
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
      }
    },
    [isAreaTypeParameterValueCreatedEdited]
  )

  useEffect(
    () => {
      if (deletedAreaTypeParameterValue) {
        message.success(`Area type value successfully deleted`)
        dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
      }
    },
    [deletedAreaTypeParameterValue]
  )

  const handleCancel = (e) => {
    dispatch(({ type: actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES }))
    setIsModalVisible(false)
  }

  const handleAddParameter = (e) => {
    dispatch(({ type: actions.ADD_AREA_TYPE_PARAMETER_VALUE_EMPTY_FORM }))
  }

  return (
    <div>
      <CenteredRow>
        <Form onSubmit={handleSubmit}>
          <FormItem label="Parameter name">
            {getFieldDecorator("label", {
              rules: [{ required: !update, message: "Name is required" }],
              initialValue: update ? areaTypeDetails && areaTypeDetails.label : ""
            })(
              <Input
                disabled={!isReady}
                placeholder="Area type name"
              />
            )}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description", {
              initialValue: update ? areaTypeDetails && areaTypeDetails.description
                : ""
            })(
              <TextArea
                disabled={!isReady}
                placeholder="Area description"
              />
            )}
          </FormItem>

          <FormItem style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "40%" }}
              size="large"
              icon={update ? "upload" : "plus"}
              loading={isLoading}
            >
              {update ? " Save" : " Add Area Type"}
            </Button>
          </FormItem>
        </Form>
      </CenteredRow>
      {update && (
        <div>
          <hr />
          <Row justify="center" align="middle" type="flex">
            <h3>Parameters values:</h3>
          </Row>
          <br/>

          {areaTypeDetails ? areaTypeDetails.parameters_values.map(parameter_value => (
            <AreaTypeManagmentAreaTypeParameterValueForm
              type={parameter_value.create ? "add" : "update"}
              area_type_parameter_value_data={parameter_value}
            />
          ))
          :
          areaTypeDetails && areaTypeDetails.parameters_values.length === 0 ? (<Empty/>) : <></>
          }
          <br />
          <Button
            type="primary"
            style={{ width: "40%" }}
            disabled={(areaTypeParameters && areaTypeParameters.length === 0 && areaTypeDetails && areaTypeDetails.parameters_values.length !== 0) || disableAddParameterButton}
            loading={isLoading}
            onClick={handleAddParameter}
          >
            Add Parameter Value
            </Button>
        </div>
      )}

      <CenteredRow>
        <Button
          type="default"
          style={{ width: "50%" }}
          htmlType="button"
          onClick={handleCancel}
          icon={"close"}
          size="large"
        >
          Cancel
          </Button>
      </CenteredRow>
    </div>
  )
};

const WrappedAreaTypeManagmentAreaTypeForm = Form.create()(AreaTypeManagmentAreaTypeForm);

export default withRouter(WrappedAreaTypeManagmentAreaTypeForm);
