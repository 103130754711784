import {
    getGroupPowerCalibrationService,
    putGroupPowerCalibrationService
} from "../services";

export const fetchPowerCalibrationApi = async (tenant_uuid, uuid) => {
    const response = await getGroupPowerCalibrationService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updatePowerCalibrationApi = async (tenant_uuid, uuid, form) => {
    const response = await putGroupPowerCalibrationService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}