import * as actions from '../constants'
import moment from 'moment'

const initialState = {
  aggregationRequestData: null,
  update: false,
  error: null,
}

const aggregationRequest = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_AGGREGATION_REQUEST_DATA:
      let aggregationRequestData = action.payload.data
      if ("aggregation_starting_date" in aggregationRequestData) {
        aggregationRequestData = Object.assign({}, action.payload.data, {
          aggregation_starting_date: moment.utc(action.payload.data.aggregation_starting_date, 'YYYY-MM-DD')
        })
      }

      return Object.assign({}, state, {
        aggregationRequestData: aggregationRequestData,
        update: false,
        error: null
      })

    case actions.SUCCESS_SAVE_AGGREGATION_REQUEST_SETUP:
      let aggregationRequestSavedData = action.payload.data
      if ("aggregation_starting_date" in aggregationRequestSavedData) {
        aggregationRequestSavedData = Object.assign({}, action.payload.data, {
          aggregation_starting_date: moment.utc(action.payload.data.aggregation_starting_date, 'YYYY-MM-DD')
        })
      }

      return Object.assign({}, state, {
        aggregationRequestData: aggregationRequestSavedData,
        update: true,
        error: null
      })

    case actions.ERROR_FETCH_UPDATE_AGGREGATION_REQUEST:
      return Object.assign({}, state, {
        aggregationRequestData: null,
        update: false,
        error: action.payload.error
      })

    case actions.CLEAN_AGGREGATION_REQUEST_ERROR:
      return Object.assign({}, state, {
        error: null,
        update: false,
      })

    case actions.CLEAN_AGGREGATION_REQUEST_STATE:
      return Object.assign({}, state, {
        error: null,
        aggregationRequestData: null,
        update: false,
      })

    default:
      return state
  }
}

export default aggregationRequest