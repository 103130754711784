import React, { useEffect } from "react"
import { Form, Input, Button, message, Card, Icon, Row, Col, Collapse } from "antd"
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link } from "react-router-dom"
import User2faQr from "../../components/User2faQr"

import "./styles.css"

import * as actions from '../../constants'
import colors from "../../styles";
const { darkIconColor } = colors;
const { Panel } = Collapse

const FormItem = Form.Item;

const Login2faSecondStepForm = ({
  form,
  history }) => {
  const loading = useSelector((state) => state.rootReducer.loaders.loadingLogin)
  const error = useSelector((state) => state.rootReducer.auth.error)
  const username = useSelector((state) => state.rootReducer.auth.username)
  const is_auth = useSelector((state) => state.rootReducer.auth.is_auth)
  const dispatch = useDispatch()

  const { getFieldDecorator, validateFields, resetFields } = form;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { verification_code } = values
        dispatch(({ type: actions.SECOND_STEP_2FA, payload: { username, verification_code } }))
      } else {
        message.error(err);
      }
    })
  }

  useEffect(
    () => {
      if (error !== null) {
        message.error(error.message)
        dispatch(({ type: actions.CLEAN_2FA_SECOND_STEP_STATE }))
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (is_auth) {
        resetFields()
        history.push("/")
      }
    },
    [is_auth]
  )

  const handleOnClick = e => {
    dispatch(({ type: actions.CLEAN_2FA_SECOND_STEP_STATE }))
  }

  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh", backgroundColor: "#fff" }}
      >
        <Col xs={20} sm={14} md={12} lg={10} xl={7}>
          <Card>
            <h3>Input your Google Authenticator verification code:</h3>
            <Form onSubmit={handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator('verification_code', {
                  rules: [{ required: true, message: 'Please input your verification code!' }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Verification code"
                  />,
                )}
              </FormItem>
              <FormItem style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Authenticate
          </Button>
              </FormItem>
            </Form>

            <br />
            <Collapse ghost>
              <Panel header="You don't have register this site on your Google Authenticator?, click here!!!" key="1">
                <User2faQr isLogin={true} />
              </Panel>
            </Collapse>

            <Row
              type="flex"
              justify="center"
              align="middle">
              <Link to="/login">
                <Button
                  onClick={handleOnClick}
                  type="default"
                  style={{ width: "100%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Cancel
          </Button>
              </Link>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

const WrappedLogin2faSecondStepForm = Form.create()(Login2faSecondStepForm);

export default withRouter(WrappedLogin2faSecondStepForm)