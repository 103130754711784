import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Row, Col, Alert, Notification, Modal } from 'antd'
import { withRouter } from "react-router-dom";
import {
  AreaChart,
  BarChart,
  Bar,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts'
import { UserContext } from "../../context";
import StyledIcon from "../StyledIcon";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import colors from "../../styles";
import { symbol, getTitle } from '../../utils/helpers';

const { primary_color_hover } = colors;

const Option = Select.Option

const { SENSOR_READING_TIME } = window.Configs;


const ElementDetailEnergyAnalysisDemandChartTab = ({
  history,
  tabKey
}) => {
  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)

  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const chartData = useSelector((state) => state.rootReducer.energyAnalysisOverview.energyChartData)
  const aggregationExists = useSelector((state) => state.rootReducer.energyAnalysisOverview.aggregationExists)
  const [requestTime, setRequestTime] = useState(1)
  const loading = useSelector(state => state.rootReducer.loaders.loadingEnergyChart)
  const error = useSelector((state) => state.rootReducer.energyAnalysisOverview.errorFetchEnergyChart)

  const dispatch = useDispatch()

  useEffect(() => {
    ; (async () => {
      if (tabKey === "charts_energy_demand") {
        dispatch(({
          type: actions.FETCH_CHART_ENERGY_DATA,
          payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, request_time: requestTime, step_time: convertRequestTime(requestTime) }
        }))
      }
    })()
  }, [requestTime, tabKey])

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({
                type: actions.FETCH_CHART_ENERGY_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, request_time: requestTime, step_time: convertRequestTime(requestTime) }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_CHART_ENERGY_DATA_ERROR })
        }
      }
    },
    [error]
  )

  const handleTimeChange = value => setRequestTime(value)

  const convertRequestTime = value => {
    let realvalue = SENSOR_READING_TIME;

    while (realvalue + SENSOR_READING_TIME < value) {
      realvalue = realvalue + SENSOR_READING_TIME;
    }
    return realvalue;
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" >
          <div className="recharts-default-tooltip" style={{ margin: "0px", padding: "10px", backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap" }}>
            <p className="label" style={{ marginBottom: "0px" }}>{`${label}`}</p>
            <p className="intro" style={{ color: "rgb(130, 202, 157)", marginBottom: "0px" }}>{`Demand:  ${payload && payload.length > 0 ? payload[0].value : 0}`} {requestTime > 12 ? "KWh" : "Wh"}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Col>
        <BarChart
          width={730}
          height={250}
          data={chartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis dataKey="watts" />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="watts" fill="#82ca9d" />
        </BarChart>
        {!aggregationExists && requestTime > 12 && (
          <>
            <Alert
              message="No aggregation"
              description="Do not exists aggregation for this time interval."
              type="error"
              showIcon
            />
            <br />
          </>
        )}

      </Col>
      {loading ? (
        <div className="loader">
          <StyledIcon type="loading" color={primary_color_hover} scope="loader" />
        </div>

      ) : (
          <Row>
            <Col xs={10}>
              <div>
                <span style={{ marginRight: '5px', fontWeight: 600 }}>
                  Time Mark:{' '}
                </span>
                <Select
                  defaultValue={`${requestTime}`}
                  style={{ width: 140 }}
                  onChange={handleTimeChange}
                >
                  <Option value="1">Last hour</Option>
                  <Option value="6">Last 6 hours</Option>
                  <Option value="12">Last 12 hours</Option>
                  <Option value="24">Yesterday</Option>
                  <Option value="48">Last two days</Option>
                  <Option value="72">Last three days</Option>
                  <Option value="96">Last four days</Option>
                  <Option value="120">Last five days</Option>
                </Select>
              </div>
            </Col>
          </Row>
        )}
    </>

  )
}

export default withRouter(ElementDetailEnergyAnalysisDemandChartTab)
