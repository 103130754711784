import {
    all,
    call,
    put,
    takeLatest,
    takeLeading
} from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'

import {
    toogleRequestPasswordResetLoader,
    toogleChangePasswordTokenLoaders
} from '../actions/loaderActions'

import {
    successRequestUserPasswordReset,
    successChangeUserPasswordToken,
} from '../actions/userPasswordResetActions'

import {
    errorRequestUserPasswordReset,
    errorChangeUserPasswordToken
} from '../actions/userPasswordResetActions'

import {
    requestPasswordResetApi,
    changeUserPasswordApi
}
from '../api/userPasswordResetApi'


export function* requestPasswordReset(action) {
    try {
        yield put(toogleRequestPasswordResetLoader(true))

        const data = yield call(requestPasswordResetApi, {username: action.payload.username} )

        yield put(successRequestUserPasswordReset(data))
        yield put(toogleRequestPasswordResetLoader(false))
    } catch (err) {
        let error = {}
        if (err.message.includes("404")) {
            error = {
                "message": "Invalid user email",
                "error_code": error_codes.ERR_INVALID_USER
            };
         } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleRequestPasswordResetLoader(false))
        yield put(errorRequestUserPasswordReset(error))
    }
}

export function* changeUserPasswordToken(action) {
    try {
        yield put(toogleChangePasswordTokenLoaders(true))

        const data = yield call(changeUserPasswordApi, action.payload.form)

        yield put(successChangeUserPasswordToken(data))
        yield put(toogleChangePasswordTokenLoaders(false))
    } catch (err) {
        let error = {}
        if (err.message.includes("404")) {
            error = {
                "message": "Invalid user",
                "error_code": error_codes.ERR_INVALID_USER
            };
         } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleChangePasswordTokenLoaders(false))
        yield put(errorChangeUserPasswordToken(error))
    }
}

export function* requestPasswordResetWatcher() {
    yield takeLatest(actions.REQUEST_USER_PASSWORD_RESET, requestPasswordReset)
}

export function* changePasswordTokenWatcher() {
    yield takeLatest(actions.CHANGE_USER_PASSWORD_TOKEN, changeUserPasswordToken)
}