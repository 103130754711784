import React, { useState, useEffect } from 'react';
import Heatmap from './heatmap';

let HEATMAP_CANVAS_WIDTH = 500;
let HEATMAP_CANVAS_HEIGTH = 500;

const Map = ({
  datadevice,
  renderMap,
  radius,
  heatmap_color
}) => {
  const [simpleHeatRef, ] = useState(React.createRef());
  const [maxOccurances, ] = useState(1); 
  const [blurValue, setBlurValue] = useState(radius);
  const [radiusValue, setRadiusValue ] = useState(radius);
  const [heatmapColor, setHeatmapColor ] = useState(heatmap_color);
  const [data, setData] = useState(datadevice);

  useEffect(() => {
      setData(datadevice);
      setBlurValue(radius);
      setRadiusValue(radius);
      setHeatmapColor(heatmap_color);
  },
    [datadevice, radius, heatmap_color])

    /*
    useEffect(() => {
      forceUpdate();
    },
      [data])
      */


  const render = () => {
    //console.log('App.render() called');

    return (
      <div className="app" style={{ position: "absolute", top: "0px", visibility: (renderMap?"visible":"hidden")}}>
        <Heatmap
          ref={simpleHeatRef}
          width={HEATMAP_CANVAS_WIDTH}
          height={HEATMAP_CANVAS_HEIGTH}
          maxOccurances={maxOccurances}
          blur={blurValue}
          radius={radiusValue}
          // uncomment to send real data in
          heatmap_color = {heatmapColor}
          data={data}
        />
      </div>
    );
  }

  return (
    render()
  )
}

Map.propTypes = {
  // authenticated: PropTypes.bool,
  // history: PropTypes.func
};

export default Map;
