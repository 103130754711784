import { call, put, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successCreateEditElement, errorCreateEditElement } from '../actions/createEditElementActions'
import { handleTreeData } from '../actions/companyManagmentActions'
import { createElementApi, editElementApi, editElementContactApi, editCompanyModulesApi } from '../api/createEditElementApi'
import { fetchLightsLevelOn } from '../actions/lightsControlActions'
import { toogleCreateEditElementLoader } from '../actions/loaderActions'

export function* createElement(action) {
    try {
        yield put(toogleCreateEditElementLoader(true))

        const data = yield call(createElementApi,
            action.payload.tenant_uuid,
            action.payload.form,
            action.payload.group_type
        )
        
        yield put(handleTreeData(data, "add"))
        yield put(successCreateEditElement(data))
        yield put(toogleCreateEditElementLoader(false))

    } catch (err) {
        let error = {}
        // console.log(err)
        if (err.message.includes("400")) {
            error = err.response.data
        } else if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN }
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED }
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_COMPANY) {
                error = { "message": "Company was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("422")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        }

        yield put(toogleCreateEditElementLoader(false))
        yield put(errorCreateEditElement(error))
    }
}

export function* editElement(action) {
    try {
        yield put(toogleCreateEditElementLoader(true))

        const data = yield call(editElementApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.element_form,
            action.payload.group_type
        )
        
        let modules_data = null
        if (action.payload.group_type === "Company" && action.payload.modules_form){
            modules_data = yield call(editCompanyModulesApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.modules_form,
            )
        }

        const contact_data = yield call(editElementContactApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.contact_form,
            action.payload.group_type
        )

        const element_data = data
        element_data.contact = contact_data
        if (action.payload.group_type === "Company" && action.payload.modules_form){
            element_data.modules = modules_data.modules
        }

        if (action.payload.updated_area_type) {
            yield put(
                fetchLightsLevelOn(action.payload.tenant_uuid, action.payload.uuid)
            )
        }
        
        yield put(handleTreeData(element_data, "edit"))
        yield put(successCreateEditElement(element_data))
        yield put(toogleCreateEditElementLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("400")) {
            error = err.response.data
        } else if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN }
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED }
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_COMPANY) {
                error = { "message": "Company was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("422")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleCreateEditElementLoader(false))
        yield put(errorCreateEditElement(error))
    }
}

export function* createElementWatcher() {
    yield takeLeading(actions.CREATE_ELEMENT, createElement)
}

export function* editElementWatcher() {
    yield takeLeading(actions.EDIT_ELEMENT, editElement)
}