import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Empty, Tabs, Icon, Button, Tooltip, Modal } from "antd";
import "./style.css";
import PeopleAnalyticsMapsTab from "../PeopleAnalyticsMapsTab";
import ElementDetailGeneralTab from "../ElementDetailGeneralTab";
import ElementDetailDataTab from "../ElementDetailDataTab";
import ElementDetailChartsTab from "../ElementDetailChartsTab";
import { getGroupLabel, enableDeleteCompanyElementButton } from "../../utils/helpers"
import * as actions from '../../constants'

const { TabPane } = Tabs

const ModulePeopleAnalytics = ({
  putToLoad,
  parentTab,
  modalSetters: { setIsModalVisible, setActionType }
}) => {

  const [currentTab, setCurrentTab] = useState(parentTab)
  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const [isLoading, setIsLoading] = useState(false)
  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)
  const managerGroup = useSelector((state) => state.rootReducer.auth.group)
  const peopleAnalytics = useSelector(state => state.rootReducer.peopleAnalytics.peopleAnalyticsData)

  const dispatch = useDispatch()

  const getElementType = group_type => {
    let elementType = "";
    switch (group_type) {
      case "Logical":
        elementType = "Logical Group";
        break;
      case "Control":
        elementType = "Control Group";
        break;
      case "Mix":
        elementType = "Mix Group";
        break;
      default:
        elementType = group_type;
        break;
    }
    return elementType;
  }

  useEffect(() => {
    if (element && element.uuid && element.group_type !== "Company") {
      dispatch(({
        type: actions.FETCH_PEOPLEANALYTICS,
        payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, group_type: element.group_type }
      }))
    }
  },
    [element])


  const handleClickEdit = () => {
    setActionType("edit")
    dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: element.group_type } })
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (putToLoad) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  },
    [putToLoad]
  )

  useEffect(() => {
    if (element && element.group_type && (element.group_type !== "Company" && element.group_type !== "Building")) {
      if (element && element.group_type && (element.group_type === "Control")) {
        setCurrentTab("latest_data")
      } else {
        setCurrentTab("people_analytics")
      }
    } else {
      setCurrentTab("general_details")
    }
  },
    [element, parentTab]
  )


  const handleClickRemove = () => {
    const group_label = getGroupLabel(element.group_type)
    Modal.confirm({
      content: `You are about to delete ${element.group_type} ${group_label}${element.name}. Are you sure?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch({ type: actions.DELETE_ELEMENT, payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, group_type: element.group_type } })
      }
    });
  };

  const handleOnChangeTab = async activeKey => {
    setCurrentTab(activeKey)
  }

  const handleClickSettings = () => {
    setActionType("settings");
    dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: "PeopleAnalytics" } })
    setIsModalVisible(true);
  };

  return (
    <Card
      title={
        isLoading
          ? "Loading details..."
          : !element || !element.name
            ? <small>Select an element to see its details</small>
            : <span>{getElementType(element.group_type)} <b>{element.name}</b> Details:</span>
      }
      actions={<></>/*isLoading || (currentTab && currentTab === "people_analytics")
        ? []
        : [
          <Button
            type="primary"
            onClick={handleClickEdit}
            icon="edit"
            disabled={!element || element.name === undefined ? true : false}
          >
            Edit
        </Button>,
          <Button
            type="primary"
            onClick={handleClickSettings}
            icon="control"
            disabled={!element || element.name === undefined || element.group_type === "Company" ? true : false}
          >
            Settings
       </Button>,
          <Button icon="delete" onClick={handleClickRemove} disabled={!element || element.name === undefined || !enableDeleteCompanyElementButton(domainClaims, element, managerGroup) ? true : false}>
            Remove
        </Button>

        ]*/}

      extra={isLoading
        ? []
        : [
          <Tooltip title="Edit element" key="tolltip1">
            <Button
              type="link"
              onClick={handleClickEdit}
              icon="edit"
              disabled={!element || element.name === undefined ? true : false}
            >
            </Button>
          </Tooltip>
          ,
          <Tooltip title="Settings" key="tolltip2">
            <Button
              type="link"
              onClick={handleClickSettings}
              icon="control"
              disabled={!element || element.name === undefined || element.group_type === "Company" ? true : false}
            >
            </Button>
          </Tooltip>
          ,
          <Tooltip title="Delete element" key="tolltip3">
            <Button type="link" icon="delete" onClick={handleClickRemove} disabled={!element || element.name === undefined || !enableDeleteCompanyElementButton(domainClaims, element, managerGroup) ? true : false}>
            </Button>
          </Tooltip>
        ]}

    >
      {element && element.name !== undefined
        ? (

          <Tabs defaultActiveKey="general_details" activeKey={currentTab} onChange={handleOnChangeTab}>

            {(element.group_type === "Control" || element.group_type === "Mix") && (
              <TabPane
                tab={
                  <span>
                    <Icon type="project" />
                    Data
                  </span>
                }
                key="latest_data"
              >
                {
                  <ElementDetailDataTab
                    tabKey={currentTab}
                  />
                }
              </TabPane>
            )}
            {(element.group_type === "Control" || element.group_type === "Mix") && (
              <TabPane
                tab={
                  <span>
                    <Icon type="area-chart" />
                    Charts
                  </span>
                }
                key="charts_data"
              >
                {
                  <ElementDetailChartsTab
                    tabKey={currentTab}
                  />
                }

              </TabPane>
            )}

            {(element.group_type === "Control" || element.group_type === "Mix" || element.group_type === "Logical") && (
              <TabPane
                tab={
                  <span>
                    <Icon type="environment" theme="filled" />
                    Maps
                      </span>
                }
                key="people_analytics">
                {(peopleAnalytics && peopleAnalytics.floor_plan_area) ?
                  <PeopleAnalyticsMapsTab
                    element={element}
                    element_uuid={(element && element.uuid ? element.uuid : "")}
                    tabKey={currentTab}
                    floor_plan={(peopleAnalytics && peopleAnalytics.floor_plan_area ? peopleAnalytics.floor_plan_area : 0)}
                    workspace_radius={(peopleAnalytics && peopleAnalytics.workspace_radius ? peopleAnalytics.workspace_radius : 0)}
                    occupancy_limit={(peopleAnalytics && peopleAnalytics.occupancy_limit ? peopleAnalytics.occupancy_limit : 0)}
                  />
                  : <Empty />
                }
              </TabPane>
            )}

            <TabPane
              tab={
                <span>
                  <Icon type="profile" />
                  Details
                </span>
              }
              key="general_details"
            >
              {
                <ElementDetailGeneralTab
                  element={element}
                  tabKey={currentTab}
                  putToLoad={putToLoad}
                />
              }
            </TabPane>


          </Tabs>
        )
        : <Empty />
      }
    </Card >
  );
};

export default ModulePeopleAnalytics;
