import React, { useEffect } from "react"
import { Form, Input, Button, Card, Row, Col } from "antd"
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link } from "react-router-dom"
import Loader from "../Loader"
import CenteredRow from "../CenteredRow"

import "./styles.css"

import * as actions from '../../constants'
import colors from "../../styles";
const { darkIconColor } = colors;

const FormItem = Form.Item;

const User2faQr = ({
  isLogin
}) => {
  const loading = useSelector((state) => state.rootReducer.loaders.loadingUser2faQr)
  const qrCode = useSelector((state) => state.rootReducer.userProfile.qrCode)
  const error = useSelector((state) => state.rootReducer.userProfile.errorFetch2faQr)

  const uuid = useSelector((state) => state.rootReducer.auth.userUuid)
  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const dispatch = useDispatch()

  useEffect(
    () => {
      if (!isLogin) {
        dispatch(({ type: actions.FETCH_USER_2FA_QR, payload: { tenant_uuid, uuid, isLogin } }))
      }
    },
    []
  )

  useEffect(
    () => {
      if (isLogin && tenant_uuid !== "" && uuid !== "") {
        dispatch(({ type: actions.FETCH_USER_2FA_QR, payload: { tenant_uuid, uuid, isLogin } }))
      }
    },
    [tenant_uuid, uuid]
  )

  return loading ? (
    <Loader />
  ) : (
      <>
        <Row
          type="flex"
          justify="center"
          align="middle"
        >
          <Col>
            <h3>Your 2fa QR code:</h3>
          </Col>

        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
        >
          <Col>
            { 
              qrCode ? (<img src={"data:image/jpeg;base64," + qrCode} alt="img_data" id="imgslot" />) 
            : 
            (<div>Unable to load QR</div>) }
            
          </Col>
        </Row>
      </>
    )

};

const WrappedUser2faQr = Form.create()(User2faQr);

export default withRouter(WrappedUser2faQr)