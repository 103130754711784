import { createContext } from "react";

export const UserContext = createContext({
  userData: {},
  setUserData: () => {}
});

export const AppContext = createContext({
  appData: {},
  setAppData: () => {}
});
