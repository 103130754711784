import * as actions from '../constants';

const initialState = {
  advancedscheduledata: null,
  error: null,
}

const advancedSchedule = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_ADVANCED_SCHEDULE:
      return Object.assign({}, state, {
        advancedscheduledata: action.payload.data,
        error: null
      })
    case actions.ERROR_FETCH_UPDATE_ADVANCED_SCHEDULE:
      return Object.assign({}, state, {
        advancedscheduledata: null,
        error: action.payload.error
      })
    case actions.CLEAN_ADVANCED_SCHEDULE_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_ADVANCED_SCHEDULE_STATE:
      return Object.assign({}, state, {
        error: null,
        advancedscheduledata: null,
      })
    default:
      return state
  }
}

export default advancedSchedule