import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchLatestData, errorFetchLatestData } from '../actions/latestDataActions'
import { fetchLatestDataApi } from '../api/latestDataApi'
import { toogleLatestDataLoader } from '../actions/loaderActions'

export function* getLatestDataValues(action) {
    try {
        yield put(toogleLatestDataLoader(true))

        const data = yield call(fetchLatestDataApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.sensor_type
        )

        yield put(successFetchLatestData(data))
        yield put(toogleLatestDataLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleLatestDataLoader(false))
        yield put(errorFetchLatestData(error))
    }
}

export function* fetchLatestDataWatcher() {
    yield takeLatest(actions.FETCH_LATEST_DATA, getLatestDataValues)
}