import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v1'
import IdleTimer from "react-idle-timer"
import { message, Form, Button, Row, Col, Slider, Select, Switch, Checkbox, Tabs, TimePicker, Icon, Input, Notification, Modal, InputNumber, Radio, Empty, Tooltip } from "antd";
import Loader from "../Loader";
import { withRouter } from "react-router-dom";
import ScheduleInput from '../ScheduleInput'
import ScheduleModal from '../ScheduleModal'

import "./styles.css";
import * as moment from 'moment';



import CenteredRow from "../CenteredRow";
import { UserContext } from "../../context";
import * as actions from '../../constants';
import * as error_codes from '../../constants/error_codes';

const { Option } = Select;
const { TabPane } = Tabs;

const dateFormat = 'HH:mm';
const FormItem = Form.Item;
const ButtonGroup = Button.Group

const tabs = {
  "Week": 0,
  "Weekdays": 1,
  "Weekend": 2,
  "Monday": 3,
  "Tuesday": 4,
  "Wednesday": 5,
  "Thursday": 6,
  "Friday": 7,
  "Saturday": 8,
  "Sunday": 9
}

const _AdvancedScheduleForm = ({
  form,
  history,
  //isModalVisible,
  handleTreeData,
  tabKey,
}) => {

  const loading = useSelector(state => state.rootReducer.loaders.loadingAdvancedSchedule)
  const group = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const error = useSelector((state) => state.rootReducer.advancedSchedule.error)

  const advancedschedule = useSelector(state => state.rootReducer.advancedSchedule.advancedscheduledata)

  const [advancedScheduleEnabled, setAdvancedScheduleEnabled] = useState(false);

  const [advancedScheduleType, setAdvancedScheduleType] = useState("Week");

  const [advancedScheduleTypeWeek, setAdvancedScheduleTypeWeek] = useState([]);
  const [advancedScheduleTypeWeek2, setAdvancedScheduleTypeWeek2] = useState([]);
  const [advancedScheduleTypeDaily, setAdvancedScheduleTypeDaily] = useState([]);

  const [updatedAdvancedSchedule, setUpdatedAdvancedSchedule] = useState(false);
  const [advancedSchedule, setAdvancedSchedule] = useState([]);

  const arrayDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  const arrayweekdays = ["Weekdays", "Weekend"]
  const arrayweek = ["Week"]

  //current schedule
  const [lightLevel, setLightLevel] = useState(0);
  const [whiteLevel, setWhiteLevel] = useState(0);
  const [timezone, setTimezone] = useState("Canada/Eastern");
  const [periods, setPeriods] = useState([]);
  const [period, setSchedulePeriod] = useState({});
  const [actionModal, setActionModal] = useState(-1);
  const [isScheduleModalVisible, setShowScheduleModal] = useState(false);

  const [currentTab, setCurrentTab] = useState("0")
  const [updateIndex, setUpdateIndex] = useState(0)

  const [saveDisabled, setSaveDisabled] = useState(true)
  const [touchedFields, setTouchedFields] = useState([])

  const dispatch = useDispatch()

  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)


  const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue } = form


  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        // const { advanced_schedule_enabled } = values
        const form = {}
        // if (touchedFields.includes("advanced_schedule_enabled")) {
        //  form.advanced_schedule_enabled = advanced_schedule_enabled
        // }

        if (updatedAdvancedSchedule) {
          form.advanced_schedule = advancedSchedule;
        }

        dispatch(({ type: actions.UPDATE_ADVANCED_SCHEDULE, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid, form: form } }))
        
      } else {
        message.error(err)
      }

    })
  }

  useEffect(
    () => {
      if (advancedschedule && advancedschedule.advanced_schedule) {
        setAdvancedSchedule((advancedschedule.advanced_schedule).sort(function (a, b) {
          return tabs[a.schedule_type] - tabs[b.schedule_type];
        }
        ))
        setAdvancedScheduleEnabled(advancedschedule && advancedschedule.advanced_schedule_enabled && advancedschedule.advanced_schedule_enabled === true ? true : false)
      }
    },
    [advancedschedule]
  )

  useEffect(() => {
    window.cancelschedule = false;
    if (advancedSchedule && advancedSchedule.length > 0) {

      //determinar el type
      const return_element = getAdvancedScheduleType(advancedSchedule)
      if (return_element.return_type === "Week") {
        setAdvancedScheduleTypeWeek(return_element.schedule_array)
      }
      if (return_element.return_type === "Weekdays") {
        setAdvancedScheduleTypeWeek2(return_element.schedule_array)
      }
      if (return_element.return_type === "Daily") {
        setAdvancedScheduleTypeDaily(return_element.schedule_array)
      }
      setAdvancedScheduleType(return_element.return_type)

      if (advancedSchedule && advancedSchedule.length > 0) {
        let intCurrentTab = parseInt(currentTab) + updateIndex
        setUpdateIndex(0)
        if (!advancedSchedule[intCurrentTab]) {
          setCurrentTab("0");
          intCurrentTab = 0;
        } else {
          setCurrentTab(intCurrentTab + "");
        }
        setPeriods(advancedSchedule[intCurrentTab].advanced_schedule_periods)
        setLightLevel(advancedSchedule[intCurrentTab].light_level);
        setWhiteLevel(advancedSchedule[intCurrentTab].white_level)
        setTimezone(advancedSchedule[intCurrentTab].timezone);
      }
    } else {
      //createNewAdvancedSchedule("Week")
      setAdvancedScheduleTypeWeek([])
      setAdvancedScheduleTypeWeek2([])
      setAdvancedScheduleTypeDaily([])
      setPeriods([])
      setLightLevel(0);
      setWhiteLevel(0)
      setTimezone("Canada/Eastern");
        
    }

    return () => {
      //window.cancelschedule = true;
    };
  }, [advancedSchedule])



  const getAdvancedScheduleType = (advanced_schedule) => {
    let return_type = "Week"
    let tmp_array_week = []
    let tmp_array_week_days = []
    let tmp_array_days = []
    advanced_schedule.forEach(element => {
      if (element.schedule_type === "Weekdays") {
        return_type = "Weekdays"
        tmp_array_week_days.push("Weekdays")
      } else if (element.schedule_type === "Weekend") {
        return_type = "Weekdays"
        tmp_array_week_days.push("Weekend")
      } else if (element.schedule_type === "Monday") {
        return_type = "Daily"
        tmp_array_days.push("Monday")
      } else if (element.schedule_type === "Tuesday") {
        return_type = "Daily"
        tmp_array_days.push("Tuesday")
      } else if (element.schedule_type === "Wednesday") {
        return_type = "Daily"
        tmp_array_days.push("Wednesday")
      } else if (element.schedule_type === "Thursday") {
        return_type = "Daily"
        tmp_array_days.push("Thursday")
      } else if (element.schedule_type === "Friday") {
        return_type = "Daily"
        tmp_array_days.push("Friday")
      } else if (element.schedule_type === "Saturday") {
        return_type = "Daily"
        tmp_array_days.push("Saturday")
      } else if (element.schedule_type === "Sunday") {
        return_type = "Daily"
        tmp_array_days.push("Sunday")
      } else if ("Week") {
        return_type = "Week"
        tmp_array_week.push("Week")
      }
    });



    if (return_type === "Week") {
      const schedule_array = tmp_array_week
      return { return_type, schedule_array }
    }
    if (return_type === "Weekdays") {
      const schedule_array = tmp_array_week_days
      return { return_type, schedule_array }
    }
    if (return_type === "Daily") {
      const schedule_array = tmp_array_days
      return { return_type, schedule_array }
    }
    const schedule_array = tmp_array_week
    return { return_type, schedule_array }
  }


  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
      setTouchedFields(touched_fields)
    },
    [form]
  )

  useEffect(
    () => {
      if (updatedAdvancedSchedule) {
        setSaveDisabled(false)
      }
    },
    [updatedAdvancedSchedule]
  )

  useEffect(
    () => {
      /*
      if (!isModalVisible) {
        resetFields()
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_LIGHTS_CONTROL_STATE }))
      }
      */
      return () => {
        resetFields()
        setUpdatedAdvancedSchedule(false)
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_ADVANCED_SCHEDULE_STATE }))
      }
    },
    []
  )

  useEffect(
    () => {
      (async () => {
        if (tabKey === "advanced_schedule" && !loading) {
          resetFields()
          dispatch(({ type: actions.FETCH_ADVANCED_SCHEDULE, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
          setUpdatedAdvancedSchedule(false);
          setSaveDisabled(true)
        }
      })();
    },
    [tabKey, group.uuid]
  )

  useEffect(
    () => {
      if (advancedschedule !== null) {
        if (advancedschedule.update) {
          //setSaveDisabled(true)
          if (updatedAdvancedSchedule) {
            message.success(`Schedules of group '${group.name}' successfully updated`)
            setUpdatedAdvancedSchedule(false)            
          }
          resetFields()
        }
      }
    },
    [advancedschedule]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              dispatch(({ type: actions.FETCH_ADVANCED_SCHEDULE, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
              setSaveDisabled(true)
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_ADVANCED_SCHEDULE_ERROR })
        }
      }
    },
    [error]
  )


  const onUserIdle = async e => {
    if (tabKey === "advanced_schedule") {
      resetFields()
      dispatch(({ type: actions.FETCH_ADVANCED_SCHEDULE, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
      setSaveDisabled(true)
    }
    //idleTimer.reset()
  }

  const handleChangeType = async (value) => {
    if (advancedScheduleType !== value) {
      await setAdvancedScheduleType(value);
      await setAdvancedScheduleTypeWeek([]);
      await setAdvancedScheduleTypeWeek2([]);
      await setAdvancedScheduleTypeDaily([]);
      await setPeriods([]);
      await setLightLevel(0);
      await setWhiteLevel(0);

      setUpdatedAdvancedSchedule(true)
      await setAdvancedSchedule([]);
    }
  }

  const onChangeDays = async (checkedValues) => {
    const error = await create_or_delete_advanced_schedule(advancedScheduleTypeDaily, checkedValues)
    if (!error) {
      setUpdatedAdvancedSchedule(true)
      setAdvancedScheduleTypeDaily(checkedValues)
    }
  }

  const onChangeWeekday = async (checkedValues) => {
    const error = await create_or_delete_advanced_schedule(advancedScheduleTypeWeek2, checkedValues)
    if (!error) {
      setUpdatedAdvancedSchedule(true)
      setAdvancedScheduleTypeWeek2(checkedValues)
    }
  }

  const onChangeWeek = async (checkedValues) => {
    const error = await create_or_delete_advanced_schedule(advancedScheduleTypeWeek, checkedValues)
    if (!error) {
      setUpdatedAdvancedSchedule(true)
      setAdvancedScheduleTypeWeek(checkedValues)
    }
  }

  function create_or_delete_advanced_schedule(old_array_schedule_type, new_array_schedule_type) {
    //determinte if is create or delete
    let error = false;
    if (old_array_schedule_type.length < new_array_schedule_type.length) {
      
      //create
      const new_element = Array.from(new_array_schedule_type).filter(x => old_array_schedule_type.indexOf(x) <= -1)
      
      //error = createNewAdvancedSchedule(new_element[0]);
      const newSchedule = {
        "light_level": 0,
        "white_level": 0,
        "warm_white_level": 100,
        "advanced_schedule_periods": [

        ],
        "timezone": "Canada/Eastern",
        "schedule_type": new_element[0],
        "updated": true
      };
      
      const newadvancedschedule = advancedSchedule
      newadvancedschedule.push(newSchedule);

      setAdvancedSchedule(
        newadvancedschedule.sort(function (a, b) {
          return tabs[a.schedule_type] - tabs[b.schedule_type];
        }
        )
      )

      if (tabs[old_array_schedule_type[parseInt(currentTab)]] > tabs[new_element[0]]) {
        setUpdateIndex(1)
        setCurrentTab(parseInt(currentTab)+1 + "")
      }

      

      //crear nuevo advanced schedule
    } else if (old_array_schedule_type.length > new_array_schedule_type.length) {
      //delete
      const delete_element = Array.from(old_array_schedule_type).filter(x => new_array_schedule_type.indexOf(x) <= -1)
      
      //delete advanced schedule
      //error = deleteOldAdvancedSchedule(schedule_uuid)
      setAdvancedSchedule(advancedSchedule.filter(element => {
        return (element.schedule_type !== delete_element[0])
      }).sort(function (a, b) {
        return tabs[a.schedule_type] - tabs[b.schedule_type];
      }))

      //const schedule_uuid = getAdvancedSchedule_with_type(delete_element[0])
      if (tabs[old_array_schedule_type[parseInt(currentTab)]] > tabs[delete_element[0]]) {
        setUpdateIndex(-1)
        //setCurrentTab(parseInt(currentTab)-1 + "")
        
      }
    }
    return false;
  }

  const getMoment = (date) => {
    //const getoffset = new Date().getTimezoneOffset() / 60;
    const getstartH = (Number)((date).split(':')[0]);
    const getstartM = (Number)((date).split(':')[1]);

    const getutcstart = ((Number)(getstartH)) % 24;

    const finalstartT = (getutcstart + ":" + getstartM + ":00")
    return finalstartT;
  }


  //change light
  const handleChangeLightLevel = value => {
    setLightLevel(value);
    
  }

  const handleChangeWhiteLevel = value => {
    setWhiteLevel(value);
  }

  //change light up
  const handleAfterChangeLightLevel = value => {
    setLightLevel(value);
    const newadvancedschedule = advancedSchedule;
    newadvancedschedule[currentTab].light_level = value
    newadvancedschedule[currentTab].updated = true
    setUpdatedAdvancedSchedule(true)
    setAdvancedSchedule(newadvancedschedule)
  }

  const handleAfterChangeWhiteLevel = value => {
    setWhiteLevel(value);
    const newadvancedschedule = advancedSchedule;
    newadvancedschedule[currentTab].white_level = value
    newadvancedschedule[currentTab].warm_white_level = 100-value
    newadvancedschedule[currentTab].updated = true
    setUpdatedAdvancedSchedule(true)
    setAdvancedSchedule(newadvancedschedule)
  }


  const savePeriod = async (startH, startM, stopH, stopM, light_level, white_level) => {
    let newperiods = periods
    const newperioddata = {
      "start_time": startH+":"+startM+":00",
      "stop_time": stopH+":"+stopM+":00",
      "light_level": light_level,
      "white_level": white_level,
      "warm_white_level": 100-white_level
    }

    if(actionModal>-1){
      newperiods = newperiods.map((element, index) => {
        if(index === actionModal){
          return newperioddata
        }else{
          return element
        }
      })
    }else{
      newperiods.push(newperioddata)
    }

    const newadvancedschedule = advancedSchedule;
    newadvancedschedule[currentTab].advanced_schedule_periods = newperiods
    newadvancedschedule[currentTab].updated = true
    setPeriods(newperiods)
    setAdvancedSchedule(newadvancedschedule)
    setUpdatedAdvancedSchedule(true)
    hideScheduleModal();
  }


  const deletePeriod = async (period_index) => {
    const newperiods = periods.filter((element, index) => {
      return index !== period_index
    }) 

    const newadvancedschedule = advancedSchedule;
    newadvancedschedule[currentTab].advanced_schedule_periods = newperiods
    setPeriods(newperiods)
    setUpdatedAdvancedSchedule(true)
    setAdvancedSchedule(newadvancedschedule)
  }


  const showScheduleModal =async (theperiod, action) => {
    setActionModal(action)
    await setSchedulePeriod(theperiod || {})
    await setShowScheduleModal(true)
  }

  const hideScheduleModal = () => {
    setSchedulePeriod(undefined)
    setShowScheduleModal(false)
  }

  const lightColorTemperatureFormatter = (value) => {
    const conver = (3000 + 30 * value).toFixed(0)
    return `${conver}K`;
  }

  const callback = (key) => {
    
    const index = parseInt(key)
    setPeriods(advancedSchedule[index].advanced_schedule_periods)
    setLightLevel(advancedSchedule[index].light_level);
    setWhiteLevel(advancedSchedule[index].white_level)
    setTimezone(advancedSchedule[index].timezone);

    setCurrentTab(key)
  }


  const marksbright = {
    0: {
      style: {
        fontSize: '12px'
      },
      label: <span>0%</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>100%</span>,
    }
  }

  const marksColorTemperature = {
    0: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Warm White</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Bright White</span>,
    }
  }


  return (false //isLoading
    ? <Loader />
    : (!group ? (<div>
      <div className="big-text">No group selected</div>
    </div>) : (
        <div className="not-margin advanced-schedule">
          <CenteredRow >

            <Form onSubmit={handleSubmit}>
              <Row>
                {/*
                <Col xs={8} sm={5} className="enabled-label">
                  <div className="big-text">Enabled:</div>
                </Col>
                <Col xs={16} sm={19} className="enabled-control">
                  <div>
                    <FormItem>
                      {getFieldDecorator("advanced_schedule_enabled", {
                        valuePropName: 'checked',
                        
                        initialValue: (advancedschedule && advancedschedule.advanced_schedule_enabled && advancedschedule.advanced_schedule_enabled === true ? true : false)
                      })(
                        <Switch disabled={loading} loading={loading} />
                      )}
                    </FormItem>
                  </div>
                </Col>
                */}
                <Col xs={8} sm={5} className="type-label">
                  <div className="big-text">Type:</div>
                </Col>
                <Col xs={16} sm={19} className="type-control">
                  <div>
                    <Select disabled={loading} defaultValue="Week" value={advancedScheduleType} style={{ width: 180 }} onChange={handleChangeType}>
                      <Option value="Week">Week</Option>
                      <Option value="Weekdays">Weekdays/Weekend</Option>
                      <Option value="Daily">Daily</Option>
                    </Select>
                  </div>
                </Col>
                <Col xs={8} sm={5} className="enabled-label">
                </Col>
                <Col xs={16} sm={19} className="type-control">
                  {(advancedScheduleType === "Week" ? (
                    <div>
                      <Checkbox.Group disabled={loading} options={arrayweek} value={advancedScheduleTypeWeek} onChange={onChangeWeek} />
                    </div>
                  ) : <></>)}

                  {(advancedScheduleType === "Weekdays" ? (
                    <div>
                      <Checkbox.Group disabled={loading} options={arrayweekdays} value={advancedScheduleTypeWeek2} onChange={onChangeWeekday} />
                    </div>
                  ) : <></>)}

                  {(advancedScheduleType === "Daily" ? (
                    <div>
                      <Checkbox.Group disabled={loading} options={arrayDays} value={advancedScheduleTypeDaily} onChange={onChangeDays} />
                    </div>
                  ) : <></>)}
                </Col>
              </Row>
              <div>
                <Tabs disabled={loading} activeKey={currentTab} onChange={callback} className="advanced-schedule-tab">
                  {advancedSchedule && advancedSchedule.map((element, index) => (
                    <TabPane disabled={loading} tab={<span className="big-text">{element.schedule_type}</span>} key={index}>
                      {currentTab + "" === index + "" &&
                        <ScheduleModal
                          period={period}
                          visible={isScheduleModalVisible}
                          hideModal={hideScheduleModal}
                          savePeriod={savePeriod}
                          schedule={element}
                          group={group}
                        />
                      }
                      <div>
                        <div className="big-text" style={{marginTop: "30px"}}>Brightness</div>
                        <div  className="advanced-schedule-slider">
                          <Slider disabled={loading} step={5} marks={marksbright} min={0} max={100} onChange={handleChangeLightLevel} onAfterChange={handleAfterChangeLightLevel} value={lightLevel ? lightLevel : 0} />
                        </div>
                        <div className="big-text" style={{marginTop: "50px"}}>Light Temperature</div>
                        <div  className="advanced-schedule-slider">
                          <Slider disabled={loading} step={5} marks={marksColorTemperature} tipFormatter={lightColorTemperatureFormatter} min={0} max={100} onChange={handleChangeWhiteLevel} onAfterChange={handleAfterChangeWhiteLevel} value={whiteLevel ? whiteLevel : 0} />
                        </div>
                        <div className="big-text" style={{marginTop: "50px"}}>Timezone</div>
                        <div>
                          <Input className="schedule-info__input" disabled value={timezone} />
                        </div>
                        <Row  style={{marginTop: "30px"}}>
                          {periods &&
                            periods.length > 0 && periods.map((period, index) => (
                              <Col xs={24} key={"period"+index+"-"+period.id} className="schedule-form">
                                <Row display="flex" justify="start" className="schedule-form__times">

                                  <Col xs={24} sm={24} style={{ marginBottom: "5px" }}>
                                    <span><b>Bright: </b>{period.light_level}%</span>

                                    <span style={{ marginLeft: "20px" }}><b>Light Temperature: </b>{lightColorTemperatureFormatter(period.white_level)}</span>
                                  </Col>


                                </Row>
                                <Row display="flex" justify="start" className="schedule-form__times" >
                                  <Col xs={12} sm={8} className="time" style={{ marginBottom: "5px" }}>
                                    <span className="time__title">ON </span>
                                    <TimePicker
                                      use12Hours
                                      format="hh:mm a"
                                      disabled={true}
                                      value={moment(getMoment(period.start_time), "HH:mm:ss")}
                                    />
                                  </Col>
                                  <Col xs={12} sm={8} className="time" style={{ marginBottom: "5px" }}>
                                    <span className="time__title">OFF </span>
                                    <TimePicker
                                      use12Hours
                                      format="hh:mm a"
                                      disabled={true}
                                      value={moment(getMoment(period.stop_time), "HH:mm:ss")}
                                    />
                                  </Col>
                                  <Col xs={24} sm={8} className="btn-advanced-schedule-period">
                                    <Row display="flex">
                                      <Tooltip title="Edit Schedule">
                                        <Button
                                          type="info"
                                          className="info"
                                          shape="circle"
                                          icon="edit"
                                          style={{ marginLeft: 20 }}
                                          loading={loading}
                                          onClick={() => showScheduleModal(period, index)}

                                        />
                                      </Tooltip>
                                      <Tooltip title="Delete Schedule">
                                        <Button
                                          type="danger"
                                          className="delete"
                                          shape="circle"
                                          icon="delete"
                                          style={{ marginLeft: 20 }}
                                          loading={loading}
                                          onClick={() => deletePeriod(index)}
                                        />
                                      </Tooltip>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            ))
                          }
                          <Col sm={{ span: 24 }} style={{ paddingTop: 20 }}>
                            <Tooltip title="Add Schedule">
                              <Button
                                icon="plus"
                                type="primary"
                                ghost
                                className="add-schedule-btn"
                                loading={loading}
                                onClick={() => showScheduleModal({}, -1)}
                              >
                                Schedule
                                </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  ))}
                </Tabs>

              </div>
              <br />

              <FormItem >
                <Button
                  type="primary"
                  htmlType="button"
                  style={{ width: "40%" }}
                  disabled={saveDisabled}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {!loading && (<Icon type="save" />)} Save
                        </Button>
              </FormItem>
            </Form >
          </CenteredRow >
        </div >
      )));
};

const ElementDetailAdvancedScheduleForm = Form.create()(_AdvancedScheduleForm);

export default withRouter(ElementDetailAdvancedScheduleForm);