import React from 'react'
import { fetchHistoricDataApi } from '../api/chartDataApi'
import moment from 'moment'
import { allSensorTypes } from '../constants/sensorDataType'

export const getGroupLabel = (group_type) => {
  let group_label = ""
  if (group_type === "Control" || group_type === "Mix" || group_type === "Logical") {
    group_label = "group "
  }
  return group_label
}

export const getTreeNodeIcon = group_type => {
  let iconType = "";
  switch (group_type) {
    case "Company":
      iconType = "bank";
      break;
    case "Building":
      iconType = "hdd";
      break;
    case "Logical":
      iconType = "apartment";
      break;
    case "Control":
      iconType = "control";
      break;
    case "Mix":
      iconType = "appstore";
      break;
    default:
      iconType = "team";
      break;
  }
  return iconType;
}

export const enableDeleteCompanyElementButton = (domainClaims, element_data, user_group) => {
  let result = true
  const element = { uuid: element_data.uuid }

  if (element_data.elementType) {
    element.group_type = element_data.elementType
  } else {
    element.group_type = element_data.group_type
  }

  if (element.group_type === "Company" && !("CanRemoveCompany" in domainClaims)) {
    result = false
  } else if (element.group_type === "Building" && !("CanRemoveBuilding" in domainClaims)) {
    result = false
  } else if (user_group) {
    if (user_group.uuid === element.uuid) {
      result = false
    }
  }

  //console.log("element", element)
  //console.log("result", result)
  return result
}


export const getArrayClickPosition = (tree, groupname) => {
  let arrayclickposition = [];
  arrayclickposition = recursiveArrayPosition(0, tree, groupname)
  return arrayclickposition
}


const recursiveArrayPosition = (index, childs, group_name) => {
  let arrayposition = []
  arrayposition.push(0)

  for (let index_element = 0; index_element < childs.length; index_element++) {
    const element = childs[index_element];
    arrayposition[0] = index_element
    //console.log(element.name)

    if (element.uuid === group_name) {
      return arrayposition;
    } else if (element.children !== null) {
      let childarray = recursiveArrayPosition(index + 1, element.children, group_name)
      if (childarray.length > 0) {
        const newarray = arrayposition.concat(childarray)
        return newarray;
      }
    }

  }
  return []
}



export const proccessPromises = async (promises) => {
  let data = await Promise.all(promises)

  return data
}


export const makeParallelRequest = (tenant_uuid, group_uuid, sensor_type, start, end, paraminterval, requestTime) => {
  let interval = paraminterval  
  if(paraminterval > requestTime){
    interval = requestTime;
  }

  let count = 1
  let tmpstart = start
  let tmpsubstracttime = moment.utc().subtract(requestTime, 'hours')
  let tmpcurrentTime = tmpsubstracttime.add(interval*60, 'minutes')
  const promises = []

  while (interval*count <= requestTime ) {
    promises.push(new Promise(resolve => {
      const datavalue = fetchHistoricDataApi(
        tenant_uuid,
        group_uuid,
        sensor_type,
        tmpstart.format('YYYYMMDDHHmmss'),
        tmpcurrentTime.format('YYYYMMDDHHmmss'),
      )
      resolve(datavalue)
    }
    ))
    tmpstart = tmpstart.add(interval, 'hours')
    tmpcurrentTime = tmpcurrentTime.add(interval, 'hours')
    count = count + 1
  }

  return promises
}

export const unifyRequestsResults = fetch_data => {
  let datavalue = {}
  let firstdata = true
  let countdataempty = 0
  
  for (let i = 0; i < fetch_data.length; i++) {
    if (fetch_data[i].error_code) {
      
    } else {
      if (fetch_data[i] ){ //&& Object.keys(fetch_data[i].data).length > 0) {
        if (firstdata) {
          datavalue = [...fetch_data[i]];
          firstdata = false;
        } else {

          for (let indey = 0; indey < fetch_data[i].length; indey++) {
            let ispresent = false
            for (let index = 0; index < datavalue.length; index++) {
              //concat with aws_id
              if(datavalue[index].aws_id ===  fetch_data[i][indey].aws_id){
                datavalue[index].sensor_data = (datavalue[index].sensor_data).concat(fetch_data[i][indey].sensor_data)
                ispresent = true;
              }
            }
            //add new aws_id sensor data
            if(ispresent === false){
              datavalue.push({"aws_id": fetch_data[i][indey].aws_id, "sensor_data": fetch_data[i][indey].sensor_data})
            }
          }
        }
      } else {
        countdataempty = countdataempty + 1;
      }
    }
  }
  
  return datavalue
}

export const parallelChartProccess = async (tenant_uuid, group_uuid, sensor_type, start, end, interval, requestTime) => {

  const promises = makeParallelRequest(
    tenant_uuid,
    group_uuid,
    sensor_type,
    start, //.format('YYYYMMDDHHmmss'),
    end, //.format('YYYYMMDDHHmmss'),
    interval,
    requestTime
  )

  const fetch_data = await proccessPromises(promises)
  const data = unifyRequestsResults(fetch_data)
  
  return data
  
}


export const getTitle = (thesensorType) => {
  switch (thesensorType) {
      case allSensorTypes.TEMPERATURE:
          return 'temperature'
      case allSensorTypes.HUMIDITY:
          return 'humidity'
      case allSensorTypes.PRESSURE:
          return 'pressure'
      case allSensorTypes.IR:
          return 'ir'
      case allSensorTypes.LIGHT:
          return 'lux'
      case allSensorTypes.MOTION:
          return 'occupancy'
      case allSensorTypes.POWER:
          return 'power'
      case allSensorTypes.CO2:
          return 'CO2'
      case allSensorTypes.VOC:
          return 'VOC'
      case allSensorTypes.ENH_MOTION:
        return 'enh_motion'
      case allSensorTypes.UV_LIGHTCYCLE:
        return 'uv_lightcycle'
      default:
          return ''
  }
}

export const symbol = (currentSensorType) => {
  switch (currentSensorType) {
      case allSensorTypes.TEMPERATURE:
          return (
                 <><sup>o</sup>C</>
          )
      case allSensorTypes.HUMIDITY:
          return <>%</>
      case allSensorTypes.PRESSURE:
          return <>mbar</>
      case allSensorTypes.IR:
          return <></>
      case allSensorTypes.LIGHT:
          return <>lux</>
      case allSensorTypes.MOTION:
          return <></>
      case allSensorTypes.POWER:
          return <>Wh</>
      case allSensorTypes.CO2:
          return <>ppm</>
      case allSensorTypes.VOC:
          return <>ppb</>
      default:
          return ''
  }
}


export const getParentKey = (uuid, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.uuid === uuid)) {
        parentKey = node.uuid;
      } else if (getParentKey(uuid, node.children)) {
        parentKey = getParentKey(uuid, node.children);
      }
    }
  }
  return parentKey;
};


export const getMessageType = message_type => {
  let messageType = "";
  switch (message_type) {
      case 1:
          messageType = "Information";
          break;
      case 2:
          messageType = "Error";
          break;
      case 3:
          messageType = "Warning";
          break;
      case 4:
          messageType = "Alarm";
          break;
      default:
          messageType = "";
          break;
  }
  return messageType;
}

export const getMessageIconType = message_type => {
  let messageType = "";
  switch (message_type) {
      case 1:
          messageType = "info";
          break;
      case 2:
          messageType = "error";
          break;
      case 3:
          messageType = "warning";
          break;
      case 4:
          messageType = "success";
          break;
      default:
          messageType = "";
          break;
  }
  return messageType;
}

export const getNotificationType = notification_type => {
  let notificationType = "";
  switch (notification_type) {
      case 1:
          notificationType = "DIRECT";
          break;
      case 2:
          notificationType = "CONTACT";
          break;
      case 3:
          notificationType = "BROADCAST";
          break;
      case 4:
          notificationType = "CONTACT LANDLORD";
          break;
      case 5:
          notificationType = "CONTACT LANDLORD";
          break;
      case 6:
          notificationType = "CONTACT TECHINCAL";
          break;
      default:
          notificationType = "";
          break;
  }
  return notificationType;
}

export const getNotificationState = (notification_state) => {
  let notificationState = ""
  switch (notification_state) {
    case 1:
      notificationState = "READ"
      break;
    case 2:
      notificationState = "UNREAD"
      break;
    default:
      notificationState = "READ"
      break;
  }
  return notificationState;
}

export const getIndexTransport = (transport_str) => {
  if (transport_str === "toast") {
    return 1;
  } else if (transport_str === "mail") {
    return 2;
  } else if (transport_str === "sms") {
    return 3;
  }
  return 1;
}

export const getElementType = group_type => {
  let elementType = "";
  switch (group_type) {
    case "Logical":
      elementType = "Logical Group";
      break;
    case "Control":
      elementType = "Control Group";
      break;
    case "Mix":
      elementType = "Mix Group";
      break;
    default:
      elementType = group_type;
      break;
  }
  return elementType;
}

export const getElementTypeForAPI = group_type => {
  let elementType = "";
  switch (group_type) {
    case "Logical":
      elementType = "group";
      break;
    case "Control":
      elementType = "group";
      break;
    case "Mix":
      elementType = "group";
      break;
    case "Building":
      elementType = "building";
      break;
    case "Company":
      elementType = "company";
      break;
    default:
      elementType = group_type;
      break;
  }
  return elementType;
}

export const isModuleNotificatoinActive = (user_modules, module_name) => {
  let exist = false;
  user_modules.forEach(element => {
    if (element.name === module_name) {
      exist = true;
    }
  });
  return exist;
}