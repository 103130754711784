import {
    call,
    put,
    takeLatest
} from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import {
    successFetchAreaTypesList,
    successFetchAreaType,
    successRemoveAreaType,
    successCreateAreaType,
    successEditAreaType,
    successAddAreaTypeParameterValue,
    successDeleteAreaTypeParameterValue,
    successFetchAreaTypeParametersList,
    successFetchAreaTypeParameter,
    successCreateAreaTypeParameter,
    successEditAreaTypeParameter,
    successRemoveAreaTypeParameter,
    errorFetchAreaTypesList,
    errorFetchAreaTypeParametersList,
    errorFetchAreaType,
    errorCreateEditAreaType,
    errorRemoveAreaType,
    errorFetchAreaTypeParameter,
    errorRemoveAreaTypeParameter,
    errorCreateEditAreaTypeParameter
} from '../actions/areaTypeParametersActions'
import {
    getAreaTypesListApi,
    getAreaTypeDetailsApi,
    createAreaTypeApi,
    updateAreaTypeApi,
    deleteAreaTypeApi,
    addAreaTypeParameterValueApi,
    deleteAreaTypeParameterValueApi,
    getAreaTypeParametersListApi,
    getAreaTypeParameterDetailsApi,
    createAreaTypeParameterApi,
    updateAreaTypeParameterApi,
    deleteAreaTypeParameterApi
} from '../api/areaTypeParametersApi'
import {
    toogleAreaTypesLoader,
    toogleAreaTypeEditionLoader,
    toogleAreaTypeParameterValueEditionLoader,
    toogleAreaTypeParametersLoader,
    toogleAreaTypeParameterEditionLoader
} from '../actions/loaderActions'

export function* getAreaTypesList(action) {
    try {
        yield put(toogleAreaTypesLoader(true))

        const result = yield call(getAreaTypesListApi)

        yield put(successFetchAreaTypesList(result))
        yield put(toogleAreaTypesLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypesLoader(false))
        yield put(errorFetchAreaTypesList(error))
    }
}

export function* getAreaTypeParametersList(action) {
    try {
        yield put(toogleAreaTypeParametersLoader(true))

        const result = yield call(getAreaTypeParametersListApi)

        yield put(successFetchAreaTypeParametersList(result))
        yield put(toogleAreaTypeParametersLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeParametersLoader(false))
        yield put(errorFetchAreaTypeParametersList(error, action.payload.inAreaTypeForm))
    }
}

export function* removeAreaType(action) {
    try {
        //yield put(toogleUsersLoader(true))

        const area_type_name = action.payload.area_type_name

        yield call(deleteAreaTypeApi,
            area_type_name
        )

        yield put(successRemoveAreaType(area_type_name))
        //yield put(toogleUsersLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Area type was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        //yield put(toogleUsersLoader(false))
        yield put(errorRemoveAreaType(error))
    }
}

export function* removeAreaTypeParameter(action) {
    try {
        //yield put(toogleUsersLoader(true))

        const area_type_parameter_name = action.payload.area_type_parameter_name

        yield call(deleteAreaTypeParameterApi,
            area_type_parameter_name
        )

        yield put(successRemoveAreaTypeParameter(area_type_parameter_name))
        //yield put(toogleUsersLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Area type parameter was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        //yield put(toogleUsersLoader(false))
        yield put(errorRemoveAreaTypeParameter(error))
    }
}

export function* getAreaTypeDetails(action) {
    try {
        yield put(toogleAreaTypeEditionLoader(true))
        yield put(toogleAreaTypeParameterValueEditionLoader(true))

        const result = yield call(getAreaTypeDetailsApi, action.payload.area_type_name)

        yield put(successFetchAreaType(result))
        yield put(toogleAreaTypeEditionLoader(false))
        yield put(toogleAreaTypeParameterValueEditionLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            }
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Area type was deleted",
                "error_code": err.response.data.error_code
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeEditionLoader(false))
        yield put(toogleAreaTypeParameterValueEditionLoader(false))
        yield put(errorFetchAreaType(error))
    }
}

export function* createAreaType(action) {
    try {
        yield put(toogleAreaTypeEditionLoader(true))

        const data = yield call(createAreaTypeApi, action.payload.form)

        yield put(successCreateAreaType(data))
        yield put(toogleAreaTypeEditionLoader(false))
    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeEditionLoader(false))
        yield put(errorCreateEditAreaType(error))
    }
}

export function* editAreaType(action) {
    try {
        yield put(toogleAreaTypeEditionLoader(true))

        const area_type_name = action.payload.area_type_name

        const data = yield call(updateAreaTypeApi,
            area_type_name,
            action.payload.form
        )

        yield put(successEditAreaType(data))
        yield put(toogleAreaTypeEditionLoader(false))
    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("500")) {
            error = {
                "message": "Server error!",
                "error_code": error_codes.ERR_INTERNAL_SERVER_ERROR
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Area type was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeEditionLoader(false))
        yield put(errorCreateEditAreaType(error))
    }
}

export function* addAreaTypeParameterValue(action) {
    try {
        yield put(toogleAreaTypeParameterValueEditionLoader(true))

        const area_type_name = action.payload.area_type_name

        const data = yield call(addAreaTypeParameterValueApi,
            area_type_name,
            action.payload.form
        )

        yield put(successAddAreaTypeParameterValue(data, action.payload.isCreate))
        yield put(toogleAreaTypeParameterValueEditionLoader(false))
    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("500")) {
            error = {
                "message": "Server error!",
                "error_code": error_codes.ERR_INTERNAL_SERVER_ERROR
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Area type was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeParameterValueEditionLoader(false))
        yield put(errorCreateEditAreaType(error))
    }
}

export function* deleteAreaTypeParameterValue(action) {
    try {
        // yield put(toogleAreaTypeParameterValueEditionLoader(true))

        const area_type_name = action.payload.area_type_name

        yield call(deleteAreaTypeParameterValueApi,
            area_type_name,
            action.payload.parameter_name
        )

        yield put(successDeleteAreaTypeParameterValue(action.payload.parameter_name))
        // yield put(toogleAreaTypeParameterValueEditionLoader(false))
    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("500")) {
            error = {
                "message": "Server error!",
                "error_code": error_codes.ERR_INTERNAL_SERVER_ERROR
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_AREA_TYPE){
                error = {
                    "message": "Area type was deleted",
                    "error_code": err.response.data.error_code
                }
            }
            if (error_code === error_codes.ERR_AREA_TYPE_INVALID_PARAMETER){
                error = {
                    "message": "Area type parameter was deleted",
                    "error_code": err.response.data.error_code
                }
            }    
        } else {
            error = err.response.data
        }

        // yield put(toogleAreaTypeParameterValueEditionLoader(false))
        yield put(errorCreateEditAreaType(error))
    }
}

export function* getAreaTypeParameterDetails(action) {
    try {
        yield put(toogleAreaTypeParameterEditionLoader(true))

        const result = yield call(getAreaTypeParameterDetailsApi, action.payload.area_type_parameter_name)

        yield put(successFetchAreaTypeParameter(result))
        yield put(toogleAreaTypeParameterEditionLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            }
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Area type was deleted",
                "error_code": err.response.data.error_code
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeParameterEditionLoader(false))
        yield put(errorFetchAreaTypeParameter(error))
    }
}

export function* createAreaTypeParameter(action) {
    try {
        yield put(toogleAreaTypeParameterEditionLoader(true))

        const data = yield call(createAreaTypeParameterApi, action.payload.form)

        yield put(successCreateAreaTypeParameter(data))
        yield put(toogleAreaTypeParameterEditionLoader(false))
    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeParameterEditionLoader(false))
        yield put(errorCreateEditAreaTypeParameter(error))
    }
}

export function* editAreaTypeParameter(action) {
    try {
        yield put(toogleAreaTypeParameterEditionLoader(true))

        const area_type_parameter_name = action.payload.area_type_parameter_name

        const data = yield call(updateAreaTypeParameterApi,
            area_type_parameter_name,
            action.payload.form
        )

        yield put(successEditAreaTypeParameter(data))
        yield put(toogleAreaTypeParameterEditionLoader(false))
    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("500")) {
            error = {
                "message": "Server error!",
                "error_code": error_codes.ERR_INTERNAL_SERVER_ERROR
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Area type parameter was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        yield put(toogleAreaTypeParameterEditionLoader(false))
        yield put(errorCreateEditAreaTypeParameter(error))
    }
}


export function* fetchAreaTypesListWatcher() {
    yield takeLatest(actions.FETCH_AREA_TYPES_LIST, getAreaTypesList)
}

export function* fetchAreaTypeParametersListWatcher() {
    yield takeLatest(actions.FETCH_AREA_TYPE_PARAMETERS_LIST, getAreaTypeParametersList)
}

export function* removeAreaTypeWatcher() {
    yield takeLatest(actions.REMOVE_AREA_TYPE, removeAreaType)
}

export function* removeAreaTypeParameterWatcher() {
    yield takeLatest(actions.REMOVE_AREA_TYPE_PARAMETER, removeAreaTypeParameter)
}

export function* fetchAreaTypeDetailsWatcher() {
    yield takeLatest(actions.FETCH_AREA_TYPE, getAreaTypeDetails)
}

export function* fetchAreaTypeParameterDetailsWatcher() {
    yield takeLatest(actions.FETCH_AREA_TYPE_PARAMETER, getAreaTypeParameterDetails)
}

export function* createAreaTypeWatcher() {
    yield takeLatest(actions.CREATE_AREA_TYPE, createAreaType)
}

export function* createAreaTypeParameterWatcher() {
    yield takeLatest(actions.CREATE_AREA_TYPE_PARAMETER, createAreaTypeParameter)
}

export function* updateAreaTypeWatcher() {
    yield takeLatest(actions.EDIT_AREA_TYPE, editAreaType)
}

export function* updateAreaTypeParameterWatcher() {
    yield takeLatest(actions.EDIT_AREA_TYPE_PARAMETER, editAreaTypeParameter)
}

export function* addAreaTypeParameterValueWatcher() {
    yield takeLatest(actions.ADD_AREA_TYPE_PARAMETER_VALUE, addAreaTypeParameterValue)
}

export function* removeAreaTypeParameterValueWatcher() {
    yield takeLatest(actions.DELETE_AREA_TYPE_PARAMETER_VALUE, deleteAreaTypeParameterValue)
}