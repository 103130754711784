import {
    getBuildingParametersService,
    getGroupParametersService,
    putBuildingParametersService,
    putGroupParametersService
} from "../services";

export const fetchIllumanceApi = async (tenant_uuid, uuid, group_type) => {
    let fetch_function = () => { }
    switch(group_type){
        case "Building":
            fetch_function = getBuildingParametersService
            break
        default:
            fetch_function = getGroupParametersService
    }

    const response = await fetch_function(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateIllumanceApi = async (tenant_uuid, uuid, form, group_type) => {
    let update_function = () => { }
    switch(group_type){
        case "Building":
            update_function = putBuildingParametersService
            break
        default:
            update_function = putGroupParametersService
    }

    const response = await update_function(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}