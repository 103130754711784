import {
    getGroupAdvancedScheduleService,
    putGroupAdvancedScheduleService,
    putGroupAdvancedSchedulePropagationService,
} from "../services";

export const fetchGroupAdvancedScheduleApi = async (tenant_uuid, uuid) => {

    const response = await getGroupAdvancedScheduleService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateGroupAdvancedScheduleApi = async (tenant_uuid, uuid, form) => {

    const response = await putGroupAdvancedScheduleService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}