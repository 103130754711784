import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { Button, Tooltip, Form, Row, TimePicker } from 'antd'
import './styles.scss'
import * as moment from 'moment';

const dateFormat = 'HH:mm';

const _ScheduleInput = ({
  form,
  loading,
  schedule,
  handleRemoveSchedule,
  handleOnChangeSchedule
}) => {
  const { getFieldDecorator } = form
  const { start_time, stop_time, uuid, isNew } = schedule
  const [currentStart, setStart] = useState("")
  const [currentStop, setStop] = useState("")

  const handleOnChangeStart = (time, timeString) => {
    const new_start_time = changeToFormat(time)
    const stop_time_value = currentStop
    if (isValid(new_start_time, stop_time_value)) {
      setStart(new_start_time)
      handleOnChangeSchedule(uuid, new_start_time, stop_time_value, isNew)
    } else {
      setStart(new_start_time)
      setStop(getMinutesAdd(time, 1))
      handleOnChangeSchedule(uuid, new_start_time, getMinutesAdd(time, 1), isNew)
    }
  }

  const handleOnChangeStop = (time, timeString) => {
    const new_stop_time = changeToFormat(time)
    const start_time_value = currentStart
    if (isValid(start_time_value, new_stop_time)) {
      setStop(new_stop_time)
      handleOnChangeSchedule(uuid, start_time_value, new_stop_time, isNew)
    } else {
      setStart(getMinutesAdd(time, -1))
      setStop(new_stop_time)
      handleOnChangeSchedule(uuid, getMinutesAdd(time, -1), new_stop_time, isNew)
    }

  }

  const changeToFormat = time_param => {
    let time = time_param;
    if (time_param) {
    } else {
      time = moment("00:00", dateFormat)
    }
    return ((time).get('hour') + ":" + (time).get('minute')) + ":00";
  }

  const isValid = (start_param, stop_param) => {
    const start = moment(start_param, dateFormat)
    const stop = moment(stop_param, dateFormat)
    const minutes_start = (parseInt((start).get('hour')) * 60 + parseInt((start).get('minute')))
    const minutes_stop = (parseInt((stop).get('hour')) * 60 + parseInt((stop).get('minute')))
    return (minutes_start < minutes_stop)
  }

  const isMinimumDuration = (count_minutes) => {
    const start_param = currentStart
    const stop_param = currentStop
    const start = moment(start_param, dateFormat)
    const stop = moment(stop_param, dateFormat)
    const minutes_start = (parseInt((start).get('hour')) * 60 + parseInt((start).get('minute')))
    const minutes_stop = (parseInt((stop).get('hour')) * 60 + parseInt((stop).get('minute')))
    return (minutes_stop - minutes_start < count_minutes)
  }

  const getMinutes = (time) => {
    return parseInt((time).get('hour')) * 60 + parseInt((time).get('minute'));
  }

  const getMinutesAdd = (time, min) => {
    let time_minutes = getMinutes(time) + min > 1439 ? 1439 : getMinutes(time) + min < 0 ? 0 : getMinutes(time) + min;
    const hour = parseInt(time_minutes / 60)
    const minutes = time_minutes - hour * 60
    return (hour + ":" + minutes + ":00");
  }

  useEffect(() => {
    if (start_time && stop_time) {
      form.setFieldsValue({ start_time: getMomentHour(start_time), stop_time: getMomentHour(stop_time) })
    } else {
      form.setFieldsValue({ start_time: getMomentHour(currentStart), stop_time: getMomentHour(currentStop) })
    }
  }, [schedule, currentStart, currentStop])

  useEffect(() => {
    setStart(start_time)
    setStop(stop_time)
  }, [schedule])

  const getMomentHour = value_param => {
    return moment(value_param, dateFormat)
  }

  return (
    <div>
      <div className="controller-form item-uv-schedule">
        <div className="controller-form__controller">
          <Row>
            <div className="controller">
              <Form.Item>
                {getFieldDecorator('start_time', {
                  onChange: handleOnChangeStart,
                })(
                  <TimePicker
                    disabled={loading}
                    format={dateFormat}
                    className="margin-right-20"
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('stop_time', {
                  onChange: handleOnChangeStop,
                })(
                  <TimePicker
                    disabled={loading}
                    format={dateFormat}
                    className="margin-right-20"
                  />
                )}
              </Form.Item>


              {uuid
                ?
                (
                  <Tooltip title="Remove Schedule">
                    <Button
                      type="danger"
                      className="action-btn controller__remove-btn"
                      shape="circle"
                      icon="delete"
                      loading={loading}
                      onClick={() => handleRemoveSchedule(uuid, currentStart, currentStop, isNew ? isNew : false)}
                    />
                  </Tooltip>
                )
                : ""
              }
            </div>
          </Row>
          {isMinimumDuration(30) ?
            <Row><span className="error-uv-time">Error: Scheduled UV disinfection times should be a minimum of 30 minutes</span></Row>
            : (isMinimumDuration(60) ?
              <Row ><span className="warning-uv-time">Warning: The duration is lower than recommended and should be increased</span></Row>
          :<></>)}
        </div>
      </div>
    </div>
  )
}

const ScheduleInput = Form.create()(_ScheduleInput)
export default withRouter(ScheduleInput)
