import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  List,
  Row,
  Col,
  Icon,
  Tooltip,

} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../Loader";
import FrecuencyMap from "../FrecuencyMap/indexuvcontrol";
import Map from "../HeatMap";
import { UserContext } from "../../context";
import { getDataDay, getLeyend } from "./heatmapfunction";
import { symbol, getTitle, isModuleNotificatoinActive } from '../../utils/helpers';
import "./style.css";

const { HEATMAP_RADIUS_UV_CONTROL, HEATMAP_RANGE_UV_CONTROL, HEATMAP_COLOR_UV_CONTROL } = window.Configs;


const DevicesHeatMap = ({
  history,
  index,
  editabled,
  parentdata,
  parentconfig,
  updateParentConfig,
  updateParentData,
  changeLoading
  //tabKey
}) => {
  const [thedata, setThedata] = useState(
    parentdata
    /*
    { image: "", imagedisplay: "horizontal", devices: [], data: [], chartData: [] }
    */
  );
  const [theconfig, setTheconfig] = useState(
    parentconfig
    /*
    { element_uuid: parentconfig.element_uuid, shouldRenderFrecuencyMap: false, sensorName: 'temperature', day: moment().format("YYYY-MM-DD"), hour: Number(moment().format("HH")), }
    */
  );

  const [bubble, setBubble] = useState(HEATMAP_RADIUS_UV_CONTROL)

  const [dataheatmap, setDataheatmap] = useState(thedata.data);
  const [dataLoading, setDataLoading] = useState(false);
  const { userData: { tenant_uuid } } = useContext(UserContext);

  const user_modules = useSelector(state => state.rootReducer.auth.modules)

  const dispatch = useDispatch()

  useEffect(() => {
    //(async () => {
    if (thedata.update === true) {
      //setUpdatedata(false)
      //await new Promise(r => setTimeout(r, 200))

      setDataheatmap([])
    }
    //})()
  },
    [thedata.update])

  useEffect(() => {
    if (thedata.update === true) {
      //setUpdatedata(true)
      setDataheatmap(thedata.data)
      if (dataheatmap.length > 0) {
        setThedata({
          ...thedata,
          update: false,
        })
      }
    }
  },
    [dataheatmap])

  useEffect(() => {
    //console.log(thedata.image)
  },
    [thedata.image])

  const updateImageDisplay = (e) => {
    if (thedata.image !== "") {
      //const imageshow = document.getElementById('image-map-show')
      //if (imageshow) {
      if (Number(e.target.width) >= Number(e.target.height)) {
        setThedata({ ...thedata, imageDisplay: "horizontal" });
      } else {
        setThedata({ ...thedata, imageDisplay: "vertical" });
      }
      changeLoading(false);
      //}
    }
  }

  const putEmptydata = (devices) => {
    let newchartdata = [];
    let emptydevices = [];

    //for all devices put x, y, 1
    for (let index = 0; index < devices.length; index++) {
      emptydevices.push([devices[index].x, devices[index].y, "1"])
    }

    //for all hour put empty device
    for (let index = 0; index < 1; index++) {
      newchartdata.push({ devices: emptydevices })
    }
    return newchartdata;
  }

  const selectDay = async (date) => {
    if (thedata.chartData.length > 0) {
      setDataLoading(true)
      const dataday = await getDataDay(history, dispatch, tenant_uuid, { ...theconfig, day: date }, thedata.devices)

      let newchartdata = putEmptydata(thedata.devices);;
      newchartdata[0] = dataday[0];

      updateParentConfig(index, { ...theconfig, day: date })
      updateParentData(index, { ...thedata, chartData: newchartdata, data: newchartdata[0].devices })

      setDataLoading(false)

      await setThedata({
        ...thedata,
        chartData: newchartdata,
        data: dataday[0].devices,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        day: date
      })
    }
    //setUpdateDataDay(true)
  }

  const onRadiusChange = param => {
    if (typeof param === "number") {
      setBubble(param);
    }
  };

  const render = () => {
    let imageMap = true;
    if (thedata.image === "") {
      imageMap = false;
    }

    const footer = [
    ];

    //lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 20 }} 
    const content = (
      <>
        <Row>
          <Col span={19} className="img-container" style={{ width: (editabled ? "" : "100%") }}>
            {imageMap && (
              <>
                <Row className={"contenedor"}>
                  {thedata.devices && thedata.devices.map((device, indexd) => (
                    <Tooltip key={`device-tooltip-${device.id}`} title={
                      <div style={{display: "inline-grid", textAlign: "center"}}>
                      <span>{device.aws_id}</span>
                      <span>{getTitle(theconfig.sensorName)} = {(dataheatmap && dataheatmap[indexd] && dataheatmap[indexd][3] && (dataheatmap[indexd][3] !== "0" && dataheatmap[indexd][3] !== 0) ? (parseFloat(dataheatmap[indexd][3])).toFixed(2) + "" : "0.00")} {symbol(theconfig.sensorName)}</span>
                      </div>
                      }>

                      <div
                        className={`device device-${device.id} ${(dataheatmap && dataheatmap[indexd] && dataheatmap[indexd][2] && (dataheatmap[indexd][2] === "-1" || dataheatmap[indexd][2] === -1) ? "inactive" : "")}`}
                        style={{
                          position: "absolute",
                          zIndex: device.x > 0 ? 1 : 0,
                          //opacity: device.x > 0 ? 1 : 0,
                          left: device.x,
                          top: device.y,
                        }}
                      >
                        <Icon type="bulb" />
                        <div className="device-name" style={{ display: "inline-grid" }}>
                          <span>{device.aws_id}</span>
                        </div>
                      </div>
                    </Tooltip>
                  ))}
                  <img id="image-map-show"
                    className={thedata.imageDisplay}
                    //src={image}
                    src={`data:image/jpeg;base64,${thedata.image}`}
                    max-width="500px"
                    max-height="500px"
                    onLoad={updateImageDisplay}
                    alt="" />
                  {(thedata.data && thedata.data.length > 0 && !thedata.update) && (<Map datadevice={dataheatmap} radius={bubble}  heatmap_color={HEATMAP_COLOR_UV_CONTROL} renderMap={true} />)}
                
                  {isModuleNotificatoinActive(user_modules, "fireDetection") && thedata.exits && thedata.exits.length> 0 && thedata.exits.map((exit, index) => (
                    <Tooltip key={`exit-tooltip-${index}`} title={
                      <div style={{ display: "inline-grid", textAlign: "center" }}>
                        <span>{exit.exit_type === "Normal" ? "normal exit" : "fire exit"}
                        </span>
                      </div>
                    }>
                      <div
                        key={`item-exit-${index}`}
                        className={`exit device item-exit-${index}`}
                        style={{
                          position: "absolute",
                          zIndex: exit.x > 0 ? 1 : 0,
                          //opacity: exit.x > 0 ? 1 : 0,
                          left: exit.x,
                          top: exit.y,
                        }}
                      >
                        {exit.exit_type === "Normal" ? <span className="image-name text-green">NX</span> : <span className="text-red image-name">FX</span>}
                        <div className="device-name exit-name">EXIT</div>
                      </div>
                    </Tooltip>

                  ))}
                </Row>

                <Row>
                  <div style={{ margin: "10px 0px 20px 0px", textAlign: "center" }}>

                    <div style={{ width: "500px", maxWidth: "500px", margin: "auto" }}>
                      {getLeyend(theconfig.sensorName, HEATMAP_RANGE_UV_CONTROL, HEATMAP_COLOR_UV_CONTROL, [], false, true)}
                    </div>

                  </div>
                  {(true) && <FrecuencyMap config={theconfig} thedata={thedata} selectDay={selectDay} dataLoading={dataLoading} parentBubble={bubble} onRadiusChange={onRadiusChange} />}
                </Row>

              </>
            )}
            {!imageMap && (
              <Row>
                <Col>
                  No devices map available, please, edit and upload one.
              </Col>
              </Row>
            )}
          </Col>
          {(editabled ?
            <Col span={5} className="device-container">

              <List
                header={<div>Devices</div>}
                bordered
                dataSource={thedata.devices}
                style={{ maxHeight: "400px", overflow: "scroll" }}
                renderItem={(item, indexd) => <List.Item style={{ display: "block" }}><div>{item.aws_id}</div><div style={{ fontSize: "11px" }}>device {indexd + 1}</div></List.Item>}
              />

            </Col>
            :
            <></>
          )}
        </Row>
      </>
    );

    const card = (
      editabled //isLoading
        ? <Card bordered={false} actions={footer}>{content}</Card>
        :
        <>{content}</>
    )

    return (
      false //isLoading
        ? <Loader />
        :
        <>
          {card}
        </>
    );
  }

  return (
    render()
  )
}

export default DevicesHeatMap;
