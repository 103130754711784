import {
    getAllCompaniesService,
    getCompanySynopsisService,
    getBuildingSynopsisService,
    getCompanyDetailsService,
    getBuildingDetailsService,
    getGroupDetailsService,
    getUserDetailsService,
    deleteCompanyService,
    deleteBuildingService,
    deleteGroupService,
    getAllModulesService,
    getChangeElementPositionService,
} from "../services";

export const fetchElementDetailsApi = async (tenant_uuid, uuid, group_type) => {
    let fetchfunction = () => { }
    switch (group_type) {
        case "Building":
            fetchfunction = getBuildingDetailsService;
            break;
        case "Logical":
            fetchfunction = getGroupDetailsService;
            break;
        case "Control":
            fetchfunction = getGroupDetailsService;
            break;
        case "Mix":
            fetchfunction = getGroupDetailsService;
            break;
        case "User":
            fetchfunction = getUserDetailsService;
            break;
        case "Company":
            fetchfunction = getCompanyDetailsService;
            break;
        default:
            fetchfunction = undefined;
    }

    const response = await fetchfunction(tenant_uuid, uuid)
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}

export const fetchAllCompaniesApi = async (tenant_uuid) => {
    const response = await getAllCompaniesService(tenant_uuid)
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}

export const fetchCompanySynopis = async (tenant_uuid, uuid) => {
    const response = await getCompanySynopsisService(tenant_uuid, uuid)
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}

export const fetchBuildingsSynopis = async (tenant_uuid, uuid) => {
    const response = await getBuildingSynopsisService(tenant_uuid, uuid)
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}

const getRemoveFuntion = group_type => {
    let removefunction = () => { };
    switch (group_type) {
        case "Company":
            removefunction = deleteCompanyService;
            break;
        case "Building":
            removefunction = deleteBuildingService;
            break;
        default:
            removefunction = deleteGroupService;
            break;
    }
    return removefunction;
}

export const deleteElementApi = async (tenant_uuid, uuid, group_type) => {
    const removeFunction = getRemoveFuntion(group_type)
    const response = await removeFunction(tenant_uuid, uuid)
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}

export const fetchAllModulesListApi = async () => {
    const response = await getAllModulesService()
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}


export const fetchChangeElementPositionApi = async (tenant_uuid, group_uuid, parent_uuid) => {
    const response = await getChangeElementPositionService(tenant_uuid, group_uuid, parent_uuid)
    if (response && response.status === 200) {
        let data = await response.data
        return data
    }
}