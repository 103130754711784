import {
    getGroupUVControlService,
    putGroupUVControlService,
    putGroupUVControlPropagationService,
} from "../services";

export const fetchGroupUVControlApi = async (tenant_uuid, uuid) => {

    const response = await getGroupUVControlService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateGroupUVControlApi = async (tenant_uuid, uuid, form) => {

    const response = await putGroupUVControlService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}