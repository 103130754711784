import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v1'
import { Tooltip, Button, Form, message, Modal, Empty, Notification, Row, Col, Card, Icon } from 'antd'
import { UserContext } from "../../context";
import { withRouter } from "react-router-dom"
import ControllerInput from '../ControllerInput'
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import * as group_constants from '../../constants/groups'
import './styles.css'

const ButtonGroup = Button.Group

const _Controllers = ({
  form,
  history,
  isModalVisible,
}) => {

  const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const loading = useSelector(state => state.rootReducer.loaders.loadingControllers)
  const groupControllers = useSelector(state => state.rootReducer.controllers.controllers)
  const error = useSelector((state) => state.rootReducer.controllers.error)
  const added = useSelector((state) => state.rootReducer.controllers.added)
  const removed = useSelector((state) => state.rootReducer.controllers.removed)
  const [currentGroupControllers, setCurrentGroupControllers] = useState([])


  const dispatch = useDispatch()

  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)

  const handleAddNewControllerInput = e => {
    setCurrentGroupControllers(currentGroupControllers.concat({ id: uuid(), isNew: true }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, param) => {
      if (!err) {
        dispatch(({
          type: actions.ADD_DEVICES,
          payload: { tenant_uuid: tenant_uuid, data: currentGroupControllers, uuid: elementData.uuid }
        }))
        dispatch(({ type: actions.REDIRECT_DETAILS, payload: { redirect: true } }))
      }
    })
  }

  const handleOnChangeController = (id, value) => {
    const tmp_controllers = currentGroupControllers.map(controller => {
      if (controller["id"] === id) {
        return { ...controller, value: value }
      } else {
        return controller
      }
    })

    setCurrentGroupControllers(tmp_controllers)
  }

  const handleRemoveController = async (aws_id) => {
    dispatch(({
      type: actions.REMOVE_DEVICE,
      payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, aws_id: aws_id }
    }))
    dispatch(({ type: actions.REDIRECT_DETAILS, payload: { redirect: true } }))
  }

  useEffect(
    () => {
      (async () => {
        if (elementData.group_type !== group_constants.GROUP_TYPE_LOGICAL) {
          dispatch(({
            type: actions.FETCH_DEVICES,
            payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
          }))
        } 
        
        return () => {
          if (elementData.group_type !== group_constants.GROUP_TYPE_LOGICAL) {
            dispatch({ type: actions.CLEAN_DEVICES_STATE })
          }
        }

      })();
    },

    [isModalVisible]
  )



  /*useEffect(
    () => {
      if (!isModalVisible) {
  
      }
    },
    [isModalVisible]
  )*/

  useEffect(
    () => {
      if (groupControllers !== null) {
        setCurrentGroupControllers(groupControllers)
        if (added) {
          message.success("Successfully Added New Device(s)")
        }
        if (removed) {
          message.success("Removed Device(s) Successfully")
        }
      } else {
        setCurrentGroupControllers([])
      }
    },
    [groupControllers]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 })
          history.push("/login")
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({
                type: actions.FETCH_DEVICES,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 })
          dispatch({ type: actions.CLEAN_DEVICES_ERROR })
        }
      }
    },
    [error]
  )

  return (
    <div className="controllers">
      <Row gutter={24}>
        {
          elementData && elementData.group_type && (elementData.group_type === group_constants.GROUP_TYPE_CONTROL || elementData.group_type === group_constants.GROUP_TYPE_MIX) && (
            <Col xs={16}>
              <Card>
                <h3><Icon type="control" /> Controllers</h3>
                <hr />
                <Form onSubmit={handleSubmit}>
                  {currentGroupControllers &&
                    currentGroupControllers.length > 0 &&
                    currentGroupControllers.map(controller => (
                      <ControllerInput
                        handleRemoveController={handleRemoveController}
                        handleOnChangeController={handleOnChangeController}
                        loading={loading}
                        key={controller.id}
                        controller={controller}
                      />
                    ))}
                  {currentGroupControllers.length === 0 && (<Empty />)}
                  <br />
                  <ButtonGroup>
                    <Tooltip title="Add controller">
                      <Button
                        icon="plus"
                        type="primary"
                        className="add-schedule-btn"
                        onClick={handleAddNewControllerInput}
                        loading={loading}
                      >
                        Controller
                    </Button>
                    </Tooltip>
                    <Tooltip title="Save">
                      <Button
                        icon="save"
                        type="primary"
                        className="add-schedule-btn"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={groupControllers === currentGroupControllers}
                      >
                        Save
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </Form>
              </Card>
            </Col>
          )
        }
      </Row>
      <br />
      <br />
    </div>
  )
}

const Controllers = Form.create()(_Controllers)
export default withRouter(Controllers)
