import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchLightsLevelOn = (tenant_uuid, uuid ) => action(actions.FETCH_LIGHTS_LEVELS_ON, {tenant_uuid, uuid})

export const updateLightsLevelOn = (tenant_uuid, uuid, form ) => action(actions.UPDATE_LIGHTS_LEVELS_ON, {tenant_uuid, uuid, form})

export const successFetchUpdateLightsLevelOn = data => action(actions.SUCCESS_FETCH_UPDATE_LIGHTS_LEVELS_ON, { data })

export const errorFetchUpdateLightsLevelOn = error => action(actions.ERROR_FETCH_UPDATE_LIGHTS_LEVELS_ON, { error })

export const cleanLightsControlError = () => action(actions.CLEAN_LIGHTS_CONTROL_ERROR)

export const cleanLightsControlState = () => action(actions.CLEAN_LIGHTS_CONTROL_STATE)