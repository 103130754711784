import * as actions from '../constants';

const initialState = {
  editCreatedElement: null,
  error: null,
}

const createEditElement = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_CREATE_EDIT_ELEMENT:
      return Object.assign({}, state, {
        editCreatedElement: action.payload.data,
        error: null,
      })
    case actions.ERROR_CREATE_EDIT_ELEMENT:
        return Object.assign({}, state, {
          editCreatedElement: null, 
          error: action.payload.error
        })
    case actions.CLEAN_CREATION_EDIT_ELEMENT_STATE:
        return Object.assign({}, state, {
          editCreatedElement: null,
          error: null,
        })
    default:
      return state
  }
}

export default createEditElement