// constant for action type for company managment actions
export const FETCH_ELEMENT = 'FETCH_ELEMENT'
export const SUCCESS_FETCH_ELEMENT = 'SUCCESS_FETCH_ELEMENT'
export const FETCH_COMPANY_TREE_DATA = 'FETCH_COMPANY_TREE_DATA'
export const SUCCESS_FETCH_COMPANY_TREE_DATA = 'SUCCESS_FETCH_COMPANY_TREE_DATA'
export const DELETE_ELEMENT = 'DELETE_ELEMENT'
export const ERROR_FETCH_ELEMENT = 'ERROR_FETCH_ELEMENT'
export const CLEAN_COMPANY_MANAGMENT_STATES = 'CLEAN_COMPANY_MANAGMENT_STATES'
export const HANDLE_TREE_DATA = 'HANDLE_TREE_DATA'
export const HANDLE_TREE_DATA_POSITION = 'HANDLE_TREE_DATA_POSITION'
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE'
export const SET_CLICK_POSITION = 'SET_CLICK_POSITION'
export const RESET_DELETE_COMPANY_ELEMENT_POPUP = 'RESET_DELETE_COMPANY_ELEMENT_POPUP'
export const CHANGE_ELEMENT_POSITION_TREE_DATA = 'CHANGE_ELEMENT_POSITION_TREE_DATA'
export const UPDATE_CURRENT_ELEMENT_DATA = 'UPDATE_CURRENT_ELEMENT_DATA'

// constants for element creation and edition
export const CREATE_ELEMENT = 'CREATE_ELEMENT'
export const EDIT_ELEMENT = 'EDIT_ELEMENT'
export const SUCCESS_CREATE_EDIT_ELEMENT = 'SUCCESS_CREATE_EDIT_ELEMENT'
export const ERROR_CREATE_EDIT_ELEMENT = 'ERROR_CREATE_EDIT_ELEMENT'
export const CLEAN_CREATION_EDIT_ELEMENT_STATE = 'CLEAN_CREATION_EDIT_ELEMENT_STATE'

//constants for latest device data
export const FETCH_LATEST_DATA = 'FETCH_LATEST_DATA'
export const SUCCESS_FETCH_LATEST_DATA = 'SUCCESS_FETCH_LATEST_DATA'
export const FILTER_SENSOR_DATA = 'FILTER_SENSOR_DATA'
export const ERROR_FETCH_LATEST_DATA = 'ERROR_FETCH_LATEST_DATA'
export const CLEAN_LATEST_DATA_ERROR = 'CLEAN_LATEST_DATA_ERROR'
export const CLEAN_LATEST_DATA_STATE = 'CLEAN_LATEST_DATA_STATE'

//constants for charts data
export const FETCH_CHART_DATA = 'FETCH_CHART_DATA'
export const SUCCESS_FETCH_CHART_DATA = 'SUCCESS_FETCH_CHART_DATA'
export const ERROR_FETCH_CHART_DATA = 'ERROR_FETCH_CHART_DATA'
export const CLEAN_CHART_DATA_ERROR = 'CLEAN_CHART_DATA_ERROR'
export const CLEAN_CHART_DATA_STATE = 'CLEAN_CHART_DATA_STATE'

// constant for illuminance settings
export const FETCH_ILLUMINANCE = 'FETCH_ILLUMINANCE'
export const UPDATE_ILLUMINANCE = 'UPDATE_ILLUMINANCE'
export const SUCCESS_FETCH_UPDATE_ILLUMINANCE = 'SUCCESS_FETCH_UPDATE_ILLUMINANCE'
export const ERROR_FETCH_UPDATE_ILLUMINANCE = 'ERROR_FETCH_UPDATE_ILLUMINANCE'
export const CLEAN_ILLUMINANCE_ERROR = 'CLEAN_ILLUMINANCE_ERROR'
export const CLEAN_ILLUMINANCE_STATE = 'CLEAN_ILLUMINANCE_STATE'

// constants for group lights control
export const FETCH_LIGHTS_LEVELS_ON = 'FETCH_LIGHTS_LEVELS_ON'
export const UPDATE_LIGHTS_LEVELS_ON = 'UPDATE_LIGHTS_LEVELS_ON'
export const SUCCESS_FETCH_UPDATE_LIGHTS_LEVELS_ON = 'SUCCESS_FETCH_UPDATE_LIGHTS_LEVELS_ON'
export const ERROR_FETCH_UPDATE_LIGHTS_LEVELS_ON = 'ERROR_FETCH_UPDATE_LIGHTS_LEVELS_ON'
export const CLEAN_LIGHTS_CONTROL_ERROR = 'CLEAN_LIGHTS_CONTROL_ERROR'
export const CLEAN_LIGHTS_CONTROL_STATE = 'CLEAN_LIGHTS_CONTROL_STATE'

// constants for devices (controllers) managment
export const FETCH_DEVICES = 'FETCH_DEVICES'
export const ADD_DEVICES = 'ADD_DEVICES'
export const REMOVE_DEVICE = 'REMOVE_DEVICE'
export const SUCCESS_FETCH_DEVICES = 'SUCCESS_FETCH_DEVICES'
export const SUCCESS_ADD_DEVICES = 'SUCCESS_ADD_DEVICES'
export const SUCCESS_REMOVE_DEVICE = 'SUCCESS_REMOVE_DEVICE'
export const ERROR_FETCH_UPDATE_DEVICES = 'ERROR_FETCH_UPDATE_DEVICES'
export const CLEAN_DEVICES_ERROR = 'CLEAN_DEVICES_ERROR'
export const CLEAN_DEVICES_STATE = 'CLEAN_DEVICES_STATE'

// constants for power calibration managment
export const FETCH_POWER_CALIBRATION_DATA = 'FETCH_POWER_CALIBRATION_DATA'
export const SUCCESS_FETCH_POWER_CALIBRATION_DATA = 'SUCCESS_FETCH_POWER_CALIBRATION_DATA'
export const SAVE_POWER_CALIBRATION_SETUP = 'SAVE_POWER_CALIBRATION_SETUP'
export const SUCCESS_SAVE_POWER_CALIBRATION_SETUP = 'SUCCESS_SAVE_POWER_CALIBRATION_SETUP'
export const ERROR_FETCH_UPDATE_POWER_CALIBRATION = 'ERROR_FETCH_UPDATE_POWER_CALIBRATION'
export const CLEAN_POWER_CALIBRATION_ERROR = 'CLEAN_POWER_CALIBRATION_ERROR'
export const CLEAN_POWER_CALIBRATION_STATE = 'CLEAN_POWER_CALIBRATION_STATE'

// constants for blue light boost
export const FETCH_BLUE_LIGHT_BOOST_DATA = 'FETCH_BLUE_LIGHT_BOOST_DATA'
export const SUCCESS_FETCH_BLUE_LIGHT_BOOST_DATA = 'SUCCESS_FETCH_BLUE_LIGHT_BOOST_DATA'
export const ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST = 'ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST'
export const SUCCESS_ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST = 'SUCCESS_ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST'
export const ERROR_BLUE_LIGHT_BOOST = 'ERROR_BLUE_LIGHT_BOOST'
export const CLEAN_BLUE_LIGHT_BOOST_ERROR = 'CLEAN_BLUE_LIGHT_BOOST_ERROR'
export const CLEAN_BLUE_LIGHT_BOOST_STATE = 'CLEAN_BLUE_LIGHT_BOOST_STATE'

// constants for circadian rhythm
export const FETCH_CIRCADIAN_RHYTHM_DATA = 'FETCH_CIRCADIAN_RHYTHM_DATA'
export const SUCCESS_FETCH_CIRCADIAN_RHYTHM_DATA = 'SUCCESS_FETCH_CIRCADIAN_RHYTHM_DATA'
export const UPDATE_CIRCADIAN_RHYTHM = 'UPDATE_CIRCADIAN_RHYTHM'
export const SUCCESS_UPDATE_CIRCADIAN_RHYTHM = 'SUCCESS_UPDATE_CIRCADIAN_RHYTHM'
export const ERROR_CIRCADIAN_RHYTHM = 'ERROR_CIRCADIAN_RHYTHM'
export const CLEAN_CIRCADIAN_RHYTHM_ERROR = 'CLEAN_CIRCADIAN_RHYTHM_ERROR'
export const CLEAN_CIRCADIAN_RHYTHM_STATE = 'CLEAN_CIRCADIAN_RHYTHM_STATE'

// constants for aggregation requests
export const FETCH_AGGREGATION_REQUEST_DATA = 'FETCH_AGGREGATION_REQUEST_DATA'
export const SUCCESS_FETCH_AGGREGATION_REQUEST_DATA = 'SUCCESS_FETCH_AGGREGATION_REQUEST_DATA'
export const SAVE_AGGREGATION_REQUEST_SETUP = 'SAVE_AGGREGATION_REQUEST_SETUP'
export const SUCCESS_SAVE_AGGREGATION_REQUEST_SETUP = 'SUCCESS_SAVE_AGGREGATION_REQUEST_SETUP'
export const ERROR_FETCH_UPDATE_AGGREGATION_REQUEST = 'ERROR_FETCH_UPDATE_AGGREGATION_REQUEST'
export const CLEAN_AGGREGATION_REQUEST_ERROR = 'CLEAN_AGGREGATION_REQUEST_ERROR'
export const CLEAN_AGGREGATION_REQUEST_STATE = 'CLEAN_AGGREGATION_REQUEST_STATE'

// constants for light capability managment
export const SUCCESS_FETCH_LIGHT_CAPABILITY_DATA = 'SUCCESS_FETCH_LIGHT_CAPABILITY_DATA'

// constants for loader actions
export const TOOGLE_FETCHING_COMPANY_TREE_LOADER = 'TOOGLE_FETCHING_COMPANY_TREE_LOADER'
export const TOOGLE_FETCHING_ELEMENT_LOADER = 'TOOGLE_FETCHING_ELEMENT_LOADER'
export const TOOGLE_CREATE_EDIT_ELEMENT_LOADER = 'TOOGLE_CREATE_EDIT_ELEMENT_LOADER'
export const TOOGLE_LATEST_DATA_LOADER = 'TOOGLE_LATEST_DATA_LOADER'
export const TOOGLE_CHART_DATA_LOADER = 'TOOGLE_CHART_DATA_LOADER'
export const TOOGLE_ILLUMINANCE_LOADER = 'TOOGLE_ILLUMINANCE_LOADER'
export const TOOGLE_LIGHTS_CONTROL_LOADER = 'TOOGLE_LIGHTS_CONTROL_LOADER'
export const TOOGLE_CONTROLLERS_LOADER = 'TOOGLE_CONTROLLERS_LOADER'
export const TOOGLE_POWER_CALIBRATION_LOADER = 'TOOGLE_POWER_CALIBRATION_LOADER'
export const TOOGLE_BLUE_LIGHT_BOOST_LOADER = 'TOOGLE_BLUE_LIGHT_BOOST_LOADER'
export const TOOGLE_CIRCADIAN_RHYTHM_LOADER = 'TOOGLE_CIRCADIAN_RHYTHM_LOADER'
export const TOOGLE_AGGREGATION_REQUEST_LOADER = 'TOOGLE_AGGREGATION_REQUEST_LOADER'
export const TOOGLE_LOGIN_LOADER = 'TOOGLE_LOGIN_LOADER'
export const TOOGLE_USERS_LOADER = 'TOOGLE_USERS_LOADER'
export const TOOGLE_USER_EDITION_LOADER = 'TOOGLE_USER_EDITION_LOADER'
export const TOOGLE_PEOPLEANALYTICS_LOADER = 'TOOGLE_PEOPLEANALYTICS_LOADER'
export const TOOGLE_UV_CONTROL_LOADER = 'TOOGLE_UV_CONTROL_LOADER'
export const TOOGLE_ADVANCED_SCHEDULE_LOADER = 'TOOGLE_ADVANCED_SCHEDULE_LOADER'
export const TOOGLE_SOUND_MASKING_LOADER = 'TOOGLE_SOUND_MASKING_LOADER'
// toogle loaders for area type parameters
export const TOOGLE_AREA_TYPES_LOADER = 'TOOGLE_AREA_TYPES_LOADER'
export const TOOGLE_AREA_TYPE_PARAMETERS_LOADER = 'TOOGLE_AREA_TYPE_PARAMETERS_LOADER'
export const TOOGLE_AREA_TYPE_EDITION_LOADER = 'TOOGLE_AREA_TYPE_EDITION_LOADER'
export const TOOGLE_AREA_TYPE_PARAMETER_VALUE_EDITION = 'TOOGLE_AREA_TYPE_PARAMETER_VALUE_EDITION'
export const TOOGLE_AREA_TYPE_PARAMETER_EDITION = 'TOOGLE_AREA_TYPE_PARAMETER_EDITION'
// toogle for user password reset
export const TOOGLE_REQUEST_PASSWORD_RESET_LOADER = 'TOOGLE_REQUEST_PASSWORD_RESET_LOADER'
export const TOOGLE_CHANGE_PASSWORD_TOKEN_LOADER = 'TOOGLE_CHANGE_PASSWORD_TOKEN_LOADER'
// toogle for notification
export const TOOGLE_NOTIFICATION_LOADER = 'TOOGLE_NOTIFICATION_LOADER'
export const TOOGLE_USER_NOTIFICATION_LOADER = 'TOOGLE_USER_NOTIFICATION_LOADER'

export const TOOGLE_SEND_NOTIFICATION_LOADER = 'TOOGLE_SEND_NOTIFICATION_LOADER'
export const TOOGLE_USER_SEND_NOTIFICATION_LOADER = 'TOOGLE_USER_SEND_NOTIFICATION_LOADER'

export const TOOGLE_CREATE_NOTIFICATION_LOADER = 'TOOGLE_CREATE_NOTIFICATION_LOADER'
export const TOOGLE_ELEMENTS_NOTIFICATION_LOADER = 'TOOGLE_ELEMENTS_NOTIFICATION_LOADER'
// toogle for user profile
export const TOOGLE_GET_USER_2FA_QR_LOADER = 'TOOGLE_GET_USER_2FA_QR_LOADER'
export const TOOGLE_USER_PROFILE_LOADER = 'TOOGLE_USER_PROFILE_LOADER'
// toogle energy analysis overview
export const TOOGLE_ENERGY_ANALYSIS_OVERVIEW_LOADER = 'TOOGLE_ENERGY_ANALYSIS_OVERVIEW_LOADER'
export const TOOGLE_ENERGY_CONSUMPTION_OVERVIEW_LOADER = 'TOOGLE_ENERGY_CONSUMPTION_OVERVIEW_LOADER'
export const TOOGLE_ENERGY_CHART_LOADER = 'TOOGLE_ENERGY_CHART_LOADER'


// constant for auth and logout
export const LOG_IN = 'LOG_IN'
export const SUCCESS_2FA_FIRST_STEP = 'SUCCESS_2FA_FIRST_STEP'
export const SECOND_STEP_2FA = 'SECOND_STEP_2FA'
export const SUCCESS_LOG_IN = 'SUCCESS_LOG_IN'
export const ERROR_LOG_IN = 'ERROR_LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const CLEAN_2FA_FIRST_STEP_STATE = 'CLEAN_2FA_FIRST_STEP_STATE'
export const CLEAN_2FA_SECOND_STEP_STATE = 'CLEAN_2FA_SECOND_STEP_STATE'
// constant for user
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

// user managment constants
export const FETCH_USERS_DATA = 'FETCH_USERS_DATA'
export const SUCCESS_FETCH_USERS_DATA = 'SUCCESS_FETCH_USERS_DATA'
export const ERROR_FETCH_USERS_DATA = 'ERROR_FETCH_USERS_DATA'
export const FETCH_USER = 'FETCH_USER'
export const SUCCESS_FETCH_USER = 'SUCCESS_FETCH_USER'
export const ERROR_FETCH_USER = 'ERROR_FETCH_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const SUCCESS_REMOVE_USER = 'SUCCESS_REMOVE_USER'
export const ERROR_REMOVE_USER = 'ERROR_REMOVE_USER'
export const CREATE_USER = 'CREATE_USER'
export const SUCCESS_CREATE_USER = 'SUCCESS_CREATE_USER'
export const EDIT_USER = 'EDIT_USER'
export const SUCCESS_EDIT_USER = 'SUCCESS_EDIT_USER'
export const ERROR_CREATE_EDIT_USER = 'ERROR_CREATE_EDIT_USER'
export const USER_FETCH_COMPANIES_LIST = 'USER_FETCH_COMPANIES_LIST'
export const USER_SUCCESS_FETCH_COMPANIES_LIST = 'USER_SUCCESS_FETCH_COMPANIES_LIST'
export const USER_FETCH_COMPANY_BUILDINGS = 'USER_FETCH_COMPANY_BUILDINGS'
export const USER_SUCCESS_FETCH_COMPANY_BUILDINGS = 'USER_SUCCESS_FETCH_COMPANY_BUILDINGS'
export const USER_FETCH_BUILDING_GROUPS = 'USER_FETCH_BUILDING_GROUPS'
export const USER_SUCCESS_FETCH_BUILDING_GROUPS = 'USER_SUCCESS_FETCH_BUILDING_GROUPS'
export const USER_FETCH_GROUP_CHILDREN = 'USER_FETCH_GROUP_CHILDREN'
export const USER_SUCCESS_FETCH_GROUP_CHILDREN  = 'USER_SUCCESS_FETCH_GROUP_CHILDREN'
export const SET_IS_USER_CREATED_UPDATED = 'SET_IS_USER_CREATED_UPDATED'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const SUCCESS_RESET_USER_PASSWORD = 'SUCCESS_RESET_USER_PASSWORD'
export const CLEAN_BUILDINGS_CURRENT_OPTIONS = 'CLEAN_BUILDINGS_CURRENT_OPTIONS'
export const CLEAN_GROUPS_CURRENT_OPTIONS = 'CLEAN_GROUPS_CURRENT_OPTIONS'
export const CLEAN_USER_MANAGMENT_STATE = 'CLEAN_USER_MANAGMENT_STATE'
export const CLEAN_USER_CREATION_EDITION_STATES = 'CLEAN_USER_CREATION_EDITION_STATES'
export const CLEAN_USER_RESET_PASSWORD_STATES = 'CLEAN_USER_RESET_PASSWORD_STATES'

// constant for user's reset password
export const REQUEST_USER_PASSWORD_RESET = 'REQUEST_USER_PASSWORD_RESET'
export const SUCCESS_REQUEST_USER_PASSWORD_RESET = 'SUCCESS_REQUEST_USER_PASSWORD_RESET'
export const ERROR_REQUEST_USER_PASSWORD_RESET = 'ERROR_REQUEST_USER_PASSWORD_RESET'

export const CHANGE_USER_PASSWORD_TOKEN = 'CHANGE_USER_PASSWORD_TOKEN'
export const SUCCESS_CHANGE_USER_PASSWORD_TOKEN = 'SUCCESS_CHANGE_USER_PASSWORD_TOKEN'
export const ERROR_CHANGE_USER_PASSWORD_TOKEN = 'ERROR_CHANGE_USER_PASSWORD_TOKEN'

export const CLEAN_USER_RESET_PASSWORD_STATE = 'CLEAN_USER_RESET_PASSWORD_STATE'

// constants for user profile
export const FETCH_USER_2FA_QR = 'FETCH_USER_2FA_QR'
export const SUCCESS_FETCH_USER_2FA_QR = 'SUCCESS_FETCH_USER_2FA_QR'
export const ERROR_FETCH_USER_2FA_QR = 'ERROR_FETCH_USER_2FA_QR'
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE'
export const SUCCESS_FETCH_USER_PROFILE = 'SUCCESS_FETCH_USER_PROFILE'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const SUCCESS_UPDATE_USER_PROFILE = 'SUCCESS_UPDATE_USER_PROFILE'
export const ERROR_FETCH_UPDATE_USER_PROFILE = 'ERROR_FETCH_UPDATE_USER_PROFILE'
export const CLEAN_USER_PROFILE_STATE = 'CLEAN_USER_PROFILE_STATE'


// constant for redirect to heatmap
export const REDIRECT_HEATMAP = 'REDIRECT_HEATMAP'
export const REDIRECT_DETAILS = 'REDIRECT_DETAILS'
export const REDIRECT_DEVICES = 'REDIRECT_DEVICES'

//constant for people analytics
export const FETCH_PEOPLEANALYTICS = 'FETCH_PEOPLEANALYTICS'
export const UPDATE_PEOPLEANALYTICS = 'UPDATE_PEOPLEANALYTICS'
export const SUCCESS_FETCH_UPDATE_PEOPLEANALYTICS = 'SUCCESS_FETCH_UPDATE_PEOPLEANALYTICS'
export const ERROR_FETCH_UPDATE_PEOPLEANALYTICS = 'ERROR_FETCH_UPDATE_PEOPLEANALYTICS'
export const CLEAN_PEOPLEANALYTICS_ERROR = 'CLEAN_PEOPLEANALYTICS_ERROR'
export const CLEAN_PEOPLEANALYTICS_STATE = 'CLEAN_PEOPLEANALYTICS_STATE'

// constants for group uv control
export const FETCH_UV_CONTROL = 'FETCH_UV_CONTROL'
export const UPDATE_UV_CONTROL = 'UPDATE_UV_CONTROL'
export const SUCCESS_FETCH_UPDATE_UV_CONTROL = 'SUCCESS_FETCH_UPDATE_UV_CONTROL'
export const ERROR_FETCH_UPDATE_UV_CONTROL = 'ERROR_FETCH_UPDATE_UV_CONTROL'
export const CLEAN_UV_CONTROL_ERROR = 'CLEAN_UV_CONTROL_ERROR'
export const CLEAN_UV_CONTROL_STATE = 'CLEAN_UV_CONTROL_STATE'

// constants for group advanced schedule
export const FETCH_ADVANCED_SCHEDULE = 'FETCH_ADVANCED_SCHEDULE'
export const UPDATE_ADVANCED_SCHEDULE = 'UPDATE_ADVANCED_SCHEDULE'
export const SUCCESS_FETCH_UPDATE_ADVANCED_SCHEDULE = 'SUCCESS_FETCH_UPDATE_ADVANCED_SCHEDULE'
export const ERROR_FETCH_UPDATE_ADVANCED_SCHEDULE = 'ERROR_FETCH_UPDATE_ADVANCED_SCHEDULE'
export const CLEAN_ADVANCED_SCHEDULE_ERROR = 'CLEAN_ADVANCED_SCHEDULE_ERROR'
export const CLEAN_ADVANCED_SCHEDULE_STATE = 'CLEAN_ADVANCED_SCHEDULE_STATE'

// constants for area type parameters managment actions
export const FETCH_AREA_TYPES_LIST = 'FETCH_AREA_TYPES_LIST'
export const SUCCESS_FETCH_AREA_TYPES_LIST = 'SUCCESS_FETCH_AREA_TYPES_LIST'
export const ERROR_FETCH_AREA_TYPES_LIST = 'ERROR_FETCH_AREA_TYPES_LIST'
export const FETCH_AREA_TYPE = 'FETCH_AREA_TYPE'
export const SUCCESS_FETCH_AREA_TYPE = 'SUCCESS_FETCH_AREA_TYPE'
export const ERROR_FETCH_AREA_TYPE = 'ERROR_FETCH_AREA_TYPE'
export const REMOVE_AREA_TYPE = 'REMOVE_AREA_TYPE'
export const SUCCESS_REMOVE_AREA_TYPE = 'SUCCESS_REMOVE_AREA_TYPE'
export const ERROR_REMOVE_AREA_TYPE = 'ERROR_REMOVE_AREA_TYPE'
export const CREATE_AREA_TYPE = 'CREATE_AREA_TYPE'
export const SUCCESS_CREATE_AREA_TYPE = 'SUCCESS_CREATE_AREA_TYPE'
export const EDIT_AREA_TYPE = 'EDIT_AREA_TYPE'
export const SUCCESS_EDIT_AREA_TYPE = 'SUCCESS_EDIT_AREA_TYPE'
export const UPDATE_AREA_TYPE_ALLOWED_PARAMETERS = 'UPDATE_AREA_TYPE_ALLOWED_PARAMETERS'
export const ADD_AREA_TYPE_PARAMETER_VALUE = 'ADD_AREA_TYPE_PARAMETER_VALUE'
export const ADD_AREA_TYPE_PARAMETER_VALUE_EMPTY_FORM = 'ADD_AREA_TYPE_PARAMETER_VALUE_EMPTY_FORM'
export const SUCCESS_ADD_AREA_TYPE_PARAMETER_VALUE = 'SUCCESS_ADD_AREA_TYPE_PARAMETER_VALUE'
export const DELETE_AREA_TYPE_PARAMETER_VALUE = 'DELETE_AREA_TYPE_PARAMETER_VALUE'
export const SUCCESS_DELETE_AREA_TYPE_PARAMETER_VALUE = 'SUCCESS_DELETE_AREA_TYPE_PARAMETER_VALUE'
export const ERROR_CREATE_EDIT_AREA_TYPE = 'ERROR_CREATE_EDIT_AREA_TYPE'
// -------------------------------------------------------------------------------
export const FETCH_AREA_TYPE_PARAMETERS_LIST = 'FETCH_AREA_TYPE_PARAMETERS_LIST'
export const SUCCESS_FETCH_AREA_TYPE_PARAMETERS_LIST = 'SUCCESS_FETCH_AREA_TYPE_PARAMETERS_LIST'
export const ERROR_FETCH_AREA_TYPE_PARAMETERS_LIST = 'ERROR_FETCH_AREA_TYPE_PARAMETERS_LIST'
export const FETCH_AREA_TYPE_PARAMETER = 'FETCH_AREA_TYPE_PARAMETER'
export const SUCCESS_FETCH_AREA_TYPE_PARAMETER = 'SUCCESS_FETCH_AREA_TYPE_PARAMETER'
export const ERROR_FETCH_AREA_TYPE_PARAMETER = 'ERROR_FETCH_AREA_TYPE_PARAMETER'
export const REMOVE_AREA_TYPE_PARAMETER = 'REMOVE_AREA_TYPE_PARAMETER'
export const SUCCESS_REMOVE_AREA_TYPE_PARAMETER = 'SUCCESS_REMOVE_AREA_TYPE_PARAMETER'
export const ERROR_REMOVE_AREA_TYPE_PARAMETER = 'ERROR_REMOVE_AREA_TYPE_PARAMETER'
export const CREATE_AREA_TYPE_PARAMETER = 'CREATE_AREA_TYPE_PARAMETER'
export const SUCCESS_CREATE_AREA_TYPE_PARAMETER = 'SUCCESS_CREATE_AREA_TYPE_PARAMETER'
export const EDIT_AREA_TYPE_PARAMETER = 'EDIT_AREA_TYPE_PARAMETER'
export const SUCCESS_EDIT_AREA_TYPE_PARAMETER = 'SUCCESS_EDIT_AREA_TYPE_PARAMETER'
export const ERROR_CREATE_EDIT_AREA_TYPE_PARAMETER = 'ERROR_CREATE_EDIT_AREA_TYPE_PARAMETER'
export const CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES = 'CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES'
export const CLEAN_AREA_TYPE_PARAMETERS_MANAGMENT_STATE = 'CLEAN_AREA_TYPE_PARAMETERS_MANAGMENT_STATE'

//constant for notification
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const SUCCESS_FETCH_UPDATE_NOTIFICATION = 'SUCCESS_FETCH_UPDATE_NOTIFICATION'
export const ERROR_FETCH_UPDATE_NOTIFICATION = 'ERROR_FETCH_UPDATE_NOTIFICATION'
export const CLEAN_NOTIFICATION_ERROR = 'CLEAN_NOTIFICATION_ERROR'
export const CLEAN_NOTIFICATION_STATE = 'CLEAN_NOTIFICATION_STATE'

export const FETCH_USER_NOTIFICATION = 'FETCH_USER_NOTIFICATION'
export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION'
export const SUCCESS_FETCH_UPDATE_USER_NOTIFICATION = 'SUCCESS_FETCH_UPDATE_USER_NOTIFICATION'
export const ERROR_FETCH_UPDATE_USER_NOTIFICATION = 'ERROR_FETCH_UPDATE_USER_NOTIFICATION'
export const CLEAN_USER_NOTIFICATION_ERROR = 'CLEAN_USER_NOTIFICATION_ERROR'
export const CLEAN_USER_NOTIFICATION_STATE = 'CLEAN_USER_NOTIFICATION_STATE'

//send
export const FETCH_SEND_NOTIFICATION = 'FETCH_SEND_NOTIFICATION'
export const UPDATE_SEND_NOTIFICATION = 'UPDATE_SEND_NOTIFICATION'
export const SUCCESS_FETCH_UPDATE_SEND_NOTIFICATION = 'SUCCESS_FETCH_UPDATE_SEND_NOTIFICATION'
export const ERROR_FETCH_UPDATE_SEND_NOTIFICATION = 'ERROR_FETCH_UPDATE_SEND_NOTIFICATION'
export const CLEAN_SEND_NOTIFICATION_ERROR = 'CLEAN_SEND_NOTIFICATION_ERROR'
export const CLEAN_SEND_NOTIFICATION_STATE = 'CLEAN_SEND_NOTIFICATION_STATE'

export const FETCH_USER_SEND_NOTIFICATION = 'FETCH_USER_SEND_NOTIFICATION'
export const UPDATE_USER_SEND_NOTIFICATION = 'UPDATE_USER_SEND_NOTIFICATION'
export const SUCCESS_FETCH_UPDATE_USER_SEND_NOTIFICATION = 'SUCCESS_FETCH_UPDATE_USER_SEND_NOTIFICATION'
export const ERROR_FETCH_UPDATE_USER_SEND_NOTIFICATION = 'ERROR_FETCH_UPDATE_USER_SEND_NOTIFICATION'
export const CLEAN_USER_SEND_NOTIFICATION_ERROR = 'CLEAN_USER_SEND_NOTIFICATION_ERROR'
export const CLEAN_USER_SEND_NOTIFICATION_STATE = 'CLEAN_USER_SEND_NOTIFICATION_STATE'

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const SUCCESS_CREATE_NOTIFICATION = 'SUCCESS_CREATE_NOTIFICATION'
export const ERROR_CREATE_NOTIFICATION = 'ERROR_CREATE_NOTIFICATION'
export const CLEAN_CREATE_NOTIFICATION = 'CLEAN_CREATE_NOTIFICATION'

//toast
export const FETCH_TOAST_NOTIFICATION = 'FETCH_TOAST_NOTIFICATION'
export const UPDATE_TOAST_NOTIFICATION = 'UPDATE_TOAST_NOTIFICATION'
export const SUCCESS_FETCH_UPDATE_TOAST_NOTIFICATION = 'SUCCESS_FETCH_UPDATE_TOAST_NOTIFICATION'
export const REMOVE_TOAST_NOTIFICATION = 'REMOVE_TOAST_NOTIFICATION'
export const ERROR_FETCH_UPDATE_TOAST_NOTIFICATION = 'ERROR_FETCH_UPDATE_TOAST_NOTIFICATION'
export const CLEAN_TOAST_NOTIFICATION_ERROR = 'CLEAN_TOAST_NOTIFICATION_ERROR'
export const CLEAN_TOAST_NOTIFICATION_STATE = 'CLEAN_TOAST_NOTIFICATION_STATE'

export const FETCH_NEW_NOTIFICATION = "FETCH_NEW_NOTIFICATION"
export const SUCCESS_FETCH_ADD_NOTIFICATION = "SUCCESS_FETCH_ADD_NOTIFICATION"
export const SUCCESS_FETCH_UPDATE_NOTIFICATION_RECEIPT = "SUCCESS_FETCH_UPDATE_NOTIFICATION_RECEIPT"

//elements
export const FETCH_ELEMENTS_NOTIFICATION = 'FETCH_ELEMENTS_NOTIFICATION'
export const SUCCESS_FETCH_ELEMENTS_NOTIFICATION = 'SUCCESS_FETCH_ELEMENTS_NOTIFICATION'
export const ERROR_FETCH_UPDATE_ELEMENTS_NOTIFICATION = 'ERROR_FETCH_UPDATE_ELEMENTS_NOTIFICATION'
export const CLEAN_ELEMENTS_NOTIFICATION_ERROR = 'CLEAN_ELEMENTS_NOTIFICATION_ERROR'
export const CLEAN_ELEMENTS_NOTIFICATION ='CLEAN_ELEMENTS_NOTIFICATION'

export const SET_NOTIFICATION_DETAIL ='SET_NOTIFICATION_DETAIL'
export const SET_NOTIFICATION_USER_DETAIL ='SET_NOTIFICATION_USER_DETAIL'


// actions constants for cache buster
export const CACHE_CLEANUP_NEEDED = 'CACHE_CLEANUP_NEEDED'
export const CACHE_CLEANUP_NOT_NEEDED = 'CACHE_CLEANUP_NOT_NEEDED'
export const REFRESH_CACHE_AND_RELOAD = 'REFRESH_CACHE_AND_RELOAD'

// constants for group sound masking
export const FETCH_SOUND_MASKING = 'FETCH_SOUND_MASKING'
export const UPDATE_SOUND_MASKING = 'UPDATE_SOUND_MASKING'
export const SUCCESS_FETCH_UPDATE_SOUND_MASKING = 'SUCCESS_FETCH_UPDATE_SOUND_MASKING'
export const ERROR_FETCH_UPDATE_SOUND_MASKING = 'ERROR_FETCH_UPDATE_SOUND_MASKING'
export const CLEAN_SOUND_MASKING_ERROR = 'CLEAN_SOUND_MASKING_ERROR'
export const CLEAN_SOUND_MASKING_STATE = 'CLEAN_SOUND_MASKING_STATE'
// constants for energy analysis overview and settings
export const FETCH_ENERGY_ANALYSIS_SETTINGS = 'FETCH_ENERGY_ANALYSIS_SETTINGS'
export const UPDATE_ENERGY_ANALYSIS_SETTINGS = 'UPDATE_ENERGY_ANALYSIS_SETTINGS'
export const SUCCESS_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS = 'SUCCESS_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS'
export const ERROR_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS = 'ERROR_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS'
export const CLEAN_ENERGY_ANALYSIS_SETTINGS_ERROR = 'CLEAN_ENERGY_ANALYSIS_SETTINGS_ERROR'
export const CLEAN_ENERGY_ANALYSIS_SETTINGS_STATE = 'CLEAN_ENERGY_ANALYSIS_SETTINGS_STATE'

export const FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA = 'FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA'
export const SUCCESS_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA = 'SUCCESS_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA'
export const ERROR_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA = 'ERROR_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA'
export const CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_ERROR = 'CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_ERROR'
export const CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_STATE = 'CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_STATE'

export const FETCH_CHART_ENERGY_DATA = 'FETCH_CHART_ENERGY_DATA'
export const SUCCESS_FETCH_CHART_ENERGY_DATA = 'SUCCESS_FETCH_CHART_ENERGY_DATA'
export const ERROR_FETCH_CHART_ENERGY_DATA = 'ERROR_FETCH_CHART_ENERGY_DATA'
export const CLEAN_CHART_ENERGY_DATA_ERROR = 'CLEAN_CHART_ENERGY_DATA_ERROR'
export const CLEAN_CHART_ENERGY_DATA_STATE = 'CLEAN_CHART_ENERGY_DATA_STATE'
