import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, message, Select, Modal, Notification, Switch, Popconfirm, Icon, Row, Col } from "antd"
import StyledIcon from "../StyledIcon"
import { withRouter } from "react-router-dom"
import * as roles from '../../constants/roles'
import * as error_codes from '../../constants/error_codes'
import * as actions from '../../constants'
import "./styles.css"

import colors from "../../styles";
import CenteredRow from "../CenteredRow";
import { CopyToClipboard } from 'react-copy-to-clipboard'

const { darkIconColor } = colors;

const Option = Select.Option;
const FormItem = Form.Item;

const UserForm = ({ form, history, type, uuid, setIsModalVisible, isModalVisible }) => {
  const isLoading = useSelector(state => state.rootReducer.loaders.loadingUserEdition)
  const isReady = !useSelector(state => state.rootReducer.loaders.loadingUserEdition)
  const userDetails = useSelector(state => state.rootReducer.userManagment.currentUserDetails)
  const companiesOptions = useSelector(state => state.rootReducer.userManagment.companies_options)
  const buildingsOptions = useSelector(state => state.rootReducer.userManagment.buildings_options)
  const groupsOptions = useSelector(state => state.rootReducer.userManagment.groups_options)
  const rolesList = useSelector(state => state.rootReducer.userManagment.roles)
  const [currentRoleFormValue, setCurrentRoleFormValue] = useState("")
  const isUserCreatedEdited = useSelector(state => state.rootReducer.userManagment.isUserCreatedEdited)
  const error = useSelector((state) => state.rootReducer.userManagment.errorCreateEdit)

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const managerRole = useSelector((state) => state.rootReducer.auth.rolename)
  const managerCompany = useSelector((state) => state.rootReducer.auth.company)
  const managerBuildings = useSelector((state) => state.rootReducer.auth.buildings)
  const managerGroup = useSelector((state) => state.rootReducer.auth.group)
  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)

  const newUserPassword = useSelector(state => state.rootReducer.userManagment.newUserPassword)
  const isPasswordReseted = useSelector(state => state.rootReducer.userManagment.isPasswordReseted)
  const [isNewPasswordModalVisible, setIsNewPasswordModalVisible] = useState(false)

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, isFieldTouched } = form
  const update = type === "update"

  const dispatch = useDispatch()

  useEffect(
    () => {
      resetFields()
      if (update) {
        dispatch(({ type: actions.FETCH_USER, payload: { tenant_uuid, user_uuid: uuid, managerCompany, managerBuildings, managerGroup, managerRole } }))
      } else {
        setCurrentRoleFormValue("")
        regenarateSelectorOptions(true, currentRoleFormValue)
      }
    },
    [uuid]
  )

  useEffect(
    () => {
      if (userDetails && update) {
        setCurrentRoleFormValue(userDetails.roles[0].name)
      }
    },
    [userDetails]
  )

  useEffect(
    () => {
      if (!isModalVisible) {
        dispatch(({ type: actions.CLEAN_USER_CREATION_EDITION_STATES }))
      }
    },
    [isModalVisible]
  )

  const canShowCompaniesSelector = () => {
    return currentRoleFormValue !== "" && currentRoleFormValue !== roles.USER_ROLE_SUPERADMIN && (currentRoleFormValue === roles.USER_ROLE_COMPANYADMIN || domainClaims.CanAssignCompanyToCompanyAdmin)
  }

  const canShowBuildingsSelector = () => {
    return currentRoleFormValue !== "" && currentRoleFormValue !== roles.USER_ROLE_COMPANYADMIN && currentRoleFormValue !== roles.USER_ROLE_SUPERADMIN && (currentRoleFormValue === roles.USER_ROLE_BUILDINGADMIN || (domainClaims.CanAssignBuildingToBuildingAdmin))
  }

  const canShowGroupsSelector = () => {
    return currentRoleFormValue !== "" && currentRoleFormValue !== roles.USER_ROLE_SUPERADMIN && (currentRoleFormValue === roles.USER_ROLE_USER || currentRoleFormValue === roles.USER_ROLE_OFFICEADMIN)
  }

  const handleOnChangeCompaniesSelector = company => {
    cleanFormWhenComapnyChange()
    if (currentRoleFormValue !== roles.USER_ROLE_COMPANYADMIN) {
      dispatch(({ type: actions.USER_FETCH_COMPANY_BUILDINGS, payload: { tenant_uuid, company_uuid: company } }))
    }
  }

  const handleOnChangeBuildingsSelector = buildings => {
    cleanFormWhenBuildingChange()
    if (currentRoleFormValue !== roles.USER_ROLE_BUILDINGADMIN) {
      dispatch(({ type: actions.USER_FETCH_BUILDING_GROUPS, payload: { tenant_uuid, buildings_uuids: buildings, user_role: currentRoleFormValue } }))
    }
  }

  const cleanFormCurrentValues = () => {
    if (canShowCompaniesSelector()) {
      setFieldsValue({ "companies": "" })
    }
    if (canShowBuildingsSelector()) {
      setFieldsValue({ "buildings": [] })
    }
    if (canShowGroupsSelector()) {
      setFieldsValue({ "groups": [] })
    }
  }

  const cleanFormWhenComapnyChange = () => {
    if (canShowBuildingsSelector()) {
      setFieldsValue({ "buildings": [] })
    }
    if (canShowGroupsSelector()) {
      setFieldsValue({ "groups": [] })
    }
  }

  const cleanFormWhenBuildingChange = () => {
    if (canShowGroupsSelector()) {
      setFieldsValue({ "groups": [] })
    }
  }



  const regenarateSelectorOptions = (obtain_options, user_role) => {
    if (managerRole === roles.USER_ROLE_SUPERADMIN) {
      dispatch(({ type: actions.CLEAN_BUILDINGS_CURRENT_OPTIONS }))
      dispatch(({ type: actions.CLEAN_GROUPS_CURRENT_OPTIONS }))
    } else if (managerRole === roles.USER_ROLE_COMPANYADMIN) {
      if (obtain_options) {
        dispatch(({ type: actions.USER_FETCH_COMPANY_BUILDINGS, payload: { tenant_uuid, company_uuid: managerCompany } }))
      }
      dispatch(({ type: actions.CLEAN_GROUPS_CURRENT_OPTIONS }))
    } else if (managerRole === roles.USER_ROLE_BUILDINGADMIN) {
      dispatch(({ type: actions.USER_FETCH_BUILDING_GROUPS, payload: { tenant_uuid, buildings_uuids: managerBuildings, user_role } }))
    } else if (managerRole === roles.USER_ROLE_OFFICEADMIN) {
      if (obtain_options) {
        dispatch(({ type: actions.USER_FETCH_GROUP_CHILDREN, payload: { tenant_uuid, group_uuid: managerGroup.uuid } }))
      }
    }
  }

  const handleOnChangeRoleSelector = role_uuid => {
    const role = rolesList.filter(role => role.uuid === role_uuid)[0]
    setCurrentRoleFormValue(role.name)
    cleanFormCurrentValues()
    regenarateSelectorOptions(false, role.name)
  }

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { username, password, password2, rolesForm, companies, buildings, groups, is_active, active_2fa } = values;
        const form = {};

        if (username && username.length) {
          form.username = username;
        }
        if (password && password.length) {
          form.password = password;
        }
        if (password2 && password2.length) {
          form.password2 = password2;
        }

        if (isFieldTouched("is_active")) {
          form.is_active = is_active
        }

        if (isFieldTouched("active_2fa")) {
          form.active_2fa = active_2fa
        }

        let update_form = { ...form }

        if (update) {
          update_form.update_roles = false
          update_form.update_groups = false
          update_form.update_companies = false
          update_form.update_buildings = false
        }

        if (rolesForm && rolesForm.length) {
          form.roles = [rolesForm]
          if (update && isFieldTouched("rolesForm")) {
            update_form.roles = form.roles
            update_form.update_roles = true
          }
        }

        if (currentRoleFormValue === roles.USER_ROLE_COMPANYADMIN) {
          if (isFieldTouched("companies"))
            form.companies = [companies]
          if (isFieldTouched("companies") && update) {
            update_form.update_companies = true
            update_form.companies = form.companies
          }
        }
        if (currentRoleFormValue === roles.USER_ROLE_BUILDINGADMIN) {
          form.buildings = buildings
          if (isFieldTouched("buildings") && update) {
            update_form.update_buildings = true
            update_form.buildings = form.buildings
          }
        }
        if (currentRoleFormValue === roles.USER_ROLE_USER || currentRoleFormValue === roles.USER_ROLE_OFFICEADMIN) {
          if (groups && groups.length) {
            //if (currentRoleFormValue === roles.USER_ROLE_USER) {
            //  form.groups = groups
            //} else {
            form.groups = [groups]
            //}
            if (isFieldTouched("groups") && update) {
              update_form.update_groups = true
              update_form.groups = form.groups
            }
          }
        }

        if (!update) {
          dispatch(({
            type: actions.CREATE_USER,
            payload: {
              tenant_uuid,
              form
            }
          }))
        } else {
          dispatch(({
            type: actions.EDIT_USER,
            payload: {
              tenant_uuid,
              user_uuid: uuid,
              form: update_form
            }
          }))
        }
      } else {
        message.error(err);
      }
    })
  }

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({ type: actions.CLEAN_USER_CREATION_EDITION_STATES }))
              setIsModalVisible(false)
              dispatch(({ type: actions.FETCH_USERS_DATA, payload: { tenant_uuid } }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (isUserCreatedEdited) {
        message.success(update ? `User successfully updated` : `User successfully added`)
        dispatch(({ type: actions.CLEAN_USER_CREATION_EDITION_STATES }))
        setIsModalVisible(false)
        resetFields()
      }
    },
    [isUserCreatedEdited]
  )

  useEffect(
    () => {
      if (isPasswordReseted) {
        setIsNewPasswordModalVisible(true)
      }
    },
    [isPasswordReseted]
  )

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Password confirm is inconsistent");
    } else {
      callback();
    }
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value) {
      form.validateFields(["confirm"], { force: true })
    }
    callback();
  }

  const handleCancel = (e) => {
    dispatch(({ type: actions.CLEAN_USER_CREATION_EDITION_STATES }))
    setIsModalVisible(false)
  }

  const clipboardButton = (
    <CopyToClipboard text={newUserPassword}>
      <Button
        type="primary"
        icon={"copy"}
        style={{ width: "100%" }}
      >
        Copy to clipboard
      </Button>
    </CopyToClipboard>
  )

  return (
    <CenteredRow>
      <Form onSubmit={handleSubmit}>
        <FormItem label="Username">
          {getFieldDecorator("username", {
            rules: [{ required: !update, message: "Username is required" }],
            initialValue: update ? userDetails && userDetails.username : ""
          })(
            <Input
              prefix={
                <StyledIcon type="mail" color={darkIconColor} scope="login" />
              }
              disabled={!isReady}
              placeholder="Username"
            />
          )}
        </FormItem>
        <FormItem label="Password">
          {getFieldDecorator("password", {
            rules: [
              { required: !update, message: "Password is required" },
              {
                validator: validateToNextPassword
              }
            ]
          })(
            <Input
              prefix={
                <StyledIcon type="lock" color={darkIconColor} scope="login" />
              }
              disabled={!isReady}
              type="password"
              placeholder="New password"
            />
          )}
        </FormItem>
        <FormItem label="Confirm Password">
          {getFieldDecorator("password2", {
            rules: [
              { required: !update, message: "Password confirmation is required" },
              {
                validator: compareToFirstPassword
              }
            ]
          })(
            <Input
              prefix={
                <StyledIcon type="lock" color={darkIconColor} scope="login" />
              }
              type="password"
              disabled={!isReady}
              placeholder="Password confirmation"
            />
          )}
           <ul>
            <li>The password needs more than 5 characters</li>
            <li>Include characters like ?, (, * and caps for a stronger password</li>
          </ul>
        </FormItem>
        {update && (
          <Popconfirm
            title={`You are about to reset password for ${userDetails ? userDetails.username : ""}. Are you sure?`}
            onConfirm={() => {
              dispatch(({ type: actions.RESET_USER_PASSWORD, payload: { tenant_uuid, user_uuid: userDetails.uuid } }))
            }}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
          >
            <Button
              type="link"
              disabled={!isReady}
            >
              Reset Password?
        </Button>
          </Popconfirm>
        )}
        <FormItem label="Roles">
          {getFieldDecorator("rolesForm", {
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (value && value.length >= 1) {
                    callback();
                  } else {
                    callback("At least one role is required");
                  }
                }
              }
            ],
            initialValue: update
              ? userDetails && userDetails.roles.map(role => `${role.uuid}`)
              : ""
          })(
            <Select
              mode="single"
              disabled={!isReady}
              style={{ width: "100%" }}
              placeholder="Select user's roles"
              onChange={handleOnChangeRoleSelector}
            >
              {rolesList && rolesList.map(role => (
                <Option key={role.uuid}>{role.name}</Option>
              ))}
            </Select>
          )}
        </FormItem>

        <Row>
          <Col sm={{ span: 12 }}>
            <FormItem label="Activate User">
              {getFieldDecorator("is_active", {
                initialValue: update && userDetails ? userDetails.is_active : true,
                valuePropName: 'checked'
              })(
                <Switch disabled={!isReady} />
              )}
            </FormItem>
          </Col>
          <Col sm={{ span: 12 }}>
            <FormItem label="Two-Factor Authentication">
              {getFieldDecorator("active_2fa", {
                initialValue: update && userDetails ? userDetails.active_2fa : false,
                valuePropName: 'checked'
              })(
                <Switch disabled={!isReady} />
              )}
            </FormItem>
          </Col>
        </Row>

        {
          (canShowCompaniesSelector()) && (
            <FormItem label="User's Company">
              {getFieldDecorator("companies", {
                initialValue: update && userDetails && !isFieldTouched("rolesForm") ? userDetails.companies.map(company => `${company.uuid}`) : [],
                rules: [
                  { required: currentRoleFormValue === roles.USER_ROLE_COMPANYADMIN, message: "A company administrator require a company" },
                ]
              })(
                <Select
                  mode="single"
                  disabled={!isReady}
                  style={{ width: "100%" }}
                  allowClear={true}
                  placeholder="Select a company"
                  onChange={handleOnChangeCompaniesSelector}
                >

                  {companiesOptions && companiesOptions.map(company => (
                    <Option key={company.uuid}>{company.name}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}
        {
          (canShowBuildingsSelector()) && (


            <FormItem label="Buildings">
              {getFieldDecorator("buildings", {
                initialValue: update && userDetails && !isFieldTouched("rolesForm") ? userDetails.buildings.map(building => `${building.uuid}`) : [],
                rules: [
                  { required: currentRoleFormValue === roles.USER_ROLE_BUILDINGADMIN, message: "A building administrator require a building" },
                ]
              })(
                <Select
                  mode="multiple"
                  disabled={!isReady}
                  style={{ width: "100%" }}
                  allowClear={true}
                  placeholder="Select user's building(s)"
                  onChange={handleOnChangeBuildingsSelector}
                >

                  {buildingsOptions && buildingsOptions.map(building => (
                    <Option key={building.uuid}>{building.name}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}
        {
          (canShowGroupsSelector()) && (
            <FormItem label="User's Groups(s)">
              {getFieldDecorator("groups", {
                initialValue: update && userDetails && !isFieldTouched("rolesForm") ? userDetails.groups.map(group => `${group.uuid}`) : [],
                rules: [
                  { required: currentRoleFormValue === roles.USER_ROLE_OFFICEADMIN || currentRoleFormValue === roles.USER_ROLE_USER, message: "A user of this role require a group" },
                ]
              })(
                //{currentRoleFormValue === roles.USER_ROLE_USER ? "multiple" : "single"}
                <Select
                  mode="single"
                  disabled={!isReady}
                  style={{ width: "100%" }}
                  allowClear={true}
                  placeholder={currentRoleFormValue === roles.USER_ROLE_USER ? "Select a control group(s)" : "Select a logical group"}
                >
                  {groupsOptions && groupsOptions.map(group => (
                    <Option key={group.uuid}>{group.name}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}

        <FormItem style={{ textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "40%" }}
            size="large"
            icon={update ? "upload" : "plus"}
            loading={isLoading}
          >
            {update ? " Save" : " Add User"}
          </Button>
          <Button
            type="default"
            htmlType="button"
            onClick={handleCancel}
            icon={"close"}
            style={{ width: "40%", marginLeft: "1em" }}
            size="large"
          >
            Cancel
          </Button>
        </FormItem>
      </Form>

      <Modal
        title={
          <span>
            <Icon type="user" />
                Password successfully changed
              </span>
        }
        width="30vw"
        destroyOnClose={true}
        maskClosable={true}
        visible={isNewPasswordModalVisible}
        onCancel={() => {
          setIsNewPasswordModalVisible(false)
          dispatch(({ type: actions.CLEAN_USER_RESET_PASSWORD_STATES }))
        }}
        footer={null}
      >
        <h4>The new password for user {userDetails ? userDetails.username : ""} is:</h4>
        <Input
          value={newUserPassword}
          suffix={clipboardButton}
          size="large"
        />
      </Modal>
    </CenteredRow>
  )
};

const WrappedUserForm = Form.create()(UserForm);

export default withRouter(WrappedUserForm);
