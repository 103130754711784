import * as actions from '../constants';

const initialState = {
  notificationData: {
    "all": [],
    "toast": [],
    "mail": [],
    "sms": []
  },
  error: null,
  notificationSendData: {
    "all": [],
    "toast": [],
    "mail": [],
    "sms": []
  },
  errorSend: null,

  notificationToast: [],
  errorToast: null,

  notificationElements: [],
  errorElements: null,
}

const notificationUser = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_USER_NOTIFICATION:
      switch (action.payload.data.transport) {
        case "all":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "all": action.payload.data.notification },
            error: null
          })
          
        case "toast":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "toast": action.payload.data.notification },
            error: null
          })
          
        case "mail":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "mail": action.payload.data.notification },
            error: null
          })
          
        case "sms":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "sms": action.payload.data.notification },
            error: null
          })
          
        default:
          return state
      }
    case actions.ERROR_FETCH_UPDATE_USER_NOTIFICATION:
      return Object.assign({}, state, {
        notificationData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
        error: action.payload.error
      })
      
    case actions.CLEAN_USER_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
      
    case actions.CLEAN_USER_NOTIFICATION_STATE:
      return Object.assign({}, state, {
        error: null,
        notificationData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
      })
      

    //SEND
    case actions.SUCCESS_FETCH_UPDATE_USER_SEND_NOTIFICATION:
      switch (action.payload.data.transport) {
        case "all":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "all": action.payload.data.notification },
            error: null
          })
          
        case "toast":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "toast": action.payload.data.notification },
            error: null
          })
          
        case "mail":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "mail": action.payload.data.notification },
            error: null
          })
          
        case "sms":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "sms": action.payload.data.notification },
            error: null
          })
          
        default:
          return state
      }
    case actions.ERROR_FETCH_UPDATE_USER_SEND_NOTIFICATION:
      return Object.assign({}, state, {
        notificationSendData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
        errorSend: action.payload.error
      })
      
    case actions.CLEAN_USER_SEND_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        errorSend: null,
      })
      
    case actions.CLEAN_USER_SEND_NOTIFICATION_STATE:
      return Object.assign({}, state, {
        errorSend: null,
        notificationSendData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
      })
      


    case actions.SUCCESS_FETCH_ADD_NOTIFICATION:
      let hashall = {};
      let hashtoast = {};
      let hashmail = {};
      let hashsms = {};

      return Object.assign({}, state, {
        notificationData: {
          ...state.notificationData, 
          "all": ( (action.payload.data).filter(function (current) {
          var exists = !hashall[current.uuid];
          hashall[current.uuid] = true;
          return exists;
        })).concat(state.notificationData.all),

        "toast": ( (action.payload.data.filter((item) => { return item.transport === 1 })).filter(function (current) {
          var exists = !hashtoast[current.uuid];
          hashtoast[current.uuid] = true;
          return exists;
        })).concat(state.notificationData.toast),
        "mail": ( (action.payload.data.filter((item) => { return item.transport === 2 })).filter(function (current) {
          var exists = !hashmail[current.uuid];
          hashmail[current.uuid] = true;
          return exists;
        })).concat(state.notificationData.mail),

        "sms": ( (action.payload.data.filter((item) => { return item.transport === 3 })).filter(function (current) {
          var exists = !hashsms[current.uuid];
          hashsms[current.uuid] = true;
          return exists;
        })).concat(state.notificationData.sms)
      }})
      

    case actions.SUCCESS_FETCH_UPDATE_NOTIFICATION_RECEIPT:
      return Object.assign({}, state, {
        notificationData: {
          ...state.notificationData, 
          "all": state.notificationData.all.map(function (item) {
            if (item.uuid === action.payload.data.notification_uuid) {
              let newreceipt = true;
              item.notification_receipts.forEach(element => {
                if (action.payload.data.notification_receipt && element.uuid === action.payload.data.notification_receipt.uuid) {
                  newreceipt = false;
                }
              });
              if (newreceipt) {
                return { ...item, "notification_receipts": item.notification_receipts.concat(action.payload.data.notification_receipt) }
              } else {
                return {
                  ...item, "notification_receipts": item.notification_receipts.map((elem) => {
                    if (action.payload.data.notification_receipt && elem.uuid === action.payload.data.notification_receipt.uuid) {
                      return action.payload.data.notification_receipt
                    } else {
                      return elem
                    }
                  })
                }
              }
            } else {
              return item
            }
          }),
          "toast": state.notificationData.toast.map(function (item) {
            if (item.uuid === action.payload.data.notification_uuid) {
              let newreceipt = true;
              item.notification_receipts.forEach(element => {
                if (action.payload.data.notification_receipt && element.uuid === action.payload.data.notification_receipt.uuid) {
                  newreceipt = false;
                }
              });
              if (newreceipt) {
                return { ...item, "notification_receipts": item.notification_receipts.concat(action.payload.data.notification_receipt) }
              } else {
                return {
                  ...item, "notification_receipts": item.notification_receipts.map((elem) => {
                    if (action.payload.data.notification_receipt && elem.uuid === action.payload.data.notification_receipt.uuid) {
                      return action.payload.data.notification_receipt
                    } else {
                      return elem
                    }
                  })
                }
              }
            } else {
              return item
            }
          }),
          "mail": state.notificationData.mail.map(function (item) {
            if (item.uuid === action.payload.data.notification_uuid) {
              let newreceipt = true;
              item.notification_receipts.forEach(element => {
                if (action.payload.data.notification_receipt && element.uuid === action.payload.data.notification_receipt.uuid) {
                  newreceipt = false;
                }
              });
              if (newreceipt) {
                return { ...item, "notification_receipts": item.notification_receipts.concat(action.payload.data.notification_receipt) }
              } else {
                return {
                  ...item, "notification_receipts": item.notification_receipts.map((elem) => {
                    if (action.payload.data.notification_receipt && elem.uuid === action.payload.data.notification_receipt.uuid) {
                      return action.payload.data.notification_receipt
                    } else {
                      return elem
                    }
                  })
                }
              }
            } else {
              return item
            }
          }),
          "sms": state.notificationData.sms.map(function (item) {
            if (item.uuid === action.payload.data.notification_uuid) {
              let newreceipt = true;
              item.notification_receipts.forEach(element => {
                if (action.payload.data.notification_receipt && element.uuid === action.payload.data.notification_receipt.uuid) {
                  newreceipt = false;
                }
              });
              if (newreceipt) {
                return { ...item, "notification_receipts": item.notification_receipts.concat(action.payload.data.notification_receipt) }
              } else {
                return {
                  ...item, "notification_receipts": item.notification_receipts.map((elem) => {
                    if (action.payload.data.notification_receipt && elem.uuid === action.payload.data.notification_receipt.uuid) {
                      return action.payload.data.notification_receipt
                    } else {
                      return elem
                    }
                  })
                }
              }
            } else {
              return item
            }
          })
        }
      })
      

    //toast
    case actions.SUCCESS_FETCH_UPDATE_TOAST_NOTIFICATION:
      let hash = {};
      return Object.assign({}, state, {
        notificationToast: (state.notificationToast.concat(action.payload.data)).filter(function (current) {
          var exists = !hash[current.uuid];
          hash[current.uuid] = true;
          return exists;
        }),
        errorToast: null
      })
      
    case actions.REMOVE_TOAST_NOTIFICATION:
      return Object.assign({}, state, {
        notificationToast: state.notificationToast.filter((notification) => {
          return notification.uuid !== action.payload.uuid
        }),
        errorToast: null
      })
      
    case actions.ERROR_FETCH_UPDATE_TOAST_NOTIFICATION:
      return Object.assign({}, state, {
        notificationToast: [],
        errorToast: action.payload.error
      })
      
    case actions.CLEAN_TOAST_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        errorToast: null,
      })
    case actions.CLEAN_TOAST_NOTIFICATION_STATE:
      return Object.assign({}, state, {
        notificatioToast: [],
        errorToast: null,
      })
      


    //elements
    case actions.SUCCESS_FETCH_ELEMENTS_NOTIFICATION:
      console.log(action.payload.data)
      return Object.assign({}, state, {
        notificationElements: action.payload.data
      })
      
    case actions.ERROR_FETCH_UPDATE_ELEMENTS_NOTIFICATION:
      return Object.assign({}, state, {
        notificationElements: [],
        errorElements: action.payload.error
      })
      
    case actions.CLEAN_ELEMENTS_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        errorElements: null,
      })
      

    default:
      return state
  }
}

export default notificationUser;