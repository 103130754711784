import React, { useState, useEffect, useContext } from "react";
import {
    Row,
    Col,
    Switch,

} from "antd";
import moment from 'moment';
import { UserContext } from "../../context";
import "./style.css";

const Demo = ({
    history,
    index,
    parentconfig,
    parentdata,
    updateParentConfig,
    updateParentData
    //tabKey
}) => {
    const [thedata, setThedata] = useState(
        parentdata
        /*
        {
        moment: ""
      }
      */
    );
    const [theconfig, setTheconfig] = useState(
        parentconfig
        /*
        {
        element_uuid: parentconfig.element_uuid,
    
        }
        */
    );

    const [themoment, setThemoment] = useState(
        moment().format("YYYY-MM-DD-mm-ss"),
    );

    const { userData: { tenant_uuid } } = useContext(UserContext);



    //when change the image, check dimension and asign correct display class
    useEffect(() => {
        setThedata(parentdata)
    },
        [parentdata])

    useEffect(() => {
        setTheconfig(parentconfig)
    },
        [parentconfig])

    const handleChange = checked => {
        //setEnabled(checked)
        updateParentConfig(index, { ...theconfig, enabled: checked })
        setTheconfig({ ...theconfig, enabled: checked })
    };

    const handleUpdate = checked => {
        //setThedata({ ...thedata, moment: moment().format("YYYY-MM-DD-mm-ss")  })
        updateParentData(index, { ...thedata, moment: moment().format("YYYY-MM-DD-mm-ss") })
        setThedata({ ...thedata, moment: moment().format("YYYY-MM-DD-mm-ss") })

    };

    const render = () => {
        const content = (
            <>
            <Row>
                <Col span={24}>
                    <div>
                        Enabled ({(thedata.enabled ? 'true' : 'false')}) <Switch onChange={handleChange} checked={theconfig.enabled} />
                    </div>
                    {theconfig.enabled &&
                        <div>
                            <div>
                                {thedata.moment} <span onClick={handleUpdate}> Update</span>
                            </div>
                            <div style={{marginTop: "200px", paddigTop: "200px"}}>
                                Local moment: {themoment}
                            </div>
                        </div>
                    }
                </Col>
            </Row>
             <Row>
                <Col span={24}>
                    <div>
                        Enabled ({(thedata.enabled ? 'true' : 'false')}) <Switch onChange={handleChange} checked={theconfig.enabled} />
                    </div>
                    {theconfig.enabled &&
                        <div>
                            <div>
                                {thedata.moment} <span onClick={handleUpdate}> Update</span>
                            </div>
                            <div style={{marginTop: "200px", paddigTop: "200px"}}>
                                Local moment: {themoment}
                            </div>
                        </div>
                    }
                </Col>
            </Row>
            </>
        );

        return <>{content}</>
    }

    return (
        render()
    )
}

export default Demo;
