import {
    getGroupCircadianRhythmService,
    putGroupCircadianRhythmService
} from "../services";

export const fetchCircadianRhythmApi = async (tenant_uuid, uuid) => {
    const response = await getGroupCircadianRhythmService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateCircadianRhythmApi = async (tenant_uuid, uuid, form) => {
    const response = await putGroupCircadianRhythmService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data
        return data
    }
}