import React from "react";
import { useSelector } from 'react-redux'
import { Descriptions, Empty, Collapse, Row, Col } from "antd";
import "./style.css";
//import { UserContext } from "../../context";
import Loader from "../../components/Loader";

const { Panel } = Collapse

const ElementDetailGeneralTab = ({
  element,
  tabKey,
}) => {

  //const { userData: { tenant_uuid } } = useContext(UserContext);
  const isLoading = useSelector(state => state.rootReducer.loaders.isFetchingElement)

  const getElementType = group_type => {
    let elementType = "";
    switch (group_type) {
      case "Logical":
        elementType = "Logical Group";
        break;
      case "Control":
        elementType = "Control Group";
        break;
      case "Mix":
        elementType = "Mix Group";
        break;
      default:
        elementType = group_type;
        break;
    }
    return elementType;
  };

  return (
    isLoading
      ? <Loader />
      :
      !element || element.name === undefined
        ? <Empty />
        :
        <Collapse defaultActiveKey="1">
          <Panel header="General" key="1">
            <Descriptions>
              <Descriptions.Item label="Name" span={3}>
                {element ? element.name : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Description" span={3}>
                {element ? element.description : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Type" span={3}>
                {element ? getElementType(element.group_type) : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Illuminance" span={3}>
                {element && element.lux ? element.lux : "0"} Lux
            </Descriptions.Item>
              <Descriptions.Item label="Power" span={3}>
                {element && element.watts ? element.watts : "0"} %
            </Descriptions.Item>
              <Descriptions.Item label="Status" span={3}>
                {element && element.on ? element.on : "off"}
              </Descriptions.Item>
            </Descriptions>
          </Panel>
          <Panel header="Contact Information" key="2">
            <Row>
              {
                element.group_type === 'Company' || element.group_type === 'Building'
                  ?
                  <Col xs={10}>
                    <Descriptions>
                      <Descriptions.Item label="City" span={3}>
                        {element && element.contact && element.contact.city ? element.contact.city : ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="State" span={3}>
                        {element && element.contact && element.contact.state ? element.contact.state : ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="Country" span={3}>
                        {element && element.contact && element.contact.country ? element.contact.country : ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address 1" span={3}>
                        {element && element.contact && element.contact.address_line1 ? element.contact.address_line1 : ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address 2" span={3}>
                        {element && element.contact && element.contact.address_line2 ? element.contact.address_line2 : ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address 3" span={3}>
                        {element && element.contact && element.contact.address_line3 ? element.contact.address_line3 : ""}
                      </Descriptions.Item>
                      <Descriptions.Item label="Postal/Zip code" span={3}>
                        {element && element.contact && element.contact.zip_code ? element.contact.zip_code : ""}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  : ""
              }

              <Col xs={10}>
                <Descriptions>
                  <Descriptions.Item label="Emergency Phone" span={3}>
                    {element && element.contact && element.contact.emergency_phone ? element.contact.emergency_phone : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Landlord Phone" span={3}>
                    {element && element.contact && element.contact.landlord_phone ? element.contact.landlord_phone : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Support Phone" span={3}>
                    {element && element.contact && element.contact.technical_phone ? element.contact.technical_phone : ""}
                  </Descriptions.Item>
                  {/*
                  <Descriptions.Item label="Other Phone" span={3}>
                    {element && element.contact && element.contact.extra_phone ? element.contact.extra_phone : ""}
                  </Descriptions.Item>
                  */}
                  <Descriptions.Item label="Email" span={3}>
                    {element && element.contact && element.contact.email ? element.contact.email : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Website" span={3}>
                    {element && element.contact && element.contact.website ? element.contact.website : ""}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col>
                <Descriptions>
                  <Descriptions.Item label="About tenant:" span={3}>
                    {element && element.contact && element.contact.tenant_information ? element.contact.tenant_information : ""}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Panel>
        </Collapse>
  );
};

export default ElementDetailGeneralTab;
