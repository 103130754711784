import React from "react";

import { Row, Col } from "antd";
import LoginForm from "../../components/LoginForm";

const Login = () => {

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", backgroundColor: "#fff" }}
    >
      <Col xs={20} sm={14} md={12} lg={10} xl={7}>
        <LoginForm/>
      </Col>
    </Row>
  )
}

export default Login
