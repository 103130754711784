import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchChartData, errorFetchChartData } from '../actions/chartDataActions'
import { parallelChartProccess } from "../utils/helpers"
import { toogleChartDataLoader } from '../actions/loaderActions'
import moment from 'moment'

export function* getChartDataValues(action) {
    try {
        yield put(toogleChartDataLoader(true))

        const stepTime = action.payload.step_time
        const requestTime = action.payload.request_time
        const start = moment.utc().subtract(requestTime, 'hours').subtract(stepTime, 'minutes')
        const startparallel = moment.utc().subtract(requestTime, 'hours')
        const startLocal = moment().subtract(requestTime, 'hours').subtract(stepTime, 'minutes')
        const endLocal = moment()
          .subtract(requestTime, 'hours')
          .add(stepTime, 'minutes')

        const end = moment
          .utc()
          .subtract(requestTime, 'hours')
          .add(stepTime, 'minutes')
        const currentTime = moment.utc()
        const currentTimeparallel = moment.utc()
        
        const sensor_type = action.payload.sensor_type

        /*
        const datad = yield call(fetchHistoricDataApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.sensor_type,
            start.format('YYYYMMDDHHmmss'),
            currentTime.format('YYYYMMDDHHmmss'),
        )
        */
        
        const data = yield call(parallelChartProccess,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.sensor_type,
            startparallel, //.format('YYYYMMDDHHmmss'),
            currentTimeparallel, //.format('YYYYMMDDHHmmss'),
            2,
            requestTime,
            )
            
        yield put(successFetchChartData(data, requestTime, stepTime, start, end, startLocal, endLocal, currentTime, sensor_type))
        yield put(toogleChartDataLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleChartDataLoader(false))
        yield put(errorFetchChartData(error))
    }
}

export function* fetchChartDataWatcher() {
    yield takeLatest(actions.FETCH_CHART_DATA, getChartDataValues)
}