import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdateNotifications, errorFetchUpdateNotifications, successFetchUpdateSendNotifications, errorFetchUpdateSendNotifications } from '../actions/notificationActions'

import { fetchNotificationApi } from '../api/notificationApi'
import { toogleNotificationLoader, toogleSendNotificationLoader } from '../actions/loaderActions'

import { successCreateNotification, errorCreateNotification } from '../actions/notificationActions'
import { fetchCreateNotificationApi } from '../api/notificationApi'
import { toogleCreateNotificationLoader } from '../actions/loaderActions'

export function* getNotificationValues(action) {
    try {
        yield put(toogleNotificationLoader(true))

        const data = yield call(fetchNotificationApi,
            action.payload.tenant_uuid,
            action.payload.element_type,
            action.payload.uuid,
            "receive",
            action.payload.transport
        )

        //data.update = false
        //console.log(data)
        yield put(successFetchUpdateNotifications({"transport":action.payload.transport, "notification":data}))
        yield put(toogleNotificationLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleNotificationLoader(false))
        yield put(errorFetchUpdateNotifications(error))
    }
}


export function* getSendNotificationValues(action) {
    try {
        yield put(toogleSendNotificationLoader(true))
        
        const data = yield call(fetchNotificationApi,
            action.payload.tenant_uuid,
            action.payload.element_type,
            action.payload.uuid,
            "send",
            action.payload.transport
        )

        //data.update = false

        yield put(successFetchUpdateSendNotifications({"transport":action.payload.transport, "notification":data}))
        yield put(toogleSendNotificationLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleSendNotificationLoader(false))
        yield put(errorFetchUpdateSendNotifications(error))
    }
}


//CREATE
export function* createNotification(action) {
    try {
        yield put(toogleCreateNotificationLoader(true))

        const data = yield call(fetchCreateNotificationApi,
            action.payload.tenant_uuid,
            action.payload.form
        )
        
        yield put(successCreateNotification(data))
        yield put(toogleCreateNotificationLoader(false))
    } catch (err) {
        let error = {}
        // console.log(err)
        if (err.message.includes("400")) {
            error = err.response.data
        } else if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN }
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED }
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_COMPANY) {
                error = { "message": "Company was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("422")) {
            error = err.response.data
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION }
        }

        yield put(toogleCreateNotificationLoader(false))
        yield put(errorCreateNotification(error))
    }
}

export function* createNotificationWatcher() {
    yield takeLeading(actions.CREATE_NOTIFICATION, createNotification)
}

export function* fetchNotificationsWatcher() {
    yield takeLatest(actions.FETCH_NOTIFICATION, getNotificationValues)
}

export function* fetchSendNotificationsWatcher() {
    yield takeLatest(actions.FETCH_SEND_NOTIFICATION, getSendNotificationValues)
}

