import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const logIn = ( username, password ) => action(actions.LOG_IN, { username, password })

export const success2faFirstStep = data => action(actions.SUCCESS_2FA_FIRST_STEP, { data })

export const secondStep2fa = ( username, verification_code ) => action(actions.SUCCESS_2FA_FIRST_STEP, { username, verification_code })

export const successLogIn = ( accessToken, refreshToken ) => action(actions.SUCCESS_LOG_IN, { accessToken, refreshToken })

export const errorLogIn = error => action(actions.ERROR_LOG_IN, { error })

export const changeLanguage = language => action(actions.CHANGE_LANGUAGE, { language })

export const logOut = () => action(actions.LOG_OUT)

export const clean2faFirstStepState = () => action(actions.CLEAN_2FA_FIRST_STEP_STATE)

export const clean2faSecondStepState = () => action(actions.CLEAN_2FA_SECOND_STEP_STATE)
