import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Radio, Button, Form, message, Modal, Icon, Notification } from 'antd'
import IdleTimer from "react-idle-timer"
import { withRouter } from "react-router-dom"
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import moment from 'moment'
import './styles.css'



const FormItem = Form.Item

const _GroupBlueLightBoostForm = ({
  form,
  history,
  tabKey,
}) => {

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, isFieldTouched } = form

  const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const loading = useSelector(state => state.rootReducer.loaders.loadingBlueLightBoost)
  const blueLightBoostData = useSelector(state => state.rootReducer.blueLightBoost.blueLightBoostData)
  const update = useSelector(state => state.rootReducer.blueLightBoost.update)
  const error = useSelector((state) => state.rootReducer.blueLightBoost.error)

  const [saveDisabled, setSaveDisabled] = useState(true)

  const dispatch = useDispatch()

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const idleTimer = useRef(null)
  const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { blue_light_boost } = values


        dispatch(({
          type: actions.ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST,
          payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, form: { blue_light_boost } }
        }))
      } else {
        message.error(err)
      }
    })
  }

  /*
  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
    },
    [form]
  )
  */

  useEffect(
    () => {
      (async () => {
        if (tabKey === "lights_control" && !loading) {
          resetFields()
          dispatch(({
            type: actions.FETCH_BLUE_LIGHT_BOOST_DATA,
            payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
          }))
          setSaveDisabled(true)

        } else {
          dispatch(({
            type: actions.CLEAN_BLUE_LIGHT_BOOST_STATE,
          }))
        }
      })();
    },
    [tabKey]
  )


  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 })
          history.push("/login")
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              setSaveDisabled(true)
              dispatch(({
                type: actions.FETCH_BLUE_LIGHT_BOOST_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 })
          dispatch({ type: actions.CLEAN_BLUE_LIGHT_BOOST_ERROR })
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (update) {

        let activate_string = ""
        if (blueLightBoostData.blue_light_boost) {
          activate_string = "activated"
        } else {
          activate_string = "deactivated"
        }

        message.success("Blue Light Boost successfully " + activate_string)
        setSaveDisabled(true)
        resetFields()
      }
    },
    [blueLightBoostData]
  )

  const getBlueLightLeftMinutes = () => {
    const blue_light_boost_end_timestamp = moment(blueLightBoostData.blue_light_boost_timestamp)
    blue_light_boost_end_timestamp.add(blueLightBoostData.blue_light_boost_minutes, 'minutes')
    const result = blue_light_boost_end_timestamp.diff(moment.utc(), 'minutes')
    return  result >= 0 ? result : 0
  }

  const handleClickRadioBlueLightBoost = async e => {
    e.preventDefault()
    setSaveDisabled(false)
  }

  const onUserIdle = async e => {
    if (tabKey === "lights_control") {
      resetFields()
      dispatch(({ type: actions.FETCH_BLUE_LIGHT_BOOST_DATA, payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid } }))
      setSaveDisabled(true)
    }
    //idleTimer.reset()
  }

  return (
    <>
        <IdleTimer
          ref={idleTimer}
          element={document}
          onIdle={onUserIdle}
          debounce={250}
          timeout={timeout}
        ></IdleTimer>
      <Form className="input-form" onSubmit={handleSubmit}>

        <FormItem label="Blue Light Boost">
          {getFieldDecorator("blue_light_boost", {
            initialValue: blueLightBoostData ? blueLightBoostData.blue_light_boost : false
          })(
            <Radio.Group disabled={loading}>
              <Radio.Button disabled={loading} value={false} onClick={handleClickRadioBlueLightBoost} >OFF</Radio.Button>
              <Radio.Button disabled={loading} value={true} onClick={handleClickRadioBlueLightBoost} >ON</Radio.Button>
            </Radio.Group>
          )}
        </FormItem>
        { blueLightBoostData && blueLightBoostData.blue_light_boost && (
          <div>Blue light boost time left: {getBlueLightLeftMinutes()} minutes</div>
        )}
        <FormItem >
          <Button
            type="primary"
            htmlType="button"
            disabled={saveDisabled}
            loading={loading}
            onClick={handleSubmit}
          >
             Save boost setup
          </Button>
        </FormItem>
      </Form>
    </>
  )
}

const GroupBlueLightBoostForm = Form.create()(_GroupBlueLightBoostForm)
export default withRouter(GroupBlueLightBoostForm)
