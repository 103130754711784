import * as actions from '../constants';
import { getStudiedFromSensorData } from '../utils/chartData'

const initialState = {
  chartData: [],
  error: null,
}

const chartData = (state = initialState, action) => {
  switch (action.type) {
    //case SUCCESS_FETCH_CHART_DATA
    case actions.SUCCESS_FETCH_CHART_DATA:
      //console.log('TCL: GroupSensorChart -> data', data)
      const tmpData = []
      const data = action.payload.data
      const start = action.payload.start
      const end = action.payload.end
      const startLocal = action.payload.startLocal
      const endLocal = action.payload.endLocal
      const currentTime = action.payload.currentTime
      const requestTime = action.payload.requestTime
      const stepTime = action.payload.stepTime
      const sensorName = action.payload.sensor_type

      const dataLength = data ? data.length : 0

      while (currentTime.diff(end) >= 0) {
        let iterationSum = 0
        let iterationCount = 0
        let iterationEmpty = true
        for (let index = 0; index < dataLength; index++) {
          //console.log('TCL: GroupSensorChart -> end, index', end.format('HH:mm:ss'), index)
          //console.log('TCL: GroupSensorChart -> endLocal ,index', endLocal.format('HH:mm:ss'), index)
          // # This is for every device in response data
          const device = data[index]
          const studiedSensor = getStudiedFromSensorData(
            device.sensor_data,
            sensorName,
            start,
            end,
          )
          iterationSum += studiedSensor.sum
          iterationCount += studiedSensor.count
          if (studiedSensor.empty) {

          } else {
            iterationEmpty = false
          }
        }
        if (iterationEmpty === false) {
          if (sensorName !== 'power') {
            const average =
              iterationCount !== 0
                ? iterationSum / iterationCount
                : iterationCount

            tmpData.push({
              time: endLocal.format('HH:mm:ss'),
              reading_average: parseFloat(average.toFixed(2)),
            })
          } else {
            tmpData.push({
              time: endLocal.format('HH:mm:ss'),
              watts: parseFloat(iterationSum.toFixed(2)),
            })
          }
        }

        start.add(stepTime, 'minutes')
        end.add(stepTime, 'minutes')
        startLocal.add(stepTime, 'minutes')
        endLocal.add(stepTime, 'minutes')
      }

      return Object.assign({}, state, {
        chartData: tmpData,
        error: null
      })

    // case ERROR_FETCH_CHART_DATA
    case actions.ERROR_FETCH_CHART_DATA:
      return Object.assign({}, state, {
        chartData: [],
        error: action.payload.error
      })
    case actions.CLEAN_CHART_DATA_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_CHART_DATA_STATE:
      return Object.assign({}, state, {
        error: null,
        chartData: [],
      })
    default:
      return state
  }
}

export default chartData