import {
  claims as claimsConstants
} from '../constants/claims'

const getDomainClaims = (claims) => {
  let domainClaims = {};
  claims.forEach(claim => {
    switch (claim) {
      // ****** user_managment_claims **********
      // super_admin_claims
      case claimsConstants.CanCreateSuperAdmin:
        // 1
        domainClaims.CanCreateSuperAdmin = true
        break
      case claimsConstants.CanEditSuperAdmin:
        // 2
        domainClaims.CanEditSuperAdmin = true
        break
      case claimsConstants.CanRemoveSuperAdmin:
        // 3
        domainClaims.CanRemoveSuperAdmin = true
        break

      // company_admin_claims
      case claimsConstants.CanCreateCompanyAdmin:
        // 4
        domainClaims.CanCreateCompanyAdmin = true
        break
      case claimsConstants.CanEditCompanyAdmin:
        // 5
        domainClaims.CanEditCompanyAdmin = true
        break
      case claimsConstants.CanAssignCompanyToCompanyAdmin:
        // 6
        domainClaims.CanAssignCompanyToCompanyAdmin = true
        break
      case claimsConstants.CanRemoveCompanyAdmin:
        // 7
        domainClaims.CanRemoveCompanyAdmin = true
        break

      // building admin_claims
      case claimsConstants.CanCreateBuildingAdmin:
        // 8
        domainClaims.CanCreateBuildingAdmin = true
        break
      case claimsConstants.CanEditBuildingAdmin:
        // 9
        domainClaims.CanEditBuildingAdmin = true
        break
      case claimsConstants.CanAssignBuildingToBuildingAdmin:
        // 10
        domainClaims.CanAssignBuildingToBuildingAdmin = true
        break
      case claimsConstants.CanRemoveBuildingAdmin:
        // 11
        domainClaims.CanRemoveBuildingAdmin = true
        break


      // office_admin_claims
      case claimsConstants.CanCreateOfficeAdministrator:
        // 12
        domainClaims.CanCreateOfficeAdministrator = true
        break
      case claimsConstants.CanEditOfficeAdministrator:
        // 13
        domainClaims.CanEditOfficeAdministrator = true
        break
      case claimsConstants.CanAssignGroupToOfficeAdministrator:
        // 14
        domainClaims.CanAssignGroupToOfficeAdministrator = true
        break
      case claimsConstants.CanRemoveOfficeAdministrator:
        // 15
        domainClaims.CanRemoveOfficeAdministrator = true
        break

      // user_claims
      case claimsConstants.CanCreateUser:
        // 16
        domainClaims.CanCreateUser = true
        break
      case claimsConstants.CanEditUser:
        // 17
        domainClaims.CanEditUser = true
        break
      case claimsConstants.CanAssignUserToGroup:
        // 18
        domainClaims.CanAssignUserToGroup = true
        break
      case claimsConstants.CanRemoveUser:
        // 19
        domainClaims.CanRemoveUser = true
        break

      // general_user_claims
      case claimsConstants.CanInitiatePasswordChange:
        // 20
        domainClaims.CanInitiatePasswordChange = true
        break
      case claimsConstants.CanManuallyChangeUserPassword:
        // 21
        domainClaims.CanManuallyChangeUserPassword = true
        break
      case claimsConstants.CanListUsers:
        // 22
        domainClaims.CanListUsers = true
        break
      case claimsConstants.CanListRoles:
        // 23
        domainClaims.CanListRoles = true
        break
      case claimsConstants.CanRequestUserInfo:
        // 24
        domainClaims.CanRequestUserInfo = true
        break

      // *** company_managment_claims ***
      // company_claims
      case claimsConstants.CanCreateCompany:
        // 25
        domainClaims.CanCreateCompany = true
        break
      case claimsConstants.CanManageCompanyInformation:
        // 26
        domainClaims.CanManageCompanyInformation = true
        break
      case claimsConstants.CanRemoveCompany:
        // 27
        domainClaims.CanRemoveCompany = true
        break
      case claimsConstants.CanRequestCompanySynopsis:
        // 28
        domainClaims.CanRequestCompanySynopsis = true
        break
      case claimsConstants.CanRequestCompanyInfo:
        // 29
        domainClaims.CanRequestCompanyInfo = true
        break
      case claimsConstants.CanListCompanies:
        // 30
        domainClaims.CanListCompanies = true
        break

      // building_claims
      case claimsConstants.CanCreateBuilding:
        // 31
        domainClaims.CanCreateBuilding = true
        break
      case claimsConstants.CanManageBuildingInformation:
        // 32
        domainClaims.CanManageBuildingInformation = true
        break
      case claimsConstants.CanRemoveBuilding:
        // 33
        domainClaims.CanRemoveBuilding = true
        break
      case claimsConstants.CanListBuildings:
        // 34
        domainClaims.CanListBuildings = true
        break
      case claimsConstants.CanRequestBuildingInfo:
        // 35
        domainClaims.CanRequestBuildingInfo = true
        break
      case claimsConstants.CanRequestBuildingSynopsis:
        // 36
        domainClaims.CanRequestBuildingSynopsis = true
        break
      case claimsConstants.CanManipulateBuildingsParameters:
        // 37
        domainClaims.CanManipulateBuildingsParameters = true
        break

      // groups_claims
      case claimsConstants.CanCreateGroup:
        // 39
        domainClaims.CanCreateGroup = true
        break
      case claimsConstants.CanManageGroupInformation:
        // 40
        domainClaims.CanManageGroupInformation = true
        break
      case claimsConstants.CanRemoveGroup:
        // 41
        domainClaims.CanRemoveGroup = true
        break
      case claimsConstants.CanChangeGroupName:
        // 42
        domainClaims.CanChangeGroupName = true
        break
      case claimsConstants.CanRequestGroupInfo:
        // 43
        domainClaims.CanRequestGroupInfo = true
        break
      case claimsConstants.CanListGroups:
        // 44
        domainClaims.CanListGroups = true
        break
      case claimsConstants.CanManipulateGroupsParameters:
        // 45
        domainClaims.CanManipulateGroupsParameters = true
        break
      case claimsConstants.CanRequestGroupsParameters:
        // 46
        domainClaims.CanRequestGroupsParameters = true
        break
      case claimsConstants.CanListGroupModules:
        // 47
        domainClaims.CanListGroupModules = true
        break
      case claimsConstants.CanRemoveModuleFromGroup:
        // 48
        domainClaims.CanRemoveModuleFromGroup = true
        break
      case claimsConstants.CanAssignModuleToGroup:
        // 49
        domainClaims.CanAssignModuleToGroup = true
        break
      case claimsConstants.CanManipulateGroupLevels:
        // 50
        domainClaims.CanManipulateGroupLevels = true
        break
      case claimsConstants.CanManipulateGroupOnOff:
        // 51
        domainClaims.CanManipulateGroupOnOff = true
        break
      case claimsConstants.CanRequestGroupLevels:
        // 52
        domainClaims.CanRequestGroupLevels = true
        break
      case claimsConstants.CanRequestGroupOnOff:
        // 53
        domainClaims.CanRequestGroupOnOff = true
        break
      case claimsConstants.CanRequestGroupLatestSensorData:
        // 54
        domainClaims.CanRequestGroupLatestSensorData = true
        break
      case claimsConstants.CanRequestGroupHistoricSensorData:
        // 55
        domainClaims.CanRequestGroupHistoricSensorData = true
        break
      case claimsConstants.CanAddGroupMapImage:
        // 56
        domainClaims.CanAddGroupMapImage = true
        break
      case claimsConstants.CanRequestGroupMapImage:
        // 57
        domainClaims.CanRequestGroupMapImage = true
        break
      case claimsConstants.CanRemoveGroupMapImage:
        // 58
        domainClaims.CanRemoveGroupMapImage = true
        break
      case claimsConstants.CanChangeGroupDevicesLocation:
        // 59
        domainClaims.CanChangeGroupDevicesLocation = true
        break
      case claimsConstants.CanRequestGroupDevicesLocation:
        // 60
        domainClaims.CanRequestGroupDevicesLocation = true
        break

      // devices_claims
      case claimsConstants.CanRequestDeviceHistoricSensorData:
        // 61
        domainClaims.CanRequestDeviceHistoricSensorData = true
        break
      case claimsConstants.CanRequestDevicesHeatmap:
        // 62
        domainClaims.CanRequestDevicesHeatmap = true
        break

      // dashboard_claims
      case claimsConstants.CanCreateDashboards:
        // 63
        domainClaims.CanCreateDashboards = true
        break
      case claimsConstants.CanListDashboard:
        // 64
        domainClaims.CanListDashboard = true
        break
      case claimsConstants.CanCreatePanels:
        // 65
        domainClaims.CanCreatePanels = true
        break
      case claimsConstants.CanListPanel:
        // 66
        domainClaims.CanListPanel = true
        break
      case claimsConstants.CanEditPanel:
        // 67
        domainClaims.CanEditPanel = true
        break

      // unused_claims
      case claimsConstants.CanProvisionNewModules:
        // 68
        domainClaims.CanProvisionNewModules = true
        break
      case claimsConstants.CanRequestChangeModuleToGroup:
        // 69
        domainClaims.CanRequestChangeModuleToGroup = true
        break
      case claimsConstants.CanManageGroupSettings:
        // 70
        domainClaims.CanManageGroupSettings = true
        break
      case claimsConstants.CanManageModuleSettings:
        // 71
        domainClaims.CanManageModuleSettings = true
        break
      case claimsConstants.CanRunReports:
        // 72
        domainClaims.CanRunReports = true
        break
      case claimsConstants.CanRunDetailedReports:
        // 73
        domainClaims.CanRunDetailedReports = true
        break
      case claimsConstants.CanRunGraphicalReports:
        // 74
        domainClaims.CanRunGraphicalReports = true
        break

      // new_claims
      case claimsConstants.CanRemoveDevicesLocation:
        // 75
        domainClaims.CanRemoveDevicesLocation = true
        break
      case claimsConstants.CanRequestDashboard:
        // 76
        domainClaims.CanRequestDashboard = true
        break
      case claimsConstants.CanRequestPanelInfo:
        // 77
        domainClaims.CanRequestPanelInfo = true
        break
      case claimsConstants.CanRemovePanel:
        // 78
        domainClaims.CanRemovePanel = true
        break
      case claimsConstants.CanRemoveDashboard:
        // 79
        domainClaims.CanRemoveDashboard = true
        break

      // list_user_companies_buildings_groups_claims
      case claimsConstants.CanListUserCompanies:
        // 80
        domainClaims.CanListUserCompanies = true
        break
      case claimsConstants.CanListUserBuildings:
        // 81
        domainClaims.CanListUserBuildings = true
        break
      case claimsConstants.CanListUserGroups:
        // 82
        domainClaims.CanListUserGroups = true
        break

      // group_power_calibration_claims
      case claimsConstants.CanSetupGroupsPowerCalibration:
        // 83
        domainClaims.CanSetupGroupsPowerCalibration = true
        break
      case claimsConstants.CanRequestGroupsPowerCalibrationStatus:
        // 84
        domainClaims.CanRequestGroupsPowerCalibrationStatus = true
        break

      // aggregation_request_claims
      case claimsConstants.CanRequestGroupAggregationInfo:
        // 87
        domainClaims.CanRequestGroupAggregationInfo = true
        break
      case claimsConstants.CanManageGroupAggregation:
        // 88
        domainClaims.CanManageGroupAggregation = true
        break
      case claimsConstants.CanRequestBuildingAggregationInfo:
        // 89
        domainClaims.CanRequestBuildingAggregationInfo = true
        break
      case claimsConstants.CanManageBuildingAggregation:
        // 90
        domainClaims.CanManageBuildingAggregation = true
        break
      case claimsConstants.CanRequestCompanyAggregationInfo:
        // 91
        domainClaims.CanRequestCompanyAggregationInfo = true
        break
      case claimsConstants.CanManageCompanyAggregation:
        // 92
        domainClaims.CanManageCompanyAggregation = true
        break

      // aggregation_request_claims
      case claimsConstants.CanManageCompanyModules:
        // 93
        domainClaims.CanManageCompanyModules = true
        break
      case claimsConstants.CanListCompanyModules:
        // 94
        domainClaims.CanListCompanyModules = true
        break
      case claimsConstants.CanListAllCompanyModules:
        // 95
        domainClaims.CanListAllCompanyModules = true
        break

      // uv-c control claims
      case claimsConstants.CanManipulateUVControl:
        // 96
        domainClaims.CanManipulateUVControl = true
        break
      case claimsConstants.CanRequestUVControl:
        // 97
        domainClaims.CanRequestUVControl = true
        break

      // groups_area_type_managment_claims
      case claimsConstants.CanCreateAreaType:
        // 98
        domainClaims.CanCreateAreaType = true
        break
      case claimsConstants.CanListAreaTypes:
        // 99
        domainClaims.CanListAreaTypes = true
        break
      case claimsConstants.CanUpdateAreaType:
        // 100
        domainClaims.CanUpdateAreaType = true
        break
      case claimsConstants.CanRemoveAreaType:
        // 101
        domainClaims.CanRemoveAreaType = true
        break
      case claimsConstants.CanGetAreaTypeDetails:
        // 102
        domainClaims.CanGetAreaTypeDetails = true
        break
      case claimsConstants.CanCreateAreaTypeParameter:
        // 103
        domainClaims.CanCreateAreaTypeParameter = true
        break
      case claimsConstants.CanListAreaTypesParameters:
        // 104
        domainClaims.CanListAreaTypesParameters = true
        break
      case claimsConstants.CanUpdateAreaTypeParameter:
        // 105
        domainClaims.CanUpdateAreaTypeParameter = true
        break
      case claimsConstants.CanRemoveAreaTypeParameter:
        // 106
        domainClaims.CanRemoveAreaTypeParameter = true
        break
      case claimsConstants.CanGetAreaTypeParameterDetails:
        // 107
        domainClaims.CanGetAreaTypeParameterDetails = true
        break
      case claimsConstants.CanRemoveParameterValue:
        // 108
        domainClaims.CanRemoveParameterValue = true
        break
      case claimsConstants.CanEditAreaTypeParameterValues:
        // 109
        domainClaims.CanEditAreaTypeParameterValues = true
      
      // generated graph claim
      case claimsConstants.CanRequestGeneratedGraph:
        // 110
        domainClaims.CanRequestGeneratedGraph = true
        break

      // user_password_reset
      case claimsConstants.CanResetUserPassword:
        // 111
        domainClaims.CanResetUserPassword = true
        break

      // notification claims
      case claimsConstants.CanRequestNotification:
        // 112
        domainClaims.CanRequestNotification = true
        break
      case claimsConstants.CanUpdateNotification:
        // 113
        domainClaims.CanUpdateNotification = true
        break
      case claimsConstants.CanCreateNotification:
        // 114
        domainClaims.CanCreateNotification = true
        break
      case claimsConstants.CanRemoveNotification:
        // 115
        domainClaims.CanRemoveNotification = true
        break

      // blue_light_boost_claims
      case claimsConstants.CanActivateDeactivateBlueLightBoost:
        // 116
        domainClaims.CanActivateDeactivateBlueLightBoost = true
        break
      case claimsConstants.CanRequestGroupsBlueLightBoostStatus:
        // 117
        domainClaims.CanRequestGroupsBlueLightBoostStatus = true
        break

      default:
        break
    }
  })
  return domainClaims;
}

export default getDomainClaims;