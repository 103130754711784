import { action } from '../utils/actionCreator'
import * as actions from '../constants'

export const requestUserPasswordReset = form => action(actions.REQUEST_USER_PASSWORD_RESET, { form })

export const successRequestUserPasswordReset = data => action(actions.SUCCESS_REQUEST_USER_PASSWORD_RESET, { data })

export const errorRequestUserPasswordReset = error => action(actions.ERROR_REQUEST_USER_PASSWORD_RESET, { error })

export const changeUserPasswordToken = form => action(actions.CHANGE_USER_PASSWORD_TOKEN, { form })

export const successChangeUserPasswordToken = data => action(actions.SUCCESS_CHANGE_USER_PASSWORD_TOKEN, { data })

export const errorChangeUserPasswordToken = error => action(actions.ERROR_CHANGE_USER_PASSWORD_TOKEN, { error })

export const cleanUSerResetPasswordStates = () => action(actions.CLEAN_USER_RESET_PASSWORD_STATES)