import {
    getGroupBlueLightBoostService,
    putGroupBlueLightBoostService
} from "../services";

export const fetchBlueLightBoostApi = async (tenant_uuid, uuid) => {
    const response = await getGroupBlueLightBoostService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const activateDeactivateBlueLightBoostApi = async (tenant_uuid, uuid, form) => {
    const response = await putGroupBlueLightBoostService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}