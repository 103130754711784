import * as actions from '../constants'
import moment from 'moment'

const initialState = {
  light_capability: "VARIABLE",
}

const lightCapability = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_LIGHT_CAPABILITY_DATA:
      return Object.assign({}, state, {
        light_capability: action.payload.data.light_capability
    })
      
    default:
      return state
  }
}

export default lightCapability