import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Statistic, Card, Modal, Row, Col, Notification, Empty, Tag } from 'antd'
import { withRouter } from "react-router-dom";
import { sensorDataTypes } from '../../constants/sensorDataType'
import Loader from "../../components/Loader";
import { UserContext } from "../../context";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import './styles.css'

import colors from "../../styles";

const { primary_color } = colors;

const { Option } = Select

const dataTypes = Object.keys(sensorDataTypes).map(k => sensorDataTypes[k])

const ElementDetailDataTab = ({
    history,
    tabKey
}) => {
    const {
        userData: { tenant_uuid }
    } = useContext(UserContext);

    const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)
    const filteredIds = useSelector((state) => state.rootReducer.latestData.filteredIds)
    const loading = useSelector(state => state.rootReducer.loaders.loadingLatestData)
    const sensorData = useSelector((state) => state.rootReducer.latestData.sensorData)
    const [currentSensorType, setCurrentSensorType] = useState('temperature')
    const controllers = useSelector((state) => state.rootReducer.latestData.controllers)
    const error = useSelector((state) => state.rootReducer.latestData.error)


    const dispatch = useDispatch()

    const typeChanged = async type => {
        setCurrentSensorType(type)
        dispatch(({
            type: actions.FETCH_LATEST_DATA,
            payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, sensor_type: type }
        }))
    }

    const filterDevices = ids => {
        dispatch(({
            type: actions.FILTER_SENSOR_DATA,
            payload: { ids }
        }))
    }

    useEffect(
        () => {
            if (error !== null) {
                if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
                    Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
                    history.push("/login");
                    dispatch({ type: actions.LOG_OUT })
                } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
                    Modal.destroyAll()
                    Modal.error({
                        destroyOnClose: true,
                        title: error.message,
                        okText: "Retry",
                        onOk: () => {
                            dispatch(({
                                type: actions.FETCH_LATEST_DATA,
                                payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, sensor_type: currentSensorType }
                            }))
                        },
                    })
                } else {
                    Notification.error({ message: "Error", "description": error.message, "duration": 0 });
                    dispatch({ type: actions.CLEAN_LATEST_DATA_ERROR })
                }
            }
        },
        [error]
    )

    const title = () => {
        switch (currentSensorType) {
            case sensorDataTypes.TEMPERATURE:
                return 'Temperature'
            case sensorDataTypes.HUMIDITY:
                return 'Humidity'
            case sensorDataTypes.PRESSURE:
                return 'Pressure'
            case sensorDataTypes.IR:
                return 'Irradiance'
            case sensorDataTypes.LIGHT:
                return 'Lux'
            case sensorDataTypes.CO2:
                return 'CO2'
            case sensorDataTypes.VOC:
                return 'VOC'
            default:
                return ''
        }
    }

    const getTitle = (thesensorType) => {
        switch (thesensorType) {
            case sensorDataTypes.TEMPERATURE:
                return 'Temperature'
            case sensorDataTypes.HUMIDITY:
                return 'Humidity'
            case sensorDataTypes.PRESSURE:
                return 'Pressure'
            case sensorDataTypes.IR:
                return 'Irradiance'
            case sensorDataTypes.LIGHT:
                return 'Lux'
            case sensorDataTypes.CO2:
                return 'CO2'
            case sensorDataTypes.VOC:
                return 'VOC'
            default:
                return ''
        }
    }

    const symbol = () => {
        switch (currentSensorType) {
            case sensorDataTypes.TEMPERATURE:
                return (
                    <span>
                        <sup>o</sup>C
          </span>
                )
            case sensorDataTypes.HUMIDITY:
                return <span>%</span>
            case sensorDataTypes.PRESSURE:
                return <span>mbar</span>
            case sensorDataTypes.IR:
                return <span></span>
            case sensorDataTypes.LIGHT:
                return <span>lux</span>
            case sensorDataTypes.CO2:
                return <span>ppm</span>
            case sensorDataTypes.VOC:
                return <span>ppb</span>
            default:
                return ''
        }
    }

    const renderDeviceFilter = () => (
        <div style={{ marginRight: '15px' }}>
            <span style={{ marginRight: '5px', fontWeight: 600 }}>Filter: </span>
            <Select
                mode="multiple"
                style={{ width: 'auto', minWidth: 200 }}
                placeholder="Filter devices"
                onChange={filterDevices}
                value={filteredIds}
            >
                {sensorData
                    .filter(c => c.aws_id)
                    .map(c => (
                        <Option key={c.aws_id}>{c.aws_id}</Option>
                    ))}
            </Select>
        </div>
    )

    const renderSensorSelector = () => (
        <div>
            <span style={{ marginRight: '5px', fontWeight: 600 }}>
                Select Sensor:{' '}
            </span>
            <Select
                disabled={loading}
                loading={loading}
                style={{ minWidth: 150 }}
                placeholder="Select a device"
                onChange={typeChanged}
                value={currentSensorType}
            >
                {dataTypes.map(key => (
                    <Option key={key} value={key}>
                        <span style={{ textTransform: 'capitalize' }}>{getTitle(key)}</span>
                    </Option>
                ))}
            </Select>
        </div>
    )

    const convertLastData = (sensorName, sensor_data) => {
        if (sensor_data.sensor_reading && sensorName === 'pressure') {
            const reading = sensor_data.sensor_reading
            let tmp =
                reading.includes(',') || reading.includes('.')
                    ? parseFloat(reading)
                    : parseInt(reading)

            tmp = (tmp / 100).toFixed(2)

            return tmp
        }
        return sensor_data.sensor_reading || 0
    }

    const renderData = () => {
        return loading
            ? <Loader />
            : controllers.length > 0
                ?
                (
                    controllers.map(({ aws_id, light_capability, uv_light_capability, sensor_data }) => (
                        <Col key={aws_id} xs={8} className="data__col">
                            <Card className="data__card" style={{ marginTop: '5%', marginRight: '5%' }}>
                                <div className="content-device-tags">
                                    <Tag className="light_capability">{light_capability} Colour</Tag>
                                    {uv_light_capability && (uv_light_capability === "UV" || uv_light_capability === "uv") ?
                                        <Tag className="light_capability" style={{ marginLeft: "20px"}}>{uv_light_capability}</Tag> : <></>}
                                </div>
                                <span className="device-id" style={{ color: primary_color }}>{aws_id}</span>
                                <Statistic value={convertLastData(currentSensorType, sensor_data)} suffix={symbol()} />
                                <span className="title">{title()}</span>
                            </Card>
                        </Col>
                    ))
                )
                :
                (
                    <div style={{ marginTop: '5%' }}><Empty /></div>
                )
    }



    useEffect(() => {
        if (tabKey === "latest_data") {
            dispatch(({
                type: actions.FETCH_LATEST_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, sensor_type: currentSensorType }
            }))
        } else {
            dispatch(({
                type: actions.CLEAN_LATEST_DATA_STATE
            }))
        }
    },
        [tabKey, element.uuid])

    return (
        <div className="group-device-data">
            <div className="header">
                <Row>
                    <Col xs={10}>
                        {renderDeviceFilter()}
                    </Col>
                    <Col xs={10}>
                        {renderSensorSelector()}
                    </Col>
                </Row>
            </div>
            <div className="data">
                <Row>
                    {renderData()}
                </Row>
            </div>
        </div>
    )
}

export default withRouter(ElementDetailDataTab) 