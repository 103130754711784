import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUser2faQr, errorFetchUser2faQr, successFetchUserProfile, successUpdateUserProfile, errorFetchUpdateUserProfile } from '../actions/userProfileActions'
import { fetchUser2faQrApi, fetchUser2faQrLoginApi } from '../api/userProfileApi'
import { getUserDetailsApi } from '../api/userManagmentApi'
import { toogleGetUser2faQrLoader, toogleUserProfileLoader } from '../actions/loaderActions'
import { updateUserProfileApi } from '../api/userProfileApi'

export function* getUser2faQr(action) {
    try {
        yield put(toogleGetUser2faQrLoader(true))
        
        let data = null

        if (!action.payload.isLogin) {
            data = yield call(fetchUser2faQrApi,
                action.payload.tenant_uuid,
                action.payload.uuid
            )
        } else {
            data = yield call(fetchUser2faQrLoginApi,
                action.payload.tenant_uuid,
                action.payload.uuid
            )
        }

        yield put(successFetchUser2faQr(data))
        yield put(toogleGetUser2faQrLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_USER) {
                error = { "message": "User was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }

        yield put(toogleGetUser2faQrLoader(false))
        yield put(errorFetchUser2faQr(error))
    }
}

export function* getUserProfile(action) {
    try {
        yield put(toogleUserProfileLoader(true))
        
        const data = yield call(getUserDetailsApi,
                action.payload.tenant_uuid,
                action.payload.uuid
        )
      
        yield put(successFetchUserProfile(data))
        yield put(toogleUserProfileLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_USER) {
                error = { "message": "User was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }

        yield put(toogleUserProfileLoader(false))
        yield put(errorFetchUpdateUserProfile(error))
    }
}

export function* updateUserProfile(action) {
    try {
        yield put(toogleUserProfileLoader(true))
        
        const data = yield call(updateUserProfileApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.form
        )
      
        yield put(successUpdateUserProfile(data))
        yield put(toogleUserProfileLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_USER) {
                error = { "message": "User was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }

        yield put(toogleUserProfileLoader(false))
        yield put(errorFetchUpdateUserProfile(error))
    }
}

export function* fetchUser2faQrWatcher() {
    yield takeLatest(actions.FETCH_USER_2FA_QR, getUser2faQr)
}

export function* fetchUserProfileWatcher() {
    yield takeLatest(actions.FETCH_USER_PROFILE, getUserProfile)
}

export function* updateUserProfileWatcher() {
    yield takeLatest(actions.UPDATE_USER_PROFILE, updateUserProfile)
}