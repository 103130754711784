import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchPowerCalibrationData, successSavePowerCalibrationSetup, errorFetchUpdatePowerCalibration } from '../actions/powerCalibrationActions'
import { fetchPowerCalibrationApi, updatePowerCalibrationApi } from '../api/powerCalibrationApi'
import { tooglePowerCalibrationLoader } from '../actions/loaderActions'

export function* getPowerCalibrationValues(action) {
    try {
        yield put(tooglePowerCalibrationLoader(true))

        const data = yield call(fetchPowerCalibrationApi,
            action.payload.tenant_uuid,
            action.payload.uuid
        )

        data.update = false

        yield put(successFetchPowerCalibrationData(data))
        yield put(tooglePowerCalibrationLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }

        yield put(tooglePowerCalibrationLoader(false))
        yield put(errorFetchUpdatePowerCalibration(error))
    }
}

export function* savePowerCalibration(action) {
    try {
        yield put(tooglePowerCalibrationLoader(true))

        const data = yield call(updatePowerCalibrationApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.form,
            )

        yield put(successSavePowerCalibrationSetup(data))
        yield put(tooglePowerCalibrationLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }

        yield put(tooglePowerCalibrationLoader(false))
        yield put(errorFetchUpdatePowerCalibration(error))
    }
}

export function* fetchPowerCalibrationWatcher() {
    yield takeLatest(actions.FETCH_POWER_CALIBRATION_DATA, getPowerCalibrationValues)
}

export function* savePowerCalibrationWatcher() {
    yield takeLeading(actions.SAVE_POWER_CALIBRATION_SETUP, savePowerCalibration)
}