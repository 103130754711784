import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, Notification
} from "antd";
import moment from 'moment'
import Loader from "../Loader";
import DevicesHeatMap from "../DevicesHeatMap/indexpeopleanalytics";
import { UserContext } from "../../context";
import "./style.css";


import { getGroupMetadataImage, getGroupMetadataDevice, getGroupMetadataExits, getHighPrecisionDataHour, getDataAnalysisRequest, createVectorDataAnalysisRequest, getVectorDataAnalysisStatusRequest, buildanalysismapdata, getGroupDeviceGraph, getResponseDataArray } from "../DevicesHeatMap/heatmapfunction";

const { API_STATUS_MAXIMUM_WAIT_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, API_STATUS_CHECK_SECOND_INTERVAL_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, DEFAULT_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, DEFAULT_DOWNSAMPLING_SECONDS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP } = window.Configs;


window.waitData = false
window.vectordataarray = []

//var lisVectorInterval = null

const PeopleAnalyticsMapsTab = ({
  history,
  element,
  element_uuid,
  tabKey,
  floor_plan,
  workspace_radius,
  occupancy_limit
}) => {
  const [loading, setLoading] = useState(false);

  const [mapType, setMapType] = useState("occupancy")

  const [occupancyLimit, setOccupancyLimit] = useState(occupancy_limit);
  //control show or not visibility of frecuency map select sensor, date and hour control
  const [data, setData] = useState({
    image: "",
    imageDisplay: "horizontal",
    width: 0,
    height: 0,
    ftToPixel: 0,
    devices: [],
    extis: [],
    chartData: [
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
    ],
    data: [],
    chartDensityData: [
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
      { devices: [] },
    ],
    dataDensity: [],
    params: [{
      active_devices: 0,
      occupancy_events: 0,
      occupants: 0,
      warning: [{}],
      group_control_data: [],
    }],

  });
  const [config, setConfig] = useState({
    shouldRenderFrecuencyMap: false,
    sensorName: 'enh_motion',
    day: moment().format("YYYY-MM-DD"),
    hour: Number(moment().format("HH")),
    'element_uuid': element.uuid,
    'element_name': element.name,

  });

  const [dataVectors, setDataVectors] = useState({
    data: []
  })
  const [configVectors, setConfigVectors] = useState({
    config: {
      start: "",
      end: "",
      interval: 5,
      downsampling_secs: 5,
      upsampling_secs: 300,
      data_analysis_request_uuid: ""
    }

  })

  const [dataWarning, setDataWarning] = useState(false)

  const [responseVectorData, setResponseVectorData] = useState([])
  const [waitRefreshDiff, setWaitRefreshDiff] = useState(false)
  const [indexNewValue, setIndexNewValue] = useState(0)

  const [devicesGraph, setDevicesGraph] = useState([])
  const [vectorSpiner, setVectorSpiner] = useState(false)
  const [startDateApiCall, setStartDateApiCall] = useState("")
  const [refresh, setRefresh] = useState(0);
  const [formConfig, setFormConfig] = useState({})

  const [jumpToRefresh, setJumpToRefresh] = useState(false)

  const [readyimageload, setReadyImageLoad] = useState("empty");
  const { userData: { tenant_uuid } } = useContext(UserContext);

  const dispatch = useDispatch()

  useEffect(() => {
  },
    [dataVectors])

  useEffect(() => {
  },
    [jumpToRefresh])

  useEffect(() => {

    if (formConfig.start && formConfig.end
      && formConfig.downsampling_secs && formConfig.data_analysis_status_type
      && formConfig.range
    ) {
      setDataWarning(false)
      createVectorsMaps(formConfig, devicesGraph)
    }
  },
    [formConfig])

  useEffect(() => {
    setOccupancyLimit(occupancy_limit)
  },
    [occupancy_limit])

  useEffect(() => {
    if (tabKey === "people_analytics" && mapType !== "vectors") {
      ; (async () => {
        loadHeatmap()
      })()
    }

  },
    [tabKey, element_uuid, mapType])//,element

  useEffect(() => {
    if (data.devices && data.devices.length && data.devices.length > 0 && mapType === "vectors") {
      ; (async () => {
        loadVectorsMaps()
        setDataWarning(false)
      })()
    }

    return () => {
      stoplistenerVectorDataStatus()
    };

  },
    [data.devices, mapType])

  useEffect(() => {

    return () => {
      stoplistenerVectorDataStatus()
    };

  },
    [])

  useEffect(() => {
    window.waitData = waitRefreshDiff
  },
    [waitRefreshDiff])

  useEffect(() => {
    window.vectordataarray = responseVectorData
    return () => {

    };

  },
    [responseVectorData])

  useEffect(() => {

    return () => {
    };
  },
    [indexNewValue])

  const setParentMapType = (param) => {
    setMapType(param)
  }


  const loadHeatmap = async () => {
    setLoading(true);
    try {
      const newconfig = {
        shouldRenderFrecuencyMap: false,
        sensorName: 'enh_motion',
        day: moment().format("YYYY-MM-DD"),
        hour: Number(moment().format("HH")),
        'element_uuid': element.uuid,
        'element_name': element.name
      };

      const newdata = await getDataHeatMap(tenant_uuid, newconfig)
      //console.log(newdata)
      setReadyImageLoad("empty")
      setLoading(false);
      await setConfig(
        newconfig
      )
      await setData(
        newdata
      )
    } catch{

    }
  }

  const loadVectorsMaps = async () => {
    setLoading(true);
    try {
      const theconfig = {
        element_type: "group",
        element_uuid: element_uuid,
        analysis_type: 'movement_transition'
      };

      let devices_graph = []
      //get json from device-neighbors graph
      const responsedevices = await getGroupDeviceGraph(history, dispatch, tenant_uuid, theconfig.element_uuid);
      if (responsedevices && !responsedevices.error) {
        devices_graph = responsedevices.data
        setDevicesGraph(devices_graph)
        //get movement data -> COMPLETED or empty
        const { newdata, newconfig } = await getDataVectorHeatMap(tenant_uuid, theconfig, devices_graph)
        setReadyImageLoad("empty")
        setLoading(false);

        await setConfigVectors(
          { config: newconfig }
        )

        if (newconfig && newconfig.data_analysis_status_type && newconfig.data_analysis_status_type === 3) {
          //case request COMPLETED
          await setDataVectors(
            {
              data: newdata,
              update: false
            }
          )
          setLoading(false);
        } else if (newconfig && newconfig.data_analysis_status_type && newconfig.data_analysis_status_type === 4) {
          //case request FAILED
          await setDataVectors(
            {
              data: [],
              update: false
            }
          )
          setLoading(false);
        } else {
          await setDataVectors(
            {
              data: [],
              update: false
            }
          )
          setLoading(false);
        }

        //make request of last movements, current time
        const range = DEFAULT_TIME_RANGE_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP
        const end = moment().utc().format("YYYY-MM-DD HH:mm:ss")
        const tmp_end = moment().utc().format("YYYY-MM-DD HH:mm:ss")
        const tmp_start = moment(tmp_end, "YYYY-MM-DD HH:mm:ss").subtract(range, "minutes")
        const start = tmp_start.format("YYYY-MM-DD HH:mm:ss")
        const downsampling_secs = DEFAULT_DOWNSAMPLING_SECONDS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP

        const form = {
          start: start,
          end: end,
          downsampling_secs: downsampling_secs,
          data_analysis_status_type: 1
        };

        createVectorsMaps(form, devices_graph)

      } else if (responsedevices && responsedevices.message === "Connection is lost!") {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => loadVectorsMaps(),
        })
        setLoading(false);
      } else {
        await setDataVectors(
          {
            data: [],
            devices_graph: [],
            update: false
          }
        )
        setLoading(false);
      }
    } catch{
      setLoading(false);
    }
  }

  const getDataHeatMap = async (tenant_uuid, theconfig) => {
    let image = "";
    let devices = [];
    let exits = [];

    const responseimage = await getGroupMetadataImage(history, dispatch, tenant_uuid, theconfig.element_uuid);


    if (responseimage && !responseimage.error) {
      image = responseimage.data;
      const responsedevices = await getGroupMetadataDevice(history, dispatch, tenant_uuid, theconfig.element_uuid);
      if (responsedevices && !responsedevices.error) {
        devices = responsedevices.data

        const responseexits = await getGroupMetadataExits(history, dispatch, tenant_uuid, theconfig.element_uuid);
        if (responseexits && !responseexits.error) {
          exits = responseexits.data
        } else if (responseexits && responseexits.message === "Connection is lost!") {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: "Connection is lost!",
            okText: "Retry",
            onOk: () => loadHeatmap(),
          })
        }
      } else if (responsedevices && responsedevices.message === "Connection is lost!") {
        Modal.destroyAll()
        Modal.error({
          destroyOnClose: true,
          title: "Connection is lost!",
          okText: "Retry",
          onOk: () => loadHeatmap(),
        })
      }
    } else if (responseimage && responseimage.message === "Connection is lost!") {
      Modal.destroyAll()
      Modal.error({
        destroyOnClose: true,
        title: "Connection is lost!",
        okText: "Retry",
        onOk: () => loadHeatmap(),
      })
    }

    const { tmpData, active_devices, occupancy_events, occupants, warning, tmpDensityData, group_control_data, control_group } = await getHighPrecisionDataHour(history, dispatch, tenant_uuid, theconfig, devices, occupancyLimit);
    const data = tmpData;
    const datadensity = tmpDensityData;

    let emptydevices = []
    for (let index = 0; index < devices.length; index++) {
      emptydevices.push([devices[index].x, devices[index].y, "1"])
    }
    let newchartdata = [];
    for (let index = 0; index < 24; index++) {
      newchartdata.push({ devices: emptydevices })
    }
    newchartdata[theconfig.hour] = (data[0]);

    let newchartdensitydata = [];
    for (let index = 0; index < 24; index++) {
      newchartdensitydata.push({ devices: emptydevices })
    }
    newchartdensitydata[theconfig.hour] = (datadensity[0]);

    let newparams = [];
    for (let index = 0; index < 24; index++) {
      newparams.push({
        active_devices: 0,
        occupancy_events: 0,
        warning: [],
        occupants: 0,
        group_control_data: [],
      })
    }

    newparams[theconfig.hour] = {
      active_devices: active_devices,
      occupancy_events: occupancy_events,
      warning: warning,
      occupants: occupants,
      group_control_data: group_control_data,
    }

    //return ({ image: image, devices: devices, chartData: data, data: data[theconfig.hour].devices })
    return ({
      image: image,
      devices: devices,
      exits: exits,
      chartData: newchartdata,
      chartDensityData: newchartdensitydata,
      data: data[0].devices,
      dataDensity: datadensity[0].devices,
      //active_devices: devices.length > 0 ? (active_devices / devices.length) * 100 : 0,
      //occupancy_events: occupancy_events,
      //warning: warning,
      //occupants: occupants,
      params: newparams,
      control_group: control_group,
    })
  }


  const getDataVectorHeatMap = async (tenant_uuid, theconfig, devices_graph) => {
    const { data, config } = await getDataAnalysisRequest(history, dispatch, tenant_uuid, theconfig, devices_graph);
    return { newdata: data, newconfig: config }
  }


  //when change the image, check dimension and asign correct display class
  useEffect(() => {
    setReadyImageLoad(data.image)
  },
    [data, config, dataVectors, configVectors, data.image, data.devices])

  const changeLoading = (value) => {
    setLoading(value);
  }

  //DevicesHeatMap function update
  const updateParentConfig = (parentindex, parentconfig) => {
    setConfig(parentconfig);
  }

  //DevicesHeatMap function update
  const updateParentData = (parentindex, parentdata) => {
    setData(parentdata);
  }

  //vector config function update
  const updateParentConfigVectors = (parentindex, parentconfig) => {
    setConfigVectors(parentconfig);
  }

  //vector data function update
  const updateParentDataVectors = (parentindex, parentdata) => {
    setDataVectors(parentdata);
  }

  //save vector map form config values
  const createParentVectors = (form) => {
    setFormConfig(form)
  }

  
  const setRefreshMinutesInterval = (param) => {
    setRefresh(param)
  }

  const setJumpToRefreshValue = (param) => {
    setJumpToRefresh(param)
  }

  //function to automatically refresh vector map data
  const refreshVectorsMaps = async (devices_graph) => {
    //make refresh
    let end = moment().utc().format("YYYY-MM-DD HH:mm:ss")
    const tmp_end = moment().utc().format("YYYY-MM-DD HH:mm:ss")
    const tmp_start = moment(tmp_end, "YYYY-MM-DD HH:mm:ss").subtract(formConfig.range, "minutes")
    let start = tmp_start.format("YYYY-MM-DD HH:mm:ss")

    const responseDataArray = window.vectordataarray
    //optimization to refresh only delta time and not the entire configuration time range
    if (responseDataArray && responseDataArray.length && responseDataArray.length > 0) {
      const current_data1 = moment().utc().format("YYYY-MM-DD HH:mm:ss")
      const current_data = moment(current_data1, "YYYY-MM-DD HH:mm:ss")
      
      const last_end_1 = moment((responseDataArray[responseDataArray.length - 1]).interval_end_time).format("YYYY-MM-DD HH:mm:ss")
      const last_end = moment(last_end_1, "YYYY-MM-DD HH:mm:ss")
      const diffdata = (moment(current_data)).diff(moment(last_end), 'minutes')
      
      //check date for refresh < range and set api dates according to delta time
      if (parseInt(diffdata) < parseInt(formConfig.range)) {
        setWaitRefreshDiff(true) //case delta time
        start = last_end.format("YYYY-MM-DD HH:mm:ss")
        end = current_data.format("YYYY-MM-DD HH:mm:ss")
      }
    }

    let form = { ...formConfig }
    form.start = start
    form.end = end
    form.data_analysis_status_type = 1

    createVectorsMaps(form, devices_graph)
  }

  //function to create a vector map data request
  const createVectorsMaps = async (form, devices_graph) => {
    stoplistenerVectorDataStatus()
    try {
      setVectorSpiner(true)
      const result_data = await createVectorDataAnalysisRequest(history, dispatch, tenant_uuid, "group", element_uuid, "movement_transition", form)

      //error api
      if (result_data && result_data.error && result_data.error === true) {
        setDataVectors({ data: [], update: false })
        stoplistenerVectorDataStatus()
      } else {
        //success api
        
        //process data
        if (data.devices && data.devices.length && data.devices.length > 0) {

          const newconfig = {
            uuid: result_data.data.uuid,
            start: result_data.data.start,
            end: result_data.data.end,
            downsampling_secs: result_data.data.downsampling_secs,
            upsampling_secs: result_data.data.upsampling_secs,
            data_analysis_status_type: result_data.data.data_analysis_status_type
          }

          setConfigVectors({ config: newconfig })
          //if type diferent de 3 o 4
          if (result_data && result_data.data && result_data.data.data_analysis_status_type && result_data.data.data_analysis_status_type === 3) {
            //convert string data to json
            const responseDataArray = getResponseDataArray(result_data.data)
            setResponseVectorData(responseDataArray)
            window.vectordataarray = responseDataArray

            //transform data to required format by map component
            const vectordata = buildanalysismapdata(responseDataArray, devices_graph)
            setDataVectors({
              "data": vectordata, "config": {
                "uuid": result_data.data.uuid,
                "end": result_data.data.end,
                "start": result_data.data.start,
                "upsampling_secs": result_data.data.upsampling_secs,
                "downsampling_secs": result_data.data.downsampling_secs,
                "analysis_type": result_data.data.analysis_type,
                "analysis_type_id": result_data.data.analysis_type_id,
                "data_analysis_status_type": result_data.data.data_analysis_status_type,
              }
            })
            stoplistenerVectorDataStatus()
          } else if (result_data && result_data.data && result_data.data.data_analysis_status_type && (result_data.data.data_analysis_status_type === 1 || result_data.data.data_analysis_status_type === 2)) {
            //guardar tiempo actual
            setStartDateApiCall(moment().format("YYYY-MM-DD HH:mm:ss"))
            const startdata = moment().format("YYYY-MM-DD HH:mm:ss")
            window.lisVectorInterval = setInterval(listenerVectorDataStatus, API_STATUS_CHECK_SECOND_INTERVAL_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP * 1000, startdata, newconfig, devices_graph);
          } else {
            //mostrar notificaion de error
            //Notification.error({ message: "Error", "description": "Data could not be obtained", "duration": 0 });
            //setDataVectors({ data: [], update: false })
            setDataWarning(true)
            stoplistenerVectorDataStatus()
            
          }

        } else {
          setVectorSpiner(false)
        }

        //setLoading(false);
        //await setDataVectors(
        //  {data: newdata}
        //)
      }
    } catch{
      setVectorSpiner(false)
    }
  }

  //setInterval function 
  function listenerVectorDataStatus(startdata, newconfig, devices_graph) {
    getVectorsMaps(startdata, newconfig, devices_graph)
  }

  //stop setInterval function
  function stoplistenerVectorDataStatus() {
    setStartDateApiCall("")
    setVectorSpiner(false)
    if (window.lisVectorInterval) {
      clearInterval(window.lisVectorInterval);
      window.lisVectorInterval = undefined;
    }
  }

  //call for setInterval function. Calling function that asks for status of data vector map request
  const getVectorsMaps = async (startdate, newconfig, devices_graph, vectorData) => {
    if (window.lisVectorInterval !== undefined) {
      try {
        setVectorSpiner(true)
        if (newconfig && newconfig.uuid && newconfig.uuid != "") {
          //api call
          const result_data = await getVectorDataAnalysisStatusRequest(history, dispatch, tenant_uuid, newconfig.uuid)

          //error api
          if (result_data && result_data.error && result_data.error === true) {
            setDataVectors({ data: [], update: false })
            stoplistenerVectorDataStatus()
          } else {
          //success api
            if (devices_graph && devices_graph.length && devices_graph.length > 0) {

              setConfigVectors({
                "config": {
                  "uuid": result_data.data.uuid,
                  "end": result_data.data.end,
                  "start": result_data.data.start,
                  "upsampling_secs": result_data.data.upsampling_secs,
                  "downsampling_secs": result_data.data.downsampling_secs,
                  "analysis_type": result_data.data.analysis_type,
                  "analysis_type_id": result_data.data.analysis_type_id,
                  "data_analysis_status_type": result_data.data.data_analysis_status_type,
                }
              })
              //if type completed
              if (result_data.data.data_analysis_status_type === 3) {
                //transform string data response to json
                let array_data = await getResponseDataArray(result_data.data)
                //setResponseVectorData(responseDataArray)
                
                //If request I do not include the entire range and only a fragment of time, do concat with existing data
                //delta time
                if (window.waitData) {
                  //Concatenate existing data with new data
                  const vectorData = window.vectordataarray
                  let concat_array = vectorData.concat(array_data)

                  const last_date1 = moment(result_data.data.end).format("YYYY-MM-DD HH:mm:ss")
                  const last_date = moment(last_date1, "YYYY-MM-DD HH:mm:ss")
                  
                  const lenarray = concat_array.length
                  let index = 0;
                  //remove date < range
                  while (index < lenarray) {
                    const old_date1 = moment(concat_array[0].interval_end_time).format("YYYY-MM-DD HH:mm:ss")
                    const old_date = moment(old_date1, "YYYY-MM-DD HH:mm:ss")

                    if (parseInt(last_date.diff(old_date, 'seconds')) > parseInt(formConfig.range) * 60) {
                      concat_array.splice(0, 1);
                    } else {
                      break;
                    }
                    index++;
                  }

                  const indexnewvalue = concat_array.length - array_data.length

                  array_data = concat_array
                  setResponseVectorData(concat_array)

                  //update animation pointer
                  if (jumpToRefresh) {
                    setIndexNewValue(indexnewvalue > 0 && indexnewvalue < concat_array.length ? indexnewvalue : 0);
                  } else {
                    setIndexNewValue(0);
                  }

                  setWaitRefreshDiff(false);
                } else {
                  //case api range call
                  //range
                  setResponseVectorData(array_data)
                }

                window.vectordataarray = array_data
                //transform data to required format by map component
                const vectordata = await buildanalysismapdata(array_data, devices_graph)
                setDataVectors({
                  "data": vectordata, "config": {
                    "uuid": result_data.uuid,
                    "end": result_data.end,
                    "start": result_data.start,
                    "upsampling_secs": result_data.upsampling_secs,
                    "downsampling_secs": result_data.downsampling_secs,
                    "analysis_type": result_data.analysis_type,
                    "analysis_type_id": result_data.analysis_type_id,
                    "data_analysis_status_type": result_data.data_analysis_status_type,
                  }
                })
                stoplistenerVectorDataStatus()

                if (refresh > 0) {
                  //setFormConfig(form);99
                  setTimeout(refreshVectorsMaps, refresh * 60 * 1000, devices_graph);
                }

              } else if (result_data.data.data_analysis_status_type === 4) {
                //Notification.error({ message: "Error", "description": "Data could not be obtained", "duration": 0 });
                //setDataVectors({ data: [], update: false })
                setDataWarning(true)
                stoplistenerVectorDataStatus()
              } else {
                //chequear diferencia > 5 minutos
                if (startdate !== "") {
                  const current_time = moment()
                  const initial_time = moment(startdate, "YYYY-MM-DD HH:mm:ss")
                  const duration = moment.duration(current_time.diff(initial_time));
                  const minutes = duration.asMinutes();
                  if (minutes > API_STATUS_MAXIMUM_WAIT_MINUTES_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP) {
                    //Notification fail time
                    stoplistenerVectorDataStatus()
                    setStartDateApiCall("")
                    Notification.error({ message: "Error", description: "The request takes too long to respond", duration: 0 });

                  }
                }
              }
            }
          }
        } else {
          setVectorSpiner(false)
          setStartDateApiCall("")
          stoplistenerVectorDataStatus()
        }

        //setLoading(false);
        //await setDataVectors(
        //  {data: newdata}
        //)
      } catch{

      }
    }
  }

  const render = () => {
    const content = (
      <>
        <DevicesHeatMap
          index={-1}
          editabled={true}
          element_uuid={element_uuid}
          parentMaType={mapType}
          setParentMapType={setParentMapType}

          parentdata={data}
          parentconfig={config}
          updateParentConfig={updateParentConfig}
          updateParentData={updateParentData}
          changeLoading={changeLoading}
          floor_plan={floor_plan}
          workspace_radius={workspace_radius}
          occupancy_limit={occupancy_limit}

          data_warning={dataWarning}

          parentdataVectors={dataVectors}
          parentconfigVectors={configVectors}
          updateParentConfigVectors={updateParentConfigVectors}
          updateParentDataVectors={updateParentDataVectors}
          createParentVectors={createParentVectors}
          vectorSpiner={vectorSpiner}
          setRefreshMinutesInterval={setRefreshMinutesInterval}
          setJumpToRefresh={setJumpToRefreshValue}
          indexNewValue={indexNewValue}
        />
      </>
    );

    return (
      loading //isLoading
        ? <Loader />
        :
        <>
          {
            (readyimageload !== "empty" ?
              <>
                {content}
              </>
              : <Loader />)
          }
        </>
    );
  }

  return (
    render()
  )
}

export default PeopleAnalyticsMapsTab;
