import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Row, Col, Notification, Modal, Icon, Alert } from 'antd'
import { withRouter } from "react-router-dom";
import { PieChart, Pie, Cell, Label } from 'recharts'
import { UserContext } from "../../context";
import CenteredRow from "../CenteredRow";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import colors from "../../styles";
import "./style.css";

const { primary_color_hover } = colors;

const Option = Select.Option

let COLORS = ['#287d8e', '#ced8de']

const ElementDetailEnergyAnalysisOverviewTab = ({
  history,
  tabKey
}) => {
  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)

  const element = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const currentDemandData = useSelector((state) => state.rootReducer.energyAnalysisOverview.currentDemandData)
  const currentDemand = useSelector((state) => state.rootReducer.energyAnalysisOverview.currentDemand)

  const currentConsumptionData = useSelector((state) => state.rootReducer.energyAnalysisOverview.currentConsumptionData)
  const currentConsumption = useSelector((state) => state.rootReducer.energyAnalysisOverview.currentConsumption)

  const offPeakConsumptionData = useSelector((state) => state.rootReducer.energyAnalysisOverview.offPeakConsumptionData)
  const offPeakConsumption = useSelector((state) => state.rootReducer.energyAnalysisOverview.offPeakConsumption)

  const midPeakConsumptionData = useSelector((state) => state.rootReducer.energyAnalysisOverview.midPeakConsumptionData)
  const midPeakConsumption = useSelector((state) => state.rootReducer.energyAnalysisOverview.midPeakConsumption)

  const onPeakConsumptionData = useSelector((state) => state.rootReducer.energyAnalysisOverview.onPeakConsumptionData)
  const onPeakConsumption = useSelector((state) => state.rootReducer.energyAnalysisOverview.onPeakConsumption)

  const off_peak_cost = useSelector((state) => state.rootReducer.energyAnalysisOverview.off_peak_cost)
  const mid_peak_cost = useSelector((state) => state.rootReducer.energyAnalysisOverview.mid_peak_cost)
  const on_peak_cost = useSelector((state) => state.rootReducer.energyAnalysisOverview.on_peak_cost)

  const loading = useSelector(state => state.rootReducer.loaders.loadingEnergyConsumptionOverview)
  const error = useSelector((state) => state.rootReducer.energyAnalysisOverview.errorConsumptionData)
  const invalidPeakNotification = useSelector((state) => state.rootReducer.energyAnalysisOverview.invalidPeakNotification)

  const dispatch = useDispatch()

  useEffect(() => {
    if (tabKey === "energy_analysis_overview") {
      dispatch(({
        type: actions.FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA,
        payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, group_type: element.group_type }
      }))
    }
  }, [tabKey, element])

  useEffect(() => {

    if (tabKey === "energy_analysis_overview") {
      dispatch(({
        type: actions.FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA,
        payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, group_type: element.group_type }
      }))
    }
  }, [])

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              dispatch(({
                type: actions.FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: element.uuid, group_type: element.group_type }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_ERROR })
        }
      }
    },
    [error]
  )


  return (
    <>
      {invalidPeakNotification && (
        <Row>
          <Alert
            message="Peak hours have not been setup!!"
            description="You need to setup the peak hours on the settings of this element."
            type="error"
            showIcon
          />
          <br />
        </Row>
      )}
      <Row type="flex" align="middle">
        <Col xs={8}>
          {loading ? (
            <CenteredRow typeClass="screen" >
              <span className={"icon-animated"}>
                <Icon
                  type="loading"
                  style={{ color: "#287d8d", fontSize: "50px" }}
                />
              </span>
            </CenteredRow>
          ) : (

              <div style={{ margin: 'auto' }}>
                <h4>Current Demand (KW) </h4>
                  <PieChart width={150} height={150}>
                    <Pie
                      data={currentDemandData}
                      dataKey="value"
                      cx={70}
                      cy={70}
                      innerRadius={50}
                      outerRadius={65}
                      startAngle={90}
                      endAngle={450}
                      fill="#287d8e"
                      labelLine={false}
                    >
                      {
                        currentDemandData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                      }
                      <Label className="label-center-piechart" value={Number(currentDemand).toFixed(2) + ""} fill={'#555'} position="center" />
                    </Pie>
                  </PieChart>
              </div>
            )}
        </Col>
        <Col xs={8}>
          {loading ? (
            <CenteredRow typeClass="screen" >
              <span className={"icon-animated"}>
                <Icon
                  type="loading"
                  style={{ color: "#287d8d", fontSize: "50px" }}
                />
              </span>
            </CenteredRow>
          ) : (

              <div style={{ margin: 'auto' }}>
                <h4>Current Consumption (KWh) </h4>
                <PieChart width={150} height={150}>
                  <Pie
                    data={currentConsumptionData}
                    dataKey="value"
                    cx={70}
                    cy={70}
                    innerRadius={50}
                    outerRadius={65}
                    startAngle={90}
                    endAngle={450}
                    fill="#287d8e"
                    labelLine={false}
                  >
                    {
                      currentConsumptionData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                    }
                    <Label className="label-center-piechart" value={Number(currentConsumption).toFixed(2) + ""} fill={'#555'} position="center" />
                  </Pie>
                </PieChart>
              </div>
            )}
        </Col>
      </Row>
      <br/>
      <h3>Consumption by Date Span <Icon type="calendar"></Icon></h3>
      <Row type="flex" align="middle">
        <Col xs={8}>
          {loading ? (
            <CenteredRow typeClass="screen" >
              <span className={"icon-animated"}>
                <Icon
                  type="loading"
                  style={{ color: "#287d8d", fontSize: "50px" }}
                />
              </span>
            </CenteredRow>
          ) : (

              <div style={{ padding: 'auto' }}>
                <h4 style={{ marginLeft: "50px" }}>Off Peak</h4>
                <PieChart width={150} height={150}>
                  <Pie
                    data={offPeakConsumptionData}
                    dataKey="value"
                    cx={70}
                    cy={70}
                    innerRadius={50}
                    outerRadius={65}
                    startAngle={90}
                    endAngle={450}
                    fill="#287d8e"
                    labelLine={false}
                  >
                    {
                      offPeakConsumptionData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                    }
                    <Label className="label-center-piechart" value={Number(offPeakConsumption).toFixed(2) + ""} fill={'#555'} position="center" />
                  </Pie>
                </PieChart>
                <h4><span className="peak-cost">$ {Number(off_peak_cost).toFixed(2)}</span></h4>
              </div>
            )}
        </Col>
        <Col xs={8}>
          {loading ? (
            <CenteredRow typeClass="screen" >
              <span className={"icon-animated"}>
                <Icon
                  type="loading"
                  style={{ color: "#287d8d", fontSize: "50px" }}
                />
              </span>
            </CenteredRow>
          ) : (
              <div style={{ margin: 'auto' }}>
                <h4 style={{ marginLeft: "50px" }}>Mid Peak </h4>
                <PieChart width={150} height={150}>
                  <Pie
                    data={midPeakConsumptionData}
                    dataKey="value"
                    cx={70}
                    cy={70}
                    innerRadius={50}
                    outerRadius={65}
                    startAngle={90}
                    endAngle={450}
                    fill="#287d8e"
                    labelLine={false}
                  >
                    {
                      midPeakConsumptionData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                    }
                    <Label className="label-center-piechart" value={Number(midPeakConsumption).toFixed(2) + ""} fill={'#555'} position="center" />
                  </Pie>
                </PieChart>
                <h4><span className="peak-cost">$ {Number(mid_peak_cost).toFixed(2)}</span></h4>
              </div>
            )}
        </Col>
        <Col xs={8}>
          {loading ? (
            <CenteredRow typeClass="screen" >
              <span className={"icon-animated"}>
                <Icon
                  type="loading"
                  style={{ color: "#287d8d", fontSize: "50px" }}
                />
              </span>
            </CenteredRow>
          ) : (

              <div style={{ margin: 'auto' }}>
                <h4 style={{ marginLeft: "50px" }}>On Peak </h4>
                <PieChart width={150} height={150}>
                  <Pie
                    data={onPeakConsumptionData}
                    dataKey="value"
                    cx={70}
                    cy={70}
                    innerRadius={50}
                    outerRadius={65}
                    startAngle={90}
                    endAngle={450}
                    fill="#287d8e"
                    labelLine={false}
                  >
                    {
                      onPeakConsumptionData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                    }
                    <Label className="label-center-piechart" value={Number(onPeakConsumption).toFixed(2) + ""} fill={'#555'} position="center" />
                  </Pie>
                </PieChart>
                <h4 ><span className="peak-cost">$ {Number(on_peak_cost).toFixed(2)}</span></h4>
              </div>
            )}
        </Col>
      </Row>
    </>

  )
}

export default withRouter(ElementDetailEnergyAnalysisOverviewTab)
