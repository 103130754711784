import React, { useState } from "react";
import { UserContext } from "./context";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./containers/LogIn";
import Login2faSecondStepForm from "./components/Login2faSecondStepForm"
import Dashboard from "./containers/Dashboard";
import UserForgotPasswordForm from "./components/UserForgotPasswordForm"
import UserResetPasswordForm from "./components/UserResetPasswordForm"
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./components/NotFound";
import CacheBuster from "./components/CacheBuster"
import { useSelector, useDispatch } from 'react-redux'

const Routes = () => {
  const [userData, setUserData] = useState(null)

  return (
    <>
      <CacheBuster />
      <UserContext.Provider value={{ userData, setUserData }}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot_password" component={UserForgotPasswordForm} />
          <Route exact path="/reset_password" component={UserResetPasswordForm} />
          <Route exact path="/2fa_second_step" component={Login2faSecondStepForm} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route exact path="/404" component={NotFound} />
          <Route render={() => <Redirect to="/404" />} />
        </Switch>
      </UserContext.Provider>
    </>

  )
}

export default Routes
