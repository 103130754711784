import {
    all,
    call,
    put,
    takeLatest,
    takeLeading
} from 'redux-saga/effects'
import * as actions from '../constants'
import * as roles from '../constants/roles'
import * as error_codes from '../constants/error_codes'
import {
    successFetchUsersData,
    successRemoveUser,
    successCreateUser,
    successEditUser,
    successFetchUser,
    successResetUserPassword,
    errorFetchUsersData,
    errorRemoveUser,
    errorCreateEditUser,
    errorFetchUser,
} from '../actions/userManagmentActions'
import {
    userSuccessFetchCompaniesList,
    userSuccessFetchCompanyBuildings,
    userSuccessFetchBuildingGroups,
    userSuccessFetchGroupChildren,
    setIsUserCreatedUpdated
} from '../actions/userManagmentActions'
import {
    userFetchCompanyBuildings,
    userFetchBuildingGroups,
    userFetchGroupChildren,
} from '../actions/userManagmentActions'
import {
    getUsersListApi,
    getRolesListApi,
    deleteUserApi,
    getUserDetailsApi,
    getUserCompaniesApi,
    getUserBuildingsApi,
    getUserGroupsApi,
    getCompaniesListApi,
    getCompanyBuildingsApi,
    getBuildingGroupsApi,
    getGroupChildrenApi,
    createUserApi,
    updateUserApi,
    resetUserPasswordApi
} from '../api/userManagmentApi'
import {
    toogleUsersLoader,
    toogleUserEditionLoader
} from '../actions/loaderActions'

export function* getUsersData(action) {
    try {
        yield put(toogleUsersLoader(true))

        const result = yield all([
            call(getUsersListApi, action.payload.tenant_uuid),
            call(getRolesListApi)
        ])

        yield put(successFetchUsersData(result[0], result[1]))
        yield put(toogleUsersLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleUsersLoader(false))
        yield put(errorFetchUsersData(error))
    }
}

export function* removeUser(action) {
    try {
        //yield put(toogleUsersLoader(true))

        const user_uuid = action.payload.user_uuid

        yield call(deleteUserApi,
            action.payload.tenant_uuid,
            user_uuid
        )

        yield put(successRemoveUser(user_uuid))
        //yield put(toogleUsersLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "User was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        //yield put(toogleUsersLoader(false))
        yield put(errorRemoveUser(error))
    }
}

export function* getUserDetails(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const result = yield all([
            call(getUserDetailsApi,
                action.payload.tenant_uuid,
                action.payload.user_uuid
            ),
            call(getUserCompaniesApi,
                action.payload.tenant_uuid,
                action.payload.user_uuid
            ),
            call(getUserBuildingsApi,
                action.payload.tenant_uuid,
                action.payload.user_uuid
            ),
            call(getUserGroupsApi,
                action.payload.tenant_uuid,
                action.payload.user_uuid
            )
        ])

        const managerRole = action.payload.managerRole
        const fetchUserRole = result[0].roles[0].name
        let usersCurrentBuildings = null

        if (managerRole === roles.USER_ROLE_SUPERADMIN) {
            if (fetchUserRole === roles.USER_ROLE_BUILDINGADMIN) {
                if (result[2] && result[2].length > 0) {
                    result[1] = [{ uuid: result[2][0].company_uuid }]
                    yield put(userFetchCompanyBuildings(action.payload.tenant_uuid, result[2][0].company_uuid))
                }
            } else if (fetchUserRole === roles.USER_ROLE_OFFICEADMIN || fetchUserRole === roles.USER_ROLE_USER) {
                if (result[3] && result[3].length > 0) {
                    result[1] = [{ uuid: result[3][0].company_uuid }]
                    yield put(userFetchCompanyBuildings(action.payload.tenant_uuid, result[3][0].company_uuid))
                    usersCurrentBuildings = [... new Set(result[3].map(group => group.parent_building_uuid))].map(building_uuid => { return { uuid: building_uuid } })
                    result[2] = usersCurrentBuildings
                    yield put(userFetchBuildingGroups(action.payload.tenant_uuid, usersCurrentBuildings.map(building => {
                        return building.uuid
                    }), fetchUserRole, false))
                }
            }
        } else if (managerRole === roles.USER_ROLE_COMPANYADMIN) {
            yield put(userFetchCompanyBuildings(action.payload.tenant_uuid, action.payload.managerCompany))
            if (fetchUserRole === roles.USER_ROLE_OFFICEADMIN || fetchUserRole === roles.USER_ROLE_USER) {
                if (result[3] && result[3].length > 0) {
                    usersCurrentBuildings = [... new Set(result[3].map(group => group.parent_building_uuid))].map(building_uuid => { return { uuid: building_uuid } })
                    result[2] = usersCurrentBuildings
                    yield put(userFetchBuildingGroups(action.payload.tenant_uuid, usersCurrentBuildings.map(building => {
                        return building.uuid
                    }), fetchUserRole, false))
                }
            }
        } else if (managerRole === roles.USER_ROLE_BUILDINGADMIN) {
            yield put(userFetchBuildingGroups(action.payload.tenant_uuid, action.payload.managerBuildings, fetchUserRole))
            result[3] = [...result[3].filter(group_data => action.payload.managerBuildings.includes(group_data.parent_building_uuid))]
        } else if (managerRole === roles.USER_ROLE_OFFICEADMIN) {
            yield put(userFetchGroupChildren(action.payload.tenant_uuid, action.payload.managerGroup.uuid))
            result[3] = [...result[3].filter(group_data => action.payload.managerGroup.parent_building_uuid === group_data.parent_building_uuid)]
        }

        yield put(successFetchUser(result[0], result[1], result[2], result[3]))
        yield put(toogleUserEditionLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            }
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "User was deleted",
                "error_code": err.response.data.error_code
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorFetchUser(error))
    }
}

export function* createUser(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const data = yield call(createUserApi, action.payload.tenant_uuid, action.payload.form)

        yield put(successCreateUser(data))
        yield put(toogleUserEditionLoader(false))
        yield put(setIsUserCreatedUpdated(false))
    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorCreateEditUser(error))
    }
}

export function* editUser(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const user_uuid = action.payload.user_uuid

        const data = yield call(updateUserApi,
            action.payload.tenant_uuid,
            user_uuid,
            action.payload.form
        )

        yield put(successEditUser(data))
        yield put(toogleUserEditionLoader(false))
        yield put(setIsUserCreatedUpdated(false))

    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("500")) {
            error = {
                "message": "Server error!",
                "error_code": error_codes.ERR_INTERNAL_SERVER_ERROR
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "User was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorCreateEditUser(error))
    }
}

export function* getCompaniesList(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const data = yield call(getCompaniesListApi,
            action.payload.tenant_uuid
        )

        yield put(userSuccessFetchCompaniesList(data))
        yield put(toogleUserEditionLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorCreateEditUser(error))
    }
}

export function* getCompanyBuildings(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const data = yield call(getCompanyBuildingsApi,
            action.payload.tenant_uuid,
            action.payload.company_uuid
        )

        yield put(userSuccessFetchCompanyBuildings(data, action.payload.company_uuid))
        yield put(toogleUserEditionLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            }
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Company was deleted",
                "error_code": err.response.data.error_code
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorCreateEditUser(error))
    }
}

export function* getBuildingGroups(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const data = yield all(action.payload.buildings_uuids.map(building_uuid => {
            return call(getBuildingGroupsApi, action.payload.tenant_uuid, building_uuid)
        }))

        yield put(userSuccessFetchBuildingGroups(data, action.payload.user_role))
        yield put(toogleUserEditionLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            }
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Building was deleted",
                "error_code": err.response.data.error_code
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorCreateEditUser(error))
    }
}

export function* getGroupChildren(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const data = yield call(getGroupChildrenApi,
            action.payload.tenant_uuid,
            action.payload.group_uuid
        )

        yield put(userSuccessFetchGroupChildren(data))
        yield put(toogleUserEditionLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            }
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "Group was deleted",
                "error_code": err.response.data.error_code
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            };
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorCreateEditUser(error))
    }
}

export function* resetUserPassword(action) {
    try {
        yield put(toogleUserEditionLoader(true))

        const user_uuid = action.payload.user_uuid

        const data = yield call(resetUserPasswordApi,
            action.payload.tenant_uuid,
            user_uuid,
        )

        yield put(successResetUserPassword(data))
        yield put(toogleUserEditionLoader(false))
        //yield put(setIsUserCreatedUpdated(false))

    } catch (err) {
        console.log(err)
        let error = {}
        if (err.message.includes("401")) {
            error = {
                "message": "You are not authorized to perform this action",
                "error_code": error_codes.ERR_INVALID_TOKEN
            };
        } else if (err.message.includes("403")) {
            error = {
                "message": "You have not permissions to perform this action",
                "error_code": error_codes.ERR_PERMISSION_REQUIRED
            };
        } else if (err.message.includes("500")) {
            error = {
                "message": "Server error!",
                "error_code": error_codes.ERR_INTERNAL_SERVER_ERROR
            }
        } else if (err.message.includes("Network Error")) {
            error = {
                "message": "Connection is lost!",
                "error_code": error_codes.ERR_LOST_CONNECTION
            }
        } else if (err.message.includes("404")) {
            error = {
                "message": "User was deleted",
                "error_code": err.response.data.error_code
            }
        } else {
            error = err.response.data
        }

        yield put(toogleUserEditionLoader(false))
        yield put(errorCreateEditUser(error))
    }
}

export function* fetchUsersDataWatcher() {
    yield takeLatest(actions.FETCH_USERS_DATA, getUsersData)
}

export function* removeUserWatcher() {
    yield takeLatest(actions.REMOVE_USER, removeUser)
}

export function* fetchUserDetailWatcher() {
    yield takeLatest(actions.FETCH_USER, getUserDetails)
}

export function* fetchUserCompaniesWatcher() {
    yield takeLatest(actions.USER_FETCH_COMPANIES_LIST, getCompaniesList)
}

export function* fetchUserCompanyBuildingsWatcher() {
    yield takeLatest(actions.USER_FETCH_COMPANY_BUILDINGS, getCompanyBuildings)
}

export function* fetchUserBuildingGroupsWatcher() {
    yield takeLatest(actions.USER_FETCH_BUILDING_GROUPS, getBuildingGroups)
}

export function* fetchUserGroupChildrenWatcher() {
    yield takeLatest(actions.USER_FETCH_GROUP_CHILDREN, getGroupChildren)
}

export function* createUserWatcher() {
    yield takeLatest(actions.CREATE_USER, createUser)
}

export function* updateUserWatcher() {
    yield takeLatest(actions.EDIT_USER, editUser)
}

export function* resetUserPasswordWatcher() {
    yield takeLatest(actions.RESET_USER_PASSWORD, resetUserPassword)
}