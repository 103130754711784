import {
   getAllUsersService,
   getAllRolesService,
   deleteUsersService,
   getUserDetailsService,
   getUserGroupsService,
   getUserCompaniesService,
   getUserBuildingsService,
   getAllCompaniesService,
   getCompanyDetailsService,
   getBuildingDetailsService,
   getGroupDetailsService,
   postUsersService,
   putUsersTransactionalService,
   putUserResetPasswordService,
} from "../services"

export const getUsersListApi = async (tenant_uuid) => {
    const response = await getAllUsersService(tenant_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getRolesListApi = async () => {
    const response = await getAllRolesService()
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getUserDetailsApi = async (tenant_uuid, user_uuid) => {
    const response = await getUserDetailsService(tenant_uuid, user_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getUserGroupsApi = async (tenant_uuid, user_uuid) => {
    const response = await getUserGroupsService(tenant_uuid, user_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getUserCompaniesApi = async (tenant_uuid, user_uuid) => {
    const response = await getUserCompaniesService(tenant_uuid, user_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getUserBuildingsApi = async (tenant_uuid, user_uuid) => {
    const response = await getUserBuildingsService(tenant_uuid, user_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const deleteUserApi = async (tenant_uuid, user_uuid) => {
    const response = await deleteUsersService(tenant_uuid, user_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getCompaniesListApi = async (tenant_uuid) => {
    const response = await getAllCompaniesService(tenant_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getCompanyBuildingsApi = async (tenant_uuid, company_uuid) => {
    const response = await getCompanyDetailsService(tenant_uuid, company_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getBuildingGroupsApi = async (tenant_uuid, building_uuid) => {
    const response = await getBuildingDetailsService(tenant_uuid, building_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const getGroupChildrenApi = async (tenant_uuid, group_uuid) => {
    const response = await getGroupDetailsService(tenant_uuid, group_uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const createUserApi = async (tenant_uuid, form) => {
    const response = await postUsersService(tenant_uuid, form)
    if (response && response.status === 201) {
        let data = await response.data;
        return data
    }
}

export const updateUserApi = async (tenant_uuid, uuid, form) => {
    const response = await putUsersTransactionalService(tenant_uuid, uuid, form)
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}

export const resetUserPasswordApi = async (tenant_uuid, user_uuid) => {
    const response = await putUserResetPasswordService(tenant_uuid, user_uuid)
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}