import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchLatestData = (tenant_uuid, uuid, sensor_type ) => action(actions.FETCH_LATEST_DATA, {tenant_uuid, uuid, sensor_type})

export const successFetchLatestData = data => action(actions.SUCCESS_FETCH_LATEST_DATA, { data })

export const filterSensorData = ids => action(actions.FILTER_SENSOR_DATA, { ids })

export const errorFetchLatestData = error => action(actions.ERROR_FETCH_LATEST_DATA, { error })

export const cleanLatestDataError = () => action(actions.CLEAN_LATEST_DATA_ERROR)

export const cleanLatestDataState = () => action(actions.CLEAN_LATEST_DATA_STATE)