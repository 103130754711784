import React from "react";
import { Row, Col } from "antd";
import moment from 'moment';
import './styles.css';

const NotificationRow = ({
  message_type,
  message,
  date,
  notification_state
}) => {

  const getTime = (param_date) => {
    const strdate = param_date.replace("T", " ");
    
    const current_date = moment.utc().local();
    
    //convert local
    const get_date =  moment.utc(strdate).local();
    const local = get_date.format("YYYY-MM-DD HH:mm:ss");

    const diff = current_date.diff(get_date, 'days');
    
    let strdiff = "";
    if (diff >= 0 && diff < 25) {
      strdiff = moment(local, "YYYY-MM-DD HH:mm:ss").fromNow();
    } else if (diff >= 25) {
      strdiff = moment(local, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
    }else{
      strdiff = moment(local, "YYYY-MM-DD HH:mm:ss").from();
    }
    //const strdiff = moment(strdate, "YYYYMMDD").fromNow();
    return strdiff;
  }

  return (
    <Row className="toast-item">
      <Col span={4}><span style={{ fontWeight: "600" }}>{message_type}</span></Col>
      <Col span={16} className="one-line">{message}</Col>
      <Col span={4} style={{ textAlign: "right" }}>{getTime(date)}</Col>
    </Row>
  );
};

export default NotificationRow;
