import {
    getGroupPeopleAnalyticsParametersService,
    putGroupPeopleAnalyticsParametersService
} from "../services";

export const fetchPeopleAnalyticsApi = async (tenant_uuid, uuid) => {
    let fetch_function = () => { }
    fetch_function = getGroupPeopleAnalyticsParametersService

    const response = await fetch_function(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updatePeopleAnalyticsApi = async (tenant_uuid, uuid, form) => {
    let update_function = () => { }
    update_function = putGroupPeopleAnalyticsParametersService

    const response = await update_function(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}