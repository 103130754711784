import React from "react";
import { Icon } from "antd";
import "./style.css";

const StyledIcon = ({ type, color, scope, handleClick }) => {
  let size;
  switch (scope) {
    case "navbar":
      size = 22;
      break;
    case "action":
      size = 20;
      break;
    case "loader":
      size = 50;
      break;
    default:
      size = 16;
      break;
  }

  return (
    <span className={scope !== "login" ? "icon-animated" : ""}>
      <Icon
        type={type}
        style={{ color, fontSize: size }}
        onClick={handleClick ? handleClick : () => {}}
      />
    </span>
  );
};

export default StyledIcon;
