import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchEnergyAnalysisSettings = (tenant_uuid, uuid, group_type ) => action(actions.FETCH_ENERGY_ANALYSIS_SETTINGS, {tenant_uuid, uuid, group_type})

export const updateFetchEnergyAnalysisSettings = (tenant_uuid, uuid, form, group_type ) => action(actions.UPDATE_ENERGY_ANALYSIS_SETTINGS, {tenant_uuid, uuid, form, group_type})

export const successFetchUpdateEnergyAnalysisSettings = data => action(actions.SUCCESS_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS, { data })

export const errorFetchUpdateEnergyAnalysisSettings = error => action(actions.ERROR_FETCH_UPDATE_ENERGY_ANALYSIS_SETTINGS, { error })

export const cleanEnergyAnalysisSettingsError = () => action(actions.CLEAN_ENERGY_ANALYSIS_SETTINGS_ERROR)

export const cleanEnergyAnalysisSettingslState = () => action(actions.CLEAN_ENERGY_ANALYSIS_SETTINGS_STATE)

export const fetchEnergyConsumptionOverviewData = (tenant_uuid, uuid, group_type ) => action(actions.FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA, {tenant_uuid, uuid, group_type})

export const successFetchEnergyConsumptionOverviewData = data => action(actions.SUCCESS_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA, { data })

export const errorFetchEnergyConsumptionOverviewData = error => action(actions.ERROR_FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA, { error })

export const cleanEnergyConsumptionOverviewDataError = () => action(actions.CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_ERROR)

export const cleanEnergyConsumptionOverviewDataState = () => action(actions.CLEAN_ENERGY_CONSUMPTION_OVERVIEW_DATA_STATE)

export const fetchChartEnergyData = (tenant_uuid, uuid, request_time, step_time ) => action(actions.FETCH_CHART_DATA, {tenant_uuid, uuid, request_time, step_time})

export const successFetchChartEnergyData = (data, requestTime, stepTime, start, end, startLocal, endLocal, currentTime) => action(actions.SUCCESS_FETCH_CHART_ENERGY_DATA, { data, requestTime, stepTime, start, end, startLocal, endLocal, currentTime })

export const errorFetchChartEnergyData = error => action(actions.ERROR_FETCH_CHART_ENERGY_DATA, { error })

export const cleanChartEnergyDataError = () => action(actions.CLEAN_CHART_ENERGY_DATA_ERROR)

export const cleanChartEnergyDataState = () => action(actions.CLEAN_CHART_ENERGY_DATA_STATE)