import {
    getGroupHistoricSensorDataService,
} from "../services";

export const fetchHistoricDataApi = async (tenant_uuid, uuid, sensor_type, start_date, end_date) => {

    const response = await getGroupHistoricSensorDataService(tenant_uuid, uuid, sensor_type, start_date, end_date);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}