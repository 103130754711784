import {
   postCompanyService,
   postBuildingsService,
   postGroupsService,
   putCompanyService,
   putBuildingService,
   putGroupService,
   putCompanyContactService,
   putBuildingContactService,
   putGroupContactService,
   putCompanyModulesService
} from "../services"

export const createElementApi = async (tenant_uuid, form, group_type) => {
    let postFunction = () => { }
    switch (group_type) {
        case "Building":
            postFunction = postBuildingsService;
            break;
        case "Logical":
            postFunction = postGroupsService;
            break;
        case "Control":
            postFunction = postGroupsService;
            break;
        case "Mix":
            postFunction = postGroupsService;
            break;
        case "Company":
            postFunction = postCompanyService;
            break;
        default:
            postFunction = undefined;
    }
    
    const response = await postFunction(tenant_uuid, form, group_type);
    if (response && response.status === 201) {
        let data = await response.data;
        data.group_type = group_type
        return data
    }
}

export const editElementApi = async (tenant_uuid, uuid, form, group_type) => {
    let postFunction = () => { }
    switch (group_type) {
        case "Building":
            postFunction = putBuildingService;
            break;
        case "Logical":
            postFunction = putGroupService;
            break;
        case "Control":
            postFunction = putGroupService;
            break;
        case "Mix":
            postFunction = putGroupService;
            break;
        case "Company":
            postFunction = putCompanyService;
            break;
        default:
            postFunction = undefined;
    }
    
    const response = await postFunction(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        data.group_type = group_type
        return data
    }
}

export const editElementContactApi = async (tenant_uuid, uuid, form, group_type) => {
    let postFunction = () => { }
    switch (group_type) {
        case "Building":
            postFunction = putBuildingContactService;
            break;
        case "Logical":
            postFunction = putGroupContactService;
            break;
        case "Control":
            postFunction = putGroupContactService;
            break;
        case "Mix":
            postFunction = putGroupContactService;
            break;
        case "Company":
            postFunction = putCompanyContactService;
            break;
        default:
            postFunction = undefined;
    }
    
    const response = await postFunction(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}

export const editCompanyModulesApi = async (tenant_uuid, uuid, form) => {
    const response = await putCompanyModulesService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}