import {
    getGroupOccupancySwitchService,
    putGroupOccupancySwitchService,
} from "../services";


export const fetchOccupancySwitchApi = async (tenant_uuid, uuid) => {

    const response = await getGroupOccupancySwitchService(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateOccupancySwitchApi = async (tenant_uuid, uuid, form) => {

    const response = await putGroupOccupancySwitchService(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}