import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchDevices = (tenant_uuid, uuid ) => action(actions.FETCH_DEVICES, {tenant_uuid, uuid})

export const addDevices = (tenant_uuid, uuid, controllers) => action(actions.ADD_DEVICES, {tenant_uuid, uuid, controllers})

export const removeDevice = (tenant_uuid, aws_id) => action(actions.REMOVE_DEVICE, {tenant_uuid, aws_id})

export const successFetchDevices = data => action(actions.SUCCESS_FETCH_DEVICES, { data })

export const successAddDevices = data => action(actions.SUCCESS_ADD_DEVICES, { data })

export const successRemoveDevice = aws_id => action(actions.SUCCESS_REMOVE_DEVICE, { aws_id })

export const errorFetchUpdateDevices = error => action(actions.ERROR_FETCH_UPDATE_DEVICES, { error })

export const cleanDevicesError = () => action(actions.CLEAN_DEVICES_ERROR)

export const cleanDevicesState = () => action(actions.CLEAN_DEVICES_STATE)