import * as actions from '../constants';

const initialState = {
  lightsData: null,
  error: null,
}

const lightsControl = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_LIGHTS_LEVELS_ON:
      return Object.assign({}, state, {
        lightsData: action.payload.data,
        error: null
      })
    case actions.ERROR_FETCH_UPDATE_LIGHTS_LEVELS_ON:
      return Object.assign({}, state, {
        lightsData: null,
        error: action.payload.error
      })
    case actions.CLEAN_LIGHTS_CONTROL_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
    case actions.CLEAN_LIGHTS_CONTROL_STATE:
      return Object.assign({}, state, {
        error: null,
        lightsData: null,
      })
    default:
      return state
  }
}

export default lightsControl