import * as actions from '../constants';
import { getGroupLabel } from '../utils/helpers';

const initialState = {
  companyTreeData: [],
  defaultExpandedUuid: "",
  modalType: "",
  clickPosition: [],
  currentElement: null,
  deletedElement: false,
  deletedElementType: "",
  modules: null,
  areasTypes: null,
  error: null,
}

const recursiveRender = (array, pos, element, action) => {
  if (pos.length === 1) {
    if (action === "add")
      array[pos[0]].children = array[pos[0]].children
        ? [...array[pos[0]].children, element]
        : [element];

    if (action === "del") array.splice(pos[0], 1);

    if (action === "edit" || action === "settings") {
      element.children = array[pos[0]].children
      array.splice(pos[0], 1, element)
      //setElementDetails(element);
    }

    return array;
  }
  return recursiveRender(
    array[pos[0]].children,
    pos.slice(1),
    element,
    action
  )
}

const companyManagment = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_COMPANY_TREE_DATA:
      let treeData = []
      let synopsisInfo = null
      let defaultExpandedUuid = ""

      if (action.payload.synopsisData) {
        synopsisInfo = action.payload.synopsisData[0]
        synopsisInfo.autoSelected = true
        defaultExpandedUuid = synopsisInfo.uuid
        if (action.payload.synopsisData.length === 1) {
          synopsisInfo.children = action.payload.data
        } else {
          synopsisInfo.children = [action.payload.synopsisData[1]]
          defaultExpandedUuid = action.payload.synopsisData[1].uuid
          synopsisInfo.children[0].autoSelected = true
          synopsisInfo.children[0].children = action.payload.data
        }
        treeData = [].concat([synopsisInfo])
      } else {
        treeData = [].concat(action.payload.data)
      }

      return Object.assign({}, state, {
        companyTreeData: treeData,
        defaultExpandedUuid,
        modules: action.payload.modulesData,
        areasTypes: action.payload.areaTypes,
        error: null
      })

    case actions.HANDLE_TREE_DATA:
      const handleTreeData = [...state.companyTreeData]
      const clickPos = state.clickPosition
      const modal_action = action.payload.modal_action
      const currentElement = modal_action !== "add" ? Object.assign({}, state.currentElement, action.payload.element) : state.currentElement
      const handleTreeElement = modal_action !== "add" ? Object.assign({}, state.currentElement, action.payload.element) : action.payload.element
      if (state.modalType === "Company") {
        if (modal_action === "add") {
          handleTreeData.push(handleTreeElement);
        } else {
          recursiveRender(handleTreeData, clickPos, handleTreeElement, modal_action);
        }
      } else {
        recursiveRender(handleTreeData, clickPos, handleTreeElement, modal_action);
      }

      return Object.assign({}, state, {
        companyTreeData: handleTreeData,
        currentElement: modal_action === "del" ? null : currentElement,
        deletedElement: modal_action === "del" ? true : false,
        deletedElementType: modal_action === "del" ? `${currentElement.group_type} ${getGroupLabel(currentElement.group_type)}` : "",
        error: null
      })

    case actions.HANDLE_TREE_DATA_POSITION:
      const handleTreeDataPosition = [...state.companyTreeData]
      const modal_action_position = action.payload.modal_action
      let clickPosition = action.payload.drag_position
      if (modal_action_position === "add") {
        clickPosition = action.payload.drop_position
      }
      const handleTreeElementPosition = action.payload.element

      recursiveRender(handleTreeDataPosition, clickPosition, handleTreeElementPosition, modal_action_position);

      return Object.assign({}, state, {
        companyTreeData: handleTreeDataPosition,
        currentElementPosition: null, //modal_action_position === "del" ? null : clickPosition,
        //deletedElement: modal_action_position === "del" ? true : false,
        //deletedElementType: modal_action_position === "del" ? `${currentElementPosition.group_type} ${getGroupLabel(currentElementPosition.group_type)}` : "",
        error: null
      })

    case actions.SUCCESS_FETCH_ELEMENT:
      return Object.assign({}, state, {
        currentElement: action.payload.data, error: null
      })

    case actions.SET_MODAL_TYPE:
      return Object.assign({}, state, {
        modalType: action.payload.type
      })

    case actions.SET_CLICK_POSITION:
      return Object.assign({}, state, {
        clickPosition: [...action.payload.position]
      })

    case actions.ERROR_FETCH_ELEMENT:
      return Object.assign({}, state, {
        currentElement: {},
        error: action.payload.error
      })

    case actions.RESET_DELETE_COMPANY_ELEMENT_POPUP:
      return Object.assign({}, state, {
        deletedElement: false,
      })

    case actions.UPDATE_CURRENT_ELEMENT_DATA:
      const newCurrentElementData = Object.assign({}, state.currentElement, action.payload.data)

      return Object.assign({}, state, {
        currentElement: newCurrentElementData,
      })

    case actions.CLEAN_COMPANY_MANAGMENT_STATES:
      return Object.assign({}, state, {
        companyTreeData: [],
        defaultExpandedUuid: "",
        modalType: "",
        clickPosition: [],
        currentElement: null,
        deletedElement: false,
        modules: null,
        areasTypes: null,
        error: null,
      })
    default:
      return state
  }
}

export default companyManagment