import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  List,
  Row,
  Col,
  Icon,
  Tooltip,
  Button,
  Slider,
  message,
  Notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import Map from "../HeatMap";
import { UserContext } from "../../context";
import { getDataLastHour, getLeyend } from "./heatmapfunction";
import { existGroup } from "../UserDashboard/dashboardfunction";
import * as actions from '../../constants'
import StyledIcon from "../StyledIcon";
import colors from "../../styles";
import moment from 'moment';
import { symbol, getTitle, isModuleNotificatoinActive } from '../../utils/helpers';

import "./style.css";
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const { primary_color_hover } = colors;

const { HEATMAP_RADIUS, HEATMAP_RANGE, HEATMAP_COLOR } = window.Configs;

const DevicesHeatMapPanel = ({
  history,
  index,
  editabled,
  parentdata,
  parentconfig,
  updateParentConfig,
  updateParentData,
  changeLoading,
  selectKey
  //tabKey
}) => {
  const [thedata, setThedata] = useState(
    parentdata
    /*
    { image: "", imagedisplay: "horizontal", devices: [], data: [], chartData: [] }
    */
  );
  const [theconfig, setTheconfig] = useState(
    parentconfig
    /*
    { element_uuid: parentconfig.element_uuid, shouldRenderFrecuencyMap: false, sensorName: 'temperature', day: moment().format("YYYY-MM-DD"), hour: Number(moment().format("HH")), }
    */
  );

  const [bubble, setBubble] = useState((theconfig && theconfig.bubble && theconfig.bubble > 0 ? theconfig.bubble : HEATMAP_RADIUS));

  let thehistory = useHistory();
  const [sensor, setSensor] = useState(theconfig.sensorName);

  const [dataheatmap, setDataheatmap] = useState(thedata.data);
  const [dataLoading, setDataLoading] = useState(false);

  const [lastUpdate, setLastUpdate] = useState(moment().format('MMM DD YYYY HH:mm'));

  const { userData: { tenant_uuid } } = useContext(UserContext);

  const user_modules = useSelector(state => state.rootReducer.auth.modules)

  const dispatch = useDispatch()

  useEffect(() => {
    if (thedata.update === true) {
      //setUpdatedata(false)
      setDataheatmap([])
    }
  },
    [thedata.update])

  useEffect(() => {
    if (thedata.update === true) {
      //setUpdatedata(true)
      setDataheatmap(thedata.data)
      if (dataheatmap.length > 0) {
        setThedata({
          ...thedata,
          update: false,
        })
      }
    }
  },
    [dataheatmap])

  useEffect(() => {
    //console.log(thedata.image)
  },
    [thedata.image])

  const updateImageDisplay = (e) => {
    if (thedata.image !== "") {
      //const imageshow = document.getElementById('image-map-show')
      //if (imageshow) {
      if (Number(e.target.width) >= Number(e.target.height)) {
        setThedata({ ...thedata, imageDisplay: "horizontal" });
      } else {
        setThedata({ ...thedata, imageDisplay: "vertical" });
      }
      changeLoading(false);
      //}
    }
  }

  const reloadData = async () => {
    //if (thedata.data.length > 0) {
    setDataLoading(true)
    const newchartdata = await getDataLastHour(history, dispatch, tenant_uuid, theconfig, thedata.devices)
    updateParentData(index, { ...thedata, data: newchartdata })
    setLastUpdate(moment().format('MMM DD YYYY HH:mm'));
    setDataLoading(false)

    await setThedata({
      ...thedata,
      update: true,
      data: newchartdata
    });
  }


  /*
  *put in state value for redirect
  *put active item menu sider in 1 === company
  *redirect to route /dashboard/company
  *
  * when load CompanyManager componet if state redirect:
  * load group data
  * expanded tree to group node
  * put group node tree how if active
  * 
  * when load ElementDetail component:
  * put active tab device
  * 
  * when click in other node tree or CompanyManager is didmount put state value for redirect empty
  * 
  */
  const goTo = async (uuid_group) => {
    const exist_group = await existGroup(history, dispatch, tenant_uuid, uuid_group);
    if (exist_group) {

      selectKey("1");
      //thehistory.push(`/dashboard/company/${id_group}`);
      dispatch({ type: actions.REDIRECT_HEATMAP, payload: { tenant_uuid: tenant_uuid, uuid: uuid_group, group_type: "Control", loading: "ready" } })
      thehistory.push(`/dashboard/company`);
      return;
    } else {
      //message.error("You have not permissions to perform this action");
      Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
    }
  }

  const render = () => {
    let imageMap = true;
    if (thedata.image === "") {
      imageMap = false;
    }

    const topInfo = () => {
      let textInfo = "";
      if (theconfig.lasthour && parseFloat(theconfig.lasthour) === 0.5) {
        textInfo = textInfo + "last 30 minutes";
      }
      if (theconfig.lasthour && parseFloat(theconfig.lasthour) === 1) {
        textInfo = textInfo + "last hour";
      }
      if (theconfig.lasthour && parseFloat(theconfig.lasthour) > 1) {
        textInfo = textInfo + "last " + theconfig.lasthour + " hours";
      }

      return (textInfo);

    }

    const onRadiusChange = param => {
      if (typeof param === "number") {
        setBubble(param);
        const newtheconfig = {
          ...theconfig,
          //uuid: uuid_heatmap,
          bubble: param
        }
        updateParentConfig(index, newtheconfig);
      }
    };


    //lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 20 }} 
    const content = (
      <Row>
        {imageMap && (

          <Col span={24} style={{ textAlign: "right" }}>
            {dataLoading ? (
              <strong>...loading</strong>
            ) : (
                <strong>Updated {lastUpdate}</strong>
              )}
            <Button
              type="primary"
              icon="reload"
              loading={dataLoading}
              style={{ marginLeft: "5px", transform: "scale(0.8)" }}
              onClick={() => {
                reloadData()
              }}>
            </Button>
          </Col>
        )}

        <Col span={19} className="img-container" style={{ width: (editabled ? "" : "100%") }}>
          {imageMap && (
            <>
              <Row className={"contenedor"}>
                {thedata.devices && thedata.devices.map((device, indexd) => (
                  <Tooltip key={`device-tooltip-${device.id}`} title={
                    <div style={{ display: "inline-grid", textAlign: "center" }}>
                      <span>{device.aws_id}</span>
                      <span>{getTitle(theconfig.sensorName)} = {(dataheatmap && dataheatmap[indexd] && dataheatmap[indexd][3] && (dataheatmap[indexd][3] !== "0" && dataheatmap[indexd][3] !== 0) ? (parseFloat(dataheatmap[indexd][3])).toFixed(2) + "" : "0.00")} {symbol(theconfig.sensorName)}</span>
                    </div>
                  }>

                    <div
                      className={`device device-${device.id} ${(dataheatmap && dataheatmap[indexd] && dataheatmap[indexd][2] && (dataheatmap[indexd][2] === "-1" || dataheatmap[indexd][2] === -1) ? "inactive" : "")}`}
                      style={{
                        position: "absolute",
                        zIndex: device.x > 0 ? 1 : 0,
                        //opacity: device.x > 0 ? 1 : 0,
                        left: device.x,
                        top: device.y,
                      }}
                    >
                      <Icon type="bulb" />
                      <div className="device-name">
                        <span>{device.aws_id}</span>
                      </div>
                    </div>
                  </Tooltip>
                ))}
                <img id="image-map-show"
                  className={thedata.imageDisplay}
                  //src={image}
                  src={`data:image/jpeg;base64,${thedata.image}`}
                  max-width="500px"
                  max-height="500px"
                  onLoad={updateImageDisplay}
                  alt="" />
                {(thedata.data && thedata.data.length > 0 && !thedata.update) && (<Map datadevice={dataheatmap} renderMap={true} radius={bubble}  heatmap_color={HEATMAP_COLOR} />)}
                {isModuleNotificatoinActive(user_modules, "fireDetection") && thedata.exits && thedata.exits.length> 0 && thedata.exits.map((exit, index) => (
                    <Tooltip key={`exit-tooltip-${index}`} title={
                      <div style={{ display: "inline-grid", textAlign: "center" }}>
                        <span>{exit.exit_type === "Normal" ? "normal exit" : "fire exit"}
                        </span>
                      </div>
                    }>
                      <div
                        key={`item-exit-${index}`}
                        className={`exit device item-exit-${index}`}
                        style={{
                          position: "absolute",
                          zIndex: exit.x > 0 ? 1 : 0,
                          //opacity: exit.x > 0 ? 1 : 0,
                          left: exit.x,
                          top: exit.y,
                        }}
                      >
                        {exit.exit_type === "Normal" ? <span className="image-name text-green">NX</span> : <span className="text-red image-name">FX</span>}
                        <div className="device-name exit-name">EXIT</div>
                      </div>
                    </Tooltip>

                  ))}
              </Row>
              <Row>
                <div style={{ margin: "10px 0px 10px 0px", textAlign: "center" }}>

                  <div style={{ width: "500px", maxWidth: "500px", margin: "auto" }}>
                    {getLeyend(theconfig.sensorName, HEATMAP_RANGE, HEATMAP_COLOR, [], true, true)}
                  </div>

                </div>
              </Row>

              <Row style={{ textAlign: "center" }}>
                {imageMap && (
                  <>
                    {theconfig.sensorName && thedata.element_name && theconfig.lasthour ? (
                      <Row style={{ maxWidth: "500px", margin: "auto" }}>

                        <Col span={24} style={{padding: "0px 20px"}}>
                          <div style={{ float: "left", textAlign: "center", marginTop: "-5px" }}>
                            Bubble Size:
                        </div>
                          <div style={{ float: "left", width: "100%", paddingLeft: "80px", paddingRight: "20px", position: "absolute" }}>

                            <Slider
                              min={5}
                              max={2 * HEATMAP_RADIUS}
                              //marks={marksRadius}
                              onAfterChange={onRadiusChange}
                              step={5}
                              defaultValue={bubble}
                              //className={getClassNotActiveStep()}
                              style={{ marginTop: "0px", marginBottom: "10px", marginRight: "20px" }}
                            />
                          </div>
                        </Col>
                        <Col span={8}>
                          <strong>group:</strong> {thedata.element_name}
                        </Col>
                        <Col span={8}>
                          <strong>sensor:</strong> {theconfig.sensorName}
                        </Col>
                        <Col span={8}>
                          <strong>data:</strong> {topInfo()}
                        </Col>
                      </Row>
                    ) : (
                        <></>
                      )}
                  </>
                )}
              </Row>

              {dataLoading ? (
                <div className="loader">
                  <StyledIcon type="loading" color={primary_color_hover} scope="loader" />
                </div>

              ) : (
                  <Row style={{ padding: "10px 0px" }}>

                    <Col span={24}>
                      <Row style={{ width: "500px", maxWidth: "500px", margin: "auto" }}>

                        <Col span={24}>
                          <Button
                            type="primary"
                            icon="control"
                            onClick={() => {
                              goTo(theconfig.element_uuid)
                            }}>
                            Heatmap detail
                        </Button>
                        </Col>

                      </Row>
                    </Col>


                  </Row>)}
            </>
          )}
          {!imageMap && (
            <>
              <Row>
                <Col span={24}>
                  No devices map available, please, edit and upload one.
                  </Col>
              </Row>
              <Row style={{ padding: "10px 0px" }}>
                <Col span={24}>
                  <Button
                    type="primary"
                    icon="control"
                    onClick={() => {
                      goTo(theconfig.element_uuid)
                    }}>
                    Heatmap detail
                        </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
        {(editabled ?
          <Col span={5} className="device-container">

            <List
              header={<div>Devices</div>}
              bordered
              dataSource={thedata.devices}
              style={{ maxHeight: "400px", overflow: "scroll" }}
              renderItem={(item, indexd) => <List.Item style={{ display: "block" }}><div>{item.aws_id}</div><div style={{ fontSize: "11px" }}>device {indexd + 1}</div></List.Item>}
            />

          </Col>
          :
          <></>
        )}

      </Row>

    );

    const card = (
      <>{content}</>
    )

    return (
      false //isLoading
        ? <Loader />
        :
        <>
          {card}
        </>
    );
  }

  return (
    render()
  )
}

export default DevicesHeatMapPanel;
