import * as actions from '../constants';

const initialState = {
  notificationDetail: false,
  notificationUserDetail: false,

  notificationData: {
    "all": [],
    "toast": [],
    "mail": [],
    "sms": []
  },
  error: null,
  notificationSendData: {
    "all": [],
    "toast": [],
    "mail": [],
    "sms": []
  },
  errorSend: null,

  notificationSuccesCreate: false,
  errorCreateNotification: null,
}

const notification = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_UPDATE_NOTIFICATION:
      switch (action.payload.data.transport) {
        case "all":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "all": action.payload.data.notification },
            error: null
          })
          
        case "toast":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "toast": action.payload.data.notification },
            error: null
          })
          
        case "mail":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "mail": action.payload.data.notification },
            error: null
          })
          
        case "sms":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "sms": action.payload.data.notification },
            error: null
          })
          
        default:
          return state
      }
      
    case actions.ERROR_FETCH_UPDATE_NOTIFICATION:
      return Object.assign({}, state, {
        notificationData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
        error: action.payload.error
      })
      
    case actions.CLEAN_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        error: null,
      })
      
    case actions.CLEAN_NOTIFICATION_STATE:
      return Object.assign({}, state, {
        error: null,
        notificationData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
      })
      
    //SEND
    case actions.SUCCESS_FETCH_UPDATE_SEND_NOTIFICATION:
      switch (action.payload.data.transport) {
        case "all":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "all": action.payload.data.notification },
            error: null
          })
          
        case "toast":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "toast": action.payload.data.notification },
            error: null
          })
          
        case "mail":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "mail": action.payload.data.notification },
            error: null
          })
          
        case "sms":
          return Object.assign({}, state, {
            notificationSendData: { ...state.notificationSendData, "sms": action.payload.data.notification },
            error: null
          })
          
        default:
          return state
      }
    case actions.ERROR_FETCH_UPDATE_SEND_NOTIFICATION:
      return Object.assign({}, state, {
        notificationSendData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
        errorSend: action.payload.error
      })
      
    case actions.CLEAN_SEND_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        errorSend: null,
      })
      
    case actions.CLEAN_SEND_NOTIFICATION_STATE:
      return Object.assign({}, state, {
        errorSend: null,
        notificationSendData: {
          "all": [],
          "toast": [],
          "mail": [],
          "sms": []
        },
      })
      



    case actions.SUCCESS_CREATE_NOTIFICATION:
      return Object.assign({}, state, {
        notificationSuccesCreate: true,
        errorCreateNotification: null
      })
      
    case actions.ERROR_CREATE_NOTIFICATION:
      return Object.assign({}, state, {
        notificationSuccesCreate: false,
        errorCreateNotification: action.payload.error
      })
      
    case actions.CLEAN_CREATE_NOTIFICATION:
      return Object.assign({}, state, {
        notificationSuccesCreate: false,
        errorCreateNotification: null
      })
      


    case actions.SET_NOTIFICATION_USER_DETAIL:
      return Object.assign({}, state, {
        notificationUserDetail: action.payload
      })
      

    case actions.SET_NOTIFICATION_DETAIL:
      return Object.assign({}, state, {
        notificationDetail: action.payload
      })
      



    default:
      return state
  }
}

export default notification