import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchAdvancedSchedule = (tenant_uuid, uuid ) => action(actions.FETCH_ADVANCED_SCHEDULE, {tenant_uuid, uuid})

export const updateAdvancedSchedule = (tenant_uuid, uuid, form ) => action(actions.UPDATE_ADVANCED_SCHEDULE, {tenant_uuid, uuid, form})

export const successFetchUpdateAdvancedSchedule = data => action(actions.SUCCESS_FETCH_UPDATE_ADVANCED_SCHEDULE, { data })

export const errorFetchUpdateAdvancedSchedule = error => action(actions.ERROR_FETCH_UPDATE_ADVANCED_SCHEDULE, { error })

export const cleanAdvancedScheduleError = () => action(actions.CLEAN_ADVANCED_SCHEDULE_ERROR)

export const cleanAdvancedScheduleState = () => action(actions.CLEAN_ADVANCED_SCHEDULE_STATE)