import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, message, Icon, Notification, Modal, TimePicker, InputNumber, Row, Col, DatePicker } from "antd";
import { withRouter } from "react-router-dom";
import IdleTimer from "react-idle-timer"
import "./styles.css";
import moment from 'moment';

import CenteredRow from "../CenteredRow";
import Loader from "../Loader";
import { UserContext } from "../../context";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'

const FormItem = Form.Item;

const _SettingsEnergyAnalysisForm = ({
    form,
    history,
    isModalVisible,
    tabKey,
}) => {

    const loading = useSelector(state => state.rootReducer.loaders.loadingEnergyAnalysisOverviewSettings)
    const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)
    const energyAnalysisSettingsData = useSelector(state => state.rootReducer.energyAnalysisOverview.energyAnalysisSettingsData)
    const error = useSelector((state) => state.rootReducer.energyAnalysisOverview.error)

    const [saveDisabled, setSaveDisabled] = useState(true);

    const [touchedFields, setTouchedFields] = useState([])
    const idleTimer = useRef(null)
    const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

    const dispatch = useDispatch()

    const {
        userData: { tenant_uuid }
    } = useContext(UserContext);

    const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue } = form

    const handleSubmit = async e => {
        e.preventDefault()
        await validateFields(async (err, values) => {
            if (!err) {
                const { off_peak_start, off_peak_end, mid_peak_start, mid_peak_end, on_peak_start, on_peak_end,
                    off_peak_cost, mid_peak_cost, on_peak_cost, consumption_date_span_start, consumption_date_span_end } = values
                const update_form = {}


                update_form.off_peak_start = off_peak_start
                update_form.off_peak_end = off_peak_end
                update_form.mid_peak_start = mid_peak_start
                update_form.mid_peak_end = mid_peak_end
                update_form.on_peak_start = on_peak_start
                update_form.on_peak_end = on_peak_end
                update_form.consumption_date_span_start = consumption_date_span_start
                update_form.consumption_date_span_end = consumption_date_span_end

                if (touchedFields.includes("off_peak_cost")) {
                    update_form.off_peak_cost = off_peak_cost
                }
                if (touchedFields.includes("mid_peak_cost")) {
                    update_form.mid_peak_cost = mid_peak_cost
                }
                if (touchedFields.includes("on_peak_cost")) {
                    update_form.on_peak_cost = on_peak_cost
                }

                dispatch(({
                    type: actions.UPDATE_ENERGY_ANALYSIS_SETTINGS,
                    payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, form: update_form, group_type: elementData.group_type }
                }))
            } else {
                message.error(err)
            }
        })
    }

    useEffect(
        () => {
            (async () => {
                if (tabKey === "energy_analysis_overview" && !loading && isModalVisible) {
                    resetFields()
                    dispatch(({
                        type: actions.FETCH_ENERGY_ANALYSIS_SETTINGS,
                        payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                    }))
                    setSaveDisabled(true)
                }
            })();
        },
        [tabKey]
    )

    useEffect(
        () => {
            const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
            if (touched_fields.length !== 0) {
                setSaveDisabled(false)
            }
            setTouchedFields(touched_fields)
        },
        [form]
    )

    useEffect(
        () => {
            if (!isModalVisible) {
                resetFields()
                setSaveDisabled(true)
                dispatch(({ type: actions.CLEAN_ENERGY_ANALYSIS_SETTINGS_STATE }))
            } else {
                if (tabKey === "energy_analysis_overview" && !loading) {
                    resetFields()
                    dispatch(({
                        type: actions.FETCH_ENERGY_ANALYSIS_SETTINGS,
                        payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                    }))
                    setSaveDisabled(true)
                }
            }
        },
        [isModalVisible]
    )

    useEffect(
        () => {
            if (energyAnalysisSettingsData) {
                if (energyAnalysisSettingsData.update) {
                    message.success(`Settings for energy analysis overview for ${elementData.group_type === "Building" ? "building" : elementData.group_type + " group"
                        } '${elementData.name}' successfully updated`)
                    resetFields()
                    setSaveDisabled(true)
                }

            }
        },
        [energyAnalysisSettingsData]
    )

    useEffect(
        () => {
            if (error !== null) {
                if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
                    Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
                    history.push("/login");
                    dispatch({ type: actions.LOG_OUT })
                } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
                    Modal.destroyAll()
                    Modal.error({
                        destroyOnClose: true,
                        title: error.message,
                        okText: "Retry",
                        onOk: () => {
                            resetFields()
                            dispatch(({
                                type: actions.FETCH_ENERGY_ANALYSIS_SETTINGS,
                                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
                            }))
                            setSaveDisabled(true)
                        },
                    })
                } else {
                    Notification.error({ message: "Error", "description": error.message, "duration": 0 });
                    dispatch({ type: actions.CLEAN_ENERGY_ANALYSIS_SETTINGS_ERROR })
                }
            }
        },
        [error]
    )

    const onUserIdle = async e => {
        if (isModalVisible && tabKey === "energy_analysis_overview") {
            resetFields()
            dispatch(({
                type: actions.FETCH_ENERGY_ANALYSIS_SETTINGS,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
            }))
            setSaveDisabled(true)
        }
        //idleTimer.reset()
    }

    return <IdleTimer
        ref={idleTimer}
        element={document}
        onIdle={onUserIdle}
        debounce={250}
        timeout={timeout}
    >
        <div>
            <Form onSubmit={handleSubmit}>
                <Row type="flex" align="middle">
                    <Col xs={3} style={{ paddingRight: "10px" }}>
                        <h4>Off Peak Hours: </h4>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="Start" >
                            {getFieldDecorator("off_peak_start", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.off_peak_start ? energyAnalysisSettingsData.off_peak_start : moment("00", "HH")
                            })(
                                <TimePicker
                                    format={"HH"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="End" >
                            {getFieldDecorator("off_peak_end", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.off_peak_end ? energyAnalysisSettingsData.off_peak_end : moment("00", "HH")
                            })(
                                <TimePicker
                                    format={"HH"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="Off Peak Cost">
                            {getFieldDecorator("off_peak_cost", {
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.off_peak_cost ? energyAnalysisSettingsData.off_peak_cost : 0,
                                rules: [
                                    { required: true, message: 'A value is required' },
                                ]
                            })(
                                <InputNumber
                                    disabled={loading}
                                    min={0}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col xs={3} style={{ paddingRight: "10px" }}>
                        <h4>Mid Peak Hours: </h4>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="Start" >
                            {getFieldDecorator("mid_peak_start", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.mid_peak_start ? energyAnalysisSettingsData.mid_peak_start : moment("00", "HH")
                            })(
                                <TimePicker
                                    format={"HH"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="End" >
                            {getFieldDecorator("mid_peak_end", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.mid_peak_end ? energyAnalysisSettingsData.mid_peak_end : moment("00", "HH")
                            })(
                                <TimePicker
                                    format={"HH"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="Mid Peak Cost">
                            {getFieldDecorator("mid_peak_cost", {
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.mid_peak_cost ? energyAnalysisSettingsData.mid_peak_cost : 0,
                                rules: [
                                    { required: true, message: 'A value is required' },
                                ]
                            })(
                                <InputNumber
                                    disabled={loading}
                                    min={0}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col xs={3} style={{ paddingRight: "10px" }}>
                        <h4>On Peak Hours: </h4>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="Start" >
                            {getFieldDecorator("on_peak_start", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.on_peak_start ? energyAnalysisSettingsData.on_peak_start : moment("00", "HH")
                            })(
                                <TimePicker
                                    format={"HH"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="End" >
                            {getFieldDecorator("on_peak_end", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.on_peak_end ? energyAnalysisSettingsData.on_peak_end : moment("00", "HH")
                            })(
                                <TimePicker
                                    format={"HH"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={5}>
                        <FormItem label="On Peak Cost">
                            {getFieldDecorator("on_peak_cost", {
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.on_peak_cost ? energyAnalysisSettingsData.on_peak_cost : 0,
                                rules: [
                                    { required: true, message: 'A value is required' },
                                ]
                            })(
                                <InputNumber
                                    disabled={loading}
                                    min={0}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={24}><div><b>Important!!! Peaks time intervals need to be set on UTC times</b></div></Col>
                </Row>
                <hr/>
                <Row type="flex" align="middle">
                    <Col xs={5} style={{ paddingRight: "10px" }}>
                        <h4>Consumption Dates Span </h4>
                    </Col>
                    <Col xs={6}>
                        <FormItem label="Start" >
                            {getFieldDecorator("consumption_date_span_start", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.consumption_date_span_start ? energyAnalysisSettingsData.consumption_date_span_start : moment("1970-01-01", "YYYY-MM-DD")
                            })(
                                <DatePicker
                                    format={"YYYY-MM-DD"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={6}>
                        <FormItem label="End" >
                            {getFieldDecorator("consumption_date_span_end", {
                                rules: [{ required: true }],
                                initialValue: energyAnalysisSettingsData !== null && energyAnalysisSettingsData.consumption_date_span_end ? energyAnalysisSettingsData.consumption_date_span_end : moment("1970-01-01", "YYYY-MM-DD")
                            })(
                                <DatePicker
                                    format={"YYYY-MM-DD"}
                                    allowClear={false}
                                    disabled={loading}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <FormItem >
                    <Button
                        type="primary"
                        htmlType="button"
                        style={{ width: "40%" }}
                        disabled={saveDisabled}
                        loading={loading}
                        onClick={handleSubmit}
                    >
                        <Icon type="save" /> Save
                        </Button>
                </FormItem>
            </Form>
        </div>
    </IdleTimer>
};

const SettingsEnergyAnalysisForm = Form.create()(_SettingsEnergyAnalysisForm)

export default withRouter(SettingsEnergyAnalysisForm)