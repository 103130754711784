import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import {
  Icon,
  Modal,
  message,
  Notification
} from "antd";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import ModuleLayout from "../../components/ModuleLayout";
import Board from "../../components/UserDashboard";
import * as actions from '../../constants'
import "./style.css"
import { getUserDashboard, postDashboard } from "../../services"

const PanelManagement = ({
  history,
  selectKey }) => {

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const username = useSelector((state) => state.rootReducer.auth.username)

  //const [elementDetails, setElementDetails] = useState({});
  const [isLoading,] = useState(false);
  const [, setLoading] = useState(false);

  const [dashboard, setDashboard] = useState({ uuid: '' })

  const dispatch = useDispatch()

  useEffect(() => {
    getUserDashboardData()
  }, []);

  //update in db device location
  const getUserDashboardData = async () => {
    setLoading(true)
    try {

      const response = await getUserDashboard(tenant_uuid, username)
      if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
        const data = await response.data;
        if (data.length === 0) {
          postDashboardData()
        } else {
          setDashboard(data[0])
        }
        setLoading(false);
      }
    } catch (err) {
      if (err.message.includes("401")) {
        //message.error("There is a problem with your credentials");
        Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
        //history.push("/login");
        dispatch({ type: actions.LOG_OUT })
      } else if (err.message.includes("403")) {
        //message.error("You have not access permissions");
        Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
        dispatch({ type: actions.LOG_OUT })
      } else if (err.message.includes("404")) {
        //message.error("Dashboard does not exists!");
        Notification.error({ message: "Error", "description": "User was deleted!", "duration": 0 });
        dispatch({ type: actions.LOG_OUT })
      } else {
        if (err.message.includes("Network Error")) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: "Connection is lost!",
            okText: "Retry",
            onOk: () => getUserDashboardData(),
          })
        } else {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: err.message,
            okText: "Reload",
            onOk: () => window.location.reload(),
          })
        }
      }
    }
  }

  //update in db device location
  const postDashboardData = async () => {
    setLoading(true)
    try {
      const response = await postDashboard(tenant_uuid, { username })
      if (response && (response.status === 202 || response.status === 201 || response.status === 200)) {
        const data = await response.data;

        setDashboard(data)

        setLoading(false);
      }
    } catch (err) {
      if (err.message.includes("401")) {
        //message.error("There is a problem with your credentials");
        Notification.error({ message: "Error", "description": "You are not authorized to perform this action", "duration": 0 });
        //history.push("/login");
        dispatch({ type: actions.LOG_OUT })
      } else if (err.message.includes("403")) {
        //message.error("You have not access permissions");
        Notification.error({ message: "Error", "description": "You have not permissions to perform this action", "duration": 0 });
        dispatch({ type: actions.LOG_OUT })
      } else if (err.message.includes("404")) {
        //message.error("Dashboard does not exists!");
        Notification.error({ message: "Error", "description": "User was deleted!", "duration": 0 });
        dispatch({ type: actions.LOG_OUT })
      } else {
        if (err.message.includes("Network Error")) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: "Connection is lost!",
            okText: "Retry",
            onOk: () => postDashboardData(),
          })
        } else {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: err.message,
            okText: "Reload",
            onOk: () => window.location.reload(),
          })
        }
      }
    }
  }

  return isLoading ? (
    <Loader />
  ) : (
      <div className="full-height">
        <DndProvider backend={HTML5Backend}>
          <ModuleLayout
            direction="row"
            justify="space-around"
            align="top"
            className="full-height"
            navbarComponent={
              <>
                <Icon type="block" /> <span>User Panels</span>
              </>
            }
            contentComponent={
              <>
                {dashboard.uuid !== '' && <Board selectKey={selectKey} dashboard_uuid={dashboard.uuid} />}
              </>
            }
          />
        </DndProvider>
      </div>
    );
};

export default withRouter(PanelManagement);
