import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchAreaTypesList = () => action(actions.FETCH_AREA_TYPES_LIST)

export const successFetchAreaTypesList = data => action(actions.SUCCESS_FETCH_AREA_TYPES_LIST, { data })

export const errorFetchAreaTypesList = error => action(actions.ERROR_FETCH_AREA_TYPES_LIST, { error })

export const fetchAreaType = area_type_name => action(actions.FETCH_AREA_TYPE, { area_type_name })

export const successFetchAreaType = data => action(actions.SUCCESS_FETCH_AREA_TYPE, { data })

export const errorFetchAreaType = error => action(actions.ERROR_FETCH_AREA_TYPE, { error })

export const removeAreaType = area_type_name => action(actions.REMOVE_AREA_TYPE, { area_type_name })

export const successRemoveAreaType = area_type_name => action(actions.SUCCESS_REMOVE_AREA_TYPE, { area_type_name })

export const errorRemoveAreaType = error => action(actions.ERROR_REMOVE_AREA_TYPE, { error })

export const createAreaType = form => action(actions.CREATE_AREA_TYPE, { form })

export const successCreateAreaType = data => action(actions.SUCCESS_CREATE_AREA_TYPE, { data })

export const editAreaType = (area_type_name, form) => action(actions.EDIT_AREA_TYPE, { area_type_name, form })

export const successEditAreaType = data => action(actions.SUCCESS_EDIT_AREA_TYPE, { data })

export const addAreaTypeParameterValue = (area_type_name, isCreate, form) => action(actions.ADD_AREA_TYPE_PARAMETER_VALUE, { area_type_name, isCreate, form })

export const addAreaTypeParameterValueEmptyForm = () => action(actions.ADD_AREA_TYPE_PARAMETER_VALUE_EMPTY_FORM )

export const updateAreaTypeAllowedParameters = () => action(actions.UPDATE_AREA_TYPE_ALLOWED_PARAMETERS )

export const successAddAreaTypeParameterValue = (data, isCreate) => action(actions.SUCCESS_ADD_AREA_TYPE_PARAMETER_VALUE, { data, isCreate })

export const deleteAreaTypeParameterValue = (parameter_name) => action(actions.DELETE_AREA_TYPE_PARAMETER_VALUE, { parameter_name })

export const successDeleteAreaTypeParameterValue = parameter_name => action(actions.SUCCESS_DELETE_AREA_TYPE_PARAMETER_VALUE, { parameter_name })

export const errorCreateEditAreaType = error => action(actions.ERROR_CREATE_EDIT_AREA_TYPE, { error })

export const fetchAreaTypeParametersList = () => action(actions.FETCH_AREA_TYPE_PARAMETERS_LIST)

export const successFetchAreaTypeParametersList = data => action(actions.SUCCESS_FETCH_AREA_TYPE_PARAMETERS_LIST, { data })

export const errorFetchAreaTypeParametersList = (error, inAreaTypeForm) => action(actions.ERROR_FETCH_AREA_TYPE_PARAMETERS_LIST, { error, inAreaTypeForm })

export const fetchAreaTypeParameter = area_type_parameter_name => action(actions.FETCH_AREA_TYPE_PARAMETER, { area_type_parameter_name })

export const successFetchAreaTypeParameter = data => action(actions.SUCCESS_FETCH_AREA_TYPE_PARAMETER, { data })

export const errorFetchAreaTypeParameter = error => action(actions.ERROR_FETCH_AREA_TYPE_PARAMETER, { error })

export const removeAreaTypeParameter = area_type_parameter_name => action(actions.REMOVE_AREA_TYPE_PARAMETER, {area_type_parameter_name })

export const successRemoveAreaTypeParameter = area_type_parameter_name => action(actions.SUCCESS_REMOVE_AREA_TYPE_PARAMETER, { area_type_parameter_name })

export const errorRemoveAreaTypeParameter = error => action(actions.ERROR_REMOVE_AREA_TYPE_PARAMETER, { error })

export const createAreaTypeParameter = form => action(actions.CREATE_AREA_TYPE_PARAMETER, { form })

export const successCreateAreaTypeParameter = data => action(actions.SUCCESS_CREATE_AREA_TYPE_PARAMETER, { data })

export const editAreaTypeParameter = (area_type_parameter_name, form) => action(actions.EDIT_AREA_TYPE_PARAMETER, { area_type_parameter_name, form })

export const successEditAreaTypeParameter = data => action(actions.SUCCESS_EDIT_AREA_TYPE_PARAMETER, { data })

export const errorCreateEditAreaTypeParameter = error => action(actions.ERROR_CREATE_EDIT_AREA_TYPE_PARAMETER, { error })

export const cleanAreaTypeParametersCreationEditionStates = () =>action(actions.CLEAN_AREA_TYPE_PARAMETERS_CREATION_EDITION_STATES)

export const cleanAreaTypeParametersManagmentState = () =>action(actions.CLEAN_AREA_TYPE_PARAMETERS_MANAGMENT_STATE)