import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Radio, Button, Form, Slider, message, Modal, Icon, Notification } from 'antd'
import IdleTimer from "react-idle-timer"
import { withRouter } from "react-router-dom"
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import moment from 'moment'
import './styles.css'



const FormItem = Form.Item

const _GroupCircadianRhythmForm = ({
  form,
  history,
  tabKey,
}) => {

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, isFieldTouched } = form

  const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const loading = useSelector(state => state.rootReducer.loaders.loadingCircadianRhythm)
  const circadianRhythmData = useSelector(state => state.rootReducer.circadianRhythm.circadianRhythmData)
  const update = useSelector(state => state.rootReducer.circadianRhythm.update)
  const error = useSelector((state) => state.rootReducer.circadianRhythm.error)

  const [saveDisabled, setSaveDisabled] = useState(true)
  const [touchedFields, setTouchedFields] = useState([])

  const dispatch = useDispatch()

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const idleTimer = useRef(null)
  const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { circadian_rhythm, minimun_circadian_intensity } = values


        dispatch(({
          type: actions.UPDATE_CIRCADIAN_RHYTHM,
          payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, form: { circadian_rhythm, minimun_circadian_intensity } }
        }))
      } else {
        message.error(err)
      }
    })
  }

  /*
  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
    },
    [form]
  )
  */

  useEffect(
    () => {
      (async () => {
        if (tabKey === "lights_control" && !loading) {
          resetFields()
          dispatch(({
            type: actions.FETCH_CIRCADIAN_RHYTHM_DATA,
            payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
          }))
          setSaveDisabled(true)

        } else {
          dispatch(({
            type: actions.CLEAN_CIRCADIAN_RHYTHM_STATE,
          }))
        }
      })();
    },
    [tabKey]
  )


  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 })
          history.push("/login")
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              setSaveDisabled(true)
              dispatch(({
                type: actions.FETCH_CIRCADIAN_RHYTHM_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 })
          dispatch({ type: actions.CLEAN_CIRCADIAN_RHYTHM_ERROR })
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (update) {
        message.success("Circadian rhythm settings successfully updated")
        setSaveDisabled(true)
        resetFields()
      }
    },
    [circadianRhythmData]
  )

  const getCircadianRhythmLeftTime = () => {
    /*
    const circadian_start_date_time = moment(circadianRhythmData.circadian_start_date_time)
    circadian_start_date_time.add(12, 'hours')
    const totalSeconds = circadian_start_date_time.diff(moment.utc(), 'seconds')
    return  moment().startOf('day').seconds(totalSeconds).format('H:mm')
    */
   return moment(circadianRhythmData.circadian_start_date_time).format('H:mm')
  }

  const getMinimunSliderMarks = () => {
    const marks = []
    marks[0] = {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>0%</span>
    }

    marks[100] = {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>100%</span>
    }
    return marks
  }

  const handleClickRadioCircadianRhythm = async e => {
    e.preventDefault()
    setSaveDisabled(false)
  }

  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
      setTouchedFields(touched_fields)
    },
    [form]
  )

  const onUserIdle = async e => {
    if (tabKey === "lights_control") {
      resetFields()
      dispatch(({ type: actions.FETCH_CIRCADIAN_RHYTHM_DATA, payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid } }))
      setSaveDisabled(true)
    }
    //idleTimer.reset()
  }

  return (
    <>
        <IdleTimer
          ref={idleTimer}
          element={document}
          onIdle={onUserIdle}
          debounce={250}
          timeout={timeout}
        ></IdleTimer>
      <Form className="input-form" onSubmit={handleSubmit}>

        <FormItem label="Circadian Rhythm">
          {getFieldDecorator("circadian_rhythm", {
            initialValue: circadianRhythmData && circadianRhythmData.circadian_rhythm ? circadianRhythmData.circadian_rhythm : false
          })(
            <Radio.Group disabled={loading}>
              <Radio.Button disabled={loading} value={false} onClick={handleClickRadioCircadianRhythm} >OFF</Radio.Button>
              <Radio.Button disabled={loading || (elementData && elementData.on_mode !== "off") } value={true} onClick={handleClickRadioCircadianRhythm} >ON</Radio.Button>
            </Radio.Group>
          )}
        </FormItem>
        { circadianRhythmData && circadianRhythmData.circadian_rhythm && (
          <div>Circadian start time: {getCircadianRhythmLeftTime()}</div>
        )}
        <FormItem label="Minimum circadian intensity">
            {getFieldDecorator("minimun_circadian_intensity", {
              initialValue: circadianRhythmData && circadianRhythmData.minimun_circadian_intensity ? circadianRhythmData.minimun_circadian_intensity : 1
            })(
              <Slider disabled={loading} step={1} marks={getMinimunSliderMarks()} min={0} max={100} />
            )}
          </FormItem>
        <FormItem >
          <Button
            type="primary"
            htmlType="button"
            disabled={saveDisabled}
            loading={loading}
            onClick={handleSubmit}
          >
            Save circadian setup
          </Button>
        </FormItem>
      </Form>
    </>
  )
}

const GroupCircadianRhythmForm = Form.create()(_GroupCircadianRhythmForm)
export default withRouter(GroupCircadianRhythmForm)
