import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Button, Form, message, Modal, Icon, Notification, Row, Col, Switch, DatePicker, Card, Alert } from 'antd'
import { withRouter } from "react-router-dom"
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import moment from 'moment'
import './styles.css'

const FormItem = Form.Item

const _ElementDetailEnergyAnalysisAggregationFormTab = ({
  form,
  history,
  tabKey,
}) => {

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, isFieldTouched } = form

  const elementData = useSelector((state) => state.rootReducer.companyManagment.currentElement)
  const loading = useSelector(state => state.rootReducer.loaders.loadingAggregationRequest)
  const aggregationRequestData = useSelector(state => state.rootReducer.aggregationRequest.aggregationRequestData)
  const update = useSelector(state => state.rootReducer.aggregationRequest.update)
  const error = useSelector((state) => state.rootReducer.aggregationRequest.error)

  const [saveDisabled, setSaveDisabled] = useState(true)
  const [showWarning, setShowWarning] = useState(false)

  const dispatch = useDispatch()

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)

  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { enable_aggregation } = values

        dispatch(({
          type: actions.SAVE_AGGREGATION_REQUEST_SETUP,
          payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, form: { enable: enable_aggregation }, group_type: elementData.group_type }
        }))
      } else {
        message.error(err)
      }
    })
  }

  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
        if(!getFieldValue('enable_aggregation') && aggregationRequestData && ("aggregation_starting_date" in aggregationRequestData)){
          setShowWarning(true)
        }else{
          setShowWarning(false)
        }
      }
    },
    [form]
  )

  useEffect(
    () => {
      (async () => {
        if (tabKey === "aggregation_request" && !loading) {
          resetFields()
          dispatch(({
            type: actions.FETCH_AGGREGATION_REQUEST_DATA,
            payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
          }))
          setSaveDisabled(true)
          setShowWarning(false)
        }else{
          dispatch(({
            type: actions.CLEAN_AGGREGATION_REQUEST_STATE,
          }))
        }
      })();
    },
    [tabKey]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 })
          history.push("/login")
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              setSaveDisabled(true)
              dispatch(({
                type: actions.FETCH_AGGREGATION_REQUEST_DATA,
                payload: { tenant_uuid: tenant_uuid, uuid: elementData.uuid, group_type: elementData.group_type }
              }))
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 })
          dispatch({ type: actions.CLEAN_POWER_CALIBRATION_ERROR })
        }
      }
    },
    [error]
  )

  useEffect(
    () => {
      if (update) {
        message.success("Aggregation successfully updated")
        setSaveDisabled(true)
        setShowWarning(false)
        resetFields()
      }
    },
    [aggregationRequestData]
  )

  return (

    <Card>
      <h3><Icon type="thunderbolt" /> Energy Consumption Analysis request</h3>
      <hr />
      {showWarning && (
        <Alert message="This action will disable energy consumption aggregation!" type="warning"/>
      )}
      <Form className="input-form" onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <FormItem label="Enable aggregation">
              {getFieldDecorator("enable_aggregation", {
                initialValue: aggregationRequestData ? aggregationRequestData.enable : false,
                valuePropName: 'checked'
              })(
                <Switch disabled={loading} />
              )}
            </FormItem>
          </Col>
          { aggregationRequestData && "aggregation_starting_date" in aggregationRequestData && (
          <Col xs={10}>
            <FormItem label="Request starting date">
              {getFieldDecorator("calibration_datetime", {
                initialValue: aggregationRequestData ? aggregationRequestData.aggregation_starting_date : moment("1070-01-01 00:00:00")
              })(
                <DatePicker
                  format="YYYY-MM-DD"
                  disabled={true}
                />
              )}
            </FormItem>
          </Col>
          )}
        </Row>
        <Tooltip title="Save aggregation request settings">
          <Button
            icon="save"
            type="primary"
            className="add-schedule-btn"
            onClick={handleSubmit}
            loading={loading}
            disabled={saveDisabled}
          >
            Save
            </Button>
        </Tooltip>
      </Form>
    </Card>
  )
}

const ElementDetailEnergyAnalysisAggregationFormTab = Form.create()(_ElementDetailEnergyAnalysisAggregationFormTab)
export default withRouter(ElementDetailEnergyAnalysisAggregationFormTab)
