import * as actions from '../constants';

const initialState = {
    version: "",
    loading: true,
    isLatestVersion: false
}

const cacheBusting = (state = initialState, action) => {
    switch (action.type) {
        case actions.CACHE_CLEANUP_NOT_NEEDED:
            return Object.assign({}, state, {
                loading: false,
                isLatestVersion: true
            })

        case actions.CACHE_CLEANUP_NEEDED:
            return Object.assign({}, state, {
                loading: false,
                isLatestVersion: false,
                version: action.payload.version
            })

        case actions.REFRESH_CACHE_AND_RELOAD:
            console.log('Clearing cache and hard reloading...')
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then(async function (names) {
                    await Promise.all(names.map(name => caches.delete(name)))
                })
            }
            // delete browser cache and hard reload
            window.location.reload()
            
            return Object.assign({}, state, {
                loading: false,
                isLatestVersion: true
            })
            
        default:
            return state
    }
}

export default cacheBusting