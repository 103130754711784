import { combineReducers } from "redux";

import auth from "./authReducer";
import userManagment from "./userManagmentReducer"
import companyManagment from "./companyManagmentReducer";
import loaders from "./loadersReducer";
import editCreateElement from "./editCreateElementReducer"
import lightsControl from "./lightsControlReducer"
import illuminance from "./illuminanceReducer"
import peopleAnalytics from "./peopleAnalyticsReducer"
import controllers from "./controllersReducer"
import powerCalibration from "./powerCalibrationReducer"
import aggregationRequest from "./aggregationRequestReducer"
import lightCapability from "./lightCapabilityReducer"
import redirect from "./redirectReducer"
import latestData from "./latestDataReducer"
import chartData from "./chartDataReducer"
import areaTypeParameters from "./areaTypeParametersReducer"
import UVControl from "./UVControlReducer"
import userPasswordReset from "./userPasswordResetReducer"
import notification from "./notificationReducer"
import notificationUser from "./notificationUserReducer"
import cacheBusting from "./cacheBustingReducer"
import blueLightBoost from "./blueLightBoostReducer"
import userProfile from "./userProfileReducer"
import advancedSchedule from "./advancedScheduleReducer"
import soundMasking from "./soundMaskingReducer"
import energyAnalysisOverview from "./energyAnalysisOverviewReducer"
import circadianRhythm from "./circadianRhythmReducer"

import * as actions from '../constants'

const appReducer = combineReducers({
  auth,
  userManagment,
  companyManagment,
  editCreateElement,
  lightsControl,
  illuminance,
  controllers,
  powerCalibration,
  aggregationRequest,
  lightCapability,
  areaTypeParameters,
  latestData,
  chartData,
  loaders,
  redirect,
  peopleAnalytics,
  UVControl,
  userPasswordReset,
  notification,
  notificationUser,
  blueLightBoost,
  cacheBusting,
  userProfile,
  advancedSchedule,
  soundMasking,
  energyAnalysisOverview,
  circadianRhythm
})

const rootReducer = ( state, action ) => {
  switch (action.type) {
    case actions.LOG_OUT:
      state = undefined
      return appReducer(state, action)
    default:
      return appReducer(state, action)
  }
}

export default rootReducer;
