import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Empty, Button, Modal, Radio, Row, Col, Input } from "antd";
import "./styles.css";
import NotificationList from "../NotificationList";
import NotificationDetail from "../NotificationDetail";
import NotificationCreateForm from "../NotificationCreateForm";
import * as actions from '../../constants'
import * as error_codes from '../../constants/error_codes'
import * as constant from '../../constants/notifications'


const NotificationUser = ({
    history,
    visible
}) => {

    const [transport, setTransport] = useState("all")
    const [currentNotification, setCurrentNotification] = useState()
    const [currentNotificationUUID, setCurrentNotificationUUID] = useState("")
    const notificationDetail = useSelector((state) => state.rootReducer.notification.notificationUserDetail)

    const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)

    const [receipt_send, setReciberSend] = useState("receipt")
    const [newNotification, setNewNotification] = useState(false)
    const [detailNotification, setDetailNotification] = useState(false)


    const notificationData = useSelector((state) => state.rootReducer.notificationUser.notificationData)
    const loading = useSelector(state => state.rootReducer.loaders.loadingUserNotification)
    const error = useSelector((state) => state.rootReducer.notificationUser.error)

    const notificationSendData = useSelector((state) => state.rootReducer.notificationUser.notificationSendData)
    const loadingSend = useSelector(state => state.rootReducer.loaders.loadingUserNotification)
    const errorSend = useSelector((state) => state.rootReducer.notificationUser.errorSend)


    const dispatch = useDispatch()

    useEffect(() => {
        if (notificationDetail === true) {
            if (currentNotificationUUID !== "") {
                if (receipt_send === constant.SEND) {

                    notificationSendData[transport].forEach(element => {
                        if (currentNotificationUUID === element.uuid) {
                            setCurrentNotification(element);
                        }
                    });
                } else {
                    notificationData[transport].forEach(element => {
                        if (currentNotificationUUID === element.uuid) {
                            setCurrentNotification(element);
                        }
                    });
                }

            }

            //put false
            dispatch(({
                type: actions.SET_NOTIFICATION_USER_DETAIL,
                payload: false
            }))
        }
        
    },
        [notificationDetail]
    )

    useEffect(() => {
        setTransport("all")
    },
        []
    )

    useEffect(
        () => {
            if (error !== null) {
                if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
                    Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
                    history.push("/login");
                    dispatch({ type: actions.LOG_OUT })
                } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
                    Modal.destroyAll()
                    Modal.error({
                        destroyOnClose: true,
                        title: error.message,
                        okText: "Retry",
                        onOk: () => {
                            if (receipt_send === constant.SEND) {
                                dispatch(({
                                    type: actions.FETCH_USER_SEND_NOTIFICATION,
                                    payload: { tenant_uuid: tenant_uuid, transport: transport }
                                }))
                            } else {
                                dispatch(({
                                    type: actions.FETCH_USER_NOTIFICATION,
                                    payload: { tenant_uuid: tenant_uuid, transport: transport }
                                }))
                            }
                        },
                    })
                } else {
                    Notification.error({ message: "Error", "description": error.message, "duration": 0 });
                    dispatch({ type: actions.CLEAN_USER_NOTIFICATION_ERROR })
                }
            }
        },
        [error]
    )

    useEffect(
        () => {
            if (errorSend !== null) {
                if (errorSend.error_code === errorSend.ERR_INVALID_TOKEN || errorSend.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
                    Notification.error({ message: "Permission required", "description": errorSend.message, "duration": 0 });
                    history.push("/login");
                    dispatch({ type: actions.LOG_OUT })
                } else if (errorSend.error_code === error_codes.ERR_LOST_CONNECTION) {
                    Modal.destroyAll()
                    Modal.error({
                        destroyOnClose: true,
                        title: error.message,
                        okText: "Retry",
                        onOk: () => {
                            if (receipt_send === constant.SEND) {
                                dispatch(({
                                    type: actions.FETCH_USER_SEND_NOTIFICATION,
                                    payload: { tenant_uuid: tenant_uuid, transport: transport }
                                }))
                            } else {
                                dispatch(({
                                    type: actions.FETCH_USER_NOTIFICATION,
                                    payload: { tenant_uuid: tenant_uuid, transport: transport }
                                }))
                            }
                        },
                    })
                } else {
                    Notification.error({ message: "Error", "description": error.message, "duration": 0 });
                    dispatch({ type: actions.CLEAN_USER_NOTIFICATION_ERROR })
                }
            }
        },
        [errorSend]
    )

    useEffect(() => {
        if(visible){

        
        if (receipt_send === constant.SEND) {
            dispatch(({
                type: actions.FETCH_USER_SEND_NOTIFICATION,
                payload: { tenant_uuid: tenant_uuid, transport: transport }
            }))
        } else {
            dispatch(({
                type: actions.FETCH_USER_NOTIFICATION,
                payload: { tenant_uuid: tenant_uuid, transport: transport }
            }))
        }
        /*
        return () => {
            dispatch(({
                type: actions.CLEAN_USER_NOTIFICATION_STATE
            }))
            dispatch(({
                type: actions.CLEAN_USER_SEND_NOTIFICATION_STATE
            }))
        }
        */
    }else{
        setTransport("all")
        setDetailNotification(false)
        setNewNotification(false)
    }
    },
        [visible, transport, receipt_send])


    const detailNotificationFunction = (index) => {
        if (receipt_send === constant.RECEIPT) {
            if (notificationData && notificationData[transport].length > index) {
                setCurrentNotification(notificationData[transport][index])
                setCurrentNotificationUUID(notificationData[transport][index].uuid)
                setDetailNotification(true)
            }
        } else if (receipt_send === constant.SEND) {

            if (notificationSendData && notificationSendData[transport].length > index) {
                setCurrentNotification(notificationSendData[transport][index])
                setCurrentNotificationUUID(notificationSendData[transport][index].uuid)
                setDetailNotification(true)
            }
        }
    }

    const detailNotificationFalse = () => {
        setDetailNotification(false)
    }

    const newNotificationFalse = () => {
        setNewNotification(false)
    }

    const createNotification = e => {
        setNewNotification(true);
    };

    const selectTransortShow = e => {
        setTransport(e.target.value);
    };

    const selectReceiptSend = e => {
        setReciberSend(e.target.value);
    };

    return (
        notificationData
            ? (
                <>
                    {newNotification ?

                        <>
                            <Row>
                                <NotificationCreateForm
                                    form_type="user"
                                    element_uuid={""}
                                    element_type={""}
                                    deselectCreate={newNotificationFalse}
                                />
                            </Row>

                        </>
                        :
                        detailNotification ?

                            <>
                                <Row>
                                    <NotificationDetail
                                        call="user"
                                        receipt_send={receipt_send}
                                        transport={transport}
                                        notification={currentNotification}
                                        deselectNotification={detailNotificationFalse}
                                    />
                                </Row>
                            </>
                            :
                            <>
                                <Row className="notification-detail" style={{ marginBottom: "10px" }}>
                                    <Col span={6} >
                                        <Button type="primary" onClick={createNotification}>New Notification</Button>
                                    </Col>
                                    <Col span={12} >
                                        <Radio.Group
                                            className="radio-button-user-receipt-sender"
                                            onChange={selectTransortShow}
                                            value={transport}
                                            optionType="button"
                                            buttonStyle="solid"
                                        >
                                            <Radio.Button value="all">All</Radio.Button>
                                            <Radio.Button value="toast">Toast</Radio.Button>
                                            <Radio.Button value="mail">Mail</Radio.Button>
                                            <Radio.Button value="sms">SMS</Radio.Button>

                                        </Radio.Group>
                                    </Col>
                                    <Col span={6} >
                                        <Radio.Group
                                            className="radio-button-user-receipt-sender"
                                            onChange={selectReceiptSend}
                                            value={receipt_send}
                                            optionType="button"
                                            buttonStyle="solid"
                                        >
                                            <Radio.Button value="receipt">Received</Radio.Button>
                                            <Radio.Button value="send">Sent</Radio.Button>

                                        </Radio.Group>
                                    </Col>
                                </Row>

                                {receipt_send && receipt_send === constant.RECEIPT && notificationData && notificationData[transport] ?
                                    <NotificationList
                                        transport={transport}
                                        notificationData={notificationData[transport]}
                                        isLoading={loading}
                                        selectNotification={detailNotificationFunction}
                                    />
                                    : receipt_send && receipt_send === constant.SEND && notificationSendData && notificationSendData[transport] ?
                                        <NotificationList
                                            transport={transport}
                                            notificationData={notificationSendData[transport]}
                                            isLoading={loadingSend}
                                            selectNotification={detailNotificationFunction}

                                        />
                                        :
                                        <Empty />
                                }
                            </>
                    }

                </>
            )
            : <Empty />
    );
};

export default withRouter(NotificationUser);
