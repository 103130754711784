import {
    getBuildingEnergyOverviewSettingsService,
    getGroupEnergyAnalysisSettingsService,
    getBuildingPeaksEnergyConsumptionService,
    getGroupPeakEnergyConsumptionService,
    getBuildingLatestEnergyConsumptionService,
    getGroupLatestEnergyConsumptionService,
    putBuildingEnergyOverviewSettingsService,
    putGroupEnergyAnalysisSettingsService,
    getGroupHistoricAggregatedSensorDataService
} from "../services";

export const fetchEnergyAnalyisSettingsApi = async (tenant_uuid, uuid, group_type) => {
    let fetch_function = () => { }
    switch(group_type){
        case "Building":
            fetch_function = getBuildingEnergyOverviewSettingsService
            break
        default:
            fetch_function = getGroupEnergyAnalysisSettingsService
    }

    const response = await fetch_function(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateEnergyAnalyisSettingsApi = async (tenant_uuid, uuid, form, group_type) => {
    let update_function = () => { }
    switch(group_type){
        case "Building":
            update_function = putBuildingEnergyOverviewSettingsService
            break
        default:
            update_function = putGroupEnergyAnalysisSettingsService
    }

    const response = await update_function(tenant_uuid, uuid, form);
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}

export const fetchPeaksEnergyConsumptionApi = async (tenant_uuid, uuid, group_type) => {
    let fetch_function = () => { }
    switch(group_type){
        case "Building":
            fetch_function = getBuildingPeaksEnergyConsumptionService
            break
        default:
            fetch_function = getGroupPeakEnergyConsumptionService
    }

    const response = await fetch_function(tenant_uuid, uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const fetchLatestEnergyConsumptionApi = async (tenant_uuid, uuid, group_type, hours, minutes, seconds) => {
    let fetch_function = () => { }
    switch(group_type){
        case "Building":
            fetch_function = getBuildingLatestEnergyConsumptionService
            break
        default:
            fetch_function = getGroupLatestEnergyConsumptionService
    }

    const response = await fetch_function(tenant_uuid, uuid, hours, minutes, seconds);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const fetchHistoricEnergyAggregatedDataApi = async (tenant_uuid, uuid, start_date, end_date) => {

    const response = await getGroupHistoricAggregatedSensorDataService(tenant_uuid, uuid, start_date, end_date);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}