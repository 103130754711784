import React, { useState, useEffect } from "react"
import {
    Tree,
    Form,
    Col,
    Row,
    Select,
} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import "./style.css";
import * as actions from '../../constants';
//import { element } from "prop-types";
import ContextMenuDashboard from "../ContextMenuDashboard"

const { Option } = Select;
const { TreeNode } = Tree;


const ModalHeatmapConfig = ({
    history,
    setElement,
    selectSensor,
    selectLastHour,
    setIsloading
}) => {

    const treeData = useSelector((state) => state.rootReducer.companyManagment.companyTreeData)

    const [group_uuid, setGroup_uuid] = useState("");
    const [clickPos, setClickPos] = useState([]);
    const [parentUuid, setParentUuid] = useState("");
    const [loading, setLoading] = useState(false);

    const [sensor, setSensor] = useState("temperature");
    const [lasthour, setLastHour] = useState("0.5");

    const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
    const managerRole = useSelector((state) => state.rootReducer.auth.rolename)
    const managerCompany = useSelector((state) => state.rootReducer.auth.company)
    const managerBuildings = useSelector((state) => state.rootReducer.auth.buildings)
    const managerGroup = useSelector((state) => state.rootReducer.auth.group)

    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            setCompanyData()
        })();
    }, []);

    useEffect(() => {
       if(treeData){
         setIsloading(false)
       }
    }, 
    [treeData])

    const setCompanyData = async () => {
        setIsloading(true)
        dispatch({ type: actions.FETCH_COMPANY_TREE_DATA, payload: { tenant_uuid: tenant_uuid, rolename: managerRole, user_companies: managerCompany, user_buildings: managerBuildings, user_groups: managerGroup } })
    }

    const getParent = async (array, pos) => {
        //console.log("TLC: get parent Parent uuid " + array[pos[0]].uuid + " len " + pos.length);
        if (pos.length === 1 || pos.length === 2) return array[pos[0]].uuid;
        return getParent(array[pos[0]].children, pos.slice(1));
      };

    const handleSelect = async (selectedKeys, info) => {
        setClickPos(info.node.props.pos.substring(2).split("-"));
        const _parentUuid = await getParent(
          treeData,
          info.node.props.pos.substring(2).split("-")
        );
        setParentUuid(_parentUuid);
        if(info.node.props.dataRef.group_type === "Control" || info.node.props.dataRef.group_type === "Mix" || info.node.props.dataRef.group_type === "Logical"){
            const select_group_uuid = info.node.props.dataRef.uuid
            setElement({ 'uuid': select_group_uuid, 'name': info.node.props.dataRef.name})
        }else{
            setElement({ 'uuid': ""})
        }
    
      };

      const handleChange = option => {
        setSensor(option)
        selectSensor(option)
      };

      const handleChangeHour = hour => {
        setLastHour(hour)
        selectLastHour(hour)
      };

    const renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode
                        title={
                            <ContextMenuDashboard
                                text={item.name}
                                parent={item}
                                icon={item.group_type}

                            />
                        }
                        key={item.uuid}
                        dataRef={item}
                    >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode
                    title={
                        <ContextMenuDashboard
                            text={item.name}
                            parent={item}
                            icon={item.group_type}
                        />
                    }
                    {...item}
                    key={item.uuid}
                    dataRef={item}
                />
            );
        });

    const render = () => {

        return (
            <div>
                <Row>
                    <Col span={24}>
                        <div>Select control group:</div>
                        <Tree
                            onSelect={handleSelect}
                            //loadData={onLoadData}
                            //onRightClick={handleRightClick}
                            defaultExpandParent
                        >
                            {treeData ? renderTreeNodes(treeData) : ""}
                        </Tree>
                    </Col>
                   
                    <Col span={24} style={{marginTop: "20px"}}>
                        <span style={{ marginRight: "10px" }}>Sensor:</span>


                        <Select
                          defaultValue={sensor}
                          style={{ width: 120 }}
                          onChange={handleChange}
                        >
                          <Option value="temperature">Temperature</Option>
                          <Option value="pressure">Pressure</Option>
                          <Option value="humidity">Humidity</Option>
                          <Option value="power">Power</Option>
                          <Option value="motion">Occupancy</Option>
                          <Option value="co2">CO2</Option>
                          <Option value="voc">VOC</Option>
                        </Select>
                      </Col>

                      <Col span={24} style={{marginTop: "20px"}}>
                        <span style={{ marginRight: "10px" }}>Last hours:</span>


                        <Select
                          defaultValue={"30 minutes"}
                          style={{ width: 120 }}
                          onChange={handleChangeHour}
                        >
                          <Option value="0.5">30 minutes</Option>
                          <Option value="1">1 hour</Option>
                          <Option value="3">3 hours</Option>
                          <Option value="5">5 hours</Option>
                          
                        </Select>
                      </Col>

                </Row>
            </div>
        );
    }

    return (
        render()
    )
}

const ModalHeatmapConfigComp = Form.create()(ModalHeatmapConfig);

export default withRouter(ModalHeatmapConfigComp);
