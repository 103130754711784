import {
    getGroupLatestSensorDataService,
} from "../services";

export const fetchLatestDataApi = async (tenant_uuid, uuid, sensor_type) => {

    const response = await getGroupLatestSensorDataService(tenant_uuid, uuid, sensor_type);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}