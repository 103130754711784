import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  List,
  Row,
  Col,
  Icon,
  Tooltip,
  Spin,
} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../Loader";
import FrecuencyMap from "../FrecuencyMap/indexpeopleanalytics";
import VectorOptionMap from "../FrecuencyMap/indexpeopleanalyticsvector";
import Map from "../HeatMap";
import { UserContext } from "../../context";
import { getHighPrecisionDataHour, getLeyend } from "./heatmapfunction";
import { symbol, getTitle, isModuleNotificatoinActive } from '../../utils/helpers';
import moment from 'moment'
import "./style.css";

const { HEATMAP_RANGE_PA, HEATMAP_COLOR_PA, HEATMAP_RANGE_PA_DENSITY, HEATMAP_COLOR_PA_DENSITY, HEATMAP_RANGE_PA_VECTORS, HEATMAP_COLOR_PA_VECTORS } = window.Configs;
const { ANIMATION_SPEED_SECONDS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, RADIUS_BUBBLE_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, MINIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, MAXIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP, ANIMATION_TRANSITION_FRAME_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP } = window.Configs;


const DevicesHeatMap = ({
  history,
  index,
  editabled,
  element_uuid,
  parentMaType,
  setParentMapType,

  parentdata,
  parentconfig,
  updateParentConfig,
  updateParentData,
  changeLoading,
  floor_plan,
  workspace_radius,
  occupancy_limit,

  //tabKey
  
  data_warning,
  parentdataVectors,
  parentconfigVectors,
  heatmapPAVectors,
  updateParentConfigVectors,
  updateParentDataVectors,
  createParentVectors,
  vectorSpiner,
  setRefreshMinutesInterval,
  
  setJumpToRefresh,
  indexNewValue,
}) => {
  const [thedata, setThedata] = useState(
    parentdata
    /*
    { image: "", imagedisplay: "horizontal", devices: [], data: [], chartData: [] }
    */
  );
  const [theconfig, setTheconfig] = useState(
    parentconfig
    /*
    { element_uuid: parentconfig.element_uuid, shouldRenderFrecuencyMap: false, sensorName: 'temperature', day: moment().format("YYYY-MM-DD"), hour: Number(moment().format("HH")), }
    */
  );

  const [thedataVectors, setThedataVectors] = useState(
    parentdataVectors
  );
  const [theconfigVectors, setTheconfigVectors] = useState(
    parentconfigVectors
  );

  const [bubble, setBubble] = useState(20)
  const [bubbleDensity, setBubbleDesity] = useState(30)
  const [bubbleVector, setBubbleVector] = useState(RADIUS_BUBBLE_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP)

  const [mapType, setMapType] = useState(parentMaType) //occupancy density vector

  const [dataheatmap, setDataheatmap] = useState(
    {
      "occupancy": thedata.data,
      "density": thedata.dataDensity
    });

  const [dataheatmapVectors, setDataheatmapVectors] = useState(
    thedataVectors && thedataVectors.data && thedataVectors.data[0] ? thedataVectors.data[0] : [],
  );
  //

  const [range, setRange] = useState(-1);
  const [panelRange, setPanelRange] = useState(-1);

  const [animationindex, setAnimationindex] = useState(indexNewValue);
  const [maxAnimationIndex, setMaxAnimationIndex] = useState(0);
  const [animationPlay, setAnimationPlay] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP)

  const [dataLoading, setDataLoading] = useState(false);
  const { userData: { tenant_uuid } } = useContext(UserContext);

  const user_modules = useSelector(state => state.rootReducer.auth.modules)

  const dispatch = useDispatch()

  useEffect(() => {
    
    return () => {
      if (window.animationInterval) {
        stopAnimationInterval()
      }
    };

  },
    [])

  useEffect(() => {
    setThedataVectors(parentdataVectors)
    if (parentdataVectors && parentdataVectors.data && parentdataVectors.data.length && parentdataVectors.data.length > 0) {
      setMaxAnimationIndex(parentdataVectors.data.length - 1)
      setAnimationindex(indexNewValue*ANIMATION_TRANSITION_FRAME_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP)
    } else {
      setMaxAnimationIndex(0)
      setAnimationindex(0)
    }
  },
    [parentdataVectors])

  useEffect(() => {
    setThedataVectors({
      ...thedataVectors,
      update: true
    })
    window.aindex = animationindex
  },
    [animationindex])

  useEffect(() => {
    if (animationPlay) {
      if (window.animationInterval) {
        stopAnimationInterval()
      }
      window.animationInterval = setInterval(
        () => {
          playAnimation()
        }, 1000 * (1 / animationSpeed));
    } else {
      stopAnimationInterval()
    }
  },
    [animationPlay, animationSpeed])

  useEffect(() => {
    if (thedata.update === true) {
      setDataheatmap(
        {
          "occupancy": [],
          "density": []
        })

    }
  },
    [thedata.update])


  useEffect(() => {
    if (thedata && thedata.update === true) {
      setDataheatmap(
        {
          "occupancy": thedata.data,
          "density": thedata.dataDensity
        })

      if (dataheatmap["occupancy"].length > 0) {
        setThedata({
          ...thedata,
          update: false
        })
      }
    }

  },
    [dataheatmap["occupancy"]])


  useEffect(() => {
    if (thedataVectors && thedataVectors.update === true) {
      setDataheatmapVectors(
        []
      )

    }
  },
    [thedataVectors.update])

  useEffect(() => {
    
  },
    [thedataVectors])


    useEffect(() => {
      if(parentconfigVectors && parentconfigVectors.config && parentconfigVectors.config.data_analysis_status_type === 3){
        setTheconfigVectors(parentconfigVectors)
        setPanelRange(range)
      }
    },
      [parentconfigVectors])

  useEffect(() => {
    if (thedataVectors && thedataVectors.update === true) {
      setDataheatmapVectors(
        thedataVectors.data[animationindex],
      )

      if (dataheatmapVectors && dataheatmapVectors.length > 0) {
        setThedataVectors({
          ...thedataVectors,
          update: false
        })
      }
    }

  },
    [dataheatmapVectors])


  useEffect(() => {

  },
    [mapType])


  const updateImageDisplay = (e) => {
    if (thedata.image !== "") {
      if (Number(e.target.width) >= Number(e.target.height)) {
        setThedata({ ...thedata, imageDisplay: "horizontal", imageWidth: e.target.width, imageHeight: e.target.height, ftToPixel: floor_plan / (e.target.width * e.target.height) });
      } else {
        setThedata({ ...thedata, imageDisplay: "vertical", imageWidth: e.target.width, imageHeight: e.target.height, ftToPixel: floor_plan / (e.target.width * e.target.height) });
      }
      changeLoading(false);
      //}
    }
  }

  const putEmptydata = (devices) => {
    let newchartdata = [];
    let emptydevices = [];

    //for all devices put x, y, 1
    for (let index = 0; index < devices.length; index++) {
      emptydevices.push([devices[index].x, devices[index].y, "1"])
    }

    //for all hour put empty device
    for (let index = 0; index < 24; index++) {
      newchartdata.push({ devices: emptydevices })
    }
    return newchartdata;
  }

  const isEmptyHour = (hour) => {
    let returnvalue = false;
    (thedata.chartData[hour].devices).forEach(element => {
      if (element[2] === "1" || element[2] === 1) {
        returnvalue = true;
      }
    });
    return returnvalue;
  }

  const selectDay = async (date) => {
    if (thedata.chartData.length > 0) {
      setDataLoading(true)
      const { tmpData, active_devices, occupancy_events, occupants, warning, tmpDensityData, group_control_data, control_group } = await getHighPrecisionDataHour(history, dispatch, tenant_uuid, { ...theconfig, day: date }, thedata.devices, occupancy_limit)
      const datahour = tmpData;
      const datadensityhour = tmpDensityData;

      let newchartdata = putEmptydata(thedata.devices);
      newchartdata[theconfig.hour] = datahour[0];

      let newchartdensitydata = putEmptydata(thedata.devices);
      newchartdensitydata[theconfig.hour] = datadensityhour[0];

      let newparams = thedata.params;
      newparams[theconfig.hour] = {
        active_devices: active_devices,
        occupancy_events: occupancy_events,
        occupants: occupants,
        warning: warning,
        group_control_data: group_control_data,
      }

      updateParentConfig(index, { ...theconfig, day: date })
      updateParentData(index, { ...thedata, chartData: newchartdata, data: newchartdata[theconfig.hour].devices, params: newparams })

      setDataLoading(false)

      await setThedata({
        ...thedata,
        chartData: newchartdata,
        chartDensityData: newchartdensitydata,
        data: datahour[0].devices,
        dataDensity: datadensityhour[0].devices,
        //active_devices: ((datahour && datahour[0] && datahour[0].devices && datahour[0].devices.length > 0) ? (active_devices / datahour[0].devices.length) * 100 : 0),
        //occupancy_events: occupancy_events,
        //occupants: occupants,
        //warning: warning,
        params: newparams,
        control_group: control_group,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        day: date
      })
    }
    //setUpdateDataDay(true)
  }


  const changeHour = async (new_hour) => {
    if (isEmptyHour(new_hour)) {
      setDataLoading(true)

      const newconfig = {
        ...theconfig,
        hour: new_hour
      };

      const { tmpData, active_devices, occupancy_events, occupants, warning, tmpDensityData, group_control_data, control_group } = await getHighPrecisionDataHour(history, dispatch, tenant_uuid, newconfig, thedata.devices, occupancy_limit);
      const datahour = tmpData;
      const datadensityhour = tmpDensityData;
      setDataLoading(false)

      let newchartdata = thedata.chartData;
      newchartdata[new_hour] = datahour[0];

      let newchartdensitydata = thedata.chartDensityData;
      newchartdensitydata[new_hour] = datadensityhour[0];

      let newparams = thedata.params;
      newparams[new_hour] = {
        active_devices: active_devices,
        occupancy_events: occupancy_events,
        occupants: occupants,
        warning: warning,
        group_control_data: group_control_data,
      }


      setDataLoading(false)

      await setThedata({
        ...thedata,
        chartData: newchartdata,
        chartDensityData: newchartdensitydata,
        data: datahour[0].devices,
        dataDensity: datadensityhour[0].devices,
        params: newparams,
        control_group: control_group,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        hour: new_hour
      })
    } else {

      await setThedata({
        ...thedata,
        data: thedata.chartData[new_hour].devices,
        dataDensity: thedata.chartDensityData[new_hour].devices,
        update: true
      });

      await setTheconfig({
        ...theconfig,
        hour: new_hour
      })

    }
  }

  const setAnimationIndexParent = (index) => {
    setAnimationindex(index)
  }

  const onRadiusChange = param => {
    if (typeof param === "number") {
      setBubble(param);
    }
  };

  const onMapTypeChange = param => {
    //setDisabledSensorButton(true)
    setMapType(param)
    setParentMapType(param)
  };

  const fileterByGroup = (densityDataheatmap, group_id) => {
    let newdensityDataheatmap = []
    for (let index = 0; index < densityDataheatmap.length; index++) {
      let element = []
      for (let indey = 0; indey < densityDataheatmap[index].length; indey++) {
        const value = densityDataheatmap[index][indey]
        element.push(value)
      }
      newdensityDataheatmap.push(element)
    }

    const filterdata = newdensityDataheatmap.filter((device, indexd) => {
      return device.length >= 5 && device[4] + "" === group_id + "";
    })

    const convertdata = filterdata.map((device, indexd) => {
      let newdevice = device
      newdevice[2] = 1
      newdevice[3] = 100
      return newdevice
    })

    return convertdata
  }

  const getHeatmapColor = (percent) => {
    //let gama = [
    //  [0, 255, 0],
    //  [255, 255, 0],
    //  [255, 0, 0]
    //]
    let gama = []

    for (let index = 0; index < HEATMAP_RANGE_PA_DENSITY["default"].length; index++) {
      let value0 = (HEATMAP_COLOR_PA_DENSITY[HEATMAP_RANGE_PA_DENSITY["default"][index] + ""])
      if (!value0) {
        value0 = (HEATMAP_COLOR_PA_DENSITY[HEATMAP_RANGE_PA_DENSITY["default"][index] + ".0"])
      }
      const value1 = value0.replace("rgb(", "");
      const value2 = value1.replace(")", "");
      const value3 = value2.replace(" ", "");
      const value4 = value3.split(",");
      gama.push(value4)
    }

    for (let index = 0; index < gama.length; index++) {
      for (let indey = 0; indey < 3; indey++) {
        gama[index][indey] = parseInt(gama[index][indey])
      }
    }

    //let range = [0, 50, 100]
    let range = HEATMAP_RANGE_PA_DENSITY["density"]

    let index_range = 0
    for (let index = 0; index < range.length - 1; index++) {
      if (parseInt(percent) > parseInt(range[index])) {
        index_range = index;
      }
    }

    const range_diff = range[index_range + 1] - range[index_range]
    const diff = parseInt(percent) - range[index_range]
    const razon = diff / range_diff;

    let newcolor = "rgb("
    for (let index = 0; index < 3; index++) {
      let element = razon * (Math.abs(gama[index_range + 1][index] - gama[index_range][index]));
      if (gama[index_range + 1][index] - gama[index_range][index] < 0) {
        element = (Math.abs(gama[index_range + 1][index] - gama[index_range][index])) - element + gama[index_range + 1][index];
      } else {
        element = element + gama[index_range][index];
      }

      if (index !== 0) {
        newcolor = newcolor + ","
      }
      newcolor = newcolor + parseInt(element)
    }
    newcolor = newcolor + ")"

    return {
      "1.0": newcolor
    }
  }

  const setPlay = (param) => {
    setAnimationPlay(param)
  }

  const setSpeed = (param) => {
    const newSpeed = animationSpeed + param
    if (newSpeed <= MAXIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP && newSpeed >= MINIMUM_ANIMATION_SPEED_FPS_PEOPLE_ANALYSTICS_MODULE_VECTORS_TAP) {
      setAnimationSpeed(animationSpeed + param)
    }
  }

  const playAnimation = () => {
    setAnimationindex((window.aindex + 1) % (thedataVectors && thedataVectors.data && thedataVectors.data.length > 0 ? thedataVectors.data.length : 1))
    //window.aindex = (window.aindex + 1) % (thedataVectors && thedataVectors.data && thedataVectors.data.length > 0 ? thedataVectors.data.length : 1)
  }

  const stopAnimationInterval = () => {
    if (window.animationInterval) {
      clearInterval(window.animationInterval);
    }
  }

  const getVectorData = (form) => {
    //createVectorsMaps(form)
    createParentVectors(form)
    setRange( (form && form.range) ? form.range: 10)
  }

  const render = () => {
    let imageMap = true;
    if (thedata.image === "") {
      imageMap = false;
    }

    const footer = [
    ];

    //lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 20 }} 
    const content = (
      <>
        <Row>
          <Col span={19} className="img-container" style={{ width: (editabled ? "" : "100%") }}>
            {imageMap && (
              <>
                <Row>
                  <Col span={12} className={"motion-buttons"}>
                    <Tooltip key={`button-map-1`} title="Occupancy">
                      <Button
                        className={"green-button"+(mapType==="occupancy"?" selected":"")}
                        onClick={() => { onMapTypeChange("occupancy") }}
                        disabled={dataLoading}
                      >
                      </Button>
                    </Tooltip>
                    <Tooltip key={`button-map-2`} title="Density">
                      <Button className={"tricolor-button"+(mapType==="density"?" selected":"")}
                        onClick={() => { onMapTypeChange("density") }}
                        disabled={dataLoading}
                      >
                      </Button>
                    </Tooltip>
                    <Tooltip key={`button-map-3`} title="Vectors">
                      <Button className={"arrows-button"+(mapType==="vectors"?" selected":"")}
                        onClick={() => { onMapTypeChange("vectors") }}
                        disabled={dataLoading}
                      >
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {((mapType === "vectors" && data_warning) ? (<Tooltip key={`warning-spinner`} title="Data not available for this period"><Icon type="warning"  style={{ fontSize: '20px', color: '#287D8E', marginRight: "20px" }} /></Tooltip>) : (mapType === "vectors" && vectorSpiner) ? (<Spin style={{ marginRight: "20px" }} />):"")}
                  </Col>
                </Row>
                <Row className={"contenedor"}>
                  {thedata.devices && thedata.devices.map((device, indexd) => (
                    <Tooltip key={`device-tooltip-${device.id}`} title={
                      <div style={{ display: "inline-grid", textAlign: "center" }}>
                        <span>{device.aws_id}</span>

                        {(mapType === "occupancy") && (<span>{getTitle(theconfig.sensorName)} = {(dataheatmap && dataheatmap[mapType].length > 0 && dataheatmap[mapType][indexd] && dataheatmap[mapType][indexd][3] && (dataheatmap[mapType][indexd][3] !== "0" && dataheatmap[mapType][indexd][3] !== 0) ? (parseFloat(dataheatmap[mapType][indexd][3])).toFixed(2) + "" : "0.00")} {symbol(theconfig.sensorName)}</span>)}

                        {false && (mapType === "density") && (<span>{getTitle(theconfig.sensorName)} = {(dataheatmap && dataheatmap[mapType].length > 0 && dataheatmap[mapType][indexd] && dataheatmap[mapType][indexd][3] && (dataheatmap[mapType][indexd][3] !== "0" && dataheatmap[mapType][indexd][3] !== 0) ? (parseFloat(dataheatmap[mapType][indexd][3])).toFixed(0) + "" : "0.00")} {symbol(theconfig.sensorName)}%</span>)}
                        {(mapType === "density") && (<span>Movement events = {(dataheatmap && dataheatmap["occupancy"].length > 0 && dataheatmap["occupancy"][indexd] && dataheatmap["occupancy"][indexd][3] && (dataheatmap["occupancy"][indexd][3] !== "0" && dataheatmap["occupancy"][indexd][3] !== 0) ? (parseFloat(dataheatmap["occupancy"][indexd][3])).toFixed(0) + "" : "0.00")} </span>)}
                        {(mapType === "density") && (<span>Movement all sensor = {(thedata.params[theconfig.hour].group_control_data && thedata.params[theconfig.hour].group_control_data[device.control_group_id + ""] ? thedata.params[theconfig.hour].group_control_data[device.control_group_id + ""]["total_active_device"] + "" : "0")}</span>)}
                        {(mapType === "density") && (<span>Number of people = {(thedata.params[theconfig.hour].group_control_data && thedata.params[theconfig.hour].group_control_data[device.control_group_id + ""] ? thedata.params[theconfig.hour].group_control_data[device.control_group_id + ""]["max_num_person"] + "" : "0")}</span>)}

                        {(mapType === "vectors") && false && (<span>{getTitle(theconfig.sensorName)} = {(thedataVectors && thedataVectors.data && thedataVectors.data.length > 0 && thedataVectors.data[animationindex].length > 0 && thedataVectors.data[animationindex][indexd] && thedataVectors.data[animationindex][indexd][3] && (thedataVectors.data[animationindex][indexd][3] !== "0" && thedataVectors.data[animationindex][indexd][3] !== 0) ? (parseFloat(thedataVectors.data[animationindex][indexd][3])).toFixed(2) + "" : "0.00")} {symbol(theconfig.sensorName)}</span>)}

                      </div>
                    }>

                      <div
                        className={`device device-${device.id} ${(dataheatmap && dataheatmap[mapType] && dataheatmap[mapType].length > 0 && dataheatmap[mapType][indexd] && dataheatmap[mapType][indexd][2] && (dataheatmap[mapType][indexd][2] === "-1" || dataheatmap[mapType][indexd][2] === -1) ? "inactive" : "")}`}
                        style={{
                          position: "absolute",
                          zIndex: device.x > 0 ? 1 : 0,
                          //opacity: device.x > 0 ? 1 : 0,
                          left: device.x,
                          top: device.y,
                        }}
                      >
                        <Icon type="bulb" />
                        <div className="device-name" style={{ display: "inline-grid" }}>
                          <span>{device.aws_id}</span>
                        </div>
                      </div>
                    </Tooltip>
                  ))}
                  <img id="image-map-show"
                    className={thedata.imageDisplay}
                    //src={image}
                    src={`data:image/jpeg;base64,${thedata.image}`}
                    max-width="500px"
                    max-height="500px"
                    onLoad={updateImageDisplay}
                    alt="" />
                  {(thedata.data && thedata.data.length > 0 && !thedata.update && mapType === "occupancy") && (<Map datadevice={dataheatmap[mapType]} radius={bubble} renderMap={true} heatmap_color={HEATMAP_COLOR_PA} />)}

                  {(mapType === "density" && thedata.control_group) && (
                    thedata.control_group.map((group, indexg) => (
                      (thedata.dataDensity && thedata.dataDensity.length > 0 && !thedata.update) && (<Map key={"map" + indexg} datadevice={fileterByGroup(dataheatmap[mapType], group.id)} radius={bubbleDensity} heatmap_color={getHeatmapColor(thedata.params[theconfig.hour].group_control_data[group.id + ""]["percent_active_device"])} renderMap={true} />)
                    ))
                  )}

                  {(thedataVectors && thedataVectors.data && thedataVectors.data.length > 1 && !thedataVectors.update && mapType === "vectors") && (<Map datadevice={thedataVectors.data[animationindex]} radius={bubbleVector + 0.000000001 * (animationindex)} renderMap={true} heatmap_color={HEATMAP_COLOR_PA_VECTORS} />)}


                  {isModuleNotificatoinActive(user_modules, "fireDetection") && thedata.exits && thedata.exits.length > 0 && thedata.exits.map((exit, index) => (
                    <Tooltip key={`exit-tooltip-${index}`} title={
                      <div style={{ display: "inline-grid", textAlign: "center" }}>
                        <span>{exit.exit_type === "Normal" ? "normal exit" : "fire exit"}
                        </span>
                      </div>
                    }>
                      <div
                        key={`item-exit-${index}`}
                        className={`exit device item-exit-${index}`}
                        style={{
                          position: "absolute",
                          zIndex: exit.x > 0 ? 1 : 0,
                          //opacity: exit.x > 0 ? 1 : 0,
                          left: exit.x,
                          top: exit.y,
                        }}
                      >
                        {exit.exit_type === "Normal" ? <span className="image-name text-green">NX</span> : <span className="text-red image-name">FX</span>}
                        <div className="device-name exit-name">EXIT</div>
                      </div>
                    </Tooltip>

                  ))}
                </Row>

                <Row>
                  <div style={{ margin: "10px 0px 20px 0px", textAlign: "center" }}>
                    <div style={{ width: "500px", maxWidth: "500px", margin: "auto" }}>
                      {(mapType === "occupancy") && getLeyend(theconfig.sensorName, HEATMAP_RANGE_PA, HEATMAP_COLOR_PA, ["Sparse", "Dense"], false, true)}
                      {(mapType === "density") && getLeyend("density", HEATMAP_RANGE_PA_DENSITY, HEATMAP_COLOR_PA_DENSITY, ["Sparse", "Moderate", "Dense"], false, false)}
                      {(mapType === "vectors") && getLeyend("vectors", HEATMAP_RANGE_PA_VECTORS, HEATMAP_COLOR_PA_VECTORS, [], false, true)}
                    </div>
                  </div>
                  {(mapType === "density" || mapType === "occupancy") && <FrecuencyMap config={theconfig} thedata={thedata} selectDay={selectDay} changeHour={changeHour} dataLoading={dataLoading} />}
                  {(mapType === "vectors") &&
                    <VectorOptionMap
                      config={theconfig}
                      thedata={thedata}
                      selectDay={selectDay}
                      dataLoading={dataLoading}
                      getVectorData={getVectorData}
                      maxAnimationIndex={maxAnimationIndex}
                      animationindex={animationindex}
                      setAnimationIndexParent={setAnimationIndexParent}
                      animationPlay={animationPlay}
                      setPlay={setPlay}
                      animationSpeed={animationSpeed}
                      setSpeed={setSpeed}
                      setRefreshMinutesInterval={setRefreshMinutesInterval}
                      indexNewValue={indexNewValue}
                      setJumpToRefresh={setJumpToRefresh}
                      vectorSpiner={vectorSpiner}
                       />}
                </Row>
              </>
            )}
            {!imageMap && (
              <Row>
                <Col>
                  No devices map available, please, edit and upload one.
              </Col>
              </Row>
            )}
          </Col>
          {(editabled ?
            <Col span={5} className="device-container">

              {(mapType === "occupancy" || mapType === "density") &&
                <List
                  header={<div><div>{theconfig.element_name}</div><div>Occupancy Data</div></div>}
                  bordered
                  className={"occupancy-data"}
                  dataSource={[
                    { "title": "Occupancy Events", "value": (thedata.params && thedata.params[theconfig.hour] && thedata.params[theconfig.hour].occupancy_events ? thedata.params[theconfig.hour].occupancy_events : 0), classItem: "" },
                    { "title": "Occupancy %", "value": (thedata.params && thedata.params[theconfig.hour] && thedata.params[theconfig.hour].active_devices ? thedata.params[theconfig.hour].active_devices : 0) + "%", classItem: "" },
                    { "title": "Occupants", "value": (thedata.params && thedata.params[theconfig.hour] && thedata.params[theconfig.hour].occupants ? thedata.params[theconfig.hour].occupants : 0) + "(" + occupancy_limit + " Max)", classItem: (thedata.params && thedata.params[theconfig.hour] && thedata.params[theconfig.hour].occupants ? thedata.params[theconfig.hour].occupants : 0) > occupancy_limit ? " red-text" : "" }
                  ]}
                  style={{ maxHeight: "400px", overflow: "scroll" }}
                  renderItem={(item, indexd) => <List.Item style={{ display: "block" }}><div>{item.title}</div><div className={"occupancy-data-value" + item.classItem}>{item.value}</div></List.Item>}
                />
              }
              {(mapType === "occupancy" || mapType === "density") &&
                <List
                  header={<div>Compliance Warnings</div>}
                  bordered
                  className={"compilance-warning"}
                  dataSource={(thedata.params && thedata.params[theconfig.hour] && thedata.params[theconfig.hour].warning ? thedata.params[theconfig.hour].warning : [])}
                  style={{ maxHeight: "400px", overflow: "scroll" }}
                  renderItem={(item, indexd) => <List.Item style={{ display: "block" }}><div className={"warning-time"}>{item.time}</div><div className={"warning-text"}> Too many occupants in group ({item.num_person}/{occupancy_limit}) </div></List.Item>}
                />
              }
              {(mapType === "vectors") &&
                <List
                  header={<div><div>{theconfig.element_name}</div><div>Data Config</div></div>}
                  bordered
                  className={"occupancy-data"}
                  dataSource={[
                    { "title": "Data date", "value": (theconfigVectors && theconfigVectors.config && theconfigVectors.config.start ? moment(moment.utc(theconfigVectors.config.start).toDate()).format("YYYY-MM-DD HH:mm:ss") : "-"), classItem: "" },
                    { "title": "Minutes Range", "value": ((panelRange>0) ? panelRange: (theconfigVectors && theconfigVectors.config && theconfigVectors.config.end ? (moment(theconfigVectors.config.end)).diff(moment(theconfigVectors.config.start), 'minutes') : "0")), classItem: "" },
                    { "title": "Interval", "value": (theconfigVectors && theconfigVectors.config && theconfigVectors.config.downsampling_secs ? theconfigVectors.config.downsampling_secs : "0"), classItem: (thedata.params && thedata.params[theconfig.hour] && thedata.params[theconfig.hour].occupants ? thedata.params[theconfig.hour].occupants : 0) > occupancy_limit ? " red-text" : "" }
                  ]}
                  style={{ maxHeight: "400px", overflow: "scroll" }}
                  renderItem={(item, indexd) => <List.Item style={{ display: "block" }}><div>{item.title}</div><div className={"occupancy-data-value" + item.classItem}>{item.value}</div></List.Item>}
                />
              }

            </Col>
            :
            <></>
          )}
        </Row>
      </>
    );

    const card = (
      editabled //isLoading
        ? <Card bordered={false} actions={footer}>{content}</Card>
        :
        <>{content}</>
    )

    return (
      false //isLoading
        ? <Loader />
        :
        <>
          {card}
        </>
    );
  }

  return (
    render()
  )
}

export default DevicesHeatMap;
