import * as actions from '../constants'
import moment from 'moment'

const initialState = {
  blueLightBoostData: null,
  update: false,
  error: null,
}

const blueLightBoost = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUCCESS_FETCH_BLUE_LIGHT_BOOST_DATA:
      const blueLightBoostData = Object.assign({}, action.payload.data, {
        blue_light_boost_timestamp: moment.utc(action.payload.data.blue_light_boost_timestamp, 'YYYY-MM-DD HH:mm:ss').local()
      })

      return Object.assign({}, state, {
        blueLightBoostData: blueLightBoostData,
        update: false,
        error: null
      })

    case actions.SUCCESS_ACTIVATE_DEACTIVATE_BLUE_LIGHT_BOOST:
      const blueLightBoostActivationData = Object.assign({}, action.payload.data, {
        blue_light_boost_timestamp: moment.utc(action.payload.data.blue_light_boost_timestamp, 'YYYY-MM-DD HH:mm:ss').local()
      })

      return Object.assign({}, state, {
        blueLightBoostData: blueLightBoostActivationData,
        update: true,
        error: null
      })

    case actions.ERROR_BLUE_LIGHT_BOOST:
      return Object.assign({}, state, {
        blueLightBoostData: null,
        update: false,
        error: action.payload.error
      })

    case actions.CLEAN_BLUE_LIGHT_BOOST_ERROR:
      return Object.assign({}, state, {
        error: null,
        update: false,
      })

    case actions.CLEAN_BLUE_LIGHT_BOOST_STATE:
      return Object.assign({}, state, {
        error: null,
        blueLightBoostData: null,
        update: false,
      })
      
    default:
      return state
  }
}

export default blueLightBoost