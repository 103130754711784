import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Dropdown, Icon, Modal } from "antd"
import * as actions from '../../constants'
import { getTreeNodeIcon, enableDeleteCompanyElementButton } from '../../utils/helpers'
import "./style.css"

const ContextMenu = ({
  text,
  icon,
  parent,
  moduleType = "",
  disableMenu,
  overlayMenu,
  modalSetters: { setIsModalVisible, setActionType }
}) => {
  const [addItem, setAddItem] = useState([{}]);
  const [item, setItem] = useState([{}]);

  const tenant_uuid = useSelector((state) => state.rootReducer.auth.tenant_uuid)
  const domainClaims = useSelector((state) => state.rootReducer.auth.claims)
  const managerGroup = useSelector((state) => state.rootReducer.auth.group)


  const dispatch = useDispatch()


  useEffect(() => {
   
  }, [])

  useEffect(() => {
    (async () => {
      // Add item
      if (parent.group_type === "Company") setAddItem(["Building"]);
      if (parent.group_type === "Building") setAddItem(["Logical Group"]);
      if (parent.group_type === "Logical")
        setAddItem(["Logical Group", "Control Group", "Mix Group"]);

      // Remove items
      setItem({ uuid: parent.uuid, elementType: parent.group_type });
    })()
  }, [])

  const handleClick = ({ item, key, keyPath, domEvent }) => {
    if (keyPath[0] === "add" || keyPath[1] === "add") {
      const { newelementtype } = item.props;
      setActionType("add");
      dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: `${newelementtype}` } })
      setIsModalVisible(true);
    } else if (keyPath[0] === "edit") {
      setActionType("edit")
      dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: parent.group_type } })
      setIsModalVisible(true);
    } else if (keyPath[0] === "settings") {
      if(moduleType === "lightsControl"){
        setActionType("settings")
        dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: "Settings" } })
        setIsModalVisible(true)
      }else if(moduleType === "peopleAnalytics"){
        setActionType("settings")
        dispatch({ type: actions.SET_MODAL_TYPE, payload: { type: "PeopleAnalytics" } })
        setIsModalVisible(true)
      }
    } else {
      // delete
      Modal.confirm({
        content: `You are about to delete ${parent.group_type} ${parent.name}. Are you sure?`,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          dispatch({ type: actions.DELETE_ELEMENT, payload: { tenant_uuid: tenant_uuid, uuid: parent.uuid, group_type: parent.group_type } })
        }
      })
    }
  }

  const menu = (
    <Menu onClick={handleClick} >
      {parent.group_type === "Logical" || parent.group_type === "Mix" ? (
        <Menu.SubMenu
          key="add"
          disabled={disableMenu}
          title={
            <span style={{ userSelect: "none" }}>
              <span>
                <Icon type="plus" /> Add{" "}
              </span>
            </span>
          }
        >
          <Menu.Item newelementtype={"Logical"}>Logical Group</Menu.Item>
          <Menu.Item newelementtype={"Control"}>Control Group</Menu.Item>
          <Menu.Item newelementtype={"Mix"}>Mix Group</Menu.Item>
        </Menu.SubMenu>
      ) : parent.group_type === "Company" ? (
        <Menu.Item key="add" newelementtype={"Building"} disabled={disableMenu}>
          <span>
            <Icon type="plus" /> Add <strong>Building</strong>
          </span>
        </Menu.Item>
      ) : parent.group_type === "Building" ? (
        <Menu.Item key="add" newelementtype={"Logical"} disabled={disableMenu}>
          <span>
            <Icon type="plus" /> Add <strong>Logical Group</strong>
          </span>
        </Menu.Item>
      ) : (
              ""
            )}
      <Menu.Item key="edit" uuid={item.uuid} elementtype={item.elementType} disabled={disableMenu}>
        <span>
          <Icon type="download" /> Edit
        </span>
      </Menu.Item>
      {
        parent.group_type !== "Company" && moduleType !== "uv-cControl" ?
          <Menu.Item key="settings" uuid={item.uuid} elementtype={item.elementType} disabled={disableMenu}>
            <span>
              <Icon type="sliders" /> Settings
            </span>
          </Menu.Item>
          : ""
      }

      {(enableDeleteCompanyElementButton(domainClaims, item, managerGroup)) && (
        <Menu.Item key="remove" uuid={item.uuid} elementtype={item.elementType} disabled={disableMenu}>
          <span>
            <Icon type="delete" /> Remove
        </span>
        </Menu.Item>
      )}

    </Menu>
  );

  const emptymenu = (
    <Menu >
    </Menu>
  );

  return (
    <Dropdown overlay={ overlayMenu ? menu: emptymenu} trigger={["contextMenu"]}>
      <span>
        <Icon type={getTreeNodeIcon(icon)} />
        <span style={{ userSelect: "none" }}>{` ${text}`}</span>
      </span>
    </Dropdown>
  );
};

export default ContextMenu;
