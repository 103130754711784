const colors = {
  primary_color: "#287d8e",
  primary_color_hover: "#1d5a66",
  darkIconColor: "#595959",
  lightIconColor: "#d9d9d9",
  card_hoverable_color: "#5959597c",
  danger: "rgb(168, 57, 53)",
  success: "darkgreen"
};

export default colors;
