import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v1'
import IdleTimer from "react-idle-timer"
import { message, Form, Button, Row, Icon, Input, Notification, Modal, InputNumber, Radio, Empty, Tooltip } from "antd";
import Loader from "../Loader";
import { withRouter } from "react-router-dom";
import ScheduleInput from '../ScheduleInput'

import "./styles.css";
import * as moment from 'moment';



import CenteredRow from "../CenteredRow";
import { UserContext } from "../../context";
import * as actions from '../../constants';
import * as error_codes from '../../constants/error_codes';


const dateFormat = 'HH:mm';
const FormItem = Form.Item;
const ButtonGroup = Button.Group

const _UVControlForm = ({
  form,
  history,
  //isModalVisible,
  handleTreeData,
  tabKey,
}) => {

  const loading = useSelector(state => state.rootReducer.loaders.loadingUVControl)
  const group = useSelector((state) => state.rootReducer.companyManagment.currentElement)

  const error = useSelector((state) => state.rootReducer.UVControl.error)

  const uv_control = useSelector(state => state.rootReducer.UVControl.uvcontroldata)
  //const uv_control = null;

  const [currentSchedules, setCurrentSchedules] = useState([])
  const [updatedSchedule, setUpdatedSchedule] = useState(false)

  const [isValidTime, setIsValidTime] = useState(true)

  const [saveDisabled, setSaveDisabled] = useState(true)
  const [touchedFields, setTouchedFields] = useState([])
  const idleTimer = useRef(null)
  const timeout = parseInt(process.env.REACT_APP_REFRESH_DATA_TIMEOUT)

  const dispatch = useDispatch()

  const {
    userData: { tenant_uuid }
  } = useContext(UserContext)


  const { getFieldDecorator, validateFields, resetFields, isFieldTouched, getFieldsValue } = form

  useEffect(
    () => {
      if (uv_control && uv_control.uv_schedule && uv_control.uv_schedule.periods) {
        setCurrentSchedules(uv_control.uv_schedule.periods)
      }
    },
    [uv_control]
  )

  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (err, values) => {
      if (!err) {
        const { uv_post_movement_delay_minutes, uv_aggresive_cleaning, uv_timezone } = values
        const form = {}
        if (touchedFields.includes("uv_post_movement_delay_minutes")) {
          form.uv_post_movement_delay_minutes = uv_post_movement_delay_minutes
        }

        if (touchedFields.includes("uv_aggresive_cleaning")) {
          form.uv_aggresive_cleaning = uv_aggresive_cleaning
        }

        if (touchedFields.includes("uv_time_zone")) {
          form.uv_timezone = uv_timezone
        }

        if (updatedSchedule) {
          form.uv_schedule = currentSchedules;
          setUpdatedSchedule(false)
        }

        dispatch(({ type: actions.UPDATE_UV_CONTROL, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid, form: form } }))
      } else {
        message.error(err)
      }
    })
  }


  useEffect(
    () => {
      const touched_fields = Object.keys(getFieldsValue()).filter(x => isFieldTouched(x))
      if (touched_fields.length !== 0) {
        setSaveDisabled(false)
      }
      setTouchedFields(touched_fields)
    },
    [form]
  )

  useEffect(
    () => {
      /*
      if (!isModalVisible) {
        resetFields()
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_LIGHTS_CONTROL_STATE }))
      }
      */
      return () => {
        resetFields()
        setSaveDisabled(true)
        dispatch(({ type: actions.CLEAN_UV_CONTROL_STATE }))
      }
    },
    []
  )

  useEffect(
    () => {
      (async () => {
        if (tabKey === "uv_control_form" && !loading) {
          resetFields()
          dispatch(({ type: actions.FETCH_UV_CONTROL, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
          setSaveDisabled(true)
        }
      })();
    },
    [tabKey, group.uuid]
  )

  const isMinimumDuration = (count_minutes) => {
    let isvalid = true;
    currentSchedules.forEach(element => {
      const start_param = element.start_time
      const stop_param = element.stop_time
      const start = moment(start_param, dateFormat)
      const stop = moment(stop_param, dateFormat)
      const minutes_start = (parseInt((start).get('hour')) * 60 + parseInt((start).get('minute')))
      const minutes_stop = (parseInt((stop).get('hour')) * 60 + parseInt((stop).get('minute')))
      if (minutes_stop - minutes_start < count_minutes) {
        isvalid = false;
      }
    });
    setIsValidTime(isvalid);
  }

  useEffect(
    () => {
      (async () => {
        isMinimumDuration(30)
      })();
    },
    [currentSchedules]
  )

  

  useEffect(
    () => {
      if (uv_control !== null) {

        if (uv_control.update) {
          setSaveDisabled(true)
          if (touchedFields.includes("uv_post_movement_delay_minutes")) {
            message.success(`Movement Delay for UV sensor of group '${group.name}' successfully updated`)
          }

          if (touchedFields.includes("uv_aggresive_cleaning")) {
            message.success(`ON/OFF Aggressive Cleaning for UV sensor of group '${group.name}' successfully updated`)
          }

          if (updatedSchedule) {
            message.success(`Schedule for UV sensor of group '${group.name}' successfully updated`)
          }

          // add handle tree data if needed
          resetFields()
        }
      }
    },
    [uv_control]
  )

  useEffect(
    () => {
      if (error !== null) {
        if (error.error_code === error_codes.ERR_INVALID_TOKEN || error.error_code === error_codes.ERR_PERMISSION_REQUIRED) {
          Notification.error({ message: "Permission required", "description": error.message, "duration": 0 });
          history.push("/login");
          dispatch({ type: actions.LOG_OUT })
        } else if (error.error_code === error_codes.ERR_LOST_CONNECTION) {
          Modal.destroyAll()
          Modal.error({
            destroyOnClose: true,
            title: error.message,
            okText: "Retry",
            onOk: () => {
              resetFields()
              dispatch(({ type: actions.FETCH_UV_CONTROL, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
              setSaveDisabled(true)
            },
          })
        } else {
          Notification.error({ message: "Error", "description": error.message, "duration": 0 });
          dispatch({ type: actions.CLEAN_UV_CONTROL_ERROR })
        }
      }
    },
    [error]
  )


  const onUserIdle = async e => {
    if (tabKey === "uv_control_form") {
      resetFields()
      dispatch(({ type: actions.FETCH_UV_CONTROL, payload: { tenant_uuid: tenant_uuid, uuid: group.uuid } }))
      setSaveDisabled(true)
    }
    //idleTimer.reset()
  }


  const handleOnChangeSchedule = (uuid, start_time, stop_time, isNew) => {
    const tmp_schedules = currentSchedules.map(schedule => {
      if (schedule["uuid"] === uuid) {
        return { ...schedule, start_time: start_time, stop_time: stop_time, isNew: isNew }
      } else {
        return schedule
      }
    })
    setUpdatedSchedule(true)
    setSaveDisabled(false)
    setCurrentSchedules(tmp_schedules)
  }

  const handleRemoveSchedule = async (uuid, start_time, stop_time, isNew) => {
    /*
    dispatch(({
      type: actions.REMOVE_DEVICE,
      payload: { tenant_uuid: tenant_uuid, uuid: uuid }
    }))
    dispatch(({ type: actions.REDIRECT_DETAILS, payload: { redirect: true } }))
    */
    const new_current_schedule = currentSchedules.filter(schedule => {
      return (schedule.uuid !== uuid)
    })
    setCurrentSchedules(new_current_schedule);
    if (!isNew) {
      setUpdatedSchedule(true)
    }
    setSaveDisabled(false)
  }

  const handleAddNewScheduleInput = e => {
    setUpdatedSchedule(true)
    setSaveDisabled(false)
    setCurrentSchedules(currentSchedules.concat({ uuid: uuid(), isNew: true, start_time: "00:00:00", stop_time: "00:00:00" }))
  }

  return (false //isLoading
    ? <Loader />
    : ( !group ? (<div>
    <div className="big-text">No group selected</div>
  </div>) : (
      <div className="not-margin">
        <CenteredRow >

          <Form onSubmit={handleSubmit}>

            <FormItem label={<span>Post Movement Delay (minutes)</span>}>
              {getFieldDecorator("uv_post_movement_delay_minutes", {
                rules: [
                  //{ required: true, message: "A floor plan area is required" },
                  { type: 'number', min: 0, message: "uv post movement delay minutes must be greater than or equal than 0" }
                ],
                //initialValue: parseInt(0)
                initialValue: parseInt(uv_control && uv_control.uv_post_movement_delay_minutes && uv_control.uv_post_movement_delay_minutes !== null ? uv_control.uv_post_movement_delay_minutes : 0)
              })(<InputNumber disabled={loading}  loading={loading}/>)}
            </FormItem>

            <p className="line-bottom-div"></p>

            <FormItem label="Aggressive Cleaning">
              {getFieldDecorator("uv_aggresive_cleaning", {
                initialValue: uv_control && uv_control.uv_aggresive_cleaning && uv_control.uv_aggresive_cleaning !== null ? uv_control.uv_aggresive_cleaning + "" : "false",
                //valuePropName: 'checked'
              })(
                <Radio.Group  disabled={loading} loading={loading}>
                  <Radio.Button value="true">ON</Radio.Button>
                  <Radio.Button value="false">OFF</Radio.Button>
                </Radio.Group>
              )}
            </FormItem>

            <p className="line-bottom-div"></p>

            <div className="uv_control_schedule">
              <FormItem label={<span>Timezone</span>}>
                {getFieldDecorator("uv_timezone", {
                  rules: [
                    //{ required: true, message: "A floor plan area is required" },
                    //{ type: 'number', min: 1, max: 100000, message: "floor plan area must be between 1 and 1000" }
                  ],
                  //initialValue: "Canada/Eastern",
                  initialValue: uv_control && uv_control.uv_schedule && uv_control.uv_schedule.timezone && uv_control.uv_schedule.timezone !== null ? uv_control.uv_schedule.timezone : "Canada/Eastern"
                })(<Input disabled={true} />)}
              </FormItem>

              {currentSchedules &&
                currentSchedules.length > 0 && (

                  <div className="controller-form">
                    <div className="controller-form__controller">
                      <Row>
                        <div className="controller">
                          <span className="controller__title start margin-right-20">Start</span>
                          <span className="controller__title stop margin-right-20">Stop</span>
                          <span className="controller__title space"></span>
                        </div>
                      </Row>
                    </div>
                  </div>
                )}

              {currentSchedules &&
                currentSchedules.length > 0 &&
                currentSchedules.map(schedule => (
                  <ScheduleInput
                    handleRemoveSchedule={handleRemoveSchedule}
                    handleOnChangeSchedule={handleOnChangeSchedule}
                    loading={loading}
                    key={schedule && schedule.uuid ? schedule.uuid : ""}
                    schedule={schedule}
                  />
                ))}
              {currentSchedules.length === 0 && false && (<Empty />)}
              <br />
              <ButtonGroup>
                <Tooltip title="Schedule">
                  <Button
                    icon="plus"
                    type="primary"
                    className="add-schedule-btn"
                    ghost
                    onClick={handleAddNewScheduleInput}
                    loading={loading}
                  >
                    Schedule
                    </Button>
                </Tooltip>
              </ButtonGroup>

            </div>


            <FormItem >
              <Button
                type="primary"
                htmlType="button"
                style={{ width: "40%" }}
                disabled={saveDisabled || !isValidTime}
                loading={loading}
                onClick={handleSubmit}
              >
                {!loading && (<Icon type="save" />)} Save
                        </Button>
            </FormItem>
          </Form >
        </CenteredRow >
      </div>
    )));
};

const ElementDetailUVControlForm = Form.create()(_UVControlForm);

export default withRouter(ElementDetailUVControlForm);