import {
    postForgotPasswordService,
    postTokenResetPasswordService,
} from "../services"

export const requestPasswordResetApi = async (form) => {
    const response = await postForgotPasswordService(form)
    if (response && response.status === 201) {
        let data = await response.data;
        return data
    }
}

export const changeUserPasswordApi = async (form) => {
    const response = await postTokenResetPasswordService(form)
    if (response && response.status === 201) {
        let data = await response.data;
        return data
    }
}