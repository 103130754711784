import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdateIlluminance, errorFetchUpdateIlluminance } from '../actions/illuminanceActions'
import { fetchIllumanceApi, updateIllumanceApi } from '../api/illuminanceApi'
import { fetchOccupancySwitchApi, updateOccupancySwitchApi } from '../api/occupancySwitchApi'
import { toogleIlluminanceLoader } from '../actions/loaderActions'
import { handleTreeData } from '../actions/companyManagmentActions'
import * as groupTypes from '../constants/groups'

export function* getIlluminanceValues(action) {
    try {
        yield put(toogleIlluminanceLoader(true))

        const illuminance_data = yield call(fetchIllumanceApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.group_type
        )

        let occupancy_data = null
        if(action.payload.group_type === groupTypes.GROUP_TYPE_CONTROL || action.payload.group_type === groupTypes.GROUP_TYPE_MIX || action.payload.group_type === groupTypes.GROUP_TYPE_LOGICAL){
            occupancy_data = yield call(fetchOccupancySwitchApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
            )
        }

        const data = Object.assign({}, illuminance_data, occupancy_data)

        data.update = false

        yield put(successFetchUpdateIlluminance(data))
        yield put(toogleIlluminanceLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleIlluminanceLoader(false))
        yield put(errorFetchUpdateIlluminance(error))
    }
}

export function* updateIlluminanceValues(action) {
    try {
        yield put(toogleIlluminanceLoader(true))

        /*
        const data = yield call(updateIllumanceApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.form,
                action.payload.group_type
            )

        data.update = true
        data.lux_in_payload = "lux" in action.payload.form
        data.watts_in_payload = "watts" in action.payload.form
        data.max_on_secs_in_payload = "max_on_secs" in action.payload.form
        data.min_off_level_in_payload = "min_off_level" in action.payload.form

        data.update = true
        data.lux_in_payload = "lux" in action.payload.form
        data.watts_in_payload = "watts" in action.payload.form
        data.max_on_secs_in_payload = "max_on_secs" in action.payload.form
        data.min_off_level_in_payload = "min_off_level" in action.payload.form

        */
       
       const form = {}
       form.lux = action.payload.form.lux
       form.watts = action.payload.form.watts
       form.max_on_secs = action.payload.form.max_on_secs
       form.max_off_secs = action.payload.form.max_off_secs
       form.min_off_level = action.payload.form.min_off_level
       form.people_analytics = action.payload.form.people_analytics

       const occupancy_form = {}
       occupancy_form.occupancy_switch = action.payload.form.occupancy_sensor === "true" ? true : false
       
        const illumenance_data = yield call(updateIllumanceApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            form,
            action.payload.group_type
        )

        let data = Object.assign({}, illumenance_data, {})

        if(action.payload.group_type !== 'Company' && action.payload.group_type !== 'Building'){
            const occupance_data = yield call(updateOccupancySwitchApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                occupancy_form
            )

            data = Object.assign({}, illumenance_data, occupance_data)
        }

        data.update = true
        data.lux_in_payload = "lux" in action.payload.form
        data.watts_in_payload = "watts" in action.payload.form
        data.max_on_secs_in_payload = "max_on_secs" in action.payload.form
        data.max_off_secs_in_payload = "max_off_secs" in action.payload.form
        data.min_off_level_in_payload = "min_off_level" in action.payload.form
        data.people_analytics_in_payload = "people_analytics" in action.payload.form
        
        //if(action.payload.group_type !== 'Company' && action.payload.group_type !== 'Building'){
        data.occupancy_sensor_in_payload = "occupancy_sensor" in action.payload.form
        //}


        yield put(handleTreeData(data, "settings"))
        yield put(successFetchUpdateIlluminance(data))
        yield put(toogleIlluminanceLoader(false))

    } catch (err) {
        let error = {}
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }else{
            error = err.response.data
        }
        yield put(toogleIlluminanceLoader(false))
        yield put(errorFetchUpdateIlluminance(error))
    }
}

export function* fetchIlluminaceWatcher() {
    yield takeLatest(actions.FETCH_ILLUMINANCE, getIlluminanceValues)
}

export function* updateIlluminacenWatcher() {
    yield takeLeading(actions.UPDATE_ILLUMINANCE, updateIlluminanceValues)
}