import {
    getUser2faQrCodeService,
    getUser2faQrCodeLoginService,
    putUserProfileService
} from "../services";

export const fetchUser2faQrApi = async (tenant_uuid, uuid) => {
    const response = await getUser2faQrCodeService(tenant_uuid, uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const fetchUser2faQrLoginApi = async (tenant_uuid, uuid) => {
    
    const response = await getUser2faQrCodeLoginService(tenant_uuid, uuid)
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}

export const updateUserProfileApi = async(tenant_uuid, uuid, form) => {
    const response = await putUserProfileService(tenant_uuid, uuid, form)
    if (response && response.status === 202) {
        let data = await response.data;
        return data
    }
}