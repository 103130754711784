import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchSoundMasking = (tenant_uuid, uuid ) => action(actions.FETCH_SOUND_MASKING, {tenant_uuid, uuid})

export const updateSoundMasking = (tenant_uuid, uuid, form ) => action(actions.UPDATE_SOUND_MASKING, {tenant_uuid, uuid, form})

export const successFetchUpdateSoundMasking = data => action(actions.SUCCESS_FETCH_UPDATE_SOUND_MASKING, { data })

export const errorFetchUpdateSoundMasking = error => action(actions.ERROR_FETCH_UPDATE_SOUND_MASKING, { error })

export const cleanSoundMaskingError = () => action(actions.CLEAN_SOUND_MASKING_ERROR)

export const cleanSoundMaskingState = () => action(actions.CLEAN_SOUND_MASKING_STATE)