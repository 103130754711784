import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Tabs, Icon, Row } from "antd";
import { withRouter } from "react-router-dom";
import "./styles.css";

import SettingsIluminanceForm from "../SettingsIluminanceForm"
import GroupPowerCalibrationForm from '../GroupPowerCalibrationForm'
import Controllers from "../Controllers"

const { TabPane } = Tabs;

const Settings = ({
    elementData,
    isModalVisible,
    setIsModalVisible,
}) => {

    const [currentTab, setCurrentTab] = useState("illuminance")
    //console.log("TLC: Initial lux value " + elementData.lux);
    const info_setting_controller_tab = useSelector(state => state.rootReducer.redirect.setting_controller_tab)


    const handleCancel = async e => {
        setCurrentTab("illuminance")
        setIsModalVisible(false)
    };

    const handleOnChangeTab = async activeKey => {
        setCurrentTab(activeKey)
    }

    useEffect(
        () => {
            if (isModalVisible) {
                if(info_setting_controller_tab && info_setting_controller_tab.redirect){
                    setCurrentTab("controllers")
                }else{
                    setCurrentTab("illuminance")
                }
            }else{
                setCurrentTab("illuminance")
            }
        },
        [isModalVisible]
    );

    return (
        <Row>
            <Tabs defaultActiveKey="illuminance" activeKey={currentTab} onChange={handleOnChangeTab}>
                <TabPane
                    tab={
                        <span>
                            <Icon type="bulb" />
                            Illuminance
                        </span>
                    }
                    key="illuminance"
                >
                    {
                        <SettingsIluminanceForm
                            isModalVisible={isModalVisible}
                            tabKey={currentTab}
                        />
                    }
                </TabPane>

                
                {
                    elementData && elementData.group_type && elementData.group_type !== "Building" && (
                        <TabPane
                            tab={
                                <span>
                                    <Icon type="control" />
                                    Calibration
                                    </span>
                            }
                            key="controllers"
                        >
                            {

                                <GroupPowerCalibrationForm
                                    tabKey={currentTab}
                                    isModalVisible={isModalVisible}
                                />

                                //<Controllers
                                //    isModalVisible={isModalVisible}
                                //    elementData={elementData}
                                //    tabKey={currentTab}
                                ///>
                            }
                        </TabPane>
                    )
                }
            </Tabs>


            <Button
                type="default"
                htmlType="button"
                style={{ width: "40%", marginRight: "10%" }}
                size="large"
                icon="close"
                onClick={handleCancel}
            >
                Cancel
            </Button>

        </Row >
    );
};

export default withRouter(Settings);