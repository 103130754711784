// notification type
export const DIRECT = "1"
export const CONTACT = "2"
export const BROADCAST = "3"
export const CONTACT_EMERGENCY = "4"
export const CONTACT_LANDLORD = "5"
export const CONTACT_TECHINCAL = "6"

//message_type
export const INFORMATION = "1"
export const ERROR = "2"
export const WARNING = "3"
export const ALARM = "4"

//notification state
export const READ = "1"
export const UNREAD = "2"
export const RESPONDED = "3"

//transport
export const TOAST = "toast"
export const EMAIL = "mail"
export const SMS = "sms"

export const INDEX_TOAST = 1
export const INDEX_EMAIL = 2
export const INDEX_SMS = 3

export const RECEIPT = "receipt"
export const SEND = "send"

export const TRANSPORT = {"toast": 1, "mail": 2, "sms": 3 }
