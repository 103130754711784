import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import * as actions from '../constants'
import * as error_codes from '../constants/error_codes'
import { successFetchUpdateEnergyAnalysisSettings, errorFetchUpdateEnergyAnalysisSettings, successFetchEnergyConsumptionOverviewData, errorFetchEnergyConsumptionOverviewData, successFetchChartEnergyData, errorFetchChartEnergyData } from '../actions/energyAnalysisOverviewActions'
import { fetchEnergyAnalyisSettingsApi, updateEnergyAnalyisSettingsApi, fetchPeaksEnergyConsumptionApi, fetchLatestEnergyConsumptionApi, fetchHistoricEnergyAggregatedDataApi } from '../api/energyAnalysisOverviewApi'
import { fetchHistoricDataApi } from '../api/chartDataApi'
import { toogleEnergyAnalysisOverviewLoader, toogleEnergyConsumptionOverviewLoader, toogleEnergyChartLoader } from '../actions/loaderActions'
import { unifyRequestsResults } from "../utils/helpers"
//import { handleTreeData } from '../actions/companyManagmentActions'
//import * as groupTypes from '../constants/groups'
import moment from 'moment'

export function* getEnergyAnalysisSettingsValues(action) {
    try {
        yield put(toogleEnergyAnalysisOverviewLoader(true))

        const data = yield call(fetchEnergyAnalyisSettingsApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            action.payload.group_type
        )

        data.update = false

        yield put(successFetchUpdateEnergyAnalysisSettings(data))
        yield put(toogleEnergyAnalysisOverviewLoader(false))

    } catch (err) {
        let error = {}
        console.log(err)
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleEnergyAnalysisOverviewLoader(false))
        yield put(errorFetchUpdateEnergyAnalysisSettings(error))
    }
}

export function* updateEnergyAnalysisSettingsValues(action) {
    try {
        yield put(toogleEnergyAnalysisOverviewLoader(true))

        const form = {}
        form.off_peak_start = moment(action.payload.form.off_peak_start, "HH").format("HH:mm:ss")
        form.off_peak_end = moment(action.payload.form.off_peak_end, "HH").format("HH:mm:ss")
        form.mid_peak_start = moment(action.payload.form.mid_peak_start, "HH").format("HH:mm:ss")
        form.mid_peak_end = moment(action.payload.form.mid_peak_end, "HH").format("HH:mm:ss")
        form.on_peak_start = moment(action.payload.form.on_peak_start, "HH").format("HH:mm:ss")
        form.on_peak_end = moment(action.payload.form.on_peak_end, "HH").format("HH:mm:ss")
        form.consumption_date_span_start = moment(action.payload.form.consumption_date_span_start, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")
        form.consumption_date_span_end = moment(action.payload.form.consumption_date_span_end, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")

        if ("off_peak_cost" in action.payload.form) {
            form.off_peak_cost = action.payload.form.off_peak_cost
        }
        if ("mid_peak_cost" in action.payload.form) {
            form.mid_peak_cost = action.payload.form.mid_peak_cost
        }
        if ("on_peak_cost" in action.payload.form) {
            form.on_peak_cost = action.payload.form.on_peak_cost
        }

        const data = yield call(updateEnergyAnalyisSettingsApi,
            action.payload.tenant_uuid,
            action.payload.uuid,
            form,
            action.payload.group_type
        )

        data.update = true

        yield put(successFetchUpdateEnergyAnalysisSettings(data))
        yield put(toogleEnergyAnalysisOverviewLoader(false))

    } catch (err) {
        let error = {}
        console.log(err)
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }
        yield put(toogleEnergyAnalysisOverviewLoader(false))
        yield put(errorFetchUpdateEnergyAnalysisSettings(error))
    }
}

export function* getEnergyConsumptionOverviewValues(action) {
    try {
        yield put(toogleEnergyConsumptionOverviewLoader(true))

        const data = yield all([
            call(fetchLatestEnergyConsumptionApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.group_type,
                0,
                0,
                30
            ),
            call(fetchLatestEnergyConsumptionApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.group_type,
                1,
                0,
                0
            ),
            call(fetchPeaksEnergyConsumptionApi,
                action.payload.tenant_uuid,
                action.payload.uuid,
                action.payload.group_type,
            )
        ])

        yield put(successFetchEnergyConsumptionOverviewData(data))
        yield put(toogleEnergyConsumptionOverviewLoader(false))

    } catch (err) {
        let error = {}
        console.log(err)
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_BUILDING) {
                error = { "message": "Building was deleted!", "error_code": error_code }
            }
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        } else {
            error = err.response.data
        }
        yield put(toogleEnergyConsumptionOverviewLoader(false))
        yield put(errorFetchEnergyConsumptionOverviewData(error))
    }
}

export function* getChartEnergyDemandDataValues(action) {
    try {
        yield put(toogleEnergyChartLoader(true))

        const stepTime = action.payload.step_time
        const requestTime = action.payload.request_time
        let startLocal = null
        let endLocal = null
        let end = null
        let start = null
        let data = null
        let currentTime = null

        if (requestTime < 24){
            start = moment.utc().subtract(requestTime, 'hours').subtract(stepTime, 'minutes')
            startLocal = moment().subtract(requestTime, 'hours').subtract(stepTime, 'minutes')
            endLocal = moment().subtract(requestTime, 'hours').add(stepTime, 'minutes')
  
            end = moment.utc().subtract(requestTime, 'hours').add(stepTime, 'minutes')
            currentTime = moment().utc()
            const time_intervals = []

            let tmpstart = moment.utc().subtract(requestTime, 'hours').subtract(stepTime, 'minutes')
            let tmpend = moment.utc().subtract(requestTime - 1, 'hours')
            let count = 1
            while (count <= requestTime ) {
                time_intervals.push([tmpstart.format('YYYYMMDDHHmmss'), tmpend.format('YYYYMMDDHHmmss')])
                tmpstart.add(1, 'hours')
                tmpend.add(1, 'hours')
                count = count + 1
              }

            const tmp_data = yield all(
                time_intervals.map(time_interval => {
                    return call(fetchHistoricDataApi, action.payload.tenant_uuid, action.payload.uuid, 'power', time_interval[0], time_interval[1])
                })
            )
            data = unifyRequestsResults(tmp_data)
        } else {
            start = moment().startOf('day').subtract(stepTime, 'hours').utc()
            end = moment().startOf('day').utc()
            startLocal = moment().startOf('day').subtract(stepTime, 'hours')
            endLocal = moment().startOf('day')

            data = yield call(fetchHistoricEnergyAggregatedDataApi, action.payload.tenant_uuid, action.payload.uuid, start.format("YYYYMMDDHHmmss"), end.format("YYYYMMDDHHmmss"))
        }

        
            
        yield put(successFetchChartEnergyData(data, requestTime, stepTime, start, end, startLocal, endLocal, currentTime ))
        yield put(toogleEnergyChartLoader(false))

    } catch (err) {
        let error = {}
        console.log(err)
        if (err.message.includes("401")) {
            error = { "message": "You are not authorized to perform this action", "error_code": error_codes.ERR_INVALID_TOKEN };
        } else if (err.message.includes("403")) {
            error = { "message": "You have not permissions to perform this action", "error_code": error_codes.ERR_PERMISSION_REQUIRED };
        } else if (err.message.includes("404")) {
            const error_code = err.response.data.error_code
            if (error_code === error_codes.ERR_INVALID_GROUP) {
                error = { "message": "Group was deleted!", "error_code": error_code }
            }
        } else if (err.message.includes("Network Error")) {
            error = { "message": "Connection is lost!", "error_code": error_codes.ERR_LOST_CONNECTION };
        }
        yield put(toogleEnergyChartLoader(false))
        yield put(errorFetchChartEnergyData(error))
    }
}

export function* fetchChartEnergyDemandDataWatcher() {
    yield takeLatest(actions.FETCH_CHART_ENERGY_DATA, getChartEnergyDemandDataValues)
}

export function* fetchEnergyAnalysisSettingsWatcher() {
    yield takeLatest(actions.FETCH_ENERGY_ANALYSIS_SETTINGS, getEnergyAnalysisSettingsValues)
}

export function* updateEnergyAnalysisSettingsWatcher() {
    yield takeLeading(actions.UPDATE_ENERGY_ANALYSIS_SETTINGS, updateEnergyAnalysisSettingsValues)
}

export function* fetchEnergyConsumptionOverviewWatcher() {
    yield takeLatest(actions.FETCH_ENERGY_CONSUMPTION_OVERVIEW_DATA, getEnergyConsumptionOverviewValues)
}