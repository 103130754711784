import {action} from '../utils/actionCreator'
import * as actions from '../constants'

export const fetchPeopleAnalytics = (tenant_uuid, uuid, group_type ) => action(actions.FETCH_PEOPLEANALYTICS, {tenant_uuid, uuid, group_type})

export const updatePeopleAnalytics = (tenant_uuid, uuid, form, group_type ) => action(actions.UPDATE_PEOPLEANALYTICS, {tenant_uuid, uuid, form, group_type})

export const successFetchUpdatePeopleAnalytics = data => action(actions.SUCCESS_FETCH_UPDATE_PEOPLEANALYTICS, { data })

export const errorFetchUpdatePeopleAnalytics = error => action(actions.ERROR_FETCH_UPDATE_PEOPLEANALYTICS, { error })

export const cleanPeopleAnalyticsError = () => action(actions.CLEAN_PEOPLEANALYTICS_ERROR)

export const cleanPeopleAnalyticsState = () => action(actions.CLEAN_PEOPLEANALYTICS_STATE)