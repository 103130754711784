import {
    getGroupLightCapabilityService,
} from "../services";

export const fetchLightCapabilityApi = async (tenant_uuid, group_uuid) => {
    const response = await getGroupLightCapabilityService(tenant_uuid, group_uuid);
    if (response && response.status === 200) {
        let data = await response.data;
        return data
    }
}